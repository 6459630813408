export const ACCOUNT_CREATED = 'Account Created'
export const WEBSITE_LEAD = 'Website Lead'
export const FIRST_SIGN_IN = 'First Sign In'
export const USER_SIGN_IN = 'Signed In'
export const BOOKED_MEETING = 'Booked Meeting'
export const USER_PROFILE_COMPLETED = 'User Profile Completed'
export const APP_INSTALLED = 'App Installed'
export const USER_IDENTIFIED = 'User Identified'

export const trackEvent = (event, data = {}) => {
  global.dataLayer.push({
    event,
    ...data
  })
}

export const trackPageView = (pageUrl) => {
  global.dataLayer.push({
    event: 'VirtualPageview',
    virtualPageURL: pageUrl
  })
}
