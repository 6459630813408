import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, registerField, Form } from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import IconButton from 'components/ad_champion/common/icon_button'
import TextInputGroup from 'components/ad_champion/common/text_input_group'
import SelectInputGroup from 'components/ad_champion/common/select_input_group'
import GenericContent from 'components/websites/workflow/generic_content'
import { openArticle } from 'components/util/help'
import handleSubmit from './feed_common_submit'
import { createValidator, validatePresence } from 'components/util/validation'
import { requestCurrencies } from 'actions/currencies'

const schemaOptions = [
  {
    value: 'http://verticals.feedops.com/v1/retail_standard.xsd',
    label: 'Retail'
  },
  {
    value: 'http://verticals.feedops.com/v1/classified_auto.xsd',
    label: 'Automotive'
  },
  {
    value: 'http://verticals.feedops.com/v1/classified_local.xsd',
    label: 'Services'
  },
  {
    value: 'http://verticals.feedops.com/v1/travel_flight.xsd',
    label: 'Flight'
  }
]

export class FeedUrlForm extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    hideSchema: PropTypes.bool,
    inventorySaveFeedError: PropTypes.string,
    inventory: PropTypes.object,
    workflowType: PropTypes.oneOf(['new', 'edit']),
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    showCurrency: PropTypes.bool.isRequired,
    requestCurrencies: PropTypes.func.isRequired,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    registerField: PropTypes.func.isRequired
  }

  static defaultProps = {
    showCurrency: true
  }

  componentDidMount() {
    const { inventory, requestCurrencies, showCurrency, registerField } =
      this.props
    this.initialiseForm(inventory)
    if (showCurrency) {
      requestCurrencies()
      registerField(REDUX_FORM_NAME, 'currency')
    }
  }

  componentDidUpdate(prevProps) {
    const { inventory } = this.props
    if (inventory && !prevProps.inventory) {
      this.initialiseForm(inventory)
    }
  }

  initialiseForm(inventory) {
    const { initialize } = this.props
    if (inventory) {
      const { schema, url, currency } = inventory
      initialize({ schema, url, currency })
    }
  }

  prependHTTP(e) {
    const { change } = this.props
    var currentUrl = e.target
    var urlTest = /^http(s)?:\/\//i
    if (currentUrl && urlTest.exec(currentUrl.value) === null) {
      change('url', 'https://' + currentUrl.value)
    }
  }

  renderCustomInventoryError() {
    const { inventorySaveFeedError } = this.props
    if (inventorySaveFeedError) {
      return <div className='alert alert-danger'>{inventorySaveFeedError}</div>
    }
    return null
  }

  setSchemaValue(value) {
    this.props.change('schema', value)
  }

  renderSchemaField() {
    const { formValues, workflowType, submitting } = this.props
    return (
      <SelectInputGroup
        name='schema'
        options={schemaOptions}
        value={formValues.schema}
        onValueChange={this.setSchemaValue.bind(this)}
        disabled={workflowType !== 'new' || submitting}
      />
    )
  }

  renderSchemaHelp() {
    const { formValues } = this.props
    return (
      <div>
        The schema for your feed.&nbsp;
        {formValues.schema && (
          <span>
            Download your selected schema.{' '}
            <a href={formValues.schema} target='_blank'>
              <i className='fa fa-external-link-alt' />
            </a>
          </span>
        )}
      </div>
    )
  }

  renderSchemaContent() {
    return (
      <GenericContent
        component={this.renderSchemaField()}
        help={this.renderSchemaHelp()}
      />
    )
  }

  renderUrlHelp() {
    return (
      <div>
        Your feed must conform to the selected XML schema.
        <IconButton
          className={'index-icon link-icon help-icon'}
          iconClass={'fa fa-question-circle'}
          tooltipText={'Help'}
          tooltipId={'help-activate-new-promotions'}
          tooltipDirection={'top'}
          action={() => openArticle('dynamic-creative-retail-feed-schema')}
          disabled={false}
        />
      </div>
    )
  }

  renderUrlField() {
    const { formValues, submitting } = this.props
    return (
      <GenericContent
        component={
          <TextInputGroup
            name='url'
            label='Feed URL'
            formValues={formValues}
            handleOnBlur={this.prependHTTP.bind(this)}
            disabled={submitting}
          />
        }
        help={this.renderUrlHelp()}
      />
    )
  }

  handleCurrencySelectorChange(value) {
    const { change } = this.props
    change('currency', value)
  }

  renderCurrencySelector() {
    const { currencies, formValues, submitting } = this.props
    return (
      <SelectInputGroup
        name='currency'
        value={formValues.currency}
        options={currencies}
        onValueChange={this.handleCurrencySelectorChange.bind(this)}
        disabled={submitting}
      />
    )
  }

  renderCurrencyField() {
    return (
      <GenericContent
        component={this.renderCurrencySelector()}
        help='The currency for your product prices.'
      />
    )
  }

  render() {
    const { hideSchema, handleSubmit, showCurrency } = this.props
    return (
      <Form onSubmit={handleSubmit}>
        {!hideSchema && this.renderSchemaContent()}
        {this.renderUrlField()}
        {this.renderCustomInventoryError()}
        {showCurrency && this.renderCurrencyField()}
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
  currencies: state.currencies.supported,
  inventorySaveFeedError: state.inventories.error
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestCurrencies, registerField }, dispatch)

const FeedUrlWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedUrlForm)

export const validateCurrency = (values, props) => {
  const { showCurrency } = props
  var errors = {}
  if (showCurrency) {
    errors.currency = validatePresence(values.currency)
  }
  return errors
}

export const REDUX_FORM_NAME = 'feedUrlForm'
export default reduxForm({
  form: REDUX_FORM_NAME,
  validate: createValidator(['url', 'schema'], validateCurrency),
  onSubmit: handleSubmit,
  ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
})(FeedUrlWithRedux)
