import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdbreact'
import { enableDisableChannelSettings } from 'actions/channel_settings'
import { fetchWebsiteDetail } from 'actions/websites'
import DeleteModal from 'components/feed_ops/common/delete_modal'
import FeedLink from 'components/feed_ops/common/feed_link'
import { appIdSelector } from 'selectors/app'
import { channelSelector } from 'selectors/channel'
import { websiteIdSelector } from 'selectors/websites'
import {
  googleAccountSelector,
  facebookAccountSelector,
  microsoftAccountSelector,
  bazaarvoiceAccountSelector,
  reebeloAccountSelector
} from 'selectors/websites/channel_settings'
import {
  CONNECTABLE_CHANNELS,
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT,
  CHANNEL_BAZAARVOICE,
  CHANNEL_REEBELO
} from 'util/supported_channels'

import './container.scss'

export class ChannelAccountsContainer extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    channel: PropTypes.oneOf(CONNECTABLE_CHANNELS).isRequired,
    websiteId: PropTypes.number.isRequired,
    googleAccount: PropTypes.arrayOf(PropTypes.object).isRequired,
    facebookAccount: PropTypes.arrayOf(PropTypes.object).isRequired,
    microsoftAccount: PropTypes.arrayOf(PropTypes.object).isRequired,
    bazaarvoiceAccount: PropTypes.arrayOf(PropTypes.object).isRequired,
    reebeloAccount: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    enableDisableChannelSettings: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { deleteModalOpen: false }
  }

  renderAccessFeedButton(accountDetail) {
    return (
      <>
        {accountDetail.updatedAt && (
          <div className='no-account-text'>
            Last updated: {accountDetail.updatedAt}
          </div>
        )}
        <div className='access-feed-container'>
          <FeedLink />
        </div>
      </>
    )
  }

  renderAccessFeedOrConnect(accountDetail) {
    const { appId, websiteId } = this.props

    return accountDetail.showAccessFeed ? (
      this.renderAccessFeedButton(accountDetail)
    ) : (
      <Link
        to={`/${appId}/${websiteId}/${accountDetail.connectPath}`}
        className='btn-connect-container'
      >
        <MDBBtn className={`${accountDetail.disabled ? 'disabled' : ''}`}>
          CONNECT
        </MDBBtn>
      </Link>
    )
  }

  async handleDisconnectConfirmed(accountDetail) {
    const {
      fetchWebsiteDetail,
      websiteId,
      channel,
      enableDisableChannelSettings
    } = this.props
    await accountDetail.disconnect(websiteId)
    await enableDisableChannelSettings(websiteId, channel, false)
    await fetchWebsiteDetail(websiteId)
  }

  renderDisconnectButton(accountDetail) {
    const { deleteModalOpen } = this.state
    return (
      <>
        {' '}
        <br />
        <MDBBtn
          outline
          color='red'
          onClick={() => this.setState({ deleteModalOpen: true })}
        >
          DISCONNECT
        </MDBBtn>
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          modalTitle='Disconnect Linked Account?'
          modalMessage='disconnect this account'
          buttonLabel='Disconnect'
          handleDeleteModalClose={() =>
            this.setState({ deleteModalOpen: false })
          }
          handleDeleteClick={this.handleDisconnectConfirmed.bind(
            this,
            accountDetail
          )}
        />
      </>
    )
  }

  renderDetailOrConnect(accountDetail) {
    if (accountDetail.accountId) {
      return (
        <div>
          Account ID: {accountDetail.accountId}
          <br />
          {accountDetail.accountName && (
            <>
              Account Name: {accountDetail.accountName}
              <br />
            </>
          )}
          Connected by: {accountDetail.connectedBy}
          {accountDetail.updatedAt && (
            <>
              <br />
              Last updated: {accountDetail.updatedAt}
            </>
          )}
          {!!accountDetail.disconnect &&
            this.renderDisconnectButton(accountDetail)}
        </div>
      )
    }

    return this.renderAccessFeedOrConnect(accountDetail)
  }

  renderAccountColumn(accountDetail) {
    return (
      <MDBCol key={accountDetail.title} size='6'>
        <div className='channel-title'>{accountDetail.title}</div>
        <div className='account-logo-container'>
          <img alt={accountDetail.title} src={accountDetail.image} />
        </div>
        {this.renderDetailOrConnect(accountDetail)}
      </MDBCol>
    )
  }

  computeConnectedAccounts() {
    const {
      channel,
      googleAccount,
      facebookAccount,
      microsoftAccount,
      bazaarvoiceAccount,
      reebeloAccount
    } = this.props
    var accountDetails = []

    switch (channel) {
      case CHANNEL_GOOGLE:
        accountDetails = googleAccount
        break
      case CHANNEL_FACEBOOK:
        accountDetails = facebookAccount
        break
      case CHANNEL_MICROSOFT:
        accountDetails = microsoftAccount
        break
      case CHANNEL_BAZAARVOICE:
        accountDetails = bazaarvoiceAccount
        break
      case CHANNEL_REEBELO:
        accountDetails = reebeloAccount
        break
      default:
        break
    }

    return accountDetails.map((detail) => this.renderAccountColumn(detail))
  }

  render() {
    const accountDetails = this.computeConnectedAccounts()

    return (
      <div className='channel-accounts-card'>
        {accountDetails.length > 0 && (
          <MDBContainer>
            <MDBRow>{accountDetails}</MDBRow>
          </MDBContainer>
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  appId: appIdSelector(state, props),
  websiteId: websiteIdSelector(state, props),
  channel: channelSelector(state, props),
  googleAccount: googleAccountSelector(state, props),
  facebookAccount: facebookAccountSelector(state, props),
  microsoftAccount: microsoftAccountSelector(state, props),
  bazaarvoiceAccount: bazaarvoiceAccountSelector(state, props),
  reebeloAccount: reebeloAccountSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteDetail,
      enableDisableChannelSettings
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChannelAccountsContainer)
)
