import QueryString from 'query-string'

export const setQueryString = (history, newParams) => {
  const existing = QueryString.parse(history.location.search)
  const newUrl = `${history.location.pathname}?${QueryString.stringify({
    ...existing,
    ...newParams
  })}`

  return newUrl
}
