import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import { REDUX_FORM_NAME } from '../category_optimizations/card'
import { ALL_CATEGORIES } from 'components/feed/optimisation/filter'
import ControlBar from '../category_optimizations/control_bar'
import CategoryTreeNodes from '../category_optimizations/category_tree_nodes'
import {
  filterCategoriesTreeBySearchTerm,
  calculateErrorNodes
} from '../category_optimizations/filter'
import { websiteIdSelector } from 'selectors/websites'

export class CategoryTree extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    formValues: PropTypes.object.isRequired,
    formErrors: PropTypes.object.isRequired,
    updateSelectedCategory: PropTypes.func.isRequired,
    filterCategoriesTreeBySearchTerm: PropTypes.func.isRequired,
    calculateErrorNodes: PropTypes.func.isRequired,
    selectedCategory: PropTypes.object
  }

  static defaultProps = {
    filterCategoriesTreeBySearchTerm,
    calculateErrorNodes
  }

  constructor(props) {
    super(props)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.updateOpenNodes = this.updateOpenNodes.bind(this)
    this.selectedCategory = this.selectedCategory.bind(this)
    this.state = {
      categoryFilter: ALL_CATEGORIES,
      searchKeyword: '',
      filteredCategories: [],
      openNodes: []
    }
  }

  async componentDidMount() {
    const { categoryFilter, searchKeyword } = this.state
    this.filterCategoriesBySearchTerm(categoryFilter, searchKeyword)
  }

  async componentDidUpdate(prevProps) {
    const { websiteId, formErrors, formValues } = this.props
    const { categoryFilter, searchKeyword } = this.state
    const isWebsiteChanged = !_.isEqual(prevProps.websiteId, websiteId)
    const isFormErrorsChanged = !_.isEqual(prevProps.formErrors, formErrors)
    const isCategoriesChanged =
      formValues &&
      Object.keys(formValues).length > 0 &&
      !_.isEqual(prevProps.formValues.categories, formValues.categories)

    if (isWebsiteChanged || isFormErrorsChanged || isCategoriesChanged) {
      this.filterCategoriesBySearchTerm(
        categoryFilter,
        searchKeyword,
        isFormErrorsChanged || isCategoriesChanged
      )
    }
  }

  filterCategoriesBySearchTerm(
    categoryFilter,
    searchKeyword,
    useStateOpenNodes = false
  ) {
    var filteredCategories = []
    var openNodes = []

    const filteredValues = this.filterCategoriesTreeBySearchTerm(
      categoryFilter,
      searchKeyword
    )
    filteredCategories = filteredValues.filteredCategories
    openNodes = filteredValues.openNodes
    openNodes = this.calculateErrorNodes(openNodes)

    useStateOpenNodes
      ? this.setState({ filteredCategories })
      : this.setState({ filteredCategories, openNodes })
    this.hideDetailView(filteredCategories)
  }

  filterCategoriesTreeBySearchTerm(categoryFilter, searchKeyword) {
    const { formValues, formErrors, filterCategoriesTreeBySearchTerm } =
      this.props

    return filterCategoriesTreeBySearchTerm(
      categoryFilter,
      searchKeyword,
      formValues,
      formErrors
    )
  }

  calculateErrorNodes(openNodes) {
    const { formErrors } = this.props
    if (
      formErrors &&
      Object.keys(formErrors).length > 0 &&
      formErrors.categoryReplacements
    ) {
      const { formValues, calculateErrorNodes } = this.props
      const { categories } = formValues
      openNodes = calculateErrorNodes(formErrors, categories || [], true)
    }

    return openNodes
  }

  hideDetailView(filteredCategories) {
    var found = this.findInCategoryTree(filteredCategories)
    this.selectedCategory(null, found)
  }

  findInCategoryTree(nodes) {
    const { selectedCategory } = this.props

    for (let category of nodes) {
      if (
        category.key === selectedCategory.key ||
        this.findInCategoryTree(category.nodes)
      )
        return true
    }
    return false
  }

  handleFilter(filter) {
    this.setState({ categoryFilter: filter })
    const { searchKeyword } = this.state
    this.filterCategoriesBySearchTerm(filter, searchKeyword)
  }

  handleSearch(searchKeyword) {
    this.setState({ searchKeyword: searchKeyword })
    const { categoryFilter } = this.state
    this.filterCategoriesBySearchTerm(categoryFilter, searchKeyword)
  }

  updateOpenNodes(openNodes) {
    this.setState({ openNodes: openNodes })
  }

  selectedCategory(category, visible) {
    const { updateSelectedCategory } = this.props
    updateSelectedCategory(category, visible)
  }

  renderCategoryTreeNodes() {
    const { filteredCategories, openNodes } = this.state
    return (
      <CategoryTreeNodes
        filteredCategories={filteredCategories}
        openNodes={openNodes}
        updateOpenNodes={this.updateOpenNodes}
        selectedCategory={this.selectedCategory}
      />
    )
  }

  render() {
    const { formValues } = this.props
    const { categories } = formValues
    const { searchKeyword } = this.state
    return categories && categories.length > 0 ? (
      <>
        <ControlBar
          searchKeyword={searchKeyword}
          handleFilter={this.handleFilter}
          handleSearch={this.handleSearch}
        />
        {this.renderCategoryTreeNodes()}
      </>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
  formErrors: getFormSyncErrors(REDUX_FORM_NAME)(state) || {}
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryTree)
)
