import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { getRefreshToken, getMicrosoftUser } from 'selectors/microsoft/user'
import sleep from 'util/sleep'

export const LIST_MICROSOFT_ADS_ACCOUNTS_REQUEST =
  'LIST_MICROSOFT_ADS_ACCOUNTS_REQUEST'
export const LIST_MICROSOFT_ADS_ACCOUNTS_RESPONSE =
  'LIST_MICROSOFT_ADS_ACCOUNTS_RESPONSE'
export const SELECT_MICROSOFT_ADS_ACCOUNT_REQUEST =
  'SELECT_MICROSOFT_ADS_ACCOUNT_REQUEST'
export const SELECT_MICROSOFT_ADS_ACCOUNT_RESPONSE =
  'SELECT_MICROSOFT_ADS_ACCOUNT_RESPONSE'

export const listAdsAccountsRequest = () => ({
  type: LIST_MICROSOFT_ADS_ACCOUNTS_REQUEST
})

export const listAdsAccountsResponse = (accounts) => ({
  type: LIST_MICROSOFT_ADS_ACCOUNTS_RESPONSE,
  accounts
})

export const listAdsAccounts = () => async (dispatch, getState) => {
  const refreshToken = getRefreshToken(getState)
  dispatch(listAdsAccountsRequest())

  var response
  var status = 202
  var statusCallback = (_data, _statusText, jqXHR) => {
    status = jqXHR.status
  }

  do {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/microsoft/ads/accounts?refresh_token=${refreshToken}`,
      success: statusCallback,
      error: statusCallback
    })
    if (status === 202) {
      await sleep(1000)
    }
  } while (status === 202)

  return dispatch(
    listAdsAccountsResponse(
      response.accounts.map((a) => camelCaseKeys(a, { deep: true }))
    )
  )
}

export const selectAdsAccountRequest = (
  websiteId,
  adsAccountId,
  accountName
) => ({
  type: SELECT_MICROSOFT_ADS_ACCOUNT_REQUEST,
  websiteId,
  adsAccountId,
  accountName
})

export const selectAdsAccountResponse = (
  websiteId,
  adsAccountId,
  accountName,
  response
) => ({
  type: SELECT_MICROSOFT_ADS_ACCOUNT_RESPONSE,
  websiteId,
  adsAccountId,
  accountName,
  response
})

export const selectAdsAccount =
  (websiteId, adsAccountId, accountName) => (dispatch, getState) => {
    const { refreshToken, email, accessToken } = getMicrosoftUser(getState)
    dispatch(selectAdsAccountRequest(websiteId, adsAccountId, accountName))

    var data = snakeCaseKeys({
      accessToken,
      oauthUser: email,
      refreshToken,
      websiteId,
      microsoftAdsCustomerId: null,
      microsoftAdsCustomerAccountId: adsAccountId,
      microsoftAdsAccountName: accountName
    })
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/microsoft/ads/accounts/select`,
      data,
      dataType: 'json',
      success: (response) => {
        return dispatch(
          selectAdsAccountResponse(
            websiteId,
            adsAccountId,
            accountName,
            response
          )
        )
      }
    })
  }
