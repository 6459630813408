import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator';

export default class FeedChampion extends Component {

  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    platform: PropTypes.string,
    feedChampionUrl: PropTypes.string
  }

  constructor() {
    super();
    this.state = {
      feedId: null,
      loading: true,
      iFrame: {
        height: '245px'
      },
      feedChampionUrl: null,
      platform: null
    }
    this.msgHandler = this.msgHandler.bind(this);
    this.calculateIFrame = this.calculateIFrame.bind(this);
    this.dispatchPostMessageRequest = this.dispatchPostMessageRequest.bind(this);
  }

  componentDidMount() {
    const { feedChampionUrl, platform } = this.props
    this.setState({feedChampionUrl, platform})
    window.addEventListener('message', this.msgHandler)
    this.unmounted = false
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  componentDidUpdate() {
    const { feedChampionUrl, platform } = this.props
    if (!this.state.feedChampionUrl && feedChampionUrl) {
      this.setState({feedChampionUrl})
    }
    if (!this.state.platform && platform) {
      this.setState({platform})
    }
  }

  calculateIFrame(value) {
    if (this.unmounted ) {
      return
    }

    if (!value || value > 1200) {
      return
    }

    const { iFrame } = this.state    
    iFrame['height'] = (value + 100).toString();
    this.setState({iFrame, loading: false})
  }

  msgHandler(e) {
    const { value } = e.data
    this.calculateIFrame(value)
  }

  dispatchPostMessageRequest(c) {
    this.setState({loading: true})
    var container = c.target
    if (container && container.contentWindow) {
      container.contentWindow.postMessage({type: 'height'}, '*');
    }
  }

  renderProgressBar() {
    if (this.state.loading){
      return(
        <IndeterminateProgressIndicator message={'Loading Feed Builder App...'} />
      );
    }
  }

  renderIFrame() {
    const { width } = this.props
    const { feedChampionUrl, iFrame, platform } = this.state

    if (!feedChampionUrl) {
      return null
    }

    var url = feedChampionUrl
    if (platform) {
      url = `${url}&platform=${platform}`
    }
    return(
      <div>
        <iframe
          onLoad= {c => this.dispatchPostMessageRequest(c)}
          id="iFrame"
          width={width}
          height={iFrame['height']}
          src = {url}>
          Loading Feed Builder App...
        </iframe>
        { url && <div className='hint'>Having trouble signing in? <a href={url} target='_blank'>Open Feed Builder in a new window.</a></div> }
      </div>
    );
  }

  render() {
    return(
      <div className="feed-champion">
      { this.renderIFrame() }
      { this.renderProgressBar() }
      </div>
      );
  }
}
