import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import BannerNotification from 'components/banner_notification'
import Container from '@material-ui/core/Container'
import ChannelItem from './container/item'
import InventoryStatus from 'components/feed_ops/common/inventory_status'
import { inventoryDetailSelector } from 'selectors/inventory'
import { websiteInventoryIdSelector } from 'selectors/websites'
import { channelSettingsSelector } from 'selectors/websites/channel_settings'
import { formatNumber } from 'util/format_number'
import timeAgoInWords from 'util/time_ago_in_words'

import './container.scss'

export class ConnectedChannelsContainer extends Component {
  static propTypes = {
    channelSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
    inventoryDetail: PropTypes.object.isRequired
  }

  renderInventorySummary() {
    const { itemCount, lastImported } = this.props.inventoryDetail
    if (!!lastImported) {
      return (
        <>
          <span className='item-count'>{formatNumber(itemCount, 0)} </span>{' '}
          items last synced{' '}
          <span className='import-time'>{timeAgoInWords(lastImported)}</span>.
        </>
      )
    } else {
      return (
        <div className='inventory-status-container'>
          <InventoryStatus />
        </div>
      )
    }
  }

  renderInventoryStatus() {
    return (
      <Accordion expanded={false}>
        <AccordionSummary>
          <Container>
            <Row className='inventory-summary'>
              {this.renderInventorySummary()}
            </Row>
            <Row>
              Connect a channel to enable its specific programs and FeedOps
              features.
            </Row>
          </Container>
        </AccordionSummary>
      </Accordion>
    )
  }

  renderChannelItems() {
    const { channelSettings } = this.props

    return channelSettings.map((channelSetting, index) => (
      <ChannelItem
        key={channelSetting.channel}
        channelSetting={channelSetting}
      />
    ))
  }

  render() {
    return (
      <div className='connected-channel-container'>
        <BannerNotification />
        {this.renderInventoryStatus()}
        {this.renderChannelItems()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdSelector(state, props)
  return {
    channelSettings: channelSettingsSelector(state, props),
    inventoryDetail: inventoryDetailSelector(inventoryId)(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedChannelsContainer)
)
