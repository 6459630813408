import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isMobileOS } from 'util/operating_systems'

export default class GenericContent extends Component {
  static propTypes = {
    component: PropTypes.object,
    help: PropTypes.node,
    helpPanelCss: PropTypes.string,
    isMobileOS: PropTypes.bool.isRequired
  }

  static defaultProps = { isMobileOS }

  renderPanelFields() {
    return (
      <div className='content-panel-field'>
        {this.props.component || this.props.children}
      </div>
    )
  }

  renderPanelHelp() {
    const { helpPanelCss } = this.props
    var klass = helpPanelCss ? ` ${helpPanelCss}` : ''
    return <div className={`content-panel-help${klass}`}>{this.props.help}</div>
  }

  render() {
    const { isMobileOS } = this.props
    return (
      <div className='content-panel'>
        {this.renderPanelFields()}
        {!isMobileOS && this.props.help && this.renderPanelHelp()}
      </div>
    )
  }
}
