import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'
import {
  FETCH_PERFORMANCE_BY_DAY_REQUEST,
  FETCH_PERFORMANCE_BY_DAY_SUCCESS,
  FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_REQUEST,
  FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS
} from 'actions/performance/general'

const fetchMasterAccountPerformanceByDayRequest = (
  accountType,
  masterAccountId,
  channel
) => ({
  type: FETCH_PERFORMANCE_BY_DAY_REQUEST,
  accountType,
  id: masterAccountId,
  channel
})

const fetchMasterAccountPerformanceByDayRequestSuccess = (
  accountType,
  masterAccountId,
  channel,
  response
) => ({
  type: FETCH_PERFORMANCE_BY_DAY_SUCCESS,
  accountType,
  id: masterAccountId,
  channel,
  ...response
})

export const fetchMasterAccountPerformanceByDay = (
  accountType,
  masterAccountId,
  channel,
  dateRange
) => async (dispatch) => {
  dispatch(
    fetchMasterAccountPerformanceByDayRequest(
      accountType,
      masterAccountId,
      channel
    )
  )

  var status = 202
  var response
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  while (status === 202) {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/master_accounts/${masterAccountId}/performance`,
      data: snakeCaseKeys({ channel, ...dateRange }, { deep: true }),
      dataType: 'json',
      statusCode
    })
    if (status === 202) {
      await sleep(1000)
    }
  }

  dispatch(
    fetchMasterAccountPerformanceByDayRequestSuccess(
      accountType,
      masterAccountId,
      channel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchMasterAccountPerformanceByWebsiteRequest = (
  accountType,
  masterAccountId,
  channel,
  page,
  perPage
) => ({
  type: FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_REQUEST,
  accountType,
  id: masterAccountId,
  channel,
  page,
  perPage
})

const fetchMasterAccountPerformanceByWebsiteRequestSuccess = (
  accountType,
  masterAccountId,
  channel,
  page,
  perPage,
  response
) => ({
  type: FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS,
  accountType,
  id: masterAccountId,
  channel,
  page,
  perPage,
  ...response
})

export const fetchMasterAccountPerformanceByWebsite = (
  accountType,
  masterAccountId,
  channel,
  dateRange,
  page,
  perPage
) => async (dispatch) => {
  dispatch(
    fetchMasterAccountPerformanceByWebsiteRequest(
      accountType,
      masterAccountId,
      channel,
      page,
      perPage
    )
  )

  var status = 202
  var response
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  while (status === 202) {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/master_accounts/${masterAccountId}/performance/show_master_account_performance_by_website`,
      data: snakeCaseKeys(
        { channel, ...dateRange, page, perPage },
        { deep: true }
      ),
      dataType: 'json',
      statusCode
    })
    if (status === 202) {
      await sleep(1000)
    }
  }

  dispatch(
    fetchMasterAccountPerformanceByWebsiteRequestSuccess(
      accountType,
      masterAccountId,
      channel,
      page,
      perPage,
      camelCaseKeys(response, { deep: true })
    )
  )
}
