import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MDBIcon, MDBBtn } from 'mdbreact'
import Poller from 'components/ad_champion/common/poller'
import {
  fetchMerchantCenterProductErrors,
  refreshMerchantCenterProductErrors
} from 'actions/merchant_center_product_errors'
import { createMerchantCenterAccountSelector } from 'selectors/google/accounts'
import { websiteIdSelector } from 'selectors/websites'

export class FetchErrorButton extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    fetchMerchantCenterProductErrors: PropTypes.func.isRequired,
    refreshMerchantCenterProductErrors: PropTypes.func.isRequired,
    createMerchantCenterAccount: PropTypes.bool.isRequired
  }

  state = { refreshAndFetchMcErrors: false }

  componentDidMount() {
    const { id, createMerchantCenterAccount } = this.props
    !createMerchantCenterAccount && this.refreshAndFetchMcErrors(id)
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      fetchMerchantCenterProductErrors,
      createMerchantCenterAccount
    } = this.props

    if (id !== prevProps.id) {
      !createMerchantCenterAccount && fetchMerchantCenterProductErrors(id)
    } else if (
      createMerchantCenterAccount !== prevProps.createMerchantCenterAccount
    ) {
      !createMerchantCenterAccount && fetchMerchantCenterProductErrors(id)
    }
  }

  refreshAndFetchMcErrors() {
    const { refreshMerchantCenterProductErrors, id } = this.props
    this.setState({ refreshAndFetchMcErrors: true })
    refreshMerchantCenterProductErrors(id)
    this.setState({ refreshAndFetchMcErrors: false })
  }

  pollErrors() {
    const {
      createMerchantCenterAccount,
      fetchMerchantCenterProductErrors,
      id
    } = this.props

    if (!createMerchantCenterAccount) {
      fetchMerchantCenterProductErrors(id)
      this.setState({ refreshAndFetchMcErrors: false })
    }
  }

  render() {
    const { refreshAndFetchMcErrors } = this.state

    return (
      <div className='fetch-error-button'>
        <Poller
          action={this.pollErrors.bind(this)}
          periodMs={30000}
          disabled={false}
        />
        <MDBBtn onClick={this.refreshAndFetchMcErrors.bind(this)}>
          <MDBIcon
            spin={refreshAndFetchMcErrors}
            icon={refreshAndFetchMcErrors ? 'spinner' : 'redo-alt'}
          />
        </MDBBtn>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = websiteIdSelector(state, props)
  return {
    id,
    createMerchantCenterAccount: createMerchantCenterAccountSelector(
      state,
      props
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMerchantCenterProductErrors,
      refreshMerchantCenterProductErrors
    },
    dispatch
  )

const FetchErrorButtonWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FetchErrorButton)

export default withRouter(FetchErrorButtonWithRedux)
