import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact'

import './delete_modal.scss'

export class DeleteModal extends Component {
  static propTypes = {
    deleteModalOpen: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalMessage: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    handleDeleteModalClose: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired
  }

  static defaultProps = {
    buttonLabel: 'Delete'
  }

  renderBody() {
    const { modalMessage } = this.props
    return (
      <span className='modal-message'>
        Are you sure you want to {modalMessage}?
      </span>
    )
  }

  handleDeleteModalClose() {
    const { handleDeleteModalClose } = this.props
    handleDeleteModalClose()
  }

  handleDeleteClick() {
    const { handleDeleteClick } = this.props
    handleDeleteClick()
  }

  renderFooterActions() {
    const { buttonLabel } = this.props
    return (
      <>
        <MDBBtn
          size='sm'
          tag='span'
          className='cancel-btn'
          onClick={this.handleDeleteModalClose.bind(this)}
        >
          Cancel
        </MDBBtn>
        <MDBBtn
          size='sm'
          tag='span'
          className='delete-btn'
          onClick={this.handleDeleteClick.bind(this)}
        >
          {buttonLabel}
        </MDBBtn>
      </>
    )
  }

  render() {
    const { deleteModalOpen, modalTitle } = this.props

    return (
      <MDBModal isOpen={deleteModalOpen} className='delete-modal'>
        <MDBModalBody>
          <MDBModalHeader>{modalTitle}</MDBModalHeader>
          {this.renderBody()}
        </MDBModalBody>
        <MDBModalFooter>{this.renderFooterActions()}</MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteModal)
)
