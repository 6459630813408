import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const CREATE_FEED_REQUEST = 'CREATE_FEED_REQUEST'
export const CREATE_FEED_RESPONSE = 'CREATE_FEED_RESPONSE'
export const UPDATE_FEED_REQUEST = 'UPDATE_FEED_REQUEST'
export const UPDATE_FEED_RESPONSE = 'UPDATE_FEED_RESPONSE'
export const GENERATE_FEED_REQUEST = 'GENERATE_FEED_REQUEST'
export const GENERATE_FEED_RESPONSE = 'GENERATE_FEED_RESPONSE'
export const FETCH_FEED_DETAIL_REQUEST = 'FETCH_FEED_DETAIL_REQUEST'
export const FETCH_FEED_DETAIL_RESPONSE = 'FETCH_FEED_DETAIL_RESPONSE'
export const FETCH_FEED_DETAILS_BY_ID_REQUEST =
  'FETCH_FEED_DETAILS_BY_ID_REQUEST'
export const FETCH_FEED_DETAILS_BY_ID_RESPONSE =
  'FETCH_FEED_DETAILS_BY_ID_RESPONSE'

const fetchFeedDetailRequest = (feedId) => ({
  type: FETCH_FEED_DETAIL_REQUEST,
  feedId
})

const fetchFeedDetailResponse = (feedId, feedDetail) => ({
  type: FETCH_FEED_DETAIL_RESPONSE,
  feedId,
  feedDetail
})

const feedGenerationRequest = () => ({
  type: GENERATE_FEED_REQUEST
})

const feedGenerationResponse = (response) => ({
  type: GENERATE_FEED_RESPONSE,
  response
})

export const triggerFeedGenerationById = (feedId) => (dispatch) => {
  dispatch(feedGenerationRequest())
  return $.ajax({
    url: `/ad_champion/feeds/${feedId}/trigger_generation`,
    method: 'POST',
    dataType: 'json',
    success: (response) => {
      dispatch(feedGenerationResponse(camelCaseKeys(response)))
    }
  })
}

export const fetchFeedDetail = (feedId, refresh = false) => (dispatch) => {
  if (feedId <= 0) {
    return
  }

  if (!refresh) {
    dispatch(fetchFeedDetailRequest(feedId))
  }

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/feeds/${feedId}`,
    dataType: 'json',
    success: (response) => {
      return dispatch(fetchFeedDetailResponse(feedId, camelCaseKeys(response)))
    }
  })
}

const createFeedRequest = () => ({
  type: CREATE_FEED_REQUEST
})

const createFeedResponse = (response) => ({
  type: CREATE_FEED_RESPONSE,
  response
})

export const createFeed = (payload) => (dispatch) => {
  dispatch(createFeedRequest())
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/feeds`,
    dataType: 'json',
    data: snakeCaseKeys(payload),
    success: (response) => {
      return dispatch(createFeedResponse(camelCaseKeys(response)))
    }
  })
}

const updateFeedRequest = (id) => ({
  type: UPDATE_FEED_REQUEST,
  id
})

const updateFeedResponse = (id) => ({
  type: UPDATE_FEED_RESPONSE,
  id
})

export const updateFeed = (id, payload) => (dispatch) => {
  dispatch(updateFeedRequest(id))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/feeds/${id}`,
    dataType: 'json',
    data: snakeCaseKeys(payload),
    success: () => {
      return dispatch(updateFeedResponse(id))
    }
  })
}

const fetchFeedDetailsByIdRequest = (feedIds) => ({
  type: FETCH_FEED_DETAILS_BY_ID_REQUEST,
  feedIds
})

const fetchFeedDetailsByIdResponse = (feedIds, response) => ({
  type: FETCH_FEED_DETAILS_BY_ID_RESPONSE,
  feedIds,
  ...response
})

export const fetchFeedDetailsById = (feedIds) => async (dispatch) => {
  dispatch(fetchFeedDetailsByIdRequest(feedIds))

  var response = await $.ajax(`/ad_champion/feeds?ids=${feedIds.join(',')}`)
  dispatch(
    fetchFeedDetailsByIdResponse(
      feedIds,
      camelCaseKeys(response, { deep: true })
    )
  )
}
