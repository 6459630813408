import { FETCH_PRODUCT_FEED_TITLES_RESPONSE } from 'actions/product_feeds/titles'
const initialState = {}

export default (state = initialState, action) => {
  const websiteData = state[action.websiteId] || {}
  switch (action.type) {
    case FETCH_PRODUCT_FEED_TITLES_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          availableFields: action.availableFields,
          allAvailableAttributes: action.allAvailableAttributes
        }
      }

    default:
      return state
  }
}
