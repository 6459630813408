import qs from 'qs'
import camelCaseKeys from 'camelcase-keys'
import _ from 'lodash'
export const REQUEST_LOAD_PRODUCT_IDS = 'LOAD_PRODUCT_IDS_REQUEST'
export const RECEIVE_LOAD_PRODUCT_IDS = 'LOAD_PRODUCT_IDS_RESPONSE'
export const REQUEST_LOAD_PRODUCTS = 'LOAD_PRODUCTS_REQUEST'
export const RECEIVE_LOAD_PRODUCTS = 'LOAD_PRODUCTS_RESPONSE'
export const SAMPLE_PRODUCTS_REQUEST = 'SAMPLE_PRODUCTS_REQUEST'
export const SAMPLE_PRODUCTS_RESPONSE = 'SAMPLE_PRODUCTS_RESPONSE'

const requestLoadProductIds = (scenarioId, offset, count) => ({
  type: REQUEST_LOAD_PRODUCT_IDS,
  scenarioId,
  offset,
  count
})

const receiveLoadProductIds = (scenarioId, available, productIds) => ({
  type: RECEIVE_LOAD_PRODUCT_IDS,
  scenarioId,
  available,
  productIds
})

const requestLoadProducts = (scenarioId, productIds) => ({
  type: REQUEST_LOAD_PRODUCTS,
  scenarioId,
  productIds
})

const receiveLoadProducts = (scenarioId, products) => ({
  type: RECEIVE_LOAD_PRODUCTS,
  scenarioId,
  products
})

export const BATCH_SIZE = 50

const productIdsToFetch = (state, productIds) => {
  // Filter out product ids already retrieved or requested
  productIds = _.difference(productIds, Object.keys(state.products).map(id => parseInt(id, 10)))
  productIds = _.difference(productIds, state.products.requestedIds)
  return productIds
}

export const loadProductsIds = (scenarioId, offset, count) => (dispatch, getState) => {
  dispatch(requestLoadProductIds(scenarioId, offset, count))
  return (
    $.ajax({
      method: 'GET',
      url: `/ad_champion/scenarios/${scenarioId}/inventory/items?offset=${offset}&count=${count}`,
      dataType: 'json',
      success: (response) => {
        return dispatch(receiveLoadProductIds(scenarioId, response.available, response.ids, { deep: true }))
      }
    })
  )
}

export const loadProducts = (scenarioId, productIds) => (dispatch, getState) => {
  const state = getState()
  productIds = productIdsToFetch(state, productIds)

  if (productIds.length === 0) {
    return Promise.resolve()
  }  

  var promises = []
  for(var i = 0; i < productIds.length; i += BATCH_SIZE) {
    var idsToLoad = productIds.slice(i, i + BATCH_SIZE)
    dispatch(requestLoadProducts(scenarioId, idsToLoad))
    promises.push(
      $.ajax({
        method: 'GET',
        url: `/ad_champion/scenarios/${scenarioId}/inventory/items?${qs.stringify({ ids: idsToLoad })}`,
        dataType: 'json',
        success: (response) => {
          return dispatch(receiveLoadProducts(scenarioId, camelCaseKeys(response, { deep: true })))
        }
      })
    )
  }

  return Promise.all(promises)
}

const requestSampleProducts = (scenarioId) => ({
  type: SAMPLE_PRODUCTS_REQUEST,
  scenarioId,
  products: {}
})

const receiveSampleProducts = (scenarioId, products) => ({
  type: SAMPLE_PRODUCTS_RESPONSE,
  scenarioId,
  products
})

export const loadSampleProducts = (scenarioId) => (dispatch) => {
  dispatch(requestSampleProducts(scenarioId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/inventory/items/sample_items`,
    dataType: 'json',
    success:(response) => {
      return dispatch(receiveSampleProducts(scenarioId, camelCaseKeys(response, { deep: true })));
    }
  })
}