import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { fetchVendors } from 'actions/inventories/vendors'
import { fetchVerticalFields } from 'actions/vertical/fields'
import { fetchValueOperators } from 'actions/value/operators'
import { fetchRuleActionTypes } from 'actions/inventories/rules/action_types'
import { fetchRules } from 'actions/inventories/rules'
import { updateAttributeFilters } from 'actions/product_feeds/replacements'
import { MDBBtn } from 'mdbreact'
import RulesEngineModal from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal'
import {
  conditionsToFilters,
  filtersToConditions,
  getAttribute
} from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal/rules_engine_util'
import {
  websiteIdSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'
import { enabledVendorsSelector } from 'selectors/inventories/vendors'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import { valueOperatorsSelector } from 'selectors/value/operators'

import './create_data_rule.scss'

export class CreateDataRule extends Component {
  static propTypes = {
    displayedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteId: PropTypes.number.isRequired,
    feedDetail: PropTypes.object.isRequired,
    vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueOperators: PropTypes.object.isRequired,
    fetchVendors: PropTypes.func.isRequired,
    fetchVerticalFields: PropTypes.func.isRequired,
    fetchValueOperators: PropTypes.func.isRequired,
    fetchRuleActionTypes: PropTypes.func.isRequired,
    fetchRules: PropTypes.func.isRequired,
    updateAttributeFilters: PropTypes.func.isRequired,
    handleColumnsConfigChange: PropTypes.func.isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  async componentDidMount() {
    const {
      fetchVendors,
      fetchVerticalFields,
      fetchValueOperators,
      fetchRuleActionTypes
    } = this.props
    await Promise.all([
      fetchVendors(),
      fetchVerticalFields(),
      fetchValueOperators(),
      fetchRuleActionTypes()
    ])
  }

  computeUpdatedAttributeFilters(newRule) {
    const { availableAttributes, valueOperators } = this.props

    return conditionsToFilters(
      newRule.conditions,
      availableAttributes,
      valueOperators
    )
  }

  computeUpdatedColumnsConfig(newRule) {
    const { availableAttributes } = this.props

    return newRule.actions.map((action) => {
      const attribute = getAttribute(
        availableAttributes,
        action.verticalFieldId
      )
      return attribute.columnName || attribute.name
    })
  }

  updateProductTableFiltersAndColumns(newRule) {
    const {
      websiteId,
      updateAttributeFilters,
      displayedColumns,
      handleColumnsConfigChange,
      onSaveProductAttributesReplacements
    } = this.props
    const updatedAttributeFilters = this.computeUpdatedAttributeFilters(newRule)
    const updatedColumnsConfig = this.computeUpdatedColumnsConfig(newRule)

    updateAttributeFilters(websiteId, updatedAttributeFilters)
    handleColumnsConfigChange(
      _.uniq([...displayedColumns, ...updatedColumnsConfig])
    )
    onSaveProductAttributesReplacements()
  }

  async handleOnRulesChange() {
    const { fetchRules, websiteId } = this.props

    const response = await fetchRules(websiteId)
    const newRule = _.last(response.rules)
    this.updateProductTableFiltersAndColumns(newRule)
  }

  computeRuleVendors() {
    const { feedDetail, vendors } = this.props
    const { channel, sourceChannel, linkedChannels } = feedDetail

    return _.compact(
      _.uniq((linkedChannels || []).concat([channel, sourceChannel]))
    ).map((c) => vendors.find((v) => v.channel === c))
  }

  renderModal() {
    const { isOpen } = this.state
    const { appliedFilters, availableAttributes, valueOperators } = this.props
    const conditions = filtersToConditions(
      [],
      appliedFilters,
      availableAttributes,
      valueOperators
    )

    return (
      <RulesEngineModal
        isOpen={isOpen}
        rule={{ vendors: this.computeRuleVendors(), conditions: conditions }}
        onModalStateChange={() => this.setState({ isOpen: !isOpen })}
        handleOnRulesChange={this.handleOnRulesChange.bind(this)}
      />
    )
  }

  render() {
    return (
      <div>
        <MDBBtn
          tag='span'
          color='link'
          className='btn-column'
          onClick={() => this.setState({ isOpen: true })}
        >
          Create Rule
        </MDBBtn>
        {this.renderModal()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedDetail: websiteFeedDetailSelector(state, props),
  vendors: enabledVendorsSelector(state, props),
  availableAttributes: websiteAllAttributesSelector(state, props),
  valueOperators: valueOperatorsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVendors,
      fetchVerticalFields,
      fetchValueOperators,
      fetchRuleActionTypes,
      fetchRules,
      updateAttributeFilters
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateDataRule)
)
