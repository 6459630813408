import {
  LOAD_PROMOTIONS_SUCCESS,
  LOAD_PROMOTIONS_ERROR,  
  SAVE_PROMOTIONS_SUCCESS,
  SAVE_PROMOTIONS_ERROR,
  LOAD_PROMOTION_DETAIL_SUCCESS,
  LOAD_PROMOTION_DETAIL_ERROR,
  LOAD_PROMOTION_FEED_URL_SUCCESS,
  LOAD_PROMOTION_FEED_URL_ERROR,
  SET_SORT_COLUMN,
  SET_ITEM_SORT_COLUMN
} from 'actions/promotions';

var DefaultState = {
  list: {},
  error: null,
  promotionDetail: {
    promotionId: '',
    description: '',
    code: '',
    promotionStartDate: '',
    promotionEndDate: '',
    promotionDisplayStartDate: '',
    promotionDisplayEndDate: '',
    productApplicability: '',
    percentOff: '',
    moneyOffAmount: '',
    minimumPurchaseAmount: '',
    items: {}
  }, 
  activateNewPromotions: false,
  promotionFeedUrl: null,
  sortColumn: '',
  itemSortColumn: ''
}

const newSortColumn = (currentColumn, newColumn) => {
  switch(currentColumn) {
    case newColumn:
      return `-${newColumn}`
    case `-${newColumn}`:
      return ''
    default:
      return newColumn
  }
}

const sortColumnReducer = (state, action) => {
  switch(action.type) {
    case SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: newSortColumn(state.sortColumn, action.column)
      }
    case SET_ITEM_SORT_COLUMN:
      return {
        ...state,
        itemSortColumn: newSortColumn(state.itemSortColumn, action.column)
      }
    default:
      return state
  }
}

const loadPromotionReducer = (state, action) => {
  var list = {}
  switch(action.type) {
    case LOAD_PROMOTIONS_SUCCESS:
    case LOAD_PROMOTIONS_ERROR:
      action.list.forEach(v => { list[v.id] = v })
      return { 
        ...state,
        list,
        activateNewPromotions: action.activateNewPromotions,
        error: action.error
      };
    case SAVE_PROMOTIONS_SUCCESS:
    case SAVE_PROMOTIONS_ERROR:
      return { 
        ...state,
        list:  action.list,
        error: action.error
      };
    default:
      return state
  }
}

const promotionDetailReducer = (state, action) => {
  var items = {}
  switch(action.type) {
    case LOAD_PROMOTION_DETAIL_SUCCESS:
    case LOAD_PROMOTION_DETAIL_ERROR:
      action.promotionDetail.items.forEach(i => { items[i.sku] = {...i, id: i.sku } })
      return { 
        ...state,
        promotionDetail: {
          ...action.promotionDetail,
          items
        }
      };
    default:
      return state
  }
}

const promotionsReducer = (state = DefaultState, action) => {
  state = loadPromotionReducer(state, action)
  state = promotionDetailReducer(state, action)
  switch(action.type) {
    case LOAD_PROMOTION_FEED_URL_SUCCESS:
    case LOAD_PROMOTION_FEED_URL_ERROR:
      return { 
        ...state,
        promotionFeedUrl: action.promotionFeedUrl
      };
    default:
      return sortColumnReducer(state, action)
  }
}

export default promotionsReducer;