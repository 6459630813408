import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col } from 'reactstrap'
import { bindActionCreators } from 'redux'
import {
  reduxForm,
  Form,
  getFormValues,
  Field,
  SubmissionError
} from 'redux-form'
import changeCase from 'change-case'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  saveFeed,
  testFeed,
  getFeedFields,
  getFeed
} from 'actions/feed_app/api'
import TextInputGroup from 'components/ad_champion/common/text_input_group'
import Toggle from 'components/ad_champion/common/toggle'
import {
  validatePresence,
  validateDomainName,
  validateClientCredentials,
  validateAccessToken
} from 'components/util/validation'
import { websiteIdSelector, websiteUrlSelector } from 'selectors/websites'
import BigCommerceStoreHash from './custom_fields/big_commerce_store_hash'
import ShopifyDomain from './custom_fields/shopify_domain'
import { channelSelector } from 'selectors/channel'

const CUSTOM_FIELD_MAP = {
  big_commerce_store_hash: BigCommerceStoreHash,
  shopify_domain: ShopifyDomain
}

export class CredentialsForm extends Component {
  static propTypes = {
    feed: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    feedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    platform: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string.isRequired,
    getFeedFields: PropTypes.func.isRequired,
    getFeed: PropTypes.func.isRequired,
    saveFeed: PropTypes.func.isRequired,
    testFeed: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    websiteId: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
    showAdvancedToggle: PropTypes.bool.isRequired
  }

  static defaultProps = {
    readOnly: false,
    showAdvancedToggle: true
  }

  constructor(props) {
    const { platform } = props
    super(props)
    this.state = {
      showAdvanced: platform === 'other' || platform === 'nop_commerce'
    }
  }

  componentDidMount() {
    const { platform, feedId } = this.props
    this.initialize(platform, feedId)
  }

  componentDidUpdate(prevProps) {
    const { platform, feedId } = this.props
    if (
      (platform && platform !== prevProps.platform) ||
      (!isNaN(feedId) && feedId !== prevProps.feedId)
    ) {
      this.initialize(platform, feedId)
    }
  }

  async initialize(platform, feedId) {
    const { getFeedFields } = this.props
    await getFeedFields(platform)
    if (feedId) {
      const { getFeed } = this.props
      await getFeed(feedId)
    }
    this.initializeValues()
  }

  initializeValues() {
    const { initialize, feed, fields, websiteUrl } = this.props
    var values = {}
    Object.keys(fields).forEach((f) => {
      values[f] = feed && feed.detail ? feed.detail[f] : fields[f].default
    })
    if (websiteUrl) {
      values.websiteUrl = websiteUrl
    }
    if (values.shopifyDomain) {
      values.shopifyDomain = values.shopifyDomain.replace('.myshopify.com', '')
    }
    initialize(values)
  }

  handleOnBlurOnShopifyDomain() {
    const { change } = this.props
    change('shopifyDomain', this.removeUrlProtocol())
  }

  removeUrlProtocol() {
    var url = this.props.formValues.shopifyDomain || ''
    return url.replace('http://', '').replace('https://', '')
  }

  toggleShowAdvanced() {
    const { showAdvanced } = this.state
    this.setState({ showAdvanced: !showAdvanced })
  }

  renderField(k) {
    const { fields, readOnly, platform } = this.props
    const field = fields[k]
    const { label, hint, required, type } = field

    var validators = []
    if (
      ['clientId', 'clientSecret'].includes(k) &&
      platform === 'nop_commerce'
    ) {
      validators.push(validateClientCredentials)
    } else if (k === 'accessToken' && platform === 'nop_commerce') {
      validators.push(validateAccessToken)
    } else if (!readOnly && required) {
      validators.push(validatePresence)
    }

    if (CUSTOM_FIELD_MAP[type]) {
      var CustomField = CUSTOM_FIELD_MAP[type]
      if (!readOnly && required) {
        validators.push(validateDomainName)
      }
      return (
        <Field key={k} name={k} component={CustomField} validate={validators} />
      )
    }

    var handleOnBlurFunction
    switch (k) {
      case 'shopifyDomain':
        handleOnBlurFunction = this.handleOnBlurOnShopifyDomain.bind(this)
        break
      default:
        handleOnBlurFunction = undefined
    }

    return (
      <TextInputGroup
        key={k}
        name={k}
        type={type}
        label={label || changeCase.titleCase(k)}
        placeholder={hint}
        validate={validators}
        disabled={readOnly}
        isOptional={!required}
        handleOnBlur={handleOnBlurFunction}
      />
    )
  }

  renderToggleIfRequired() {
    const { fields, readOnly, showAdvancedToggle } = this.props
    const { showAdvanced } = this.state
    var anyAdvanced =
      Object.values(fields).filter((f) => !f.required).length > 0
    if (showAdvancedToggle && anyAdvanced && !readOnly) {
      return (
        <Row>
          <Col xs='12'>
            <Toggle
              label='Show Advanced Settings'
              value={showAdvanced}
              onClick={this.toggleShowAdvanced.bind(this)}
            />
          </Col>
        </Row>
      )
    }
  }

  render() {
    const { handleSubmit, fields, feed } = this.props
    const { showAdvanced } = this.state
    return (
      <Form onSubmit={handleSubmit} className='credentials-form'>
        {this.renderToggleIfRequired()}
        {Object.keys(fields)
          .filter((k) => showAdvanced || fields[k].required)
          .filter(
            (k) =>
              !((feed.detail || {}).embedded && fields[k].excludeWhenEmbedded)
          )
          .map((k) => this.renderField(k))}
      </Form>
    )
  }
}

const testConnection = async (values, _dispatch, props) => {
  const { testFeed, platform } = props
  toastr.info('Testing connection.')
  var success = await testFeed(platform, values)
  if (success === 'true') {
    toastr.success('Connection successful')
  } else if (success === 'false') {
    toastr.error('Could not connect. Please check your details and try again.')
  } else {
    toastr.error(success)
  }
  return success
}

export const REDUX_FORM_NAME = 'CredentialsForm'
export const handleSubmit = async (values, _dispatch, props) => {
  var updatedValues = values.shopifyDomain
    ? { ...values, shopifyDomain: `${values.shopifyDomain}.myshopify.com` }
    : { ...values }
  var success = await testConnection(updatedValues, _dispatch, props)
  if (success === 'true') {
    const { platform, feedId, websiteId, channel } = props
    var feedDetails = { ...updatedValues, id: feedId, websiteId }
    await props.saveFeed(platform, feedDetails, channel)
  } else {
    throw new SubmissionError('connection failed')
  }
}

const CredentialsRedux = reduxForm({
  form: REDUX_FORM_NAME,
  onSubmit: handleSubmit,
  ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
})(CredentialsForm)

export const mapStateToProps = (state, ownProps) => {
  const feed = state.feedApp.feeds[ownProps.feedId] || {}
  const fields =
    state.feedApp.fields[changeCase.camelCase(ownProps.platform)] || {}
  return {
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    feed,
    websiteId: ownProps.websiteId || websiteIdSelector(state, ownProps),
    websiteUrl: websiteUrlSelector(state, ownProps),
    fields,
    channel: channelSelector(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveFeed, testFeed, getFeedFields, getFeed }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CredentialsRedux)
)
