import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import changeCase from 'change-case'
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBInput,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn
} from 'mdbreact'
import { fetchProductTitles } from 'actions/product_feeds/titles'
import {
  websiteProductTitlesAvailableFieldsSelector,
  websiteProductTitlesSortedAvailableFieldsSelector,
  websiteProductTitlesAllAvailableAttributesSelector
} from 'selectors/product_feeds/titles'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import { findDuplicates, findEmptyTitles } from 'util/titles'
import { ADD_TEXT_HEADER } from 'util/format_string'

export class TitleDescriptionBuilder extends Component {
  static propTypes = {
    defaultFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    sortedAvailableFields: PropTypes.object.isRequired,
    allAvailableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    templateFields: PropTypes.arrayOf(PropTypes.object),
    duplicates: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyStaticTexts: PropTypes.arrayOf(PropTypes.string).isRequired,
    onTemplateFieldsChanged: PropTypes.func.isRequired,
    fetchProductTitles: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    websiteFeedIdByChannel: PropTypes.number.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  async componentDidMount() {
    const {
      availableFields,
      allAvailableFields,
      fetchProductTitles,
      websiteId,
      websiteFeedIdByChannel
    } = this.props
    if (availableFields.length <= 0 || allAvailableFields.length <= 0) {
      await fetchProductTitles(websiteId, websiteFeedIdByChannel)
    }
  }

  onFieldsChanged(newFields) {
    const { onTemplateFieldsChanged } = this.props
    onTemplateFieldsChanged(newFields)
  }

  handleFieldChange(index, field, value, isAdd) {
    const { templateFields } = this.props
    var modifiedFields = [...templateFields]
    modifiedFields.splice(index, isAdd ? 0 : 1, {
      name: field,
      value: value === ADD_TEXT_HEADER ? '' : value
    })
    this.onFieldsChanged(modifiedFields)
  }

  renderDropdownMenu(index, isAdd = false) {
    const { sortedAvailableFields } = this.props
    var updatedAvailableFields = { ...sortedAvailableFields }
    updatedAvailableFields = {
      [ADD_TEXT_HEADER]: ADD_TEXT_HEADER,
      ...sortedAvailableFields
    }

    return (
      <MDBDropdownMenu>
        {Object.keys(updatedAvailableFields).map((displayName, fieldIndex) => (
          <MDBDropdownItem
            key={fieldIndex}
            onClick={this.handleFieldChange.bind(
              this,
              index,
              updatedAvailableFields[displayName],
              updatedAvailableFields[displayName],
              isAdd
            )}
          >
            {displayName}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  renderAddBtn(index) {
    return (
      <MDBDropdown key={index} className='add-dropdown'>
        <MDBDropdownToggle tag='div'>
          <MDBIcon icon='plus-circle' />
        </MDBDropdownToggle>
        {this.renderDropdownMenu(index, true)}{' '}
      </MDBDropdown>
    )
  }

  handleRemoveClick(index, event) {
    const { templateFields } = this.props
    var modifiedFields = [...templateFields]
    modifiedFields.splice(index, 1)
    this.onFieldsChanged(modifiedFields)

    if (event) {
      event.stopPropagation()
    }
  }

  renderRemoveBtn(index) {
    return (
      <MDBIcon
        icon='minus-circle'
        onClick={this.handleRemoveClick.bind(this, index)}
      />
    )
  }

  findAvailableAttribute(field) {
    const { attributes } = this.props

    return attributes.find(
      (attribute) => attribute.name === changeCase.titleCase(field.name)
    )
  }

  handleStaticTextChange(index, event) {
    const value = event.target.value
    this.handleFieldChange(index, ADD_TEXT_HEADER, value, false)
  }

  handleStaticTextClick(event) {
    event.stopPropagation()
  }

  renderFieldOrInput(field, index) {
    const { allAvailableFields } = this.props
    const attribute = this.findAvailableAttribute(field)
    var fields = [...allAvailableFields, 'ProductType']
    return fields.includes(field.name) ? (
      !_.isEmpty(attribute) ? (
        attribute.columnName
      ) : (
        changeCase.titleCase(field.name)
      )
    ) : (
      <MDBInput
        placeholder='Enter text here'
        value={field.value}
        autoFocus
        onChange={this.handleStaticTextChange.bind(this, index)}
        onClick={this.handleStaticTextClick.bind(this)}
      />
    )
  }

  renderField(field, index) {
    const { duplicates, emptyStaticTexts } = this.props
    const error =
      duplicates.indexOf(field.value) >= 0 ||
      emptyStaticTexts.indexOf(field.value) >= 0

    return (
      <MDBDropdown key={index}>
        <MDBDropdownToggle
          className={`field-menu-toggle ${error ? 'error' : ''}`}
          tag='div'
        >
          {this.renderRemoveBtn(index)} {this.renderFieldOrInput(field, index)}{' '}
          {<MDBIcon size='lg' icon='caret-down' />}
        </MDBDropdownToggle>
        {this.renderDropdownMenu(index)}{' '}
      </MDBDropdown>
    )
  }

  handleResetClick() {
    const { defaultFields } = this.props
    this.onFieldsChanged(defaultFields)
  }

  renderResetBtn() {
    return (
      <MDBBtn
        outline
        tag='span'
        size='sm'
        color='black'
        className='btn-reset'
        onClick={this.handleResetClick.bind(this)}
      >
        Reset to Default
      </MDBBtn>
    )
  }

  render() {
    const { templateFields, duplicates, emptyStaticTexts } = this.props

    return (
      <div className='title-description-builder'>
        {this.renderAddBtn(0)}
        {templateFields.map((field, index) => this.renderField(field, index))}
        {this.renderAddBtn(templateFields.length + 1)}
        <br />
        {this.renderResetBtn()}
        <br />
        {duplicates.length > 0 && (
          <div className='error-message'>There are duplicate attributes.</div>
        )}
        {emptyStaticTexts.length > 0 && (
          <div className='error-message'>There are empty static texts.</div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { templateFields } = props
  const websiteId = websiteIdSelector(state, props)
  const availableFields =
    websiteProductTitlesAvailableFieldsSelector(websiteId)(state)
  const duplicates = findDuplicates(
    templateFields
      .filter((field) => availableFields.includes(field.name))
      .map((field) => field.value)
  )
  const emptyStaticTexts = findEmptyTitles(templateFields)
  return {
    availableFields,
    sortedAvailableFields:
      websiteProductTitlesSortedAvailableFieldsSelector(websiteId)(
        state,
        props
      ) || {},
    allAvailableFields:
      websiteProductTitlesAllAvailableAttributesSelector(websiteId)(state),
    duplicates,
    emptyStaticTexts,
    websiteId,
    websiteFeedIdByChannel: websiteFeedIdByChannelSelector(state, props),
    attributes: websiteAllAttributesSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProductTitles }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TitleDescriptionBuilder)
)
