import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { fetchAccountUsers } from 'actions/accounts'
import QueryString from 'query-string'
import { MDBCard, MDBCardBody } from 'mdbreact'
import MaterialTable from '@material-table/core'
import Avatar from '@material-ui/core/Avatar'
import TableActions from './overview/table_actions'
import DeleteUserModal from './delete_user_modal'
import {
  COLUMNS,
  DELETE_USER_ACTION,
  getTableData,
  generateTableActions,
  generateTableOptions,
  generateTableLocalization
} from './overview_util'
import { accountListSelector } from 'selectors/user'
import { accountsSelector } from 'selectors/accounts'

export class UsersOverview extends Component {
  tableRef = React.createRef()

  static propTypes = {
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    accounts: PropTypes.object.isRequired,
    fetchAccountUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      windowHeight: 0,
      accountId: null,
      deleteModalOpen: false,
      rowData: null
    }
  }

  handleWindowResize() {
    this.setState({ windowHeight: window.innerHeight })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    this.handleWindowResize()

    const { accountList } = this.props
    if (accountList.length === 1) {
      this.setState({ accountId: accountList[0].id })
      this.tableRef.current.onQueryChange()
    }

    const qs = QueryString.parse(this.props.location.search)
    if (qs.account_id) {
      this.setState({ accountId: parseInt(qs.account_id) })
      this.tableRef.current.onQueryChange()
    }
  }

  renderNameWithAvatar(rowData) {
    return (
      <div className='avatar-name-container'>
        <Avatar
          alt={rowData.name}
          src={rowData.imageUrl}
          style={{ width: '30px', height: '30px' }}
        />
        <span>{rowData.name}</span>
      </div>
    )
  }

  getDisplayColumns() {
    var displayedColumnsData = []
    COLUMNS.forEach((columnData, index) => {
      displayedColumnsData.push({
        ...columnData,
        render: (rowData) => (
          <span id={`${columnData.field}${index}${rowData.id}`}>
            {rowData[columnData.field]
              ? columnData.field === 'name'
                ? this.renderNameWithAvatar(rowData)
                : rowData[columnData.field]
              : '-'}
          </span>
        )
      })
    })

    return displayedColumnsData
  }

  async fetchAccountUsers(query) {
    const { accountId } = this.state
    const { fetchAccountUsers } = this.props

    if (accountId) {
      await fetchAccountUsers(accountId)
    }

    const { accounts } = this.props
    return getTableData(accountId, accounts, query)
  }

  checkIfLastUser(actionName, rowData) {
    if (
      actionName !== DELETE_USER_ACTION ||
      rowData.lastLoggedIn.includes('Invitation')
    ) {
      return false
    }

    const { accounts } = this.props
    const { accountId } = this.state

    const account = accounts[accountId]
    return (
      account &&
      account.users.filter((user) => !user.lastLoggedIn.includes('Invitation'))
        .length === 1
    )
  }

  handleAccountIdChanged(accountId) {
    this.setState({ accountId })
    this.tableRef.current.onQueryChange()
  }

  handleDeleteUserClick(rowData) {
    this.setState({ deleteModalOpen: true, rowData })
  }

  renderTableAction(actionName, rowData) {
    const { accountId } = this.state
    const { accountList } = this.props

    return (
      <TableActions
        actionName={actionName}
        accountId={accountId}
        accountList={accountList}
        rowData={rowData}
        handleAccountIdChanged={this.handleAccountIdChanged.bind(this)}
        handleDeleteUserClick={this.handleDeleteUserClick.bind(this)}
      />
    )
  }

  generateTableAction(actionName, position, tooltip) {
    return {
      position: position,
      action: (rowData) =>
        this.checkIfLastUser(actionName, rowData)
          ? null
          : {
              icon: () => this.renderTableAction(actionName, rowData),
              tooltip: tooltip
            }
    }
  }

  getTableActions() {
    const tableActions = generateTableActions().map((action) =>
      this.generateTableAction(
        action.actionName,
        action.position,
        action.tooltip
      )
    )

    return tableActions
  }

  renderAccountUsersTable() {
    const { accountId, windowHeight } = this.state

    return (
      <MaterialTable
        tableRef={this.tableRef}
        columns={this.getDisplayColumns()}
        data={this.fetchAccountUsers.bind(this)}
        actions={this.getTableActions()}
        options={generateTableOptions(windowHeight)}
        localization={generateTableLocalization(accountId)}
      />
    )
  }

  handleDeleteModalClose() {
    this.setState({ deleteModalOpen: false })
    this.tableRef.current.onQueryChange()
  }

  render() {
    const { deleteModalOpen, accountId, rowData } = this.state

    return (
      <>
        <MDBCard className='accounts-settings'>
          <MDBCardBody>{this.renderAccountUsersTable()}</MDBCardBody>
        </MDBCard>
        {deleteModalOpen && (
          <DeleteUserModal
            deleteModalOpen={deleteModalOpen}
            accountId={accountId}
            rowData={rowData}
            handleDeleteModalClose={this.handleDeleteModalClose.bind(this)}
          />
        )}
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  accountList: accountListSelector(state, props),
  accounts: accountsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchAccountUsers }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersOverview)
)
