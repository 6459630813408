import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBtn } from 'mdbreact'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import GenericContent from 'components/websites/workflow/generic_content'
import { googleAuthRequestToken } from 'util/google/google_apis'

export class GoogleUserContext extends Component {
  static propTypes = {
    scope: PropTypes.string.isRequired,
    grantedScope: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    authoriseText: PropTypes.string,
    help: PropTypes.node,
    email: PropTypes.string,
    googleAuthRequestToken: PropTypes.func.isRequired,
    signInButtonText: PropTypes.string.isRequired
  }

  static defaultProps = {
    googleAuthRequestToken,
    signInButtonText: 'Sign In With Google'
  }

  constructor(props) {
    super(props)
    this.state = { busy: false }
  }

  componentDidMount() {
    const { scope, grantedScope } = this.props
    if (this.scopeGranted(scope, grantedScope)) {
      this.fireCallback()
    }
  }

  componentDidUpdate(prevProps) {
    const { scope, grantedScope, email } = this.props
    if (
      (prevProps.grantedScope !== grantedScope || prevProps.email !== email) &&
      this.scopeGranted(scope, grantedScope)
    ) {
      this.fireCallback()
    }
  }

  async fireCallback() {
    const { onSuccess } = this.props
    if (onSuccess) {
      this.setState({ busy: true })
      await onSuccess()
      this.setState({ busy: false })
    }
  }

  async startLogin() {
    const { scope, googleAuthRequestToken } = this.props
    try {
      this.setState({ busy: true })
      await googleAuthRequestToken(scope)
    } catch (error) {
      // Ignore
    }
    this.setState({ busy: false })
  }

  renderGoogleLogin(content, authoriseText) {
    return (
      <>
        {authoriseText && <p>{authoriseText}</p>}
        <div className='loginButton' onClick={this.startLogin.bind(this)}>
          {content}
        </div>
      </>
    )
  }

  renderLoginLink() {
    const { email } = this.props
    return (
      <div className='logoutButton'>
        Signed in as {email}. Not the Google account you want to use?{' '}
        {this.renderGoogleLogin(
          <MDBBtn className='btn-href-style'>Change</MDBBtn>,
          null
        )}
      </div>
    )
  }

  scopeGranted(requested, granted) {
    const scopes = requested.split(' ')
    for (var i = 0; i < scopes.length; ++i) {
      if (granted.search(scopes[i]) < 0) {
        return false
      }
    }

    return true
  }

  renderGenericContent() {
    const { signInButtonText, authoriseText, help } = this.props

    return (
      <GenericContent
        component={this.renderGoogleLogin(
          <MDBBtn className={'next-action'} color='primary'>
            {signInButtonText}
          </MDBBtn>,
          authoriseText
        )}
        help={help}
      />
    )
  }

  render() {
    const { grantedScope, scope, children } = this.props
    var scopeGranted = this.scopeGranted(scope, grantedScope)
    if (scopeGranted) {
      return (
        <div>
          {this.renderLoginLink()}
          {children}
        </div>
      )
    } else {
      return this.renderGenericContent()
    }
  }
}

const mapStateToProps = (state) => ({
  grantedScope: state.google.user.scope,
  email: state.google.user.email
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GoogleUserContext)
