import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBBtn, MDBIcon } from 'mdbreact'
import ProductOptimiserModal from './common/product_optimiser_modal'
import { ONE_TIME_PRODUCT_DATA_IMPORT } from './common/table_actions_util'

export class ImportProductData extends Component {
  static propTypes = {
    onSaveProductAttributesReplacements: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleSaveProductAttributesReplacements() {
    const { onSaveProductAttributesReplacements } = this.props
    onSaveProductAttributesReplacements()
  }

  renderModal() {
    const { isOpen } = this.state

    return (
      <ProductOptimiserModal
        isOpen={isOpen}
        headerText={'Import product data optimizations'}
        bodyComponent={ONE_TIME_PRODUCT_DATA_IMPORT}
        onModalStateChange={() => this.setState({ isOpen: !isOpen })}
        onSaveProductAttributesReplacements={this.handleSaveProductAttributesReplacements.bind(
          this
        )}
      />
    )
  }

  render() {
    return (
      <div>
        <MDBBtn
          tag='span'
          color='link'
          className='btn-column'
          onClick={() => this.setState({ isOpen: true })}
        >
          <MDBIcon icon={'file-import'} />
          Import
        </MDBBtn>
        {this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportProductData)
)
