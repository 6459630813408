import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { listMerchantCenterAccounts } from 'actions/microsoft/merchant_center'
import ApiSource from './common/api_source'
import { merchantCenterAccountsSelector } from 'selectors/microsoft/merchant_center'
import { CHANNEL_MICROSOFT } from 'util/supported_channels'

export const MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE = 'microsoft_content_api'
const MICROSOFT_MC_SCOPES = ['https://ads.microsoft.com/msads.manage']

export class MicrosoftContentApiSource extends Component {
  static propTypes = {
    merchantCenterAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleApiSourceChange: PropTypes.func.isRequired,
    listMerchantCenterAccounts: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      mcAccounts: [],
      mcAccountId: ''
    }
  }

  async handleOnScopeGranted() {
    const { listMerchantCenterAccounts } = this.props

    try {
      const mcAccounts = await listMerchantCenterAccounts()
      this.setState({ mcAccounts })
    } catch (error) {}
  }

  async handleAccountChange(mcAccount, accessToken, headers, mappings) {
    this.setState({ mcAccountId: mcAccount.value })

    if (headers && headers.length > 0) {
      const { merchantCenterAccounts, handleApiSourceChange } = this.props
      const merchantCenter = merchantCenterAccounts.find(
        (mc) => mc.id.toString() === mcAccount.value.toString()
      )
      const { customerId, customerAccountId } = merchantCenter

      handleApiSourceChange(
        {
          source: MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE,
          accessToken,
          customerId,
          customerAccountId,
          accountId: mcAccount.value
        },
        headers,
        mappings
      )
    }
  }

  render() {
    const { mcAccounts, mcAccountId } = this.state

    return (
      <ApiSource
        channel={CHANNEL_MICROSOFT}
        sourceName={MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE}
        scopes={MICROSOFT_MC_SCOPES}
        asyncAccountName=''
        asyncAccounts={[]}
        asyncAccountId={''}
        accountName='Merchant Center Account'
        accounts={mcAccounts}
        accountId={mcAccountId}
        handleOnScopeGranted={this.handleOnScopeGranted.bind(this)}
        handleAccountChange={this.handleAccountChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  merchantCenterAccounts: merchantCenterAccountsSelector(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listMerchantCenterAccounts }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MicrosoftContentApiSource)
)
