import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem
} from 'mdbreact'
import { attributeCategoryNames } from '../common/attributes_util'

export default class AvailableColumnSelectionSection extends Component {
  static propTypes = {
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAddAllColumnsClick: PropTypes.func.isRequired,
    handleAddColumnClick: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      searchTerm: '',
      columnCategoryNames: [],
      selectedCategory: ''
    }
  }

  componentDidMount() {
    const { availableColumns } = this.props
    const columnCategoryNames = attributeCategoryNames(availableColumns)

    this.setState({
      columnCategoryNames: columnCategoryNames,
      selectedCategory: columnCategoryNames[0]
    })
  }

  handleSearchTermChanged(event) {
    this.setState({ searchTerm: event.target.value })
  }

  renderSearchBox() {
    const { searchTerm } = this.state

    return (
      <MDBCol>
        <>
          <MDBInput
            placeholder={`Search columns`}
            value={searchTerm}
            onChange={this.handleSearchTermChanged.bind(this)}
          />
          <MDBIcon icon={'search'} />
        </>
      </MDBCol>
    )
  }

  getFilteredColumns() {
    const { searchTerm, selectedCategory } = this.state
    const { availableColumns } = this.props
    var filteredColumns = []

    if (_.isEmpty(searchTerm)) {
      filteredColumns = availableColumns.filter(
        (column) => column.columnCategoryName === selectedCategory
      )
    } else {
      filteredColumns = availableColumns.filter((column) =>
        column.columnName.toLowerCase().match(searchTerm.toLowerCase())
      )
    }

    return filteredColumns
  }

  handleAddAllClick() {
    const { handleAddAllColumnsClick } = this.props
    const filteredColumns = this.getFilteredColumns()
    handleAddAllColumnsClick(filteredColumns)
  }

  renderAddAllBtn() {
    return (
      <MDBBtn
        id='add-all-btn'
        onClick={this.handleAddAllClick.bind(this)}
        tag='span'
      >
        Add All
      </MDBBtn>
    )
  }

  handleCategoryItemClick(name) {
    this.setState({ selectedCategory: name })
  }

  renderCategories() {
    const { columnCategoryNames, selectedCategory } = this.state
    const categories = columnCategoryNames.map((name) => (
      <MDBListGroupItem
        key={name}
        hover
        active={selectedCategory === name}
        onClick={this.handleCategoryItemClick.bind(this, name)}
      >
        {name}
        <MDBIcon icon='angle-right' />
      </MDBListGroupItem>
    ))

    return <MDBListGroup>{categories}</MDBListGroup>
  }

  handleAddColumnClick(columnName) {
    const { handleAddColumnClick } = this.props
    handleAddColumnClick(columnName)
  }

  renderColumnRows(column) {
    const { selectedColumns } = this.props

    return (
      <MDBRow key={`${column.columnName}-available`}>
        <MDBCol className='column-name-col'>{column.columnName}</MDBCol>
        <MDBCol>
          {selectedColumns.includes(column.columnName) ? (
            <span className='added-span'>Added</span>
          ) : (
            <MDBBtn
              key={`${column.columnName}-available`}
              onClick={this.handleAddColumnClick.bind(this, column.columnName)}
              tag='span'
            >
              Add
            </MDBBtn>
          )}
        </MDBCol>
      </MDBRow>
    )
  }

  renderSelectedCategoryOrSearchedColumns() {
    const filteredColumns = this.getFilteredColumns()

    return _.sortBy(filteredColumns, [
      'columnCategoryPosition',
      'columnPosition'
    ]).map((column) => this.renderColumnRows(column))
  }

  render() {
    const { searchTerm } = this.state
    const searching = !_.isEmpty(searchTerm)

    return (
      <>
        <MDBRow>
          {this.renderSearchBox()}
          <MDBCol>{this.renderAddAllBtn()}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className={searching ? 'hide-categories' : ''}>
            {this.renderCategories()}
          </MDBCol>
          <MDBCol className={searching ? 'search-column' : ''}>
            {this.renderSelectedCategoryOrSearchedColumns()}
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}
