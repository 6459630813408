import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact'
import Avatar from '@material-ui/core/Avatar'
import { setWorkEmailModalOpen } from 'actions/app'
import ChargeBeeCheckout from './charge_bee_checkout'
import {
  loggedInSelector,
  emailSelector,
  currentUserProfileSelector,
  workEmailSelector
} from 'selectors/user'
import { googleAuthLogin, googleAuthLogout } from 'util/google/google_apis'
import { appIdSelector, isEmbeddedAppSelector } from 'selectors/app'
import { bindActionCreators } from 'redux'

export class SettingsMenu extends Component {
  static propTypes = {
    showPaymentInformation: PropTypes.bool,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    invisible: PropTypes.bool.isRequired,
    userEmail: PropTypes.string.isRequired,
    userProfile: PropTypes.object.isRequired,
    setWorkEmailModalOpen: PropTypes.func.isRequired,
    workEmail: PropTypes.string.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired
  }

  static defaultProps = {
    onLogin: googleAuthLogin,
    onLogout: googleAuthLogout
  }

  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
    this.handleSignOut = this.handleSignOut.bind(this)
    this.handleUpdatePayment = this.handleUpdatePayment.bind(this)
    this.handlePaymentClose = this.handlePaymentClose.bind(this)
    this.handleInviteUser = this.handleInviteUser.bind(this)
    this.state = {
      dropdownOpen: false,
      updatePaymentOpen: false,
      inviteUserModalOpen: false
    }
  }

  handlePaymentClose() {
    this.setState({ updatePaymentOpen: false })
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  handleSignIn() {
    const { onLogin } = this.props
    onLogin()
  }

  handleSignOut() {
    const { onLogout, appId, location, history } = this.props
    const qsAppId = QueryString.parse(location.search).app_id || 'feed_ops'

    onLogout().then(() => {
      appId !== 'sign_up'
        ? history.push(`/${appId}/sign_in`)
        : window.open(`/${qsAppId}/sign_in`, '_self')
    })
  }

  handleUpdatePayment() {
    this.setState({
      updatePaymentOpen: true
    })
  }

  handleInviteUser() {
    this.setState({ inviteUserModalOpen: !this.state.inviteUserModalOpen })
  }

  renderPaymentInformationMenuItem() {
    const { showPaymentInformation } = this.props
    return (
      showPaymentInformation && (
        <div>
          <MDBDropdownItem color='link' onClick={this.handleUpdatePayment}>
            Payment Information
          </MDBDropdownItem>
          <ChargeBeeCheckout
            editorOpen={this.state.updatePaymentOpen}
            onSuccess={this.handlePaymentClose}
            onCancel={this.handlePaymentClose}
          />
          <hr />
        </div>
      )
    )
  }

  openWorkEmailModal() {
    const { setWorkEmailModalOpen } = this.props
    setWorkEmailModalOpen(true)
  }

  renderWorkEmailMenuItem() {
    const { workEmail } = this.props
    return (
      <div>
        Work Email
        <MDBDropdownItem
          color='link'
          onClick={this.openWorkEmailModal.bind(this)}
        >
          <MDBIcon icon='edit' /> {workEmail}
        </MDBDropdownItem>
        <hr />
      </div>
    )
  }

  renderLoggedInMenu() {
    const { isEmbeddedApp } = this.props
    if (isEmbeddedApp) {
      return (
        <MDBDropdownMenu right>
          {this.renderWorkEmailMenuItem()}
        </MDBDropdownMenu>
      )
    } else {
      return (
        <MDBDropdownMenu right>
          {this.renderPaymentInformationMenuItem()}
          {this.renderWorkEmailMenuItem()}
          <MDBDropdownItem color='link' onClick={this.handleSignOut}>
            <MDBIcon icon='sign-out' /> Sign Out
          </MDBDropdownItem>
        </MDBDropdownMenu>
      )
    }
  }

  renderLoggedOutMenu() {
    return (
      <MDBDropdownMenu right>
        <MDBDropdownItem color='link' onClick={this.handleSignIn}>
          Sign In
        </MDBDropdownItem>
      </MDBDropdownMenu>
    )
  }

  renderToggleContent() {
    const { userProfile, userEmail, workEmail } = this.props
    const { firstName, lastName, imageUrl } = userProfile

    return (
      <div className='toggle-content-container'>
        <div className='user-info-container'>
          {firstName + ' ' + lastName}
          <br />
          <div>{userEmail || workEmail}</div>
        </div>
        <Avatar
          src={imageUrl || '/broken-image.jpg'}
          alt={firstName || lastName}
          className='profile-avatar'
        />
      </div>
    )
  }

  renderDropdownToggle() {
    return (
      <MDBDropdownToggle
        color={'color-workflow'}
        className={'nav-link'}
        onClick={this.toggle}
      >
        {this.renderToggleContent()}
      </MDBDropdownToggle>
    )
  }

  render() {
    const { loggedIn, invisible, invisibleWhenLoggedOut } = this.props
    return (
      <MDBDropdown
        className={
          invisible || !(invisibleWhenLoggedOut || loggedIn)
            ? 'settings-menu invisible'
            : 'settings-menu'
        }
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        {this.renderDropdownToggle()}
        {loggedIn ? this.renderLoggedInMenu() : this.renderLoggedOutMenu()}
      </MDBDropdown>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  loggedIn: loggedInSelector(state),
  userEmail: emailSelector(state, props),
  userProfile: currentUserProfileSelector(state, props),
  workEmail: workEmailSelector(state, props),
  appId: appIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setWorkEmailModalOpen }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsMenu)
)
