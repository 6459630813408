import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import FilterPopoverHeader from 'components/feed_ops/common/popover/filter_popover_header'
import FilterOptionsDropdown from 'components/feed_ops/common/popover/filter_options_dropdown'
import EnumListItem from './enum_list_item'
import AttributeDatePicker from './attribute_date_picker'
import GoogleProductCategoryDropdown from './google_product_category_dropdown'
import { getSelectedFilterOptionAndValues } from 'components/feed_ops/common/popover/popover_util'
import {
  TEXT_OPERATORS,
  DATE_OPERATORS,
  GOOGLE_PRODUCT_CATEGORY_OPERATORS,
  NUMERIC_FORMATS,
  filterTypeRequiresValue
} from '../filter_operations'

export default class ProductFilterPopoverContainer extends Component {
  static propTypes = {
    selectedAttribute: PropTypes.object.isRequired,
    appliedFilter: PropTypes.object,
    handleClosePopover: PropTypes.func,
    handleApplyFilterClick: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      selectedFilterOption: '',
      values: '',
      showValidationErrors: false
    }
  }

  componentDidMount() {
    const { appliedFilter, selectedAttribute } = this.props
    const updatedStateValues = getSelectedFilterOptionAndValues(
      appliedFilter,
      selectedAttribute
    )
    this.setState({
      selectedFilterOption: updatedStateValues.selectedFilterOption,
      values: updatedStateValues.values
    })
  }

  handleCloseFilterPopover() {
    const { handleClosePopover } = this.props

    if (handleClosePopover) {
      handleClosePopover()
    }
  }

  renderFilterPopoverHeader() {
    const { selectedAttribute } = this.props
    return (
      <FilterPopoverHeader
        attributeName={selectedAttribute.columnName || selectedAttribute.name}
        handleCloseFilterPopover={this.handleCloseFilterPopover.bind(this)}
      />
    )
  }

  handleFilterOptionItemClick(selectedFilterOption) {
    this.setState({ selectedFilterOption: selectedFilterOption })
  }

  renderFilterOptionsDropdown() {
    const { selectedAttribute } = this.props
    const { filterOptions } = selectedAttribute
    const { selectedFilterOption } = this.state

    return (
      <FilterOptionsDropdown
        selectedFilterOption={selectedFilterOption}
        filterOptions={filterOptions}
        handleFilterOptionItemClick={this.handleFilterOptionItemClick.bind(
          this
        )}
      />
    )
  }

  requiresValue() {
    const { selectedFilterOption } = this.state
    return filterTypeRequiresValue(selectedFilterOption)
  }

  handleEnumOrDateValueChanged(values) {
    this.setState({ values })
  }

  handleFilterValuesChange(event) {
    this.setState({ values: event.target.value })
  }

  renderInputOrTextarea(isTextArea) {
    const { values } = this.state

    return (
      <MDBInput
        placeholder={isTextArea ? 'Values' : 'Value'}
        type={isTextArea ? 'textarea' : 'number'}
        rows='3'
        value={values}
        onChange={this.handleFilterValuesChange.bind(this)}
      />
    )
  }

  filterValuesEmpty() {
    const { values } = this.state
    return _.isEmpty(values.replace(/^\s+|\s+$/g, ''))
  }

  renderErrorText(isTextArea) {
    const { showValidationErrors } = this.state
    if (!showValidationErrors) {
      return null
    }

    const isEmpty = this.filterValuesEmpty()

    return isEmpty ? (
      <span className={isEmpty ? 'error-text' : ''}>
        {isTextArea ? 'Values' : 'Value'} cannot be empty.
      </span>
    ) : null
  }

  renderEnumListItem(hasEnumValues, selectedAttribute, values) {
    return hasEnumValues ? (
      <EnumListItem
        attribute={selectedAttribute}
        filterValues={values}
        enumValueChanged={this.handleEnumOrDateValueChanged.bind(this)}
      />
    ) : null
  }

  renderDatePicker(isAttributeDate, values) {
    return isAttributeDate ? (
      <AttributeDatePicker
        filterValue={values}
        dateValueChanged={this.handleEnumOrDateValueChanged.bind(this)}
      />
    ) : null
  }

  renderGoogleProductCategories(isGoogleProductCategory, values) {
    return (
      isGoogleProductCategory && (
        <GoogleProductCategoryDropdown
          filterValue={values}
          valueChanged={this.handleEnumOrDateValueChanged.bind(this)}
        />
      )
    )
  }

  conditionallyRenderInput(isTextArea) {
    return (
      <>
        {this.renderInputOrTextarea(isTextArea)}
        {isTextArea && (
          <span className='explanation'>Enter one value per line</span>
        )}
        <br />
        {this.renderErrorText(isTextArea)}
      </>
    )
  }

  renderFilterValues() {
    const { selectedAttribute } = this.props
    const { selectedFilterOption, values } = this.state
    const isTextArea =
      TEXT_OPERATORS.includes(selectedFilterOption) &&
      !NUMERIC_FORMATS.includes(selectedAttribute.format)
    const hasEnumValues = !_.isEmpty(selectedAttribute.enumValues)
    const isAttributeDate = DATE_OPERATORS.includes(selectedFilterOption)
    const isGoogleProductCategory = _.isEqual(
      selectedAttribute.filterOptions,
      GOOGLE_PRODUCT_CATEGORY_OPERATORS
    )

    return (
      this.requiresValue() && (
        <>
          {isTextArea && (
            <span className='explanation'>Any of the following values</span>
          )}
          {this.renderEnumListItem(hasEnumValues, selectedAttribute, values)}
          {this.renderDatePicker(isAttributeDate, values)}
          {this.renderGoogleProductCategories(isGoogleProductCategory, values)}
          {!(hasEnumValues || isAttributeDate || isGoogleProductCategory) &&
            this.conditionallyRenderInput(isTextArea)}
        </>
      )
    )
  }

  handleApplyFilter() {
    const { showValidationErrors, selectedFilterOption, values } = this.state

    if (!this.requiresValue() || !this.filterValuesEmpty()) {
      const { handleApplyFilterClick, selectedAttribute } = this.props
      handleApplyFilterClick(
        selectedAttribute.name,
        selectedFilterOption,
        this.requiresValue()
          ? values.split('\n').filter((v) => !_.isEmpty(v.trim()))
          : []
      )
    }

    if (!showValidationErrors) {
      this.setState({ showValidationErrors: true })
    }
  }

  renderApplyFilterBtn() {
    var disabled = this.requiresValue() && this.filterValuesEmpty()
    return (
      <MDBBtn
        tag='span'
        className='apply-filter-btn'
        disabled={disabled}
        onClick={this.handleApplyFilter.bind(this)}
      >
        Apply
      </MDBBtn>
    )
  }

  render() {
    return (
      <>
        {this.renderFilterPopoverHeader()}
        <MDBRow>
          <MDBCol>{this.renderFilterOptionsDropdown()}</MDBCol>
        </MDBRow>
        <MDBRow className='filter-values-row'>
          <MDBCol>{this.renderFilterValues()}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>{this.renderApplyFilterBtn()}</MDBCol>
        </MDBRow>
      </>
    )
  }
}
