import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from 'react-select'

export default class GoogleProductCategorySelect extends Component {
  static propTypes = {
    googleTaxonomyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMultiSelectEnabled: PropTypes.bool.isRequired,
    isSelectDisabled: PropTypes.bool.isRequired,
    valueChanged: PropTypes.func.isRequired
  }

  static defaultProps = {
    isMultiSelectEnabled: false,
    isSelectDisabled: false
  }

  constructor(props) {
    super(props)
    this.state = {
      filteredOptions: [],
      options: [],
      selectedOption: null,
      initialOption: null
    }
  }

  componentDidMount() {
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    const { selectedValue } = this.props
    if (!_.isEqual(prevProps.selectedValue, selectedValue)) {
      this.setDefaults()
    }
  }

  setDefaults() {
    const { selectedValue } = this.props
    if (selectedValue && !_.isEmpty(selectedValue)) {
      this.setState({
        initialOption: selectedValue,
        selectedOption: selectedValue
      })
    }
    this.filterTaxonomyOptions('')
  }

  updateOptions(filteredOptions, newSearch = true) {
    const { options } = this.state
    const updatedOptionsLength = newSearch ? 100 : options.length + 100
    if (!_.isEmpty(filteredOptions)) {
      const sliceIndex =
        updatedOptionsLength > filteredOptions.length
          ? filteredOptions.length
          : updatedOptionsLength
      this.setState({ options: filteredOptions.slice(0, sliceIndex) })
    }
  }

  handleScrollToBottom() {
    const { filteredOptions } = this.state
    this.updateOptions(filteredOptions, false)
  }

  filterTaxonomyOptions(searchTerm) {
    const { googleTaxonomyOptions } = this.props
    var newFilteredOptions = []
    if (_.isEmpty(searchTerm)) {
      newFilteredOptions = googleTaxonomyOptions || []
    } else {
      googleTaxonomyOptions.forEach((option) => {
        if (option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
          newFilteredOptions.push(option)
        }
      })
    }
    this.updateOptions(newFilteredOptions)
    this.setState({ filteredOptions: newFilteredOptions })
  }

  handleSearchInput(searchTerm) {
    this.filterTaxonomyOptions(searchTerm)
  }

  handleTaxonomySelectionChange(selectedOptions) {
    const { valueChanged } = this.props
    valueChanged(
      Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]
    )
    this.setState({
      selectedOption: selectedOptions,
      initialOption: selectedOptions
    })
  }

  render() {
    const { isSelectDisabled, isMultiSelectEnabled } = this.props
    const { options, selectedOption } = this.state

    return (
      <Select
        isDisabled={isSelectDisabled}
        isMulti={isMultiSelectEnabled}
        isClearable={!isMultiSelectEnabled}
        isSearchable={true}
        options={options}
        value={selectedOption}
        backspaceRemovesValue={false}
        placeholder='Select / Search'
        classNamePrefix='taxonomy'
        onMenuScrollToBottom={this.handleScrollToBottom.bind(this)}
        onInputChange={this.handleSearchInput.bind(this)}
        onChange={this.handleTaxonomySelectionChange.bind(this)}
        styles={{
          valueContainer: (base) => ({
            ...base,
            direction: 'rtl'
          })
        }}
      />
    )
  }
}
