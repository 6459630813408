import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { googleAuthRequestToken } from 'util/google/google_apis'
import { microsoftAuthRequestToken } from 'util/microsoft/microsoft_apis'
import { MDBBtn } from 'mdbreact'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'

import { CHANNEL_GOOGLE } from 'util/supported_channels'
import './authentication_buttons.scss'

export class AuthenticationButtons extends Component {
  static propTypes = {
    channel: PropTypes.string.isRequired,
    btnName: PropTypes.string.isRequired,
    requiredScopes: PropTypes.arrayOf(PropTypes.string).isRequired,
    grantedScopes: PropTypes.arrayOf(PropTypes.string).isRequired,
    grantedEmail: PropTypes.string.isRequired,
    onScopeGranted: PropTypes.func.isRequired,
    googleAuthRequestToken: PropTypes.func.isRequired,
    microsoftAuthRequestToken: PropTypes.func.isRequired
  }

  static defaultProps = {
    googleAuthRequestToken,
    microsoftAuthRequestToken
  }

  constructor(props) {
    super(props)
    this.state = {
      authenticating: false
    }
  }

  checkIfScopeGranted() {
    const { requiredScopes, grantedScopes } = this.props
    return requiredScopes.every((item) => grantedScopes.includes(item))
  }

  componentDidMount() {
    if (this.checkIfScopeGranted()) {
      this.startLogin()
    }
  }

  async startLogin() {
    const {
      channel,
      googleAuthRequestToken,
      microsoftAuthRequestToken,
      requiredScopes,
      onScopeGranted
    } = this.props

    this.setState({ authenticating: true })
    try {
      if (channel === CHANNEL_GOOGLE) {
        await googleAuthRequestToken(requiredScopes)
      } else {
        await microsoftAuthRequestToken(requiredScopes)
      }
      onScopeGranted()
    } catch (error) {}
    this.setState({ authenticating: false })
  }

  renderBtn(className, name) {
    return (
      <MDBBtn
        tag='span'
        className={className}
        onClick={this.startLogin.bind(this)}
      >
        {name}
      </MDBBtn>
    )
  }

  render() {
    const { authenticating } = this.state
    const { grantedEmail, btnName } = this.props
    const connectBtn = this.checkIfScopeGranted() ? (
      <span>
        Logged in as <span className='granted-email'>{grantedEmail}</span>{' '}
        {this.renderBtn('btn-href-style', '[Change]')}
      </span>
    ) : (
      this.renderBtn('btn-connect', btnName)
    )

    return (
      <>
        <div className='connect-container'>{connectBtn}</div>
        {authenticating && <IndeterminateProgressIndicator />}
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  grantedScopes: state[props.channel].user.scope || [],
  grantedEmail: state[props.channel].user.email
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticationButtons)
)
