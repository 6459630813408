import React from 'react'
export const CHANNELS_FIELD = 'vendors'
export const CONDITIONS_FIELD = 'conditions'
export const ACTIONS_FIELD = 'actions'
export const DESCRIPTION_FIELD = 'description'
export const DRAFT_STATUS_FIELD = 'draftStatus'
export const COLUMNS = [
  { title: 'Channel(s)', field: CHANNELS_FIELD, width: '10%' },
  { title: 'Condition(s)', field: CONDITIONS_FIELD, width: '27%' },
  { title: 'Action(s)', field: ACTIONS_FIELD, width: '27%' },
  { title: 'Description', field: DESCRIPTION_FIELD, width: '27%' },
  { title: '', field: DRAFT_STATUS_FIELD, width: '9%' }
]

export const ORDER_NOTICE_ACTION = 'Order Notice'
export const PREVIEW_RULES_ACTION = 'Preview Rules'
export const ADD_RULE_ACTION = 'Add Rule'
export const EDIT_RULE_ACTION = 'Edit Rule'
export const DELETE_RULE_ACTION = 'Delete Rule'
export const REORDER_RULE_ACTION = 'Reorder Rule'

export const ACTION_NAMES_TO_DISABLE = [EDIT_RULE_ACTION, DELETE_RULE_ACTION]

export const generateTableOptions = (windowHeight) => {
  return {
    idSynonym: 'id',
    actionsColumnIndex: -1,
    columnResizable: false,
    debounceInterval: 1000,
    defaultExpanded: true,
    draggable: false,
    grouping: false,
    headerStyle: { position: 'sticky', top: 0 },
    maxBodyHeight: `${windowHeight - 325}px`,
    minBodyHeight: `${windowHeight - 325}px`,
    paging: false,
    showTitle: false,
    search: false,
    sorting: false,
    tableLayout: 'fixed',
    toolbar: true
  }
}

export const generateTableLocalization = () => {
  return {
    header: {
      actions: ''
    },
    body: {
      emptyDataSourceMessage: (
        <span className='no-data-message'>No rules found.</span>
      )
    }
  }
}

const toolbarActions = () => {
  return [ORDER_NOTICE_ACTION, PREVIEW_RULES_ACTION, ADD_RULE_ACTION].map(
    (action) => ({
      actionName: action,
      position: 'toolbar',
      tooltip: null
    })
  )
}

const rowActions = () => {
  return [EDIT_RULE_ACTION, DELETE_RULE_ACTION, REORDER_RULE_ACTION].map(
    (action) => ({
      actionName: action,
      position: 'row',
      tooltip: null
    })
  )
}

export const generateTableActions = () => {
  return [...toolbarActions(), ...rowActions()]
}

export const updateDraftRuleStatus = (rules, rule, status) => {
  return rules.map((r) => {
    return r.position === rule.position ? { ...rule, draftStatus: status } : r
  })
}

export const removeDraftRule = (rules, rule) => {
  return rules
    .filter((r) => r.position !== rule.position)
    .map((r, index) => ({
      ...r,
      position: index + 1
    }))
}

export const generatePreviewTableOptions = (windowHeight) => {
  const modalContent = document.querySelector(
    '.preview-rules-modal .modal-content'
  )
  
  const modalContentHeight = modalContent ? modalContent.clientHeight : 0

  return {
    idSynonym: 'itemId',
    actionsColumnIndex: -1,
    columnResizable: false,
    debounceInterval: 1000,
    defaultExpanded: true,
    draggable: false,
    grouping: false,
    headerStyle: { position: 'sticky', top: 0 },
    maxBodyHeight: `${modalContentHeight - 408}px`,
    minBodyHeight: `${modalContentHeight - 408}px`,
    paging: false,
    showTitle: false,
    search: false,
    sorting: false,
    toolbar: false
  }
}

export const generatePreviewTableLocalization = () => {
  return {
    header: {
      actions: ''
    },
    body: {
      emptyDataSourceMessage: (
        <span className='no-data-message'>
          No items found matching your search.
        </span>
      )
    }
  }
}
