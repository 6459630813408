import toastr from 'toastr'
import { SUPER_USER_ONLY } from 'util/router/types'
import { store } from 'containers/ad_platform_context'
import { superUserSelector } from 'selectors/user'

const requireSuperUser = (to, from, next) => {
  if (to.meta[SUPER_USER_ONLY]) {
    const state = store.getState()
    if (!superUserSelector(state)) {
      toastr.error('Only super users can access this page.')
      throw new Error('Only super users can access this page')
    }
  }
  next()
}

export default requireSuperUser
