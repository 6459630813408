import { createSelector } from 'reselect'

export const verticalFieldsSelector = (state) =>
  (state.vertical && state.vertical.fields) || {}

export const verticalFieldSelector = (verticalFieldId) =>
  createSelector(
    [verticalFieldsSelector],
    (fields) => fields[verticalFieldId] || {}
  )
