import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography, IconButton } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { formatNumber } from 'util/format_number'

export default class CustomPagination extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired
  }

  renderRowsPerPage() {
    const { rowsPerPage, rowsPerPageOptions, onRowsPerPageChange } = this.props
    return (
      <FormControl>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          style={{
            lineHeight: '2rem'
          }}
        >
          {rowsPerPageOptions.map((x, i) => (
            <MenuItem value={x} key={i}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  renderNavControls() {
    const { page, rowsPerPage, count, onPageChange } = this.props
    let from = rowsPerPage * page + 1
    let to = rowsPerPage * (page + 1)
    if (to > count) {
      to = count
    }

    return (
      <>
        {' '}
        <IconButton disabled={page === 0} onClick={(e) => onPageChange(e, 0)}>
          <i className='far fa-step-backward' disabled={page === 0} />
        </IconButton>
        <IconButton
          disabled={page === 0}
          onClick={(e) => onPageChange(e, page - 1)}
        >
          <i className='far fa-chevron-left' disabled={page === 0} />
        </IconButton>
        <Typography style={{ lineHeight: '3rem' }}>
          {formatNumber(from, 0)} - {formatNumber(to, 0)} of{' '}
          {count === 'many' ? count : formatNumber(count, 0)}
        </Typography>
        <IconButton
          disabled={count !== 'many' && to >= count}
          onClick={(e) => onPageChange(e, page + 1)}
        >
          <i className='far fa-chevron-right' />
        </IconButton>
        <IconButton
          disabled={count === 'many' || to >= count}
          onClick={(e) => onPageChange(e, count / rowsPerPage - 1)}
        >
          <i className='far fa-step-forward' />
        </IconButton>
      </>
    )
  }

  render() {
    return (
      <Container>
        <Row>
          <Col />
          <Col style={{ display: 'inline-flex' }}>
            {this.renderRowsPerPage()}
            {this.renderNavControls()}
          </Col>
          <Col />
        </Row>
      </Container>
    )
  }
}
