import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { updateAccount } from 'actions/accounts'
import { fetchUserMasterAccounts } from 'actions/users'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
  MDBCardFooter,
  MDBBtn
} from 'mdbreact'
import toastr from 'toastr'
import { accountByAccountIdSelector } from 'selectors/user'

export class AccountDetails extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    updateAccount: PropTypes.func.isRequired,
    fetchUserMasterAccounts: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      accountLabel: '',
      companyUrl: 'https://',
      updatingAccount: false
    }
  }

  componentDidMount() {
    const { account } = this.props

    this.setState({
      accountLabel: account.label,
      companyUrl: account.companyUrl
    })
  }

  handleAccountNameChange(event) {
    this.setState({ accountLabel: event.target.value })
  }

  renderAccountDetails() {
    const { accountLabel } = this.state

    return (
      <>
        <MDBInput
          id='accountLabel'
          label='Account Name'
          value={accountLabel}
          onChange={this.handleAccountNameChange.bind(this)}
        />
        {!accountLabel ? (
          <div className='input-error'>Please enter account name.</div>
        ) : null}
      </>
    )
  }

  handleCompanyUrlChange(event) {
    this.setState({ companyUrl: event.target.value })
  }

  renderCompanyUrl() {
    const { companyUrl } = this.state

    return (
      <MDBInput
        id='companyUrl'
        label='Company URL'
        value={companyUrl}
        onChange={this.handleCompanyUrlChange.bind(this)}
      />
    )
  }

  async handleEditAccount() {
    this.setState({ updatingAccount: true })

    const { accountLabel, companyUrl } = this.state
    const { updateAccount, match, fetchUserMasterAccounts } = this.props

    const accountId = parseInt(match.params.accountId)
    await updateAccount(accountId, accountLabel, companyUrl)
    await fetchUserMasterAccounts()

    toastr.success('Account updated successfully.')
    this.setState({ updatingAccount: false })
  }

  renderFooter() {
    const { accountLabel, updatingAccount } = this.state

    return (
      <MDBCardFooter className='edit-account-card-footer'>
        <MDBBtn
          disabled={!accountLabel || updatingAccount}
          onClick={this.handleEditAccount.bind(this)}
        >
          SAVE
        </MDBBtn>
      </MDBCardFooter>
    )
  }

  render() {
    return (
      <MDBCard className='accounts-settings'>
        <MDBCardBody className='edit-account-card-body'>
          <MDBCardTitle>Edit Account</MDBCardTitle>
          {this.renderAccountDetails()}
          {this.renderCompanyUrl()}
        </MDBCardBody>
        {this.renderFooter()}
      </MDBCard>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  account: accountByAccountIdSelector(parseInt(props.match.params.accountId))(
    state,
    props
  )
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateAccount, fetchUserMasterAccounts }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
)
