import changeCase from 'change-case'
import { isEmbeddedApp } from 'selectors/app'
import {
  WEBSITE_SETTINGS,
  ACCOUNT_SETTINGS
} from 'components/feed_ops/menu_items'

const computeMenuItemTitle = (menuItem, subMenuItem) => {
  return menuItem.title === WEBSITE_SETTINGS
    ? `${WEBSITE_SETTINGS} > ${subMenuItem.title}`
    : menuItem.title === ACCOUNT_SETTINGS
    ? `${ACCOUNT_SETTINGS} > ${subMenuItem.title}`
    : subMenuItem.title
}

const findMenuItemForSubMenuItem = (menuItems, topPath) => {
  return menuItems.find(
    (menuItem) =>
      menuItem.path === topPath ||
      menuItem.title ===
        (topPath === 'accounts' ? ACCOUNT_SETTINGS : WEBSITE_SETTINGS)
  )
}

export const findSubMenuItemByPath = (menuItems, sectionPath) => {
  const pathDivisionIndex = sectionPath.indexOf('/')
  const topPath = sectionPath.slice(0, pathDivisionIndex)
  const menuItem = findMenuItemForSubMenuItem(menuItems, topPath)
  if (!menuItem) {
    return
  }

  const subMenuItem = menuItem.subMenuItems.find((subMenuItem) =>
    subMenuItem.path.includes(sectionPath)
  )
  if (!subMenuItem) {
    return
  }

  return {
    title: computeMenuItemTitle(menuItem, subMenuItem),
    tertiaryHeader: subMenuItem.tertiaryHeader && {
      ...menuItem.tertiaryHeader,
      ...subMenuItem.tertiaryHeader
    },
    path: subMenuItem.path ? `${subMenuItem.path}` : ''
  }
}

const findMenuItemByPath = (menuItems, sectionPath) => {
  return menuItems.find(
    (menuItem) =>
      menuItem.path === sectionPath || sectionPath.includes('accounts_overview')
  )
}

const getTitleFromAppId = (appId) => {
  return appId === 'feed_ops' ? 'FeedOps' : changeCase.titleCase(appId)
}

const embeddedNewMenuItem = (sectionPath, appId) =>
  sectionPath === 'new' && isEmbeddedApp(appId)

const newOrSignUpMenuItem = (sectionPath, appId) => {
  return {
    title: getTitleFromAppId(appId),
    tertiaryHeader:
      sectionPath.includes('accounts') ||
      embeddedNewMenuItem(sectionPath, appId)
        ? { filters: { disabled: true } }
        : appId === 'platform' && sectionPath === 'new'
        ? {
            channelCheck: true,
            analyticsViewSelector: false,
            dateRangePicker: false,
            showChannelSelection: true
          }
        : false
  }
}

const getMenuItemByPath = (sectionPath, appId) => {
  if (sectionPath.endsWith('sign_up')) {
    return newOrSignUpMenuItem(sectionPath, appId)
  } else if (sectionPath.includes('ads_configurator')) {
    return {
      title: '',
      tertiaryHeader: true
    }
  }
}

export const findMenuItem = (menuItems, sectionPath, appId) => {
  var item = getMenuItemByPath(sectionPath, appId)
  return item
    ? item
    : findMenuItemByPath(menuItems, sectionPath) ||
        findSubMenuItemByPath(menuItems, sectionPath) || {
          title: '',
          tertiaryHeader: false
        }
}
