import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBBtn, MDBIcon } from 'mdbreact'
import toastr from 'toastr'
import { exportProductAttributeReplacements } from 'actions/product_feeds/replacements'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { websiteProductReplacementsFiltersSelector } from 'selectors/product_feeds/replacements'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class ExportProductData extends Component {
  static propTypes = {
    displayedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    toastr: PropTypes.object.isRequired,
    exportProductAttributeReplacements: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  async exportProductAttributeReplacements() {
    const {
      exportProductAttributeReplacements,
      displayedColumns,
      websiteId,
      feedId,
      channel,
      filters
    } = this.props
    var response = await exportProductAttributeReplacements(
      websiteId,
      feedId,
      channel,
      filters,
      displayedColumns
    )

    return response
  }

  async handleExportClick() {
    const { toastr } = this.props
    toastr.options.timeOut = 0
    toastr.options.extendedTimeout = 0
    toastr.info(
      'Preparing your export. This will open in a new tab once it is ready.'
    )

    try {
      var response = await this.exportProductAttributeReplacements()
      toastr.clear()

      var url = `https://docs.google.com/spreadsheets/d/${response.spreadsheetId}/edit`
      toastr.success(
        `Export completed. Click <a href='${url}' target='_blank'>here</a> to open.`
      )

      toastr.options = {}
      window.open(url, '_blank')
    } catch (ex) {
      toastr.error(
        'An error occurred exporting your product data. Please contact support for assistance.'
      )
    }
  }

  render() {
    return (
      <div>
        <MDBBtn
          tag='span'
          color='link'
          className='btn-column'
          onClick={this.handleExportClick.bind(this)}
        >
          <MDBIcon icon={'file-export'} />
          Export
        </MDBBtn>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  channel: channelSelector(state, props),
  filters: websiteProductReplacementsFiltersSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ exportProductAttributeReplacements }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportProductData)
)
