import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane
} from 'mdbreact'
import ProductFeedsContainer from './mobile_container/product_feeds_container'
import AdvertisingToolsContainer from './mobile_container/advertising_tools_container'

import './mobile_container.scss'

const PRODUCT_FEEDS_TAB = 'productFeeds'
const ADVERTISING_TOOLS_TAB = 'advertisingTools'

export class ConnectedChannelsMobileContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: PRODUCT_FEEDS_TAB
    }
  }

  renderNavItem(tabId, tabName) {
    const { activeTab } = this.state

    return (
      <MDBNavItem>
        <MDBNavLink
          to='#'
          role='link'
          active={activeTab === tabId}
          className={activeTab === tabId ? 'active-tab' : ''}
          onClick={() => this.setState({ activeTab: tabId })}
        >
          {tabName}
        </MDBNavLink>
      </MDBNavItem>
    )
  }

  renderNav() {
    return (
      <MDBNav>
        {this.renderNavItem(PRODUCT_FEEDS_TAB, 'Product Feeds')}
        {this.renderNavItem(ADVERTISING_TOOLS_TAB, 'Advertising Tools')}
      </MDBNav>
    )
  }

  renderTabContent() {
    const { activeTab } = this.state

    return (
      <MDBTabContent activeItem={activeTab}>
        <MDBTabPane tabId={PRODUCT_FEEDS_TAB} role='tabpanel'>
          <ProductFeedsContainer />
        </MDBTabPane>
        <MDBTabPane tabId={ADVERTISING_TOOLS_TAB} role='tabpanel'>
          <AdvertisingToolsContainer />
        </MDBTabPane>
      </MDBTabContent>
    )
  }

  render() {
    return (
      <div className='connected-channel-container'>
        {this.renderNav()}
        {this.renderTabContent()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedChannelsMobileContainer)
)
