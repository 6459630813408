import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBBtn } from 'mdbreact'
import { Popover } from '@material-ui/core'
import PopoverSection from './apply_filters_section/popover_section'

export class ApplyFiltersSection extends Component {
  static propTypes = {
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  constructor() {
    super()
    this.state = {
      anchorEl: null,
      popoverOpen: false
    }
  }

  handleAddFiltersClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      popoverOpen: true
    })
  }

  renderApplyFilterBtn() {
    return (
      <MDBBtn
        color='link'
        tag='span'
        className='btn-toolbar-action'
        onClick={this.handleAddFiltersClick.bind(this)}
      >
        Add Filters
      </MDBBtn>
    )
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  renderPopover() {
    const { anchorEl, popoverOpen } = this.state
    const { filteredAccounts } = this.props

    return (
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className='accounts-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        <PopoverSection
          filteredAccounts={filteredAccounts}
          handleClosePopover={this.handleClosePopover.bind(this)}
        />
      </Popover>
    )
  }

  render() {
    return (
      <>
        {this.renderApplyFilterBtn()}
        {this.renderPopover()}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplyFiltersSection)
)
