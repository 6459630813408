import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { MDBSwitch } from 'mdbreact'

export default class Switch extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    labelLeft: PropTypes.node,
    labelRight: PropTypes.node,
    disabled: PropTypes.bool
  }

  renderFieldSet({ input, labelLeft, labelRight, disabled, ...custom }) {
    return (
      <fieldset onClick={() => input.onChange(input.checked)}>
        <MDBSwitch
          name={input.name}
          labelLeft={labelLeft}
          labelRight={labelRight}
          disabled={disabled}
          onChange={input.onChange}
          checked={input.checked}
          //          {...custom}
        />
      </fieldset>
    )
  }

  render() {
    const { name, labelLeft, labelRight, disabled } = this.props
    return (
      <Field
        name={name}
        labelLeft={labelLeft}
        labelRight={labelRight}
        disabled={disabled}
        component={this.renderFieldSet}
        type='checkbox'
      />
    )
  }
}
