import {
  FETCH_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE,
  FETCH_INVENTORY_ATTRIBUTES_RESPONSE,
  FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  UPDATE_INVENTORY_ATTRIBUTE_REPLACEMENTS,
  CLEAR_UPDATED_INVENTORY_ATTRIBUTE_REPLACEMENTS,
  CLEAR_INVENTORY_ATTRIBUTE_REPLACEMENTS,
  FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  UPDATE_PRODUCT_ATTRIBUTE_REPLACEMENTS,
  UPDATE_DISABLED_INVENTORY_ITEMS,
  CLEAR_UPDATED_ITEMS_AND_REPLACEMENTS,
  UPDATE_ATTRIBUTE_FILTERS,
  UPDATE_SELECTED_SUGGESTION_COLUMNS,
  UPDATE_PRODUCT_ROW_SELECTION_STATUS,
  PRODUCT_ATTRIBUTE_REPLACEMENTS_QUERY_CHANGED_STATUS
} from 'actions/product_feeds/replacements'

const initialState = {}

const generateCategoryReplacementsHierarchy = (action) => {
  var hierarchy = {}
  hierarchy[0] = {}

  for (var replacement of action.replacements) {
    if (!(`${replacement.depth}` in hierarchy)) {
      hierarchy[replacement.depth] = {}
    }

    var categoryReplacement = hierarchy[replacement.depth]
    categoryReplacement[replacement.fcId] = replacement.replacement
  }

  return Object.values(hierarchy)
}

const generateAttributeHierarchy = (action, websiteData) => {
  var hierarchy = {}
  for (var attribute of action.attributes) {
    hierarchy[attribute.id] = attribute
  }

  if (action.availableAttributes) {
    return {
      ...websiteData,
      attributes: hierarchy
    }
  } else {
    return {
      ...websiteData,
      allAttributes: hierarchy
    }
  }
}

const generateAttributeReplacementHierarchy = (state, action) => {
  var attributeReplacements = state[action.websiteId].attributeReplacements
    ? { ...state[action.websiteId].attributeReplacements }
    : {}

  var newAttributeReplacements = {}
  for (const attribute of action.replacements) {
    newAttributeReplacements[attribute.id] = attribute
  }
  attributeReplacements[action.attributeId] = newAttributeReplacements
  return attributeReplacements
}

const initialiseAttributesObject = (attributes) => {
  var attributeReplacements = {}
  for (const attribute of Object.keys(attributes)) {
    attributeReplacements[attribute] = {}
  }
  return attributeReplacements
}

const generateSearchAttributeReplacementHierarchy = (state, action) => {
  var attributeReplacements = initialiseAttributesObject(action.attributes)

  for (const replacement of action.replacements) {
    var replacements = attributeReplacements[replacement.parentId] || {}
    if (Object.values(replacements).length > 0) {
      attributeReplacements[replacement.parentId][replacement.id] = replacement
    } else {
      attributeReplacements[replacement.parentId] = {
        [replacement.id]: replacement
      }
    }
  }
  return attributeReplacements
}

const generateUpdatedAttributeReplacements = (state, action) => {
  var updatedAttributeReplacements =
    { ...state[action.websiteId].updatedAttributeReplacements } || {}
  var replacements = updatedAttributeReplacements[action.attributeId] || {}
  if (action.attribute.replacement || !action.attribute.remove) {
    replacements[action.attribute.id] = action.attribute.replacement
  } else {
    delete replacements[action.attribute.id]
  }
  updatedAttributeReplacements[action.attributeId] = replacements
  return updatedAttributeReplacements
}

const findReplacementByItemId = (replacements, itemId) => {
  return replacements.find((r) => r.itemId === itemId)
}

const updateProductReplacements = (state, action) => {
  var updatedProductReplacements = [
    ...state[action.websiteId].productReplacements
  ]
  action.payload.forEach((attribute) => {
    var replacementToUpdate = findReplacementByItemId(
      updatedProductReplacements,
      attribute.itemId
    )
    for (const [attributeName, value] of Object.entries(
      attribute.updatedAttributes
    )) {
      replacementToUpdate.override[attributeName] = value
    }
  })

  return updatedProductReplacements
}

const updateInventoryDisableStatus = (state, action) => {
  var updatedProductReplacements = [
    ...state[action.websiteId].productReplacements
  ]
  action.payload.forEach((attribute) => {
    var replacementToUpdate = findReplacementByItemId(
      updatedProductReplacements,
      attribute.itemId
    )
    replacementToUpdate.itemDisabled = attribute.updatedItemDisabled
  })

  return updatedProductReplacements
}

const updateProductAttributes = (state, action) => {
  var updatedProductAttributes =
    state[action.websiteId].updatedProductAttributes || {}
  action.payload.forEach((attribute) => {
    var productAttributes = updatedProductAttributes[attribute.itemId] || {}
    updatedProductAttributes[attribute.itemId] = {
      ...productAttributes,
      ...attribute.updatedAttributes
    }
  })

  return updatedProductAttributes
}

const updateInventoryItems = (state, action) => {
  var lastUpdatedItems = { ...state[action.websiteId].updatedInventoryItems }
  action.payload.forEach((attribute) => {
    lastUpdatedItems[attribute.itemId] = attribute.updatedItemDisabled
  })

  return lastUpdatedItems
}

export default (state = initialState, action) => {
  const websiteData = state[action.websiteId] || {}
  switch (action.type) {
    case FETCH_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE:
      var generatedCategoryReplacementHierarchy =
        generateCategoryReplacementsHierarchy(action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          categoryReplacements: generatedCategoryReplacementHierarchy
        }
      }
    case FETCH_INVENTORY_ATTRIBUTES_RESPONSE:
      var generatedAttributeHierarchy = generateAttributeHierarchy(
        action,
        websiteData
      )
      return {
        ...state,
        [action.websiteId]: generatedAttributeHierarchy
      }
    case FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE:
      var generatedAttributeReplacementHierarchy =
        generateAttributeReplacementHierarchy(state, action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          attributeReplacements: generatedAttributeReplacementHierarchy
        }
      }
    case SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE:
      var generatedSearchAttributeReplacementHierarchy =
        generateSearchAttributeReplacementHierarchy(state, action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          attributeReplacements: generatedSearchAttributeReplacementHierarchy
        }
      }
    case UPDATE_INVENTORY_ATTRIBUTE_REPLACEMENTS:
      var generatedUpdatedAttributeReplacement =
        generateUpdatedAttributeReplacements(state, action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          updatedAttributeReplacements: generatedUpdatedAttributeReplacement
        }
      }
    case CLEAR_UPDATED_INVENTORY_ATTRIBUTE_REPLACEMENTS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          attributeReplacements: action.attributeReplacements,
          updatedAttributeReplacements: {}
        }
      }
    case CLEAR_INVENTORY_ATTRIBUTE_REPLACEMENTS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          attributeReplacements: {}
        }
      }
    case FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE:
      const { page, perPage, searchTerm, filters } = action
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          page,
          perPage,
          searchTerm,
          filters,
          productReplacements: action.replacements,
          productOptimisationSuggestions: action.optimisationSuggestions,
          productReplacementsMetaData: action.metaData,
          updatedProductAttributes: {}
        }
      }
    case UPDATE_PRODUCT_ATTRIBUTE_REPLACEMENTS:
      var updatedProductReplacements = updateProductReplacements(state, action)
      var updatedProductAttributes = updateProductAttributes(state, action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          productReplacements: updatedProductReplacements,
          updatedProductAttributes
        }
      }
    case UPDATE_DISABLED_INVENTORY_ITEMS:
      var updatedProductReplacements = updateInventoryDisableStatus(
        state,
        action
      )
      var updatedInventoryItems = updateInventoryItems(state, action)
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          productReplacements: updatedProductReplacements,
          updatedInventoryItems
        }
      }
    case CLEAR_UPDATED_ITEMS_AND_REPLACEMENTS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          updatedProductAttributes: {},
          updatedInventoryItems: {}
        }
      }
    case UPDATE_ATTRIBUTE_FILTERS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          filters: action.filters
        }
      }
    case UPDATE_SELECTED_SUGGESTION_COLUMNS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          selectedSuggestionColumns: action.selectedColumns
        }
      }
    case UPDATE_PRODUCT_ROW_SELECTION_STATUS:
      return {
        ...state,
        isProductRowSelected: action.isProductRowSelected
      }
    case PRODUCT_ATTRIBUTE_REPLACEMENTS_QUERY_CHANGED_STATUS:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          queryChanged: action.queryChangedStatus
        }
      }
    default:
      return state
  }
}
