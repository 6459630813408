import {
  FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_SUCCESS,
  SAVE_AD_DESCRIPTION_SUCCESS,
  SAVE_LANDING_PAGE_SUCCESS
} from 'actions/campaign_type_configurations';

const updateConfiguration = (state, delta) => {
  return state.map((configuration) => {
    if (configuration.id === delta.id) {
      return { ...configuration, ...delta };
    } else {
      return configuration;
    }
  });
}

const campaignTypeConfigurationsReducer = (state = [], action) => {
  switch(action.type) {
    case FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_SUCCESS:
      return action.campaignTypeConfigurations;
    case SAVE_AD_DESCRIPTION_SUCCESS:
      return updateConfiguration(state, action.adDescription);
    case SAVE_LANDING_PAGE_SUCCESS:
      return updateConfiguration(state, action.landingPage);
    default:
      return state;
  }
}

export default campaignTypeConfigurationsReducer;