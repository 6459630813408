import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import QueryString from 'query-string'
import { sendInvitation } from 'actions/users'
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCardFooter
} from 'mdbreact'
import HeaderSection from './add_user/header_section'
import toastr from 'toastr'
import { accountListSelector } from 'selectors/user'
import { validateEmail } from 'components/util/validation'

export class AddUser extends Component {
  static propTypes = {
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    sendInvitation: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      accountId: null,
      workEmail: null,
      invalidEmail: false
    }
  }

  componentDidMount() {
    const qs = QueryString.parse(this.props.location.search)
    if (qs.account_id) {
      this.setState({ accountId: parseInt(qs.account_id) })
    }
  }

  handleBackBtnClick() {
    const { accountId } = this.state
    const { history } = this.props

    history.push(`/feed_ops/accounts/users_overview?account_id=${accountId}`)
  }

  renderBackButton() {
    return (
      <MDBRow className='actions-row'>
        <MDBCol className='col-3' />
        <MDBCol>
          <MDBBtn
            className='btn-card-nav float-left'
            onClick={this.handleBackBtnClick.bind(this)}
          >
            <MDBIcon icon='arrow-left' /> BACK
          </MDBBtn>
        </MDBCol>
        <MDBCol className='col-3' />
      </MDBRow>
    )
  }

  renderHeaderSection() {
    const { accountId } = this.state

    return accountId ? (
      <HeaderSection
        accountId={accountId}
        handleOnAccountIdChanged={(accountId) => this.setState({ accountId })}
      />
    ) : null
  }

  validateInput(id) {
    if (id === 'workEmail') {
      const { workEmail } = this.state
      this.setState({ invalidEmail: validateEmail(workEmail) })
    }
  }

  handleInputChange(event) {
    this.setState({ workEmail: event.target.value })
  }

  renderUserInput(id, label, value) {
    const { invalidEmail } = this.state

    return (
      <>
        <MDBInput
          id={id}
          label={label}
          value={value}
          onBlur={this.validateInput.bind(this, id)}
          onChange={this.handleInputChange.bind(this)}
        />
        {id === 'workEmail' && invalidEmail ? (
          <div className='input-error'>Please enter a valid work email.</div>
        ) : null}
      </>
    )
  }

  async handleAddUser() {
    const { accountId, workEmail } = this.state
    const { sendInvitation } = this.props

    await sendInvitation(workEmail, 'accounts', accountId, true).then(
      (_success) => {
        toastr.success('Invitation sent.')
        this.handleBackBtnClick()
      },
      (error) => {
        toastr.error(error.responseJSON.error)
      }
    )
  }

  renderCardFooter() {
    const { workEmail, invalidEmail } = this.state

    return (
      <MDBCardFooter className='add-user-card-footer'>
        <MDBBtn
          disabled={!workEmail || invalidEmail}
          onClick={this.handleAddUser.bind(this)}
        >
          ADD USER
        </MDBBtn>
      </MDBCardFooter>
    )
  }

  renderAddEditUserCard() {
    const { workEmail } = this.state

    return (
      <MDBCard>
        <MDBCardBody>
          {this.renderHeaderSection()}
          {this.renderUserInput('workEmail', 'Work Email', workEmail)}
        </MDBCardBody>
        {this.renderCardFooter()}
      </MDBCard>
    )
  }

  render() {
    return (
      <div className='add-edit-user-container'>
        {this.renderBackButton()}
        <MDBRow className='options-row'>
          <MDBCol className='col-3' />
          <MDBCol>{this.renderAddEditUserCard()}</MDBCol>
          <MDBCol className='col-3' />
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  accountList: accountListSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ sendInvitation }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser))
