import {
  LIST_ANALYTICS_ACCOUNTS_RESPONSE,
  FETCH_ANALYTICS_REPORT_SUCCESS,
  FETCH_ANALYTICS_REPORT_ERROR,
  SET_ANALYTICS_API_READY,
  FETCH_ANALYTICS_VIEWS_SUCCESS,
  FETCH_ANALYTICS_VIEWS_ERROR,
  SET_SELECTED_VIEW_ACTION,
  FETCH_ANALYTICS_FEEDOPS_REPORT_SUCCESS,
  FETCH_ANALYTICS_FEEDOPS_REPORT_ERROR
} from 'actions/google/analytics'
import { SELECT_ANALYTICS_PROPERTY_ERROR } from 'actions/google/analytics_property'

const initialState = {
  analyticsReport: {},
  accounts: [],
  analyticsApiReady: false,
  analyticsViews: {},
  selectedView: {},
  analyticsFeedopsReport: {}
}

const formatFeedopsReportRows = (rows) => {
  return rows.map((data) => {
    var y = data[0].substr(0, 4),
      m = data[0].substr(4, 2),
      d = data[0].substr(6, 2)
    return [d + '/' + m + '/' + y, data[1], data[2]]
  })
}

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_ANALYTICS_ACCOUNTS_RESPONSE:
      return { ...state, accounts: action.accounts }
    case SELECT_ANALYTICS_PROPERTY_ERROR:
      return { ...state, error: action.error }
    case FETCH_ANALYTICS_REPORT_SUCCESS:
      return {
        ...state,
        analyticsReport: {
          error: null,
          headers: action.headers,
          totals: action.totals,
          rows: action.rows
        }
      }
    case FETCH_ANALYTICS_REPORT_ERROR:
      return {
        ...state,
        analyticsReport: { error: action.error }
      }
    case FETCH_ANALYTICS_FEEDOPS_REPORT_SUCCESS:
      return {
        ...state,
        analyticsFeedopsReport: {
          error: null,
          rows: formatFeedopsReportRows(
            action.rows,
            action.utmSource,
            action.utmMedium
          )
        }
      }
    case FETCH_ANALYTICS_FEEDOPS_REPORT_ERROR:
      return {
        ...state,
        analyticsFeedopsReport: { error: action.error }
      }
    case SET_ANALYTICS_API_READY:
      return {
        ...state,
        analyticsApiReady: action.analyticsApiReady
      }
    case FETCH_ANALYTICS_VIEWS_SUCCESS:
      return {
        ...state,
        analyticsViews: { views: action.views }
      }
    case FETCH_ANALYTICS_VIEWS_ERROR:
      return {
        ...state,
        analyticsViews: { error: action.error }
      }
    case SET_SELECTED_VIEW_ACTION:
      return {
        ...state,
        selectedView: action.selectedView || {}
      }
    default:
      return state
  }
}
