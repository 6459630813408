import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MDBAlert } from 'mdbreact'
import { editorPropTypes } from './common'
import TitleDescriptionBuilder from 'components/feed_ops/website/product_optimisations/common/title_description_builder'
import { websiteProductTitlesAllAvailableAttributesSelector } from 'selectors/product_feeds/titles'
import { websiteIdSelector } from 'selectors/websites'
import { templateToFieldArray, fieldArrayToTemplate } from 'util/format_string'

export class TitleDescriptionEditor extends Component {
  static propTypes = {
    ...editorPropTypes,
    allAvailableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    conflictingValues: PropTypes.bool.isRequired
  }

  static defaultProps = {
    conflictingValues: false
  }

  constructor(props) {
    super(props)
    this.state = {
      templateFields: []
    }
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (value !== prevProps.value && prevProps.value === '') {
      this.initialize()
    }
  }

  generateDefaultParts() {
    const { attributeName, allAvailableFields } = this.props
    const fieldValue =
      attributeName === 'description' ? '{Description}' : '{Title}'
    return templateToFieldArray(`${fieldValue}`, allAvailableFields)
  }

  initialize() {
    const { value, allAvailableFields } = this.props
    const defaultParts = this.generateDefaultParts()
    var templateFields = []
    var warnOverwrite = false

    if (value !== '') {
      templateFields = templateToFieldArray(value || '', allAvailableFields)
    } else {
      warnOverwrite = true
    }

    if (templateFields.length <= 0) {
      templateFields = [...defaultParts]
    }
    this.setState({ templateFields, warnOverwrite })
  }

  handleTemplateFieldsChanged(newParts) {
    const { onChange, allAvailableFields } = this.props
    this.setState({ templateFields: newParts })
    onChange(fieldArrayToTemplate(newParts, allAvailableFields))
  }

  render() {
    const defaultParts = this.generateDefaultParts()
    const { templateFields, warnOverwrite } = this.state

    return (
      <>
        <TitleDescriptionBuilder
          defaultFields={defaultParts}
          onTemplateFieldsChanged={this.handleTemplateFieldsChanged.bind(this)}
          templateFields={templateFields}
        />
        {warnOverwrite && (
          <MDBAlert color='warning'>
            Different title structures detected for selected items. Applying a
            template will override any individual product title optimizations.
          </MDBAlert>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    allAvailableFields:
      websiteProductTitlesAllAvailableAttributesSelector(websiteId)(state)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TitleDescriptionEditor)
)
