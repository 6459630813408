import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Redirect } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import FeedOpsWebsiteNewDesktop from 'components/feed_ops/website/store_connection/desktop'
import WebsiteInitialReconciling from '../initial_reconciling'
import PlaybookContainer from 'components/feed_ops/website/playbook/container'
import FeedOpsGoalAndBudgetManagement from 'components/feed_ops/website/product_feeds/goal_and_budget_management'
import ProductLevelOptimiserForm from 'components/feed/optimisation/product_level/form'
import WebsiteStoreConnection from '../store_connection'
import ConnectedChannelsContainer from 'components/feed_ops/website/connected_channels/container'
import FeedSettingsContainer from 'components/feed_ops/feed_settings/container'
import RulesEngineForm from 'components/feed_ops/website/rules_engine/form'
import CategoryOptimizationsCard from 'components/feed_ops/website/category_optimizations/card'
import ProductAttributeOptimiserForm from 'components/feed/optimisation/product_attributes/form'
import AIOptimisationContainer from '../ai_optimisation/container'
import ConnectMerchantCenterAccount from '../connect/merchant_center_account'
import ConnectAdsAccount from '../connect/ads_account'
import Errors from 'components/websites/merchant_center_diagnostics/errors'
import AuditTemplate from 'components/feed_ops/website/playbook/audit/audit_template'
import { channelSelector } from 'selectors/channel'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import { enabledChannelsSelector } from 'selectors/websites/channel_settings'
import { SUPPORTED_CHANNELS, CHANNEL_ALL } from 'util/supported_channels'
import qs from 'qs'

export class FeedOpsWebsiteProductFeedsDesktop extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    enabledChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
    isInitialInventoryReconciled: PropTypes.bool.isRequired
  }

  componentDidMount() {
    this.defaultToDefaultChannel()
  }

  renderRedirectToDefaultRoute() {
    const { enabledChannels, isInitialInventoryReconciled } = this.props
    if (enabledChannels.length <= 0) {
      return <Redirect to={this.computePath('connected_channels')} />
    } else if (!isInitialInventoryReconciled) {
      return <Redirect to={this.computePath('initial_reconciling')} />
    } else {
      return <Redirect to={this.computePath('playbook')} />
    }
  }

  defaultToDefaultChannel() {
    const { location, history, enabledChannels } = this.props
    if (!location) {
      return
    }
    var searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    var { channel } = searchParams
    if (
      (location.pathname.includes('playbook') ||
        location.pathname.includes('product_feeds') ||
        location.pathname.includes('goal_budget_management')) &&
      (!channel ||
        channel === CHANNEL_ALL ||
        !enabledChannels.includes(channel))
    ) {
      history.replace({
        search: qs.stringify({ channel: enabledChannels[0] })
      })
    }
  }

  computePath(featureName) {
    const { location, match } = this.props
    const { url } = match
    return `${url}/${featureName}${location.search}`
  }

  computeRoute(featureName) {
    const { location, channel, match } = this.props
    const { path } = match

    if (
      location &&
      Object.keys(location).length > 0 &&
      location.search &&
      location.search.includes('channel')
    ) {
      return `${path}/${featureName}`
    } else {
      return `${path}/${featureName}?channel=${channel}`
    }
  }

  render() {
    const { match } = this.props
    return (
      <div>
        <Switch>
          <GuardedRoute
            path={`${match.path}/connect_store`}
            component={FeedOpsWebsiteNewDesktop}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/initial_reconciling`}
            component={WebsiteInitialReconciling}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('playbook')}
            component={PlaybookContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('goal_budget_management')}
            component={FeedOpsGoalAndBudgetManagement}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('product_level_optimizations')}
            component={ProductLevelOptimiserForm}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/store_details`}
            component={WebsiteStoreConnection}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/ai_optimisation`}
            component={AIOptimisationContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/connected_channels`}
            component={ConnectedChannelsContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/feed_settings`}
            component={FeedSettingsContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('data_rules')}
            component={RulesEngineForm}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/category_optimization`}
            component={CategoryOptimizationsCard}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/attribute_optimization`}
            component={ProductAttributeOptimiserForm}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/connect_merchant_center_account`}
            component={ConnectMerchantCenterAccount}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/connect_ads_account`}
            component={ConnectAdsAccount}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('errors_warnings')}
            component={Errors}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={this.computeRoute('product_data_audit')}
            component={AuditTemplate}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path='*'
            render={this.renderRedirectToDefaultRoute.bind(this)}
          />
        </Switch>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    channel: channelSelector(state, props),
    enabledChannels: enabledChannelsSelector(state, props),
    isInitialInventoryReconciled:
      initialReconciledStatusSelector(state, props) || false
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedOpsWebsiteProductFeedsDesktop)
