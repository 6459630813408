export const ERROR_LEADING_TRAILING = 'Contains leading or trailing spaces'
export const ERROR_REQUIRED = 'Value required'

const setError = (attrValue, errors, errorMessage) => {
  errors[attrValue.parentId] = errors[attrValue.parentId] || {}
  errors[attrValue.parentId][attrValue.id] = errorMessage
}

const attributeName = (attrValue, values) => {
  const enabled = values[`enabled-${attrValue.parentId}-${attrValue.id}`]
  return enabled ? attrValue.replacement : attrValue.name
}

const validateRequiredFields = (values) => {
  var errors = {}
  const { attributeValues } = values
  Object.entries(attributeValues || {}).forEach(([attrId, attr]) => {
    Object.values(attr).forEach((attrValue) => {
      const attrName = attributeName(attrValue, values)
      if (!attrName || attrName.length === 0) {
        setError(attrValue, errors, ERROR_REQUIRED)
      } else if (attrName.trim() !== attrName) {
        setError(attrValue, errors, ERROR_LEADING_TRAILING)
      }
    })
  })
  return errors
}

export const validate = (values) => {
  var errors = validateRequiredFields(values)

  const { attributeValues } = values
  Object.entries(attributeValues || {}).forEach(([attrId, attr]) => {
    var foundValues = {}
    Object.values(attr).forEach((attrValue) => {
      const attrName = attributeName(attrValue, values)
      foundValues[attrName] = foundValues[attrName] || []
      foundValues[attrName].push(attrValue)
    })
  })

  return Object.entries(errors).length > 0 ? { attributeValues: errors } : {}
}
