import camelCaseKeys from 'camelcase-keys'

export const FETCH_RULE_ACTION_TYPES_REQUEST = 'FETCH_RULE_ACTION_TYPES_REQUEST'
export const FETCH_RULE_ACTION_TYPES_RESPONSE =
  'FETCH_RULE_ACTION_TYPES_RESPONSE'

const fetchRuleActionTypesRequest = () => ({
  type: FETCH_RULE_ACTION_TYPES_REQUEST
})

const fetchRuleActionTypesResponse = (response) => ({
  type: FETCH_RULE_ACTION_TYPES_RESPONSE,
  ...response
})

export const fetchRuleActionTypes = () => async (dispatch) => {
  dispatch(fetchRuleActionTypesRequest())
  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/inventories/rules/action_types',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchRuleActionTypesResponse(response))
  return response
}
