import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input } from 'reactstrap'
import { Field } from 'redux-form'
import InputError from './input_error'

const formatNumber = (number) => {
  return number.toLocaleString('en-US', { useGrouping: false })
}

const renderWordCounter = (value, maxLength) => {
  if (maxLength && value) {
    return (
      <div className='field-counter'>
        {`${formatNumber(value.length)}/${maxLength}`}
      </div>
    )
  }
  return null
}

const renderIcon = (iconClass) =>
  iconClass && <i className={`${iconClass} test prefix`} />
export const renderError = (touched, error, iconClass) =>
  touched && (
    <InputError
      error={error}
      cssClass={iconClass ? 'input-field-validation-error' : ''}
    />
  )

export default class TextInputGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    iconClass: PropTypes.string,
    disabled: PropTypes.bool,
    handleOnBlur: PropTypes.func,
    maxLength: PropTypes.number,
    isOptional: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.arrayOf(PropTypes.func),
    type: PropTypes.string,
    softLimit: PropTypes.bool,
    autoFocus: PropTypes.bool
  }

  renderInput({
    input,
    label,
    type,
    isOptional,
    iconClass,
    form,
    meta: { touched, error },
    ...custom
  }) {
    const { maxLength, softLimit } = custom
    delete custom.maxLength
    delete custom.softLimit

    return (
      <div>
        {renderIcon(iconClass)}
        <Input
          className={`${
            input.value && softLimit && input.value.length > maxLength
              ? 'length-exceeded'
              : ''
          } ${input.value ? 'set' : ''}`}
          name={input.name}
          type={type}
          {...input}
          {...custom}
        />
        {renderError(touched, error, iconClass)}
        {renderWordCounter(input.value, maxLength)}
        <Label
          htmlFor={input.name}
          className={
            type !== 'checkbox' && (input.value || custom.placeholder)
              ? 'active'
              : ''
          }
        >
          {label}
          {isOptional ? '' : <span className='required-field'> * </span>}
        </Label>
      </div>
    )
  }

  render() {
    const {
      name,
      label,
      iconClass,
      maxLength,
      handleOnBlur,
      isOptional,
      disabled,
      placeholder,
      validate,
      type,
      softLimit,
      autoFocus
    } = this.props
    return (
      <FormGroup className='md-form' onBlur={handleOnBlur}>
        <Field
          id={name}
          name={name}
          iconClass={iconClass}
          maxLength={maxLength}
          softLimit={softLimit}
          component={this.renderInput}
          label={label}
          disabled={disabled}
          isOptional={isOptional}
          placeholder={placeholder}
          validate={validate}
          type={type || 'text'}
          autoFocus={autoFocus}
        />
      </FormGroup>
    )
  }
}
