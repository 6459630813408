import { SET_DATE_RANGE } from 'actions/date_ranges'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        [action.keyName]: action.dateRange
      }
    default:
      return state
  }
}
