import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Button } from 'reactstrap'
import * as Guid from 'guid'

export default class IconButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    iconClass: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    tooltipId: PropTypes.string,
    tooltipDirection: PropTypes.string,
    tooltipInnerClass: PropTypes.string,
    action: PropTypes.func,
    label: PropTypes.object,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.state = {
      tooltip: {
        information: false
      },
      tooltipId: Guid.raw()
    }
  }

  componentDidMount() {
    const { tooltipId } = this.props
    if (tooltipId) {
      this.setState({ tooltipId })
    }
  }

  componentDidUpdate(prevProps) {
    const { tooltipId } = this.props
    if (prevProps.tooltipId !== tooltipId) {
      this.setState({ tooltipId })
    }
  }

  toggle(tooltipId) {
    var tooltip = this.state.tooltip
    tooltip[tooltipId] = !tooltip[tooltipId]
    this.setState(tooltip)
  }

  handleOnClick() {
    const { action } = this.props
    if (action) {
      action()
    }
  }

  renderTooltip() {
    const { tooltip } = this.state
    const { tooltipText, tooltipDirection, tooltipInnerClass } = this.props
    const { tooltipId } = this.state
    return (
      <Tooltip
        innerClassName={tooltipInnerClass}
        placement={tooltipDirection ? tooltipDirection : 'right'}
        isOpen={tooltip[tooltipId]}
        target={'Tooltip-' + tooltipId}
        toggle={() => this.toggle(tooltipId)}
      >
        {tooltipText}
      </Tooltip>
    )
  }

  render() {
    const { iconClass, disabled, className } = this.props
    const { tooltipId } = this.state
    return (
      <div className={'common-icon-button'}>
        <Button
          id={'Tooltip-' + tooltipId}
          className={className ? className : 'index-icon link-icon'}
          color='Link'
          onClick={this.handleOnClick}
          disabled={disabled || false}
        >
          {tooltipId && this.renderTooltip()}
          <i className={iconClass}> </i>
        </Button>
        {this.props.label}
      </div>
    )
  }
}
