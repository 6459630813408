import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import FilterPopoverHeader from 'components/feed_ops/common/popover/filter_popover_header'
import FilterOptionsDropdown from 'components/feed_ops/common/popover/filter_options_dropdown'
import ListItems from './list_items'
import { getSelectedFilterOptionAndValues } from 'components/feed_ops/common/popover/popover_util'
import {
  ACCOUNT_NAME_ATTRIBUTE,
  WEBSITE_ATTRIBUTE,
  NUMERIC_OPERATORS,
  searchedAttributeValues
} from '../attributes_util'

export default class AccountFilterPopoverContainer extends Component {
  static propTypes = {
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAttribute: PropTypes.object.isRequired,
    appliedFilter: PropTypes.object,
    handleClosePopover: PropTypes.func,
    handleApplyFilterClick: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      searchText: '',
      selectedFilterOption: '',
      values: '',
      showValidationErrors: false
    }
  }

  componentDidMount() {
    const { appliedFilter, selectedAttribute, filteredAccounts } = this.props
    const updatedStateValues = getSelectedFilterOptionAndValues(
      appliedFilter,
      selectedAttribute
    )
    this.setState({
      selectedFilterOption: updatedStateValues.selectedFilterOption,
      values: updatedStateValues.values
    })

    this.setState({ searchFilteredAccounts: [...filteredAccounts] })
  }

  handleCloseFilterPopover() {
    const { handleClosePopover } = this.props
    if (handleClosePopover) {
      handleClosePopover()
    }
  }

  renderFilterPopoverHeader() {
    const { selectedAttribute } = this.props
    return (
      <FilterPopoverHeader
        attributeName={selectedAttribute.name}
        handleCloseFilterPopover={this.handleCloseFilterPopover.bind(this)}
      />
    )
  }

  checkAccountOrWebsiteAttribute() {
    const { selectedAttribute } = this.props
    return [ACCOUNT_NAME_ATTRIBUTE, WEBSITE_ATTRIBUTE].includes(
      selectedAttribute.name
    )
  }

  handleSearchValueChange(event) {
    this.setState({ searchText: event.target.value })
  }

  renderSearchBox() {
    const { searchText } = this.state
    return (
      <MDBInput
        placeholder='Search'
        value={searchText}
        onChange={this.handleSearchValueChange.bind(this)}
      />
    )
  }

  handleFilterOptionItemClick(selectedFilterOption) {
    this.setState({ selectedFilterOption: selectedFilterOption })
  }

  renderFilterOptionsDropdown() {
    const { selectedAttribute } = this.props
    const { filterOptions } = selectedAttribute
    const { selectedFilterOption } = this.state

    return (
      <FilterOptionsDropdown
        selectedFilterOption={selectedFilterOption}
        filterOptions={filterOptions}
        handleFilterOptionItemClick={this.handleFilterOptionItemClick.bind(
          this
        )}
      />
    )
  }

  renderSearchBoxOrFilterOptionsRow() {
    const accountOrWebsiteAttribute = this.checkAccountOrWebsiteAttribute()

    return (
      <MDBRow>
        <MDBCol
          className={
            accountOrWebsiteAttribute ? 'search-accounts-or-websites' : ''
          }
        >
          {accountOrWebsiteAttribute
            ? this.renderSearchBox()
            : this.renderFilterOptionsDropdown()}
        </MDBCol>
      </MDBRow>
    )
  }

  handleBlur() {
    this.setState({ showValidationErrors: true })
  }

  handleFilterValuesChange(event) {
    this.setState({ values: event.target.value })
  }

  renderInput() {
    const { values } = this.state

    return (
      <MDBInput
        placeholder='Value'
        type='number'
        value={values}
        onBlur={this.handleBlur.bind(this)}
        onChange={this.handleFilterValuesChange.bind(this)}
      />
    )
  }

  filterValuesEmpty() {
    const { values } = this.state
    return _.isEmpty(values.replace(/^\s+|\s+$/g, ''))
  }

  renderErrorText() {
    const isEmpty = this.filterValuesEmpty()

    return isEmpty ? (
      <span className={isEmpty ? 'error-text' : ''}>
        Value cannot be empty.
      </span>
    ) : null
  }

  handleItemValueChanged(values) {
    this.setState({ values })
  }

  renderFilterValues() {
    const { showValidationErrors, searchText, values } = this.state
    const { selectedAttribute, filteredAccounts } = this.props
    const { filterOptions } = selectedAttribute

    if (filterOptions === NUMERIC_OPERATORS) {
      return (
        <>
          {this.renderInput()}
          {showValidationErrors && this.renderErrorText()}
        </>
      )
    }

    return (
      <>
        <span className='explanation'>Any of the following values</span>
        <ListItems
          attribute={selectedAttribute}
          filteredAccounts={filteredAccounts}
          searchText={searchText}
          filterValues={values}
          itemValueChanged={this.handleItemValueChanged.bind(this)}
        />
      </>
    )
  }

  handleSelectAllClick() {
    const { selectedAttribute, filteredAccounts } = this.props
    const { values, searchText } = this.state
    var valuesArray = values ? values.split('\n') : []
    const allValues = searchedAttributeValues(
      selectedAttribute.name,
      [...filteredAccounts],
      searchText
    )

    this.handleItemValueChanged(_.union(valuesArray, allValues).join('\n'))
  }

  renderSelectAllBtn() {
    return (
      <MDBBtn
        tag='span'
        className='select-all-btn'
        onClick={this.handleSelectAllClick.bind(this)}
      >
        Select All
      </MDBBtn>
    )
  }

  handleApplyFilter() {
    const { selectedFilterOption, values } = this.state
    const { handleApplyFilterClick, selectedAttribute } = this.props

    handleApplyFilterClick(
      selectedAttribute.name,
      selectedFilterOption,
      values.split('\n').filter((v) => !_.isEmpty(v.trim()))
    )
  }

  renderApplyFilterBtn() {
    var disabled = this.filterValuesEmpty()

    return (
      <MDBBtn
        tag='span'
        className='apply-filter-btn'
        disabled={disabled}
        onClick={this.handleApplyFilter.bind(this)}
      >
        Apply
      </MDBBtn>
    )
  }

  render() {
    const accountOrWebsiteAttribute = this.checkAccountOrWebsiteAttribute()

    return (
      <>
        {this.renderFilterPopoverHeader()}
        {this.renderSearchBoxOrFilterOptionsRow()}
        <MDBRow className='filter-values-row'>
          <MDBCol>{this.renderFilterValues()}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {accountOrWebsiteAttribute && this.renderSelectAllBtn()}
            {this.renderApplyFilterBtn()}
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}
