import _ from 'lodash'
import {
  FETCH_PLAYBOOK_PROGRESS_RESPONSE,
  DISMISS_PLAYBOOK_PROGRESS_RESPONSE,
  SET_PLAYBOOK_DISMISS_SWITCH
} from 'actions/playbook/playbook_progress'

const initialState = {}

const dismissPlaybookProgress = (state, action) => {
  var updatedPlaybookProgressDetails = _.cloneDeep(state[action.websiteId])
  Object.values(updatedPlaybookProgressDetails.progressDetails).forEach(
    (channelPlaybookProgressDetails) => {
      channelPlaybookProgressDetails.forEach((playbookProgressDetails) => {
        if (playbookProgressDetails.id === action.websitePlaybookItemId) {
          playbookProgressDetails.isDismissed = true
          playbookProgressDetails.score = 0
          playbookProgressDetails.possibleScore = 0
          playbookProgressDetails.opportunity = 0
        }
      })
    }
  )

  return updatedPlaybookProgressDetails
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAYBOOK_PROGRESS_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          ...state[action.websiteId],
          progressDetails: action.progressDetails || {},
          generatedDetailsTime: action.generatedDetailsTime
        }
      }
    case DISMISS_PLAYBOOK_PROGRESS_RESPONSE:
      var updatedPlaybookProgressDetails = dismissPlaybookProgress(
        state,
        action
      )
      return {
        ...state,
        [action.websiteId]: updatedPlaybookProgressDetails
      }
    case SET_PLAYBOOK_DISMISS_SWITCH:
      return {
        ...state,
        showDismissPlaybookItems: action.show
      }
    default:
      return state
  }
}
