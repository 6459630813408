import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import { fetchWebsiteSubscription } from 'actions/billing'
import { MDBBtn, MDBIcon } from 'mdbreact'
import {
  subscriptionSelector,
  planCanGenerateFeedsSelector
} from 'selectors/billing'
import {
  websiteIdSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'
import toastr from 'toastr'

export class FeedLink extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    subscriptionDetail: PropTypes.object.isRequired,
    feedDetail: PropTypes.object.isRequired,
    canGenerateFeed: PropTypes.bool.isRequired,
    toastr: PropTypes.object.isRequired,
    navigator: PropTypes.object.isRequired,
    fetchWebsiteSubscription: PropTypes.func.isRequired
  }

  async componentDidMount() {
    const { subscriptionDetail, fetchWebsiteSubscription, websiteId } =
      this.props
    if (
      !subscriptionDetail ||
      Object.entries(subscriptionDetail).length === 0
    ) {
      await fetchWebsiteSubscription(websiteId)
    }
  }

  static defaultProps = {
    toastr,
    navigator: global.navigator
  }

  async handleCopyClick() {
    const { navigator, toastr } = this.props
    const { url } = this.props.feedDetail
    try {
      navigator.clipboard.writeText(url)
      toastr.success('Feed URL copied to clipboard')
    } catch (error) {
      toastr.error('Unable to copy Feed URL')
    }
  }

  render() {
    const { feedDetail, canGenerateFeed } = this.props
    const { url, useContentApi } = feedDetail

    if (url && !useContentApi) {
      return (
        <>
          <MDBBtn
            disabled={!canGenerateFeed}
            color='link'
            onClick={this.handleCopyClick.bind(this)}
          >
            <MDBIcon icon='clone' />
            {!isMobileOS && <br />}
            Feed URL
          </MDBBtn>
        </>
      )
    }

    return null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props),
    subscriptionDetail: subscriptionSelector(state, props),
    feedDetail: websiteFeedDetailSelector(state, props),
    canGenerateFeed: planCanGenerateFeedsSelector(state, props) || false
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchWebsiteSubscription }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedLink)
)
