import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_DRIVE_DOC_REQUEST = 'FETCH_DRIVE_DOC_REQUEST'
export const FETCH_DRIVE_DOC_RESPONSE = 'FETCH_DRIVE_DOC_RESPONSE'

const fetchDriveDocRequest = (articleId) => ({
  type: FETCH_DRIVE_DOC_REQUEST,
  articleId
})

const fetchDriveDocResponse = (articleId, response) => ({
  type: FETCH_DRIVE_DOC_RESPONSE,
  articleId,
  ...response
})

export const fetchDriveDocs = (articleId) => async (dispatch) => {
  dispatch(fetchDriveDocRequest(articleId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/google/drive/docs`,
    data: snakeCaseKeys({ articleId }, { deep: true }),
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(fetchDriveDocResponse(articleId, camelResponse))

  return { title: camelResponse.title, description: camelResponse.description }
}
