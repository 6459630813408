import { createSelector } from 'reselect'
import changeCase from 'change-case'
import { defaultGoogleChannelSelector } from 'selectors/channel'
import { websiteIdSelector } from 'selectors/websites'

export const advertisingSelector = (state) => state.advertising || {}

const advertisingBudgetCategoriesSelector = createSelector(
  [advertisingSelector],
  (a) => a.budgetCategories || {}
)

export const advertisingBudgetCategorySelector = (budgetCategoryId) =>
  createSelector(
    [advertisingBudgetCategoriesSelector],
    (budgetCategory) => budgetCategory[budgetCategoryId] || {}
  )

export const budgetCategoryAdvertisingGoalsSelector = (budgetCategoryId) =>
  createSelector(
    [advertisingBudgetCategorySelector(budgetCategoryId)],
    (a) => a.goals || []
  )

export const budgetCategoryAdvertisingBudgetsSelector = (budgetCategoryId) =>
  createSelector(
    [advertisingBudgetCategorySelector(budgetCategoryId)],
    (a) => a.budgets || []
  )

const advertisingWebsitesSelector = createSelector(
  [advertisingSelector],
  (a) => a.websites || {}
)

export const advertisingWebsiteSelector = (websiteId) =>
  createSelector(
    [advertisingWebsitesSelector],
    (websites) => websites[websiteId] || {}
  )

export const websiteAdvertisingPlanSelector = (websiteId) =>
  createSelector(
    [advertisingWebsiteSelector(websiteId)],
    (a) => a.advertisingPlan || ''
  )

export const websiteLatestAdvertisingGoalSelector = (websiteId) =>
  createSelector(
    [advertisingGoalsSelector('websites', websiteId)],
    (goals) => (goals.find((g) => g.endDate === null) || {}).value || 0
  )

export const websiteLatestAdvertisingBudgetSelector = (websiteId) =>
  createSelector(
    [advertisingBudgetsSelector('websites', websiteId)],
    (budgets) => (budgets.find((b) => b.endDate === null) || {}).value || 0
  )

const advertisingAccountsSelector = createSelector(
  [advertisingSelector],
  (a) => a.accounts || {}
)

export const advertisingAccountSelector = (accountId) =>
  createSelector(
    [advertisingAccountsSelector],
    (accounts) => accounts[accountId] || {}
  )

const advertisingMasterAccountsSelector = createSelector(
  [advertisingSelector],
  (a) => a.masterAccounts || {}
)

export const advertisingMasterAccountSelector = (masterAccountId) =>
  createSelector(
    [advertisingMasterAccountsSelector],
    (masterAccounts) => masterAccounts[masterAccountId] || {}
  )

const advertisingAccountTypeSelector = (accountType) =>
  createSelector(
    [advertisingSelector],
    (advertising) => advertising[changeCase.camelCase(accountType)] || {}
  )

const advertisingAccountTypeByIdSelector = (accountType, id) =>
  createSelector(
    [advertisingAccountTypeSelector(accountType)],
    (accountType) => accountType[id] || {}
  )

export const advertisingChannelSelector = (accountType, id) =>
  createSelector(
    [
      advertisingAccountTypeByIdSelector(accountType, id),
      defaultGoogleChannelSelector
    ],
    (accountTypeById, channel) => accountTypeById[channel] || {}
  )

export const advertisingGoalsSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryAdvertisingGoalsSelector(id)
    case 'websites':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.goals || []
      )
    case 'accounts':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.goals || []
      )
    case 'master_accounts':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.goals || []
      )
    default:
      return () => []
  }
}

export const advertisingBudgetsSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryAdvertisingBudgetsSelector(id)
    case 'websites':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.budgets || []
      )
    case 'accounts':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.budgets || []
      )
    case 'master_accounts':
      return createSelector(
        [advertisingChannelSelector(accountType, id)],
        (channel) => channel.budgets || []
      )
    default:
      return () => []
  }
}

export const hasBudgetCategoriesSelector = (websiteId) =>
  createSelector(
    [advertisingWebsiteSelector(websiteId), defaultGoogleChannelSelector],
    (website, channel) =>
      channel === 'google' && (website.hasBudgetCategories || false)
  )

export const hasGooglePublicationAccountSelector = (accountType, id) =>
  createSelector(
    [advertisingAccountTypeByIdSelector(accountType, id)],
    (accountTypeById) => accountTypeById.hasGooglePublicationAccount || false
  )

export const hasMicrosoftPublicationAccountSelector = (accountType, id) =>
  createSelector(
    [advertisingAccountTypeByIdSelector(accountType, id)],
    (accountTypeById) => accountTypeById.hasMicrosoftPublicationAccount || false
  )

export const advertisingStatusSelector = (buildType) =>
  createSelector([advertisingSelector, websiteIdSelector], (a, websiteId) =>
    a.status && a.status[websiteId] && buildType
      ? a.status[websiteId][buildType]
      : {}
  )

export const websiteBudgetSelector = (buildType) =>
  createSelector([advertisingSelector, websiteIdSelector], (a, websiteId) =>
    a.budget && a.budget[websiteId] && buildType
      ? a.budget[websiteId][buildType]
      : 0
  )

export const websiteCampaignIdsSelector = (buildType) =>
  createSelector([advertisingSelector, websiteIdSelector], (a, websiteId) =>
    a.campaignIds && a.campaignIds[websiteId] && buildType
      ? a.campaignIds[websiteId][buildType] || []
      : []
  )
