import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { MDBBtn } from 'mdbreact'
import { openArticle } from 'components/util/help'
import { setBookAMeetingModalOpen } from 'actions/app'
import { customerFeaturesSelector } from 'selectors/billing'
import { websiteDetailSelector } from 'selectors/websites'
import AskAQuestion from './ask_a_question'

export class CustomerHelper extends Component {
  static propTypes = {
    customerFeatures: PropTypes.object.isRequired,
    websiteDetail: PropTypes.object.isRequired,
    helperName: PropTypes.string.isRequired,
    bookAMeetingType: PropTypes.string,
    setBookAMeetingModalOpen: PropTypes.func.isRequired,
    articleId: PropTypes.string.isRequired,
    openArticle: PropTypes.func.isRequired
  }

  static defaultProps = {
    openArticle
  }

  openBookAMeetingModal() {
    const { setBookAMeetingModalOpen, bookAMeetingType } = this.props
    setBookAMeetingModalOpen(true, bookAMeetingType)
  }

  renderGetAnswers() {
    const { openArticle, articleId } = this.props
    return (
      <MDBBtn
        outline
        onClick={() => {
          openArticle(articleId)
        }}
      >
        Read help docs
      </MDBBtn>
    )
  }

  renderBookAMeeting() {
    return (
      <MDBBtn outline onClick={this.openBookAMeetingModal.bind(this)}>
        Book a meeting
      </MDBBtn>
    )
  }

  render() {
    const { helperName, customerFeatures } = this.props

    return (
      <div className='customer-helper'>
        <p>{helperName}</p>
        <div>
          {this.renderGetAnswers()}
          {customerFeatures.canAskQuestions && <AskAQuestion />}
          {customerFeatures.canBookMeetings && this.renderBookAMeeting()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    customerFeatures: customerFeaturesSelector(state),
    websiteDetail: websiteDetailSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setBookAMeetingModalOpen }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerHelper)
)
