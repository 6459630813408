import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { editorPropTypes } from './common'
import _ from 'lodash'
import GoogleProductCategorySelect from 'components/feed_ops/common/google_product_category_select'
import { taxonomiesOptionsSelector } from 'selectors/google/taxonomies'

export class GoogleProductCategoryDropdown extends Component {
  static propTypes = {
    ...editorPropTypes,
    googleTaxonomyOptions: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      defaultOption: null
    }
  }

  componentDidMount() {
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (!_.isEqual(prevProps.value, value)) {
      this.setDefaults()
    }
  }

  setDefaults() {
    const { value, googleTaxonomyOptions } = this.props
    if (value) {
      const option = googleTaxonomyOptions.find(
        (o) => o.value === value || o.label === value
      )
      this.setState({ defaultOption: option })
    }
  }

  handleTaxonomySelectionChange(selectedOptions) {
    const { onChange } = this.props
    const selectedOption = selectedOptions[0]
    const currentValue = selectedOption ? selectedOption.label : null
    onChange(currentValue)
    this.setState({ defaultOption: selectedOption })
  }

  render() {
    const { defaultOption } = this.state
    const { googleTaxonomyOptions } = this.props

    return (
      <GoogleProductCategorySelect
        selectedValue={defaultOption}
        googleTaxonomyOptions={googleTaxonomyOptions}
        valueChanged={this.handleTaxonomySelectionChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  googleTaxonomyOptions: taxonomiesOptionsSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleProductCategoryDropdown)
)
