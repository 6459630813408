import camelCaseKeys from 'camelcase-keys'

export const FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_REQUEST =
  'FETCH_MERCHANT_CENTER_PRODUCT_ERRS_REQUEST'
export const FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS =
  'FETCH_MERCHANT_CENTER_PRODUCT_ERRS_SUCCESS'
export const REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_REQUEST =
  'REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_REQUEST'
export const REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS =
  'REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS'
export const FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST =
  'FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST'
export const FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE =
  'FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE'
export const FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_REQUEST =
  'FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_REQUEST'
export const FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_RESPONSE =
  'FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_RESPONSE'
export const FETCH_MERCHANT_CENTER_ERROR_TYPES_REQUEST =
  'FETCH_MERCHANT_CENTER_ERROR_TYPES_REQUEST'
export const FETCH_MERCHANT_CENTER_ERROR_TYPES_RESPONSE =
  'FETCH_MERCHANT_CENTER_ERROR_TYPES_RESPONSE'
export const FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST =
  'FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST'
export const FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE =
  'FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE'

const fetchMerchantCenterProductErrorsRequest = (websiteId) => ({
  type: FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_REQUEST,
  websiteId
})

const fetchMerchantCenterProductErrorsSuccess = (websiteId, response) => ({
  type: FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS,
  websiteId,
  merchantCenterProductErrors: response.mcAccountLevelProductErrors
})

export const fetchMerchantCenterProductErrors = (websiteId) => async (
  dispatch
) => {
  dispatch(fetchMerchantCenterProductErrorsRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/merchant_center_product_errors`,
    dataType: 'json'
  })

  dispatch(
    fetchMerchantCenterProductErrorsSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const refreshMerchantCenterProductErrorsRequest = (websiteId) => ({
  type: REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_REQUEST,
  websiteId
})

const refreshMerchantCenterProductErrorsSuccess = (
  websiteId,
  response,
  refreshAt
) => ({
  type: REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS,
  websiteId,
  ...response,
  refreshAt
})

export const refreshMerchantCenterProductErrors = (
  websiteId,
  refreshAt = new Date().toLocaleString()
) => async (dispatch) => {
  dispatch(refreshMerchantCenterProductErrorsRequest(websiteId))
  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/websites/${websiteId}/merchant_center_product_errors/refresh_errors`,
    dataType: 'json'
  })

  dispatch(
    refreshMerchantCenterProductErrorsSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true }),
      refreshAt
    )
  )
}

const fetchWebsiteMerchantCenterErrorCategoriesRequest = (
  websiteId,
  scope
) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST,
  websiteId,
  scope
})

const fetchWebsiteMerchantCenterErrorCategoriesResponse = (
  websiteId,
  scope,
  payload
) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE,
  websiteId,
  scope,
  ...payload
})

export const fetchWebsiteMerchantCenterErrorCategories = (
  websiteId,
  scope
) => async (dispatch) => {
  dispatch(fetchWebsiteMerchantCenterErrorCategoriesRequest(websiteId, scope))

  var response = await $.ajax({
    url: `/ad_champion/websites/${websiteId}/merchant_center/error_categories?scope=${scope}`
  })

  var payload = camelCaseKeys(response, { deep: true })
  dispatch(
    fetchWebsiteMerchantCenterErrorCategoriesResponse(websiteId, scope, payload)
  )
  return payload
}

const fetchWebsiteMerchantCenterErrorsRequest = (websiteId, categoryId) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_REQUEST,
  websiteId,
  categoryId
})

const fetchWebsiteMerchantCenterErrorsResponse = (
  websiteId,
  categoryId,
  payload
) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_RESPONSE,
  websiteId,
  categoryId,
  ...payload
})

export const fetchWebsiteMerchantCenterErrors = (
  websiteId,
  categoryId
) => async (dispatch) => {
  dispatch(fetchWebsiteMerchantCenterErrorsRequest(websiteId, categoryId))

  var response = await $.ajax({
    url: `/ad_champion/websites/${websiteId}/merchant_center/errors?category_id=${categoryId}`
  })
  var payload = camelCaseKeys(response, { deep: true })
  dispatch(
    fetchWebsiteMerchantCenterErrorsResponse(websiteId, categoryId, payload)
  )
  return payload
}

const fetchMerchantCenterErrorTypesRequest = (ids) => ({
  type: FETCH_MERCHANT_CENTER_ERROR_TYPES_REQUEST,
  ids
})

const fetchMerchantCenterErrorTypesResponse = (ids, payload) => ({
  type: FETCH_MERCHANT_CENTER_ERROR_TYPES_RESPONSE,
  ids,
  ...payload
})

export const fetchMerchantCenterErrorTypes = (ids) => async (dispatch) => {
  dispatch(fetchMerchantCenterErrorTypesRequest(ids))
  var response = await $.ajax({
    url: `/ad_champion/google/merchant_center/error_types`,
    data: { ids }
  })
  var payload = camelCaseKeys(response, { deep: true })
  dispatch(fetchMerchantCenterErrorTypesResponse(ids, payload))
  return payload
}

const fetchMerchantCenterErrorCategoriesRequest = (ids) => ({
  type: FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_REQUEST,
  ids
})

const fetchMerchantCenterErrorCategoriesResponse = (ids, payload) => ({
  type: FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE,
  ids,
  ...payload
})

export const fetchMerchantCenterErrorCategories = (ids) => async (dispatch) => {
  if (!ids || ids.length === 0) { return }
  dispatch(fetchMerchantCenterErrorCategoriesRequest(ids))
  var response = await $.ajax({
    url: `/ad_champion/google/merchant_center/error_categories`,
    data: { ids }
  })
  var payload = camelCaseKeys(response, { deep: true })
  dispatch(fetchMerchantCenterErrorCategoriesResponse(ids, payload))
  return payload
}
