import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const UPLOAD_IMPORT_FILE_REQUEST = 'UPLOAD_IMPORT_FILE_REQUEST'
export const UPLOAD_IMPORT_FILE_RESPONSE = 'UPLOAD_IMPORT_FILE_RESPONSE'
export const FETCH_FILE_IMPORT_SOURCE_HEADERS_REQUEST =
  'FETCH_FILE_IMPORT_SOURCE_HEADERS_REQUEST'
export const FETCH_FILE_IMPORT_SOURCE_HEADERS_RESPONSE =
  'FETCH_FILE_IMPORT_SOURCE_HEADERS_RESPONSE'
export const SAVE_FILE_IMPORT_PRODUCTS_REQUEST =
  'SAVE_FILE_IMPORT_PRODUCTS_REQUEST'
export const SAVE_FILE_IMPORT_PRODUCTS_RESPONSE =
  'SAVE_FILE_IMPORT_PRODUCTS_RESPONSE'

const uploadImportFileRequest = (websiteId, formData) => ({
  type: UPLOAD_IMPORT_FILE_REQUEST,
  websiteId,
  formData
})

const uploadImportFileResponse = (websiteId, formData, response) => ({
  type: UPLOAD_IMPORT_FILE_RESPONSE,
  websiteId,
  formData,
  ...response
})

export const uploadImportFile = (websiteId, formData) => {
  return async (dispatch) => {
    dispatch(uploadImportFileRequest(websiteId, formData))

    const response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/replacements/import_products/upload_import_file`,
      data: formData,
      processData: false,
      contentType: false
    })

    const camelCaseResponse = camelCaseKeys(response, { deep: true })
    dispatch(uploadImportFileResponse(websiteId, formData, camelCaseResponse))
    return camelCaseResponse
  }
}

const fetchFileImportSourceHeadersRequest = (websiteId, fileUrl) => ({
  type: FETCH_FILE_IMPORT_SOURCE_HEADERS_REQUEST,
  websiteId,
  fileUrl
})

const fetchFileImportSourceHeadersResponse = (
  websiteId,
  fileUrl,
  response
) => ({
  type: FETCH_FILE_IMPORT_SOURCE_HEADERS_RESPONSE,
  websiteId,
  fileUrl,
  ...response
})

const getFileImportSourceHeaders = async (websiteId, fileUrl) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const queryString = `?file_url=${encodeURIComponent(fileUrl)}`
  const url = `/ad_champion/websites/${websiteId}/replacements/import_products/fetch_file_headers${queryString}`
  var response = await $.ajax({ method: 'GET', url, statusCode })
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax({ method: 'GET', url, statusCode })
  }

  return camelCaseKeys(response, { deep: true })
}

export const fetchFileImportSourceHeaders = (websiteId, fileUrl) => {
  return async (dispatch) => {
    dispatch(fetchFileImportSourceHeadersRequest(websiteId, fileUrl))

    const camelCaseResponse = await getFileImportSourceHeaders(
      websiteId,
      fileUrl
    )

    dispatch(
      fetchFileImportSourceHeadersResponse(
        websiteId,
        fileUrl,
        camelCaseResponse
      )
    )

    return camelCaseResponse
  }
}

const saveFileImportProductsRequest = (
  websiteId,
  fileUrl,
  attributesMapping,
  importSettings
) => ({
  type: SAVE_FILE_IMPORT_PRODUCTS_REQUEST,
  websiteId,
  fileUrl,
  attributesMapping,
  importSettings
})

const saveFileImportProductsResponse = (
  websiteId,
  fileUrl,
  attributesMapping,
  importSettings,
  response
) => ({
  type: SAVE_FILE_IMPORT_PRODUCTS_RESPONSE,
  websiteId,
  fileUrl,
  attributesMapping,
  importSettings,
  ...response
})

const fileImportPorducts = async (
  websiteId,
  fileUrl,
  attributesMapping,
  importSettings
) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const url = `/ad_champion/websites/${websiteId}/replacements/import_products/import_file_products`
  const data = snakeCaseKeys({
    fileUrl,
    attributesMapping: JSON.stringify(snakeCaseKeys(attributesMapping)),
    importSettings: JSON.stringify(snakeCaseKeys(importSettings))
  })
  var response = await $.ajax({ method: 'POST', url, data, statusCode })
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax({ method: 'POST', url, data, statusCode })
  }

  return camelCaseKeys(response, { deep: true })
}

export const saveFileImportProducts =
  (websiteId, fileUrl, attributesMapping, importSettings) =>
  async (dispatch) => {
    var args = [websiteId, fileUrl, attributesMapping, importSettings]
    dispatch(saveFileImportProductsRequest(...args))

    const camelCaseResponse = await fileImportPorducts(...args)

    dispatch(saveFileImportProductsResponse(...args, camelCaseResponse))
    return camelCaseResponse
  }
