import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_INVENTORY_ATTRIBUTES_REQUEST =
  'FETCH_INVENTORY_ATTRIBUTES_REQUEST'
export const FETCH_INVENTORY_ATTRIBUTES_RESPONSE =
  'FETCH_INVENTORY_ATTRIBUTES_RESPONSE'
export const FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST =
  'FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST'
export const FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE =
  'FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE'
export const SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST =
  'SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST'
export const SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE =
  'SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE'
export const UPDATE_INVENTORY_ATTRIBUTE_REPLACEMENTS =
  'UPDATE_INVENTORY_ATTRIBUTE_REPLACEMENTS'
export const CLEAR_UPDATED_INVENTORY_ATTRIBUTE_REPLACEMENTS =
  'CLEAR_UPDATED_INVENTORY_ATTRIBUTE_REPLACEMENTS'
export const CLEAR_INVENTORY_ATTRIBUTE_REPLACEMENTS =
  'CLEAR_INVENTORY_ATTRIBUTE_REPLACEMENTS'
export const SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST =
  'SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST'
export const SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE =
  'SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE'

const fetchInventoryAttributesRequest = (websiteId) => ({
  type: FETCH_INVENTORY_ATTRIBUTES_REQUEST,
  websiteId
})

const fetchInventoryAttributesResponse = (websiteId, response) => ({
  type: FETCH_INVENTORY_ATTRIBUTES_RESPONSE,
  websiteId,
  ...response
})

export const fetchInventoryAttributes =
  (websiteId, available) => async (dispatch) => {
    dispatch(fetchInventoryAttributesRequest(websiteId))

    var response = {}
    while (true) {
      response = await $.ajax({
        method: 'GET',
        url: `/ad_champion/websites/${websiteId}/replacements/attributes`,
        data: snakeCaseKeys({ available }, { deep: true }),
        dataType: 'json'
      })
      if (response.attributes && response.attributes.length > 0) {
        break
      }
      await sleep(500)
    }
    response['availableAttributes'] = available
    dispatch(
      fetchInventoryAttributesResponse(
        websiteId,
        camelCaseKeys(response, { deep: true })
      )
    )
    return response
  }

const fetchInventoryAttributeReplacementsRequest = (
  websiteId,
  attributeId
) => ({
  type: FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST,
  websiteId,
  attributeId
})

const fetchInventoryAttributeReplacementsResponse = (
  websiteId,
  attributeId,
  response
) => ({
  type: FETCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  websiteId,
  attributeId,
  ...response
})

export const fetchInventoryAttributeReplacements =
  (websiteId, attributeId) => async (dispatch) => {
    dispatch(fetchInventoryAttributeReplacementsRequest(websiteId, attributeId))
    var camelResponse = null
    var page = 1
    var perPage = 50
    var replacements = []

    do {
      var response = await $.ajax({
        method: 'GET',
        url: `/ad_champion/websites/${websiteId}/replacements/attribute_replacements`,
        data: snakeCaseKeys({ page, perPage, attributeId }, { deep: true }),
        dataType: 'json'
      })

      camelResponse = camelCaseKeys(response, { deep: true })
      replacements.push(camelResponse.replacements)
      page += 1
    } while (!camelResponse.lastPage)

    replacements = { replacements: replacements.flat() }

    dispatch(
      fetchInventoryAttributeReplacementsResponse(
        websiteId,
        attributeId,
        replacements
      )
    )
    return replacements
  }

const searchInventoryAttributeReplacementsRequest = (
  websiteId,
  attributes,
  searchTerm
) => ({
  type: SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST,
  websiteId,
  attributes,
  searchTerm
})

const searchInventoryAttributeReplacementsResponse = (
  websiteId,
  attributes,
  searchTerm,
  response
) => ({
  type: SEARCH_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  websiteId,
  ...{ attributes, searchTerm },
  ...response
})

export const searchInventoryAttributeReplacements =
  (websiteId, attributes, searchTerm) => async (dispatch) => {
    dispatch(
      searchInventoryAttributeReplacementsRequest(
        websiteId,
        attributes,
        searchTerm
      )
    )
    var camelResponse = null
    var page = 1
    var perPage = 50
    var replacements = []

    do {
      var response = await $.ajax({
        method: 'GET',
        url: `/ad_champion/websites/${websiteId}/replacements/attribute_replacements_search`,
        data: snakeCaseKeys({ page, perPage, searchTerm }, { deep: true }),
        dataType: 'json'
      })

      camelResponse = camelCaseKeys(response, { deep: true })
      replacements.push(camelResponse.replacements)
      page += 1
    } while (!camelResponse.lastPage)

    replacements = { replacements: replacements.flat() }

    dispatch(
      searchInventoryAttributeReplacementsResponse(
        websiteId,
        attributes,
        searchTerm,
        replacements
      )
    )
    return replacements
  }

export const updateInventoryAttributeReplacements =
  (websiteId, payload) => (dispatch) => {
    dispatch({
      type: UPDATE_INVENTORY_ATTRIBUTE_REPLACEMENTS,
      websiteId,
      ...payload
    })
  }

const saveInventoryAttributeReplacementsRequest = (
  websiteId,
  replacements
) => ({
  type: SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_REQUEST,
  websiteId,
  ...replacements
})

const saveInventoryAttributeReplacementsResponse = (
  websiteId,
  replacements,
  response
) => ({
  type: SAVE_INVENTORY_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  websiteId,
  ...replacements,
  response
})

export const saveInventoryAttributeReplacements =
  (websiteId, replacements) => async (dispatch) => {
    dispatch(saveInventoryAttributeReplacementsRequest(websiteId, replacements))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/replacements/save_attribute_replacements`,
      data: {
        replacements: JSON.stringify(replacements)
      },
      dataType: 'json'
    })

    dispatch(
      saveInventoryAttributeReplacementsResponse(
        websiteId,
        replacements,
        response
      )
    )
    return response
  }

export const clearUpdatedInventoryAttributeReplacements =
  (websiteId, attributeReplacements) => (dispatch) => {
    dispatch({
      type: CLEAR_UPDATED_INVENTORY_ATTRIBUTE_REPLACEMENTS,
      websiteId,
      ...{ attributeReplacements }
    })
  }

export const clearInventoryAttributeReplacements =
  (websiteId) => (dispatch) => {
    dispatch({
      type: CLEAR_INVENTORY_ATTRIBUTE_REPLACEMENTS,
      websiteId
    })
  }
