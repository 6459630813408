import { FETCH_COUNTRY_GEO_TARGET_RESPONSE } from 'actions/geo_targets'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY_GEO_TARGET_RESPONSE:
      return {
        ...state,
        countries: action.countries || {}
      }
    default:
      return state
  }
}
