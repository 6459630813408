import { FETCH_VALUE_OPERATORS_RESPONSE } from 'actions/value/operators'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALUE_OPERATORS_RESPONSE:
      const operators = action.operators.reduce(
        (index, op) => ({ ...index, [op.id]: op }),
        {}
      )
      return {
        ...state,
        ...operators
      }

    default:
      return state
  }
}
