import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'

export class HeaderSelectorDropdown extends Component {
  static propTypes = {
    float: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    validOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedOptionId: PropTypes.number,
    optionChange: PropTypes.func.isRequired
  }

  renderDropdownToggle() {
    const { validOptions, selectedOptionId } = this.props
    var selectedOption =
      validOptions.filter((o) => o.id === selectedOptionId)[0] || {}

    return (
      <MDBDropdownToggle nav caret>
        <label className='active'>
          {selectedOption.name} | {selectedOption.currency}{' '}
        </label>
      </MDBDropdownToggle>
    )
  }

  handleSelectOptionClick(selectedOptionId) {
    const { optionChange } = this.props
    optionChange(selectedOptionId)
  }

  renderDropdownMenu() {
    const { validOptions } = this.props

    return (
      <MDBDropdownMenu>
        {validOptions.map((o) => (
          <MDBDropdownItem
            key={o.id}
            onClick={this.handleSelectOptionClick.bind(this, o.id)}
          >
            {o.name} | {o.currency}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  render() {
    const { validOptions, float, disabled } = this.props

    return validOptions.length > 1 ? (
      <MDBDropdown
        className={`channel-selector-dropdown float-${float} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {this.renderDropdownToggle()}
        {this.renderDropdownMenu()}
      </MDBDropdown>
    ) : null
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderSelectorDropdown)
)
