import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter
} from 'mdbreact'
import { adwordsAccountLinkingStatus } from 'actions/adwords_account'
import {
  websiteIdSelector,
  websiteAdwordsSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import AdwordsForm from 'components/platform/website/channel/google/adwords_form'
import CustomerHelper from 'components/customer_helper/customer_helper'
import CardNavButton from 'components/card_nav_button'

export const cardTitle = 'Link Google Ads with Google Merchant Center'

export class GoogleAdsConnectionCard extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    adwordsAccount: PropTypes.object.isRequired,
    channel: PropTypes.string.isRequired,
    feedId: PropTypes.number.isRequired,
    adwordsAccountLinkingStatus: PropTypes.func.isRequired,
    mcLinkRequired: PropTypes.bool.isRequired,
    showFooter: PropTypes.bool
  }

  static defaultProps = {
    showFooter: true
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      accountCreated: false
    }
  }

  componentDidMount() {
    this.adwordsAccountLinkingStatus()
  }

  componentDidUpdate(prevProps) {
    const { adwordsAccount } = this.props
    const adwordsAccountId = adwordsAccount.adwordsAccountId
    if (
      adwordsAccountId !== prevProps.adwordsAccount.adwordsAccountId &&
      !adwordsAccount.connected
    ) {
      this.adwordsAccountLinkingStatus()
    }
  }

  async adwordsAccountLinkingStatus() {
    const { adwordsAccountLinkingStatus, websiteId } = this.props
    await adwordsAccountLinkingStatus(websiteId)
  }

  renderAdsConnectionStatus() {
    const { adwordsAccount } = this.props
    return (
      <div className='content'>
        <MDBCardText tag='span'>
          <ul>
            <li>
              Status: {adwordsAccount.connected ? 'Connected' : 'Not Linked'}
            </li>
            <li>Ads Account ID: {adwordsAccount.adwordsAccountId} </li>
          </ul>
        </MDBCardText>
      </div>
    )
  }

  renderConnectionOptions() {
    const { mcLinkRequired } = this.props

    return (
      <AdwordsForm className='adwords-form' mcLinkRequired={mcLinkRequired} />
    )
  }

  renderConnectFooter() {
    return (
      <MDBCardFooter>
        <CustomerHelper
          helperName='Not sure what to do?'
          articleId='google-ads-connection'
          bookAMeetingType='feedops'
        />
      </MDBCardFooter>
    )
  }

  render() {
    const { adwordsAccount, showFooter } = this.props
    const adwordsAccountId = adwordsAccount.adwordsAccountId
    return (
      <MDBCard className='google-ads-connection-card'>
        <MDBCardBody>
          <MDBCardTitle>
            {cardTitle}
            {adwordsAccountId && (
              <CardNavButton
                label='ACCESS'
                destination='https://ads.google.com/'
                float='right'
              />
            )}
          </MDBCardTitle>
          <div className='content'>
            {adwordsAccountId
              ? this.renderAdsConnectionStatus()
              : this.renderConnectionOptions()}
          </div>
        </MDBCardBody>
        {!adwordsAccountId && showFooter && this.renderConnectFooter()}
      </MDBCard>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId,
    adwordsAccount: websiteAdwordsSelector(state, props),
    channel: channelSelector(state, props),
    feedId: websiteFeedIdByChannelSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ adwordsAccountLinkingStatus }, dispatch)

const GoogleAdsConnectionCardWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAdsConnectionCard)

export default withRouter(GoogleAdsConnectionCardWithRedux)
