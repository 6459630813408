import {
  FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS,
  FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE,
  FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_RESPONSE,
  FETCH_MERCHANT_CENTER_ERROR_TYPES_RESPONSE,
  FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE,
  REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS
} from 'actions/merchant_center_product_errors'

const initialState = {
  categories: {},
  errorTypes: {},
  errors: {},
  websites: {}
}

const storeWebsiteErrorCategories = (state, action) => {
  const existingWebsiteData = state.websites[action.websiteId] || {}
  return {
    ...state,
    websites: {
      ...state.websites,
      [action.websiteId]: {
        ...existingWebsiteData,
        categoryIds: {
          ...existingWebsiteData.categoryIds,
          [action.scope]: action.categoryIds
        }
      }
    }
  }
}

const mergeData = (arr, base) =>
  arr.reduce((obj, arrayItem) => ({ ...obj, [arrayItem.id]: arrayItem }), base)
const storeErrorTypes = (state, action) => ({
  ...state,
  errorTypes: mergeData(action.types, state.errorTypes)
})

const storeErrorCategories = (state, action) => ({
  ...state,
  categories: mergeData(action.categories, state.categories)
})

const storeErrors = (state, action) => ({
  ...state,
  errors: { ...action }
})

export const merchantCenterProductErrorsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS:
      return {
        ...state,
        [action.websiteId]: action.merchantCenterProductErrors
      }
    case FETCH_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE:
      return storeErrorCategories(state, action)
    case FETCH_MERCHANT_CENTER_ERROR_TYPES_RESPONSE:
      return storeErrorTypes(state, action)
    case FETCH_WEBSITE_MERCHANT_CENTER_ERROR_CATEGORIES_RESPONSE:
      return storeWebsiteErrorCategories(state, action)
    case FETCH_WEBSITE_MERCHANT_CENTER_ERRORS_RESPONSE:
      return storeErrors(state, action)
    case REFRESH_MERCHANT_CENTER_PRODUCT_ERRORS_SUCCESS:
      return { ...state, refreshAt: action.refreshAt }
    default:
      return state
  }
}

export default merchantCenterProductErrorsReducer
