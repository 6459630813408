import _ from 'lodash'
import camelCase from 'camel-case'
import { CHANNEL_GOOGLE } from 'util/supported_channels'
import {
  PRICE_ATTRIBUTES,
  GOOGLE_PRODUCT_CATEGORY,
  QUANTITY
} from 'util/product_attributes'

export const NO_VALUE = 'Empty'

const COLOUR_COLUMN_HEADER = 'Color'
const AUTO_MAPPED_BY_GOOGLE = 'Auto Mapped by Google'

export const getAttributeByColumnName = (attributes, columnName) => {
  const attribute = attributes.find((attr) => attr.columnName === columnName)
  var attributeName = ''

  if (attribute && Object.keys(attribute).length > 0) {
    attributeName = camelCase(attribute.name)
    if (attribute.name === COLOUR_COLUMN_HEADER) {
      attributeName = 'colour'
    }
  }

  return { ...attribute, field: attributeName, title: columnName }
}

const checkValueType = (override, attributeName) => {
  if (typeof override === 'number') {
    if (attributeName === QUANTITY) {
      return override.toString()
    }
    return override.toFixed(2).toString()
  } else if (typeof override === 'boolean') {
    return override.toString()
  } else {
    return override.length > 0 ? override : NO_VALUE
  }
}

const updatePriceValue = (value, attributeName, currency) => {
  if (
    value !== NO_VALUE &&
    attributeName.toLowerCase().includes(PRICE_ATTRIBUTES)
  ) {
    value = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(value)
  }

  return value
}

const getGoogleProductCategoryValue = (value, channel) => {
  if (value === NO_VALUE && channel === CHANNEL_GOOGLE) {
    value = AUTO_MAPPED_BY_GOOGLE
  }

  return value
}

export const getDisplayValue = (params, value) => {
  const { attributeName, currency, channel } = params
  value = checkValueType(value, attributeName)
  value = updatePriceValue(value, attributeName, currency)

  if (attributeName === GOOGLE_PRODUCT_CATEGORY) {
    value = getGoogleProductCategoryValue(value, channel)
  }

  return value
}

export const attributeCategoryNames = (attributes) => {
  var categoryNames = []
  _.sortBy(attributes, 'columnCategoryPosition').forEach((attribute) => {
    if (!categoryNames.includes(attribute.columnCategoryName)) {
      categoryNames.push(attribute.columnCategoryName)
    }
  })

  return categoryNames
}

export const getAllFilterableAttributes = (attributes, appliedFilters) => {
  const appliedAttributesNames =
    appliedFilters.length > 0 ? appliedFilters.map((a) => a.attributeName) : []
  const availableAttributes = attributes.filter(
    (attribute) =>
      attribute.filterable && !appliedAttributesNames.includes(attribute.name)
  )

  return availableAttributes
}

export const getAllMappableAttributes = (
  attributes,
  mappableAttributes,
  existingMappings
) => {
  const mappableAttributesNames =
    mappableAttributes.length > 0
      ? mappableAttributes.map((a) => a.displayName)
      : []
  const existingAttributesNames =
    existingMappings.length > 0
      ? existingMappings.map((a) => a.displayName)
      : []
  const availableAttributes = attributes.filter(
    (attribute) =>
      mappableAttributesNames.includes(attribute.columnName) &&
      !existingAttributesNames.includes(attribute.columnName)
  )

  return availableAttributes
}

const withInSelectedSuggestions = (selectedSuggestions, fields) => {
  for (var index = 0; index < selectedSuggestions.length; index++) {
    const suggestionsPresent = Object.keys(selectedSuggestions[index]).filter(
      (suggestion) => fields.includes(suggestion)
    )
    if (suggestionsPresent && suggestionsPresent.length > 0) {
      return true
    }
  }

  return false
}

export const optimisationSuggestionsPresent = (
  allRowsSelected,
  selectedRows,
  optimisationSuggestions,
  selectedColumns
) => {
  if (allRowsSelected) {
    return true
  }

  const selectedRowsItemIds = selectedRows.map((row) => row.itemId)
  const selectedSuggestions = optimisationSuggestions.map((os) => {
    return selectedRowsItemIds.includes(os.itemId) ? os.suggestions : {}
  })

  return withInSelectedSuggestions(selectedSuggestions, selectedColumns)
}

const getOptimisationData = (
  optimisationSuggestions,
  rowData,
  attributeName,
  field
) => {
  const optimisationSuggestion = optimisationSuggestions.find(
    (suggestion) => suggestion.itemId === rowData.itemId
  )

  if (optimisationSuggestion) {
    const { suggestions } = optimisationSuggestion
    if (!_.isEmpty(suggestions) && !_.isEmpty(suggestions[attributeName])) {
      return suggestions[attributeName][field] || null
    }
  }

  return null
}

export const getAttributeOptimisationSuggestion = (
  optimisationSuggestions,
  rowData,
  attributeName
) => {
  return (
    getOptimisationData(
      optimisationSuggestions,
      rowData,
      attributeName,
      'optimisationValue'
    ) ||
    getOptimisationData(
      optimisationSuggestions,
      rowData,
      attributeName,
      'suggestion'
    )
  )
}

export const getAttributeOptimisationSource = (
  optimisationSuggestions,
  rowData,
  attributeName
) => {
  return getOptimisationData(
    optimisationSuggestions,
    rowData,
    attributeName,
    'source'
  )
}
