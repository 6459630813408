import { addDays } from 'date-fns'
import { createSelector } from 'reselect'

const defaultDateRange = {
  startDate: addDays(new Date(), -30),
  endDate: addDays(new Date(), -1)
}

export const dateRangesSelector = (state) => state.dateRanges

export const dateRangeSelector = (keyName) =>
  createSelector(
    [dateRangesSelector],
    (dateRanges) => dateRanges[keyName] || defaultDateRange
  )

export const getDateString = (dateInt) => {
  const date = new Date(dateInt)
  return !isNaN(date.getDate())
    ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    : ''
}
