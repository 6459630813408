import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux'
import { reduxSearch } from 'redux-search'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { Provider as RollbarProvider } from '@rollbar/react'
import {
  createTheme,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles'
import reducer from 'reducers'
import browserHistoryMiddleware from 'middleware/browser_history'
import pageTrackingMiddleware from 'middleware/page_tracking'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f9d58'
    },
    secondary: {
      main: '#34a853'
    }
  }
})

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g

const safePrefix = (classNamePrefix) => {
  const prefix = String(classNamePrefix)
  // Sanitize the string as will be used to prefix the generated class name.
  return prefix.replace(escapeRegex, '-')
}

var ruleCounter = 0
const generateClassName = (rule, styleSheet) => {
  if (styleSheet && styleSheet.options.classNamePrefix) {
    const prefix = safePrefix(styleSheet.options.classNamePrefix)

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`
    }

    return `${prefix}-${rule.key}-${++ruleCounter}`
  }

  return `${rule.key}-${++ruleCounter}`
}

const middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}
middleware.push(routerMiddleware(browserHistoryMiddleware))
middleware.push(pageTrackingMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(...middleware),
    reduxSearch({
      // Configure redux-search by telling it which resources to index for searching
      resourceIndexes: {
        'promotions.list': ['description', 'coupon'],
        'promotions.promotionDetail.items': ['sku', 'product']
      },
      // This selector is responsible for returning each collection of searchable resources
      resourceSelector: (resourceName, state) => {
        var parts = resourceName.split('.')
        var resource = state
        parts.forEach((p) => {
          resource = resource[p]
        })
        return resource
      }
    })
  )
)

syncHistoryWithStore(browserHistoryMiddleware, store)

const renderWithContext = (child) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>{child}</Provider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default renderWithContext
