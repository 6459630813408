import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  reduxForm,
  getFormValues,
  registerField,
  initialize,
  change,
  isDirty,
  isSubmitting,
  Form
} from 'redux-form'
import { MDBSelect, MDBBtn, MDBIcon } from 'mdbreact'
import { listAdwordsAccounts } from 'actions/adwords_account'
import { createAdwordsAccountAndSendInvitation } from 'actions/adwords_account'
import { sendAdwordsAccountLink } from 'actions/google/merchant_center'
import { fetchWebsiteDetail } from 'actions/websites'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  websiteIdSelector,
  merchantCenterSetupCompleteSelector
} from 'selectors/websites'
import { adwordsAccountIdSelector } from 'selectors/google/accounts'
import { adwordsAccountsSelector } from 'selectors/adwords_account'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import GoogleUserContext from 'components/google/google_user_context'
import GenericContent from 'components/websites/workflow/generic_content'

const ADWORDS_API = 'https://www.googleapis.com/auth/adwords'

export class AdwordsForm extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    adwordsAccountId: PropTypes.string.isRequired,
    adwordsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    listAdwordsAccounts: PropTypes.func.isRequired,
    createAdwordsAccountAndSendInvitation: PropTypes.func.isRequired,
    sendAdwordsAccountLink: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    mcLinkRequired: PropTypes.bool.isRequired,
    merchantCenterConnected: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { fetchingAdsAccounts: false }
  }

  componentDidMount() {
    const { registerField, initialize, adwordsAccountId } = this.props
    registerField(REDUX_FORM_NAME, 'adwordsAccountId', 'Field')
    initialize({ adwordsAccountId })
  }

  componentDidUpdate(prevProps) {
    const { initialize, adwordsAccountId } = this.props
    if (adwordsAccountId && adwordsAccountId !== prevProps.adwordsAccountId) {
      initialize({ adwordsAccountId })
    }
  }

  async fetchAdsAccounts() {
    this.setState({ fetchingAdsAccounts: true })
    const response = await this.props.listAdwordsAccounts()
    if (!response) {
      toastr.error(
        'An error occurred while fetching your Google Ads accounts. Please contact support for assistance.'
      )
    }
    this.setState({ fetchingAdsAccounts: false })
  }

  async createAdwordsAccountAndSendInvitation(adwordsAccountId) {
    const {
      websiteId,
      createAdwordsAccountAndSendInvitation,
      merchantCenterConnected
    } = this.props
    const response = await createAdwordsAccountAndSendInvitation(
      websiteId,
      adwordsAccountId
    )
    if (!response.linked) {
      toastr.error(
        'An error occurred while trying to connect to your Google Ads account. Please accept linking request from your Google Ads account.'
      )
    } else {
      toastr.success('Google Ads account is successfully connected.')
      if (!merchantCenterConnected) await this.fetchWebsiteDetail()
    }
    await this.sendAdwordsAccountLink()
  }

  async sendAdwordsAccountLink() {
    const { websiteId, sendAdwordsAccountLink, merchantCenterConnected } =
      this.props

    if (!merchantCenterConnected) return
    const response = await sendAdwordsAccountLink(websiteId)

    if (!response.linked) {
      toastr.error(
        'An error occurred while linking your Google Ads account & Merchant Center. Please accept linking request from your Google Ads account.'
      )
    } else {
      toastr.success(
        'Google Ads account & Merchant Center account linked successfully.'
      )
      await this.fetchWebsiteDetail()
    }
  }

  async fetchWebsiteDetail() {
    const { websiteId, fetchWebsiteDetail } = this.props
    await fetchWebsiteDetail(websiteId)
  }

  async handleSubmit(values, _dispatch, _props) {
    try {
      await this.createAdwordsAccountAndSendInvitation(values.adwordsAccountId)
    } catch (error) {
      toastr.error(error.responseJSON.error, 'ERROR')
    }
  }

  handleAccountChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      const { adwordsAccounts } = this.props
      var account = adwordsAccounts.find((account) => account.id === e[0])
      change(REDUX_FORM_NAME, 'adwordsAccountId', account.id)
    }
  }

  renderSelectLabel(account) {
    return `${account.name} (${account.id})`
  }

  renderAccountSelect() {
    const { adwordsAccounts, formValues } = this.props
    if (adwordsAccounts.length > 0) {
      var options = adwordsAccounts.map((account) => ({
        value: account.id,
        text: this.renderSelectLabel(account)
      }))
      var selectedOption =
        options.find((o) => o.value === formValues.adwordsAccountId) || {}
      return (
        <MDBSelect
          search
          className={options.length === 0 ? 'disabled' : null}
          options={options}
          selected={selectedOption.text || 'Select Google Ads Account'}
          labelClass='adwordsSelectField'
          getValue={this.handleAccountChange.bind(this)}
        />
      )
    } else {
      return (
        <div>
          There is no Google Ads account available under the current Google
          account. Please either switch to use another Google account or create
          a new Google Ads account.
        </div>
      )
    }
  }

  renderAccountForm() {
    const { fetchingAdsAccounts } = this.state
    if (fetchingAdsAccounts) {
      return (
        <IndeterminateProgressIndicator message='Loading your accounts (may take a few minutes).' />
      )
    }

    return this.renderAccountSelect()
  }

  renderSaveButton() {
    const { submit, valid, isSubmitting, mcLinkRequired } = this.props
    const buttonText = isSubmitting ? (
      <div>
        <MDBIcon spin fixed icon='spinner' />
        Connecting Ads Account
        {mcLinkRequired ? ' And Linking To Merchant Center' : ''}
      </div>
    ) : (
      `Connect Ads Account${
        mcLinkRequired ? ' And Link To Merchant Center' : ''
      }`
    )
    return (
      <MDBBtn
        color={'primary'}
        className={'next-action'}
        onClick={submit}
        disabled={!valid || isSubmitting}
      >
        {buttonText}
      </MDBBtn>
    )
  }

  renderForm() {
    const { isDirty } = this.props
    return (
      <GoogleUserContext
        scope={ADWORDS_API}
        authoriseText='Connect existing Google Ads account and link it with Google Merchant Center.'
        signInButtonText='Authorize Google Ads Access'
        onSuccess={this.fetchAdsAccounts.bind(this)}
      >
        <GenericContent component={this.renderAccountForm()} />
        {isDirty && this.renderSaveButton()}
      </GoogleUserContext>
    )
  }

  render() {
    const { handleSubmit } = this.props
    return (
      <Form
        className='adwords-form'
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
      >
        {this.renderForm()}
      </Form>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId,
    adwordsAccountId: adwordsAccountIdSelector(state, props),
    adwordsAccounts: adwordsAccountsSelector(state, props),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    isSubmitting: isSubmitting(REDUX_FORM_NAME)(state),
    isDirty: isDirty(REDUX_FORM_NAME)(state) || false,
    merchantCenterConnected: merchantCenterSetupCompleteSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listAdwordsAccounts,
      createAdwordsAccountAndSendInvitation,
      sendAdwordsAccountLink,
      fetchWebsiteDetail,
      registerField,
      initialize,
      change
    },
    dispatch
  )

const AdwordsFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdwordsForm)

export const REDUX_FORM_NAME = 'GoogleAdwordsForm'

export default withRouter(
  reduxForm({
    form: REDUX_FORM_NAME,
    ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
  })(AdwordsFormWithRedux)
)
