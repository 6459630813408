import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Switch, Route, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Provider, ErrorBoundary } from '@rollbar/react'
import { setSideNavOpen } from 'actions/app'
import { addMembership } from 'actions/users'
import { fetchCountryGeoTargets } from 'actions/geo_targets'
import { fetchFullWebsiteList } from 'actions/websites'
import { FEED_OPS_MENU_ITEMS } from './menu_items'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import FeedOpsContent from 'components/feed_ops/content'
import FeedOpsHeader from 'components/feed_ops/header'
import FeedOpsSideNav from 'components/feed_ops/side_nav'
import BookAMeetingModal from 'components/book_a_meeting_modal'
import ChannelFilterModal from 'components/channel_filter_modal'
import WorkEmailModal from 'components/work_email_modal'
import { settingsValueSelector } from 'selectors/settings'
import {
  currentUserSelector,
  loggedInSelector,
  profileCompleteSelector,
  superUserSelector
} from 'selectors/user'

import feedOpsFavicon from 'images/favicon.ico'

const rollbarConfig = {
  accessToken: '407560bbdf8940d7becb2bb8e8c6c704',
  captureUncaught: false,
  payload: { environment: process.env.NODE_ENV }
}

export class FeedOpsMain extends Component {
  static propTypes = {
    setSideNavOpen: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profileComplete: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    superUser: PropTypes.bool.isRequired,
    fetchCountryGeoTargets: PropTypes.func.isRequired,
    fetchFullWebsiteList: PropTypes.func.isRequired,
    addMembership: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      menuItems: [],
      firstLoadComplete: false
    }

    this.MENU_ITEMS = FEED_OPS_MENU_ITEMS
  }

  async componentDidMount() {
    await this.refreshMenuItems()
    this.props.setSideNavOpen(true)
    this.setState({ firstLoadComplete: true })
    if (this.props.invitationToken && this.props.loggedIn) {
      const { addMembership, currentUser, invitationToken } = this.props
      await addMembership(currentUser.email, invitationToken)
    }
    if (this.props.loggedIn) {
      await this.props.fetchCountryGeoTargets()
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.loggedIn !== this.props.loggedIn ||
      prevProps.superUser !== this.props.superUser
    ) {
      this.setState({ firstLoadComplete: false })
      await this.refreshMenuItems()
      this.setState({ firstLoadComplete: true })
    }
  }

  async refreshMenuItems() {
    const { superUser, fetchFullWebsiteList, loggedIn, profileComplete } =
      this.props
    var menuItems = this.MENU_ITEMS.filter(
      (mi) => superUser || !mi.superUserOnly
    )
    this.setState({ menuItems })
    loggedIn && profileComplete && (await fetchFullWebsiteList())
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>FeedOps</title>
        <link rel='icon' href={feedOpsFavicon} />
      </Helmet>
    )
  }

  renderModals() {
    const { match } = this.props

    return (
      <>
        <BookAMeetingModal />
        <Switch>
          <Route path={`${match.path}/:websiteId`}>
            <ChannelFilterModal />
            <WorkEmailModal />
          </Route>
        </Switch>
      </>
    )
  }

  renderFeedOpsSideNav() {
    const { match } = this.props
    const { menuItems } = this.state

    return (
      <Switch>
        <Route
          path={[
            `${match.path}/:accountType(accounts)/:accountId`,
            `${match.path}/:websiteId`
          ]}
        >
          <FeedOpsSideNav menuItems={menuItems} />
        </Route>
      </Switch>
    )
  }

  renderFeedOpsHeader() {
    const { match } = this.props
    const { menuItems } = this.state

    return (
      <Route path={`${match.path}/:websiteId?`}>
        <FeedOpsHeader menuItems={menuItems} />
      </Route>
    )
  }

  render() {
    const { firstLoadComplete } = this.state

    if (!firstLoadComplete) {
      return <AdChampionIndeterminateProgressIndicator />
    }

    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          {this.renderHelmet()}
          {this.renderModals()}
          {this.renderFeedOpsSideNav()}
          {this.renderFeedOpsHeader()}
          <FeedOpsContent />
        </ErrorBoundary>
      </Provider>
    )
  }
}

const mapStateToProps = (state, props) => ({
  loggedIn: loggedInSelector(state),
  profileComplete: profileCompleteSelector(state),
  superUser: superUserSelector(state),
  currentUser: currentUserSelector(state),
  invitationToken: settingsValueSelector(state, 'invitationToken')
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSideNavOpen,
      fetchCountryGeoTargets,
      fetchFullWebsiteList,
      addMembership
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsMain)
)
