import { isValid } from 'redux-form'
import { createSelector } from 'reselect'
import { REDUX_FORM_NAME as ANALYTICS_FORM } from 'components/google/analytics_form'
import { REDUX_FORM_NAME as MERCHANT_CENTER_FORM } from 'components/google/merchant_center_form'
import { REDUX_FORM_NAME as ADWORDS_FORM } from 'components/google/adwords_form'
import {
  websiteAdwordsSelector,
  websiteAnalyticsSelector,
  websiteMerchantCenterSelector,
  websitePlanTypeSelector
} from 'selectors/websites'

export const adwordsAccountIdSelector = createSelector(
  [websiteAdwordsSelector],
  (adwords) =>
    (adwords && adwords.adwordsAccountId) || (adwords && adwords.value) || ''
)

export const createAdwordsAccountSelector = createSelector(
  [websiteAdwordsSelector],
  (adwords) => adwords && adwords.createAccount
)

export const merchantCenterAccountIdSelector = createSelector(
  [websiteMerchantCenterSelector],
  (merchantCenter) =>
    (merchantCenter && merchantCenter.merchantCenterAccountId) || ''
)

export const merchantCenterAccountNameSelector = createSelector(
  [websiteMerchantCenterSelector],
  (merchantCenter) =>
    (merchantCenter && merchantCenter.merchantCenterAccountName) || ''
)

export const merchantCenterAccountOauthUserSelector = createSelector(
  [websiteMerchantCenterSelector],
  (merchantCenter) =>
    (merchantCenter && merchantCenter.oauthUser) || ''
)

export const createMerchantCenterAccountSelector = createSelector(
  [websiteMerchantCenterSelector],
  (merchantCenter) => merchantCenter && merchantCenter.newAccountRequired
)

export const nextUploadRemoveExistingPrimaryFeedsSelector = createSelector(
  [websiteMerchantCenterSelector],
  (merchantCenter) => merchantCenter.nextUploadRemoveExistingPrimaryFeeds
)

export const analyticsAccountIdSelector = createSelector(
  [websiteAnalyticsSelector],
  (analytics) => (analytics && analytics.analyticsAccountId) || ''
)

export const analyticsAccountNameSelector = createSelector(
  [websiteAnalyticsSelector],
  (analytics) => (analytics && analytics.analyticsAccountName) || ''
)

export const analyticsWebPropertyNameSelector = createSelector(
  [websiteAnalyticsSelector],
  (analytics) => (analytics && analytics.webPropertyName) || ''
)

export const analyticsWebPropertyIdSelector = createSelector(
  [websiteAnalyticsSelector],
  (analytics) => (analytics && analytics.webPropertyId) || ''
)

export const createAnalyticsAccountSelector = createSelector(
  [websiteAnalyticsSelector],
  (analytics) => analytics && analytics.newAccountRequired
)

export const googleAccountSetupCompleteSelector = createSelector(
  merchantCenterAccountIdSelector,
  analyticsAccountIdSelector,
  adwordsAccountIdSelector,
  (merchantCenterId, analyticsAccountId, adwordsAccountId) =>
    merchantCenterId.length > 0 &&
    analyticsAccountId.length > 0 &&
    adwordsAccountId.length > 0
)

export const allGoogleAccountFormsValid = createSelector(
  [
    isValid(ADWORDS_FORM),
    isValid(MERCHANT_CENTER_FORM),
    isValid(ANALYTICS_FORM),
    websitePlanTypeSelector
  ],
  (adwordsValid, merchantCenterValid, analyticsValid, websitePlan) => {
    if (websitePlan === 'free') {
      return true
    }
    return adwordsValid && merchantCenterValid && analyticsValid
  }
)
