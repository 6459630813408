import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import LeftPrimaryHeader from './header/primary_header/left_primary_header'
import RightPrimaryHeader from './header/primary_header/right_primary_header'
import RightSecondayHeader from './header/secondary_header/right_secondary_header'
import LeftTertiaryHeader from './header/tertiary_header/left_tertiary_header'
import RightTertiaryHeader from './header/tertiary_header/right_tertiary_header'
import { findMenuItem } from 'selectors/header'
import { websiteDetailSelector, listSelector } from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import {
  superUserSelector,
  accountTypeIdSelector,
  accountTypeSelector,
  loggedInSelector
} from 'selectors/user'
import { isEmbeddedAppSelector } from 'selectors/app'
import { abbreviateUrls, scrubDuplicate } from 'util/format_string'
import { isMobileOS } from 'util/operating_systems'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem
} from 'mdbreact'
import './header.scss'

export class Header extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    accountType: PropTypes.string.isRequired,
    superUser: PropTypes.bool.isRequired,
    websiteDetail: PropTypes.object.isRequired,
    list: PropTypes.object.isRequired,
    busy: PropTypes.bool.isRequired,
    onLogout: PropTypes.func,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    menuItem: PropTypes.object,
    sideNav: PropTypes.bool.isRequired,
    websitesMenu: PropTypes.bool.isRequired,
    secondaryHeader: PropTypes.bool.isRequired,
    isMobileOS: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired
  }

  static defaultProps = {
    sideNav: true,
    websitesMenu: true,
    secondaryHeader: false,
    helpButton: false,
    menuItems: [],
    isMobileOS
  }

  adsConfiguratorTitle() {
    const { location } = this.props
    return location.pathname.includes('ads_configurator')
      ? location.pathname.includes('brand')
        ? 'Brand Search Campaign Configuration'
        : 'Smart Shopping Campaign Configuration'
      : ''
  }

  navBarStyle() {
    return { paddingLeft: '16px' }
  }

  renderBusyIndicator() {
    const { busy } = this.props
    return (
      busy && (
        <AdChampionIndeterminateProgressIndicator className='fixed-top busy' />
      )
    )
  }

  renderPrimayHeader() {
    const { sideNav, websitesMenu, helpButton, settingsMenu, menuItem } =
      this.props

    const displayType = isMobileOS ? 'mobile' : 'desktop'

    const showSideNav =
      sideNav && (typeof menuItem?.leftNavbar == 'undefined' ? true : false)

    return (
      <MDBNavbar
        style={this.navBarStyle()}
        dark={true}
        double={true}
        expand='md'
        fixed='top'
        className={`platform-header ${displayType}`}
      >
        {this.renderBusyIndicator()}
        <LeftPrimaryHeader sideNav={showSideNav} />
        <RightPrimaryHeader
          websitesMenu={websitesMenu}
          helpButton={helpButton}
          settingsMenu={settingsMenu}
        />
      </MDBNavbar>
    )
  }

  handleBreadcrumbClick(type, id) {
    const { appId } = this.props
    this.props.history.push(
      `/${appId}${type === 'websites' ? '' : `/${type}`}/${id}`,
      {}
    )
  }

  renderBreadcrumbItem(type, id, label, spacer, disabled) {
    const { accountType } = this.props
    return (
      <MDBBreadcrumbItem icon={spacer ? 'chevron-right' : undefined}>
        <MDBBtn
          disabled={disabled || type === accountType}
          size='sm'
          className='breadcrumb-btn'
          onClick={this.handleBreadcrumbClick.bind(this, type, id)}
        >
          {abbreviateUrls(label)}
        </MDBBtn>
      </MDBBreadcrumbItem>
    )
  }

  conditionallyRenderMasterAccountBreadcrumb() {
    const { list, appId } = this.props
    if (appId === process.env.FEEDOPS_APP_ID) {
      return null
    }

    const masterAccount = (list || {}).masterAccount
    return (
      masterAccount &&
      this.renderBreadcrumbItem(
        'master_accounts',
        masterAccount.id,
        masterAccount.label,
        false,
        (masterAccount.accountIds || []).length <= 1
      )
    )
  }

  conditionallyRenderAccountBreadcrumb() {
    const { accountType, list, superUser, appId } = this.props
    const account = (list || {}).account
    if (appId === process.env.FEEDOPS_APP_ID) {
      return null
    }
    return (
      (accountType === 'accounts' ||
        accountType === 'websites' ||
        accountType === 'budget_categories') &&
      account &&
      this.renderBreadcrumbItem(
        'accounts',
        account.id,
        superUser ? account.label : scrubDuplicate(account.label),
        (list || {}).masterAccount,
        (account.websiteIds || []).length <= 1
      )
    )
  }

  conditionallyRenderWebsiteBreadcrumb() {
    const { accountType, list } = this.props
    const website = (list || {}).website
    return (
      (accountType === 'websites' || accountType === 'budget_categories') &&
      website &&
      this.renderBreadcrumbItem(
        'websites',
        website.id,
        website.name,
        (list || {}).account,
        false
      )
    )
  }

  conditionallyRenderBudgetCategoryBreadcrumb() {
    const { accountType, list } = this.props
    const budgetCategory = (list || {}).budgetCategory
    return (
      accountType === 'budget_categories' &&
      budgetCategory &&
      this.renderBreadcrumbItem(
        'budget_categories',
        budgetCategory.id,
        budgetCategory.name,
        (list || {}).website,
        false
      )
    )
  }

  renderBreadcrumbs() {
    return (
      <MDBNavItem>
        <MDBBreadcrumb>
          {this.conditionallyRenderMasterAccountBreadcrumb()}
          {this.conditionallyRenderAccountBreadcrumb()}
          {this.conditionallyRenderWebsiteBreadcrumb()}
          {this.conditionallyRenderBudgetCategoryBreadcrumb()}
        </MDBBreadcrumb>
      </MDBNavItem>
    )
  }

  determineWebsiteDisplayName(title) {
    const { websiteDetail } = this.props
    const isOptimizationPage = title.includes('Optimize with AI')

    if (isOptimizationPage) {
      return websiteDetail.name
    }

    return false
  }

  renderSecondaryLeftNavBar() {
    const { menuItem, isEmbeddedApp } = this.props
    const { displayFor } = menuItem
    const websiteDisplayName = this.determineWebsiteDisplayName(menuItem.title)
    const title = menuItem.title || this.adsConfiguratorTitle()

    return (
      <MDBNavbarNav left>
        <MDBNavItem className='title'>
          {title}
          {websiteDisplayName && (
            <span className='current-website'>{websiteDisplayName}</span>
          )}
        </MDBNavItem>
        {displayFor &&
          displayFor.accountAndMasterAccount &&
          !isEmbeddedApp &&
          this.renderBreadcrumbs()}
      </MDBNavbarNav>
    )
  }

  conditionallyRenderSecondaryHeader() {
    const { secondaryHeader, loggedIn, menuItem } = this.props
    return secondaryHeader && loggedIn ? (
      <MDBNavbar
        style={this.navBarStyle()}
        expand='md'
        fixed='top'
        className='platform-secondary-header'
      >
        {menuItem.title !== 'FeedOps' && (
          <>
            {this.renderSecondaryLeftNavBar()}
            <RightSecondayHeader menuItem={menuItem} />
          </>
        )}
      </MDBNavbar>
    ) : null
  }

  conditionallyRenderTertiaryHeader() {
    const { menuItem, loggedIn } = this.props

    return menuItem.tertiaryHeader && loggedIn ? (
      <MDBNavbar
        style={this.navBarStyle()}
        expand='md'
        fixed='top'
        className='platform-tertiary-header'
      >
        <LeftTertiaryHeader menuItem={menuItem} />
        <RightTertiaryHeader menuItem={menuItem} />
      </MDBNavbar>
    ) : null
  }

  render() {
    const { menuItem, accountType, websiteDetail } = this.props
    const title = menuItem.title || this.adsConfiguratorTitle()

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {`${title}${
              accountType === 'websites' && websiteDetail.url
                ? ` | ${abbreviateUrls(websiteDetail.url)}`
                : ''
            }`}
          </title>
        </Helmet>
        {this.renderPrimayHeader()}
        {!isMobileOS && this.conditionallyRenderSecondaryHeader()}
        {!isMobileOS && this.conditionallyRenderTertiaryHeader()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const accountType = accountTypeSelector(state, props)
  const id = accountTypeIdSelector(state, props)
  const appId = props.match.params.appId || 'ad_builder'
  const sectionPath = props.location.pathname
    .replace(
      accountType === 'websites' ? `/${id}` : `/${accountType}/${id}`,
      ''
    )
    .replace(`/${appId}/`, '')
  const menuItems = props.menuItems || []
  const menuItem = findMenuItem(menuItems, sectionPath, appId)

  return {
    id,
    appId,
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    menuItem,
    accountType,
    channel: channelSelector(state, props),
    superUser: superUserSelector(state),
    loggedIn: loggedInSelector(state),
    websiteDetail: websiteDetailSelector(state, props),
    list: listSelector(accountType, id)(state, props),
    busy: state.app.pendingRequests.length > 0,
    inventoryReconcileStatus:
      initialReconciledStatusSelector(state, props) || false
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
