import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { change, reduxForm, getFormValues } from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import _ from 'lodash'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBCardFooter
} from 'mdbreact'
import FormButton from 'components/form_button'
import {
  fetchAccountAndWebsiteSubscription,
  updateAccountAndWebsiteSubscription
} from 'actions/billing'
import { websiteIdSelector } from 'selectors/websites'
import {
  accountSubscriptionSelector,
  websiteSubscriptionSelector
} from 'selectors/billing'

export class FeedOpsSubscriptions extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    accountSubscriptionDetails: PropTypes.object.isRequired,
    websiteSubscriptionDetails: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    fetchAccountAndWebsiteSubscription: PropTypes.func.isRequired,
    updateAccountAndWebsiteSubscription: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  async componentDidMount() {
    const { websiteId, fetchAccountAndWebsiteSubscription } = this.props

    if (websiteId !== 0) {
      await fetchAccountAndWebsiteSubscription(websiteId)
      this.initializeForm()
    }
  }

  async componentDidUpdate(prevProps) {
    const { websiteId, fetchAccountAndWebsiteSubscription } = this.props

    if (prevProps.websiteId !== websiteId && websiteId !== 0) {
      await fetchAccountAndWebsiteSubscription(websiteId)
      this.initializeForm()
    }

    const { accountSubscriptionDetails, websiteSubscriptionDetails } =
      this.props
    if (
      !_.isEqual(
        prevProps.accountSubscriptionDetails,
        accountSubscriptionDetails
      ) ||
      !_.isEqual(
        prevProps.websiteSubscriptionDetails,
        websiteSubscriptionDetails
      )
    ) {
      this.initializeForm()
    }
  }

  async initializeForm() {
    const {
      initialize,
      accountSubscriptionDetails,
      websiteSubscriptionDetails
    } = this.props
    await initialize({
      accountSubscriptionDetails,
      websiteSubscriptionDetails
    })
  }

  handleChange(event) {
    const { change } = this.props
    const splitId = event.target.id.split(' ')
    const subscriptionType =
      splitId[0] === 'Account'
        ? 'accountSubscriptionDetails'
        : 'websiteSubscriptionDetails'
    const attribute =
      splitId[1] === 'Customer' ? 'customerId' : 'subscriptionId'

    change(
      REDUX_FORM_NAME,
      `${subscriptionType}[${attribute}]`,
      event.target.value
    )
  }

  renderDetailItem(name, value) {
    return (
      <MDBListGroupItem>
        <MDBRow>
          <MDBCol size='4'>{name}</MDBCol>
          <MDBCol>
            <input
              id={name}
              value={value}
              onChange={this.handleChange.bind(this)}
            />
          </MDBCol>
        </MDBRow>
      </MDBListGroupItem>
    )
  }

  renderDetail(detail, type) {
    return (
      <MDBListGroup>
        {this.renderDetailItem(
          `${type} Customer ID`,
          detail.customerId || 'N/A'
        )}
        {this.renderDetailItem(
          `${type} Subscription ID`,
          detail.subscriptionId || 'N/A'
        )}
      </MDBListGroup>
    )
  }

  render() {
    const { formValues } = this.props
    const { accountSubscriptionDetails, websiteSubscriptionDetails } =
      formValues

    return accountSubscriptionDetails || websiteSubscriptionDetails ? (
      <MDBCard className='subscriptions-card'>
        <MDBCardBody>
          <MDBCardTitle>Chargebee Customer &amp; Subscription IDs</MDBCardTitle>
          {this.renderDetail(accountSubscriptionDetails, 'Account')}
          {this.renderDetail(websiteSubscriptionDetails, 'Website')}
        </MDBCardBody>
        <MDBCardFooter>
          <FormButton isCancel={false} reduxFormName={REDUX_FORM_NAME} />
        </MDBCardFooter>
      </MDBCard>
    ) : null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props),
    accountSubscriptionDetails: accountSubscriptionSelector(state, props) || {},
    websiteSubscriptionDetails: websiteSubscriptionSelector(state, props) || {},
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountAndWebsiteSubscription,
      updateAccountAndWebsiteSubscription,
      change
    },
    dispatch
  )

export const REDUX_FORM_NAME = 'FeedOpsSubscriptionsSettings'

export const handleSubmit = async (values, dispatch, props) => {
  const {
    websiteId,
    updateAccountAndWebsiteSubscription,
    fetchAccountAndWebsiteSubscription
  } = props

  try {
    await updateAccountAndWebsiteSubscription(
      websiteId,
      values.accountSubscriptionDetails,
      values.websiteSubscriptionDetails
    )
    toastr.success('Chargebee details updated successfully.')
    await fetchAccountAndWebsiteSubscription(websiteId)
  } catch (error) {
    toastr.error(
      'An error occurred while saving settings. Please contact support for assistance.'
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(FeedOpsSubscriptions))
  )
)
