import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

export const TRACK_AFFILIATE_SIGN_UP_REQUEST = 'TRACK_AFFILIATE_SIGN_UP_REQUEST'
export const TRACK_AFFILIATE_SIGN_UP_RESPONSE =
  'TRACK_AFFILIATE_SIGN_UP_RESPONSE'

const trackAffiliateSignUpRequest = (userId, trackingId) => ({
  type: TRACK_AFFILIATE_SIGN_UP_REQUEST,
  userId,
  trackingId
})

const trackAffiliateSignUpResponse = (userId, trackingId, response) => ({
  type: TRACK_AFFILIATE_SIGN_UP_RESPONSE,
  userId,
  trackingId,
  response
})

export const trackAffiliateSignUp =
  (userId, trackingId) => async (dispatch) => {
    dispatch(trackAffiliateSignUpRequest(userId, trackingId))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/affiliates/track_signup`,
      data: snakeCaseKeys({ userId, trackingId }, { deep: true }),
      dataType: 'json'
    })

    response = camelCaseKeys(response, { deep: true })
    dispatch(trackAffiliateSignUpResponse(userId, trackingId, response))
    return response
  }
