import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { MDBIcon } from 'mdbreact'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import { TreeItemLabel } from '../category_optimizations/tree_item_label'
import { getFormValues } from 'redux-form'
import { REDUX_FORM_NAME } from '../category_optimizations/card'
import { MDBCardText } from 'mdbreact'

export class CategoryTreeNodes extends Component {
  static propTypes = {
    filteredCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    openNodes: PropTypes.array.isRequired,
    displayCheckboxes: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
    updateOpenNodes: PropTypes.func.isRequired,
    selectedCategory: PropTypes.func.isRequired
  }

  static defaultProps = {
    displayCheckboxes: false
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedCategoryKey: '0'
    }
  }

  handleUpdateOpenNodes(openNodes, category) {
    const { updateOpenNodes } = this.props
    updateOpenNodes(
      openNodes.includes(category.key)
        ? openNodes.filter((item) => item !== category.key)
        : openNodes.push(category.key) && openNodes
    )
  }

  handleItemClick(event, category, openNodes, iconClick) {
    const { selectedCategory, displayCheckboxes } = this.props
    if (iconClick) {
      this.handleUpdateOpenNodes(openNodes, category)
    } else {
      event.preventDefault()
    }
    this.setState({ selectedCategoryKey: category.key })
    selectedCategory(category, displayCheckboxes ? !iconClick : true)
  }

  renderChildNodes(category, openNodes, path) {
    var subTreeItems = []
    category.nodes.forEach((subCategory, index) => {
      var firstNode = index === 0
      var lastNode = index === category.nodes.length - 1
      subTreeItems.push(
        this.renderTreeItem(subCategory, openNodes, firstNode, lastNode, path)
      )
    })
    return subTreeItems
  }

  renderTreeItemLabel(category) {
    const { displayCheckboxes } = this.props
    return (
      <TreeItemLabel category={category} displayCheckbox={displayCheckboxes} />
    )
  }

  renderTreeItem(category, openNodes, first, last, path) {
    var localPath = _.clone(path)
    localPath.push(category.id)
    var subTreeItems = this.renderChildNodes(category, openNodes, localPath)

    return (
      <div key={category.key} className='cat-item-row'>
        <TreeItem
          key={category.key}
          nodeId={category.key}
          label={this.renderTreeItemLabel(category)}
          onIconClick={(event) => {
            this.handleItemClick(event, category, openNodes, true)
          }}
          onLabelClick={(event) => {
            this.handleItemClick(event, category, openNodes, false)
          }}
        >
          {subTreeItems.length > 0 ? subTreeItems : null}
        </TreeItem>
      </div>
    )
  }

  renderTreeView() {
    const { filteredCategories, openNodes } = this.props
    const { selectedCategoryKey } = this.state
    var treeItems = []
    filteredCategories.length > 0 &&
      filteredCategories.forEach((category, index) => {
        var first = index === 0
        var last = index === filteredCategories.length - 1
        treeItems.push(
          this.renderTreeItem(category, [...openNodes], first, last, [])
        )
      })

    return (
      <TreeView
        defaultCollapseIcon={<MDBIcon icon='chevron-down' />}
        defaultExpandIcon={<MDBIcon icon='chevron-right' />}
        expanded={openNodes}
        selected={selectedCategoryKey}
      >
        {treeItems}
      </TreeView>
    )
  }

  render() {
    const { filteredCategories } = this.props
    return filteredCategories.length > 0 ? (
      this.renderTreeView()
    ) : (
      <MDBCardText>No categories match the filter.</MDBCardText>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryTreeNodes)
)
