import { FETCH_FEEDOPS_FEATURES_RESPONSE } from 'actions/feedops_features'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEEDOPS_FEATURES_RESPONSE:
      return action.feedopsFeatures || []
    default:
      return state
  }
}
