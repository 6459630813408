import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact'
import { destroyBudgetCategory } from 'actions/_budget_categories/management'
import {
  superUserSelector,
  accountTypeIdSelector,
  accountTypeSelector
} from 'selectors/user'
import { appIdSelector } from 'selectors/app'
import { listSelector } from 'selectors/websites'
import { isManagedPlanSelector } from 'selectors/billing'

export class RightSecondaryHeader extends Component {
  static propTypes = {
    isSuperUser: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    appId: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired,
    isManagedPlan: PropTypes.bool.isRequired,
    menuItem: PropTypes.object.isRequired,
    destroyBudgetCategory: PropTypes.func.isRequired
  }

  async handleRemoveBudgetCategory() {
    const { destroyBudgetCategory, list, id, history, appId, menuItem } =
      this.props
    const website = (list || {}).website

    try {
      await destroyBudgetCategory(website.id, id)
      history.replace(`/${appId}/${website.id}/${menuItem.path}`, {})
    } catch (error) {
      toastr.error(error.responseJSON.error, 'ERROR')
      throw error
    }
  }

  renderDropdownMenu() {
    return (
      <MDBDropdownMenu>
        <MDBDropdownItem onClick={this.handleRemoveBudgetCategory.bind(this)}>
          <MDBIcon fas='true' icon={'trash'} />
          Remove budget category
        </MDBDropdownItem>
      </MDBDropdownMenu>
    )
  }

  conditionallyRenderBudgetCategoryDropdown() {
    const { isSuperUser, accountType, list } = this.props
    const budgetCategory = (list || {}).budgetCategory

    return isSuperUser &&
      accountType === 'budget_categories' &&
      budgetCategory &&
      budgetCategory.name !== 'Everything else' ? (
      <MDBNavItem>
        <MDBDropdown>
          <MDBDropdownToggle floating>
            <MDBIcon icon='ellipsis-v' />
          </MDBDropdownToggle>
          {this.renderDropdownMenu()}
        </MDBDropdown>
      </MDBNavItem>
    ) : null
  }

  conditionallyRenderReadOnlyTooltip() {
    const { isSuperUser, isManagedPlan } = this.props

    return !isSuperUser && isManagedPlan ? (
      <MDBNavItem>Google Shopping Ads Service</MDBNavItem>
    ) : null
  }

  render() {
    return (
      <MDBNavbarNav right>
        {this.conditionallyRenderBudgetCategoryDropdown()}
        {this.conditionallyRenderReadOnlyTooltip()}
      </MDBNavbarNav>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const id = accountTypeIdSelector(state, props)
  const accountType = accountTypeSelector(state, props)

  return {
    isSuperUser: superUserSelector(state, props),
    id,
    appId: appIdSelector(state, props),
    accountType,
    list: listSelector(accountType, id)(state, props),
    isManagedPlan: isManagedPlanSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ destroyBudgetCategory }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RightSecondaryHeader)
)
