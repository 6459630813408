import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { enableDisableChannelSettings } from 'actions/channel_settings'
import { fetchWebsiteDetail } from 'actions/websites'
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdbreact'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import BannerNotification from 'components/banner_notification'
import { websiteInventoryIdSelector } from 'selectors/websites'
import {
  websiteIdSelector,
  websiteFeedPerChannelSelector
} from 'selectors/websites'
import { inventoryDetailSelector } from 'selectors/inventory'
import { channelSettingsSelector } from 'selectors/websites/channel_settings'
import { formatNumber } from 'util/format_number'
import timeAgoInWords from 'util/time_ago_in_words'
import {
  getDisplayName,
  getLogoByChannel,
  API_CONNECTABLE_CHANNELS
} from 'util/supported_channels'

import './product_feeds_container.scss'

export class ProductFeedsContainer extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    inventoryDetail: PropTypes.object.isRequired,
    channelSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
    feedsPerChannel: PropTypes.object.isRequired,
    enableDisableChannelSettings: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  renderInventorySummary() {
    const { inventoryDetail } = this.props
    const { lastImported, itemCount } = inventoryDetail

    return (
      <MDBContainer className='inventory-detail-container'>
        {!!lastImported ? (
          <div>
            <span className='item-count'>
              Store items: {formatNumber(itemCount, 0)}
            </span>
            <br />
            <span className='import-time'>
              Synced {timeAgoInWords(lastImported)}.
            </span>
          </div>
        ) : (
          <div>
            <span className='item-count'>Fetching store items.</span>
            <AdChampionIndeterminateProgressIndicator />
          </div>
        )}
      </MDBContainer>
    )
  }

  renderInventorySummaryAndNotification() {
    return (
      <MDBRow>
        <MDBCol>{this.renderInventorySummary()}</MDBCol>
        <MDBCol>
          <BannerNotification />
        </MDBCol>
      </MDBRow>
    )
  }

  computeChannelText(channel, enabled, displayName) {
    return API_CONNECTABLE_CHANNELS.includes(channel)
      ? enabled
        ? `Synced with ${displayName}`
        : `Sync items with ${displayName}`
      : enabled
      ? `Updated ${displayName} feed`
      : `Access item feed for ${displayName}`
  }

  renderChannelText(channelSetting) {
    const { feedsPerChannel } = this.props
    const { channel, enabled } = channelSetting
    const feeds = feedsPerChannel[channel]
    const displayName = getDisplayName(channel)
    const text = this.computeChannelText(channel, enabled, displayName)

    return (
      <span>
        <span className={enabled ? 'enabled-channel' : ''}>
          {enabled && <i className='fa fa-check-circle' />}
          {text}
        </span>
        {enabled &&
          feeds &&
          feeds.length > 0 &&
          ` ${timeAgoInWords(feeds[0].lastGeneratedAt)}`}
      </span>
    )
  }

  getUrl(location, channel) {
    const { feedsPerChannel } = this.props
    const feeds = feedsPerChannel[channel] || [{}]
    return `${location}?channel=${channel}&feed_id=${feeds[0].id}`
  }

  async enableChannelSetting(channel, enabled) {
    const {
      history,
      enableDisableChannelSettings,
      fetchWebsiteDetail,
      websiteId
    } = this.props

    if (!enabled) {
      await enableDisableChannelSettings(websiteId, channel, true)
      await fetchWebsiteDetail(websiteId)
    }
    history.push(this.getUrl('feed_settings', channel))
  }

  async handleConnectChannelClick(channelSetting) {
    const { history } = this.props
    const { channel, enabled } = channelSetting

    if (API_CONNECTABLE_CHANNELS.includes(channel)) {
      history.push(
        this.getUrl(
          enabled ? 'feed_settings' : 'connect_merchant_center_account',
          channel
        )
      )
    } else {
      this.enableChannelSetting(channel, enabled)
    }
  }

  renderChannelButton(channelSetting) {
    const { channel, enabled } = channelSetting
    const btnName = enabled
      ? 'DETAILS'
      : API_CONNECTABLE_CHANNELS.includes(channel)
      ? 'CONNECT'
      : 'ENABLE'

    return (
      <MDBBtn
        onClick={this.handleConnectChannelClick.bind(this, channelSetting)}
      >
        <img src={getLogoByChannel(channel)} alt={channel} /> {btnName}
      </MDBBtn>
    )
  }

  renderChannelItem(channelSetting) {
    return (
      <MDBRow key={channelSetting.channel}>
        <MDBCol>
          <MDBContainer className='channel-item-container'>
            <MDBRow>
              <MDBCol size='8'>{this.renderChannelText(channelSetting)}</MDBCol>
              <MDBCol>{this.renderChannelButton(channelSetting)}</MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    )
  }

  render() {
    const { channelSettings } = this.props

    return (
      <div className='product-feeds-container'>
        {this.renderInventorySummaryAndNotification()}
        {channelSettings.map((channelSetting, index) =>
          this.renderChannelItem(channelSetting)
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdSelector(state, props)
  return {
    websiteId: websiteIdSelector(state, props),
    inventoryDetail: inventoryDetailSelector(inventoryId)(state, props),
    channelSettings: channelSettingsSelector(state, props),
    feedsPerChannel: websiteFeedPerChannelSelector(state, props) || {}
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { enableDisableChannelSettings, fetchWebsiteDetail },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductFeedsContainer)
)
