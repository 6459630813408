import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MDBBox, MDBCollapseHeader, MDBIcon } from 'mdbreact'
import { REDUX_FORM_NAME } from './attribute_list'
import { getFormValues } from 'redux-form'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'

export class AttributeValueList extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    attributeValues: PropTypes.object.isRequired,
    ChildComponent: PropTypes.func.isRequired,
    onToggleAttribute: PropTypes.func.isRequired
  }

  errorClass(attribute) {
    var errorClass = ''
    if (attribute.error) {
      errorClass = 'error'
    } else if (attribute.childHasError) {
      errorClass = 'child-error'
    }
    return errorClass
  }

  renderAttributeExpander() {
    const { attribute } = this.props
    return (
      <MDBBox display='flex' flex='column' justifyContent='start'>
        <MDBIcon
          className={attribute.expanded ? 'chevron-down-new' : ''}
          onClick={this.handleToggleCollapse.bind(this, attribute.id)}
          icon={attribute.expanded ? 'chevron-down' : 'chevron-right'}
        />
      </MDBBox>
    )
  }

  async handleToggleCollapse() {
    const { onToggleAttribute, attribute } = this.props
    await onToggleAttribute(attribute.id)
  }

  renderError(attribute) {
    return (
      attribute.error && (
        <span className='category-error-message'>{attribute.error}</span>
      )
    )
  }

  renderValue(attributeValue) {
    const { ChildComponent, attribute } = this.props
    return (
      <div key={`av-${attributeValue.id}`} className={'attribute-value-writer'}>
        <ChildComponent attribute={attribute} attributeValue={attributeValue} />
        {this.renderError(attributeValue)}
      </div>
    )
  }

  renderValues() {
    const { attribute, attributeValues } = this.props
    if (!attribute.expanded) {
      return null
    } else if (attribute.isLoading) {
      return <IndeterminateProgressIndicator />
    } else if (attribute.noResults) {
      return <span className='category-info-message'>No matching results</span>
    }
    return (
      <div className={'attribute-list-values'}>
        {Object.values(attributeValues || {}).map((attributeValue) =>
          this.renderValue(attributeValue)
        )}
      </div>
    )
  }

  render() {
    const { attribute } = this.props
    return (
      <MDBCollapseHeader
        key={attribute.id}
        className={this.errorClass(attribute)}
      >
        <MDBBox display='flex'>
          {this.renderAttributeExpander()}
          <MDBBox
            display='flex'
            flex='column'
            className='flex-grow-1'
            justifyContent='between'
          >
            <MDBBox display='flex' flex='row'>
              <span className='attribute-name'>{attribute.name}</span>
            </MDBBox>
            {this.renderValues()}
          </MDBBox>
        </MDBBox>
      </MDBCollapseHeader>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(REDUX_FORM_NAME)(state) || {}
  return { formValues }
}

export default withRouter(connect(mapStateToProps)(AttributeValueList))
