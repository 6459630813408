import { FETCH_GOOGLE_TAXONOMIES_RESPONSE } from 'actions/google/taxonomies'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOOGLE_TAXONOMIES_RESPONSE:
      return action.taxonomies || {}
    default:
      return state
  }
}
