import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  getAllMappableAttributes,
  attributeCategoryNames
} from '../../product_optimisations/common/attributes_util'
import { MDBCol, MDBIcon } from 'mdbreact'
import { Popover } from '@material-ui/core'
import AttributePopoverContainer from '../../product_optimisations/attribute_filters_section/popover_section/attribute_popover_container'
import { REDUX_FORM_NAME as ATTRIBUTES_MAPPING_FORM } from '../attribute_mapping'

export const STATIC_VALUE_CATEGORY = 'Static Value'

export default class AttributeMappingPopover extends Component {
  static propTypes = {
    isFeedOpsAttributePopover: PropTypes.bool.isRequired,
    mapping: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object),
    mappableAttributes: PropTypes.arrayOf(PropTypes.object),
    existingMappings: PropTypes.arrayOf(PropTypes.object),
    storeAttributes: PropTypes.arrayOf(PropTypes.object),
    handleFeedOpsAttributeChange: PropTypes.func,
    handleStoreAttributeChange: PropTypes.func,
    handleStoreAttributeStaticValueChange: PropTypes.func
  }

  constructor() {
    super()
    this.state = {
      anchorEl: null,
      popoverOpen: false
    }
  }

  handleOpenClosePopoverClick(event) {
    const { popoverOpen } = this.state
    this.setState({
      anchorEl: event.currentTarget,
      popoverOpen: !popoverOpen
    })
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  getAvailableAttributeAndCategoryNames() {
    const {
      isFeedOpsAttributePopover,
      attributes,
      mappableAttributes,
      existingMappings,
      storeAttributes,
      reduxFormName
    } = this.props

    const availableAttributes = isFeedOpsAttributePopover
      ? getAllMappableAttributes(
          attributes,
          mappableAttributes,
          existingMappings
        )
      : storeAttributes
    var categoryNames = attributeCategoryNames(availableAttributes)
    if (
      !isFeedOpsAttributePopover &&
      reduxFormName === ATTRIBUTES_MAPPING_FORM
    ) {
      categoryNames.push(STATIC_VALUE_CATEGORY)
    }

    return [availableAttributes, categoryNames]
  }

  handleAttributeSelected(attribute) {
    const {
      isFeedOpsAttributePopover,
      handleFeedOpsAttributeChange,
      handleStoreAttributeChange
    } = this.props
    isFeedOpsAttributePopover
      ? handleFeedOpsAttributeChange(attribute)
      : handleStoreAttributeChange(attribute)
    this.handleClosePopover()
  }

  handleStaticValueChange(value) {
    const { handleStoreAttributeStaticValueChange } = this.props
    handleStoreAttributeStaticValueChange(value)
  }

  renderPopoverSection() {
    const { mapping } = this.props
    const attributesAndCategoryNames =
      this.getAvailableAttributeAndCategoryNames()

    return (
      <AttributePopoverContainer
        availableAttributes={attributesAndCategoryNames[0]}
        categoryNames={attributesAndCategoryNames[1]}
        currentMapping={mapping}
        handleSelectedAttributeChange={this.handleAttributeSelected.bind(this)}
        handleStaticValueChange={this.handleStaticValueChange.bind(this)}
      />
    )
  }

  renderPopover() {
    const { anchorEl, popoverOpen } = this.state

    return (
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className='attribute-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        {this.renderPopoverSection()}
      </Popover>
    )
  }

  render() {
    const { name } = this.props

    return (
      <>
        <MDBCol
          className='popover-col'
          onClick={this.handleOpenClosePopoverClick.bind(this)}
        >
          {name}
          <MDBIcon icon='angle-down' />
        </MDBCol>
        {this.renderPopover()}
      </>
    )
  }
}
