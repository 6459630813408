import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBInput } from 'mdbreact'
import { bindActionCreators } from 'redux'
import {
  reduxForm,
  getFormValues,
  registerField,
  change,
  initialize
} from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { selectMerchantCenterAccount } from 'actions/google/merchant_center'
import { fetchWebsitePrimaryFeeds } from 'actions/_websites/_status'
import CountryOfSaleSelector, {
  REDUX_FORM_NAME as COUNTRY_OF_SALE_FORM
} from 'components/google/country_of_sale_selector'
import LanguageSelector, {
  REDUX_FORM_NAME as LANGUAGE_FORM
} from 'components/google/language_selector'
import {
  websiteIdSelector,
  websitePrimaryFeedCountSelector
} from 'selectors/websites'

export class MerchantCenterFeedUploadForm extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    countryOfSaleFormValues: PropTypes.object.isRequired,
    languageFormValues: PropTypes.object.isRequired,
    registerField: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    selectMerchantCenterAccount: PropTypes.func.isRequired,
    fetchWebsitePrimaryFeeds: PropTypes.func.isRequired,
    primaryFeedCount: PropTypes.number.isRequired,
    merchantCenterId: PropTypes.string.isRequired
  }

  componentDidMount() {
    const { registerField, initialize, merchantCenterId, websiteId } =
      this.props
    registerField(REDUX_FORM_NAME, 'feedUploadMethod', 'Field')
    initialize({ feedUploadMethod: undefined })
    if (websiteId && merchantCenterId) {
      this.selectMerchantCenterAccount()
    }
  }

  componentDidUpdate(prevProps) {
    const { websiteId, merchantCenterId, primaryFeedCount } = this.props
    if (
      websiteId !== prevProps.websiteId ||
      merchantCenterId !== prevProps.merchantCenterId
    ) {
      this.selectMerchantCenterAccount()
    }
    if (
      primaryFeedCount !== prevProps.primaryFeedCount &&
      primaryFeedCount === 0
    ) {
      change(REDUX_FORM_NAME, 'feedUploadMethod', 'additional')
    }
  }

  async selectMerchantCenterAccount() {
    const { websiteId, selectMerchantCenterAccount, merchantCenterId } =
      this.props
    try {
      await selectMerchantCenterAccount(websiteId, merchantCenterId, true)
      await this.fetchWebsitePrimaryFeeds()
    } catch (error) {
      //Do nothing
    }
  }

  async fetchWebsitePrimaryFeeds() {
    const { fetchWebsitePrimaryFeeds, websiteId } = this.props
    try {
      await fetchWebsitePrimaryFeeds(websiteId)
    } catch (error) {
      //Do nothing
    }
  }

  conditionallyRenderUploadMethodOptions() {
    const { primaryFeedCount, formValues, change } = this.props
    const { feedUploadMethod } = formValues
    if (primaryFeedCount === 0) {
      return null
    }
    const pluralifiedText =
      primaryFeedCount > 1
        ? `Merchant Center already has ${primaryFeedCount} primary feeds.`
        : 'Merchant Center already has a primary feed.'

    return (
      <div className={'replace-or-additional'}>
        <p>{pluralifiedText}</p>
        <MDBInput
          onClick={() => change(REDUX_FORM_NAME, 'feedUploadMethod', 'replace')}
          checked={feedUploadMethod === 'replace'}
          label={'Add as a primary feed and replace existing primary feeds'}
          type='radio'
          id={'replace-radio'}
          gap
        />
        <div className={'divider-new'}>
          <h6 className=''>OR</h6>
        </div>
        <MDBInput
          onClick={() =>
            change(REDUX_FORM_NAME, 'feedUploadMethod', 'additional')
          }
          checked={feedUploadMethod === 'additional'}
          label={'Add as an additional primary feed'}
          type='radio'
          id={'additional-radio'}
          gap
        />
      </div>
    )
  }

  render() {
    const { countryOfSaleFormValues, languageFormValues } = this.props
    const { country } = countryOfSaleFormValues
    const { language } = languageFormValues

    return (
      <React.Fragment>
        <CountryOfSaleSelector />
        {country && <LanguageSelector />}
        {language && this.conditionallyRenderUploadMethodOptions()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    countryOfSaleFormValues: getFormValues(COUNTRY_OF_SALE_FORM)(state) || {},
    languageFormValues: getFormValues(LANGUAGE_FORM)(state) || {},
    websiteId: websiteIdSelector(state, props),
    primaryFeedCount: websitePrimaryFeedCountSelector(state, props) || 0
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerField,
      initialize,
      change,
      selectMerchantCenterAccount,
      fetchWebsitePrimaryFeeds
    },
    dispatch
  )

export const MerchantCenterFeedUploadFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantCenterFeedUploadForm)
export const REDUX_FORM_NAME = 'MerchantCenterFeedUploadForm'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(MerchantCenterFeedUploadFormWithRedux)
  )
)
