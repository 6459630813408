import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { showFeedOpsFeedbackModal } from 'actions/feed_ops/feedbacks'
import ApplyDismissSuggestions from './apply_dismiss_suggestions'
import {
  websiteProductOptimisationSuggestionsSelector,
  websiteProductReplacementsSelectedColumnsSelector,
  suggestionBatchIdsSelector
} from 'selectors/product_feeds/replacements'
import { optimisationSuggestionsPresent } from 'components/feed_ops/website/product_optimisations/common/attributes_util'

import './approve_reject_ai_optimisations.scss'

export class ApproveRejectAIOptimisations extends Component {
  static propTypes = {
    allRowsSelected: PropTypes.bool.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({ field: PropTypes.string, title: PropTypes.string })
    ).isRequired,
    optimisationSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    batchIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired,
    showFeedOpsFeedbackModal: PropTypes.func.isRequired
  }

  isSuggestionsPresent() {
    const {
      allRowsSelected,
      selectedRows,
      optimisationSuggestions,
      selectedColumns
    } = this.props

    return optimisationSuggestionsPresent(
      allRowsSelected,
      selectedRows,
      optimisationSuggestions,
      selectedColumns
    )
  }

  async handleOnSaveProductAttributesReplacements(btnText) {
    const { onSaveProductAttributesReplacements } = this.props
    await onSaveProductAttributesReplacements()

    if (btnText === 'Reject') {
      const { showFeedOpsFeedbackModal, batchIds } = this.props
      const latestBatchId = _.max(batchIds)
      showFeedOpsFeedbackModal('AI Optimisations', latestBatchId)
    }
  }

  renderButton(isApply, btnText) {
    const { allRowsSelected, selectedRows, attributes } = this.props

    return (
      <ApplyDismissSuggestions
        allRowsSelected={allRowsSelected}
        applySuggestions={isApply}
        suggestionBtnText={btnText}
        selectedRows={selectedRows}
        attributes={attributes}
        suggestionSource='open_ai'
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          btnText
        )}
      />
    )
  }

  renderApproveBtn() {
    return this.renderButton(true, 'Approve')
  }

  renderRejectBtn() {
    return this.renderButton(false, 'Reject')
  }

  render() {
    const isSuggestionsPresent = this.isSuggestionsPresent()

    return (
      <div className='approve-reject-ai-optimisations'>
        {isSuggestionsPresent ? (
          <>
            {this.renderApproveBtn()} or {this.renderRejectBtn()} the filtered
            and selected rows and columns.
          </>
        ) : (
          'No AI optimisations found for the selected columns & rows.'
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  optimisationSuggestions: websiteProductOptimisationSuggestionsSelector(
    state,
    props
  ),
  selectedColumns: websiteProductReplacementsSelectedColumnsSelector(
    state,
    props
  ),
  batchIds: suggestionBatchIdsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showFeedOpsFeedbackModal }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApproveRejectAIOptimisations)
)
