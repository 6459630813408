import camelCaseKeys from 'camelcase-keys'

export const FETCH_PRODUCT_FEED_TITLES_REQUEST =
  'FETCH_PRODUCT_FEED_TITLES_REQUEST'
export const FETCH_PRODUCT_FEED_TITLES_RESPONSE =
  'FETCH_PRODUCT_FEED_TITLES_RESPONSE'

const fetchProductTitlesRequest = (websiteId, feedId) => ({
  type: FETCH_PRODUCT_FEED_TITLES_REQUEST,
  websiteId,
  feedId
})

const fetchProductTitlesResponse = (websiteId, feedId, data) => ({
  type: FETCH_PRODUCT_FEED_TITLES_RESPONSE,
  websiteId,
  feedId,
  ...data
})

export const fetchProductTitles = (websiteId, feedId) => async (dispatch) => {
  dispatch(fetchProductTitlesRequest(websiteId, feedId))

  var data = {}
  if (feedId > 0) {
    data.feed_id = feedId
  }
  const response = await $.ajax({
    url: `/ad_champion/websites/${websiteId}/product_titles`,
    data
  })
  var payload = camelCaseKeys(response, { deep: true })

  dispatch(fetchProductTitlesResponse(websiteId, feedId, payload))

  return payload
}
