import {
  SAVE_AD_HEADLINE_LIST_ITEMS_SUCCESS,
  SAVE_AD_HEADLINE_LIST_ITEMS_ERROR,
  LOAD_AD_HEADLINE_LIST_ITEMS_SUCCESS,
  LOAD_AD_HEADLINE_LIST_ITEMS_ERROR
} from 'actions/ad_headline_list';

const adHeadlineListReducer = (state = { items: [] }, action) => {
  switch(action.type) {
    case LOAD_AD_HEADLINE_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
        errorMessage: action.errorMessage
      };
    case LOAD_AD_HEADLINE_LIST_ITEMS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case SAVE_AD_HEADLINE_LIST_ITEMS_SUCCESS:
      return { 
        ...state,
        items: action.items,
        errorMessage: action.errorMessage
      };
    case SAVE_AD_HEADLINE_LIST_ITEMS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
}

export default adHeadlineListReducer;
