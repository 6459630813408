import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import timeAgoInWords from 'util/time_ago_in_words'
import qs from 'qs'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Summary from './summary'
import AllAuditsModal from './audit/all_audits_modal'
import PlaybookItem from 'components/feed_ops/website/playbook/item'
import FeedbackModal from 'components/feed_ops/common/feedback_modal'
import { PLAYBOOK_CHANNELS, CHANNEL_ALL } from 'util/supported_channels'
import changeCase from 'change-case'
import {
  setDismissSwitch,
  fetchPlaybookProgress
} from 'actions/playbook/playbook_progress'
import {
  websiteIdSelector,
  websiteFeedDetailSelector,
  websiteFeedPerChannelSelector
} from 'selectors/websites'
import {
  showDismissPlaybookItemsSelector,
  playbookProgressWebsiteChannelSelector,
  playbookProgressWebsiteGeneratedAtSelector
} from 'selectors/playbook/playbook_progress'
import { noDefaultChannelSelector } from 'selectors/channel'

export class PlaybookContainer extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedDetail: PropTypes.object.isRequired,
    feedIdsByChannel: PropTypes.object.isRequired,
    showDismissPlaybookItems: PropTypes.bool.isRequired,
    playbookItems: PropTypes.array.isRequired,
    playbookGeneratedAt: PropTypes.string.isRequired,
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    fetchPlaybookProgress: PropTypes.func.isRequired,
    setDismissSwitch: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isLoading: false }
  }

  async componentDidMount() {
    const {
      setDismissSwitch,
      playbookItems,
      fetchPlaybookProgress,
      websiteId,
      channel,
      history
    } = this.props

    history.replace({
      search: qs.stringify({ channel: !channel ? CHANNEL_ALL : channel })
    })

    setDismissSwitch(false)
    if (playbookItems && playbookItems.length === 0) {
      this.setState({ isLoading: true })
      await fetchPlaybookProgress(websiteId)
      this.setState({ isLoading: false })
    }
  }

  renderPlaybookUpdatedAt() {
    const { playbookGeneratedAt } = this.props
    if (playbookGeneratedAt) {
      return (
        <MDBRow>
          <MDBCol md='4' />
          <MDBCol md='4' />
          <MDBCol md='4'>
            <span className='playbook-last-generated'>
              Playbook updated {timeAgoInWords(playbookGeneratedAt)}
            </span>
          </MDBCol>
        </MDBRow>
      )
    }

    return null
  }

  renderPlaybookItems() {
    const { channel, playbookItems } = this.props
    return playbookItems.map((item, i) => (
      <MDBCol key={i} className='col-md-4 mb-4 d-flex align-items-stretch'>
        <div className='playbook-list-item'>
          <PlaybookItem item={item} channel={channel} />
        </div>
      </MDBCol>
    ))
  }

  genSyncedMessageWithLink(channel, feedDetail) {
    const { location } = this.props

    return (
      <>
        {`${changeCase.titleCase(
          channel
        )} feed is synced with ${changeCase.titleCase(
          feedDetail.sourceChannel
        )}. Please switch to the `}
        <a href={`${location.pathname}?channel=${feedDetail.sourceChannel}`}>
          {changeCase.titleCase(feedDetail.sourceChannel)}
        </a>
        {' channel.'}
      </>
    )
  }

  renderMessage() {
    const { showDismissPlaybookItems, channel, feedDetail } = this.props
    var message = ''

    if (showDismissPlaybookItems) {
      message = 'No dismissed playbook items found.'
    } else if (
      channel !== CHANNEL_ALL &&
      feedDetail.sourceChannel !== feedDetail.channel
    ) {
      message = this.genSyncedMessageWithLink(channel, feedDetail)
    } else {
      message = 'No active playbook items found.'
    }

    return <div className='message-container'>{message}</div>
  }

  render() {
    const { isLoading } = this.state
    const { channel, playbookItems } = this.props

    return !isLoading ? (
      <MDBContainer className='playbook-container'>
        <FeedbackModal showSkipBtn={false} />
        {this.renderPlaybookUpdatedAt()}
        <Summary channel={channel} />
        {playbookItems && playbookItems.length > 0 ? (
          <MDBRow>{this.renderPlaybookItems()}</MDBRow>
        ) : (
          this.renderMessage()
        )}
        <AllAuditsModal />
      </MDBContainer>
    ) : (
      <AdChampionIndeterminateProgressIndicator />
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId,
    feedDetail: websiteFeedDetailSelector(state, props),
    feedIdsByChannel: websiteFeedPerChannelSelector(state, props),
    showDismissPlaybookItems: showDismissPlaybookItemsSelector(state, props),
    playbookItems: playbookProgressWebsiteChannelSelector(state, props),
    playbookGeneratedAt:
      playbookProgressWebsiteGeneratedAtSelector(state, props) || '',
    channel: noDefaultChannelSelector(state, props) || CHANNEL_ALL
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchPlaybookProgress, setDismissSwitch }, dispatch)

const PlaybookContainerWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaybookContainer)

export default withRouter(PlaybookContainerWithRedux)
