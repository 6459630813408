import {
  LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_RESPONSE,
  LIST_MC_CATALOGS_RESPONSE
} from 'actions/microsoft/merchant_center'

const initialState = {
  accounts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_RESPONSE:
      return { ...state, accounts: action.accounts }

    case LIST_MC_CATALOGS_RESPONSE:
      return { ...state, catalogs: action.catalogs }

    default:
      return state
  }
}
