import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { getRefreshToken } from 'selectors/google/user'

export const SET_ADWORDS_ACCOUNT_ID_REQUEST = 'SET_ADWORDS_ACCOUNT_ID_REQUEST'
export const SET_ADWORDS_ACCOUNT_ID_RESPONSE = 'SET_ADWORDS_ACCOUNT_ID_RESPONSE'
export const LIST_ADWORDS_ACCOUNTS_REQUEST = 'LIST_ADWORDS_ACCOUNTS_REQUEST'
export const LIST_ADWORDS_ACCOUNTS_RESPONSE = 'LIST_ADWORDS_ACCOUNTS_RESPONSE'
export const LOAD_ADWORDS_ACCOUNT_REQUEST = 'LOAD_ADWORDS_ACCOUNT_REQUEST'
export const LOAD_ADWORDS_ACCOUNT_SUCCESS = 'LOAD_ADWORDS_ACCOUNT_SUCCESS'
export const UPDATE_ADWORDS_ACCOUNT_REQUEST = 'UPDATE_ADWORDS_ACCOUNT_REQUEST'
export const UPDATE_ADWORDS_ACCOUNT_ERROR = 'UPDATE_ADWORDS_ACCOUNT_ERROR'
export const UPDATE_ADWORDS_ACCOUNT_SUCCESS = 'UPDATE_ADWORDS_ACCOUNT_SUCCESS'
export const CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_REQUEST =
  'CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_REQUEST'
export const CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_RESPONSE =
  'CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_RESPONSE'
export const ADWORDS_ACCOUNT_LINKING_STATUS_REQUEST =
  'ADWORDS_ACCOUNT_LINKING_STATUS_REQUEST'
export const ADWORDS_ACCOUNT_LINKING_STATUS_RESPONSE =
  'ADWORDS_ACCOUNT_LINKING_STATUS_RESPONSE'

const loadAdwordsAccountRequest = (scenarioId) => ({
  type: LOAD_ADWORDS_ACCOUNT_REQUEST,
  scenarioId
})

const loadAdwordsAccountSuccess = (scenarioId, adwordsAccount) => ({
  type: LOAD_ADWORDS_ACCOUNT_SUCCESS,
  scenarioId,
  adwordsAccount
})

export const loadAdwordsAccount = (scenarioId) => (dispatch) => {
  dispatch(loadAdwordsAccountRequest(scenarioId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/adwords_account/edit`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        loadAdwordsAccountSuccess(
          scenarioId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const updateAdwordsAccountRequest = (scenarioId, adwordsAccount) => ({
  type: UPDATE_ADWORDS_ACCOUNT_REQUEST,
  scenarioId,
  adwordsAccount
})

const updateAdwordsAccountError = (scenarioId, adwordsAccount) => ({
  type: UPDATE_ADWORDS_ACCOUNT_ERROR,
  scenarioId,
  adwordsAccount
})

const updateAdwordsAccountSuccess = (scenarioId, adwordsAccount) => ({
  type: UPDATE_ADWORDS_ACCOUNT_SUCCESS,
  scenarioId,
  adwordsAccount
})

const mergeResponse = (adwordsAccount, response) => {
  var newData = camelCaseKeys(response, { deep: true })
  return {
    ...adwordsAccount,
    ...newData
  }
}

export const updateAdwordsAccount =
  (scenarioId, adwordsAccount) => (dispatch) => {
    dispatch(updateAdwordsAccountRequest(scenarioId, adwordsAccount))
    var payload = {
      publication_account: snakeCaseKeys(adwordsAccount)
    }

    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/scenarios/${scenarioId}/adwords_account`,
      data: payload,
      success: (response) => {
        return dispatch(
          updateAdwordsAccountSuccess(
            scenarioId,
            mergeResponse(adwordsAccount, response)
          )
        )
      },
      error: (response) => {
        return dispatch(
          updateAdwordsAccountError(
            scenarioId,
            mergeResponse(adwordsAccount, response.responseJSON)
          )
        )
      }
    })
  }

const listAdwordsAccountsRequest = () => ({
  type: LIST_ADWORDS_ACCOUNTS_REQUEST
})

const listAdwordsAccountsResponse = (adwordsAccounts) => ({
  type: LIST_ADWORDS_ACCOUNTS_RESPONSE,
  adwordsAccounts
})

export const listAdwordsAccounts =
  (getToken = getRefreshToken) =>
  async (dispatch, getState) => {
    const refreshToken = getToken(getState)
    if (refreshToken) {
      dispatch(listAdwordsAccountsRequest())

      var accounts = await $.ajax({
        method: 'GET',
        url: `/ad_champion/google/adwords/accounts?refresh_token=${refreshToken}`
      })

      dispatch(listAdwordsAccountsResponse(accounts))

      return accounts
    }
  }

const createAdwordsAccountAndSendInvitationRequest = (
  websiteId,
  adwordsAccountId
) => ({
  type: CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_REQUEST,
  websiteId,
  adwordsAccountId
})

const createAdwordsAccountAndSendInvitationResponse = (
  websiteId,
  adwordsAccountId,
  response
) => ({
  type: CREATE_ADWORDS_ACCOUNT_AND_SEND_INVITATION_RESPONSE,
  websiteId,
  adwordsAccountId,
  response
})

export const createAdwordsAccountAndSendInvitation =
  (websiteId, adwordsAccountId, getToken = getRefreshToken) =>
  async (dispatch, getState) => {
    dispatch(
      createAdwordsAccountAndSendInvitationRequest(websiteId, adwordsAccountId)
    )

    const refreshToken = getToken(getState)
    var response = await $.ajax({
      method: 'POST',
      url: '/ad_champion/google/adwords/accounts/create_adwords_account_and_send_invitation',
      data: {
        website_id: websiteId,
        adwords_account_id: adwordsAccountId,
        refreshToken: refreshToken
      }
    })

    const camelCaseResponse = camelCaseKeys(response, { deep: true })

    dispatch(
      createAdwordsAccountAndSendInvitationResponse(
        websiteId,
        adwordsAccountId,
        camelCaseResponse
      )
    )
    return camelCaseResponse
  }

const adwordsAccountLinkingStatusRequest = (websiteId) => ({
  type: ADWORDS_ACCOUNT_LINKING_STATUS_REQUEST,
  websiteId
})

const adwordsAccountLinkingStatusResponse = (websiteId, response) => ({
  type: ADWORDS_ACCOUNT_LINKING_STATUS_RESPONSE,
  websiteId,
  ...response
})

export const adwordsAccountLinkingStatus = (websiteId) => async (dispatch) => {
  dispatch(adwordsAccountLinkingStatusRequest(websiteId))

  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/google/adwords/accounts/adwords_account_linking_status',
    data: {
      website_id: websiteId
    }
  })

  const camelCaseResponse = camelCaseKeys(response, { deep: true })

  dispatch(adwordsAccountLinkingStatusResponse(websiteId, camelCaseResponse))
  return camelCaseResponse
}
