import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  fetchInventoryDetail,
  fetchInventoryProcessingStatus
} from 'actions/inventories'
import { fetchWebsiteDetail } from 'actions/websites'
import Poller from 'components/ad_champion/common/poller'
import { inventoryProcessingStatusSelector } from 'selectors/inventory'
import {
  websiteIdSelector,
  websiteInventoryIdSelector
} from 'selectors/websites'

export class InventoryStatusPoller extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    inventoryId: PropTypes.number.isRequired,
    processingMessage: PropTypes.string.isRequired,
    fetchInventoryDetail: PropTypes.func.isRequired,
    fetchInventoryProcessingStatus: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  async componentDidMount() {
    await this.fetchWebsiteDetail()
    await this.fetchInventoryDetail()
    await this.fetchInventoryProcessingStatus()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId, inventoryId, processingMessage } = this.props

    if (websiteId !== prevProps.websiteId) {
      await this.fetchWebsiteDetail()
    }

    if (inventoryId !== prevProps.inventoryId) {
      await this.fetchInventoryDetail()
      await this.fetchInventoryProcessingStatus()
    }

    if (!processingMessage && !!prevProps.processingMessage) {
      await this.fetchInventoryDetail()
    }
  }

  async fetchWebsiteDetail() {
    const { websiteId, fetchWebsiteDetail } = this.props
    if (websiteId > 0) {
      await fetchWebsiteDetail(websiteId)
    }
  }

  async fetchInventoryDetail() {
    const { inventoryId, fetchInventoryDetail } = this.props
    if (inventoryId > 0) {
      await fetchInventoryDetail(inventoryId)
    }
  }
  async fetchInventoryProcessingStatus() {
    const { fetchInventoryProcessingStatus, inventoryId } = this.props

    if (inventoryId > 0) {
      await fetchInventoryProcessingStatus(inventoryId)
    }
  }

  render() {
    const { inventoryId } = this.props
    return (
      <Poller
        action={this.fetchInventoryProcessingStatus.bind(this)}
        periodMs={20000}
        enabled={inventoryId > 0}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  inventoryId: websiteInventoryIdSelector(state, props),
  processingMessage: inventoryProcessingStatusSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryProcessingStatus,
      fetchInventoryDetail,
      fetchWebsiteDetail
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryStatusPoller)
)
