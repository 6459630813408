import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, withRouter } from 'react-router-dom'
import FeedOpsMain from 'components/feed_ops/main'
import withSplashScreen from 'components/splash_screen'

export class FeedOpsApp extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  render() {
    return (
      <div className='platform'>
        <Switch>
          <Route path='/:appId(feed_ops)' component={FeedOpsMain} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(withSplashScreen(FeedOpsApp, 'feed_ops'))
