import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ModalFooter, Button, Spinner } from 'reactstrap'
import './footer_actions.scss'

export default class FooterAction extends Component {
  static propTypes = {
    showSkipBtn: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSuccessfullySubmitted: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }

  renderContinueButton() {
    const { toggleModal } = this.props

    return (
      <Button
        color='primary'
        className='primary-btn'
        onClick={() => toggleModal()}
      >
        Continue
      </Button>
    )
  }

  renderSkipButton() {
    const { toggleModal, showSkipBtn } = this.props
    return (
      <Button color='secondary' className='skip' onClick={() => toggleModal()}>
        {showSkipBtn ? 'SKIP' : 'CANCEL'}
      </Button>
    )
  }

  renderSubmitButton() {
    const { isSubmitting, handleSubmit } = this.props

    return (
      <Button
        color='primary'
        disabled={isSubmitting}
        className='primary-btn'
        onClick={() => handleSubmit()}
      >
        {isSubmitting ? (
          <>
            Submitting
            <Spinner className='loading' size='sm' />
          </>
        ) : (
          'SUBMIT'
        )}
      </Button>
    )
  }

  render() {
    const { isSuccessfullySubmitted } = this.props

    return (
      <ModalFooter className={`${isSuccessfullySubmitted ? 'Continue' : ''}`}>
        {isSuccessfullySubmitted ? (
          this.renderContinueButton()
        ) : (
          <>
            {this.renderSkipButton()} {this.renderSubmitButton()}
          </>
        )}
      </ModalFooter>
    )
  }
}
