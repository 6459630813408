import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { isMobileOS } from 'util/operating_systems'
import { fetchFeedopsFeatures } from 'actions/feedops_features'
import { MDBCard, MDBCardBody } from 'mdbreact'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { feedopsFeaturesSelector } from 'selectors/feedops_features'
import { isEmbeddedAppSelector } from 'selectors/app'
import { loggedInSelector } from 'selectors/user'
import feedOpsFeaturesBackground from 'images/feed_ops/sign_up_features_background.jpg'

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1
}

export class FeedopsFeatures extends Component {
  static propTypes = {
    isEmbeddedApp: PropTypes.bool.isRequired,
    features: PropTypes.arrayOf(PropTypes.object).isRequired,
    loggedIn: PropTypes.bool.isRequired,
    fetchFeedopsFeatures: PropTypes.func.isRequired
  }

  async componentDidMount() {
    const { features, fetchFeedopsFeatures } = this.props
    if (!features || features.length === 0) {
      await fetchFeedopsFeatures()
    }
  }

  genFeatureItems() {
    const { features } = this.props

    var featureItems = features.map((feature, index) => (
      <div key={index}>
        <h5>
          <center>{feature.title}</center>
        </h5>
        <center>{feature.feature}</center>
      </div>
    ))
    return featureItems
  }

  renderSlider() {
    const featureItems = this.genFeatureItems()

    return (
      featureItems.length > 0 && (
        <div className='slider-container'>
          <MDBCard className='transulent-card'>
            <MDBCardBody>
              <Slider {...settings}>{featureItems}</Slider>
            </MDBCardBody>
          </MDBCard>
        </div>
      )
    )
  }

  renderSliderWithBgImage() {
    return (
      <div
        style={{ backgroundImage: `url(${feedOpsFeaturesBackground})` }}
        className={`${
          isMobileOS
            ? 'features-image-mobile-container'
            : 'features-image-container'
        }`}
      >
        {this.renderSlider()}
      </div>
    )
  }

  render() {
    const { isEmbeddedApp, loggedIn } = this.props
    if (isEmbeddedApp) {
      return null
    }

    return isMobileOS
      ? loggedIn
        ? null
        : this.renderSliderWithBgImage()
      : this.renderSliderWithBgImage()
  }
}

export const mapStateToProps = (state, props) => ({
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  features: feedopsFeaturesSelector(state, props),
  loggedIn: loggedInSelector(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFeedopsFeatures }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedopsFeatures)
)
