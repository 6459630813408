export const SAVE_KEYWORD_LIST_ITEMS_REQUEST = 'SAVE_KEYWORD_LIST_ITEMS_REQUEST'
export const SAVE_KEYWORD_LIST_ITEMS_SUCCESS = 'SAVE_KEYWORD_LIST_ITEMS_SUCCESS'
export const SAVE_KEYWORD_LIST_ITEMS_ERROR = 'SAVE_KEYWORD_LIST_ITEMS_ERROR'
export const LOAD_KEYWORD_LIST_ITEMS_REQUEST = 'LOAD_KEYWORD_LIST_ITEMS_REQUEST'
export const LOAD_KEYWORD_LIST_ITEMS_SUCCESS = 'LOAD_KEYWORD_LIST_ITEMS_SUCCESS'
export const LOAD_KEYWORD_LIST_ITEMS_ERROR = 'LOAD_KEYWORD_LIST_ITEMS_ERROR'

export const updateAllKeywordListItems = (items) => ({
  type: LOAD_KEYWORD_LIST_ITEMS_SUCCESS,
  items: items
});

export const loadKeywordListItems = scenarioId => dispatch => {
  dispatch(setPendingKeywordListItems('load'));
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/keyword_list/edit`,
    dataType: 'json',
    success: response => {
      var index = 0;
      var keywordListItems = response.keyword_list.map(
        x => {
          index++
          return { prefix: x.prefix, suffix: x.suffix, itemId: index }
        }
      )
      return dispatch(updateAllKeywordListItems(keywordListItems));
    },
    error: () => dispatch(ajaxErrorMessage('load'))
  })
}

export const saveKeywordListItems = (scenarioId, keywordListItems) => dispatch => {
  dispatch(setPendingKeywordListItems('save'));
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/keyword_list`,
    data: { keyword_list: JSON.stringify(keywordListItems) },
    dataType: 'json',
    success: () => dispatch(saveKeywordListItemsSuccess(keywordListItems)),
    error: () => dispatch(ajaxErrorMessage('save'))
  });
};

const ajaxErrorMessage = (messageType) => ({
  type: messageType === 'save' ? SAVE_KEYWORD_LIST_ITEMS_ERROR : LOAD_KEYWORD_LIST_ITEMS_ERROR,
  errorMessage: `A connection error has occurred while trying to ${messageType} the keyword list items.`
});

const setPendingKeywordListItems = (actionType) => ({
  type: actionType === 'save' ? SAVE_KEYWORD_LIST_ITEMS_REQUEST : LOAD_KEYWORD_LIST_ITEMS_REQUEST
});

const saveKeywordListItemsSuccess = () => ({
  type: SAVE_KEYWORD_LIST_ITEMS_SUCCESS
});
