import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AdChampionMessage from 'components/ad_champion/common/message'
import { MDBProgress } from 'mdbreact'

export default class IndeterminateProgressIndicator extends Component {
  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    messageShort: PropTypes.node,
    messageLong: PropTypes.node
  }

  renderAdChampionMessage() {
    const { messageShort, messageLong } = this.props
    if (messageShort || messageLong) {
      return (
        <div className='md-form'>
          <AdChampionMessage
            messageShort={messageShort}
            messageLong={messageLong}
          />
        </div>
      )
    }
    return null
  }

  render() {
    const { className } = this.props
    return (
      <div className={className}>
        {this.props.message}
        {this.renderAdChampionMessage()}
        <MDBProgress material preloader />
      </div>
    )
  }
}
