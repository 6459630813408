import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getLogoByChannel } from 'util/supported_channels'
import RuleCondition from './column_content/rule_condition'
import RuleAction from './column_content/rule_action'
import {
  CHANNELS_FIELD,
  CONDITIONS_FIELD,
  ACTIONS_FIELD,
  DESCRIPTION_FIELD,
  DRAFT_STATUS_FIELD
} from '../overview_util'

import './column_content.scss'

export class ColumnContent extends Component {
  static propTypes = {
    columnData: PropTypes.object.isRequired,
    rowData: PropTypes.object.isRequired
  }

  conditionallyRenderAdditionalCount(count) {
    if (count > 1) {
      return <div className='additional-count'>+{count - 1}</div>
    }
    return null
  }

  renderChannelCellContent() {
    const { rowData } = this.props

    if (rowData.vendors.length > 0) {
      var vendor = rowData.vendors.at(0)
      return (
        <div className='channel-list'>
          <img
            className='channel-icon'
            src={getLogoByChannel(vendor.channel)}
            alt={vendor.channel}
          />
          {this.conditionallyRenderAdditionalCount(rowData.vendors.length)}
        </div>
      )
    }
    return null
  }

  renderConditionsCellContent() {
    const { rowData } = this.props

    if (rowData.conditions.length > 0) {
      return (
        <div className='condition-list'>
          <RuleCondition condition={rowData.conditions.at(0)} />
          {this.conditionallyRenderAdditionalCount(rowData.conditions.length)}
        </div>
      )
    }
    return null
  }

  renderActionsCellContent() {
    const { rowData } = this.props

    if (rowData.actions.length > 0) {
      return (
        <div className='action-list'>
          <RuleAction action={rowData.actions.at(0)} />
          {this.conditionallyRenderAdditionalCount(rowData.actions.length)}
        </div>
      )
    }

    return null
  }

  renderDescriptionCellContent() {
    const { rowData } = this.props
    return (
      <p
        key={rowData.id}
        title={rowData.description}
        className='discription-cell-ellipsis'
      >
        {rowData.description}
      </p>
    )
  }

  renderDraftStatusCellContent() {
    const { rowData } = this.props
    return <div key={rowData.id}>{rowData.draftStatus}</div>
  }

  conditionallyRenderCellContent() {
    const { columnData } = this.props
    var cellContent = null

    switch (columnData.field) {
      case CHANNELS_FIELD:
        cellContent = this.renderChannelCellContent()
        break
      case CONDITIONS_FIELD:
        cellContent = this.renderConditionsCellContent()
        break
      case ACTIONS_FIELD:
        cellContent = this.renderActionsCellContent()
        break
      case DESCRIPTION_FIELD:
        cellContent = this.renderDescriptionCellContent()
        break
      case DRAFT_STATUS_FIELD:
        cellContent = this.renderDraftStatusCellContent()
        break
      default:
        break
    }

    return cellContent
  }

  render() {
    const { rowData, columnData } = this.props
    const cellId = `rule-${rowData.id}-${columnData.field}`
    return (
      <div className='rules-overview-column' id={cellId}>
        {this.conditionallyRenderCellContent()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ColumnContent)
)
