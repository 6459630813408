import camelCaseKeys from 'camelcase-keys'

export const FETCH_VALUE_FORMATS_REQUEST = 'FETCH_VALUE_FORMATS_REQUEST'
export const FETCH_VALUE_FORMATS_RESPONSE = 'FETCH_VALUE_FORMATS_RESPONSE'

const fetchValueFormatsRequest = () => ({
  type: FETCH_VALUE_FORMATS_REQUEST
})

const fetchValueFormatsResponse = (response) => ({
  type: FETCH_VALUE_FORMATS_RESPONSE,
  ...response
})

export const fetchValueFormats = () => async (dispatch) => {
  dispatch(fetchValueFormatsRequest())
  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/value/formats',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchValueFormatsResponse(response))
  return response
}
