import snakeCaseKeys from 'snakecase-keys'

export const ADD_FEEDOPS_FEEDBACK_REQUEST = 'ADD_FEEDOPS_FEEDBACK_REQUEST'
export const ADD_FEEDOPS_FEEDBACK_RESPONSE = 'ADD_FEEDOPS_FEEDBACK_RESPONSE'
export const SHOW_FEEDOPS_FEEDBACK_MODAL = 'SHOW_FEEDOPS_FEEDBACK_MODAL'
export const HIDE_FEEDOPS_FEEDBACK_MODAL = 'HIDE_FEEDOPS_FEEDBACK_MODAL'

const addFeedOpsFeedbackRequest = (
  websiteId,
  feedbackFor,
  feedbackForId,
  feedback
) => ({
  type: ADD_FEEDOPS_FEEDBACK_REQUEST,
  websiteId,
  feedbackFor,
  feedbackForId,
  feedback
})

const addFeedOpsFeedbackResponse = (
  websiteId,
  feedbackFor,
  feedbackForId,
  feedback,
  response
) => ({
  type: ADD_FEEDOPS_FEEDBACK_RESPONSE,
  websiteId,
  feedbackFor,
  feedbackForId,
  feedback,
  response
})

export const addFeedOpsFeedback =
  (websiteId, feedbackFor, feedbackForId, feedback) => async (dispatch) => {
    dispatch(
      addFeedOpsFeedbackRequest(websiteId, feedbackFor, feedbackForId, feedback)
    )

    var response = await $.ajax({
      method: 'POST',
      url: `/feed_ops/feedbacks/add_feedback`,
      data: snakeCaseKeys(
        { websiteId, feedbackFor, feedbackForId, feedback },
        { deep: true }
      ),
      dataType: 'json'
    })

    dispatch(
      addFeedOpsFeedbackResponse(
        websiteId,
        feedbackFor,
        feedbackForId,
        feedback,
        response
      )
    )
    return response
  }

export const showFeedOpsFeedbackModal =
  (feedbackFor, feedbackForId) => async (dispatch) => {
    dispatch({
      type: SHOW_FEEDOPS_FEEDBACK_MODAL,
      isOpen: true,
      feedbackFor,
      feedbackForId
    })
  }

export const hideFeedOpsFeedbackModal = () => async (dispatch) => {
  dispatch({ type: HIDE_FEEDOPS_FEEDBACK_MODAL, isOpen: false })
}
