import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import { MDBRow, MDBCol } from 'mdbreact'
import BackButton from '../../common/back_button'
import MerchantCenterConnection from 'components/google/merchant_center_connection'
import MicrosoftMerchantCenterConnection from '../../account_connection/microsoft_merchant_center_connection'
import { channelSelector } from 'selectors/channel'
import { SUPPORTED_CHANNELS, CHANNEL_MICROSOFT } from 'util/supported_channels'

import './merchant_center_account.scss'

export class ConnectMerchantCenterAccount extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS)
  }

  renderActions(colClassName) {
    return (
      <MDBRow className='actions-row'>
        <MDBCol />
        <MDBCol className={colClassName}>
          <BackButton label='GO BACK' float='left' />
        </MDBCol>
        <MDBCol />
      </MDBRow>
    )
  }

  renderConnectionByChannel() {
    const { channel } = this.props

    return channel === CHANNEL_MICROSOFT ? (
      <MicrosoftMerchantCenterConnection />
    ) : (
      <MerchantCenterConnection showFooter={false} />
    )
  }

  renderConnectionComponent(colClassName) {
    return (
      <MDBRow className='options-row'>
        <MDBCol />
        <MDBCol className={colClassName}>
          {this.renderConnectionByChannel()}
        </MDBCol>
        <MDBCol />
      </MDBRow>
    )
  }

  render() {
    return (
      <div className={`connect-main-content${isMobileOS ? '-mobile' : ''}`}>
        {this.renderActions(isMobileOS ? 'col-12' : 'col-8')}
        {this.renderConnectionComponent(isMobileOS ? 'col-12' : 'col-8')}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    channel: channelSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const ConnectMerchantCenterAccountWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectMerchantCenterAccount)

export default withRouter(ConnectMerchantCenterAccountWithRedux)
