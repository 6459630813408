import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { isMobileOS } from 'util/operating_systems'
import { MDBCardText, MDBBtn } from 'mdbreact'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { updateUser } from 'actions/users'
import TextInputGroup from 'components/ad_champion/common/text_input_group'
import {
  createValidator,
  validatePresence,
  validateEmail,
  validatePhoneNumber
} from 'components/util/validation'
import { appIdSelector, isEmbeddedAppSelector } from 'selectors/app'
import { currentUserSelector } from 'selectors/user'
import { googleAuthLogout } from 'util/google/google_apis'

export class UserProfileForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    onComplete: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    googleAuthLogout
  }

  constructor(props) {
    super(props)
    this.state = { isSubmitting: false }
  }

  componentDidMount() {
    this.initialiseUser()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.initialiseUser()
    }
  }

  initialiseUser() {
    const { user, initialize } = this.props
    initialize(user)
  }

  renderInputGroup(
    name,
    label,
    disabled = false,
    isOptional = false,
    autoFocus = false
  ) {
    return (
      <TextInputGroup
        name={name}
        label={label}
        disabled={disabled}
        isOptional={isOptional}
        autoFocus={autoFocus}
      />
    )
  }

  renderProfileInputGroups() {
    return (
      <>
        {this.renderInputGroup(
          'profile.workEmail',
          'Work Email',
          false,
          false,
          true
        )}
        {this.renderInputGroup('profile.firstName', 'First Name')}
        {this.renderInputGroup('profile.lastName', 'Last Name')}
      </>
    )
  }

  renderAccountInputGroups() {
    return (
      <>
        {this.renderInputGroup('profile.company', 'Business Name')}
        {this.renderInputGroup(
          'profile.officePhone',
          'Phone Number',
          false,
          true
        )}
      </>
    )
  }

  handleChangeClick() {
    const { googleAuthLogout } = this.props
    googleAuthLogout().then(() => {
      window.location.reload()
    })
  }

  renderChangeEmailBtn() {
    return (
      <MDBBtn
        className='btn-href-style'
        onClick={this.handleChangeClick.bind(this)}
      >
        Change
      </MDBBtn>
    )
  }

  renderSignedInWith() {
    const { user, isEmbeddedApp } = this.props
    const { profile } = user

    return (
      !isEmbeddedApp && (
        <MDBCardText className='signed-in-with-text'>
          Signed in with: {profile.workEmail}
          {this.renderChangeEmailBtn()}
        </MDBCardText>
      )
    )
  }

  render() {
    return (
      <div className='user-profile-form'>
        {this.renderSignedInWith()}
        {this.renderProfileInputGroups()}
        <MDBCardText>Account details</MDBCardText>
        {this.renderAccountInputGroups()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  appId: appIdSelector(state, props),
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  user: currentUserSelector(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser
    },
    dispatch
  )

export const UserProfileFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileForm)

export const validator = (values) => {
  const { workEmail, firstName, lastName, company, officePhone } =
    values.profile || {}
  return {
    profile: {
      workEmail: validatePresence(workEmail) || validateEmail(workEmail),
      firstName: validatePresence(firstName),
      lastName: validatePresence(lastName),
      company: validatePresence(company),
      officePhone: officePhone && validatePhoneNumber(officePhone)
    }
  }
}

export const handleSubmit = async (values, _dispatch, props) => {
  const { updateUser, onComplete } = props

  var result = await updateUser({ ...values, isMobile: isMobileOS })
  if (onComplete) {
    onComplete()
  }
  return result
}

export const REDUX_FORM_NAME = 'UserProfile'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate: createValidator([], validator),
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(UserProfileFormWithRedux)
  )
)
