import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getSingleRowDisableEnableTooltip } from '../common/optimiser_util'
import { SINGLE_ROW_DISABLE_ENABLE_ACTION } from '../common/optimiser_util'
import { websiteFeedDetailSelector } from 'selectors/websites'
import { websiteUpdatedItemsDisabledSelector } from 'selectors/product_feeds/replacements'

export class TableActionsTooltip extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    updatedDisabledItems: PropTypes.object.isRequired
  }

  render() {
    const { actionName, tooltip } = this.props

    if (actionName === SINGLE_ROW_DISABLE_ENABLE_ACTION) {
      const { readOnly, updatedDisabledItems, rowData } = this.props
      return (
        <span>
          {getSingleRowDisableEnableTooltip(
            readOnly,
            updatedDisabledItems,
            rowData
          )}
        </span>
      )
    }

    return <span>{tooltip}</span>
  }
}

export const mapStateToProps = (state, props) => {
  const feedDetail = websiteFeedDetailSelector(state, props)
  return {
    readOnly: feedDetail.sourceChannel !== feedDetail.channel,
    updatedDisabledItems: websiteUpdatedItemsDisabledSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableActionsTooltip)
)
