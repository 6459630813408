import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  reduxForm,  
  Field
} from 'redux-form'
import { Alert } from 'reactstrap'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { createValidator } from 'components/util/validation'
import FeedChampion from 'components/inventory/iframe/feed_champion'

const TEMP_REFERENCE = 'TEMP'
export class FeedChampionForm extends Component {
  static propTypes = {
    inventory: PropTypes.object,
    selectedPlatform: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { initialize, inventory } = this.props
    const { feedChampionReference } = inventory || {}
    initialize({ feedChampionReference: feedChampionReference || TEMP_REFERENCE })
  }

  componentDidUpdate() {
    const { change, inventory } = this.props
    const { feedChampionReference } = inventory || {}
    change('feedChampionReference', feedChampionReference || TEMP_REFERENCE)
  }

  renderFeedChampion() {
    const { selectedPlatform, inventory } = this.props || {}
    const { feedChampionUrl } = inventory || {}
    return(      
      <FeedChampion width="100%" 
        height="100%"
        handleClose={() => {}}
        platform={selectedPlatform}
        feedChampionUrl={feedChampionUrl} />
    )
  }

  render() {
    return(
      <div className='feed-champion'>
        <Alert color='warning'>This step contains multiple sub-steps. Use the right arrow to move through them.</Alert>
        <Field name='feedChampionReference' component={() => <div />} />
        {this.renderFeedChampion()}
      </div>
    )
  }
}

const checkForTempReference = values => {
  return {
    feedChampionReference: values.feedChampionReference === TEMP_REFERENCE ? 'error' : null
  }
}

export const REDUX_FORM_NAME = 'feedChampionForm'
export default reduxForm({
  form: REDUX_FORM_NAME,
  validate: createValidator(['feedChampionReference'], checkForTempReference),
  onSubmit: () => {},
  ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
})(FeedChampionForm)
