import camelCaseKeys from 'camelcase-keys'

export const FETCH_COUNTRY_GEO_TARGET_REQUEST =
  'FETCH_COUNTRY_GEO_TARGET_REQUEST'
export const FETCH_COUNTRY_GEO_TARGET_RESPONSE =
  'FETCH_COUNTRY_GEO_TARGET_RESPONSE'

const fetchCountryGeoTargetsRequest = () => ({
  type: FETCH_COUNTRY_GEO_TARGET_REQUEST
})

const fetchCountryGeoTargetsResponse = (response) => ({
  type: FETCH_COUNTRY_GEO_TARGET_RESPONSE,
  ...response
})

export const fetchCountryGeoTargets = () => async (dispatch) => {
  dispatch(fetchCountryGeoTargetsRequest())

  var response = await $.ajax({
    method: 'GET',
    url: `/geo_targets/countries`,
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(fetchCountryGeoTargetsResponse(camelResponse))

  return camelResponse.countries
}
