import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import RulePillContent from './rule_pill_content'
import { getAppliedFilterText } from 'components/feed_ops/common/popover/popover_util'
import { verticalFieldSelector } from 'selectors/vertical/fields'
import { valueOperatorSelector } from 'selectors/value/operators'

import './rule_condition.scss'

export class RuleCondition extends Component {
  static propTypes = {
    condition: PropTypes.object.isRequired,
    verticalField: PropTypes.object.isRequired,
    operator: PropTypes.object.isRequired
  }

  formatConditionText() {
    const { condition, verticalField, operator } = this.props
    const operatorName = operator.displayName || operator.name || ''
    return getAppliedFilterText(
      verticalField.displayName || verticalField.name,
      operatorName.toLowerCase(),
      (condition.value || '').split('\n')
    )
  }
  render() {
    return (
      <div className='rule-condition' title={this.formatConditionText()}>
        <RulePillContent btnText={this.formatConditionText()} />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const { verticalFieldId, operatorId } = props.condition || {}
  return {
    verticalField: verticalFieldSelector(verticalFieldId)(state, props),
    operator: valueOperatorSelector(operatorId)(state, props)
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RuleCondition)
