import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBNavbar, MDBNavbarNav } from 'mdbreact'
import { websiteUrlSelector } from 'selectors/websites'

// Update this component to include banner for
// Subscription Cancelled
// Trial Expired
// Hierarchy - Subscription Cancelled > Trial Expired > Demo Website
export class BottomBanner extends Component {
  static propTypes = {
    websiteUrl: PropTypes.string.isRequired
  }

  conditionallyRenderDemoWebsiteHeader() {
    const { websiteUrl } = this.props

    return websiteUrl.includes('demowebsite') ? (
      <MDBNavbar
        style={{ paddingLeft: '16px' }}
        expand='md'
        fixed='bottom'
        className='demo-website-header'
      >
        <MDBNavbarNav>
          This is a demo website. To add your website,{' '}
          <a href='/feed_ops/options'>click here</a>
        </MDBNavbarNav>
      </MDBNavbar>
    ) : null
  }

  render() {
    return this.conditionallyRenderDemoWebsiteHeader()
  }
}

export const mapStateToProps = (state, props) => ({
  websiteUrl: websiteUrlSelector(state, props)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomBanner)
)
