export const CLEAR_REQUEST_QUEUE = 'CLEAR_REQUEST_QUEUE'
export const SET_SIDE_NAV_OPEN = 'SET_SIDE_NAV_OPEN'
export const SET_BOOK_A_MEETING_MODAL_OPEN = 'SET_BOOK_A_MEETING_MODAL_OPEN'
export const SET_CHANNEL_FILTER_MODAL_OPEN = 'SET_CHANNEL_FILTER_MODAL_OPEN'
export const SET_CHANNEL_SETTINGS_MODAL_OPEN = 'SET_CHANNEL_SETTINGS_MODAL_OPEN'
export const SET_WORK_EMAIL_MODAL_OPEN = 'SET_WORK_EMAIL_MODAL_OPEN'

export const clearRequestQueue = () => ({ type: CLEAR_REQUEST_QUEUE })

export const setSideNavOpen = (open) => ({
  type: SET_SIDE_NAV_OPEN,
  open
})

export const setBookAMeetingModalOpen = (
  open,
  meetingType,
  jsonData = undefined
) => ({
  type: SET_BOOK_A_MEETING_MODAL_OPEN,
  open,
  meetingType,
  jsonData
})

export const setChannelFilterModalOpen = (open) => ({
  type: SET_CHANNEL_FILTER_MODAL_OPEN,
  open
})

export const setWorkEmailModalOpen = (open) => ({
  type: SET_WORK_EMAIL_MODAL_OPEN,
  open
})
