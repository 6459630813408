export const getMicrosoftUser = (getState) => {
  const state = getState()
  const { user } = state.microsoft
  return user
}

export const getRefreshToken = (getState) => {
  const { refreshToken } = getMicrosoftUser(getState)
  return refreshToken
}
