import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { isDirty, isSubmitting, reset } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { remoteSubmitAndWait } from 'actions/forms'
import { Row, Button } from 'reactstrap'

import './rule_order_notice.scss'

export class RuleOrderNotice extends Component {
  static propTypes = {
    isDirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  }

  handleApplyBtnClick() {
    const { remoteSubmitAndWait } = this.props
    remoteSubmitAndWait(REDUX_FORM_NAME)
  }

  handleDiscardBtnClick() {
    const { reset } = this.props
    reset(REDUX_FORM_NAME)
  }

  renderButton(btnProps, btnName) {
    const { isSubmitting } = this.props

    return (
      <Button tag='span' color='link' disabled={isSubmitting} {...btnProps}>
        {btnName}
      </Button>
    )
  }

  renderActionButtons() {
    return (
      <>
        {this.renderButton(
          { onClick: this.handleApplyBtnClick.bind(this) },
          'Apply'
        )}
        {this.renderButton(
          { onClick: this.handleDiscardBtnClick.bind(this) },
          'Discard'
        )}
      </>
    )
  }

  conditionallyRenderNotice() {
    const { isDirty } = this.props

    return isDirty ? (
      <>
        <Row>
          <p className='feed-rule-info'>
            You've created a draft of your feed rules.
          </p>
        </Row>
        <Row>
          <p className='feed-rule-info'>
            You can preview your draft feed rules before you apply them to your
            feed. When you're satisfied with the preview result, apply them.
          </p>
        </Row>
        <Row>{this.renderActionButtons()}</Row>
      </>
    ) : (
      <p className='feed-rule-info'>
        Rules are processed in order from top to bottom. You can adjust the
        order by dragging your rule to the correct position.
      </p>
    )
  }

  render() {
    return (
      <div className='rule-order-notice'>
        {this.conditionallyRenderNotice()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  isDirty: isDirty(REDUX_FORM_NAME)(state),
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ remoteSubmitAndWait, reset }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RuleOrderNotice)
)
