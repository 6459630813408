import React from 'react'
import _ from 'lodash'
import {
  HEALTH_CRITICAL,
  HEALTH_ERROR,
  HEALTH_WARNING,
  HEALTH_OK
} from 'selectors/playbook/playbook_progress'

export const ACCOUNT_NAME_FIELD = 'accountName'
export const WEBSITE_NAME_FIELD = 'websiteName'
export const SKUS_FIELD = 'skus'
export const HEALTH_FIELD = 'health'
export const SCORE_FIELD = 'score'
export const ACCOUNT_NAME_COLUMN = {
  title: 'Account Name',
  field: ACCOUNT_NAME_FIELD
}
export const COLUMNS = [
  { title: 'Website', field: WEBSITE_NAME_FIELD },
  { title: '#SKUs', field: SKUS_FIELD, type: 'numeric' },
  { title: 'Health', field: HEALTH_FIELD },
  { title: 'Score (%)', field: SCORE_FIELD, type: 'numeric' }
]

export const PER_PAGE = 10
export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

export const APPLIED_FILTERS_ACTION = 'Applied Filters'
export const APPLY_FILTERS_ACTION = 'Apply Filters'
export const ADD_WEBSITE_ACTION = 'Add Website'

export const generateTableOptions = (windowHeight) => {
  return {
    idSynonym: 'websiteId',
    columnResizable: false,
    debounceInterval: 1000,
    defaultExpanded: true,
    draggable: false,
    grouping: false,
    maxBodyHeight: `${windowHeight - 325}px`,
    minBodyHeight: `${windowHeight - 325}px`,
    pageSize: PER_PAGE,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTitle: false,
    search: false,
    sorting: true,
    tableLayout: 'fixed',
    thirdSortClick: false,
    toolbar: true
  }
}

export const generateTableLocalization = () => {
  return {
    header: {
      actions: ''
    },
    body: {
      emptyDataSourceMessage: (
        <span className='no-data-message'>No websites found.</span>
      )
    }
  }
}

export const generateTableActions = () => {
  return [APPLIED_FILTERS_ACTION, APPLY_FILTERS_ACTION, ADD_WEBSITE_ACTION].map(
    (action) => ({
      actionName: action,
      position: 'toolbar',
      tooltip: null
    })
  )
}

const generateSkuTotal = (data) => {
  return data
    .reduce((total, row) => total + row.skus, 0)
    .toLocaleString('en-AU')
}

const getOccKeyInOrder = (occKeys) => {
  var healthKey = null
  if (occKeys.includes(HEALTH_CRITICAL)) {
    healthKey = HEALTH_CRITICAL
  } else if (occKeys.includes(HEALTH_ERROR)) {
    healthKey = HEALTH_ERROR
  } else if (occKeys.includes(HEALTH_WARNING)) {
    healthKey = HEALTH_WARNING
  }

  return healthKey
}

const generateHealthTotal = (data) => {
  if (data.length > 0) {
    const occHash = _.countBy(data.map((row) => row.health))
    const occKeys = Object.keys(occHash)
    const healthKey = getOccKeyInOrder(occKeys)

    if (healthKey && healthKey.length > 0) {
      return `${occHash[healthKey]} ${healthKey}`
    } else if (occKeys.includes(HEALTH_OK)) {
      return HEALTH_OK
    }
  }

  return '-'
}

const generateScoreTotal = (data) => {
  if (data.length > 0) {
    const scoreTotal = data.reduce((total, row) => total + row.score, 0)
    if (scoreTotal === 0) {
      return '-'
    }

    return parseInt(
      scoreTotal / data.filter((row) => row.score !== 0).length
    ).toLocaleString('en-AU')
  } else {
    return '-'
  }
}

export const generateSummaryRow = (column, data, isSingleAccount) => {
  var value = ''
  switch (column.field) {
    case ACCOUNT_NAME_FIELD:
      value = 'Totals/Averages'
      break
    case WEBSITE_NAME_FIELD:
      value = isSingleAccount ? 'Totals/Averages' : ''
      break
    case SKUS_FIELD:
      value = generateSkuTotal(data)
      break
    case HEALTH_FIELD:
      value = generateHealthTotal(data)
      break
    case SCORE_FIELD:
      value = generateScoreTotal(data)
      break
    default:
      break
  }

  return { value, style: { background: 'rgba(224, 224, 224, 0.5)' } }
}
