import {
  FETCH_ADVERTISING_GOAL_TYPES_SUCCESS,
  TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_SUCCESS
} from 'actions/advertising_goal_types'
import { FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS } from 'actions/_advertising/budget_categories'
import { FETCH_ADVERTISING_GOAL_TYPE_SUCCESS } from 'actions/advertising'

const initialState = {
  accounts: {},
  masterAccounts: {},
  websites: {},
  budgetCategories: {}
}

export default (state = initialState, action) => {
  const accountTypeData =
    (action.accountType &&
      action.id &&
      state[action.accountType] &&
      state[action.accountType][action.id]) ||
    {}

  const accountTypeChannelData =
    (action.channel && accountTypeData[action.channel]) || {}

  switch (action.type) {
    case FETCH_ADVERTISING_GOAL_TYPES_SUCCESS:
      return {
        ...state,
        ...action.advertisingGoalTypes
      }
    case FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: {
            ...action.advertisingGoalType
          }
        }
      }
    case FETCH_ADVERTISING_GOAL_TYPE_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              ...action.advertisingGoalType
            }
          }
        },

        displayConversionValueOnCost: false
      }

    case TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_SUCCESS:
      return {
        ...state,
        displayConversionValueOnCost: !state.displayConversionValueOnCost
      }
    default:
      return state
  }
}
