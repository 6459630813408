import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import { MDBNavbarNav, MDBNavItem } from 'mdbreact'
import { subscriptionSelector } from 'selectors/billing'
import { appIdSelector } from 'selectors/app'
import { loggedInSelector } from 'selectors/user'
import WebsitesMenu from '../../ad_champion/common/websites_menu'
import SettingsMenu from '../../ad_champion/common/settings_menu'

export class RightPrimaryHeader extends Component {
  static propTypes = {
    websitesMenu: PropTypes.bool.isRequired,
    helpButton: PropTypes.bool.isRequired,
    settingsMenu: PropTypes.bool.isRequired,
    settingMenuOnlyVisibleLoggIn: PropTypes.bool,
    onLogout: PropTypes.func,
    appId: PropTypes.string.isRequired,
    subscriptionDetail: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  }

  computeShowTrialExpires() {
    const { location, subscriptionDetail } = this.props

    return (
      (location.pathname.includes('product_feeds') ||
        location.pathname.includes('website')) &&
      subscriptionDetail &&
      Object.entries(subscriptionDetail).length > 0 &&
      subscriptionDetail.status === 'in_trial'
    )
  }

  computeExpiryDate() {
    const show = this.computeShowTrialExpires()
    var expiryDate = null

    if (show) {
      const { subscriptionDetail } = this.props
      const trialExpires = subscriptionDetail.trialEnd

      if (trialExpires && trialExpires > 0) {
        const trialExpiresDate = new Date(trialExpires * 1000)
        expiryDate = `${trialExpiresDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}`
      }
    }

    return expiryDate
  }

  conditionallyRenderTrialExpires() {
    const expiryDate = this.computeExpiryDate()

    return expiryDate ? (
      <MDBNavItem className='trial-expires-nav-item'>
        Your free trial expires {expiryDate}
      </MDBNavItem>
    ) : null
  }

  conditionallyRenderTrailExpired() {
    const { subscriptionDetail } = this.props

    return subscriptionDetail &&
      Object.entries(subscriptionDetail).length > 0 &&
      subscriptionDetail.status === 'cancelled' ? (
      <MDBNavItem className='trial-expired-nav-item'>
        Your free trial expired.
      </MDBNavItem>
    ) : null
  }

  handleWebsiteMenuClick(id, type, label) {
    const { appId } = this.props
    this.props.history.push(`/${appId}/${id}`, {})
  }

  conditionallyRenderWebsiteMenu() {
    const { websitesMenu } = this.props

    return websitesMenu ? (
      <MDBNavItem>
        <WebsitesMenu onClick={this.handleWebsiteMenuClick.bind(this)} />
      </MDBNavItem>
    ) : null
  }

  conditionallyRenderSettingsMenu() {
    const { isLoggedIn, settingsMenu, settingMenuOnlyVisibleLoggIn, onLogout } =
      this.props

    return isLoggedIn ? (
      <MDBNavItem>
        <SettingsMenu
          invisible={!settingsMenu}
          invisibleWhenLoggedOut={!settingMenuOnlyVisibleLoggIn}
          onLogout={onLogout}
        />
      </MDBNavItem>
    ) : null
  }

  render() {
    return (
      <MDBNavbarNav right>
        {!isMobileOS && this.conditionallyRenderTrialExpires()}
        {!isMobileOS && this.conditionallyRenderTrailExpired()}
        {this.conditionallyRenderWebsiteMenu()}
        {this.conditionallyRenderSettingsMenu()}
      </MDBNavbarNav>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    appId: appIdSelector(state, props),
    subscriptionDetail: subscriptionSelector(state, props),
    isLoggedIn: loggedInSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RightPrimaryHeader)
)
