import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { getRefreshToken, getMicrosoftUser } from 'selectors/microsoft/user'
import sleep from 'util/sleep'

export const LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_REQUEST =
  'LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_REQUEST'
export const LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_RESPONSE =
  'LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_RESPONSE'
export const LIST_MC_CATALOGS_REQUEST = 'LIST_MC_CATALOGS_REQUEST'
export const LIST_MC_CATALOGS_RESPONSE = 'LIST_MC_CATALOGS_RESPONSE'
export const SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE'
export const DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE'

export const listMerchantCenterAccountsRequest = () => ({
  type: LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_REQUEST
})

export const listMerchantCenterAccountsResponse = (accounts) => ({
  type: LIST_MICROSOFT_MERCHANT_CENTER_ACCOUNTS_RESPONSE,
  accounts
})

export const listMerchantCenterAccounts = () => async (dispatch, getState) => {
  const refreshToken = getRefreshToken(getState)
  dispatch(listMerchantCenterAccountsRequest())

  var response
  var status = 202
  var statusCallback = (_data, _statusText, jqXHR) => {
    status = jqXHR.status
  }

  do {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/microsoft/merchant_center/accounts?refresh_token=${refreshToken}`,
      success: statusCallback,
      error: statusCallback
    })
    if (status === 202) {
      await sleep(1000)
    }
  } while (status === 202)

  const camelCaseResponse = camelCaseKeys(response, { deep: true })
  const accounts = camelCaseResponse.accounts.map((a) =>
    camelCaseKeys(a, { deep: true })
  )
  dispatch(listMerchantCenterAccountsResponse(accounts))

  return accounts
}

export const listMcCatalogsRequest = () => ({
  type: LIST_MC_CATALOGS_REQUEST
})

export const listMcCatalogsResponse = (catalogs) => ({
  type: LIST_MC_CATALOGS_RESPONSE,
  catalogs
})

export const listMcCatalogs =
  (merchantCenterAccountId, customerId, customerAccountId) =>
  async (dispatch, getState) => {
    const refreshToken = getRefreshToken(getState)
    dispatch(listMcCatalogsRequest())

    var data = snakeCaseKeys({
      refreshToken,
      merchantCenterAccountId,
      customerId,
      customerAccountId
    })
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/microsoft/merchant_center/accounts/catalogs`,
      data,
      dataType: 'json'
    })

    return dispatch(
      listMcCatalogsResponse(camelCaseKeys(response, { deep: true }))
    )
  }

export const selectMerchantCenterAccountRequest = (
  websiteId,
  merchantCenterAccountId,
  merchantCenterAccountName,
  catalogId,
  customerId,
  customerAccountId
) => ({
  type: SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId,
  merchantCenterAccountId,
  merchantCenterAccountName,
  catalogId,
  customerId,
  customerAccountId
})

export const selectMerchantCenterAccountResponse = (
  websiteId,
  merchantCenterAccountId,
  merchantCenterAccountName,
  catalogId,
  customerId,
  customerAccountId,
  response
) => ({
  type: SELECT_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  merchantCenterAccountId,
  merchantCenterAccountName,
  catalogId,
  customerId,
  customerAccountId,
  response
})

export const selectMerchantCenterAccount =
  (
    websiteId,
    merchantCenterAccountId,
    merchantCenterAccountName,
    catalogId,
    customerId,
    customerAccountId
  ) =>
  (dispatch, getState) => {
    const { refreshToken, email, accessToken } = getMicrosoftUser(getState)
    dispatch(
      selectMerchantCenterAccountRequest(
        websiteId,
        merchantCenterAccountId,
        merchantCenterAccountName,
        catalogId,
        customerId,
        customerAccountId
      )
    )

    var data = snakeCaseKeys({
      accessToken,
      email,
      refreshToken,
      websiteId,
      merchantCenterAccountId,
      merchantCenterAccountName,
      catalogId,
      customerId,
      customerAccountId
    })
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/microsoft/merchant_center/accounts/select`,
      data,
      dataType: 'json',
      success: (response) => {
        return dispatch(
          selectMerchantCenterAccountResponse(
            websiteId,
            merchantCenterAccountId,
            merchantCenterAccountName,
            catalogId,
            customerId,
            customerAccountId,
            response
          )
        )
      }
    })
  }

const deleteMerchantCenterAccountRequest = (websiteId) => ({
  type: DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId
})

const deleteMerchantCenterAccountResponse = (websiteId, response) => ({
  type: DELETE_MICROSOFT_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  response
})

export const deleteMerchantCenterAccount = (websiteId) => (dispatch) => {
  dispatch(deleteMerchantCenterAccountRequest(websiteId))
  return $.ajax({
    url: `/ad_champion/microsoft/merchant_center/accounts/${websiteId}`,
    method: 'DELETE',
    dataType: 'json',
    success: (response) => {
      dispatch(
        deleteMerchantCenterAccountResponse(websiteId, camelCaseKeys(response))
      )
    }
  })
}
