import { combineReducers } from 'redux'
import analytics from 'reducers/google/analytics'
import merchantCenter from 'reducers/google/merchant_center'
import user from 'reducers/google/user'
import performance from 'reducers/google/performance'
import taxonomies from 'reducers/google/taxonomies'
import general from 'reducers/google/general'
import merchantCenterProductInfoAndMeasures from 'reducers/google/merchant_center_product_info_and_measures'
import merchantCenterProductErrors from 'reducers/google/merchant_center_product_errors'

const reducers = {
  analytics,
  merchantCenter,
  user,
  performance,
  taxonomies,
  general,
  merchantCenterProductInfoAndMeasures,
  merchantCenterProductErrors
}

export default combineReducers(reducers)
