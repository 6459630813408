import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV
} from '@chargebee/chargebee-js-react-wrapper'
import {
  fetchWebsiteCustomer,
  updateWebsitePaymentMethod
} from 'actions/billing'
import { websiteIdSelector } from 'selectors/websites'

import './credit_card_form.scss'

export class CreditCardForm extends Component {
  static propTypes = {
    fetchWebsiteCustomer: PropTypes.func.isRequired,
    updateWebsitePaymentMethod: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)
    this.CardRef = React.createRef()

    this.state = {
      placeholder: {
        number: 'XXXX XXXX XXXX XXXX',
        expiry: 'MM / YY',
        cvv: 'XXX'
      },
      isSubmitting: false
    }
  }

  async onSubmit(e) {
    if (e) {
      e.preventDefault()
    }

    this.setState({ isSubmitting: true })

    // Chargebee returns a temp token that can be used to pass to the back end
    var data = await this.CardRef.current.tokenize()

    const { updateWebsitePaymentMethod, fetchWebsiteCustomer, websiteId } =
      this.props
    await updateWebsitePaymentMethod(websiteId, data.token)

    // Re-fecth website customer to see if we now have a valid CC.
    await fetchWebsiteCustomer(websiteId)

    this.setState({ isSubmitting: false })
  }

  renderCardComponentBody() {
    return (
      <Container>
        <Row>
          <Col xs='12'>
            <CardNumber />
            <label>Card Number</label>
          </Col>
        </Row>
        <Row>
          <Col xs='6'>
            <CardExpiry />
            <label>Expiry</label>
          </Col>
          <Col xs='6'>
            <CardCVV />
            <label>CVV</label>
          </Col>
        </Row>
      </Container>
    )
  }

  render() {
    const { placeholder, isSubmitting } = this.state
    return (
      <div className='credit-card-form'>
        <CardComponent ref={this.CardRef} placeholder={placeholder}>
          {this.renderCardComponentBody()}
        </CardComponent>
        <Button
          color='primary'
          onClick={this.onSubmit.bind(this)}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWebsiteCustomer, updateWebsitePaymentMethod },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditCardForm)
)
