import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { MDBBtn } from 'mdbreact'
import { setBookAMeetingModalOpen } from 'actions/app'
import { createCustomFeed, updateCustomFeed } from 'actions/feed_app/api'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import TextInputGroup from 'components/ad_champion/common/text_input_group'
import { openArticle } from 'components/util/help'
import { validatePresence } from 'components/util/validation'
import { websiteIdSelector, websiteUrlSelector } from 'selectors/websites'
import { channelSelector } from 'selectors/channel'

export class OtherPlatformForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    createCustomFeed: PropTypes.func.isRequired,
    updateCustomFeed: PropTypes.func.isRequired,
    inventoryId: PropTypes.number,
    websiteId: PropTypes.number.isRequired,
    websiteUrl: PropTypes.string.isRequired,
    feedUrl: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    setBookAMeetingModalOpen: PropTypes.func.isRequired,
    meetingType: PropTypes.string.isRequired,
    openArticle: PropTypes.func.isRequired
  }

  static defaultProps = {
    meetingType: 'integration',
    openArticle
  }

  componentDidMount() {
    const { initialize, websiteUrl, feedUrl } = this.props
    initialize({ websiteUrl: websiteUrl || '', feedUrl: feedUrl || '' })
  }

  openConsultationModal() {
    const { setBookAMeetingModalOpen, meetingType } = this.props
    setBookAMeetingModalOpen(true, meetingType)
  }

  renderBookConsultation() {
    return (
      <>
        <p>
          Connection with other eCommerce stores is through an XML feed of
          products and promotions. We can assist in producing this XML feed for
          a once-off setup fee.
          <br />
          The first step is to book a free consultation session where we can
          provide a quotation. You will not be invoiced until the work is
          complete and you have approved it.
        </p>
        <MDBBtn
          color='primary'
          className={'next-action'}
          onClick={this.openConsultationModal.bind(this)}
        >
          Book a consultation
        </MDBBtn>
      </>
    )
  }

  renderHelpAriticle() {
    const { openArticle } = this.props

    return (
      <p>
        Alternatively, the XML feed can be implemented by following{' '}
        <MDBBtn
          className={'btn-href-style'}
          onClick={() => {
            openArticle('dynamic-creative-retail-feed-schema')
          }}
        >
          our documentation
        </MDBBtn>{' '}
        and the eCommerce store can then be connected by providing the URL for
        the XML feed.
      </p>
    )
  }

  prependHTTP(e) {
    const { change } = this.props
    var target = e.target
    var urlTest = /^http(s)?:\/\//i
    if (target && urlTest.exec(target.value) === null) {
      change(target.id, 'https://' + target.value)
    }
  }

  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit} className={'other-platform-form'}>
        {this.renderBookConsultation()}
        {this.renderHelpAriticle()}
        <TextInputGroup
          name={'feedUrl'}
          label={'XML Feed URL'}
          isOptional={false}
          validate={[validatePresence]}
          handleOnBlur={this.prependHTTP.bind(this)}
        />
      </Form>
    )
  }
}

const mapStateToProps = (state, props) => ({
  websiteId: props.websiteId || websiteIdSelector(state, props),
  websiteUrl: props.websiteUrl || websiteUrlSelector(state, props),
  channel: channelSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createCustomFeed, updateCustomFeed, setBookAMeetingModalOpen },
    dispatch
  )

export const REDUX_FORM_NAME = 'OtherPlatform'

export const handleSubmit = async (values, _dispatch, props) => {
  const {
    createCustomFeed,
    updateCustomFeed,
    inventoryId,
    websiteId,
    channel
  } = props

  if (!!inventoryId) {
    await updateCustomFeed(inventoryId, values.feedUrl)
  } else {
    await createCustomFeed(values.feedUrl, websiteId, channel)
  }
}

const OtherPlatformFormWithRedux = reduxForm({
  form: REDUX_FORM_NAME,
  onSubmit: handleSubmit,
  ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
})(OtherPlatformForm)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtherPlatformFormWithRedux)
)
