import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { channelSelector } from 'selectors/channel'
import {
  CONNECTABLE_CHANNELS,
  CHANNEL_MICROSOFT,
  MARKETPLACE_CHANNELS
} from 'util/supported_channels'

import './description_setting.scss'

export class DescriptionSetting extends Component {
  static propTypes = {
    applyRichDescription: PropTypes.bool.isRequired,
    channel: PropTypes.oneOf(CONNECTABLE_CHANNELS).isRequired,
    applyRichDescriptionChange: PropTypes.func.isRequired
  }

  handleRichDescriptionChange(event) {
    const { applyRichDescriptionChange } = this.props
    applyRichDescriptionChange(event.target.checked)
  }

  renderDescriptionSettingAccordian() {
    const { applyRichDescription } = this.props

    return (
      <Accordion defaultExpanded={false} className='description-setting'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Description settings
        </AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            control={<Checkbox />}
            label={`Apply using rich text description to all descriptions?`}
            checked={applyRichDescription}
            onChange={this.handleRichDescriptionChange.bind(this)}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  render() {
    const { channel } = this.props

    return [CHANNEL_MICROSOFT, ...MARKETPLACE_CHANNELS].includes(channel)
      ? null
      : this.renderDescriptionSettingAccordian()
  }
}

export const mapStateToProps = (state, props) => ({
  channel: channelSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DescriptionSetting)
)
