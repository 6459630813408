import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardFooter,
  MDBContainer,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
  MDBView,
  MDBCardText
} from 'mdbreact'
import CategoryList from 'components/inventory/feed_errors/category_list.jsx'
import { setBookAMeetingModalOpen } from 'actions/app'
import { websiteIdSelector } from 'selectors/websites'
import CustomerHelper from 'components/customer_helper/customer_helper'
import FetchErrorButton from 'components/inventory/feed_errors/fetch_error_button'

const ACCOUNT_TAB = 'account'
const PRODUCT_TAB = 'product'

export class Errors extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    setBookAMeetingModalOpen: PropTypes.func.isRequired
  }

  openBookAMeetingModal() {
    const { setBookAMeetingModalOpen } = this.props
    setBookAMeetingModalOpen(true, 'product_feeds_errors')
  }

  renderBookAMeetingFooter() {
    return (
      <MDBCardFooter>
        <CustomerHelper
          helperName='Not sure what to do?'
          articleId='managing-merchant-center-errors'
          bookAMeetingType={'feedops'}
        />
      </MDBCardFooter>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTab: ACCOUNT_TAB
    }
  }

  activateTab(tabId) {
    this.setState({ activeTab: tabId })
  }

  renderTabNavLink(tabId, title) {
    const { activeTab } = this.state
    const { search } = this.props.location
    return (
      <MDBNavItem>
        <MDBNavLink
          link
          to={search}
          active={activeTab === tabId}
          onClick={this.activateTab.bind(this, tabId)}
          className={activeTab === tabId ? 'active-tab' : ''}
        >
          {title}
        </MDBNavLink>
      </MDBNavItem>
    )
  }

  renderAccountPane() {
    return (
      <MDBTabPane tabId={ACCOUNT_TAB} role='tabpanel'>
        <CategoryList scope='account' />
      </MDBTabPane>
    )
  }

  renderProductsPane() {
    return (
      <MDBTabPane tabId={PRODUCT_TAB} role='tabpanel'>
        <CategoryList scope='product' />
      </MDBTabPane>
    )
  }

  render() {
    const { activeTab } = this.state
    return (
      <MDBCard className='merchant-center-diagnostics-errors'>
        <MDBView>
          <MDBCardBody>
            <MDBCardTitle>
              <p className={'merchant-center-errors-title'}>
                Merchant Center Errors
              </p>
              <FetchErrorButton />
            </MDBCardTitle>
            <MDBContainer className={'merchant-center-errors-content'}>
              <MDBCardText>
                It can take up to 24 hours for product errors to appear.
              </MDBCardText>
              <MDBNav classicTabs={true} className='nav-justified'>
                {this.renderTabNavLink(ACCOUNT_TAB, 'Account')}
                {this.renderTabNavLink(PRODUCT_TAB, 'Products')}
              </MDBNav>
              <MDBTabContent activeItem={activeTab}>
                {this.renderAccountPane()}
                {this.renderProductsPane()}
              </MDBTabContent>
            </MDBContainer>
          </MDBCardBody>
          {this.renderBookAMeetingFooter()}
        </MDBView>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBookAMeetingModalOpen
    },
    dispatch
  )

const ErrorsWithRedux = connect(mapStateToProps, mapDispatchToProps)(Errors)

export default withRouter(ErrorsWithRedux)
