import qs from 'qs'
import camelCaseKeys from 'camelcase-keys'
import _ from 'lodash'

export const REQUEST_LOAD_KEYWORDS = 'REQUEST_LOAD_KEYWORDS'
export const RECEIVE_LOAD_KEYWORDS = 'RECEIVE_LOAD_KEYWORDS'
export const REQUEST_EXPORT_KEYWORDS = 'REQUEST_EXPORT_KEYWORDS'
export const RECEIVE_EXPORT_KEYWORDS = 'RECEIVE_EXPORT_KEYWORDS'

const requestLoadKeywords = (scenarioId, keywordIds) => ({
  type: REQUEST_LOAD_KEYWORDS,
  scenarioId,
  keywordIds
})

const receiveLoadKeywords = (scenarioId, keywords) => ({
  type: RECEIVE_LOAD_KEYWORDS,
  scenarioId,
  keywords
})

export const BATCH_SIZE = 50

const keywordIdsToFetch = (state, keywordIds) => {
  keywordIds = _.difference(keywordIds, Object.keys(state.keywords).map(id => parseInt(id, 10)))
  keywordIds = _.difference(keywordIds, state.keywords.requestedIds)
  return keywordIds
}

export const loadKeywords = (scenarioId, keywordIds) => (dispatch, getState) => {
  const state = getState()
  keywordIds = keywordIdsToFetch(state, keywordIds)

  dispatch(requestLoadKeywords(scenarioId, keywordIds))

  var promises = []
  for(var i = 0; i < keywordIds.length; i += BATCH_SIZE) {
    var url = `/ad_champion/scenarios/${scenarioId}/keywords?${qs.stringify({ ids: keywordIds.slice(i, i + BATCH_SIZE) })}`
    promises.push(
      $.ajax({
        method: 'GET',
        url,
        dataType: 'json',
        success: (response) => {
          return dispatch(receiveLoadKeywords(scenarioId, camelCaseKeys(response, { deep: true })))
        }
      })
    )
  }

  return Promise.all(promises)
}

const requestExportKeywords = scenarioId => ({
  type: REQUEST_EXPORT_KEYWORDS,
  scenarioId
})

const receiveExportKeywords = (scenarioId, response) => ({
  type: RECEIVE_EXPORT_KEYWORDS,
  scenarioId,
  response
})

export const exportKeywords = scenarioId => dispatch => {
  dispatch(requestExportKeywords(scenarioId))
  $.ajax({
    method: 'POST',
    url: `/ad_champion/scenarios/${scenarioId}/keywords/export`,
    success: response => dispatch(receiveExportKeywords(scenarioId, camelCaseKeys(response, { deep: true })))
  })
}