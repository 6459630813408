import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import toastr from 'toastr'
import { MDBBtn } from 'mdbreact'
import { saveProductAttributeReplacements } from 'actions/product_feeds/replacements'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  websiteUpdatedProductAttributesSelector,
  websiteUpdatedItemsDisabledSelector
} from 'selectors/product_feeds/replacements'
import { planCanGenerateFeedsSelector } from 'selectors/billing'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class ProductLevelOptimiserBtn extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    updatedItems: PropTypes.object.isRequired,
    updatedDisabledItems: PropTypes.object.isRequired,
    canGenerateFeed: PropTypes.bool.isRequired,
    toastr: PropTypes.object.isRequired,
    saveProductAttributeReplacements: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  async handleSubmit() {
    const {
      saveProductAttributeReplacements,
      updatedItems,
      updatedDisabledItems,
      websiteId,
      feedId,
      channel,
      toastr
    } = this.props

    const saveResponse = await saveProductAttributeReplacements(
      websiteId,
      feedId,
      channel,
      updatedItems,
      updatedDisabledItems,
      true
    )
    if (saveResponse.error) {
      toastr.error(
        'An error occurred while saving your optimizations. Please contact support for assistance.'
      )
    } else {
      toastr.success(
        'Product optimizations applied. Your feed is being generated.'
      )
    }
  }

  render() {
    const { canGenerateFeed } = this.props

    return (
      <MDBBtn
        type='submit'
        color='primary'
        className={`category-optimizations-action`}
        disabled={!canGenerateFeed}
        onClick={this.handleSubmit.bind(this)}
        size='sm'
      >
        Publish
      </MDBBtn>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  channel: channelSelector(state, props),
  updatedItems: websiteUpdatedProductAttributesSelector(state, props),
  updatedDisabledItems: websiteUpdatedItemsDisabledSelector(state, props),
  canGenerateFeed: planCanGenerateFeedsSelector(state, props) || false
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveProductAttributeReplacements }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductLevelOptimiserBtn)
)
