import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Alert, Button, Col, Row } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import {
  getNotifications,
  acknowledgeNotification
} from 'actions/notifications'

import './banner_notification.scss'

export class BannerNotification extends Component {
  static propTypes = {
    isMobileOS: PropTypes.bool.isRequired,
    history: PropTypes.object,
    notification: PropTypes.object,
    getNotifications: PropTypes.func.isRequired,
    acknowledgeNotification: PropTypes.func.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  constructor(props) {
    super(props)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  async componentDidMount() {
    const { getNotifications } = this.props
    await getNotifications()
  }

  notificationLevel(notification) {
    switch (notification.level) {
      case 'error':
        return 'danger'
      default:
        return notification.level
    }
  }

  async acknowledgeNotification() {
    const { acknowledgeNotification, notification, getNotifications } =
      this.props
    await acknowledgeNotification(notification.id)
    await getNotifications()
  }

  computeAlertProperties(notification) {
    const properties = {
      className: 'banner-notification',
      color: this.notificationLevel(notification)
    }

    return isMobileOS
      ? properties
      : { ...properties, toggle: () => this.acknowledgeNotification() }
  }

  handleButtonClick() {
    const { history, notification } = this.props
    const { linkUrl, mobileLinkUrl } = notification
    const url = isMobileOS ? mobileLinkUrl : linkUrl

    if (history) {
      history.push(url)
    } else if (url.includes('http')) {
      window.open(url, '_blank')
    } else {
      window.location = url
    }
  }

  renderButton() {
    const { notification } = this.props
    return (
      <Button color='default' onClick={this.handleButtonClick}>
        {notification.linkText}
      </Button>
    )
  }

  bannerNotification() {
    const { notification } = this.props
    const { message, mobileMessage } = notification
    const msgText = isMobileOS ? mobileMessage : message

    return (
      <Alert {...this.computeAlertProperties(notification)}>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: msgText }} />
            {this.renderButton()}
          </Col>
        </Row>
      </Alert>
    )
  }

  render() {
    const { notification } = this.props

    return notification ? (
      <Container
        className={`banner-notification-container${
          isMobileOS ? '-mobile' : ''
        }`}
      >
        {this.bannerNotification()}
      </Container>
    ) : null
  }
}

export const shouldShowNotification = (notification) => {
  if (notification.status !== 'active') {
    return false
  }

  if (notification.displayUrlFilter) {
    var regex = new RegExp(notification.displayUrlFilter)
    return regex.test(window.location)
  }

  return true
}

export const mapStateToProps = (state) => {
  const notifications = state.notifications.list

  return {
    notification: notifications.find(shouldShowNotification)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getNotifications, acknowledgeNotification }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BannerNotification)
