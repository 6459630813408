import qs from 'qs'
import camelCaseKeys from 'camelcase-keys'
import _ from 'lodash'

export const REQUEST_LOAD_ADS = 'REQUEST_LOAD_ADS'
export const RECEIVE_LOAD_ADS = 'RECEIVE_LOAD_ADS'
export const SAMPLE_ADS_REQUEST = 'SAMPLE_ADS_REQUEST'
export const SAMPLE_ADS_RESPONSE = 'SAMPLE_ADS_RESPONSE'

const requestLoadAds = (scenarioId, adIds) => ({
  type: REQUEST_LOAD_ADS,
  scenarioId,
  adIds
})

const receiveLoadAds = (scenarioId, ads) => ({
  type: RECEIVE_LOAD_ADS,
  scenarioId,
  ads
})

export const BATCH_SIZE = 50

const adIdsToFetch = (state, adIds) => {
  adIds = _.difference(adIds, Object.keys(state.ads).map(id => parseInt(id, 10)))
  adIds = _.difference(adIds, state.ads.requestedIds)
  return adIds
}

export const loadAds = (scenarioId, adIds) => (dispatch, getState) => {
  const state = getState()
  adIds = adIdsToFetch(state, adIds)

  if (adIds.length > 0) {
    dispatch(requestLoadAds(scenarioId, adIds))
  }

  var promises = []
  for(var i = 0; i < adIds.length; i += BATCH_SIZE) {
    promises.push(
      $.ajax({
        method: 'GET',
        url: `/ad_champion/scenarios/${scenarioId}/ads?${qs.stringify({ ids: adIds.slice(i, i + BATCH_SIZE) })}`,
        dataType: 'json',
        success: (response) => {
          return dispatch(receiveLoadAds(scenarioId, camelCaseKeys(response, { deep: true })))
        }
      })
    )
  }

  return Promise.all(promises)
}


const requestSampleAds = (scenarioId) => ({
  type: SAMPLE_ADS_REQUEST,
  scenarioId,
  ads: {}
})

const receiveSampleAds = (scenarioId, ads) => ({
  type: SAMPLE_ADS_RESPONSE,
  scenarioId,
  ads
})

export const loadSampleAds = (scenarioId) => (dispatch) => {
  dispatch(requestSampleAds(scenarioId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/ads/sample_ads`,
    dataType: 'json',
    success:(response) => {
      return dispatch(receiveSampleAds(scenarioId, camelCaseKeys(response, { deep: true })));
    }
  })
}