import { createSelector } from 'reselect'
import _ from 'lodash'
import { stripProtocolFromWebsiteUrl } from 'util/website'

const usersSelector = (state) => state.users || {}

export const currentUserSelector = createSelector(
  usersSelector,
  (users) => users.currentUser || {}
)

// TODO: remove these two methods and use imported
const websiteListSelector = (state, props) =>
  (state.websites || {}).fullList || []

export const loggedInSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.id > 0
)

export const superUserSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.superUser || false
)

export const newUserSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.newUser || false
)

export const profileCompleteSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.profileComplete || false
)

export const canGenManualOptSuggestionsSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.canGenManualOptSuggestions || false
)

export const roleSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.role || ''
)

export const masterAccountListSelector = createSelector(
  currentUserSelector,
  (currentUser) => _.sortBy(currentUser.masterAccountList, ['label']) || []
)

export const accountListSelector = createSelector(
  currentUserSelector,
  (currentUser) => _.sortBy(currentUser.accountList, ['label']) || []
)

export const accountByAccountIdSelector = (accountId) =>
  createSelector(accountListSelector, (accountList) =>
    accountList.find((account) => account.id === accountId)
  ) || {}

export const emailSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.email || ''
)

export const currentUserProfileSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.profile || {}
)

export const workEmailSelector = createSelector(
  currentUserProfileSelector,
  (profile) => profile.workEmail || ''
)

export const currentUserAccountsOverviewSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.accountsOverview || []
)

export const currentUserAccountsOverviewFiltersSelector = createSelector(
  currentUserSelector,
  (currentUser) => currentUser.accountsOverviewFilters || []
)

export const accountTypeSelector = (_state, props) =>
  props.match.params.accountType ||
  (props.match.params.budgetCategoryId ? 'budget_categories' : 'websites')

export const accountTypeIdSelector = createSelector(
  [accountTypeSelector, (_state, props) => props.match.params],
  (accountType, params) => {
    let lookupType
    switch (accountType) {
      case 'master_accounts':
        lookupType = 'accountId'
        break
      case 'accounts':
        lookupType = 'accountId'
        break
      case 'websites':
        lookupType = 'websiteId'
        break
      case 'budget_categories':
        lookupType = 'budgetCategoryId'
        break
      default:
        lookupType = 'websiteId'
    }
    const result = parseInt(params[lookupType] || '0', 10)
    return isNaN(result) ? 0 : result
  }
)

export const userFriendlyAccountTypeSelector = createSelector(
  accountTypeSelector,
  (accountType) => {
    switch (accountType) {
      case 'websites':
        return 'website'
      case 'accounts':
        return 'account'
      case 'master_accounts':
        return 'master account'
      case 'budget_categories':
        return 'budget category'
      default:
        return ''
    }
  }
)

export const accountTypeLabelSelector = createSelector(
  [
    accountTypeSelector,
    accountTypeIdSelector,
    currentUserSelector,
    websiteListSelector
  ],
  (accountType, accountId, user, websitesList) => {
    switch (accountType) {
      case 'master_accounts':
        return (
          (user.masterAccountList.find((ma) => ma.id === accountId) || {})
            .label || ''
        )
      case 'accounts':
        return (
          (user.accountList.find((a) => a.id === accountId) || {}).label || ''
        )
      case 'websites':
        return (
          stripProtocolFromWebsiteUrl(
            (websitesList.find((w) => w.id === accountId) || {}).url
          ) || ''
        )
      case 'budget_categories':
        return (
          (
            websitesList.find((w) => w.budgetCategoryIds.includes(accountId)) ||
            {}
          ).url || ''
        )
      default:
        return ''
    }
  }
)
