import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { currentUserSelector } from 'selectors/user'

export class FeedOpsWebsiteServiceOptionsMobile extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired
  }

  render() {
    return (
      <div className='option-mobile-container'>
        <span>
          You have successfully created your FeedOps account. Please log in on a
          desktop computer to complete your account configuration and start
          optimizing your products.
        </span>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  currentUser: currentUserSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeedOpsWebsiteServiceOptionsMobile)
)
