import { combineReducers } from 'redux'
import formatsReducer from './formats'
import operatorsReducer from './operators'

const reducers = {
  formats: formatsReducer,
  operators: operatorsReducer
}

export default combineReducers(reducers)
