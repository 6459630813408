import { createSelector } from 'reselect'
import { enabledChannelSettingsSelector } from 'selectors/websites/channel_settings'

export const vendorsSelector = (state) => state.vendors || []

export const enabledVendorsSelector = createSelector(
  [vendorsSelector, enabledChannelSettingsSelector],
  (vendors, channelSettings) =>
    vendors.filter((v) => channelSettings.find((cs) => cs.vendorId === v.id))
)
