import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBtn, MDBInput, MDBListGroup, MDBListGroupItem } from 'mdbreact'
import { Popover } from '@material-ui/core'

export default class AccountSelector extends Component {
  static propTypes = {
    accountId: PropTypes.number,
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAccountIdChanged: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      anchorEl: null,
      popoverOpen: false,
      searchText: ''
    }
  }

  handleSelectAccountClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      popoverOpen: true
    })
  }

  renderSelectAccountBtn() {
    const { accountId, accountList } = this.props
    const btnName = accountId
      ? `Account: ${
          accountList.find((account) => account.id === accountId).label
        }`
      : 'Select Account'

    return (
      <MDBBtn
        color='link'
        tag='span'
        className={`btn-account-selector ${
          accountId && 'btn-selected-account'
        }`}
        onClick={this.handleSelectAccountClick.bind(this)}
      >
        {btnName}
      </MDBBtn>
    )
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  handleAccountItemClick(account) {
    const { handleAccountIdChanged } = this.props
    handleAccountIdChanged(account.id)
    this.handleClosePopover()
  }

  handleSearchValueChange(event) {
    this.setState({ searchText: event.target.value })
  }

  renderSearchBox() {
    const { searchText } = this.state

    return (
      <MDBInput
        placeholder='Search Account'
        value={searchText}
        onChange={this.handleSearchValueChange.bind(this)}
      />
    )
  }

  computeAccountList() {
    const { accountList } = this.props
    const { searchText } = this.state

    return searchText
      ? accountList.filter((account) =>
          account.label.toLowerCase().includes(searchText.toLowerCase())
        )
      : accountList
  }

  renderAccountList() {
    const accountList = this.computeAccountList()
    const accountItems = accountList.map((account) => (
      <MDBListGroupItem
        key={account.id}
        hover
        onClick={this.handleAccountItemClick.bind(this, account)}
      >
        {account.label}
      </MDBListGroupItem>
    ))

    return (
      <>
        {this.renderSearchBox()}
        <MDBListGroup>{accountItems}</MDBListGroup>
      </>
    )
  }

  renderPopover() {
    const { anchorEl, popoverOpen } = this.state

    return (
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className='accounts-popover account-selector-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        {this.renderAccountList()}
      </Popover>
    )
  }

  render() {
    return (
      <>
        {this.renderSelectAccountBtn()}
        {this.renderPopover()}
      </>
    )
  }
}
