import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { updateProductAttributeOptimisationSuggestions } from 'actions/product_feeds/replacements'
import { OP_EQUALS } from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  websiteProductOptimisationSuggestionsSelector,
  websiteProductReplacementsFiltersSelector,
  websiteProductReplacementsSelectedColumnsSelector
} from 'selectors/product_feeds/replacements'
import { optimisationSuggestionsPresent } from 'components/feed_ops/website/product_optimisations/common/attributes_util'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'
import toastr from 'toastr'

export class ApplyDismissSuggestions extends Component {
  static propTypes = {
    allRowsSelected: PropTypes.bool.isRequired,
    applySuggestions: PropTypes.bool.isRequired,
    suggestionBtnText: PropTypes.string.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({ field: PropTypes.string, title: PropTypes.string })
    ).isRequired,
    suggestionSource: PropTypes.oneOf(['all', 'manual', 'open_ai']).isRequired,
    disableOnRowsSelected: PropTypes.bool,
    toastr: PropTypes.object.isRequired,
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    feedId: PropTypes.number.isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    optimisationSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired,
    updateProductAttributeOptimisationSuggestions: PropTypes.func.isRequired
  }

  static defaultProps = {
    disableOnRowsSelected: false,
    toastr,
    suggestionSource: 'all'
  }

  isSuggestionsPresent() {
    const {
      allRowsSelected,
      selectedRows,
      optimisationSuggestions,
      selectedColumns
    } = this.props

    return optimisationSuggestionsPresent(
      allRowsSelected,
      selectedRows,
      optimisationSuggestions,
      selectedColumns
    )
  }

  buildFilters() {
    const { allRowsSelected, selectedRows } = this.props
    if (allRowsSelected) {
      return this.props.filters
    } else {
      return [
        {
          attributeName: 'Sku',
          filterType: OP_EQUALS,
          values: selectedRows.map((r) => r.override.sku)
        }
      ]
    }
  }

  async handleApplyDismissSuggestions() {
    const { disableOnRowsSelected, toastr } = this.props
    if (disableOnRowsSelected) {
      return
    }

    toastr.info('Applying optimization suggestions.')
    try {
      const {
        updateProductAttributeOptimisationSuggestions,
        websiteId,
        channel,
        feedId,
        applySuggestions,
        suggestionSource,
        selectedColumns
      } = this.props
      await updateProductAttributeOptimisationSuggestions(
        websiteId,
        channel,
        feedId,
        this.buildFilters(),
        selectedColumns,
        applySuggestions,
        [suggestionSource]
      )
      toastr.success('Your suggestions were applied.')
    } catch (ex) {
      toastr.error('An error occurred applying suggestions.')
    }
    const { onSaveProductAttributesReplacements } = this.props
    await onSaveProductAttributesReplacements()
  }

  render() {
    const { suggestionBtnText } = this.props
    const isSuggestionsPresent = this.isSuggestionsPresent()

    return (
      isSuggestionsPresent && (
        <div
          id={`${suggestionBtnText}-suggestions-btn`}
          tag='span'
          color='link'
          className='btn-action'
          onClick={this.handleApplyDismissSuggestions.bind(this)}
        >
          {suggestionBtnText}
        </div>
      )
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  channel: channelSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  filters: websiteProductReplacementsFiltersSelector(state, props),
  selectedColumns: websiteProductReplacementsSelectedColumnsSelector(
    state,
    props
  ),
  optimisationSuggestions: websiteProductOptimisationSuggestionsSelector(
    state,
    props
  )
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProductAttributeOptimisationSuggestions
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplyDismissSuggestions)
)
