import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import qs from 'qs'

export const FETCH_WEBSITES_REQUEST = 'FETCH_WEBSITES_REQUEST'
export const FETCH_WEBSITES_RESPONSE = 'FETCH_WEBSITES_RESPONSE'
export const FETCH_FULL_WEBSITE_LIST_RESPONSE =
  'FETCH_FULL_WEBSITE_LIST_RESPONSE'
export const FETCH_FULL_WEBSITE_LIST_REQUEST = 'FETCH_FULL_WEBSITE_LIST_REQUEST'
export const FETCH_WEBSITE_DETAIL_REQUEST = 'FETCH_WEBSITE_DETAIL_REQUEST'
export const FETCH_WEBSITE_DETAIL_RESPONSE = 'FETCH_WEBSITE_DETAIL_RESPONSE'
export const CREATE_WEBSITE_REQUEST = 'CREATE_WEBSITE_REQUEST'
export const CREATE_WEBSITE_RESPONSE = 'CREATE_WEBSITE_RESPONSE'
export const UPDATE_WEBSITE_REQUEST = 'UPDATE_WEBSITE_REQUEST'
export const UPDATE_WEBSITE_RESPONSE = 'UPDATE_WEBSITE_RESPONSE'
export const DELETE_WEBSITE_REQUEST = 'DELETE_WEBSITE_REQUEST'
export const DELETE_WEBSITE_RESPONSE = 'DELETE_WEBSITE_RESPONSE'

const fetchWebsitesRequest = (masterAccountId, searchText, sortColumn) => ({
  type: FETCH_WEBSITES_REQUEST,
  masterAccountId,
  searchText,
  sortColumn
})

const fetchWebsitesResponse = (
  masterAccountId,
  searchText,
  sortColumn,
  reset,
  websites
) => ({
  type: FETCH_WEBSITES_RESPONSE,
  masterAccountId,
  searchText,
  sortColumn,
  reset: reset,
  websites
})

const calculateNextPage = (getState, reset) => {
  var nextPage = 1
  const state = getState()
  if (!reset) {
    nextPage = Math.floor(state.websites.list.length / 20) + 1
  }
  return nextPage
}

const buildQueryParam = (searchText, sortColumn) => {
  return qs.stringify({
    q: {
      s: sortColumn,
      url_or_name_cont: searchText
    }
  })
}

const buildUrl = (
  masterAccountId,
  searchText,
  sortColumn,
  reset,
  getState,
  notPaginate
) => {
  var nextPage = calculateNextPage(getState, reset)
  var queryParam = buildQueryParam(searchText, sortColumn)
  var paginate = notPaginate ? false : true
  var masterAccountFilter = masterAccountId
    ? `&master_account_id=${masterAccountId}`
    : ''
  return `/ad_champion/websites?${queryParam}${masterAccountFilter}&page=${nextPage}&paginate=${paginate}`
}

export const fetchWebsites = (params) => (dispatch, getState) => {
  const { masterAccountId, searchText, sortColumn, notPaginate } = params
  var reset = params.reset === undefined ? true : params.reset
  dispatch(fetchWebsitesRequest(masterAccountId, searchText, sortColumn))
  var url = buildUrl(
    masterAccountId,
    searchText,
    sortColumn,
    reset,
    getState,
    notPaginate
  )
  return $.ajax({
    method: 'GET',
    url,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchWebsitesResponse(
          masterAccountId,
          searchText,
          sortColumn,
          reset,
          camelCaseKeys(response)
        )
      )
    }
  })
}

const fetchfullWebsiteListRequest = () => ({
  type: FETCH_FULL_WEBSITE_LIST_REQUEST
})

const fetchFullWebsiteListResponse = (websites) => ({
  type: FETCH_FULL_WEBSITE_LIST_RESPONSE,
  websites
})

export const fetchFullWebsiteList = () => (dispatch) => {
  dispatch(fetchfullWebsiteListRequest())
  return $.ajax({
    method: 'GET',
    url: '/ad_champion/websites?paginate=false',
    dataType: 'json',
    success: (response) => {
      dispatch(
        fetchFullWebsiteListResponse(camelCaseKeys(response, { deep: true }))
      )
    }
  })
}

const fetchWebsiteDetailRequest = (websiteId) => ({
  type: FETCH_WEBSITE_DETAIL_REQUEST,
  websiteId
})

const fetchWebsiteDetailResponse = (websiteId, websiteDetail) => ({
  type: FETCH_WEBSITE_DETAIL_RESPONSE,
  websiteId,
  websiteDetail
})

export const fetchWebsiteDetail = (websiteId) => (dispatch) => {
  if (!websiteId) {
    return Promise.resolve()
  }

  dispatch(fetchWebsiteDetailRequest(websiteId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchWebsiteDetailResponse(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const createWebsiteRequest = (website) => ({
  type: CREATE_WEBSITE_REQUEST,
  website
})

const createWebsiteResponse = (website, response) => ({
  type: CREATE_WEBSITE_RESPONSE,
  website,
  response
})

export const createWebsite = (payload) => async (dispatch) => {
  dispatch(createWebsiteRequest(payload))

  var response = await $.ajax({
    method: 'POST',
    url: `/ad_champion/websites`,
    dataType: 'json',
    data: snakeCaseKeys(payload)
  })
  const camelCaseResponse = camelCaseKeys(response)
  dispatch(createWebsiteResponse(payload, camelCaseResponse))
  return camelCaseResponse
}

const updateWebsiteRequest = (websiteId, payload) => ({
  type: UPDATE_WEBSITE_REQUEST,
  websiteId,
  payload
})

const updateWebsiteResponse = (websiteId, payload, response) => ({
  type: UPDATE_WEBSITE_RESPONSE,
  websiteId,
  payload,
  response
})

export const updateWebsite = (websiteId, payload) => (dispatch) => {
  dispatch(updateWebsiteRequest(websiteId, payload))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/websites/${websiteId}`,
    data: snakeCaseKeys(payload),
    success: (response) => {
      return dispatch(
        updateWebsiteResponse(websiteId, payload, camelCaseKeys(response))
      )
    }
  })
}

const deleteWebsiteRequest = (websiteId, fullDelete) => ({
  type: DELETE_WEBSITE_REQUEST,
  websiteId,
  fullDelete
})

const deleteWebsiteResponse = (websiteId, fullDelete, response) => ({
  type: DELETE_WEBSITE_RESPONSE,
  websiteId,
  fullDelete,
  response
})

export const deleteWebsite =
  (websiteId, fullDelete = false) =>
  (dispatch) => {
    dispatch(deleteWebsiteRequest(websiteId, fullDelete))
    return $.ajax({
      url: `/ad_champion/websites/${websiteId}?full_delete=${fullDelete}`,
      method: 'DELETE',
      success: (response) => {
        dispatch(
          deleteWebsiteResponse(websiteId, fullDelete, camelCaseKeys(response))
        )
      }
    })
  }

export * from 'actions/_websites/_budget'
export * from 'actions/_websites/_contacts'
export * from 'actions/_websites/_status'
