import camelCaseKeys from 'camelcase-keys';

export const REQUEST_MERCHANT_CENTER_FEED = 'REQUEST_MERCHANT_CENTER_FEED'
export const RECEIVE_MERCHANT_CENTER_FEED = 'RECEIVE_MERCHANT_CENTER_FEED'

const requestMerchantCenterFeedData = scenarioId => ({
  type: REQUEST_MERCHANT_CENTER_FEED,
  scenarioId
})

const receiveMerchantCenterFeedData = (scenarioId, data) => ({
  type: RECEIVE_MERCHANT_CENTER_FEED,
  scenarioId,
  data
})

export const fetchMerchantCenterFeedData = scenarioId => dispatch => {
  dispatch(requestMerchantCenterFeedData(scenarioId));
  $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/merchant_center_feed`,
    dataType: 'json',
    success: (response) => {
      dispatch(receiveMerchantCenterFeedData(scenarioId, camelCaseKeys(response, { deep: true })))
    }
  })
}