import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchInventoryFirstReconcileStatus } from 'actions/inventories'
import { fetchWebsiteDetail } from 'actions/websites'
import { MDBRow, MDBCol } from 'mdbreact'
import Poller from 'components/ad_champion/common/poller'
import WebsiteStoreConnection from './store_connection'
import { websiteIdSelector } from 'selectors/websites'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import { websiteRetailInventoryIdsSelector } from 'selectors/websites'

export class WebsiteInitialReconciling extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    isInitialInventoryReconciled: PropTypes.bool.isRequired,
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fetchInventoryFirstReconcileStatus: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  async componentDidUpdate(prevProps) {
    const { isInitialInventoryReconciled } = this.props

    if (
      isInitialInventoryReconciled &&
      !prevProps.isInitialInventoryReconciled
    ) {
      const { fetchWebsiteDetail, websiteId, history } = this.props
      await fetchWebsiteDetail(websiteId)

      history.push(`/feed_ops/${websiteId}/website/connected_channels`)
    }
  }

  async fetchInventoryFirstReconcileStatus() {
    const { fetchInventoryFirstReconcileStatus, websiteId } = this.props
    await fetchInventoryFirstReconcileStatus(websiteId)
  }

  renderReconcileStatusPoller() {
    return (
      <Poller
        action={this.fetchInventoryFirstReconcileStatus.bind(this)}
        periodMs={5000}
      />
    )
  }

  conditionallyRenderComponent() {
    const { isInitialInventoryReconciled } = this.props

    return isInitialInventoryReconciled ? null : (
      <>
        {this.renderReconcileStatusPoller()}
        <WebsiteStoreConnection initialReconcilingCheck={true} />
      </>
    )
  }

  render() {
    return (
      <MDBRow className='initial-reconciling-row'>
        <MDBCol className='col-3' />
        <MDBCol>{this.conditionallyRenderComponent()}</MDBCol>
        <MDBCol className='col-3' />
      </MDBRow>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  isInitialInventoryReconciled:
    initialReconciledStatusSelector(state, props) || false,
  retailInventoryIds: websiteRetailInventoryIdsSelector(state, props) || []
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryFirstReconcileStatus,
      fetchWebsiteDetail
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteInitialReconciling)
