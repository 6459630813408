import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBBtn } from 'mdbreact'
import { openHubspot } from 'components/util/help'
import { customerFeaturesSelector } from 'selectors/billing'
import { websiteDetailSelector } from 'selectors/websites'

export class AskAQuestion extends Component {
  static propTypes = {
    customerFeatures: PropTypes.object.isRequired,
    websiteDetail: PropTypes.object.isRequired,
    btnClassName: PropTypes.string,
    btnSize: PropTypes.string
  }

  static defaultProps = {
    openHubspot,
    btnClassName: 'btn-href-style',
    btnSize: undefined
  }

  render() {
    const { btnClassName, btnSize, openHubspot } = this.props
    return (
      <MDBBtn
        className={btnClassName}
        size={btnSize}
        onClick={() => {
          openHubspot()
        }}
      >
        Send us a message
      </MDBBtn>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    customerFeatures: customerFeaturesSelector(state),
    websiteDetail: websiteDetailSelector(state, props)
  }
}

export default withRouter(connect(mapStateToProps)(AskAQuestion))
