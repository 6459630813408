import { createSelector } from 'reselect'

export const productUrlSettingsSelector = (state, _props) =>
  state.productUrlSettings || {}

export const websiteProductUrlSettingsSelector = (websiteId) =>
  createSelector(
    [productUrlSettingsSelector],
    (productUrlSettings) => productUrlSettings[websiteId] || {}
  )
