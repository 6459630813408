import {
  FETCH_PERFORMANCE_BY_DAY_SUCCESS,
  FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS,
  FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS
} from 'actions/performance/general'

import { FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_RESPONSE } from 'actions/performance/websites'

const initialState = {
  accounts: {},
  masterAccounts: {},
  websites: {},
  budgetCategories: {},
  feedopsAdvertising: {}
}

export default (state = initialState, action) => {
  const accountTypeData =
    (action.accountType &&
      action.id &&
      state[action.accountType] &&
      state[action.accountType][action.id]) ||
    {}

  const accountTypeChannelData =
    (action.channel && accountTypeData[action.channel]) || {}

  switch (action.type) {
    case FETCH_PERFORMANCE_BY_DAY_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              ...action.performanceByDay,
              error: action.error
            }
          }
        }
      }

    case FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              performanceBySubAccountType: action.performanceBySubAccountType,
              subAccountTypeCount: action.count
            }
          }
        }
      }

    case FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              performanceByCampaignType: action.performanceByCampaignType
            }
          }
        }
      }

    case FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_RESPONSE:
      return {
        ...state,
        feedopsAdvertising: {
          ...state.feedopsAdvertising,
          [action.channel]: {
            [action.websiteId]: action.performanceByFeedopsCampaigns || {}
          }
        }
      }

    default:
      return state
  }
}
