import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MDBIcon } from 'mdbreact'
import {
  fetchWebsiteMerchantCenterErrors,
  fetchMerchantCenterErrorTypes
} from 'actions/merchant_center_product_errors'
import { websiteIdSelector } from 'selectors/websites'

export class CategoryDetail extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    category: PropTypes.object.isRequired,
    fetchWebsiteMerchantCenterErrors: PropTypes.func.isRequired,
    fetchMerchantCenterErrorTypes: PropTypes.func.isRequired,
    scope: PropTypes.oneOf(['account', 'product']).isRequired
  }

  constructor(props) {
    super(props)
    this.state = { errors: [], loading: true }
  }

  componentDidMount() {
    this.fetchErrorCategoryDetail()
  }

  componentDidUpdate(prevProps) {
    const { websiteId, category, refreshAt } = this.props
    if (
      prevProps.websiteId !== websiteId ||
      prevProps.refreshAt !== refreshAt ||
      prevProps.category.id !== category.id
    ) {
      this.fetchErrorCategoryDetail()
    }
  }

  async fetchErrorCategoryDetail() {
    const { fetchWebsiteMerchantCenterErrors, websiteId, category } = this.props
    if (!websiteId || !category.id) {
      return
    }

    this.setState({ loading: true })
    const {
      itemCount,
      errors,
      errorCount
    } = await fetchWebsiteMerchantCenterErrors(websiteId, category.id)

    const { fetchMerchantCenterErrorTypes } = this.props
    var { types } = await fetchMerchantCenterErrorTypes(
      errors.map((e) => e.errorTypeId)
    )

    this.setState({
      errors: errors.map((e) => ({
        ...e,
        type: types.find((t) => t.id === e.errorTypeId) || {}
      })),
      errorCount,
      itemCount,
      loading: false
    })
  }

  renderLink(link) {
    return (
      <a href={link} target='_blank'>
        Learn more <MDBIcon icon='external-link' />
      </a>
    )
  }

  renderError(error, index) {
    const { scope } = this.props
    return (
      <div key={index}>
        <div className={'error-description'}>
          <p className={'error-title'}>{error.type.title}</p>
          <p className={'percentage-affected'}>
            {scope !== 'account' && error.count}
          </p>
        </div>
        <div className={'error-detail'}>
          <p>{error.type.detail}</p>
          <div className={'learn-more'}>
            {error.type.link && this.renderLink(error.type.link)}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { errors, loading } = this.state
    return (
      <div
        className={
          loading
            ? 'loading auto-scrollbar category-details'
            : 'auto-scrollbar category-details'
        }
      >
        {errors.map((e, i) => this.renderError(e, i))}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWebsiteMerchantCenterErrors, fetchMerchantCenterErrorTypes },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryDetail)
)
