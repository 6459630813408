import camelCaseKeys from 'camelcase-keys'

export const CREATE_BUDGET_CATEGORY_REQUEST = 'ADD_BUDGET_CATEGORY_REQUEST'
export const CREATE_BUDGET_CATEGORY_SUCCESS = 'ADD_BUDGET_CATEGORY_SUCCESS'
export const DESTROY_BUDGET_CATEGORY_REQUEST = 'DESTROY_BUDGET_CATEGORY_REQUEST'
export const DESTROY_BUDGET_CATEGORY_SUCCESS = 'DESTROY_BUDGET_CATEGORY_SUCCESS'

const createBudgetCategoryRequest = (
  websiteId,
  budgetCategoryName,
  budgetCategoryLabel
) => ({
  type: CREATE_BUDGET_CATEGORY_REQUEST,
  websiteId,
  budgetCategoryName,
  budgetCategoryLabel
})

const createBudgetCategorySuccess = (
  websiteId,
  budgetCategoryName,
  budgetCategoryLabel,
  response
) => ({
  type: CREATE_BUDGET_CATEGORY_SUCCESS,
  websiteId,
  budgetCategoryName,
  budgetCategoryLabel,
  ...response
})

export const createBudgetCategory = (
  websiteId,
  budgetCategoryName,
  budgetCategoryLabel
) => async (dispatch) => {
  dispatch(
    createBudgetCategoryRequest(
      websiteId,
      budgetCategoryName,
      budgetCategoryLabel
    )
  )

  var response = await $.ajax({
    method: 'POST',
    url: `/ad_champion/websites/${websiteId}/budget_categories`,
    data: {
      name: budgetCategoryName,
      label: budgetCategoryLabel
    },
    dataType: 'json'
  })

  dispatch(
    createBudgetCategorySuccess(
      websiteId,
      budgetCategoryName,
      budgetCategoryLabel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const destroyBudgetCategoryRequest = (websiteId, budgetCategoryId) => ({
  type: DESTROY_BUDGET_CATEGORY_REQUEST,
  websiteId,
  budgetCategoryId
})

const destroyBudgetCategorySuccess = (
  websiteId,
  budgetCategoryId,
  response
) => ({
  type: DESTROY_BUDGET_CATEGORY_SUCCESS,
  websiteId,
  budgetCategoryId,
  ...response
})

export const destroyBudgetCategory = (websiteId, budgetCategoryId) => async (
  dispatch
) => {
  dispatch(destroyBudgetCategoryRequest(websiteId, budgetCategoryId))

  var response = await $.ajax({
    method: 'DELETE',
    url: `/ad_champion/websites/${websiteId}/budget_categories/${budgetCategoryId}`,
    dataType: 'json'
  })
  dispatch(
    destroyBudgetCategorySuccess(
      websiteId,
      budgetCategoryId,
      camelCaseKeys(response, { deep: true })
    )
  )
}
