import camelCaseKeys from 'camelcase-keys'
import { getAccessToken, getGoogleUser } from 'selectors/google/user'
import sleep from 'util/sleep'

export const LIST_MERCHANT_CENTER_ACCOUNTS_REQUEST =
  'LIST_MERCHANT_CENTER_ACCOUNTS_REQUEST'
export const LIST_MERCHANT_CENTER_ACCOUNTS_RESPONSE =
  'LIST_MERCHANT_CENTER_ACCOUNTS_RESPONSE'
export const SELECT_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'SELECT_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const SELECT_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'SELECT_MERCHANT_CENTER_ACCOUNT_RESPONSE'
export const CREATE_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'CREATE_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const CREATE_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'CREATE_MERCHANT_CENTER_ACCOUNT_RESPONSE'
export const CREATE_NEW_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'CREATE_NEW_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const CREATE_NEW_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'CREATE_NEW_MERCHANT_CENTER_ACCOUNT_RESPONSE'
export const DELETE_MERCHANT_CENTER_REQUEST = 'DELETE_MERCHANT_CENTER_REQUEST'
export const DELETE_MERCHANT_CENTER_RESPONSE = 'DELETE_MERCHANT_CENTER_RESPONSE'
export const UPDATE_MERCHANT_CENTER_ACCOUNT_REQUEST =
  'UPDATE_MERCHANT_CENTER_ACCOUNT_REQUEST'
export const UPDATE_MERCHANT_CENTER_ACCOUNT_RESPONSE =
  'UPDATE_MERCHANT_CENTER_ACCOUNT_RESPONSE'
export const SEND_ADWORDS_ACCOUNT_LINK_REQUEST =
  'SEND_ADWORDS_ACCOUNT_LINK_REQUEST'
export const SEND_ADWORDS_ACCOUNT_LINK_RESPONSE =
  'SEND_ADWORDS_ACCOUNT_LINK_RESPONSE'

export const listMerchantCenterAccountsRequest = () => ({
  type: LIST_MERCHANT_CENTER_ACCOUNTS_REQUEST
})

export const listMerchantCenterAccountsResponse = (accounts) => ({
  type: LIST_MERCHANT_CENTER_ACCOUNTS_RESPONSE,
  accounts
})

export const listMerchantCenterAccounts = () => async (dispatch, getState) => {
  const accessToken = getAccessToken(getState)
  dispatch(listMerchantCenterAccountsRequest())

  var response
  var status = 202
  var statusCallback = (_data, _statusText, jqXHR) => {
    status = jqXHR.status
  }

  do {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/google/merchant_center/accounts?access_token=${accessToken}`,
      success: statusCallback,
      error: statusCallback
    })
    if (status === 202) {
      await sleep(1000)
    }
  } while (status === 202)

  const camelCaseResponse = camelCaseKeys(response, { deep: true })
  const accounts = camelCaseResponse.accounts.map((a) =>
    camelCaseKeys(a, { deep: true })
  )
  dispatch(listMerchantCenterAccountsResponse(accounts))

  return accounts
}

export const selectMerchantCenterAccountRequest = (
  websiteId,
  merchantCenterAccountId,
  listPrimaryFeeds
) => ({
  type: SELECT_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId,
  merchantCenterAccountId,
  listPrimaryFeeds
})

export const selectMerchantCenterAccountResponse = (
  websiteId,
  merchantCenterAccountId,
  listPrimaryFeeds,
  response
) => ({
  type: SELECT_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  merchantCenterAccountId,
  listPrimaryFeeds,
  response
})

export const selectMerchantCenterAccount =
  (websiteId, merchantCenterAccountId, listPrimaryFeeds = false) =>
  (dispatch, getState) => {
    const { refreshToken, email, accessToken } = getGoogleUser(getState)
    dispatch(
      selectMerchantCenterAccountRequest(
        websiteId,
        merchantCenterAccountId,
        listPrimaryFeeds
      )
    )
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/google/merchant_center/accounts/select`,
      data: {
        access_token: accessToken,
        email,
        refresh_token: refreshToken,
        website_id: websiteId,
        merchant_center_account_id: merchantCenterAccountId,
        list_primary_feeds: listPrimaryFeeds
      },
      dataType: 'json',
      success: (response) => {
        return dispatch(
          selectMerchantCenterAccountResponse(
            websiteId,
            merchantCenterAccountId,
            listPrimaryFeeds,
            response
          )
        )
      }
    })
  }

const createMerchantCenterAccountRequest = (websiteId) => ({
  type: CREATE_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId
})

const createMerchantCenterAccountResponse = (websiteId, response) => ({
  type: CREATE_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  response
})

export const createMerchantCenterAccount = (websiteId) => (dispatch) => {
  dispatch(createMerchantCenterAccountRequest(websiteId))

  return $.ajax({
    method: 'POST',
    url: `/ad_champion/google/merchant_center/accounts`,
    data: {
      website_id: websiteId
    },
    dataType: 'json',
    success: (response) =>
      dispatch(
        createMerchantCenterAccountResponse(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
  })
}

const deleteMerchantCenterAccountRequest = (websiteId) => ({
  type: DELETE_MERCHANT_CENTER_REQUEST,
  websiteId
})

const deleteMerchantCenterAccountResponse = (websiteId, response) => ({
  type: DELETE_MERCHANT_CENTER_RESPONSE,
  websiteId,
  response
})

export const deleteMerchantCenterAccount = (websiteId) => (dispatch) => {
  dispatch(deleteMerchantCenterAccountRequest(websiteId))
  return $.ajax({
    url: `/ad_champion/google/merchant_center/accounts/${websiteId}`,
    method: 'DELETE',
    dataType: 'json',
    success: (response) => {
      dispatch(
        deleteMerchantCenterAccountResponse(websiteId, camelCaseKeys(response))
      )
    }
  })
}

const createNewMerchantCenterAccountRequest = (websiteId, countryCode) => ({
  type: CREATE_NEW_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId,
  countryCode
})

const createNewMerchantCenterAccountResponse = (
  websiteId,
  countryCode,
  response
) => ({
  type: CREATE_NEW_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  countryCode,
  response
})

export const createNewMerchantCenterAccount =
  (websiteId, countryCode) => (dispatch) => {
    dispatch(createNewMerchantCenterAccountRequest(websiteId, countryCode))

    return $.ajax({
      method: 'POST',
      url: `/ad_champion/google/merchant_center/accounts/create_mc_account`,
      data: {
        website_id: websiteId,
        country_of_sale: countryCode
      },
      dataType: 'json',
      success: (response) =>
        dispatch(
          createNewMerchantCenterAccountResponse(
            websiteId,
            countryCode,
            camelCaseKeys(response, { deep: true })
          )
        )
    })
  }

const updateMerchantCenterAccountRequest = (
  websiteId,
  removeExistingPrimaryFeeds,
  countryCode,
  language
) => ({
  type: UPDATE_MERCHANT_CENTER_ACCOUNT_REQUEST,
  websiteId,
  removeExistingPrimaryFeeds,
  countryCode,
  language
})

const updateMerchantCenterAccountResponse = (
  websiteId,
  removeExistingPrimaryFeeds,
  countryCode,
  language,
  response
) => ({
  type: UPDATE_MERCHANT_CENTER_ACCOUNT_RESPONSE,
  websiteId,
  removeExistingPrimaryFeeds,
  countryCode,
  language,
  response
})

export const updateMerchantCenterAccount =
  (websiteId, removeExistingPrimaryFeeds = false, countryCode, language) =>
  (dispatch) => {
    dispatch(
      updateMerchantCenterAccountRequest(
        websiteId,
        removeExistingPrimaryFeeds,
        countryCode,
        language
      )
    )

    return $.ajax({
      method: 'POST',
      url: `/ad_champion/google/merchant_center/accounts/update_merchant_center_account`,
      data: {
        website_id: websiteId,
        remove_existing_primary_feeds: removeExistingPrimaryFeeds,
        country_of_sale: countryCode,
        language: language
      },
      dataType: 'json',
      success: (response) =>
        dispatch(
          updateMerchantCenterAccountResponse(
            websiteId,
            removeExistingPrimaryFeeds,
            countryCode,
            language,
            camelCaseKeys(response, { deep: true })
          )
        )
    })
  }

const sendAdwordsAccountLinkRequest = (websiteId) => ({
  type: SEND_ADWORDS_ACCOUNT_LINK_REQUEST,
  websiteId
})

const sendAdwordsAccountLinkResponse = (websiteId, response) => ({
  type: SEND_ADWORDS_ACCOUNT_LINK_RESPONSE,
  websiteId,
  response
})

export const sendAdwordsAccountLink = (websiteId) => async (dispatch) => {
  dispatch(sendAdwordsAccountLinkRequest(websiteId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/google/merchant_center/accounts/link_adwords_account`,
    data: { website_id: websiteId },
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(sendAdwordsAccountLinkResponse(websiteId, camelResponse))

  return camelResponse
}
