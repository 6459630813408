import {
  GEN_PRODUCT_DATA_AUDIT_RESPONSE,
  FETCH_PRODUCT_DATA_AUDITS_RESPONSE,
  UPDATE_AUDIT_SCHEDULE_RESPONSE,
  SHOW_HIDE_ALL_AUDITS_MODAL
} from 'actions/inventories/product_data_audits'

export const inventoryProductDataAuditsReducer = (state, action) => {
  switch (action.type) {
    case GEN_PRODUCT_DATA_AUDIT_RESPONSE:
    case FETCH_PRODUCT_DATA_AUDITS_RESPONSE:
      return {
        ...state,
        audits: {
          ...state.audits,
          [action.inventoryId]: {
            inventoryAudits: action.inventoryAudits,
            scheduleMessage: action.scheduleMessage
          }
        }
      }
    case UPDATE_AUDIT_SCHEDULE_RESPONSE:
      return {
        ...state,
        audits: {
          ...state.audits,
          [action.inventoryId]: {
            ...state.audits[action.inventoryId],
            scheduleMessage: action.scheduleMessage
          }
        }
      }
    case SHOW_HIDE_ALL_AUDITS_MODAL:
      return {
        ...state,
        audits: {
          ...state.audits,
          showAllAudits: action.show
        }
      }

    default:
      return state
  }
}
