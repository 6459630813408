import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import MaterialTable from '@material-table/core'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { fetchKeywordIdeas } from 'actions/keyword_ideas'
import { fetchWebsiteDetail } from 'actions/websites'
import {
  websiteIdSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'
import CountrySelectorDropdown from 'components/feed_ops/common/country_selector_dropdown'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import {
  generateKeywordIdeasTableOptions,
  generateTableLocalization
} from '../../../common/optimiser_util'
import { websiteFieldKeywordIdeasSelector } from 'selectors/keyword_ideas'
import _ from 'lodash'

const PERMITTED_ATTRIBUTES = [
  'focusKeyword',
  'firstCategory',
  'secondCategory',
  'thirdCategory',
  'fourthCategory',
  'fifthCategory'
]

const KEYWORD_FILED = 'keyword'

const COLUMNS = [
  { title: 'Keyword', field: KEYWORD_FILED, sorting: false },
  {
    title: 'Avg. Monthly Searches',
    field: 'avgMonthlySearches',
    sorting: false
  },
  { title: 'Already Exists in', field: 'alreadyExists', sorting: false }
]

export class KeywordIdeasContainer extends Component {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    attributeDisplayName: PropTypes.string.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    seedKeywordText: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    feedDetail: PropTypes.object.isRequired,
    keywordIdeas: PropTypes.arrayOf(PropTypes.object).isRequired,
    keywordIdeaClick: PropTypes.func.isRequired,
    fetchKeywordIdeas: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      keywordsFetched: false,
      isFetching: false,
      seedKeywordUpdated: false,
      seedKeyword: '',
      geoTargetId: ''
    }
  }

  componentDidMount() {
    const { feedDetail } = this.props
    const { geoTargetId } = feedDetail
    if (geoTargetId) {
      this.setState({ geoTargetId })
    }
  }

  componentDidUpdate(prevProps) {
    const { seedKeywordText } = this.props
    if (!_.isEqual(seedKeywordText, prevProps.seedKeywordText)) {
      this.setState({ seedKeywordUpdated: false, seedKeyword: seedKeywordText })
    }

    const { feedDetail } = this.props
    if (!_.isEqual(prevProps.feedDetail, feedDetail)) {
      const { geoTargetId } = feedDetail
      this.setState({ geoTargetId })
    }
  }

  handelKeywordIdeaBtnClick(idea) {
    const { keywordIdeaClick } = this.props
    keywordIdeaClick(idea)
    this.setState({ seedKeywordUpdated: false, seedKeyword: idea })
  }

  renderKeywordIdeaBtn(idea) {
    return (
      <MDBBtn
        tag='span'
        className='keyword-idea-btn'
        onClick={this.handelKeywordIdeaBtnClick.bind(this, idea)}
      >
        {idea}
      </MDBBtn>
    )
  }

  getDisplayColumns() {
    const { attributeDisplayName } = this.props

    var displayedColumnsData = []
    COLUMNS.forEach((columnData, index) => {
      displayedColumnsData.push({
        ...columnData,
        title: `${columnData.title} ${
          columnData.title === 'Already Exists in' ? attributeDisplayName : ''
        }`,
        render: (rowData) => (
          <span id={`${columnData.field}${index}${rowData.keyword}`}>
            {columnData.field === KEYWORD_FILED
              ? this.renderKeywordIdeaBtn(rowData[columnData.field].toString())
              : rowData[columnData.field].toString()}
          </span>
        )
      })
    })

    return displayedColumnsData
  }

  renderKeywordIdeasTable() {
    const { keywordIdeas, metaData } = this.props

    return (
      <div className='keyword-ideas-container'>
        {keywordIdeas && keywordIdeas.length > 0 ? (
          <MaterialTable
            data={keywordIdeas}
            columns={this.getDisplayColumns()}
            options={generateKeywordIdeasTableOptions()}
            localization={generateTableLocalization({
              allRowsSelected: false,
              metaData
            })}
          />
        ) : (
          <span>Unfortunately, no keyword ideas were found.</span>
        )}
      </div>
    )
  }

  generateItems() {
    const { seedKeyword } = this.state
    const { selectedRows, attributeName } = this.props

    var items = []
    selectedRows.forEach((item) => {
      const keyword =
        seedKeyword || item.override[attributeName] || item.raw[attributeName]
      const url = keyword ? '' : item.raw['url']

      items.push({
        id: item.itemId,
        keyword: keyword,
        url: url
      })
    })

    return items
  }

  async fetchWebsiteDetail() {
    const { fetchWebsiteDetail, websiteId } = this.props
    await fetchWebsiteDetail(websiteId)
  }

  async handleFetchKeywordIdeasClick() {
    const { geoTargetId } = this.state
    const { fetchKeywordIdeas, websiteId, feedDetail, attributeName } =
      this.props
    const { id } = feedDetail
    var items = this.generateItems()
    this.setState({ isFetching: true })

    try {
      await fetchKeywordIdeas(websiteId, id, geoTargetId, items, attributeName)
      await this.fetchWebsiteDetail()
    } catch (error) {
      toastr.error(
        'Something went wrong while fetching keyword ideas. Please try again after sometime.'
      )
    }
    this.setState({
      keywordsFetched: true,
      isFetching: false,
      seedKeywordUpdated: true
    })
  }

  renderFetchBtn() {
    const { isFetching, seedKeywordUpdated } = this.state

    return (
      <div className='keyword-ideas-container'>
        <MDBBtn
          outline
          tag='span'
          color='dark'
          disabled={isFetching || seedKeywordUpdated}
          className='fetch-keyword-ideas-btn'
          onClick={this.handleFetchKeywordIdeasClick.bind(this)}
        >
          Fetch Keyword Ideas
        </MDBBtn>
      </div>
    )
  }

  handleSelectedCountryChange(geoTargetId) {
    this.setState({ geoTargetId, seedKeywordUpdated: false })
  }

  renderCountrySelector() {
    const { geoTargetId } = this.state

    return (
      <CountrySelectorDropdown
        geoTargetId={geoTargetId}
        selectedCountryChange={this.handleSelectedCountryChange.bind(this)}
      />
    )
  }

  render() {
    const { attributeName } = this.props
    if (!PERMITTED_ATTRIBUTES.includes(attributeName)) {
      return null
    }

    const { isFetching, keywordsFetched } = this.state
    return (
      <div className='keyword-ideas-action-container'>
        <MDBRow>
          <MDBCol>{this.renderCountrySelector()}</MDBCol>
          <MDBCol>{this.renderFetchBtn()}</MDBCol>
        </MDBRow>
        {isFetching && (
          <IndeterminateProgressIndicator message='Fetching keyword ideas (may take a few minutes).' />
        )}
        {!isFetching && keywordsFetched && this.renderKeywordIdeasTable()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const attributeName = props.attributeName
  return {
    websiteId: websiteIdSelector(state, props),
    feedDetail: websiteFeedDetailSelector(state, props),
    keywordIdeas:
      websiteFieldKeywordIdeasSelector(attributeName)(state, props) || []
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchKeywordIdeas, fetchWebsiteDetail }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KeywordIdeasContainer)
)
