import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBtn, MDBIcon } from 'mdbreact'

export default class ClearSelectedRows extends Component {
  static propTypes = {
    handleClearSelectedRows: PropTypes.func.isRequired
  }

  handleClearSelectedRowsClick() {
    const { handleClearSelectedRows } = this.props
    handleClearSelectedRows()
  }

  render() {
    return (
      <div>
        <MDBBtn
          tag='span'
          color='link'
          className='btn-column'
          onClick={this.handleClearSelectedRowsClick.bind(this)}
        >
          <MDBIcon icon={'times'} />
          Clear
        </MDBBtn>
      </div>
    )
  }
}
