import { UPDATE_SETTINGS } from 'actions/settings'

const initialState = {
  platform: ''
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.settings
      }
    default:
      return state
  }
}

export default settingsReducer
