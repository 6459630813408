import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_ERROR
} from 'actions/currencies';

const currenciesReducer = (state = { supported: [{ label: 'loading', value: 'loading' }], errorMessage: 'default' }, action) => {
  switch(action.type) {
    case FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        supported: [],
        errorMessage: null
      }
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        supported: action.currencies,
        errorMessage: null
      }
    case FETCH_CURRENCIES_ERROR:
      return {
        ...state,
        supported: [],
        errorMessage: action.errorMessage
      }
    default:
      return state;
  }
}

export default currenciesReducer;
