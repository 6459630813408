import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_INVENTORY_FC_ATTRIBUTES_REQUEST =
  'FETCH_INVENTORY_FC_ATTRIBUTES_REQUEST'
export const FETCH_INVENTORY_FC_ATTRIBUTES_RESPONSE =
  'FETCH_INVENTORY_FC_ATTRIBUTES_RESPONSE'
export const SAVE_INVENTORY_FC_ATTRIBUTES_REQUEST =
  'SAVE_INVENTORY_FC_ATTRIBUTES_REQUEST'
export const SAVE_INVENTORY_FC_ATTRIBUTES_RESPONSE =
  'SAVE_INVENTORY_FC_ATTRIBUTES_RESPONSE'

const fetchInventoryFcAttributesRequest = (websiteId, feedId) => ({
  type: FETCH_INVENTORY_FC_ATTRIBUTES_REQUEST,
  websiteId,
  feedId
})

const fetchInventoryFcAttributesResponse = (
  websiteId,
  feedId,
  fcAttributes,
  inventoryId
) => ({
  type: FETCH_INVENTORY_FC_ATTRIBUTES_RESPONSE,
  websiteId,
  feedId,
  fcAttributes,
  inventoryId
})

export const fetchInventoryFcAttributes =
  (websiteId, feedId = null) =>
  async (dispatch) => {
    dispatch(fetchInventoryFcAttributesRequest(websiteId, feedId))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/inventories/fc_attributes`,
      data: snakeCaseKeys({ feedId }),
      dataType: 'json'
    })

    var fcAttributes = camelCaseKeys(response.fc_attributes, { deep: true })
    var inventoryIdByFeed = response.inventory_id
    dispatch(
      fetchInventoryFcAttributesResponse(
        websiteId,
        feedId,
        fcAttributes,
        inventoryIdByFeed
      )
    )
    return fcAttributes
  }

const saveInventoryFcAttributesRequest = (websiteId, feedId, mappings) => ({
  type: SAVE_INVENTORY_FC_ATTRIBUTES_REQUEST,
  websiteId,
  feedId,
  mappings
})

const saveInventoryFcAttributesResponse = (
  websiteId,
  feedId,
  mappings,
  response
) => ({
  type: SAVE_INVENTORY_FC_ATTRIBUTES_RESPONSE,
  websiteId,
  feedId,
  mappings,
  response
})

export const saveInventoryFcAttributes =
  (websiteId, feedId = null, mappings) =>
  async (dispatch) => {
    dispatch(saveInventoryFcAttributesRequest(websiteId, feedId, mappings))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/inventories/save_fc_attributes`,
      data: snakeCaseKeys(
        { feedId, mappings: JSON.stringify(mappings) },
        { deep: true }
      ),
      dataType: 'json'
    })

    dispatch(
      saveInventoryFcAttributesResponse(websiteId, feedId, mappings, response)
    )
    return response
  }
