import { FETCH_INVENTORY_FC_ATTRIBUTES_RESPONSE } from 'actions/inventories'

export const inventoryFcAttributesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_FC_ATTRIBUTES_RESPONSE:
      return {
        ...state,
        fcAttributes: action.fcAttributes,
        inventoryId: action.inventoryId
      }

    default:
      return state
  }
}
