import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setQueryString } from './header_util'
import HeaderSelectorDropdown from './header_selector_dropdown'
import {
  websiteValidFeedsByChannelSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'

export class FeedSelectorDropdown extends Component {
  static propTypes = {
    float: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    validFeeds: PropTypes.arrayOf(PropTypes.object).isRequired,
    feedId: PropTypes.number
  }

  handleSelectFeedChange(feedId) {
    const { history } = this.props
    history.push(setQueryString(history, { feedId }))
  }

  render() {
    const { float, disabled, validFeeds, feedId } = this.props

    return (
      <HeaderSelectorDropdown
        float={float}
        disabled={disabled}
        validOptions={validFeeds}
        selectedOptionId={feedId}
        optionChange={this.handleSelectFeedChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    validFeeds: websiteValidFeedsByChannelSelector(state, props),
    feedId: websiteFeedIdByChannelSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedSelectorDropdown)
)
