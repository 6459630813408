import {
  FETCH_WEBSITES_REQUEST,
  FETCH_WEBSITES_RESPONSE,
  FETCH_FULL_WEBSITE_LIST_RESPONSE,
  FETCH_WEBSITE_DETAIL_RESPONSE,
  FETCH_WEBSITE_BUDGET_RESPONSE,
  FETCH_WEBSITE_CONTACTS_RESPONSE,
  DELETE_WEBSITE_RESPONSE
} from 'actions/websites'
import {
  CREATE_BUDGET_CATEGORY_SUCCESS,
  DESTROY_BUDGET_CATEGORY_SUCCESS
} from 'actions/_budget_categories/management'
import { FETCH_WEBSITE_PRIMARY_FEEDS_RESPONSE } from 'actions/_websites/_status'
import { ADWORDS_ACCOUNT_LINKING_STATUS_RESPONSE } from 'actions/adwords_account'

const minimumDailyBudget = 300

const initialState = {
  searchText: '',
  sortColumn: 'name_case_insensitive asc',
  list: [],
  fullList: [],
  budgetCategoryFullList: [],
  count: 0,
  details: {},
  budget: {
    id: null,
    goal: 'online-sales',
    dailyBudget: minimumDailyBudget
  }
}

const getWebsiteList = (state, action) => {
  const fetchedWebsites = action.websites.list
  if (action.reset === true) {
    return fetchedWebsites
  }

  const stateWebsiteIds = state.list.map((site) => site.id)
  var website,
    list = state.list.slice(0)
  for (website of fetchedWebsites) {
    if (!stateWebsiteIds.includes(website.id)) {
      list.push(website)
    }
  }
  return list
}

const fetchDetailReducer = (state, action) => {
  const websiteDetailData = state.details[action.websiteDetail.id] || {}
  switch (action.type) {
    case FETCH_WEBSITE_DETAIL_RESPONSE:
      return {
        ...state,
        details: {
          ...state.details,
          [action.websiteDetail.id]: {
            ...websiteDetailData,
            ...action.websiteDetail
          }
        }
      }
    default:
      return state
  }
}

const websiteListReducer = (state, action) => {
  var websiteList = []
  switch (action.type) {
    case FETCH_WEBSITES_REQUEST:
      return {
        ...state,
        searchText: action.searchText,
        sortColumn: action.sortColumn
      }
    case FETCH_WEBSITES_RESPONSE:
      websiteList = getWebsiteList(state, action)
      return {
        ...state,
        count: action.websites.count,
        list: websiteList
      }
    case FETCH_FULL_WEBSITE_LIST_RESPONSE:
      return {
        ...state,
        fullList: action.websites.list,
        budgetCategoryFullList: action.websites.budgetCategoryList
      }
    case DELETE_WEBSITE_RESPONSE:
      return {
        ...state,
        count: state.count - 1,
        list: state.list.filter((w) => w.id !== action.websiteId),
        details: {
          ...state.details,
          [action.websiteId]: null
        }
      }
    case CREATE_BUDGET_CATEGORY_SUCCESS: {
      const website = state.fullList.find(
        (website) => website.id === action.websiteId
      )
      const newFullList = state.fullList.filter(
        (website) => website.id !== action.websiteId
      )
      const newBudgetCategoryIds = [
        ...website.budgetCategoryIds,
        ...action.budgetCategoryIds
      ]
      const newBudgetCategory = {
        name: action.budgetCategoryName,
        label: action.budgetCategoryLabel,
        id: action.budgetCategoryIds[0]
      }
      const everythingElseBudgetCategory =
        action.budgetCategoryIds.length > 1
          ? [
              newBudgetCategory,
              {
                name: 'Everything else',
                label: 'Everything else',
                id: action.budgetCategoryIds[1]
              }
            ]
          : [newBudgetCategory]
      return {
        ...state,
        fullList: [
          ...newFullList,
          { ...website, budgetCategoryIds: newBudgetCategoryIds }
        ],
        budgetCategoryFullList: [
          ...state.budgetCategoryFullList,
          ...everythingElseBudgetCategory
        ]
      }
    }
    case DESTROY_BUDGET_CATEGORY_SUCCESS: {
      const website = state.fullList.find(
        (website) => website.id === action.websiteId
      )
      const newFullList = state.fullList.filter(
        (website) => website.id !== action.websiteId
      )
      const newBudgetCategoryIds = website.budgetCategoryIds.filter(
        (id) => !action.budgetCategoryIds.includes(id)
      )
      return {
        ...state,
        fullList: [
          ...newFullList,
          { ...website, budgetCategoryIds: newBudgetCategoryIds }
        ],
        budgetCategoryFullList: state.budgetCategoryFullList.filter(
          (bc) => !action.budgetCategoryIds.includes(bc.id)
        )
      }
    }

    default:
      return state
  }
}

const websitesReducer = (state = initialState, action) => {
  const websiteDetails = state.details || {}
  const websiteDetailData = websiteDetails[action.websiteId] || {}
  switch (action.type) {
    case FETCH_WEBSITE_DETAIL_RESPONSE:
      return fetchDetailReducer(state, action)
    case FETCH_WEBSITE_PRIMARY_FEEDS_RESPONSE:
      return {
        ...state,
        details: {
          ...state.details,
          [action.websiteId]: {
            ...websiteDetailData,
            primaryFeeds: action.primaryFeeds
          }
        }
      }
    case FETCH_WEBSITE_BUDGET_RESPONSE:
      return {
        ...state,
        budget: action.budget
      }
    case FETCH_WEBSITE_CONTACTS_RESPONSE:
      return {
        ...state,
        contacts: action.contacts
      }

    case ADWORDS_ACCOUNT_LINKING_STATUS_RESPONSE: {
      const adwordsData = websiteDetailData.adwords || {}
      return {
        ...state,
        details: {
          ...websiteDetails,
          [action.websiteId]: {
            ...websiteDetailData,
            adwords: {
              ...adwordsData,
              connected: action.linked
            }
          }
        }
      }
    }

    default:
      return websiteListReducer(state, action)
  }
}

export default websitesReducer
