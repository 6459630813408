import {
  FETCH_BUILDS,
  RESPONSE_ERROR,
  DELETE_BUILD_ERROR,
  LOAD_BUILD_RESPONSE
} from 'actions/builds';

const initialState = {
  orders: [],
  links: {},
  error: null,
  totalOrders: 0,
  details: {}
}

const fetchReducer = (state, action) => {
  switch(action.type) {
    case FETCH_BUILDS:
      return {
        ...state,
        orders: action.orders,
        links: action.links,
        totalOrders: action.totalOrders
      }
    default:
      return state
  }
}

const buildsReducer = (state = initialState, action) => {
  state = fetchReducer(state, action)
  switch(action.type) {    
    case RESPONSE_ERROR:
    case DELETE_BUILD_ERROR:
      return {
        ...state,
        error: action.error
      }
    case LOAD_BUILD_RESPONSE:
      return {
        ...state,
        details: {
          ...state.details,
          [action.build.id]: action.build
        }
      }
    default:
      return state
  }
}

export default buildsReducer
