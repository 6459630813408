import { createSelector } from 'reselect'

const microsoftSelector = (state) => state.microsoft || {}

const merchantCenterSelector = createSelector(
  [microsoftSelector],
  (microsoft) => microsoft.merchantCenter || {}
)

export const merchantCenterAccountsSelector = createSelector(
  [merchantCenterSelector],
  (merchantCenter) => merchantCenter.accounts || []
)

export const merchantCenterCatalogsSelector = createSelector(
  [merchantCenterSelector],
  (merchantCenter) => merchantCenter.catalogs || []
)
