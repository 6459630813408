import camelCaseKeys from 'camelcase-keys'
import snakeCase from 'snake-case'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_KEYWORD_SUGGESTIONS_REQUEST =
  'FETCH_KEYWORD_SUGGESTIONS_REQUEST'
export const FETCH_KEYWORD_SUGGESTIONS_SUCCESS =
  'FETCH_KEYWORD_SUGGESTIONS_SUCCESS'
export const FETCH_KEYWORD_SUGGESTIONS_ERROR = 'FETCH_KEYWORD_SUGGESTIONS_ERROR'
export const UPDATE_KEYWORD_SUGGESTIONS_REQUEST =
  'UPDATE_KEYWORD_SUGGESTIONS_REQUEST'
export const UPDATE_KEYWORD_SUGGESTIONS_SUCCESS =
  'UPDATE_KEYWORD_SUGGESTIONS_SUCCESS'
export const UPDATE_KEYWORD_SUGGESTIONS_ERROR =
  'UPDATE_KEYWORD_SUGGESTIONS_ERROR'
export const APPLY_PENDING_KEYWORD_SUGGESTIONS_REQUEST =
  'APPLY_PENDING_KEYWORD_SUGGESTIONS_REQUEST'
export const APPLY_PENDING_KEYWORD_SUGGESTIONS_SUCCESS =
  'APPLY_PENDING_KEYWORD_SUGGESTIONS_SUCCESS'
export const APPLY_PENDING_KEYWORD_SUGGESTIONS_ERROR =
  'APPLY_PENDING_KEYWORD_SUGGESTIONS_ERROR'

const fetchKeywordSuggestionsRequest = (
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending
) => ({
  type: FETCH_KEYWORD_SUGGESTIONS_REQUEST,
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending
})

const fetchKeywordSuggestionsSuccess = (
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  keywordSuggestions
) => ({
  type: FETCH_KEYWORD_SUGGESTIONS_SUCCESS,
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  ...keywordSuggestions
})

const fetchKeywordSuggestionsError = (
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  error
) => ({
  type: FETCH_KEYWORD_SUGGESTIONS_ERROR,
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  error
})

export const fetchKeywordSuggestions = (
  websiteId,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending
) => async (dispatch) => {
  dispatch(
    fetchKeywordSuggestionsRequest(
      websiteId,
      actionFilter,
      page,
      perPage,
      searchText,
      orderBy,
      ascending
    )
  )

  try {
    var data = snakeCaseKeys(
      {
        actionFilter,
        page,
        perPage,
        orderBy: snakeCase(orderBy),
        ascending,
        filter: searchText
      },
      { deep: true }
    )

    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/keyword_suggestions`,
      data,
      dataType: 'json'
    })

    dispatch(
      fetchKeywordSuggestionsSuccess(
        websiteId,
        actionFilter,
        page,
        perPage,
        searchText,
        orderBy,
        ascending,
        camelCaseKeys(response, { deep: true })
      )
    )
  } catch (error) {
    dispatch(
      fetchKeywordSuggestionsError(
        websiteId,
        actionFilter,
        page,
        perPage,
        searchText,
        orderBy,
        ascending,
        error.responseJSON.error
      )
    )
  }
}

export const refreshKeywordSuggestions = (websiteId, tableName) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    page,
    perPage,
    orderBy,
    ascending,
    searchText
  } = state.keywordSuggestions[tableName]
  await fetchKeywordSuggestions(
    websiteId,
    tableName,
    page,
    perPage,
    searchText,
    orderBy,
    ascending
  )(dispatch)
}

const updateKeywordSuggestionsRequest = (
  websiteId,
  action,
  keywordSuggestionChanges
) => ({
  type: UPDATE_KEYWORD_SUGGESTIONS_REQUEST,
  websiteId,
  action,
  ...keywordSuggestionChanges
})

const updateKeywordSuggestionsSuccess = (websiteId) => ({
  type: UPDATE_KEYWORD_SUGGESTIONS_SUCCESS,
  websiteId
})

const updateKeywordSuggestionsError = (websiteId, error) => ({
  type: UPDATE_KEYWORD_SUGGESTIONS_ERROR,
  websiteId,
  error
})

export const updateKeywordSuggestions = (
  websiteId,
  action,
  keywordSuggestionChanges
) => async (dispatch) => {
  dispatch(
    updateKeywordSuggestionsRequest(websiteId, action, keywordSuggestionChanges)
  )

  try {
    var response = await $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/keyword_suggestions`,
      data: {
        apply_pending: false,
        action_filter: action,
        keyword_suggestion_changes: JSON.stringify(keywordSuggestionChanges)
      },
      dataType: 'json'
    })

    dispatch(updateKeywordSuggestionsSuccess(websiteId))
  } catch (error) {
    dispatch(updateKeywordSuggestionsError(websiteId, error.responseJSON.error))
  }
}

const applyPendingKeywordSuggestionsRequest = (websiteId) => ({
  type: APPLY_PENDING_KEYWORD_SUGGESTIONS_REQUEST,
  websiteId
})

const applyPendingKeywordSuggestionsSuccess = (websiteId) => ({
  type: APPLY_PENDING_KEYWORD_SUGGESTIONS_SUCCESS,
  websiteId
})

const applyPendingKeywordSuggestionsError = (websiteId, error) => ({
  type: APPLY_PENDING_KEYWORD_SUGGESTIONS_ERROR,
  websiteId,
  error
})

export const applyPendingKeywordSuggestions = (websiteId) => async (
  dispatch
) => {
  dispatch(applyPendingKeywordSuggestionsRequest(websiteId))

  try {
    var response = await $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/keyword_suggestions`,
      data: {
        apply_pending: true
      },
      dataType: 'json'
    })

    dispatch(applyPendingKeywordSuggestionsSuccess(websiteId))
  } catch (error) {
    dispatch(
      applyPendingKeywordSuggestionsError(websiteId, error.responseJSON.error)
    )
  }
}
