import {
  FETCH_CAMPAIGN_TYPE_DETAILS_SUCCESS,
  FETCH_CAMPAIGN_TYPE_DETAIL_SUCCESS,
  FETCH_CAMPAIGN_TYPES_SUCCESS
} from 'actions/campaign_types';

const campaignTypesReducer = (state = { list: [], detail: [] }, action) => {
  switch(action.type) {
    case FETCH_CAMPAIGN_TYPES_SUCCESS:
      return {
        ...state,
        list: action.campaignTypes
      }

    case FETCH_CAMPAIGN_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.campaignTypeDetail
      }
    case FETCH_CAMPAIGN_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        detail: action.campaignTypeDetails
      }
    default:
      return state;
  }
}

export default campaignTypesReducer;