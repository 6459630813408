import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import {
  ACCOUNT_OVERVIEW,
  ACCOUNT_SETTINGS,
  ACCOUNT_DETAILS
} from './menu_items'
import SideNavMobileContainer from './side_nav/mobile_container'
import SideNavContainer from './side_nav/container'
import {
  fetchInventoryFirstReconcileStatus,
  fetchInventoryOutboundFeedsGenerationStatus
} from 'actions/inventories'
import { fetchWebsiteDetail } from 'actions/websites'
import { fetchPlaybookProgress } from 'actions/playbook/playbook_progress'
import { showSideNavSelector, isEmbeddedAppSelector } from 'selectors/app'
import { websiteIdSelector, websiteAccountIdSelector } from 'selectors/websites'
import {
  initialReconciledStatusSelector,
  outboundFeedsGeneratedStatusSelector
} from 'selectors/inventory'
import { planCanGenerateFeedsSelector } from 'selectors/billing'
import Poller from 'components/ad_champion/common/poller'

export class FeedOpsSideNav extends Component {
  static propTypes = {
    showSideNav: PropTypes.bool.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object.isRequired,
    websiteId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    isInitialInventoryReconciled: PropTypes.bool.isRequired,
    isOutboundFeedsGenerated: PropTypes.bool.isRequired,
    canGenerateFeed: PropTypes.bool.isRequired,
    fetchInventoryFirstReconcileStatus: PropTypes.func.isRequired,
    fetchInventoryOutboundFeedsGenerationStatus: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    fetchPlaybookProgress: PropTypes.func.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired
  }

  async componentDidMount() {
    await this.fetchInventoryFirstReconcileStatus()
    await this.fetchInventoryOutboundFeedsGenerationStatus()
    await this.fetchWebsiteDetail()
    this.fetchPlaybookProgress()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props

    if (prevProps.websiteId !== websiteId) {
      await this.fetchInventoryFirstReconcileStatus()
      this.fetchPlaybookProgress()
      await this.fetchInventoryOutboundFeedsGenerationStatus()
      await this.fetchWebsiteDetail()
    }
  }

  async fetchInventoryFirstReconcileStatus() {
    const { fetchInventoryFirstReconcileStatus, websiteId } = this.props

    if (websiteId !== 0) {
      await fetchInventoryFirstReconcileStatus(websiteId)
    }
  }

  async fetchInventoryOutboundFeedsGenerationStatus() {
    const { fetchInventoryOutboundFeedsGenerationStatus, websiteId } =
      this.props

    if (websiteId !== 0) {
      await fetchInventoryOutboundFeedsGenerationStatus(websiteId)
    }
  }

  async fetchWebsiteDetail() {
    const { fetchWebsiteDetail, websiteId } = this.props

    if (websiteId !== 0) {
      await fetchWebsiteDetail(websiteId)
    }
  }

  fetchPlaybookProgress() {
    const { fetchPlaybookProgress, websiteId, isInitialInventoryReconciled } =
      this.props

    if (websiteId !== 0 && isInitialInventoryReconciled) {
      fetchPlaybookProgress(websiteId)
    }
  }

  updateMenuItems(menuItems, isEmbeddedApp, currentAccountId) {
    var menuItemsCopy = [...menuItems]
    var accountOverviewMenuItem = menuItemsCopy.find(
      (item) => item.title === ACCOUNT_OVERVIEW
    )
    var accountSettingsMenuItem = menuItemsCopy.find(
      (item) => item.title === ACCOUNT_SETTINGS
    )

    if (isEmbeddedApp) {
      var index = menuItemsCopy.indexOf(accountOverviewMenuItem)
      menuItemsCopy.splice(index, 1)
      index = menuItemsCopy.indexOf(accountSettingsMenuItem)
      menuItemsCopy.splice(index, 1)
    } else {
      accountOverviewMenuItem.path = `feed_ops/accounts/${currentAccountId}/accounts_overview`
      accountSettingsMenuItem.subMenuItems.find(
        (item) => item.title === ACCOUNT_DETAILS
      ).path = `feed_ops/accounts/${currentAccountId}/account_details`
    }

    return menuItemsCopy
  }

  computeMenuItems() {
    const { menuItems, accountId, match, isEmbeddedApp } = this.props
    const currentAccountId = accountId || match.params.accountId

    if (currentAccountId) {
      return this.updateMenuItems(menuItems, isEmbeddedApp, currentAccountId)
    }

    return null
  }

  renderOutboundFeedPoller() {
    const { isOutboundFeedsGenerated } = this.props

    return isOutboundFeedsGenerated ? null : (
      <Poller
        action={this.fetchInventoryOutboundFeedsGenerationStatus.bind(this)}
        periodMs={15000}
        disabled={isOutboundFeedsGenerated}
      />
    )
  }

  renderPollers() {
    const { isInitialInventoryReconciled, canGenerateFeed } = this.props

    return isInitialInventoryReconciled ? (
      canGenerateFeed ? (
        this.renderOutboundFeedPoller()
      ) : null
    ) : (
      <Poller
        action={this.fetchInventoryFirstReconcileStatus.bind(this)}
        periodMs={15000}
        disabled={isInitialInventoryReconciled}
      />
    )
  }

  render() {
    const { showSideNav } = this.props
    const updatedMenuItems = this.computeMenuItems()

    return showSideNav && updatedMenuItems ? (
      <>
        {this.renderPollers()}
        {isMobileOS ? (
          <SideNavMobileContainer />
        ) : (
          <SideNavContainer menuItems={updatedMenuItems} />
        )}
      </>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  showSideNav: showSideNavSelector(state, props),
  websiteId: websiteIdSelector(state, props),
  accountId: websiteAccountIdSelector(state, props) || 0,
  isInitialInventoryReconciled:
    initialReconciledStatusSelector(state, props) || false,
  isOutboundFeedsGenerated:
    outboundFeedsGeneratedStatusSelector(state, props) || false,
  canGenerateFeed: planCanGenerateFeedsSelector(state, props) || false,
  isEmbeddedApp: isEmbeddedAppSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryFirstReconcileStatus,
      fetchInventoryOutboundFeedsGenerationStatus,
      fetchWebsiteDetail,
      fetchPlaybookProgress
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsSideNav)
)
