import toastr from 'toastr'
import { CAN_ADD_WEBSITES } from 'util/router/types'
import { store } from 'containers/ad_platform_context'
import { accountListSelector } from 'selectors/user'
import { customerFeaturesSelector, CUSTOMER_FEATURE_ADD_WEBSITES } from 'selectors/billing'

const requireCanAddWebsites = (to, from, next) => {
  if (to.meta[CAN_ADD_WEBSITES]) {
    const state = store.getState()
    const accountList = accountListSelector(state) || {}
    if (
      customerFeaturesSelector(state)[CUSTOMER_FEATURE_ADD_WEBSITES] === false &&
      accountList &&
      accountList.length >= 1 &&
      accountList.filter((account) => account.websiteIds > 0).length > 0
    ) {
      toastr.error('Only one website is allowed for this account.')
      throw new Error(`Only one website is allowed for this account`)
    }
  }
  next()
}

export default requireCanAddWebsites
