import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import snakeCaseKeys from 'snakecase-keys'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'

export default class ChargeBeeCheckout extends Component {
  static propTypes = {
    editorOpen: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    campaignOrderId: PropTypes.number,
    websiteId: PropTypes.number,
    planId: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleResize = this.handleResize.bind(this)
    this.handleAddIframe = this.handleAddIframe.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  componentDidMount() {
    if (this.props.editorOpen === true) {
      this.loadIframe()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.editorOpen === true && prevProps.editorOpen === false) {
      this.loadIframe()
    }
  }

  loadIframe() {
    const { campaignOrderId, websiteId, planId } = this.props
    this.setState({ loading: true })
    $.ajax({
      url: '/subscription_details/new',
      method: 'GET',
      dataType: 'json',
      data: snakeCaseKeys({ campaignOrderId, websiteId, planId }),
      success: (response) => {
        var url = new URL(response.url)
        var hostname = url.hostname
        var parts = hostname.split('.')
        var siteName = parts[0]
        var hostSuffix = hostname.replace(siteName, '')
        ChargeBee.embed(response.url, siteName).load({
          hostSuffix: hostSuffix,
          addIframe: this.handleAddIframe,
          onLoad: this.handleResize,
          onResize: this.handleResize,
          onCancel: this.props.onCancel,
          onSuccess: () => this.handleSuccess(response.hosted_page_id)
        })
        this.setState({ loading: false })
      }
    })
  }

  handleAddIframe(iframe) {
    iframe.removeAttribute('scrolling')
    this.refs.checkoutInfo.appendChild(iframe)
  }

  handleResize(iframe, width, height) {
    var style = 'border:none;width:100%;'
    style = style + 'height:' + height + 'px;'
    iframe.setAttribute('style', style)
  }

  handleSuccess(hostedPageId) {
    $.ajax({
      url: '/subscription_details',
      method: 'POST',
      dataType: 'json',
      data: { id: hostedPageId, campaign_order_id: this.props.campaignOrderId },
      success: (response) => {
        this.props.onSuccess()
      },
      error: (response) => {
        // TODO: implement real error handling
        console.log('Error!')
      }
    })
  }

  showLoading() {
    if (this.state.loading) {
      return <AdChampionIndeterminateProgressIndicator />
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.editorOpen} id='paymentModal' size='lg'>
          <ModalHeader>Payment Information</ModalHeader>
          <ModalBody>
            <div ref='checkoutInfo' style={{ overflow: 'scroll' }}>
              {this.showLoading()}
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
