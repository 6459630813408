import React, { Component } from 'react'
import { MDBInput, MDBIcon } from 'mdbreact'
import { editorPropTypes } from './common'
import KeywordIdeasContainer from 'components/feed_ops/website/product_optimisations/optimiser/table_actions/bulk_editor/keyword_ideas_container'

export default class BulkTextEditor extends Component {
  static propTypes = {
    ...editorPropTypes
  }

  constructor(props) {
    super(props)
    this.state = { seedKeyword: '' }
  }

  handleInputChange(e) {
    const { onChange } = this.props
    onChange(e.target.value)
    this.setState({ seedKeyword: e.target.value })
  }

  handleKeywordIdeaClick(keywordIdea) {
    const { onChange } = this.props
    onChange(keywordIdea)
  }

  renderKeywordIdeasContainer() {
    const { seedKeyword } = this.state
    const { attributeName, attributeDisplayName, selectedRows } = this.props

    return (
      <KeywordIdeasContainer
        attributeName={attributeName}
        attributeDisplayName={attributeDisplayName}
        selectedRows={selectedRows}
        seedKeywordText={seedKeyword}
        keywordIdeaClick={this.handleKeywordIdeaClick.bind(this)}
      />
    )
  }

  render() {
    const { value, attributeName, onChange } = this.props

    return (
      <>
        <MDBInput
          type={attributeName === 'description' ? 'textarea' : 'input'}
          value={value}
          onChange={this.handleInputChange.bind(this)}
        />
        <MDBIcon icon='trash' onClick={() => onChange('')} />
        {this.renderKeywordIdeasContainer()}
      </>
    )
  }
}
