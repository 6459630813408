import _ from 'lodash'
import { REQUEST_LOAD_ADS, RECEIVE_LOAD_ADS, SAMPLE_ADS_RESPONSE } from 'actions/scenario/ads'

const adsReducer = (state = { requestedIds: [], ads: {}, sample: {}}, action) => {
  switch(action.type) {
    case REQUEST_LOAD_ADS:
      return {
        ...state,
        requestedIds: action.adIds
      }
    case RECEIVE_LOAD_ADS:
      var requestedIds = _.difference(state.requestedIds, Object.keys(action.ads).map(id => parseInt(id, 10)))
      return {
        ...state,
        ...action.ads,
        requestedIds
      }
    case SAMPLE_ADS_RESPONSE:
      return {
        ...state,
        sample: { ...state.sample, [action.scenarioId]: action.ads }
      }
    default:
      return state
  }
}

export default adsReducer