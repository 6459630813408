import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Select from 'react-select'
import { accountListSelector } from 'selectors/user'

export class AccountsSelector extends Component {
  static propTypes = {
    forWebsite: PropTypes.bool.isRequired,
    accountId: PropTypes.number,
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAccountIdChanged: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { options: [] }
  }

  handleAccountIdChanged(accountId) {
    const { handleAccountIdChanged } = this.props
    handleAccountIdChanged(accountId)
  }

  componentDidMount() {
    const { accountList } = this.props
    if (accountList.length === 1) {
      this.handleAccountIdChanged(accountList[0].id)
    }

    this.setState({
      options: accountList.map((account) => ({
        value: account.id,
        label: account.label
      }))
    })
  }

  filterAccountOptions(searchTerm) {
    const { accountList } = this.props
    var newAccountList = []
    if (_.isEmpty(searchTerm)) {
      newAccountList =
        accountList.map((account) => ({
          value: account.id,
          label: account.label
        })) || []
    } else {
      accountList.forEach((option) => {
        if (option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
          newAccountList.push({ value: option.id, label: option.label })
        }
      })
    }

    this.setState({ options: newAccountList })
  }

  handleSearchInput(searchTerm) {
    this.filterAccountOptions(searchTerm)
  }

  handleAccountSelectionChange(selectedOption) {
    this.handleAccountIdChanged(selectedOption.value)
  }

  renderSelect(options, account) {
    return (
      <Select
        isDisabled={false}
        isMulti={false}
        isClearable={false}
        isSearchable={true}
        options={options}
        value={account}
        backspaceRemovesValue={false}
        placeholder='Select Account'
        classNamePrefix='account-select'
        onInputChange={this.handleSearchInput.bind(this)}
        onChange={this.handleAccountSelectionChange.bind(this)}
      />
    )
  }

  renderAccountsDropdown() {
    const { accountId } = this.props
    const { options } = this.state
    const account = accountId
      ? options.find((account) => account.value === accountId)
      : null

    return this.renderSelect(options, account)
  }

  render() {
    const { forWebsite, accountList, accountId } = this.props

    return (
      <>
        {accountId && (
          <span>
            New {forWebsite ? 'website' : 'user'} will be added under the
            account -{' '}
            {accountList.find((account) => account.id === accountId).label}
          </span>
        )}
        {accountList.length > 1 && this.renderAccountsDropdown()}
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  accountList: accountListSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountsSelector)
)
