import {
  FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS,
  FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS,
  FETCH_WEBSITE_SAVINGS_VALUES_RESPONSE,
  FETCH_WEBSITE_WIDE_QS_SCORES_SUCCESS
} from 'actions/quality_score_components'

const initialState = {
  averageQualityScoreComponents: {},
  systemAverageQualityScoreComponents: {},
  websiteSavingsValues: {},
  websiteWideQsScores: {}
}

const qualityScoreComponentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS:
      return {
        ...state,
        averageQualityScoreComponents: action.averageQualityScoreComponents
      }

    case FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS:
      return {
        ...state,
        systemAverageQualityScoreComponents:
          action.systemAverageQualityScoreComponents
      }
    case FETCH_WEBSITE_SAVINGS_VALUES_RESPONSE:
      return { ...state, websiteSavingsValues: action.websiteSavingsValues }
    case FETCH_WEBSITE_WIDE_QS_SCORES_SUCCESS:
      return { ...state, websiteWideQsScores: action.websiteWideQsScores }
    default:
      return state
  }
}

export default qualityScoreComponentsReducer
