import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBSideNavLink, MDBIcon } from 'mdbreact'
import ReactTooltip from 'react-tooltip'
import {
  REQUIRED_SETTING_AD_ACCOUNT_CONNECTED,
  REQUIRED_SETTING_CHANNEL_ENABLED,
  REQUIRED_SETTING_INITIAL_RECONCILE_COMPLETE,
  REQUIRED_SETTING_NOT_EMBEDDED_APP,
  REQUIRED_SETTING_NOT_USER_OVERVIEW
} from 'components/feed_ops/menu_items'
import { isEmbeddedAppSelector } from 'selectors/app'
import { superUserSelector } from 'selectors/user'
import {
  websiteRetailInventoryIdsSelector,
  websiteAdwordsSelector,
  websiteMicrosoftAdwordsSelector
} from 'selectors/websites'
import { enabledChannelsSelector } from 'selectors/websites/channel_settings'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import { playbookProgressWebsiteActiveItemsSelector } from 'selectors/playbook/playbook_progress'
import {
  customerFeaturesSelector,
  planFeaturesSelector
} from 'selectors/billing'
import { PLAYBOOK } from 'components/feed_ops/menu_items'

export class SideNavLink extends Component {
  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    googleAdwordsAccount: PropTypes.object.isRequired,
    microsoftAdwordsAccount: PropTypes.object.isRequired,
    isInitialInventoryReconciled: PropTypes.bool.isRequired,
    activePlaybookItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    customerFeatures: PropTypes.object.isRequired,
    planFeatures: PropTypes.object.isRequired,
    isChannelEnabled: PropTypes.bool.isRequired
  }

  computeToPath(path, absolutePath) {
    const { location, match } = this.props
    const { pathname, search } = location

    return path
      ? absolutePath
        ? `/${path}`
        : `${match.url}/${path}${search}`
      : `${pathname}${search}#`
  }

  computeNavLinkClassName(title, enabled) {
    const titleCss = `link-${title.toLowerCase().replaceAll(' ', '-')}`
    return `side-nav-link ${titleCss} ${enabled ? '' : 'link-disabled'}`
  }

  computeRequiredSettingsMet(menuItem) {
    const { requiredSettings } = menuItem
    var met = true

    switch (requiredSettings) {
      case REQUIRED_SETTING_AD_ACCOUNT_CONNECTED:
        const { googleAdwordsAccount, microsoftAdwordsAccount } = this.props
        met =
          (googleAdwordsAccount && googleAdwordsAccount.adwordsAccountId) ||
          (microsoftAdwordsAccount && microsoftAdwordsAccount.customerAccountId)
            ? true
            : false
        break

      case REQUIRED_SETTING_CHANNEL_ENABLED:
        const { isChannelEnabled } = this.props
        met = isChannelEnabled
        break

      case REQUIRED_SETTING_INITIAL_RECONCILE_COMPLETE:
        met = this.props.isInitialInventoryReconciled
        break

      case REQUIRED_SETTING_NOT_EMBEDDED_APP:
        const { isEmbeddedApp } = this.props
        met = !isEmbeddedApp
        break

      case REQUIRED_SETTING_NOT_USER_OVERVIEW:
        const { location } = this.props
        met = !location.pathname.includes('users_overview')
        break

      default:
        break
    }

    return met
  }

  computeRequiredFeaturesMet(menuItem) {
    const { customerFeatures, planFeatures } = this.props
    const { requiredFeatures } = menuItem
    const { plan, customer } = requiredFeatures || {}
    var met = true

    if (customer) {
      customer.forEach((f) => {
        met = met && customerFeatures[f] === true
      })
    }
    if (plan) {
      plan.forEach((f) => {
        met = met && planFeatures[f] === true
      })
    }

    return met
  }

  onNavLinkClickHandler(action) {
    action()
  }

  renderIcon(iconName) {
    return (
      <MDBIcon
        size='lg'
        icon={iconName ? iconName : 'plus'}
        className={`menu-item-link-icon ${iconName ? null : 'invisible'}`}
      />
    )
  }

  renderActivePlaybookItemsCount(title) {
    const { activePlaybookItems } = this.props
    return title === PLAYBOOK && activePlaybookItems.length !== 0 ? (
      <span>{activePlaybookItems.length}</span>
    ) : null
  }

  renderNavLinkContent(component, iconName, title) {
    return (
      <div>
        {component ? (
          component
        ) : (
          <>
            {this.renderIcon(iconName)}
            {title}
            {this.renderActivePlaybookItemsCount(title)}
          </>
        )}
      </div>
    )
  }

  renderSideNavLink(enabled) {
    const { menuItem } = this.props
    const { title, iconName, path, absolutePath, component, action } = menuItem

    return (
      <MDBSideNavLink
        key={path || title}
        to={enabled ? this.computeToPath(path, absolutePath) : '#'}
        className={this.computeNavLinkClassName(title, enabled)}
        activeClassName={path ? undefined : 'never-active'}
        onClick={
          action
            ? this.onNavLinkClickHandler.bind(this, action)
            : enabled
            ? null
            : (event) => event.preventDefault()
        }
      >
        {this.renderNavLinkContent(component, iconName, title)}
      </MDBSideNavLink>
    )
  }

  render() {
    const { menuItem, retailInventoryIds } = this.props
    const { title, path } = menuItem
    const enabled =
      this.computeRequiredSettingsMet(menuItem) &&
      this.computeRequiredFeaturesMet(menuItem)

    return (
      <span>
        <span data-tip data-for={path || title}>
          {this.renderSideNavLink(enabled)}
        </span>
        <ReactTooltip
          id={path || title}
          className='side-nav-tooltip'
          place='right'
          effect='solid'
          disable={enabled}
        >
          {retailInventoryIds.length === 0
            ? 'Please connect your eCommerce Store by going to Website > Connect'
            : menuItem.toolTip}
        </ReactTooltip>
      </span>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    isSuperUser: superUserSelector(state, props),
    retailInventoryIds: websiteRetailInventoryIdsSelector(state, props),
    googleAdwordsAccount: websiteAdwordsSelector(state, props),
    microsoftAdwordsAccount: websiteMicrosoftAdwordsSelector(state, props),
    isInitialInventoryReconciled:
      initialReconciledStatusSelector(state, props) || false,
    activePlaybookItems:
      playbookProgressWebsiteActiveItemsSelector(state, props) || [],
    isChannelEnabled: enabledChannelsSelector(state, props).length > 0,
    customerFeatures: customerFeaturesSelector(state, props),
    planFeatures: planFeaturesSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideNavLink)
)
