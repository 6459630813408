import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  inventoryProcessingStatusSelector,
  inventoryProcessingValueSelector
} from 'selectors/inventory'

import './inventory_status.scss'

export class InventoryStatus extends Component {
  static propTypes = {
    processingMessage: PropTypes.string.isRequired,
    processingValue: PropTypes.number.isRequired
  }

  renderProcessingStatus() {
    const { processingMessage, processingValue } = this.props
    return (
      <>
        {processingMessage}
        {processingValue > 0 && ` (${processingValue}%)`}
        <LinearProgress
          variant={processingValue ? 'determinate' : 'indeterminate'}
          value={processingValue}
        />
      </>
    )
  }

  render() {
    const { processingMessage, processingValue } = this.props
    if (!processingMessage && !processingValue) {
      return null
    }

    return (
      <Container className='inventory-status'>
        {this.renderProcessingStatus()}
      </Container>
    )
  }
}

const mapStateToProps = (state, props) => ({
  processingMessage: inventoryProcessingStatusSelector(state, props),
  processingValue: inventoryProcessingValueSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryStatus)
)
