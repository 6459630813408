import camelCaseKeys from 'camelcase-keys'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_RESPONSE = 'GET_NOTIFICATIONS_RESPONSE'
export const ACKNOWLEDGE_NOTIFICATION_REQUEST = 'ACKNOWLEDGE_NOTIFICATION_REQUEST'
export const ACKNOWLEDGE_NOTIFICATION_RESPONSE = 'ACKNOWLEDGE_NOTIFICATION_RESPONSE'

const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST
})

const getNotificationsResponse = notifications => ({
  type: GET_NOTIFICATIONS_RESPONSE,
  notifications
})

export const getNotifications = () => dispatch => {
  dispatch(getNotificationsRequest())
  return $.ajax({
    method: 'GET',
    url: '/ad_champion/notifications',
    success: response => {
      dispatch(getNotificationsResponse(camelCaseKeys(response, { deep: true })))
    }
  })
}

const acknowledgeNotificationRequest = id => ({
  type: ACKNOWLEDGE_NOTIFICATION_REQUEST,
  id
})

const acknowledgeNotificationResponse = (id, response) => ({
  type: ACKNOWLEDGE_NOTIFICATION_RESPONSE,
  id,
  response
})

export const acknowledgeNotification = id => dispatch => {
  dispatch(acknowledgeNotificationRequest(id))
  $.ajax({
    method: 'PUT',
    url: `/ad_champion/notifications/${id}`,
    dataType: 'json',
    data: { status: 'acknowledged' },
    success: response => dispatch(acknowledgeNotificationResponse(id, response))
  })
}