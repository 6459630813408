import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdbreact'
import { channelSettingsSelector } from 'selectors/websites/channel_settings'
import { websiteAdwordsSelector } from 'selectors/websites'
import { googleAdsLogo, CHANNEL_GOOGLE } from 'util/supported_channels'

import './product_feeds_container.scss'

export class AdvertisingToolsContainer extends Component {
  static propTypes = {
    channelSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
    adwordsAccount: PropTypes.object.isRequired
  }

  handleConnectChannelClick() {
    const { history } = this.props
    history.push('connect_ads_account')
  }

  renderChannelButton(channelSetting) {
    const { adwordsAccount } = this.props
    const { channel, enabled } = channelSetting
    const btnName = adwordsAccount.adwordsAccountId ? 'DETAILS' : 'LINK'

    return (
      <MDBBtn
        disabled={!enabled}
        onClick={this.handleConnectChannelClick.bind(this)}
      >
        <img src={googleAdsLogo} alt={channel} /> {btnName}
      </MDBBtn>
    )
  }

  render() {
    const { channelSettings } = this.props
    const channelSetting = channelSettings.find(
      (cs) => cs.channel === CHANNEL_GOOGLE
    )

    return (
      <div className='product-feeds-container'>
        <MDBRow>
          <MDBCol>
            <MDBContainer className='channel-item-container'>
              <MDBRow>
                <MDBCol size='8'>
                  Link Google Ads with Google Merchant Center
                </MDBCol>
                <MDBCol>{this.renderChannelButton(channelSetting)}</MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    channelSettings: channelSettingsSelector(state, props),
    adwordsAccount: websiteAdwordsSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdvertisingToolsContainer)
)
