import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'

export default class FilterOptionsDropdown extends Component {
  static propTypes = {
    selectedFilterOption: PropTypes.string.isRequired,
    filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleFilterOptionItemClick: PropTypes.func.isRequired
  }

  renderDropdownToggle() {
    const { selectedFilterOption } = this.props

    return (
      <MDBDropdownToggle tag='div' className='filter-dropdown-toggle'>
        <div>{selectedFilterOption}</div>
        <div className='float-right'>
          <MDBIcon icon='chevron-down' className='float-right' />
        </div>
      </MDBDropdownToggle>
    )
  }

  handleFilterOptionItemClick(index) {
    const { filterOptions, handleFilterOptionItemClick } = this.props
    handleFilterOptionItemClick(filterOptions[index])
  }

  renderDropdownMenu() {
    const { filterOptions } = this.props

    return (
      <MDBDropdownMenu>
        {filterOptions.map((option, index) => (
          <MDBDropdownItem
            id={option}
            key={option}
            onClick={this.handleFilterOptionItemClick.bind(this, index)}
          >
            {option}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  render() {
    return (
      <MDBDropdown>
        {this.renderDropdownToggle()}
        {this.renderDropdownMenu()}
      </MDBDropdown>
    )
  }
}
