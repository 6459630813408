import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DataTable from 'react-data-table-component'

export class AuditTableTemplate extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    noDataMessage: PropTypes.string.isRequired,
    hideColumns: PropTypes.bool.isRequired
  }

  static defaultProps = {
    noDataMessage: '',
    hideColumns: false
  }

  renderNoDataComponent() {
    const { noDataMessage } = this.props
    return <h5 className='no-table-data-message'>{noDataMessage}</h5>
  }

  render() {
    const { columns, data, hideColumns } = this.props
    return (
      <DataTable
        noHeader
        className={hideColumns ? 'no-border' : ''}
        noTableHead={hideColumns}
        noDataComponent={this.renderNoDataComponent()}
        columns={columns}
        data={data}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditTableTemplate)
)
