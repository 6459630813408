import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues } from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import _ from 'lodash'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button
} from 'reactstrap'
import ModalBodyContainer from './rules_engine_modal/modal_body_container'

import './rules_engine_modal.scss'
const CANCEL_BTN_ACTION = 'Cancel'
const SAVE_BTN_ACTION = 'Save Draft'

export class RulesEngineModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    rule: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    onModalStateChange: PropTypes.func.isRequired,
    handleOnRulesChange: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isSaving: false }
  }

  componentDidMount() {
    const { initialize, rule } = this.props
    initialize({ rule })
  }

  componentDidUpdate(prevProps) {
    const { isOpen, initialize, rule } = this.props
    if (isOpen && !prevProps.isOpen) {
      initialize({ rule })
    }
  }

  renderModalHeader() {
    const { rule } = this.props
    const headerText = rule.id ? 'Edit Feed Rules' : 'Add Feed Rules'

    return (
      <ModalHeader>
       <span className='header-text' >{headerText}</span> 
        <Button onClick={() => this.props.onModalStateChange()}>
          <i className='fa fa-times' />
        </Button>
      </ModalHeader>
    )
  }

  renderModalBody() {
    const { formValues } = this.props
    const { rule } = formValues

    return <ModalBodyContainer rule={rule} />
  }

  computeIsSaveDisabled(actionName) {
    const { formValues } = this.props
    return actionName === SAVE_BTN_ACTION ? !validateRule(formValues) : false
  }

  handleActionBtnClick(actionName) {
    const { formValues, onModalStateChange } = this.props
    const { rule } = formValues

    if (actionName === SAVE_BTN_ACTION) {
      const { handleOnRulesChange } = this.props
      this.setState({ isSaving: true })
      handleOnRulesChange(rule)
      this.setState({ isSaving: false })
    }
    onModalStateChange()
  }

  renderActionButton(actionName, btnId, outline, color) {
    const { isSaving } = this.state

    return (
      <Button
        key={actionName}
        id={btnId}
        tag='span'
        color={color}
        outline={outline}
        disabled={this.computeIsSaveDisabled(actionName) || isSaving}
        onClick={this.handleActionBtnClick.bind(this, actionName)}
      >
        {actionName}
      </Button>
    )
  }

  renderFooterActions() {
    return (
      <>
        {this.renderActionButton(
          SAVE_BTN_ACTION,
          'save-and-continue',
          false,
          'primary'
        )}
        {this.renderActionButton(
          CANCEL_BTN_ACTION,
          'cancel-and-continue',
          true,
          'dark'
        )}
      </>
    )
  }

  render() {
    const { isOpen, formValues } = this.props
    const { rule } = formValues
    const actions = !_.isEmpty(rule) ? rule.actions || [] : []

    return (
      <Modal
        size='lg'
        isOpen={isOpen}
        centered={true}
        className='rules-engine-modal'
        toggle={this.handleActionBtnClick.bind(this, CANCEL_BTN_ACTION)}
      >
        {this.renderModalHeader()}
        <ModalBody>{this.renderModalBody()}</ModalBody>
        <ModalFooter>
          <Col className='override-col'>
            {actions.length > 0 ? 'Action(s) that override manual edits.' : ''}
          </Col>
          <Col>{this.renderFooterActions()}</Col>
        </ModalFooter>
      </Modal>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const RulesEngineModalWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesEngineModal)

export const REDUX_FORM_NAME = 'RuleEngineForm'

export const validateRule = (values) => {
  const rule = values.rule || {}
  const { vendors, actions } = rule

  return vendors && vendors.length > 0 && actions && actions.length > 0
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      validate: validateRule,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(RulesEngineModalWithRedux))
  )
)
