import shopifyImage from 'images/ad_champion/shopify.png'
import magento2Image from 'images/ad_champion/magento2.png'
import wooCommerceImage from 'images/ad_champion/woocommerce.png'
import netoImage from 'images/ad_champion/neto.png'
import ecwidImage from 'images/ad_champion/ecwid.png'
import bigCommerceImage from 'images/ad_champion/bigcommerce.png'
import sheetsImage from 'images/ad_champion/sheets.png'
import xmlImage from 'images/ad_champion/xml.png'
import salesforceImage from 'images/ad_champion/salesforce.png'
import marketplacerImage from 'images/ad_champion/marketplacer.png'
import nopCommerceImage from 'images/ad_champion/nopcommerce.png'
import wixImage from 'images/feed_ops/platforms/wix.png'
import googleShoppingFeedImage from 'images/ad_champion/google_shopping_feed.png'
import otherImage from 'images/ad_champion/feedops_custom_xml_feed.png'

const supportedPlatformsManagedServicePlan = {
  shopify_embedded: {
    planId: 'feeds_managed_starter_USD',
    currencyCode: 'USD'
  },
  neto: { planId: 'feeds_managed_starter_USD', currencyCode: 'USD' },
  big_commerce: { planId: 'feeds_managed_starter_USD', currencyCode: 'USD' }
}

const supportedPlatformsHelpDocs = {
  shopify: 'shopify-store-to-feedops',
  magento_v2: 'magento-2-store-to-feedops',
  neto: 'neto-store-to-feedops',
  woo_commerce: 'wooCommerceSelected',
  ecwid: 'ecwid-store-to-feedops',
  big_commerce: 'bigcommerce-store-to-feedops',
  salesforce: 'salesforce-commerce-cloud-store-to-feedops',
  marketplacer: 'connect-a-marketplacer-store-to-feedops',
  wix: 'connect-wix-store-to-feedops',
  google_shopping_feed: 'connect-google-merchant-center-feed-to-feedops',
  other: 'ecommerce-store-to-feedops'
}

export const PLATFORM_GOOGLE_SHEET = 'google_sheet'
export const PLATFORM_GOOGLE_SHOPPING_FEED = 'google_shopping_feed'
export const PLATFORM_CUSTOM_XML = 'xml'
export const PLATFORM_OTHER = 'other'

export const supportedPlatforms = [
  { value: 'shopify', label: 'Shopify', image: shopifyImage },
  { value: 'magento_v2', label: 'Magento v2.x', image: magento2Image },
  { value: 'neto', label: 'Neto', image: netoImage },
  { value: 'woo_commerce', label: 'WooCommerce', image: wooCommerceImage },
  { value: 'ecwid', label: 'Ecwid', image: ecwidImage },
  { value: 'big_commerce', label: 'BigCommerce', image: bigCommerceImage },
  { value: PLATFORM_GOOGLE_SHEET, label: 'Google Sheets', image: sheetsImage },
  { value: 'xml', label: 'Custom Xml', image: xmlImage },
  {
    value: 'salesforce',
    label: 'Salesforce Commerce Cloud',
    image: salesforceImage
  },
  { value: 'marketplacer', label: 'Marketplacer', image: marketplacerImage },
  { value: 'nop_commerce', label: 'NopCommerce', image: nopCommerceImage },
  { value: 'wix', label: 'Wix', image: wixImage },
  {
    value: PLATFORM_GOOGLE_SHOPPING_FEED,
    label: 'GoogleShoppingFeed',
    image: googleShoppingFeedImage
  },
  { value: PLATFORM_OTHER, label: 'Other', image: otherImage }
]

export const getPlatform = (name) => {
  return supportedPlatforms.find((platform) => platform.value === name) || {}
}

export const getPlatformLabel = (name) => {
  return getPlatform(name).label
}

export const getPlatformManagedServicePlan = (name) => {
  return supportedPlatformsManagedServicePlan[name]
}

export const getPlatformHelpDoc = (name) => {
  return supportedPlatformsHelpDocs[name] || 'identify-and-connect'
}
