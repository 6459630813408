import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { fetchIfNeeded } from './util'

import { fetchScenarioStatus } from './scenario'

export const FETCH_WEBSITE_DETAILS_REQUEST = 'FETCH_WEBSITE_DETAILS_REQUEST'
export const FETCH_WEBSITE_DETAILS_SUCCESS = 'FETCH_WEBSITE_DETAILS_SUCCESS'
export const UPDATE_WEBSITE_DETAILS_REQUEST = 'UPDATE_WEBSITE_DETAILS_REQUEST'
export const UPDATE_WEBSITE_DETAILS_SUCCESS = 'UPDATE_WEBSITE_DETAILS_SUCCESS'

const requestWebsiteDetails = (id, forScenario) => ({
  type: FETCH_WEBSITE_DETAILS_REQUEST,
  idType: forScenario ? 'scenario' : 'website',
  id
})

const receiveWebsiteDetails = (id, json, forScenario) => ({
  type: FETCH_WEBSITE_DETAILS_SUCCESS,
  idType: forScenario ? 'scenario' : 'website',
  id,
  websiteDetails: json,
  receivedAt: Date.now()
})

export const fetchWebsiteDetails =
  (id, forScenario = true) =>
  (dispatch) => {
    var details = requestWebsiteDetails(id, forScenario)
    dispatch(details)

    return $.ajax({
      method: 'GET',
      url: `/ad_champion/${
        forScenario ? 'scenarios' : 'websites'
      }/${id}/website_detail/edit.json`,
      dataType: 'json',
      success: (response) => {
        dispatch(
          receiveWebsiteDetails(
            id,
            camelCaseKeys(
              forScenario ? response.campaign_order : response.website,
              {
                deep: true
              }
            ),
            forScenario
          )
        )
      }
    })
  }

const shouldFetchWebsiteDetails = (state, scenarioId) => {
  return state.websiteDetails.website.url === ''
}

export const fetchWebsiteDetailsIfNeeded =
  (scenarioId) => (dispatch, getState) => {
    return fetchIfNeeded(
      scenarioId,
      shouldFetchWebsiteDetails,
      fetchWebsiteDetails,
      dispatch,
      getState
    )
  }

const saveWebsiteDetailsRequest = (id, forScenario) => ({
  type: UPDATE_WEBSITE_DETAILS_REQUEST,
  idType: forScenario ? 'scenario' : 'website',
  id
})

const saveWebsiteDetailsSuccess = (id, websiteDetails, forScenario) => ({
  type: UPDATE_WEBSITE_DETAILS_SUCCESS,
  idType: forScenario ? 'scenario' : 'website',
  id,
  websiteDetails
})

export const saveWebsiteDetails =
  (id, details, forScenario = true) =>
  (dispatch) => {
    dispatch(saveWebsiteDetailsRequest(id, forScenario))

    const payload = forScenario
      ? { campaignOrder: details }
      : { website: details }

    var snakeCasePayload = snakeCaseKeys(payload, { deep: true })
    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/${
        forScenario ? 'scenarios' : 'websites'
      }/${id}/website_detail`,
      contentType: 'application/json',
      data: JSON.stringify(snakeCasePayload),
      dataType: 'json',
      success: () => {
        if (forScenario) {
          dispatch(fetchScenarioStatus(id))
        }
        return dispatch(saveWebsiteDetailsSuccess(id, details, forScenario))
      }
    })
  }
