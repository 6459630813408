import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { removeMembership, removeInvitation } from 'actions/users'
import DeleteModal from 'components/feed_ops/common/delete_modal'
import { currentUserSelector } from 'selectors/user'

export class DeleteUserModal extends Component {
  static propTypes = {
    deleteModalOpen: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    handleDeleteModalClose: PropTypes.func.isRequired,
    removeMembership: PropTypes.func.isRequired,
    removeInvitation: PropTypes.func.isRequired
  }

  computeUserNameOrEmail() {
    const { rowData } = this.props
    const { name, workEmail } = rowData
    return name || workEmail
  }

  handleDeleteModalClose() {
    const { handleDeleteModalClose } = this.props
    handleDeleteModalClose()
  }

  async handleDeleteUserClick() {
    const { removeMembership, accountId, currentUser, rowData } = this.props
    const { id, lastLoggedIn } = rowData

    if (!lastLoggedIn.includes('Invitation')) {
      await removeMembership(id, accountId)

      if (currentUser.id === id) {
        this.props.history.push('/feed_ops')
      }
    } else {
      const { removeInvitation } = this.props
      await removeInvitation(id, id)
    }

    this.handleDeleteModalClose()
  }

  render() {
    const { deleteModalOpen } = this.props

    return (
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        modalTitle='Delete User'
        modalMessage={`delete ${this.computeUserNameOrEmail()}`}
        handleDeleteModalClose={this.handleDeleteModalClose.bind(this)}
        handleDeleteClick={this.handleDeleteUserClick.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  currentUser: currentUserSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeMembership, removeInvitation }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal)
)
