import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import {
  FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  FETCH_ADVERTISING_BUDGETS_REQUEST,
  FETCH_ADVERTISING_BUDGETS_SUCCESS,
  CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS
} from 'actions/advertising'

const fetchMasterAccountAdvertisingBudgetsRequest = (
  accountType,
  masterAccountId,
  channel
) => ({
  type: FETCH_ADVERTISING_BUDGETS_REQUEST,
  accountType,
  id: masterAccountId,
  channel
})

const fetchMasterAccountAdvertisingBudgetsSuccess = (
  accountType,
  masterAccountId,
  channel,
  advertisingBudgets
) => ({
  type: FETCH_ADVERTISING_BUDGETS_SUCCESS,
  accountType,
  id: masterAccountId,
  channel,
  ...advertisingBudgets
})

export const fetchMasterAccountAdvertisingBudgets = (
  accountType,
  masterAccountId,
  channel
) => async (dispatch) => {
  dispatch(
    fetchMasterAccountAdvertisingBudgetsRequest(
      accountType,
      masterAccountId,
      channel
    )
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/master_accounts/${masterAccountId}/advertising/load_budgets`,
    data: snakeCaseKeys({ channel }, { deep: true }),
    dataType: 'json'
  })

  dispatch(
    fetchMasterAccountAdvertisingBudgetsSuccess(
      accountType,
      masterAccountId,
      channel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchMasterAccountAdvertisingGoalTypeRequest = (
  accountType,
  masterAccountId,
  channel
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  accountType,
  id: masterAccountId,
  channel
})

const fetchMasterAccountAdvertisingGoalTypeSuccess = (
  accountType,
  masterAccountId,
  channel,
  advertisingGoalType
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  accountType,
  id: masterAccountId,
  channel,
  advertisingGoalType
})

export const fetchMasterAccountAdvertisingGoalType = (
  accountType,
  masterAccountId,
  channel
) => async (dispatch) => {
  dispatch(
    fetchMasterAccountAdvertisingGoalTypeRequest(
      accountType,
      masterAccountId,
      channel
    )
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/master_accounts/${masterAccountId}/advertising/load_goal_type`,
    data: snakeCaseKeys({ channel }, { deep: true }),
    dataType: 'json'
  })

  dispatch(
    fetchMasterAccountAdvertisingGoalTypeSuccess(
      accountType,
      masterAccountId,
      channel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const checkMasterAccountPublicationAccountTypesRequest = (
  accountType,
  masterAccountId
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  accountType,
  id: masterAccountId
})

const checkMasterAccountPublicationAccountTypesSuccess = (
  accountType,
  masterAccountId,
  publicationAccountTypes
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS,
  accountType,
  id: masterAccountId,
  ...publicationAccountTypes
})

export const checkMasterAccountPublicationAccountTypes = (
  accountType,
  masterAccountId
) => async (dispatch) => {
  dispatch(
    checkMasterAccountPublicationAccountTypesRequest(
      accountType,
      masterAccountId
    )
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/master_accounts/${masterAccountId}/advertising/load_goal_type`,
    dataType: 'json'
  })

  dispatch(
    checkMasterAccountPublicationAccountTypesSuccess(
      accountType,
      masterAccountId,
      camelCaseKeys(response, { deep: true })
    )
  )
}
