import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { settingsValueSelector } from 'selectors/settings'
import {
  trackEvent,
  FIRST_SIGN_IN,
  USER_SIGN_IN,
  USER_PROFILE_COMPLETED,
  USER_IDENTIFIED
} from 'util/tag_manager'
import { fetchFullWebsiteList } from 'actions/websites'
import { profileCompleteSelector } from 'selectors/user'

export const INVITATION_REQUEST = 'INVITATION_REQUEST'
export const INVITATION_RESPONSE = 'INVITATION_RESPONSE'
export const INVITATION_ERROR = 'INVITATION_ERROR'
export const USER_MASTER_ACCOUNT_LIST_REQUEST =
  'USER_MASTER_ACCOUNT_LIST_REQUEST'
export const USER_MASTER_ACCOUNT_LIST_RESPONSE =
  'USER_MASTER_ACCOUNT_LIST_RESPONSE'
export const SELECT_MASTER_ACCOUNT = 'SELECT_MASTER_ACCOUNT'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE'
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE'
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST'
export const LOGOUT_USER_RESPONSE = 'LOGOUT_USER_RESPONSE'
export const ADD_MEMBERSHIP_REQUEST = 'ADD_MEMBERSHIP_REQUEST'
export const ADD_MEMBERSHIP_RESPONSE = 'ADD_MEMBERSHIP_RESPONSE'
export const REMOVE_MEMBERSHIP_REQUEST = 'REMOVE_MEMBERSHIP_REQUEST'
export const REMOVE_MEMBERSHIP_RESPONSE = 'REMOVE_MEMBERSHIP_RESPONSE'
export const REMOVE_INVITAION_REQUEST = 'REMOVE_INVITAION_REQUEST'
export const REMOVE_INVITAION_RESPONSE = 'REMOVE_INVITAION_RESPONSE'

const invitationRequest = () => ({
  type: INVITATION_REQUEST
})

const invitationResponse = (response) => ({
  type: INVITATION_RESPONSE
})

const invitationError = (error) => ({
  type: INVITATION_ERROR,
  error: error.responseJson['error']
})

const requestUserMasterAccounts = () => ({
  type: USER_MASTER_ACCOUNT_LIST_REQUEST
})

const receiveUserMasterAccounts = (response) => ({
  type: USER_MASTER_ACCOUNT_LIST_RESPONSE,
  currentUser: response['user']
})

export const sendInvitation =
  (email, ownerType, ownerId, send) => (dispatch) => {
    dispatch(invitationRequest())
    var payload = {
      user: {
        email,
        memberships_attributes: JSON.stringify([
          { owner_id: ownerId, owner_type: ownerType }
        ]),
        send_invite: send
      }
    }
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/users`,
      dataType: 'json',
      data: payload,
      success: (response) => {
        return dispatch(
          invitationResponse(camelCaseKeys(response, { deep: true }))
        )
      },
      error: (response) => {
        return dispatch(
          invitationError(camelCaseKeys(response, { deep: true }))
        )
      }
    })
  }

export const fetchUserMasterAccounts = () => async (dispatch) => {
  dispatch(requestUserMasterAccounts())
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/users`,
    dataType: 'json'
  })

  const payload = camelCaseKeys(response, { deep: true })

  var { id, email, profile } = payload.user
  if (profile.workEmail) {
    email = profile.workEmail
  }
  trackEvent(USER_IDENTIFIED, { id, email })
  dispatch(receiveUserMasterAccounts(payload))
  return payload
}

export const selectMasterAccount = (masterAccountId) => ({
  type: SELECT_MASTER_ACCOUNT,
  masterAccountId
})

const updateUserRequest = (user) => ({
  type: UPDATE_USER_REQUEST,
  user
})

const updateUserResponse = (user) => ({
  type: UPDATE_USER_RESPONSE,
  user,
  newUserFirstTimeSignIn: false
})

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch(updateUserRequest(user))
  const state = getState()
  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/users/${user.id}`,
    dataType: 'json',
    data: {
      user: snakeCaseKeys(
        {
          ...user,
          profile_attributes: user.profile,
          coupons: settingsValueSelector(state, 'coupon')
        },
        { deep: true }
      )
    }
  })
  response = camelCaseKeys(response, { deep: true })
  if (response.firstComplete) {
    trackEvent(USER_PROFILE_COMPLETED)
  }
  dispatch(updateUserResponse(user), response)
  return response
}

const loginUserRequest = () => ({ type: LOGIN_USER_REQUEST })
const loginUserResponse = (response) => ({
  type: LOGIN_USER_RESPONSE,
  ...response
})

export const loginUser =
  (idToken, appId, profilePicture, platform = 'google') =>
  async (dispatch, getState) => {
    dispatch(loginUserRequest())
    const state = getState()
    var response = await $.ajax({
      url: '/ad_champion/users/login',
      method: 'POST',
      data: {
        id_token: idToken,
        app_id: appId,
        platform,
        image_url: profilePicture,
        invitation_token: settingsValueSelector(state, 'invitationToken')
      },
      dataType: 'json'
    })

    response = camelCaseKeys(response, { deep: true })

    if (response.invitationExpired) {
      toastr.error(
        'Your invitation has expired. Please contact the person who invited you to resend the invitation.'
      )
      throw new Error('invitation_expired')
    }

    if (response.newUserFirstTimeSignIn) {
      trackEvent(FIRST_SIGN_IN)
    } else {
      trackEvent(USER_SIGN_IN)
    }

    if (profileCompleteSelector(state)) {
      await fetchFullWebsiteList()(dispatch)
    }
    await fetchUserMasterAccounts()(dispatch)

    dispatch(loginUserResponse(response))
    return response
  }

const addMembershipRequest = (emailAddress, invitationToken) => ({
  type: ADD_MEMBERSHIP_REQUEST,
  emailAddress,
  invitationToken
})

const addMembershipResponse = (emailAddress, invitationToken, response) => ({
  type: ADD_MEMBERSHIP_RESPONSE,
  emailAddress,
  invitationToken,
  ...response
})

export const addMembership =
  (emailAddress, invitationToken) => async (dispatch, getState) => {
    dispatch(addMembershipRequest(emailAddress, invitationToken))
    var response = await $.ajax({
      url: '/ad_champion/users/add_membership',
      method: 'POST',
      data: {
        email: emailAddress,
        invitation_token: invitationToken
      },
      dataType: 'json'
    })

    response = camelCaseKeys(response, { deep: true })

    if (response.invitationExpired) {
      toastr.error(
        'Your invitation has expired. Please contact the person who invited you to resend the invitation.'
      )
      throw new Error('invitation_expired')
    }

    await fetchFullWebsiteList()(dispatch)
    await fetchUserMasterAccounts()(dispatch)

    dispatch(addMembershipResponse(emailAddress, invitationToken, response))
    return response
  }

const logoutUserRequest = () => ({ type: LOGOUT_USER_REQUEST })
const logoutUserResponse = () => ({ type: LOGOUT_USER_RESPONSE })
export const logoutUser = () => async (dispatch) => {
  dispatch(logoutUserRequest())
  try {
    await $.ajax({ url: '/ad_champion/users/logout', method: 'POST' })
  } catch (ex) {
    if (ex.status !== 422) {
      throw ex
    }
  }

  await fetchUserMasterAccounts()(dispatch)

  dispatch(logoutUserResponse())
}

const removeMembershipRequest = (userId, accountId) => ({
  type: REMOVE_MEMBERSHIP_REQUEST,
  userId,
  accountId
})

const removeMembershipResponse = (userId, accountId, response) => ({
  type: REMOVE_MEMBERSHIP_RESPONSE,
  userId,
  accountId,
  ...response
})

export const removeMembership = (userId, accountId) => async (dispatch) => {
  dispatch(removeMembershipRequest(userId, accountId))
  var response = await $.ajax({
    url: `/ad_champion/users/${userId}/remove_membership?account_id=${accountId}`,
    method: 'GET',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(removeMembershipResponse(userId, accountId, response))
  return response
}

const removeInvitationRequest = (userId, invitationId) => ({
  type: REMOVE_INVITAION_REQUEST,
  userId,
  invitationId
})

const removeInvitationResponse = (userId, invitationId, response) => ({
  type: REMOVE_INVITAION_RESPONSE,
  userId,
  invitationId,
  ...response
})

export const removeInvitation = (userId, invitationId) => async (dispatch) => {
  dispatch(removeInvitationRequest(userId, invitationId))
  var response = await $.ajax({
    url: `/ad_champion/users/${userId}/remove_invitation?invitation_id=${invitationId}`,
    method: 'GET',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(removeInvitationResponse(userId, invitationId, response))
  return response
}
