import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBListGroup, MDBListGroupItem, MDBInput } from 'mdbreact'
import { BOOLEAN_OPERATORS } from '../filter_operations'

export default class EnumListItem extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    filterValues: PropTypes.string.isRequired,
    singleSelectionOnly: PropTypes.bool.isRequired,
    enumValueChanged: PropTypes.func.isRequired
  }

  static defaultProps = {
    singleSelectionOnly: false
  }

  concatSelectionValues(filterValues, value) {
    if (
      filterValues.length > 0 &&
      filterValues.charAt(filterValues.length - 1) !== '\n'
    ) {
      return filterValues + `\n${value}\n`
    } else {
      return filterValues + `${value}\n`
    }
  }

  computeSelectionValues(value) {
    const { attribute, filterValues } = this.props
    var filterValuesArray = _.compact(filterValues.split('\n'))
    var newFilterValues = ''

    if (_.isEqual(attribute.filterOptions, BOOLEAN_OPERATORS)) {
      newFilterValues = value
    } else if (filterValuesArray.includes(value)) {
      const valueIndex = filterValuesArray.indexOf(value)
      filterValuesArray.splice(valueIndex, 1)
      newFilterValues =
        filterValuesArray.length > 0 ? `${filterValuesArray.join('\n')}\n` : ''
    } else {
      newFilterValues = this.concatSelectionValues(filterValues, value)
    }

    return newFilterValues
  }

  handleEnumValueClick(value) {
    const { singleSelectionOnly, enumValueChanged } = this.props
    const newFilterValues = singleSelectionOnly
      ? value
      : this.computeSelectionValues(value)

    enumValueChanged(newFilterValues)
  }

  render() {
    const { attribute, filterValues } = this.props
    const attributeValues = attribute.enumValues.map((value) => (
      <MDBListGroupItem
        key={value}
        hover
        onClick={this.handleEnumValueClick.bind(this, value)}
      >
        <MDBInput
          type='checkbox'
          label={value}
          checked={filterValues.split('\n').includes(value)}
        />
      </MDBListGroupItem>
    ))

    return <MDBListGroup>{attributeValues}</MDBListGroup>
  }
}
