import camelCaseKeys from 'camelcase-keys'
import sleep from 'util/sleep'

export const GEN_PRODUCT_DATA_AUDIT_REQUEST = 'GEN_PRODUCT_DATA_AUDIT_REQUEST'
export const GEN_PRODUCT_DATA_AUDIT_RESPONSE = 'GEN_PRODUCT_DATA_AUDIT_RESPONSE'
export const FETCH_PRODUCT_DATA_AUDITS_REQUEST =
  'FETCH_PRODUCT_DATA_AUDITS_REQUEST'
export const FETCH_PRODUCT_DATA_AUDITS_RESPONSE =
  'FETCH_PRODUCT_DATA_AUDITS_RESPONSE'
export const SHOW_HIDE_ALL_AUDITS_MODAL = 'SHOW_HIDE_ALL_AUDITS_MODAL'
export const UPDATE_AUDIT_SCHEDULE_REQUEST = 'UPDATE_AUDIT_SCHEDULE_REQUEST'
export const UPDATE_AUDIT_SCHEDULE_RESPONSE = 'UPDATE_AUDIT_SCHEDULE_RESPONSE'

const genProductDataAuditRequest = (inventoryId) => ({
  type: GEN_PRODUCT_DATA_AUDIT_REQUEST,
  inventoryId
})

const genProductDataAuditResponse = (inventoryId, response) => ({
  type: GEN_PRODUCT_DATA_AUDIT_RESPONSE,
  inventoryId,
  ...response
})

export const genProductDataAudit =
  (inventoryId, autoGenerated = false) =>
  async (dispatch) => {
    dispatch(genProductDataAuditRequest(inventoryId))

    var status = 202
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }
    const url = `/ad_champion/inventories/${inventoryId}/product_data_audits/gen_audit?auto_generated=${autoGenerated}`
    var response = await $.ajax({ method: 'GET', url, statusCode })
    var camelResponse = null

    while (status === 202) {
      camelResponse = camelCaseKeys(response, { deep: true })
      dispatch(genProductDataAuditResponse(inventoryId, camelResponse))
      await sleep(3000)
      response = await $.ajax({ method: 'GET', url, statusCode })
    }

    camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(genProductDataAuditResponse(inventoryId, camelResponse))

    return camelResponse.inventoryAuditUrl
  }

const fetchProductDataAuditsRequest = (inventoryId) => ({
  type: FETCH_PRODUCT_DATA_AUDITS_REQUEST,
  inventoryId
})

const fetchProductDataAuditsResponse = (inventoryId, response) => ({
  type: FETCH_PRODUCT_DATA_AUDITS_RESPONSE,
  inventoryId,
  ...response
})

export const fetchProductDataAudits = (inventoryId) => async (dispatch) => {
  dispatch(fetchProductDataAuditsRequest(inventoryId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/inventories/${inventoryId}/product_data_audits`,
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(fetchProductDataAuditsResponse(inventoryId, camelResponse))

  return camelResponse.inventoryAudits
}

const updateAuditScheduleRequest = (inventoryId) => ({
  type: UPDATE_AUDIT_SCHEDULE_REQUEST,
  inventoryId
})

const updateAuditScheduleResponse = (inventoryId, response) => ({
  type: UPDATE_AUDIT_SCHEDULE_RESPONSE,
  inventoryId,
  ...response
})

export const updateAuditSchedule =
  (inventoryId, auditSchedule) => async (dispatch) => {
    dispatch(updateAuditScheduleRequest(inventoryId))

    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/inventories/${inventoryId}/product_data_audits/update_audit_schedule?audit_schedule=${auditSchedule}`,
      dataType: 'json'
    })

    var camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(updateAuditScheduleResponse(inventoryId, camelResponse))

    return camelResponse.scheduleMessage
  }

export const showHideAllAuditsModal = (show) => ({
  type: SHOW_HIDE_ALL_AUDITS_MODAL,
  show
})
