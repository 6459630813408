import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBSwitch } from 'mdbreact'
import ReactTooltip from 'react-tooltip'
import GoogleProductCategorySelect from 'components/feed_ops/common/google_product_category_select'

export default class GoogleProductCategoryRow extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    googleTaxonomyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleGoogleTaxonomyChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      overridden: false,
      defaultOption: null
    }
  }

  componentDidMount() {
    this.fetchDefaultValue()
  }

  componentDidUpdate(prevProps) {
    const { category } = this.props
    if (!_.isEqual(prevProps.category, category)) {
      this.fetchDefaultValue()
    }
  }

  validateValuesPresent(category, googleTaxonomyOptions) {
    return (
      category &&
      Object.keys(category).length > 0 &&
      googleTaxonomyOptions &&
      googleTaxonomyOptions.length > 0
    )
  }

  fetchDefaultValue() {
    const { category, googleTaxonomyOptions } = this.props
    var defaultValue = null
    var overridden = false
    if (
      this.validateValuesPresent(category, googleTaxonomyOptions) &&
      category.taxonomyId
    ) {
      defaultValue = googleTaxonomyOptions.find(
        (option) => option.value === category.taxonomyId.toString()
      )
      if (defaultValue) {
        overridden = true
      }
    }

    this.setState({ defaultOption: defaultValue, overridden })
  }

  handleTaxonomySelectionChange(selectedOptions) {
    const selectedOption = selectedOptions[0]
    const { handleGoogleTaxonomyChange } = this.props
    const currentValue = selectedOption ? parseInt(selectedOption.value) : null
    handleGoogleTaxonomyChange(currentValue)
    this.setState({
      defaultOption: selectedOption
    })
  }

  renderSelect() {
    const { isDisabled, googleTaxonomyOptions } = this.props
    const { defaultOption } = this.state

    return (
      <GoogleProductCategorySelect
        selectedValue={defaultOption}
        googleTaxonomyOptions={googleTaxonomyOptions}
        isSelectDisabled={isDisabled}
        valueChanged={this.handleTaxonomySelectionChange.bind(this)}
      />
    )
  }

  handleChange() {
    const { handleGoogleTaxonomyChange } = this.props
    const { overridden, initialOption } = this.state
    const option = overridden ? null : initialOption
    this.setState({ overridden: !overridden })
    if (overridden || option !== null) {
      const value = option ? parseInt(option.value) : null
      handleGoogleTaxonomyChange(value)
    }
  }

  renderSwitchAndSelect() {
    const { isDisabled } = this.props
    const { overridden } = this.state

    return (
      <>
        <MDBSwitch
          labelLeft='Automatic'
          labelRight='Overridden'
          disabled={isDisabled}
          checked={overridden}
          onChange={this.handleChange.bind(this)}
        />
        {overridden && this.renderSelect()}
      </>
    )
  }

  render() {
    const { category, googleTaxonomyOptions, isDisabled } = this.props
    return this.validateValuesPresent(category, googleTaxonomyOptions) ? (
      <MDBRow className='taxonomy-selector-row'>
        <MDBCol className='label-column'>Google Product Category:</MDBCol>
        <MDBCol className='input-column'>
          <span data-tip='To edit this field,<br/>the category must be<br/>Enabled for Advertising.'>
            {this.renderSwitchAndSelect()}
          </span>
          {isDisabled && <ReactTooltip multiline={true} effect='solid' />}
        </MDBCol>
      </MDBRow>
    ) : null
  }
}
