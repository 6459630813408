import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { Container, Row, Col, Input } from 'reactstrap'
import ChannelMultiSelect from '../common/channel_multi_select'
import ConditionsContainer from './conditions_container'
import ActionsContainer from './actions_container'
import { RULE_CONDTIONS_NAME, RULE_ACTIONS_NAME } from './rules_engine_util'
import { enabledVendorsSelector } from 'selectors/inventories/vendors'
import { REDUX_FORM_NAME } from '../rules_engine_modal'

import './modal_body_container.scss'

export class ModalBodyContainer extends Component {
  static propTypes = {
    rule: PropTypes.object.isRequired,
    vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
    change: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.initializeChannels()
  }

  initializeChannels() {
    const { vendors, rule } = this.props
    this.handleChannelSelectorChange(
      (rule.vendors || vendors).map((v) => v.channel)
    )
  }

  renderHeader(headerText) {
    return <h5>{headerText}</h5>
  }

  renderDescriptionInput() {
    const { rule, change } = this.props
    const { description } = rule

    return (
      <>
       <span className='description-title' >{this.renderHeader('Description')}</span> 
        <Input
          rows='3'
          type='textarea'
          className='description-textarea'
          placeholder='Enter Description'
          value={description || ''}
          onChange={(event) =>
            change(REDUX_FORM_NAME, 'rule.description', event.target.value)
          }
        />
      </>
    )
  }

  handleChannelSelectorChange(channels) {
    const { change, vendors } = this.props
    change(
      REDUX_FORM_NAME,
      'rule.vendors',
      vendors.filter((v) => channels.includes(v.channel))
    )
  }

  renderChannelsCol() {
    const { vendors, rule } = this.props

    return (
      <Col>
        <ChannelMultiSelect
          channels={vendors.map((v) => v.channel)}
          defaultChannels={(rule.vendors || vendors).map((v) => v.channel)}
          onChange={this.handleChannelSelectorChange.bind(this)}
        />
      </Col>
    )
  }

  renderDescriptionCol() {
    return <Col>{this.renderDescriptionInput()}</Col>
  }

  handleConditionsChanged(conditions) {
    const { change } = this.props
    change(REDUX_FORM_NAME, 'rule.conditions', conditions)
  }

  handleActionsChanged(actions) {
    const { change } = this.props
    change(REDUX_FORM_NAME, 'rule.actions', actions)
  }

  renderConditionsAndActionsCol() {
    const { rule } = this.props
    const { conditions, actions } = rule

    return (
      <>
        <Col className="handel-condition" >

       
          {this.renderHeader(RULE_CONDTIONS_NAME)}
          <ConditionsContainer
            conditions={conditions || []}
            handleConditionsChange={this.handleConditionsChanged.bind(this)}
          />


        </Col>
        <Col  className="handel-condition" >
          {this.renderHeader(RULE_ACTIONS_NAME)}
          <ActionsContainer
            actions={actions || []}
            handleActionsChange={this.handleActionsChanged.bind(this)}
          />
        </Col>
      </>
    )
  }

  render() {
    return (
      <Container className='rule-modal-body-container'>
        <Row>{this.renderChannelsCol()}</Row>
        <Row>{this.renderDescriptionCol()}</Row>
        <Row>{this.renderConditionsAndActionsCol()}</Row>
      </Container>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  vendors: enabledVendorsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalBodyContainer)
)
