import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_WEBSITE_BUDGET_REQUEST = 'FETCH_WEBSITE_BUDGET_REQUEST'
export const FETCH_WEBSITE_BUDGET_RESPONSE = 'FETCH_WEBSITE_BUDGET_RESPONSE'
export const CREATE_WEBSITE_BUDGET_REQUEST = 'CREATE_WEBSITE_BUDGET_REQUEST'
export const CREATE_WEBSITE_BUDGET_RESPONSE = 'CREATE_WEBSITE_BUDGET_RESPONSE'
export const UPDATE_WEBSITE_BUDGET_REQUEST = 'UPDATE_WEBSITE_BUDGET_REQUEST'
export const UPDATE_WEBSITE_BUDGET_RESPONSE = 'UPDATE_WEBSITE_BUDGET_RESPONSE'

const fetchWebsiteBudgetRequest = websiteId => ({
  type: FETCH_WEBSITE_BUDGET_REQUEST,
  websiteId
})

const fetchWebsiteBudgetResponse = (websiteId, response) => ({
  type: FETCH_WEBSITE_BUDGET_RESPONSE,
  budget: response,
  websiteId
})

export const fetchWebsiteBudget = websiteId => dispatch => {
  dispatch(fetchWebsiteBudgetRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/budget`,
    dataType: 'json',
    success: response => {
      return dispatch(
        fetchWebsiteBudgetResponse(websiteId, camelCaseKeys(response))
      )
    }
  })
}

const createWebsiteBudgetRequest = websiteId => ({
  type: CREATE_WEBSITE_BUDGET_REQUEST,
  websiteId
})

const createWebsiteBudgetResponse = websiteId => ({
  type: CREATE_WEBSITE_BUDGET_RESPONSE,
  websiteId
})

export const createWebsiteBudget = (websiteId, payload) => dispatch => {
  dispatch(createWebsiteBudgetRequest(websiteId))
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/websites/${websiteId}/budget`,
    dataType: 'json',
    data: snakeCaseKeys({ budget: payload }),
    success: response => {
      return dispatch(createWebsiteBudgetResponse(websiteId))
    }
  })
}

const updateWebsiteBudgetRequest = websiteId => ({
  type: UPDATE_WEBSITE_BUDGET_REQUEST,
  websiteId
})

const updateWebsiteBudgetResponse = websiteId => ({
  type: UPDATE_WEBSITE_BUDGET_RESPONSE,
  websiteId
})

export const updateWebsiteBudget = (websiteId, payload, id) => dispatch => {
  dispatch(updateWebsiteBudgetRequest(websiteId))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/websites/${websiteId}/budget/${id}`,
    dataType: 'json',
    data: snakeCaseKeys({ budget: payload }),
    success: response => {
      return dispatch(updateWebsiteBudgetResponse(websiteId))
    }
  })
}