import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { WEBSITE_SETTINGS, ACCOUNT_SETTINGS } from '../menu_items'
import { MDBSideNav, MDBSideNavNav, MDBSideNavCat } from 'mdbreact'
import WebsitesMenu from 'components/ad_champion/common/websites_menu'
import SideNavLink from './container/side_nav_link'
import { appIdSelector, isEmbeddedAppSelector } from 'selectors/app'
import { superUserSelector } from 'selectors/user'

export class SideNavContainer extends Component {
  static propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    sideNavOpen: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    isSuperUser: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      websiteSettingsOpen: false,
      accountSettingsOpen: false
    }
  }

  handleWebsiteMenuClick(id, type) {
    const { appId, history } = this.props
    history.push(`/${appId}${type !== 'websites' ? `/${type}` : ''}/${id}/`, {})
  }

  renderWebsiteMenu() {
    const { location } = this.props
    const { pathname } = location

    return (
      <WebsitesMenu
        showGenericToggleName={!pathname.includes('accounts')}
        canSelectMasterAccount={false}
        canSelectAccount={false}
        onClick={this.handleWebsiteMenuClick.bind(this)}
      />
    )
  }

  isAccountSettingPages() {
    const { location } = this.props
    const { pathname } = location

    return pathname.includes('accounts')
  }

  renderSideNavLinks(menuItems) {
    var navLinks = []
    menuItems.forEach((menuItem) => {
      const { title, path } = menuItem
      navLinks.push(<SideNavLink key={path || title} menuItem={menuItem} />)
    })

    return navLinks
  }

  renderSideNavHeader() {
    const { menuItems } = this.props
    const headerMenuItems = menuItems.filter((menuItem) => menuItem.header)

    return (
      <MDBSideNavCat className='side-nav-header'>
        {this.renderSideNavLinks(headerMenuItems)}
      </MDBSideNavCat>
    )
  }

  getSubMenuItems(parentMenuItemTitle) {
    const { menuItems } = this.props
    const menuItem = menuItems.find(
      (menuItem) => menuItem.title === parentMenuItemTitle
    )
    return menuItem ? menuItem.subMenuItems : []
  }

  renderWebsiteOrAccountSettingsSideNavCat(
    id,
    name,
    settingsState,
    onClickHandler
  ) {
    const menuItems = this.getSubMenuItems(name)

    return (
      <MDBSideNavCat
        id={id}
        name={name}
        className={`side-nav-${name.toLowerCase().replace(' ', '-')}`}
        isOpen={settingsState}
        onClick={() => {
          onClickHandler()
        }}
      >
        {this.renderSideNavLinks(menuItems)}
      </MDBSideNavCat>
    )
  }

  renderSideNavWebsiteSettings() {
    const { websiteSettingsOpen } = this.state

    return this.renderWebsiteOrAccountSettingsSideNavCat(
      'websiteSettingsCategory',
      WEBSITE_SETTINGS,
      websiteSettingsOpen,
      () =>
        this.setState({
          websiteSettingsOpen: !websiteSettingsOpen,
          accountSettingsOpen: false
        })
    )
  }

  renderSideNavAccountSettings() {
    const { accountSettingsOpen } = this.state

    return this.renderWebsiteOrAccountSettingsSideNavCat(
      'accountSettingsCategory',
      ACCOUNT_SETTINGS,
      accountSettingsOpen,
      () =>
        this.setState({
          accountSettingsOpen: !accountSettingsOpen,
          websiteSettingsOpen: false
        })
    )
  }

  renderSideNavFooter() {
    const { menuItems } = this.props
    const footerMenuItems = menuItems.filter((menuItem) => menuItem.footer)
    return (
      <MDBSideNavCat className='side-nav-footer'>
        {this.renderSideNavLinks(footerMenuItems)}
      </MDBSideNavCat>
    )
  }

  renderSideNavNavContent() {
    return (
      <>
        {!this.isAccountSettingPages() && this.renderSideNavHeader()}
        <div className='bottom-nav-container'>
          {!this.isAccountSettingPages() && this.renderSideNavWebsiteSettings()}
          {this.renderSideNavAccountSettings()}
          {this.renderSideNavFooter()}
        </div>
      </>
    )
  }

  render() {
    const { sideNavOpen } = this.props

    return (
      <MDBSideNav
        triggerOpening={sideNavOpen}
        className={`side-nav side-nav-light desktop`}
        showOverlay={false}
        responsive={true}
      >
        {this.renderWebsiteMenu()}
        <MDBSideNavNav>{this.renderSideNavNavContent()}</MDBSideNavNav>
      </MDBSideNav>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    sideNavOpen: state.app.sideNavOpen || false,
    appId: appIdSelector(state, props),
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    isSuperUser: superUserSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const SideNavContainerWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavContainer)

export default withRouter(SideNavContainerWithRedux)
