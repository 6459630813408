import camelCaseKeys from 'camelcase-keys'

export const FETCH_WEBSITE_PRIMARY_FEEDS_REQUEST =
  'FETCH_WEBSITE_PRIMARY_FEEDS_REQUEST'
export const FETCH_WEBSITE_PRIMARY_FEEDS_RESPONSE =
  'FETCH_WEBSITE_PRIMARY_FEEDS_RESPONSE'
export const FETCH_WEBSITE_MERCHANT_CENTER_STATUS_REQUEST =
  'FETCH_WEBSITE_MERCHANT_CENTER_STATUS_REQUEST'
export const FETCH_WEBSITE_MERCHANT_CENTER_STATUS_SUCCESS =
  'FETCH_WEBSITE_MERCHANT_CENTER_STATUS_SUCCESS'
export const SET_WATCHING_REQUEST = 'SET_WATCHING_REQUEST'
export const SET_WATCHING_RESPONSE = 'SET_WATCHING_RESPONSE'

const fetchWebsitePrimaryFeedsRequest = (websiteId) => ({
  type: FETCH_WEBSITE_PRIMARY_FEEDS_REQUEST,
  websiteId
})

const fetchWebsitePrimaryFeedsResponse = (websiteId, response) => ({
  type: FETCH_WEBSITE_PRIMARY_FEEDS_RESPONSE,
  primaryFeeds: response.primaryFeeds,
  websiteId
})

export const fetchWebsitePrimaryFeeds = (websiteId) => (dispatch) => {
  dispatch(fetchWebsitePrimaryFeedsRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/status/load_primary_feeds`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchWebsitePrimaryFeedsResponse(websiteId, camelCaseKeys(response))
      )
    }
  })
}

const fetchWebsiteMerchantCenterStatusRequest = (websiteId) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_STATUS_REQUEST,
  websiteId
})

const fetchWebsiteMerchantCenterStatusSuccess = (
  websiteId,
  websiteMerchantCenterStatus
) => ({
  type: FETCH_WEBSITE_MERCHANT_CENTER_STATUS_SUCCESS,
  websiteId,
  websiteMerchantCenterStatus: websiteMerchantCenterStatus.merchantCenterStatus
})

export const fetchWebsiteMerchantCenterStatus = (websiteId) => async (
  dispatch
) => {
  dispatch(fetchWebsiteMerchantCenterStatusRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/status/load_merchant_center_status`,
    dataType: 'json'
  })

  dispatch(
    fetchWebsiteMerchantCenterStatusSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const setWebsiteWatchingRequest = (websiteId, watching) => ({
  type: SET_WATCHING_REQUEST,
  websiteId,
  watching
})

const setWebsiteWatchingResponse = (websiteId, watching, response) => ({
  type: SET_WATCHING_RESPONSE,
  websiteId,
  watching,
  response
})

export const setWebsiteWatching = (websiteId, watching) => async (dispatch) => {
  dispatch(setWebsiteWatchingRequest(websiteId, watching))
  var response = await $.ajax({
    method: 'POST',
    url: `/ad_champion/websites/${websiteId}/set_watching`,
    data: {
      watching
    }
  })
  response = camelCaseKeys(response, { deep: true })
  dispatch(setWebsiteWatchingResponse(websiteId, watching, response))
  return response
}
