import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchProductDataAudits,
  genProductDataAudit,
  showHideAllAuditsModal
} from 'actions/inventories'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdbreact'
import toastr from 'toastr'
import { inventoryProductDataAuditsSelector } from 'selectors/inventory'
import { websiteRetailInventoryIdsSelector } from 'selectors/websites'

export class AuditsCard extends Component {
  static propTypes = {
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    allAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchProductDataAudits: PropTypes.func.isRequired,
    genProductDataAudit: PropTypes.func.isRequired,
    showHideAllAuditsModal: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  async componentDidMount() {
    const { fetchProductDataAudits, retailInventoryIds } = this.props
    if (retailInventoryIds && retailInventoryIds.length > 0) {
      this.setState({ fetchingAllAudits: true })
      await fetchProductDataAudits(retailInventoryIds[0])
      this.setState({ fetchingAllAudits: false })
    }
  }

  handelAuditUrlBtnClick(auditUrl) {
    window.open(auditUrl, '_blank')
  }

  renderAuditUrlBtn(auditUrl) {
    return (
      <MDBBtn
        key={auditUrl}
        tag='span'
        className='primary-outline-btn'
        onClick={this.handelAuditUrlBtnClick.bind(this, auditUrl)}
      >
        Download
      </MDBBtn>
    )
  }

  renderRecentAudits() {
    const { allAudits } = this.props
    const recentAudits = allAudits.slice(
      0,
      allAudits.length > 2 ? 2 : allAudits.length
    )

    return (
      <MDBCardText className='content' tag='span'>
        {recentAudits.map((audit, index) => (
          <div className='generating-row' key={audit.url}>
            <span>{audit.createdAt.split('|')[0]}</span>
            {audit.url === 'Generating' ? (
              <span className='generating-span'>{audit.url}...</span>
            ) : (
              this.renderAuditUrlBtn(audit.url)
            )}
          </div>
        ))}
      </MDBCardText>
    )
  }

  handleBtnClick() {
    const { showHideAllAuditsModal } = this.props
    showHideAllAuditsModal(true)
  }

  renderButton() {
    return (
      <MDBBtn
        key='show-all-audit-action'
        className='float-left playbook-nav-btn fill-btn'
        onClick={this.handleBtnClick.bind(this)}
      >
        View All
      </MDBBtn>
    )
  }

  async handleGenerateAuditClick() {
    const { toastr, genProductDataAudit, retailInventoryIds } = this.props
    toastr.info(
      'We are commencing your product data audit. You can stay on this screen and wait, or come back later.'
    )

    const auditUrl = await genProductDataAudit(retailInventoryIds[0])
    toastr.clear()
    if (auditUrl) {
      window.open(auditUrl, '_blank')
      toastr.success('FeedOps Audit PDF downloaded.')

      const { fetchProductDataAudits } = this.props
      await fetchProductDataAudits(retailInventoryIds[0])
    }
    this.setState({ generating: false })
  }

  renderGenerateAuditBtn() {
    return (
      <MDBBtn
        key='generate-new-audit-action'
        className='audit-btn'
        onClick={this.handleGenerateAuditClick.bind(this)}
      >
        Generate Audit
      </MDBBtn>
    )
  }

  renderCardBodyAndFooter() {
    const { allAudits } = this.props
    const auditsPresent = allAudits && allAudits.length > 0

    return (
      <>
        <MDBCardBody>
          <MDBCardTitle className='card-title'>
            {auditsPresent ? 'Recent ' : ''}Product Data Audits
          </MDBCardTitle>
          <hr />
          {auditsPresent && this.renderRecentAudits()}
        </MDBCardBody>
        <MDBCardFooter>
          {auditsPresent ? this.renderButton() : this.renderGenerateAuditBtn()}
        </MDBCardFooter>
      </>
    )
  }

  render() {
    const { retailInventoryIds } = this.props

    return retailInventoryIds.length > 0 ? (
      <MDBCard
        key='audits-summary'
        className='playbook-item-card recent-audits-card'
      >
        {this.renderCardBodyAndFooter()}
      </MDBCard>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  retailInventoryIds: websiteRetailInventoryIdsSelector(state, props) || [],
  allAudits: inventoryProductDataAuditsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchProductDataAudits, genProductDataAudit, showHideAllAuditsModal },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditsCard)
)
