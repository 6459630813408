import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HEALTH_ATTRIBUTE, searchedAttributeValues } from '../attributes_util'
import { HEALTH_IN_ORDER } from 'selectors/playbook/playbook_progress'
import { MDBListGroup, MDBListGroupItem, MDBInput } from 'mdbreact'

export default class ListItems extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchText: PropTypes.string.isRequired,
    filterValues: PropTypes.string.isRequired,
    itemValueChanged: PropTypes.func.isRequired
  }

  generateAttributeValues() {
    const { attribute, filteredAccounts, searchText } = this.props

    if (attribute.name === HEALTH_ATTRIBUTE) {
      return HEALTH_IN_ORDER
    } else {
      return searchedAttributeValues(
        attribute.name,
        [...filteredAccounts],
        searchText
      )
    }
  }

  conditionalItemsValueChanges(filterValuesArray) {
    const { itemValueChanged } = this.props

    if (filterValuesArray.length === 0) {
      itemValueChanged('')
    } else {
      itemValueChanged(filterValuesArray.join('\n'))
    }
  }

  updateFilterValuesArray(filterValuesArray, value) {
    if (filterValuesArray.length === 1) {
      filterValuesArray.pop()
    } else {
      filterValuesArray.splice(filterValuesArray.indexOf(value), 1)
    }
  }

  handleItemValueClick(value) {
    const { filterValues } = this.props
    var filterValuesArray = filterValues ? filterValues.split('\n') : []

    if (filterValuesArray.includes(value)) {
      this.updateFilterValuesArray(filterValuesArray, value)
    } else {
      filterValuesArray.push(value)
    }

    this.conditionalItemsValueChanges(filterValuesArray)
  }

  render() {
    const { filterValues } = this.props
    const filterValuesArray = filterValues.split('\n')

    const attributeValues = this.generateAttributeValues().map((value) => (
      <MDBListGroupItem
        key={value}
        hover
        onClick={this.handleItemValueClick.bind(this, value)}
      >
        <MDBInput
          type='checkbox'
          label={value}
          checked={filterValuesArray.includes(value)}
        />
      </MDBListGroupItem>
    ))

    return <MDBListGroup>{attributeValues}</MDBListGroup>
  }
}
