import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Col, Row } from 'reactstrap'
import {
  SUPPORTED_CHANNELS,
  getChannelPrograms,
  getFeaturesByChannel,
  getLogoByChannel
} from 'util/supported_channels'

import feedOpsLogo from 'images/feed_ops/feed_ops_logo.png'

import './channel_justification.scss'

export class ChannelJustification extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    programs: PropTypes.arrayOf(PropTypes.string).isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  renderList(items) {
    return (
      <ul className='fa-ul'>
        {items.map((item, index) => (
          <li key={index}>
            <span className='fa-li'>
              <i className='fa fa-check-circle' />
            </span>
            {item}
          </li>
        ))}
      </ul>
    )
  }

  renderPrograms() {
    const { programs } = this.props
    return (
      <Container className='programs'>
        Enables participation in the following:
        {this.renderList(programs)}
      </Container>
    )
  }

  renderProgramsLogo() {
    const { channel } = this.props
    return (
      <Container className='programs'>
        <img key={channel} src={getLogoByChannel(channel)} alt={channel} />
      </Container>
    )
  }

  renderFeatures() {
    const { features } = this.props
    return (
      <Container className='features'>
        Connecting this channel will enable FeedOps to:
        {this.renderList(features)}
      </Container>
    )
  }

  renderFeaturesLogo() {
    return (
      <Container className='features'>
        <img src={feedOpsLogo} alt='FeedOps' />
      </Container>
    )
  }

  render() {
    return (
      <Container className='channel-justification'>
        <Row>
          <Col xs={6}>{this.renderPrograms()}</Col>
          <Col xs={6}>{this.renderFeatures()}</Col>
        </Row>
        <Row>
          <Col xs={6}>{this.renderProgramsLogo()} </Col>
          <Col xs={6}>{this.renderFeaturesLogo()} </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state, props) => ({
  programs: getChannelPrograms(props.channel),
  features: getFeaturesByChannel(props.channel)
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelJustification)
