import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export * from './inventories/attributes'
export * from './inventories/categories'
export * from './inventories/processing_status'
export * from './inventories/product_data_audits'

export const FETCH_INVENTORIES_LIST = 'FETCH_INVENTORIES_LIST'
export const SAVE_SELECTED_INVENTORY_REQUEST = 'SAVE_SELECTED_INVENTORY_REQUEST'
export const SAVE_SELECTED_INVENTORY_SUCCESS = 'SAVE_SELECTED_INVENTORY_SUCCESS'
export const SAVE_SELECTED_INVENTORY_ERROR = 'SAVE_SELECTED_INVENTORY_ERROR'
export const SAVE_NEW_FEED_REQUEST = 'SAVE_NEW_FEED_REQUEST'
export const SAVE_NEW_FEED_SUCCESS = 'SAVE_NEW_FEED_SUCCESS'
export const SAVE_NEW_FEED_ERROR = 'SAVE_NEW_FEED_ERROR'
export const REQUEST_INVENTORY_STATUS = 'REQUEST_INVENTORY_STATUS'
export const RECEIVE_INVENTORY_STATUS = 'RECEIVE_INVENTORY_STATUS'
export const REQUEST_INVENTORY_DOWNLOAD = 'REQUEST_INVENTORY_DOWNLOAD'
export const RECEIVE_INVENTORY_DOWNLOAD = 'RECEIVE_INVENTORY_DOWNLOAD'
export const REQUEST_SAVE_INVENTORY_CURRENCY = 'REQUEST_SAVE_INVENTORY_CURRENCY'
export const RECEIVE_SAVE_INVENTORY_CURRENCY = 'RECEIVE_SAVE_INVENTORY_CURRENCY'
export const FETCH_INVENTORY_DETAIL_REQUEST = 'FETCH_INVENTORY_DETAIL_REQUEST'
export const FETCH_INVENTORY_DETAIL_RESPONSE = 'FETCH_INVENTORY_DETAIL_RESPONSE'
export const FETCH_FEED_CHAMPION_REQUEST = 'FETCH_FEED_CHAMPION_REQUEST'
export const FETCH_FEED_CHAMPION_RESPONSE = 'FETCH_FEED_CHAMPION_REQUEST'
export const UPDATE_INVENTORY_DETAIL_REQUEST = 'UPDATE_INVENTORY_DETAIL_REQUEST'
export const UPDATE_INVENTORY_DETAIL_RESPONSE =
  'UPDATE_INVENTORY_DETAIL_RESPONSE'
export const DELETE_INVENTORY_REQUEST = 'DELETE_INVENTORY_REQUEST'
export const DELETE_INVENTORY_RESPONSE = 'DELETE_INVENTORY_RESPONSE'
export const DELETE_INVENTORY_ERROR = 'DELETE_INVENTORY_ERROR'
export const OPEN_INVENTORY_SHEET_REQUEST = 'OPEN_INVENTORY_SHEET_REQUEST'
export const OPEN_INVENTORY_SHEET_SUCCESS = 'OPEN_INVENTORY_SHEET_SUCCESS'
export const ORDER_CUSTOM_INVENTORY_REQUEST = 'ORDER_CUSTOM_INVENTORY_REQUEST'
export const ORDER_CUSTOM_INVENTORY_RESPONSE = 'ORDER_CUSTOM_INVENTORY_RESPONSE'
export const FETCH_INVENTORY_CATEGORIES_REQUEST =
  'FETCH_INVENTORY_CATEGORIES_REQUEST'
export const FETCH_INVENTORY_CATEGORIES_RESPONSE =
  'FETCH_INVENTORY_CATEGORIES_RESPONSE'
export const REQUEST_INVENTORY_FIRST_RECONCILE_STATUS =
  'REQUEST_INVENTORY_FIRST_RECONCILE_STATUS'
export const RECEIVE_INVENTORY_FIRST_RECONCILE_STATUS =
  'RECEIVE_INVENTORY_FIRST_RECONCILE_STATUS'
export const REQUEST_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS =
  'REQUEST_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS'
export const RECEIVE_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS =
  'RECEIVE_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS'
export const FETCH_INVENTORY_FEED_CATEGORIES_REQUEST =
  'FETCH_INVENTORY_FEED_CATEGORIES_REQUEST'
export const FETCH_INVENTORY_FEED_CATEGORIES_RESPONSE =
  'FETCH_INVENTORY_FEED_CATEGORIES_RESPONSE'
export const SAVE_FEED_TAXONOMY_MAPPINGS_REQUEST =
  'SAVE_FEED_TAXONOMY_MAPPINGS_REQUEST'
export const SAVE_FEED_TAXONOMY_MAPPINGS_RESPONSE =
  'SAVE_FEED_TAXONOMY_MAPPINGS_RESPONSE'

export const fetchInventoriesList = (scenarioId) => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/website/inventories`,
    dataType: 'json',
    success: (response) => {
      dispatch(receiveInventoriesList(camelCaseKeys(response, { deep: true })))
    }
  })
}

export const fetchInventoriesListByWebsiteId = (websiteId) => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/inventories`,
    dataType: 'json',
    success: (response) => {
      dispatch(receiveInventoriesList(camelCaseKeys(response, { deep: true })))
    }
  })
}

export const saveSelectedInventory = (scenarioId, data) => (dispatch) => {
  dispatch(saveInventoryRequest(data, 'existing'))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/inventory/assign_inventory`,
    data: data,
    dataType: 'json',
    success: (response) => {
      return dispatch(saveInventorySuccess(response.responseJSON, 'existing'))
    },
    error: (response) => {
      return dispatch(
        saveInventoryError(response.responseJSON.error, 'existing')
      )
    }
  })
}

const newFeedByScenarioApi = (scenarioId, data) => {
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/inventory`,
    data: snakeCaseKeys(data)
  })
}

const newFeedApi = (data) => {
  return $.ajax({
    method: 'POST',
    url: '/ad_champion/inventories',
    data: snakeCaseKeys(data)
  })
}

export const saveNewFeed = (scenarioId, data) => (dispatch) => {
  dispatch(saveInventoryRequest(data, 'new'))

  var promise = scenarioId
    ? newFeedByScenarioApi(scenarioId, data)
    : newFeedApi(data)

  return promise.then(
    (response) => dispatch(saveInventorySuccess(response, 'new')),
    (response) => dispatch(saveInventoryError(response.error, 'new'))
  )
}

const receiveInventoriesList = (response) => ({
  type: FETCH_INVENTORIES_LIST,
  inventories: response.inventories,
  availableVerticalFields: response.availableVerticalFields,
  feedChampionUrl: response.feedChampionUrl,
  feedChampionPlatform: response.feedChampionPlatform,
  feedChampionReference: response.feedChampionReference,
  feedChampionStatus: response.feedChampionStatus,
  inventoryId: response.inventoryId
})

const saveInventoryRequest = (data, action_type) => ({
  type:
    action_type === 'new'
      ? SAVE_NEW_FEED_REQUEST
      : SAVE_SELECTED_INVENTORY_REQUEST,
  data
})

const saveInventorySuccess = (data, actionType) => ({
  type:
    actionType === 'new'
      ? SAVE_NEW_FEED_SUCCESS
      : SAVE_SELECTED_INVENTORY_SUCCESS,
  data
})

const saveInventoryError = (error, action_type) => ({
  type:
    action_type === 'new' ? SAVE_NEW_FEED_ERROR : SAVE_SELECTED_INVENTORY_ERROR,
  error
})

const requestInventoryStatus = (scenarioId) => ({
  type: REQUEST_INVENTORY_STATUS,
  scenarioId
})

const receiveInventoryStatus = (scenarioId, status) => ({
  type: RECEIVE_INVENTORY_STATUS,
  scenarioId,
  status
})

export const fetchInventoryStatus = (scenarioId) => (dispatch) => {
  dispatch(requestInventoryStatus(scenarioId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/inventory`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        receiveInventoryStatus(
          scenarioId,
          camelCaseKeys(response || {}, { deep: true })
        )
      )
    }
  })
}

const requestInventoryDownload = (scenarioId, inventoryId) => ({
  type: REQUEST_INVENTORY_DOWNLOAD,
  scenarioId,
  inventoryId
})

const receiveInventoryDownload = (scenarioId, inventoryId, download) => ({
  type: RECEIVE_INVENTORY_DOWNLOAD,
  scenarioId,
  inventoryId,
  download
})

export const triggerInventoryDownload = (scenarioId) => (dispatch) => {
  dispatch(requestInventoryDownload(scenarioId, ''))
  return $.ajax({
    url: `/ad_champion/scenarios/${scenarioId}/inventory/trigger_download`,
    method: 'POST',
    dataType: 'json',
    success: (response) => {
      dispatch(
        receiveInventoryDownload(camelCaseKeys(scenarioId, '', response))
      )
    }
  })
}

export const triggerInventoryDownloadByInventoryId =
  (inventoryId, force = false) =>
  (dispatch) => {
    dispatch(requestInventoryDownload('', inventoryId))
    return $.ajax({
      url: `/ad_champion/inventories/${inventoryId}/trigger_download`,
      method: 'POST',
      dataType: 'json',
      data: { force },
      success: (response) => {
        dispatch(
          receiveInventoryDownload('', inventoryId, camelCaseKeys(response))
        )
      }
    })
  }

const requestSaveInventoryCurrency = (scenarioId, currency) => (dispatch) => ({
  type: REQUEST_SAVE_INVENTORY_CURRENCY,
  currency
})

const receiveSaveInventoryCurrency = (scenarioId, currency) => (dispatch) => ({
  type: RECEIVE_SAVE_INVENTORY_CURRENCY,
  currency
})

export const setInventoryCurrency = (scenarioId, currency) => (dispatch) => {
  dispatch(requestSaveInventoryCurrency(scenarioId, currency))
  var payload = {
    currency
  }
  return $.ajax({
    url: `/ad_champion/scenarios/${scenarioId}/inventory/currency`,
    method: 'PUT',
    dataType: 'json',
    data: payload,
    success: (response) => {
      dispatch(receiveSaveInventoryCurrency(scenarioId, currency))
    }
  })
}

const fetchInventoryDetailRequest = (inventoryId) => ({
  type: FETCH_INVENTORY_DETAIL_REQUEST,
  inventoryId
})

const fetchInventoryDetailResponse = (inventoryId, inventoryDetail) => ({
  type: FETCH_INVENTORY_DETAIL_RESPONSE,
  inventoryId,
  inventoryDetail
})

export const fetchInventoryDetail = (inventoryId) => (dispatch) => {
  dispatch(fetchInventoryDetailRequest(inventoryId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/inventories/${inventoryId}`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchInventoryDetailResponse(
          inventoryId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const updateInventoryDetailRequest = (inventoryId, inventoryDetail) => ({
  type: UPDATE_INVENTORY_DETAIL_REQUEST,
  inventoryId,
  inventoryDetail
})

const updateInventoryDetailResponse = (inventoryId, inventoryDetail) => ({
  type: UPDATE_INVENTORY_DETAIL_RESPONSE,
  inventoryId,
  inventoryDetail
})

export const updateInventoryDetail =
  (inventoryId, inventoryDetail) => (dispatch) => {
    dispatch(updateInventoryDetailRequest(inventoryId, inventoryDetail))
    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/inventories/${inventoryId}`,
      dataType: 'json',
      data: snakeCaseKeys(inventoryDetail),
      success: () => {
        dispatch(updateInventoryDetailResponse(inventoryId, inventoryDetail))
        return fetchInventoryDetail(inventoryId)
      },
      error: (response) => {
        dispatch(updateInventoryDetailResponse(inventoryId, inventoryDetail))
        return camelCaseKeys(response, { deep: true })
      }
    })
  }

const deleteInventoryRequest = (inventoryId) => ({
  type: DELETE_INVENTORY_REQUEST,
  inventoryId
})

const deleteInventoryResponse = (inventoryId) => ({
  type: DELETE_INVENTORY_RESPONSE,
  inventoryId
})

const deleteInventoryError = (inventoryId) => ({
  type: DELETE_INVENTORY_ERROR,
  inventoryId
})

export const deleteInventory = (inventoryId) => (dispatch) => {
  dispatch(deleteInventoryRequest(inventoryId))
  return $.ajax({
    method: 'DELETE',
    url: `/ad_champion/inventories/${inventoryId}`,
    dataType: 'json',
    success: () => {
      dispatch(deleteInventoryResponse(inventoryId))
    },
    error: () => {
      dispatch(deleteInventoryError(inventoryId))
    }
  })
}

const openErrorsSheetRequest = (inventoryId) => ({
  type: OPEN_INVENTORY_SHEET_REQUEST,
  inventoryId
})

const openErrorsSheetResponse = (inventoryId, googleSheet) => ({
  type: OPEN_INVENTORY_SHEET_SUCCESS,
  inventoryId,
  googleSheet
})

export const openErrorsSheet = (inventoryId) => (dispatch) => {
  dispatch(openErrorsSheetRequest(inventoryId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/inventories/${inventoryId}/open_errors_sheet`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        openErrorsSheetResponse(
          inventoryId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const orderCustomInventoryRequest = (websiteId, formValues) => ({
  type: ORDER_CUSTOM_INVENTORY_REQUEST,
  websiteId,
  formValues
})

const orderCustomInventoryResponse = (websiteId, formValues, response) => ({
  type: ORDER_CUSTOM_INVENTORY_RESPONSE,
  websiteId,
  formValues,
  response
})

export const orderCustomInventory =
  (websiteId, formValues) => async (dispatch) => {
    var data = snakeCaseKeys({ ...formValues })
    dispatch(orderCustomInventoryRequest(websiteId, formValues))
    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/inventories/order_custom`,
      data,
      dataType: 'json'
    })
    dispatch(orderCustomInventoryResponse(websiteId, formValues, response))
  }

const fetchInventoryCategoriesRequest = (websiteId, feedId) => ({
  type: FETCH_INVENTORY_CATEGORIES_REQUEST,
  websiteId,
  feedId
})

const fetchInventoryCategoriesResponse = (
  websiteId,
  categories,
  inventoryId
) => ({
  type: FETCH_INVENTORY_CATEGORIES_RESPONSE,
  websiteId,
  categories,
  inventoryId
})

const getCategoriesApi = (websiteId, forceFetch, statusCode, feedId) => {
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/inventories/categories`,
    data: snakeCaseKeys({ forceFetch, feedId }),
    dataType: 'json',
    statusCode
  })
}

export const fetchInventoryCategories =
  (websiteId, feedId = null, forceFetch = false) =>
  async (dispatch) => {
    dispatch(fetchInventoryCategoriesRequest(websiteId, feedId))
    var status = 202
    var response
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }
    var categories = []

    while (status === 202) {
      response = await getCategoriesApi(
        websiteId,
        forceFetch,
        statusCode,
        feedId
      )
      forceFetch = false
      if (status === 202) {
        await sleep(1000)
      }
    }

    categories = camelCaseKeys(JSON.parse(response.categories), { deep: true })
    var inventoryIdByFeed = response.inventory_id
    dispatch(
      fetchInventoryCategoriesResponse(websiteId, categories, inventoryIdByFeed)
    )
    return categories
  }

const requestInventoryFirstReconcileStatus = (websiteId) => ({
  type: REQUEST_INVENTORY_FIRST_RECONCILE_STATUS,
  websiteId
})

const receiveInventoryFirstReconcileStatus = (websiteId, response) => ({
  type: RECEIVE_INVENTORY_FIRST_RECONCILE_STATUS,
  websiteId,
  ...response
})

export const fetchInventoryFirstReconcileStatus =
  (websiteId) => async (dispatch) => {
    dispatch(requestInventoryFirstReconcileStatus(websiteId))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/inventories/initial_reconciled`,
      dataType: 'json'
    })

    var camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(receiveInventoryFirstReconcileStatus(websiteId, camelResponse))
    return camelResponse.reconciled
  }

const requestInventoryOutboundFeedsGenerationStatus = (websiteId) => ({
  type: REQUEST_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS,
  websiteId
})

const receiveInventoryOutboundFeedsGenerationStatus = (
  websiteId,
  response
) => ({
  type: RECEIVE_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS,
  websiteId,
  ...response
})

export const fetchInventoryOutboundFeedsGenerationStatus =
  (websiteId) => async (dispatch) => {
    dispatch(requestInventoryOutboundFeedsGenerationStatus(websiteId))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/inventories/shopping_feed_generated`,
      dataType: 'json'
    })

    var camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(
      receiveInventoryOutboundFeedsGenerationStatus(websiteId, camelResponse)
    )
    return camelResponse.reconciled
  }
