import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getFormValues, change } from 'redux-form'
import { MDBBox, MDBInput, MDBSwitch } from 'mdbreact'
import { REDUX_FORM_NAME } from './attribute_list'
import { websiteIdSelector } from 'selectors/websites'
import { updateInventoryAttributeReplacements } from 'actions/product_feeds/replacements'

export class ProductAttributeListNode extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    attribute: PropTypes.object.isRequired,
    attributeValue: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    updateInventoryAttributeReplacements: PropTypes.func.isRequired
  }

  async onBlur(event) {
    await this.updateInventoryAttributeReplacements(event.target.value)
  }

  onChange(event) {
    const { attribute, attributeValue, change } = this.props
    change(
      REDUX_FORM_NAME,
      `attributeValues[${attribute.id}][${attributeValue.id}].replacement`,
      event.target.value
    )
  }

  async updateInventoryAttributeReplacements(replacementValue) {
    const {
      websiteId,
      attribute,
      attributeValue,
      updateInventoryAttributeReplacements
    } = this.props
    await updateInventoryAttributeReplacements(websiteId, {
      attributeId: attribute.id,
      attribute: {
        id: attributeValue.id,
        replacement: replacementValue,
        remove: attributeValue.replacement ? false : true
      }
    })
  }

  async handleSwitchChange() {
    const { attribute, attributeValue, formValues, change } = this.props
    const enabledKey = `enabled-${attribute.id}-${attributeValue.id}`
    var enabled = formValues[enabledKey]
    change(REDUX_FORM_NAME, enabledKey, !enabled)
    if (enabled) {
      change(
        REDUX_FORM_NAME,
        `attributeValues[${attribute.id}][${attributeValue.id}].replacement`,
        null
      )
      await this.updateInventoryAttributeReplacements(null)
    }
  }

  render() {
    const { attribute, attributeValue, formValues } = this.props
    const enabledKey = `enabled-${attribute.id}-${attributeValue.id}`
    const enabled = formValues[enabledKey]
    const rewrite =
      formValues.attributeValues &&
      formValues.attributeValues[attribute.id] &&
      formValues.attributeValues[attribute.id][attributeValue.id] &&
      formValues.attributeValues[attribute.id][attributeValue.id].replacement
    return (
      <React.Fragment>
        <MDBBox display='flex' flex='row'>
          <span className='attribute-value'>{`${attributeValue.name}: `}</span>
          <MDBSwitch
            name={enabledKey}
            checked={enabled}
            labelLeft={'Unchanged'}
            labelRight={'Rewritten'}
            onChange={this.handleSwitchChange.bind(this)}
          />
        </MDBBox>
        <MDBBox display='flex' flex='row' alignItems='stretch'>
          {enabled && (
            <MDBInput
              className='attribute-rewrite'
              type='text'
              value={rewrite ? rewrite : ''}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
            />
          )}
        </MDBBox>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  const formValues = getFormValues(REDUX_FORM_NAME)(state) || {}
  return { websiteId, formValues }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change, updateInventoryAttributeReplacements }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAttributeListNode)
)
