import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import changeCase from 'change-case'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Checkbox,
  Select,
  MenuItem
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MDBAlert } from 'mdbreact'
import { OPTION_INDEPENDENT, OPTION_SYNC } from '../container'
import { websiteFeedDetailSelector } from 'selectors/websites'
import { enabledChannelsSelector } from 'selectors/websites/channel_settings'

import './feed_sync_setting.scss'

export class FeedSyncSetting extends Component {
  static propTypes = {
    feedDetail: PropTypes.object.isRequired,
    enabledChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
    syncOption: PropTypes.string.isRequired,
    sourceChannel: PropTypes.string.isRequired,
    copyOptimisations: PropTypes.bool.isRequired,
    syncOptionChange: PropTypes.func.isRequired,
    sourceChannelChange: PropTypes.func.isRequired,
    copyOptimisationsChange: PropTypes.func.isRequired
  }

  computeIsDisabled() {
    const { feedDetail } = this.props
    const { linkedChannels } = feedDetail

    return linkedChannels && linkedChannels.length > 0
  }

  handleSyncValueChanged(event) {
    const { syncOptionChange } = this.props
    syncOptionChange(event.target.value)
  }

  handleCopyOptimisationsChange(event) {
    const { copyOptimisationsChange } = this.props
    copyOptimisationsChange(event.target.checked)
  }

  renderIndependentSettings() {
    const { feedDetail, copyOptimisations } = this.props

    return (
      <Box sx={{ ml: '2rem' }}>
        <FormControlLabel
          control={<Checkbox />}
          label={`Copy the current optimizations from ${changeCase.titleCase(
            feedDetail.sourceChannel
          )}?`}
          checked={copyOptimisations}
          onChange={this.handleCopyOptimisationsChange.bind(this)}
        />
      </Box>
    )
  }

  renderIndependentOption() {
    const { feedDetail, syncOption } = this.props
    const isNewlyIndependent =
      syncOption === OPTION_INDEPENDENT &&
      feedDetail.sourceChannel !== feedDetail.channel

    return (
      <>
        <FormControlLabel
          value={OPTION_INDEPENDENT}
          control={<Radio />}
          label='Optimize this feed independently'
        />
        {isNewlyIndependent && this.renderIndependentSettings()}
      </>
    )
  }

  handleChannelSelectorChange(event) {
    const { sourceChannelChange } = this.props
    sourceChannelChange(event.target.value)
  }

  renderChannelSelector() {
    const { enabledChannels, feedDetail, sourceChannel } = this.props
    const options = enabledChannels.filter((c) => feedDetail.channel !== c)

    return (
      <FormControl fullWidth>
        <Select
          value={sourceChannel}
          onChange={this.handleChannelSelectorChange.bind(this)}
        >
          {options.map((c) => (
            <MenuItem value={c} key={c}>
              {changeCase.titleCase(c)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  renderSyncOption() {
    const { feedDetail, syncOption } = this.props
    const isSync = syncOption === OPTION_SYNC
    const isNewlySync =
      isSync && feedDetail.sourceChannel === feedDetail.channel

    return (
      <>
        <FormControlLabel
          value={OPTION_SYNC}
          control={<Radio />}
          label='Use optimizations from another feed'
        />
        {isSync && this.renderChannelSelector()}
        {isNewlySync && (
          <Box sx={{ mt: 5 }}>
            <MDBAlert color='warning'>
              This change will remove all existing optimizations and replace
              them with optimizations from the selected feed. It cannot be
              undone.
            </MDBAlert>
          </Box>
        )}
      </>
    )
  }

  renderRadioGroup() {
    const { syncOption } = this.props
    const disabled = this.computeIsDisabled()

    return (
      <RadioGroup
        value={syncOption}
        disabled={disabled}
        onChange={this.handleSyncValueChanged.bind(this)}
      >
        {this.renderIndependentOption()}
        {this.renderSyncOption()}
      </RadioGroup>
    )
  }

  renderAccordion() {
    const disabled = this.computeIsDisabled()

    return (
      <Accordion className='feed-sync-setting'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Optimization settings
        </AccordionSummary>
        <AccordionDetails>
          <FormControl disabled={disabled} fullWidth>
            {disabled && (
              <MDBAlert color='warning'>
                There are other feeds linked to this feed. Please unlink before
                changing this setting.
              </MDBAlert>
            )}
            {this.renderRadioGroup()}
          </FormControl>
        </AccordionDetails>
      </Accordion>
    )
  }

  render() {
    const { enabledChannels } = this.props

    return enabledChannels && enabledChannels.length > 1
      ? this.renderAccordion()
      : null
  }
}

export const mapStateToProps = (state, props) => ({
  feedDetail: websiteFeedDetailSelector(state, props),
  enabledChannels: enabledChannelsSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedSyncSetting)
)
