import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues } from 'redux-form'
import _ from 'lodash'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { saveFileImportProducts } from 'actions/product_feeds/replacements/products/file_single_import'
import { saveApiImportProducts } from 'actions/product_feeds/replacements/products/api_single_import'
import CustomFieldMappings from 'components/feed_ops/website/store_connection/attribute_mapping/custom_field_mappings'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import { DEFAULT_ATTRIBUTES_MAPPING } from '../common/table_actions_util'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

import './attributes_mapping.scss'

export class AttributesMapping extends Component {
  static propTypes = {
    fileUrl: PropTypes.string.isRequired,
    accessSettings: PropTypes.object.isRequired,
    importHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    allAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    handleIsValidChange: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    saveFileImportProducts: PropTypes.func.isRequired,
    saveApiImportProducts: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { initialize, initialMappings } = this.props
    const mappings =
      initialMappings && initialMappings.length > 0
        ? initialMappings
        : DEFAULT_ATTRIBUTES_MAPPING

    initialize({
      existingMappings: mappings,
      saveToAllChannels: false,
      saveOptimisationSuggestions: false
    })
  }

  componentDidUpdate(prevProps) {
    const { formValues } = this.props
    if (!_.isEqual(prevProps.formValues, formValues)) {
      const { handleIsValidChange } = this.props
      handleIsValidChange(validateMappings(formValues))
    }
  }

  // All FeedOps attributes are mappable
  computeMappableAttributes() {
    const { allAttributes } = this.props

    return allAttributes.map((attribute) => ({
      id: attribute.id,
      name: attribute.name,
      displayName: attribute.columnName
    }))
  }

  createImportedHeadersObj() {
    const { importHeaders } = this.props

    return importHeaders.map((header) => ({
      id: header,
      name: header,
      columnName: header,
      columnCategoryName: 'Source Attributes'
    }))
  }

  render() {
    const { websiteId, formValues } = this.props
    const { existingMappings } = formValues

    return (
      <div className='attributes-mapping-container'>
        <div className='map-attributes-text'>
          Map attributes from your source to FeedOps attributes.
        </div>
        {existingMappings && (
          <CustomFieldMappings
            websiteId={websiteId}
            mappableAttributes={this.computeMappableAttributes()}
            storeAttributes={this.createImportedHeadersObj()}
            existingMappings={existingMappings}
            reduxFormName={REDUX_FORM_NAME}
          />
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  channel: channelSelector(state, props),
  allAttributes: websiteAllAttributesSelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { saveFileImportProducts, saveApiImportProducts },
    dispatch
  )

const AttributesMappingWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributesMapping)

export const REDUX_FORM_NAME = 'ImportAttributesMapping'

const computeImportSettings = (props, values) => {
  const { feedId, channel } = props
  const { saveToAllChannels, saveOptimisationSuggestions } = values

  return {
    feedId,
    channel,
    saveToAllChannels,
    saveOptimisationSuggestions
  }
}

const saveProducts = async (props, mappings, importSettings) => {
  const { websiteId, fileUrl, accessSettings } = props
  var response = null

  if (fileUrl) {
    response = await props.saveFileImportProducts(
      websiteId,
      fileUrl,
      mappings,
      importSettings
    )
  } else if (accessSettings && !_.isEmpty(accessSettings)) {
    response = await props.saveApiImportProducts(
      websiteId,
      accessSettings,
      mappings,
      importSettings
    )
  }

  if (response && response.unavailableItemsCount > 0) {
    toastr.warning(
      `${response.unavailableItemsCount} items were not imported because they contained mismatched Item IDs.`
    )
  }
}

export const handleSubmit = async (values, dispatch, props) => {
  try {
    if (!validateMappings(values)) {
      toastr.error('Please map Item ID & at least one other attribute.')
    } else {
      const mappings = values.existingMappings
      const importSettings = computeImportSettings(props, values)

      toastr.info('Importing product data. This can take some time.')
      await saveProducts(props, mappings, importSettings)
      toastr.success('Product data imported successfully.')
    }
  } catch (error) {
    toastr.error(
      'An error occurred while importing your product data. Please contact support for assistance.'
    )
  }
}

export const validateMappings = (values) => {
  const mappings = values.existingMappings
  if (
    _.isEmpty(mappings) ||
    mappings.length <= 1 ||
    !mappings.some((mapping) => mapping.displayName === 'Item ID') ||
    mappings.some((mapping) => _.isEmpty(mapping.sourceName))
  ) {
    return false
  }

  return true
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate: validateMappings,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(AttributesMappingWithRedux))
  )
)
