import camelCaseKeys from 'camelcase-keys'

export const FETCH_ADVERTISING_GOAL_TYPES_REQUEST =
  'FETCH_ADVERTISING_GOAL_TYPES_REQUEST'
export const FETCH_ADVERTISING_GOAL_TYPES_SUCCESS =
  'FETCH_ADVERTISING_GOAL_TYPES_SUCCESS'

export const TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_REQUEST =
  'TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_REQUEST'
export const TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_SUCCESS =
  'TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_SUCCESS'

const fetchAdvertisingGoalTypesRequest = () => ({
  type: FETCH_ADVERTISING_GOAL_TYPES_REQUEST
})

const fetchAdvertisingGoalTypesSuccess = (advertisingGoalTypes) => ({
  type: FETCH_ADVERTISING_GOAL_TYPES_SUCCESS,
  advertisingGoalTypes
})

export const fetchAdvertisingGoalTypes = () => async (dispatch) => {
  dispatch(fetchAdvertisingGoalTypesRequest())
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/advertising_goal_types`,
    dataType: 'json'
  })
  dispatch(
    fetchAdvertisingGoalTypesSuccess(camelCaseKeys(response, { deep: true }))
  )
}

const toggleDisplayConversionValueOnCostRequest = () => ({
  type: TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_REQUEST
})

const toggleDisplayConversionValueOnCostSuccess = () => ({
  type: TOGGLE_DISPLAY_CONVERSION_VALUE_ON_COST_SUCCESS
})

export const toggleDisplayConversionValueOnCost = () => (dispatch) => {
  dispatch(toggleDisplayConversionValueOnCostRequest())
  return dispatch(toggleDisplayConversionValueOnCostSuccess())
}
