import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import MaterialTable from '@material-table/core'
import {
  PAGE_SIZE_OPTIONS,
  ITEM_ID_HEADER,
  TITLE_COLUMN_HEADER,
  generateTableOptions,
  generateTableLocalization,
  generateTableActions,
  getQueryStringColumns,
  getQueryStringFilters,
  getQueryStringBatchIds
} from './common/optimiser_util'
import { getAttributeByColumnName } from './common/attributes_util'
import { fetchGoogleTaxonomies } from 'actions/google/taxonomies'
import {
  updateAttributeFilters,
  fetchInventoryAttributes,
  fetchProductAttributeReplacements,
  updateRowSelectionStatus,
  clearUpdatedItemsAndReplacements,
  productAttributeReplacementsQueryChangedStatus
} from 'actions/product_feeds/replacements'
import ColumnTitle from './optimiser/column_title'
import ColumnContent from './optimiser/column_content'
import TableActions from './optimiser/table_actions'
import TableActionsTooltip from './optimiser/table_actions_tooltip'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  websiteProductReplacementsSelector,
  websiteProductReplacementsMetaDataSelector,
  websiteProductReplacementsPageSelector,
  websiteProductReplacementsPerPageSelector,
  websiteProductReplacementsFiltersSelector,
  websiteAllAttributesSelector,
  websiteQueryChangedStatusSelector
} from 'selectors/product_feeds/replacements'
import { OP_HAS_SUGGESTIONS } from '../product_optimisations/attribute_filters_section/filter_operations'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'
import CustomPagination from 'components/feed_ops/website/product_optimisations/optimiser/custom_pagination'

export class ProductLevelOptimiser extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    sourceChannel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    allowRichTextDescription: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    metaData: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    queryChangedStatus: PropTypes.bool.isRequired,
    updateAttributeFilters: PropTypes.func.isRequired,
    fetchGoogleTaxonomies: PropTypes.func.isRequired,
    fetchInventoryAttributes: PropTypes.func.isRequired,
    fetchProductAttributeReplacements: PropTypes.func.isRequired,
    clearUpdatedItemsAndReplacements: PropTypes.func.isRequired,
    productAttributeReplacementsQueryChangedStatus: PropTypes.func.isRequired
  }

  static defaultProps = {
    readOnly: false
  }

  constructor() {
    super()
    this.state = {
      windowHeight: 0,
      filtersQueryChanged: false,
      hasSuggestionsFilter: false,
      displayedColumns: [ITEM_ID_HEADER, TITLE_COLUMN_HEADER],
      page: 1,
      allRowsSelected: false,
      selectedRows: [],
      batchIds: null
    }
  }

  handleWindowResize() {
    this.setState({ windowHeight: window.innerHeight })
  }

  async componentDidMount() {
    this.tableRef = React.createRef()
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    this.handleWindowResize()

    const { location } = this.props
    const qsBatchIds = getQueryStringBatchIds(location)
    if (qsBatchIds) {
      this.setState({ batchIds: qsBatchIds })
    }
    const qsColumns = getQueryStringColumns(location)
    if (qsColumns) {
      this.handleColumnsConfigChange(qsColumns)
    }
    const qsFilters = getQueryStringFilters(location)
    if (qsFilters) {
      const { updateAttributeFilters, websiteId } = this.props
      await updateAttributeFilters(websiteId, qsFilters)
      this.setState({ filtersQueryChanged: true })

      if (qsFilters.some((flt) => flt.filterType === OP_HAS_SUGGESTIONS)) {
        this.setState({ hasSuggestionsFilter: true })
      }
    }

    const {
      fetchGoogleTaxonomies,
      fetchInventoryAttributes,
      feedId,
      websiteId
    } = this.props
    await Promise.all([
      fetchGoogleTaxonomies(feedId),
      fetchInventoryAttributes(websiteId, false)
    ])
  }

  async componentDidUpdate(prevProps) {
    const { channel, feedId, sourceChannel, filters } = this.props
    const isChannelChanged =
      !_.isEqual(channel, prevProps.channel) ||
      !_.isEqual(feedId, prevProps.feedId) ||
      !_.isEqual(sourceChannel, prevProps.sourceChannel)
    const isFiltersChanged = !_.isEqual(filters, prevProps.filters)

    const { allowRichTextDescription } = this.props
    const isFeedSettingsChanged = !_.isEqual(
      allowRichTextDescription,
      prevProps.allowRichTextDescription
    )

    const { queryChangedStatus } = this.props
    const isQueryChanged = queryChangedStatus && !prevProps.queryChangedStatus

    if (
      isChannelChanged ||
      isFiltersChanged ||
      isFeedSettingsChanged ||
      isQueryChanged
    ) {
      const { clearUpdatedItemsAndReplacements, websiteId } = this.props
      await clearUpdatedItemsAndReplacements(websiteId)
      isFiltersChanged && this.setState({ filtersQueryChanged: true })
      if (!_.isEmpty(this.tableRef.current)) {
        this.tableRef.current.onQueryChange()
      }
    }

    const { hasSuggestionsFilter } = this.state
    if (
      hasSuggestionsFilter &&
      !_.isEmpty(this.tableRef.current) &&
      this.tableRef.current.dataManager.data.length > 0
    ) {
      this.tableRef.current.onAllSelected(true)
      this.setState({ hasSuggestionsFilter: false })
    }
  }

  async fetchProductAttributeReplacements(
    page,
    perPage,
    filters,
    displayedColumns
  ) {
    const { batchIds } = this.state
    const { fetchProductAttributeReplacements, websiteId, feedId, channel } =
      this.props
    await fetchProductAttributeReplacements(
      websiteId,
      feedId,
      channel,
      page,
      perPage,
      filters,
      displayedColumns,
      batchIds
    )
    this.setState({ page })
  }

  async fetchProductData(query) {
    const { filtersQueryChanged, displayedColumns } = this.state
    const { filters, perPage } = this.props
    const page = filtersQueryChanged ? 0 : query.page
    const pageSize = PAGE_SIZE_OPTIONS.includes(query.pageSize)
      ? query.pageSize
      : perPage

    await this.fetchProductAttributeReplacements(
      page + 1,
      pageSize,
      filters && filters.length > 0 ? filters : [],
      displayedColumns
    )

    const { productAttributeReplacementsQueryChangedStatus, websiteId } =
      this.props
    productAttributeReplacementsQueryChangedStatus(websiteId, false)

    const { items, metaData } = this.props
    const tableData = {
      data: items,
      page,
      pageSize,
      totalCount: metaData.totalItems
    }
    this.setState({ filtersQueryChanged: false })
    return tableData
  }

  getDisplayColumns() {
    const { displayedColumns } = this.state
    const { attributes } = this.props
    var displayedColumnsData = []

    displayedColumns.forEach((columnName) => {
      const attribute = getAttributeByColumnName(attributes, columnName)
      if (attribute) {
        displayedColumnsData.push({
          title: <ColumnTitle columnName={columnName} attribute={attribute} />,
          field: `override.${attribute.field}`,
          sorting: false,
          render: (rowData) => (
            <ColumnContent
              rowData={rowData}
              attributeName={attribute.field}
              columnName={columnName}
            />
          )
        })
      }
    })

    return displayedColumnsData
  }

  async handleColumnsConfigChange(newConfig) {
    this.setState({ displayedColumns: newConfig })
    if (!_.isEmpty(this.tableRef.current)) {
      this.tableRef.current.onQueryChange()
    }
  }

  async handleOnSaveProductAttributesReplacements(fetchAttributes) {
    if (fetchAttributes) {
      const { fetchInventoryAttributes, websiteId } = this.props
      await fetchInventoryAttributes(websiteId)
    }

    if (!_.isEmpty(this.tableRef.current)) {
      this.tableRef.current.onAllSelected(false)
      this.tableRef.current.onQueryChange()
    }
  }

  handleClearSelectedRows() {
    this.tableRef.current.onAllSelected(false)
  }

  handleSelectAllRows() {
    this.setState({ allRowsSelected: true })
  }

  renderTableAction(actionName, rowData) {
    const { displayedColumns, allRowsSelected } = this.state
    const { metaData } = this.props
    return (
      <TableActions
        rowData={rowData}
        actionName={actionName}
        displayedColumns={displayedColumns}
        handleColumnsConfigChange={this.handleColumnsConfigChange.bind(this)}
        handleOnSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this
        )}
        handleClearSelectedRows={this.handleClearSelectedRows.bind(this)}
        handleSelectAllRows={this.handleSelectAllRows.bind(this)}
        metaData={metaData}
        allRowsSelected={allRowsSelected}
      />
    )
  }

  generateTableAction(actionName, position, tooltip) {
    return {
      position: position,
      action: (rowData) => ({
        icon: () => this.renderTableAction(actionName, rowData),
        tooltip: tooltip && (
          <TableActionsTooltip
            actionName={actionName}
            tooltip={tooltip}
            rowData={rowData}
          />
        )
      })
    }
  }

  getTableActions() {
    const { location } = this.props
    const tableActions = generateTableActions(location).map((action) =>
      this.generateTableAction(
        action.actionName,
        action.position,
        action.tooltip
      )
    )

    return tableActions
  }

  handleColumnDragged(start, end) {
    const { displayedColumns } = this.state
    var movingColumn = displayedColumns[start]
    displayedColumns.splice(start, 1)
    displayedColumns.splice(end, 0, movingColumn)
    this.setState({ displayedColumns })
  }

  handleProductSelectionChange(selectedProducts) {
    const { updateRowSelectionStatus } = this.props
    const isProductRowSelected = selectedProducts.length > 0
    updateRowSelectionStatus(isProductRowSelected)
    this.setState({ allRowsSelected: false })
  }

  render() {
    const { windowHeight } = this.state
    const { perPage, readOnly, metaData } = this.props
    const { allRowsSelected } = this.state

    return (
      <div className='product-level-optimiser'>
        <MaterialTable
          tableRef={this.tableRef}
          data={this.fetchProductData.bind(this)}
          columns={this.getDisplayColumns()}
          actions={this.getTableActions()}
          onColumnDragged={this.handleColumnDragged.bind(this)}
          onSelectionChange={this.handleProductSelectionChange.bind(this)}
          options={generateTableOptions(windowHeight, perPage, readOnly)}
          localization={generateTableLocalization({
            allRowsSelected,
            metaData
          })}
          components={{ Pagination: CustomPagination }}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const feedDetail = websiteFeedDetailSelector(state, props)
  return {
    websiteId: websiteIdSelector(state, props),
    feedId: websiteFeedIdByChannelSelector(state, props),
    readOnly: feedDetail.sourceChannel !== feedDetail.channel,
    channel: channelSelector(state, props),
    sourceChannel: feedDetail.sourceChannel,
    allowRichTextDescription: feedDetail.allowRichTextDescription,
    items: websiteProductReplacementsSelector(state, props),
    metaData: websiteProductReplacementsMetaDataSelector(state, props),
    page: websiteProductReplacementsPageSelector(state, props),
    perPage: websiteProductReplacementsPerPageSelector(state, props),
    filters: websiteProductReplacementsFiltersSelector(state, props),
    attributes: websiteAllAttributesSelector(state, props),
    queryChangedStatus: websiteQueryChangedStatusSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAttributeFilters,
      fetchGoogleTaxonomies,
      fetchInventoryAttributes,
      fetchProductAttributeReplacements,
      clearUpdatedItemsAndReplacements,
      updateRowSelectionStatus,
      productAttributeReplacementsQueryChangedStatus
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductLevelOptimiser)
)
