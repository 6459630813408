import validator from 'validator'
import { tldExists } from 'tldjs'
import _ from 'lodash'

const domainNameRegex = new RegExp('^(?!-)[A-Za-z0-9-]+(?=-?$)')

const REQUIRED = 'This field is required.'
export const validatePresence = (value) => (value ? null : REQUIRED)

export const validateWebsiteUrl = (value) => {
  if (value === undefined || value === 'https://') {
    return 'This field is required'
  }
  if (!tldExists(value)) {
    return 'Not a valid URL'
  }
  return ''
}

export const validateClientCredentials = (value, allValues) => {
  var error = ''
  if (_.isEmpty(allValues)) {
    error = ''
  } else if (
    (!_.isEmpty(allValues.clientId) || !_.isEmpty(allValues.clientSecret)) &&
    !_.isEmpty(allValues.accessToken)
  ) {
    error = 'Please use either client details or access token'
  } else if (
    (!_.isEmpty(allValues.clientId) || !_.isEmpty(allValues.clientSecret)) &&
    _.isEmpty(value)
  ) {
    error = 'This field is required'
  } else if (
    _.isEmpty(allValues.clientId) &&
    _.isEmpty(allValues.clientSecret) &&
    _.isEmpty(allValues.accessToken)
  ) {
    error = 'Please enter client details or access token'
  }
  return error
}

export const validateAccessToken = (value, allValues) => {
  var error = ''
  if (_.isEmpty(allValues)) {
    error = ''
  } else if (
    (!_.isEmpty(allValues.clientId) || !_.isEmpty(allValues.clientSecret)) &&
    !_.isEmpty(value)
  ) {
    return 'Please use either client details or access token'
  } else if (
    _.isEmpty(allValues.clientId) &&
    _.isEmpty(allValues.clientSecret) &&
    _.isEmpty(value)
  ) {
    return 'Please enter client details or access token'
  }
  return error
}

export const validateDomainName = (value) => {
  if (!domainNameRegex.test(value)) {
    return 'Not valid'
  }
  return ''
}

export const createValidator = (
  requiredFields,
  additionalValidation = () => {}
) => {
  return (values, props) => {
    const errors = {}
    requiredFields.forEach((f) => {
      errors[f] = validatePresence(values[f])
    })
    return { ...errors, ...additionalValidation(values, props) }
  }
}

function genericValidator(type, args) {
  var value = args.field
  if (value) {
    switch (type) {
      case 'email':
        return emailValidator(value)
      case 'zipCode':
        return postalCodeValidator(value, args.locale)
      case 'phone':
        return phoneNumberValidator(value)
      default:
        return value
    }
  }
  return REQUIRED
}

export const validateEmail = (value) =>
  genericValidator('email', { field: value })

const emailValidator = (value) =>
  validator.isEmail(value) ? null : 'Please enter a valid email address.'

export const validatePostalCode = (value, locale = 'any') =>
  genericValidator('zipCode', { field: value, locale: locale })

const postalCodeValidator = (value, locale) =>
  validator.isPostalCode(value, locale)
    ? null
    : 'Please enter a valid zip/postal code.'

export const validatePhoneNumber = (value) => {
  return genericValidator('phone', { field: value })
}

const phoneNumberValidator = (value) => {
  return validator.isMobilePhone(value.replace(/^\+/g, ''), 'any', {
    strict: true
  })
    ? null
    : 'Please enter a valid phone number.'
}

export const validateNumeric = (value) => {
  if (value && isNaN(Number(value))) {
    return 'Numeric value required'
  }
  return null
}

export const validateAdwordsId = (value) => {
  if (!value) {
    return REQUIRED
  } else if (!value.match(/^\s*\d{3}-\d{3}-\d{4}\s*$/)) {
    return 'The Customer Id should be in the format xxx-xxx-xxxx'
  }
  return undefined
}
