import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  APPLIED_FILTERS_ACTION,
  APPLY_FILTERS_ACTION,
  AI_OPTIMISATION_INFO,
  CREATE_RULE_ACTION,
  COLUMNS_SELECTION_ACTION,
  EXPORT_PRODUCT_DATA_ACTION,
  IMPORT_PRODUCT_DATA_ACTION,
  DISABLE_SELECTED_ROWS_ACTION,
  ENABLE_SELECTED_ROWS_ACTION,
  EDIT_SELECTED_ROWS_ACTION,
  APPLY_SUGGESTION_ON_SELECTED_ROWS_ACTION,
  DISMISS_SUGGESTION_ON_SELECTED_ROWS_ACTION,
  CLEAR_SELECTED_ROWS_ACTION,
  APPROVE_REJECT_AI_OPTIMISATION_ACTION,
  SINGLE_ROW_DISABLE_ENABLE_ACTION,
  SELECT_ALL_ROWS_ACTION
} from '../common/optimiser_util'
import { getAttributeByColumnName } from '../common/attributes_util'
import ProductAppliedFiltersSection from '../product_applied_filters_section'
import AttributeFiltersSection from '../attribute_filters_section'
import CreateDataRule from './table_actions/create_data_rule'
import ColumnSelectionModal from '../column_selection_modal'
import ExportProductData from './table_actions/export_product_data'
import ImportProductData from './table_actions/import_product_data'
import DisableEnableProducts from './table_actions/disable_enable_products'
import BulkEditor from 'components/feed/optimisation/product_level/bulk_editor'
import ApplyDismissSuggestions from './table_actions/apply_dismiss_suggestions'
import ClearSelectedRows from './table_actions/clear_selected_rows'
import ApproveRejectAIOptimisations from './table_actions/approve_reject_ai_optimisations'
import SelectAllRows from './table_actions/select_all_rows'
import {
  websiteAllAttributesSelector,
  websiteProductReplacementsFiltersSelector
} from 'selectors/product_feeds/replacements'

import './table_actions.scss'

const ITEM_STATUS_COLUMN_HEADER = 'Item Status'

export class TableActions extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    allRowsSelected: PropTypes.bool.isRequired,
    displayedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleColumnsConfigChange: PropTypes.func.isRequired,
    handleOnSaveProductAttributesReplacements: PropTypes.func.isRequired,
    handleClearSelectedRows: PropTypes.func.isRequired
  }

  handleColumnsConfigChange(newConfig) {
    const { handleColumnsConfigChange } = this.props
    handleColumnsConfigChange(newConfig)
  }

  async handleOnSaveProductAttributesReplacements(fetchAttributes = false) {
    const { handleOnSaveProductAttributesReplacements } = this.props
    await handleOnSaveProductAttributesReplacements(fetchAttributes)
  }

  handleClearSelectedRows() {
    const { handleClearSelectedRows } = this.props
    handleClearSelectedRows()
  }

  conditionallyRenderAppliedFilters() {
    const { attributes, filters } = this.props
    return (
      <ProductAppliedFiltersSection
        availableAttributes={attributes}
        appliedFilters={filters}
      />
    )
  }

  conditionallyRenderApplyFilters() {
    const { filters } = this.props
    return <AttributeFiltersSection appliedFilters={filters} />
  }

  conditionallyRenderAIOptimisationInfo() {
    return (
      <div className='ai-optimisation-info'>
        <b>Please note:</b> Your optimisations will not be applied to your feed
        until you approve them
      </div>
    )
  }

  conditionallyRenderCreateRule() {
    const { displayedColumns, filters } = this.props
    return (
      <CreateDataRule
        displayedColumns={displayedColumns}
        appliedFilters={filters}
        handleColumnsConfigChange={this.handleColumnsConfigChange.bind(this)}
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          true
        )}
      />
    )
  }

  conditionallyRenderColumnsSelection() {
    const { displayedColumns, attributes } = this.props
    return (
      <ColumnSelectionModal
        selectedColumns={displayedColumns}
        availableColumns={attributes.filter(
          (attribute) => attribute.columnName !== ITEM_STATUS_COLUMN_HEADER
        )}
        handleColumnsConfigChange={this.handleColumnsConfigChange.bind(this)}
      />
    )
  }

  conditionallyRenderExportProductData() {
    const { displayedColumns } = this.props
    return <ExportProductData displayedColumns={displayedColumns} />
  }

  conditionallyRenderImportProductData() {
    return (
      <ImportProductData
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          true
        )}
      />
    )
  }

  conditionallyRenderSelectAllRows() {
    return <SelectAllRows {...this.props} />
  }

  conditionallyRenderDisableEnableRows(forSingleRow, actionName, disableItems) {
    const { rowData, allRowsSelected } = this.props
    return (
      <DisableEnableProducts
        allRowsSelected={allRowsSelected}
        forSingleRow={forSingleRow}
        actionName={actionName}
        disableItems={disableItems}
        selectedRows={forSingleRow ? [rowData] : rowData}
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this
        )}
      />
    )
  }

  conditionallyRenderEditRows() {
    const { rowData, displayedColumns, attributes, allRowsSelected } =
      this.props
    return (
      <BulkEditor
        allRowsSelected={allRowsSelected}
        selectedRows={rowData}
        attributes={displayedColumns.map((columnName) =>
          getAttributeByColumnName(attributes, columnName)
        )}
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          true
        )}
      />
    )
  }

  conditionallyRenderApplyDismissSuggestions(isApply) {
    const { rowData, displayedColumns, attributes, allRowsSelected } =
      this.props
    return (
      <ApplyDismissSuggestions
        allRowsSelected={allRowsSelected}
        applySuggestions={isApply}
        suggestionBtnText={`${isApply ? 'Apply' : 'Dismiss'} Suggestions`}
        selectedRows={rowData}
        attributes={displayedColumns.map((columnName) =>
          getAttributeByColumnName(attributes, columnName)
        )}
        suggestionSource='manual'
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          true
        )}
      />
    )
  }

  conditionallyRenderClearRows() {
    return (
      <ClearSelectedRows
        handleClearSelectedRows={this.handleClearSelectedRows.bind(this)}
      />
    )
  }

  conditionallyRenderApproveRejectAIOptimisations() {
    const { allRowsSelected, rowData, displayedColumns, attributes } =
      this.props
    return (
      <ApproveRejectAIOptimisations
        allRowsSelected={allRowsSelected}
        selectedRows={rowData}
        attributes={displayedColumns.map((columnName) =>
          getAttributeByColumnName(attributes, columnName)
        )}
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          true
        )}
      />
    )
  }

  render() {
    const { actionName } = this.props
    var actionComponent = null
    switch (actionName) {
      case APPLIED_FILTERS_ACTION:
        actionComponent = this.conditionallyRenderAppliedFilters()
        break
      case APPLY_FILTERS_ACTION:
        actionComponent = this.conditionallyRenderApplyFilters()
        break
      case AI_OPTIMISATION_INFO:
        actionComponent = this.conditionallyRenderAIOptimisationInfo()
        break
      case CREATE_RULE_ACTION:
        actionComponent = this.conditionallyRenderCreateRule()
        break
      case COLUMNS_SELECTION_ACTION:
        actionComponent = this.conditionallyRenderColumnsSelection()
        break
      case EXPORT_PRODUCT_DATA_ACTION:
        actionComponent = this.conditionallyRenderExportProductData()
        break
      case IMPORT_PRODUCT_DATA_ACTION:
        actionComponent = this.conditionallyRenderImportProductData()
        break
      case SELECT_ALL_ROWS_ACTION:
        actionComponent = this.conditionallyRenderSelectAllRows()
        break
      case DISABLE_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderDisableEnableRows(
          false,
          'Disable',
          true
        )
        break
      case ENABLE_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderDisableEnableRows(
          false,
          'Enable',
          false
        )
        break
      case EDIT_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderEditRows()
        break
      case APPLY_SUGGESTION_ON_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderApplyDismissSuggestions(true)
        break
      case DISMISS_SUGGESTION_ON_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderApplyDismissSuggestions(false)
        break
      case CLEAR_SELECTED_ROWS_ACTION:
        actionComponent = this.conditionallyRenderClearRows()
        break
      case APPROVE_REJECT_AI_OPTIMISATION_ACTION:
        actionComponent = this.conditionallyRenderApproveRejectAIOptimisations()
        break
      case SINGLE_ROW_DISABLE_ENABLE_ACTION:
        actionComponent = this.conditionallyRenderDisableEnableRows(
          true,
          '',
          false
        )
        break
      default:
        break
    }

    return actionComponent
  }
}

export const mapStateToProps = (state, props) => ({
  attributes: websiteAllAttributesSelector(state, props),
  filters: websiteProductReplacementsFiltersSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableActions)
)
