import { combineReducers } from 'redux'
import adwords from 'reducers/microsoft/adwords'
import merchantCenter from 'reducers/microsoft/merchant_center'
import user from 'reducers/microsoft/user'

const reducers = {
  adwords,
  merchantCenter,
  user
}

export default combineReducers(reducers)
