import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'
import { performanceWebsiteChannelSelector } from 'selectors/performance'
import { websitePerformanceByBudgetCategoryReportSelector } from 'selectors/google/performance'
import {
  FETCH_PERFORMANCE_BY_DAY_REQUEST,
  FETCH_PERFORMANCE_BY_DAY_SUCCESS,
  FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST,
  FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS
} from 'actions/performance/general'

export const FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_REQUEST =
  'FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_REQUEST'
export const FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_SUCCESS =
  'FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_SUCCESS'

export const FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_REQUEST =
  'FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_REQUEST'
export const FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_RESPONSE =
  'FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_RESPONSE'

const fetchWebsiteDailyPerformanceRequest = (
  accountType,
  websiteId,
  channel,
  dateRange
) => ({
  type: FETCH_PERFORMANCE_BY_DAY_REQUEST,
  accountType,
  id: websiteId,
  channel,
  dateRange
})

const fetchWebsiteDailyPerformanceSuccess = (
  accountType,
  websiteId,
  channel,
  dateRange,
  dailyAccountPerformance
) => ({
  type: FETCH_PERFORMANCE_BY_DAY_SUCCESS,
  accountType,
  id: websiteId,
  channel,
  dateRange,
  ...dailyAccountPerformance
})

const compareDateRange = (incoming, existing) => {
  var incomingStart = JSON.stringify(incoming.startDate)
    .slice(1, 11)
    .replace(/-/g, '')
  var incomingEnd = JSON.stringify(incoming.endDate)
    .slice(1, 11)
    .replace(/-/g, '')
  return incomingStart === existing.start && incomingEnd === existing.end
}

const checkPerformanceReportInRedux = (
  websiteId,
  getState,
  dateRange,
  channel
) => {
  var reportInStore =
    performanceWebsiteChannelSelector(websiteId, channel)(getState()) || {}
  return (
    Object.keys(reportInStore).length !== 0 &&
    reportInStore.constructor === Object &&
    compareDateRange(dateRange, reportInStore.dateRange)
  )
}

export const fetchWebsiteDailyPerformance =
  (accountType, websiteId, channel, dateRange) =>
  async (dispatch, getState) => {
    if (
      checkPerformanceReportInRedux(websiteId, getState, dateRange, channel)
    ) {
      return
    }
    dispatch(
      fetchWebsiteDailyPerformanceRequest(
        accountType,
        websiteId,
        channel,
        dateRange
      )
    )
    var status = 202
    var response
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }

    while (status === 202) {
      response = await $.ajax({
        method: 'GET',
        url: `/ad_champion/websites/${websiteId}/performance/generate_daily_report`,
        data: snakeCaseKeys({ channel, ...dateRange }, { deep: true }),
        dataType: 'json',
        statusCode
      })
      if (status === 202) {
        await sleep(1000)
      }
    }

    dispatch(
      fetchWebsiteDailyPerformanceSuccess(
        accountType,
        websiteId,
        channel,
        dateRange,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const fetchWebsitePerformanceByBudgetCategoriesRequest = (
  websiteId,
  dateRange,
  page,
  perPage
) => ({
  type: FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_REQUEST,
  dateRange,
  websiteId,
  page,
  perPage
})

const fetchWebsitePerformanceByBudgetCategoriesSuccess = (
  websiteId,
  dateRange,
  page,
  perPage,
  response
) => ({
  type: FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_SUCCESS,
  websiteId,
  dateRange,
  page,
  perPage,
  ...response
})

const checkWebsitePerformanceByBudgetCategoriesInRedux = (
  websiteId,
  getState,
  dateRange,
  page,
  perPage
) => {
  var reportInStore = websitePerformanceByBudgetCategoryReportSelector(
    websiteId
  )(getState())
  return (
    reportInStore.budgetCategoryReports &&
    reportInStore.budgetCategoryReports.length > 0 &&
    reportInStore.page === page &&
    reportInStore.perPage === perPage &&
    compareDateRange(dateRange, reportInStore.dateRange)
  )
}

export const fetchWebsitePerformanceByBudgetCategories =
  (websiteId, dateRange, page, perPage, force = false) =>
  async (dispatch, getState) => {
    if (
      !force &&
      checkWebsitePerformanceByBudgetCategoriesInRedux(
        websiteId,
        getState,
        dateRange,
        page,
        perPage
      )
    ) {
      return
    }
    dispatch(
      fetchWebsitePerformanceByBudgetCategoriesRequest(
        websiteId,
        dateRange,
        page,
        perPage
      )
    )

    var status = 202
    var response
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }

    while (status === 202) {
      response = await $.ajax({
        method: 'GET',
        url: `/ad_champion/websites/${websiteId}/performance/show_website_performance_by_budget_categories?page=${page}&per_page=${perPage}`,
        data: { ...snakeCaseKeys(dateRange, { deep: true }) },
        dataType: 'json',
        statusCode
      })
      if (status === 202) {
        await sleep(1000)
      }
    }

    dispatch(
      fetchWebsitePerformanceByBudgetCategoriesSuccess(
        websiteId,
        dateRange,
        page,
        perPage,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const fetchWebsitePerformanceByCampaignTypeRequest = (
  accountType,
  websiteId,
  channel
) => ({
  type: FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST,
  accountType,
  id: websiteId,
  channel
})

const fetchWebsitePerformanceByCampaignTypeSuccess = (
  accountType,
  websiteId,
  channel,
  campaignPerformance
) => ({
  type: FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS,
  accountType,
  id: websiteId,
  channel,
  ...campaignPerformance
})

export const fetchWebsitePerformanceByCampaignType =
  (accountType, websiteId, channel, dateRange) => async (dispatch) => {
    dispatch(
      fetchWebsitePerformanceByCampaignTypeRequest(
        accountType,
        websiteId,
        channel
      )
    )
    var status = 202
    var response
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }

    while (status === 202) {
      response = await $.ajax({
        url: `/ad_champion/websites/${websiteId}/performance/show_website_performance_by_campaign_types`,
        data: snakeCaseKeys({ channel, ...dateRange }, { deep: true }),
        dataType: 'json',
        statusCode
      })
      if (status === 202) {
        await sleep(1000)
      }
    }

    dispatch(
      fetchWebsitePerformanceByCampaignTypeSuccess(
        accountType,
        websiteId,
        channel,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

var status = 202
const statusCode = {
  200: () => {
    status = 200
  },
  202: () => {
    status = 202
  }
}

const fetchWebsitePerformanceByFeedopsCampaignsRequest = (
  websiteId,
  channel
) => ({
  type: FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_REQUEST,
  websiteId,
  channel
})

const fetchWebsitePerformanceByFeedopsCampaignsResponse = (
  websiteId,
  channel,
  campaignPerformance
) => ({
  type: FETCH_WEBSITE_PERFORMANCE_BY_FEEDOPS_CAMPAIGNS_RESPONSE,
  websiteId,
  channel,
  ...campaignPerformance
})

export const fetchWebsitePerformanceByFeedopsCampaigns =
  (websiteId, channel, dateRange) => async (dispatch) => {
    dispatch(
      fetchWebsitePerformanceByFeedopsCampaignsRequest(websiteId, channel)
    )

    status = 202
    var response

    while (status === 202) {
      response = await $.ajax({
        url: `/ad_champion/websites/${websiteId}/feed_ops_performance/show_website_performance_by_feedops_campaigns`,
        data: snakeCaseKeys({ channel, ...dateRange }, { deep: true }),
        dataType: 'json',
        statusCode
      })
      if (status === 202) {
        await sleep(1000)
      }
    }

    dispatch(
      fetchWebsitePerformanceByFeedopsCampaignsResponse(
        websiteId,
        channel,
        camelCaseKeys(response, { deep: true })
      )
    )
  }
