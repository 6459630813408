import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import QueryString from 'query-string'
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCardTitle,
  MDBBtn,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem
} from 'mdbreact'
import InventoryDetails from './store_connection/inventory_details'
import ConnectionOptionsModal from './store_connection/connection_options_modal'
import PlatformIcon from './store_connection/platform_icon'
import { fetchInventoryDetail } from 'actions/inventories'
import { fetchWebsiteDetail } from 'actions/websites'
import { inventoryDetailSelector } from 'selectors/inventory'
import {
  websiteIdSelector,
  websiteDetailSelector,
  websiteInventoryIdSelector
} from 'selectors/websites'
import { isEmbeddedAppSelector } from 'selectors/app'
import { PLATFORM_GOOGLE_SHOPPING_FEED } from 'util/supported_platforms'

export const EDIT_CONNECTION_DETAILS = 'Edit Connection Details'
export const CONFIGURE_ATTRIBUTE_MAPPINGS = 'Configure Attribute Mappings'
export const SELECT_WEBSITE_CATEGORIES = 'Select Website Categories'

export class WebsiteStoreConnection extends Component {
  static propTypes = {
    initialReconcilingCheck: PropTypes.bool.isRequired,
    websiteId: PropTypes.number.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    websiteDetail: PropTypes.object.isRequired,
    inventoryId: PropTypes.number.isRequired,
    inventoryDetail: PropTypes.object.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    fetchInventoryDetail: PropTypes.func.isRequired
  }

  static defaultProps = {
    initialReconcilingCheck: false
  }

  constructor(props) {
    super(props)
    this.state = { connectionDetailsModalOpen: false, modalTitle: '' }
  }

  async componentDidMount() {
    await this.fetchWebsiteDetail()
    await this.fetchInventoryDetail()

    const { location } = this.props
    const { search } = location
    const mapAttributes = QueryString.parse(search).map_attributes
    if (mapAttributes && mapAttributes === 'true') {
      this.setState({
        connectionDetailsModalOpen: true,
        modalTitle: CONFIGURE_ATTRIBUTE_MAPPINGS
      })
    }
  }

  async componentDidUpdate(prevProps) {
    const { inventoryId, websiteId } = this.props

    if (prevProps.websiteId !== websiteId) {
      this.fetchWebsiteDetail()
    }

    if (prevProps.inventoryId !== inventoryId) {
      this.fetchInventoryDetail()
    }
  }

  async fetchWebsiteDetail() {
    const { websiteId, fetchWebsiteDetail } = this.props
    if (websiteId > 0) {
      await fetchWebsiteDetail(websiteId)
    }
  }

  async fetchInventoryDetail() {
    const { inventoryId, fetchInventoryDetail } = this.props
    if (inventoryId > 0) {
      await fetchInventoryDetail(inventoryId)
    }
  }

  handleConnectionOptionsModal(openModal, title = '') {
    this.setState({ connectionDetailsModalOpen: openModal, modalTitle: title })
  }

  renderConnectionOptionsModal() {
    const { websiteId, websiteDetail, inventoryId } = this.props
    const { platform } = websiteDetail
    const { connectionDetailsModalOpen, modalTitle } = this.state

    return (
      <ConnectionOptionsModal
        isModalOpen={connectionDetailsModalOpen}
        title={modalTitle}
        websiteId={websiteId}
        platform={platform}
        inventoryId={inventoryId}
        closeModal={this.handleConnectionOptionsModal.bind(this)}
      />
    )
  }

  isStoreConnected() {
    const { websiteDetail, inventoryId } = this.props
    const { platform } = websiteDetail

    return platform || inventoryId > 0
  }

  renderStoreDetails() {
    const { websiteId, inventoryId } = this.props
    return <InventoryDetails websiteId={websiteId} inventoryId={inventoryId} />
  }

  renderConnectLink(path) {
    return (
      <Link to={path} className='btn-connect-container'>
        <MDBBtn>Connect</MDBBtn>
      </Link>
    )
  }

  genCardTitle() {
    const { initialReconcilingCheck, websiteDetail } = this.props
    const { url } = websiteDetail

    return url.includes('demowebsite')
      ? initialReconcilingCheck
        ? 'Loading a Demo Website'
        : 'Demo Website Inventory'
      : 'eCommerce Store'
  }

  renderStoreColumn() {
    const { websiteDetail } = this.props
    const { platform } = websiteDetail

    return (
      <MDBCol>
        <MDBCardTitle>{this.genCardTitle()}</MDBCardTitle>
        <div className='account-logo-container'>
          <PlatformIcon platformName={platform || 'none'} />
        </div>
        {this.isStoreConnected()
          ? this.renderStoreDetails()
          : this.renderConnectLink('connect_store')}
      </MDBCol>
    )
  }

  renderConnectionOptionsBtn(disalbed, title) {
    return (
      <MDBListGroupItem key={title}>
        <MDBBtn
          disabled={disalbed}
          onClick={this.handleConnectionOptionsModal.bind(this, true, title)}
        >
          {title}
        </MDBBtn>
      </MDBListGroupItem>
    )
  }

  renderStoreConnectionOptionsColumn() {
    const { isEmbeddedApp, inventoryDetail } = this.props
    const { feedChampionReference, feedChampionPlatform } = inventoryDetail

    return (
      <MDBCol className='connection-options-col'>
        <MDBCardTitle />
        <MDBCardText>
          <MDBListGroup tag='span'>
            {this.renderConnectionOptionsBtn(
              isEmbeddedApp,
              EDIT_CONNECTION_DETAILS
            )}
            {this.renderConnectionOptionsBtn(
              !!!(
                feedChampionReference &&
                feedChampionPlatform !== PLATFORM_GOOGLE_SHOPPING_FEED
              ),
              CONFIGURE_ATTRIBUTE_MAPPINGS
            )}
            {this.renderConnectionOptionsBtn(
              !!!feedChampionReference,
              SELECT_WEBSITE_CATEGORIES
            )}
          </MDBListGroup>
        </MDBCardText>
      </MDBCol>
    )
  }

  render() {
    const { initialReconcilingCheck } = this.props

    return (
      <>
        {!initialReconcilingCheck && this.renderConnectionOptionsModal()}
        <MDBCard className='channel-accounts-card'>
          <MDBCardBody>
            <MDBRow>
              {this.renderStoreColumn()}
              {!initialReconcilingCheck &&
                this.isStoreConnected() &&
                this.renderStoreConnectionOptionsColumn()}
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdSelector(state, props)
  return {
    websiteId: websiteIdSelector(state, props),
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    websiteDetail: websiteDetailSelector(state, props),
    inventoryId,
    inventoryDetail: inventoryDetailSelector(inventoryId)(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchWebsiteDetail, fetchInventoryDetail }, dispatch)

const WebsiteStoreConnectionWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteStoreConnection)

export default withRouter(WebsiteStoreConnectionWithRedux)
