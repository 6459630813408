import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { fetchInventoryAttributes } from 'actions/product_feeds/replacements'
import { previewRules } from 'actions/inventories/rules'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress'
import ModalBodyContainer from './preview_rules_modal/modal_body_container'
import ModalFooterContainer from './preview_rules_modal/modal_footer_container'
import {
  websiteIdSelector,
  websiteFeedPerChannelSelector
} from 'selectors/websites'
import { enabledVendorsSelector } from 'selectors/inventories/vendors'
import { websiteRulesSelector } from 'selectors/inventories/rules'

import './preview_rules_modal.scss'

export class PreviewRulesModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    websiteId: PropTypes.number.isRequired,
    feedsPerChannel: PropTypes.object.isRequired,
    vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteRules: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    onModalStateChange: PropTypes.func.isRequired,
    fetchInventoryAttributes: PropTypes.func.isRequired,
    previewRules: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      channel: '',
      searchText: '',
      isFetching: false
    }
  }

  async componentDidMount() {
    const { websiteId, fetchInventoryAttributes } = this.props
    await fetchInventoryAttributes(websiteId)
  }

  async componentDidUpdate(prevProps) {
    const { isOpen, vendors } = this.props
    if (isOpen && !prevProps.isOpen) {
      this.setState(
        { isLoading: true, channel: vendors[0].channel },
        async () => await this.previewRules()
      )
    }
  }

  computeFeedId() {
    const { channel } = this.state
    const { feedsPerChannel } = this.props
    const feeds = feedsPerChannel[channel] || []
    const feed = feeds[0]
    return feed ? feed.id : null
  }

  async previewRules() {
    const { searchText } = this.state
    const { websiteId, formValues, previewRules } = this.props
    const feedId = this.computeFeedId()
    const { rules } = formValues
    const filteredRules = [...rules].filter(
      (rule) => rule.draftStatus !== 'Deleted'
    )

    if (filteredRules.length > 0) {
      this.setState({ isFetching: true })
      await previewRules(websiteId, feedId, filteredRules, searchText)
      this.setState({ isLoading: false, isFetching: false })
    }
  }

  renderModalHeader() {
    return (
      <ModalHeader>
        Preview Your Feed Rules
        <Button onClick={() => this.props.onModalStateChange()}>
          <i className='fa fa-times' />
        </Button>
      </ModalHeader>
    )
  }

  onChannelChanged(channel) {
    if (this.state.channel !== channel) {
      this.setState({ channel }, () => this.previewRules())
    }
  }

  onSearchTextChanged(searchText) {
    if (this.state.searchText !== searchText) {
      this.setState({ searchText }, () => this.previewRules())
    }
  }

  conditionallyRenderModalBody() {
    const { isLoading, isFetching, channel, searchText } = this.state
    const { vendors } = this.props

    return isLoading ? (
      <div className='loading-preview'>
        <CircularProgress />
        <br />
        Generating preview
      </div>
    ) : (
      <ModalBodyContainer
        isFetching={isFetching}
        channel={channel}
        searchText={searchText}
        vendors={vendors}
        onChannelChanged={this.onChannelChanged.bind(this)}
        onSearchTextChanged={this.onSearchTextChanged.bind(this)}
      />
    )
  }

  render() {
    const { isLoading, channel } = this.state
    const { isOpen } = this.props

    return (
      <Modal
        size={`${isLoading ? 'md' : 'xl'}`}
        isOpen={isOpen}
        centered={true}
        className={`preview-rules-modal ${!isLoading && 'preview-modal'} `}
        toggle={() => this.props.onModalStateChange()}
      >
        {!isLoading && this.renderModalHeader()}
        <ModalBody>{this.conditionallyRenderModalBody()}</ModalBody>
        {!isLoading && (
          <ModalFooterContainer
            channel={channel}
            onModalStateChange={() => this.props.onModalStateChange()}
          />
        )}
      </Modal>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedsPerChannel: websiteFeedPerChannelSelector(state, props),
  vendors: enabledVendorsSelector(state, props),
  websiteRules: websiteRulesSelector(state, props) || {},
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInventoryAttributes, previewRules }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewRulesModal)
)
