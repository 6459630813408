import camelCase from 'camelcase'
import { createSelector } from 'reselect'
import _ from 'lodash'
import QueryString from 'query-string'
import { masterAccountListSelector, accountListSelector } from 'selectors/user'
import { channelSelector } from 'selectors/channel'
import { CHANNEL_ALL } from 'util/supported_channels'

export const websitesSelector = (state, _props) => state.websites || {}

export const websiteListSelector = createSelector(
  [websitesSelector],
  (websites) => _.sortBy(websites.fullList, ['name']) || []
)

export const budgetCategoryListSelector = createSelector(
  [websitesSelector],
  (websites) => websites.budgetCategoryFullList || []
)

export const websiteIdSelector = (_state, props) => {
  var id = parseInt((props && props.match.params.websiteId) || '0', 10)
  return !id || isNaN(id) ? 0 : id
}

const websiteDetailsSelector = createSelector(
  [websitesSelector],
  (websites) => websites.details || {}
)

export const websiteDetailSelector = createSelector(
  [websiteIdSelector, websiteDetailsSelector],
  (websiteId, details) => details[websiteId] || {}
)

export const websiteWatchingSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.watching || false
)

export const websitePrimaryFeedsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.primaryFeeds || []
)

export const websitePrimaryFeedCountSelector = createSelector(
  [websitePrimaryFeedsSelector],
  (feeds) => (feeds || []).length
)

export const websiteAccountIdSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.accountId || 0
)

export const websiteDefaultAdwordsIdSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.defaultAdwordsId || ''
)

export const websiteDefaultMicrosoftIdSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.defaultMicrosoftId || ''
)

export const websiteAdwordsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.adwords) || {}
)

export const websiteAnalyticsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.analytics) || {}
)

export const websiteMerchantCenterSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.merchantCenter) || {}
)

export const merchantCenterSetupCompleteSelector = createSelector(
  [websiteDetailSelector],
  (detail) => {
    return detail.merchantCenter.merchantCenterAccountId &&
      detail.merchantCenter.countryOfSale
      ? true
      : false
  }
)

export const websiteAdvertisingFeedIdsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.advertisingFeedIds) || []
)

export const websiteMicrosoftMerchantCenterSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.microsoftMerchantCenter) || {}
)

export const websiteMicrosoftAdwordsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.microsoftAds) || {}
)

export const websiteInventoryIdsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.inventoryIds) || []
)

export const websiteRetailInventoryIdsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.retailInventoryIds) || []
)

export const websiteBuildsIdsSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.buildIds) || []
)

export const websitePlanTypeSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.planType) || 'free'
)

export const websiteUrlSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.url || ''
)

export const websiteNameSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.name || ''
)

export const websiteFeedPerChannelSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.feedPerChannel) || {}
)

export const feedIdSelector = (_state, props) => {
  if (props.location) {
    var feedId = parseInt(QueryString.parse(props.location.search).feedId, 10)
    return isNaN(feedId) ? null : feedId
  } else {
    throw new Error('Missing location prop')
  }
}

export const websiteValidFeedsByChannelSelector = createSelector(
  [websiteFeedPerChannelSelector, channelSelector],
  (feedPerChannel, channel) => {
    var validFeeds = []
    channel = camelCase(channel)
    if (channel === CHANNEL_ALL) {
      Object.values(feedPerChannel).forEach((feeds) => {
        validFeeds = validFeeds.concat(feeds)
      })
    } else {
      validFeeds = feedPerChannel[channel] || []
    }
    return validFeeds || []
  }
)

export const websiteFeedByInventoryIdAndChannelSelector = (
  inventoryId,
  channel
) =>
  createSelector([websiteFeedPerChannelSelector], (feedPerChannel) => {
    channel = camelCase(channel)
    return (
      (feedPerChannel[channel] &&
        feedPerChannel[channel].find((f) => f.inventoryId === inventoryId)) ||
      {}
    )
  })

export const websiteFeedIdByChannelSelector = createSelector(
  [websiteValidFeedsByChannelSelector, feedIdSelector],
  (validFeeds, feedId) => {
    if (validFeeds && validFeeds.length > 0) {
      if (validFeeds.filter((f) => f.id === feedId).length > 0) {
        return feedId
      } else if (validFeeds && validFeeds.length > 0) {
        return validFeeds[0].id
      }
    }
    return feedId || 0
  }
)

export const websiteFeedDetailSelector = createSelector(
  [
    websiteFeedPerChannelSelector,
    websiteFeedIdByChannelSelector,
    channelSelector
  ],
  (feedPerChannel, feedId, channel) => {
    var detail = {}
    channel = camelCase(channel)
    if (feedPerChannel[channel] && feedPerChannel[channel].length > 0) {
      detail = feedPerChannel[channel].find((f) => f.id === feedId) || {}
    }
    return detail
  }
)

export const websiteInventoriesSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.inventories || []
)

export const websiteInventoryIdSelector = (state, props) => {
  const inventories = websiteInventoriesSelector(state, props)
  const defaultInventoryId = inventories[0] ? inventories[0].id : 0
  if (props.location) {
    var { inventoryId } = QueryString.parse(props.location.search)
    inventoryId = parseInt(inventoryId, 10)
    return isNaN(inventoryId) ? defaultInventoryId : inventoryId
  } else {
    throw new Error('Missing location prop')
  }
}

export const websiteInventoryIdWithDefaultSelector = createSelector(
  [websiteInventoriesSelector, websiteInventoryIdSelector],
  (inventories, inventoryId) =>
    inventoryId ? inventoryId : inventories[0] ? inventories[0].id : 0
)

export const websiteRetailInventoryDataSelector = createSelector(
  [websiteDetailSelector],
  (detail) => (detail && detail.retailInventoryData) || {}
)

export const constructedWebsiteListSelector = createSelector(
  [masterAccountListSelector, accountListSelector, websiteListSelector],
  (masterAccountList, accountList, websitesList) => {
    return accountTreeSelector(
      masterAccountList,
      accountList,
      websitesList,
      false
    )
  }
)

export const accountsWithContactDetailsListSelector = createSelector(
  [masterAccountListSelector, accountListSelector, websiteListSelector],
  (masterAccountList, accountList, websitesList) => {
    return accountTreeSelector(
      masterAccountList,
      accountList,
      websitesList,
      true
    )
  }
)

const accountTreeSelector = (
  masterAccountList,
  accountList,
  websitesList,
  filterNoContactDetails
) => {
  var accountAndWebsiteIndex = constructAccountList(
    accountList,
    websitesList,
    filterNoContactDetails
  )
  var accountIndex = accountAndWebsiteIndex[0]
  var websiteIndex = accountAndWebsiteIndex[1]
  var finalList = masterAccountList
    .map((ma) => {
      var accounts = ma.accountIds
        .map((id) => {
          return accountIndex[id]
        })
        .filter((a) => a && a.id)

      ma.accountIds.forEach((id) => delete accountIndex[id])
      return (!filterNoContactDetails || !ma.hasContactDetails) &&
        accounts.length === 0
        ? null
        : {
            ...ma,
            accounts,
            disabled: filterNoContactDetails && !ma.hasContactDetails
          }
    })
    .filter((ma) => ma !== null)

  if (Object.keys(accountIndex).length !== 0) {
    finalList.push({
      id: 0,
      label: 'accounts',
      disabled: true,
      accounts: Object.keys(accountIndex).map((key) => {
        return accountIndex[key]
      })
    })
  }

  if (Object.keys(websiteIndex).length !== 0) {
    finalList.push({
      id: 1,
      label: 'websites',
      disabled: true,
      websites: Object.keys(websiteIndex).map((key) => {
        return websiteIndex[key]
      })
    })
  }

  const sortedList = _.sortBy(finalList, ['label'])
  _.forEach(sortedList, (item) => {
    item.accounts = _.orderBy(item.accounts, ['label'])
    item.accounts = _.map(item.accounts, (account) => {
      account.websites = _.orderBy(account.websites, ['name'])
      return account
    })
  })

  return sortedList
}

const constructAccountList = (
  accountList,
  websitesList,
  filterNoContactDetails
) => {
  var websiteIndex = {}
  websitesList.forEach((w) => {
    websiteIndex[w.id] = w
  })

  var accountIndex = {}
  accountList
    .map((a) => {
      var websites = a.websiteIds
        .map((id) => websiteIndex[id])
        .filter((w) => w)
        .filter((w) => !filterNoContactDetails || w.hasContactDetails)
      a.websiteIds.forEach((id) => delete websiteIndex[id])
      return (!filterNoContactDetails || !a.hasContactDetails) &&
        websites.length === 0
        ? null
        : {
            ...a,
            websites,
            disabled: filterNoContactDetails && !a.hasContactDetails
          }
    })
    .filter((a) => a !== null)
    .forEach((a) => {
      accountIndex[a.id] = a
    })
  return [accountIndex, websiteIndex]
}

export const listSelector = (accountType, id) =>
  createSelector(
    [
      masterAccountListSelector,
      accountListSelector,
      websiteListSelector,
      budgetCategoryListSelector
    ],
    (masterAccountList, accountList, websitesList, budgetCategoriesList) => {
      switch (accountType) {
        case 'budget_categories':
          return currentListFromBudgetCategory(
            id,
            masterAccountList,
            accountList,
            websitesList,
            budgetCategoriesList
          )
        case 'websites':
          return currentListFromWebsite(
            id,
            masterAccountList,
            accountList,
            websitesList
          )
        case 'accounts':
          return currentListFromAccount(id, masterAccountList, accountList)
        case 'master_accounts':
          return currentListFromMasterAccount(id, masterAccountList)
        default:
          return
      }
    }
  )

const currentListFromBudgetCategory = (
  id,
  masterAccountList,
  accountList,
  websitesList,
  budgetCategoriesList
) => {
  const website = websitesList.find((w) => w.budgetCategoryIds.includes(id))

  const account = accountList.find((a) =>
    a.websiteIds.includes((website || {}).id)
  )
  return {
    budgetCategory: budgetCategoriesList.find((bc) => bc.id === id),
    website,
    account,
    masterAccount: masterAccountList.find((ma) =>
      ma.accountIds.includes((account || {}).id)
    )
  }
}

const currentListFromWebsite = (
  id,
  masterAccountList,
  accountList,
  websitesList
) => {
  const account = accountList.find((a) => a.websiteIds.includes(id))
  return {
    website: websitesList.find((w) => w.id === id),
    account,
    masterAccount: masterAccountList.find((ma) =>
      ma.accountIds.includes((account || {}).id)
    )
  }
}

const currentListFromAccount = (id, masterAccountList, accountList) => ({
  account: accountList.find((a) => a.id === id),
  masterAccount: masterAccountList.find((ma) => ma.accountIds.includes(id))
})

const currentListFromMasterAccount = (id, masterAccountList) => ({
  masterAccount: masterAccountList.find((ma) => ma.id === id)
})

export const feedopsShoppingSelector = createSelector(
  [websiteDetailSelector],
  (detail) => detail.feedopsShopping || {}
)

export const feedopsShoppingPublishedSelector = createSelector(
  [feedopsShoppingSelector],
  (feedops) => feedops.campaignPublished || false
)
