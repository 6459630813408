import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MDBBtn, MDBIcon } from 'mdbreact'

export class CardNavButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    float: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired
  }

  static defaultProps = {
    cssClass: ''
  }

  pushPath(destination) {
    const { location } = this.props
    this.props.history.push(destination, { prevPath: location.pathname })
  }

  render() {
    const { label, destination, float, cssClass, showCopyBtn } = this.props
    const isAbsolutePath =
      destination.startsWith('http://') || destination.startsWith('https://')
    const btnProps = isAbsolutePath
      ? { href: destination, target: '_blank' }
      : { onClick: this.pushPath.bind(this, destination) }

    return (
      <>
        <MDBBtn
          className={`btn-card-nav float-${float} ${cssClass}`}
          {...btnProps}
        >
          {label}{' '}
          {showCopyBtn ? null : (
            <MDBIcon icon={isAbsolutePath ? 'external-link' : 'angle-right'} />
          )}
        </MDBBtn>
      </>
    )
  }
}

export default withRouter(CardNavButton)
