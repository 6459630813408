import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_API_IMPORT_SOURCE_HEADERS_REQUEST =
  'FETCH_API_IMPORT_SOURCE_HEADERS_REQUEST'
export const FETCH_API_IMPORT_SOURCE_HEADERS_RESPONSE =
  'FETCH_API_IMPORT_SOURCE_HEADERS_RESPONSE'
export const SAVE_API_IMPORT_PRODUCTS_REQUEST =
  'SAVE_API_IMPORT_PRODUCTS_REQUEST'
export const SAVE_API_IMPORT_PRODUCTS_RESPONSE =
  'SAVE_API_IMPORT_PRODUCTS_RESPONSE'

const fetchApiImportSourceHeadersRequest = (websiteId, accessSettings) => ({
  type: FETCH_API_IMPORT_SOURCE_HEADERS_REQUEST,
  websiteId,
  accessSettings
})

const fetchApiImportSourceHeadersResponse = (
  websiteId,
  accessSettings,
  response
) => ({
  type: FETCH_API_IMPORT_SOURCE_HEADERS_RESPONSE,
  websiteId,
  accessSettings,
  ...response
})

const getApiImportSourceHeaders = async (websiteId, accessSettings) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const settings = snakeCaseKeys({ ...accessSettings })
  const url = `/ad_champion/websites/${websiteId}/replacements/import_products/fetch_api_headers?access_settings=${JSON.stringify(
    settings
  )}`
  var response = await $.ajax({ method: 'GET', url, statusCode })
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax({ method: 'GET', url, statusCode })
  }

  return camelCaseKeys(response, { deep: true })
}

export const fetchApiImportSourceHeaders =
  (websiteId, accessSettings) => async (dispatch) => {
    dispatch(fetchApiImportSourceHeadersRequest(websiteId, accessSettings))

    const camelCaseResponse = await getApiImportSourceHeaders(
      websiteId,
      accessSettings
    )

    dispatch(
      fetchApiImportSourceHeadersResponse(
        websiteId,
        accessSettings,
        camelCaseResponse
      )
    )

    return camelCaseResponse
  }

const saveApiImportProductsRequest = (
  websiteId,
  accessSettings,
  attributesMapping,
  importSettings
) => ({
  type: SAVE_API_IMPORT_PRODUCTS_REQUEST,
  websiteId,
  accessSettings,
  attributesMapping,
  importSettings
})

const saveApiImportProductsResponse = (
  websiteId,
  accessSettings,
  attributesMapping,
  importSettings,
  response
) => ({
  type: SAVE_API_IMPORT_PRODUCTS_RESPONSE,
  websiteId,
  accessSettings,
  attributesMapping,
  importSettings,
  ...response
})

const apiImportPorducts = async (
  websiteId,
  accessSettings,
  attributesMapping,
  importSettings
) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const data = snakeCaseKeys({
    accessSettings: JSON.stringify(snakeCaseKeys(accessSettings)),
    attributesMapping: JSON.stringify(snakeCaseKeys(attributesMapping)),
    importSettings: JSON.stringify(snakeCaseKeys(importSettings))
  })
  const url = `/ad_champion/websites/${websiteId}/replacements/import_products/import_api_products`
  var response = await $.ajax({ method: 'POST', url, data, statusCode })
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax({ method: 'POST', url, data, statusCode })
  }

  return camelCaseKeys(response, { deep: true })
}

export const saveApiImportProducts =
  (websiteId, accessSettings, attributesMapping, importSettings) =>
  async (dispatch) => {
    var args = [websiteId, accessSettings, attributesMapping, importSettings]
    dispatch(saveApiImportProductsRequest(...args))

    const camelCaseResponse = await apiImportPorducts(...args)

    dispatch(saveApiImportProductsResponse(...args, camelCaseResponse))
    return camelCaseResponse
  }
