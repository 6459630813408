import _ from 'lodash'

const urlRegex = /(https?:\/\/)?(www.)?/g

export const abbreviateUrls = (label) => (label || '').replace(urlRegex, '')

export const scrubDuplicate = (label) =>
  label.indexOf(' dup_') !== -1 ? label.slice(0, label.indexOf(' dup_')) : label

const dynamicFieldRegex = /\{(.*?)\}/g

const getFieldValue = (rowData, dynamicField, allowOverride = true) => {
  var replacementValue = allowOverride
    ? rowData.override[dynamicField] || rowData.raw[dynamicField]
    : rowData.raw[dynamicField]
  return replacementValue
}

const CATEGORY_FIELDS = [
  'firstCategory',
  'secondCategory',
  'thirdCategory',
  'fourthCategory',
  'fifthCategory'
].reverse()

const getProductTypeValue = (rowData) => {
  var replacementValue = null
  CATEGORY_FIELDS.forEach((cat) => {
    if (!replacementValue || replacementValue === '') {
      replacementValue = getFieldValue(rowData, cat)
    }
  })
  return replacementValue
}

export const replaceDynamicVariables = (attributeName, rowData, template) => {
  var value = template || ''

  var matches = value.matchAll(dynamicFieldRegex)
  for (const match of matches) {
    var matchedText = match[0]
    var dynamicField = _.camelCase(match[1])
    var replacementValue = null
    if (dynamicField === 'productType') {
      replacementValue = getProductTypeValue(rowData)
    } else if (Object.keys(rowData.raw).includes(dynamicField)) {
      replacementValue = getFieldValue(
        rowData,
        dynamicField,
        dynamicField.toLowerCase() !== attributeName.toLowerCase()
      )
    } else {
      replacementValue = match[1]
    }
    value = value.replace(matchedText, replacementValue || '')
  }

  return value.replace(/\s+/g, ' ')
}

export const ADD_TEXT_HEADER = 'Add Text'

export const templateToFieldArray = (template, allAvailableFields) => {
  var fields = []
  if (!_.isEmpty(template)) {
    template.split(' ').forEach((field) => {
      const strippedField = field.replace(/[{}]/g, '')
      if (
        field.includes('{') &&
        field.includes('}') &&
        allAvailableFields.includes(strippedField)
      ) {
        fields.push({
          name: strippedField,
          value: strippedField
        })
      } else {
        var lastField = fields[fields.length - 1]
        if (!_.isEmpty(lastField) && lastField.name === ADD_TEXT_HEADER) {
          lastField.value += ` ${field}`
        } else {
          fields.push({
            name: ADD_TEXT_HEADER,
            value: field
          })
        }
      }
    })
  }

  return fields
}

export const fieldArrayToTemplate = (fieldArray, allAvailableFields) => {
  return fieldArray
    .map((p) =>
      allAvailableFields.includes(p.name) ? `{${p.value}}` : p.value
    )
    .join(' ')
}
