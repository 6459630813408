import { FETCH_KEYWORD_SUGGESTIONS_SUCCESS } from 'actions/keyword_suggestions'

const defaultState = {
  count: 0,
  values: [],
  searchText: '',
  page: 1,
  perPage: 20,
  orderBy: 'search_term',
  ascending: true
}
const initialState = {
  unprocessed: defaultState,
  exact: defaultState,
  negative: defaultState,
  undecided: defaultState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KEYWORD_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        [action.actionFilter]: {
          count: action.count,
          values: action.values || [],
          searchText: action.searchText,
          page: action.page,
          perPage: action.perPage,
          orderBy: action.orderBy,
          ascending: action.ascending
        }
      }
    default:
      return state
  }
}
