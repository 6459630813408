import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBSwitch, MDBInput } from 'mdbreact'
import ReactTooltip from 'react-tooltip'
import InputError from 'components/ad_champion/common/input_error'

export default class CategoryRewriteRow extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    formErrors: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    handleRewriteChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { rewrite: false, value: '', error: '', initialValue: '' }
  }

  componentDidMount() {
    this.updateInitialState(true)
  }

  componentDidUpdate(prevProps) {
    const { category, formValues, formErrors } = this.props
    if (
      !_.isEqual(prevProps.category, category) ||
      !_.isEqual(prevProps.formValues, formValues) ||
      !_.isEqual(prevProps.formErrors, formErrors)
    ) {
      this.updateInitialState(prevProps.category.id !== category.id)
    }
  }

  validateValuesPresent(category, formValues) {
    return (
      category &&
      Object.keys(category).length > 0 &&
      formValues &&
      Object.keys(formValues).length > 0
    )
  }

  fetchFormErrors(depth, id) {
    const { formErrors } = this.props
    if (formErrors && Object.keys(formErrors).length > 0) {
      const { categoryReplacements } = formErrors
      return (
        (categoryReplacements &&
          categoryReplacements[depth] &&
          categoryReplacements[depth][id]) ||
        ''
      )
    }

    return ''
  }

  updateInitialState(categoryChanged) {
    const { formValues, category } = this.props
    if (this.validateValuesPresent(category, formValues)) {
      const { categoryReplacements } = formValues
      const { depth, id } = category
      const value =
        (categoryReplacements[depth] && categoryReplacements[depth][id]) || ''
      const error = this.fetchFormErrors(depth, id)

      this.setState({ rewrite: value.length > 0, value, error })
      if (categoryChanged) this.setState({ initialValue: value })
    }
  }

  handleChange() {
    const { handleRewriteChange } = this.props
    const { rewrite, initialValue } = this.state
    const value = rewrite ? '' : initialValue
    this.setState({ rewrite: !rewrite, value })
    if (rewrite || value !== '') {
      handleRewriteChange(value === '' ? null : value)
    }
  }

  handleInputChange(event) {
    const { handleRewriteChange } = this.props
    handleRewriteChange(event.target.value)
    this.setState({ initialValue: event.target.value })
  }

  renderSwitchAndInput() {
    const { isDisabled } = this.props
    const { rewrite, value } = this.state

    return (
      <>
        <MDBSwitch
          labelLeft='Unchanged'
          labelRight='Rewrite'
          disabled={isDisabled}
          checked={rewrite}
          onChange={this.handleChange.bind(this)}
        />
        {rewrite && (
          <MDBInput
            className={isDisabled && 'md-form-disabled'}
            disabled={isDisabled}
            name='Product Type'
            value={value}
            onChange={this.handleInputChange.bind(this)}
          />
        )}
      </>
    )
  }

  renderInputField() {
    const { isDisabled } = this.props

    return (
      <>
        <span data-tip='To edit this field,<br/>the category must be<br/>Enabled for Advertising.'>
          {this.renderSwitchAndInput()}
        </span>
        {isDisabled && <ReactTooltip multiline={true} effect='solid' />}
      </>
    )
  }

  render() {
    const { category, formValues } = this.props
    const { error } = this.state

    return this.validateValuesPresent(category, formValues) ? (
      <MDBRow className='form-input-row'>
        <MDBCol className='label-column'>Product Type:</MDBCol>
        <MDBCol className='input-column'>
          {this.renderInputField()}
          {error.length > 0 && (
            <InputError error={error} cssClass='input-field-validation-error' />
          )}
        </MDBCol>
      </MDBRow>
    ) : null
  }
}
