import { FETCH_VALUE_FORMATS_RESPONSE } from 'actions/value/formats'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALUE_FORMATS_RESPONSE:
      const formats = action.formats.reduce(
        (index, fmt) => ({ ...index, [fmt.id]: fmt }),
        {}
      )
      return {
        ...state,
        ...formats
      }

    default:
      return state
  }
}
