import React, { Component } from 'react'
import { Router, Route } from 'react-router-dom'
import { GuardProvider } from 'react-router-guards'
import renderWithContext from './ad_platform_context'
import FeedOpsApp from 'apps/feed_ops'
import browserHistory from 'middleware/browser_history'
import allRouterGuards from 'util/router/guards'

export default class FeedOpsAppWithContext extends Component {
  render() {
    return renderWithContext(
      <Router history={browserHistory}>
        <GuardProvider guards={allRouterGuards} loading={'Loading...'}>
          <Route
            path='/:appId(product_feeds|feed_ops)'
            render={() => <FeedOpsApp {...this.props} />}
          />
        </GuardProvider>
      </Router>
    )
  }
}
