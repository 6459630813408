import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm, registerField, getFormValues, isDirty } from 'redux-form'
import _ from 'lodash'
import { MDBCard, MDBCardBody, MDBCardFooter, MDBRow, MDBCol } from 'mdbreact'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { fetchInventoryFeedOpsCategories } from 'actions/inventories'
import {
  fetchInventoryCategoryReplacements,
  saveInventoryCategoryReplacements
} from 'actions/product_feeds/replacements'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import SaveChangesPrompt from 'components/save_changes_prompt'
import CategoryTree from '../category_optimizations/category_tree'
import SelectedCategoryForm from '../category_optimizations/selected_category_form'
import FormButton from 'components/form_button'
import CustomerHelper from 'components/customer_helper/customer_helper'
import { websiteIdSelector } from 'selectors/websites'
import {
  inventoryFeedOpsCategoryTreeSelector,
  inventoryFeedOpsCategoryHierarchySelector
} from 'selectors/inventory'
import { websiteCategoryReplacementsSelector } from 'selectors/product_feeds/replacements'

export class CategoryOptimizationsCard extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryHierarchy: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryReplacements: PropTypes.arrayOf(PropTypes.object).isRequired,
    registerField: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    fetchInventoryFeedOpsCategories: PropTypes.func.isRequired,
    fetchInventoryCategoryReplacements: PropTypes.func.isRequired,
    saveInventoryCategoryReplacements: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.updateSelectedCategory = this.updateSelectedCategory.bind(this)
    this.state = {
      loading: true,
      selectedCategory: {},
      categoryVisibile: true
    }
  }

  async componentDidMount() {
    const { registerField } = this.props
    await this.fetchInventoryFeedOpsCategories()
    await this.fetchInventoryCategoryReplacements()
    registerField(REDUX_FORM_NAME, 'categoryReplacements', 'Field')
    this.initializeForm()
  }

  async componentDidUpdate(prevProps) {
    const { categories, categoryReplacements, websiteId } = this.props
    if (prevProps.websiteId !== websiteId && websiteId !== 0) {
      await this.fetchInventoryFeedOpsCategories()
      await this.fetchInventoryCategoryReplacements()
    }

    if (
      !_.isEqual(prevProps.categoryReplacements, categoryReplacements) ||
      !_.isEqual(prevProps.categories, categories)
    ) {
      this.initializeForm()
    }
  }

  updateSelectedCategory(category, visible) {
    if (!_.isEmpty(category)) {
      this.setState({ selectedCategory: category })
    }
    this.setState({ categoryVisibile: visible })
  }

  async fetchInventoryFeedOpsCategories() {
    const { fetchInventoryFeedOpsCategories, websiteId } = this.props
    if (websiteId !== 0) {
      try {
        this.setState({ loading: true })
        await fetchInventoryFeedOpsCategories(websiteId)
      } catch (error) {
        toastr.error(
          'An error occurred while fetching your categories. Please contact support for assistance.'
        )
      }
      this.setState({ loading: false })
    }
  }

  async fetchInventoryCategoryReplacements() {
    const {
      categoryReplacements,
      fetchInventoryCategoryReplacements,
      websiteId
    } = this.props
    if (
      (!categoryReplacements || categoryReplacements.length === 0) &&
      websiteId !== 0
    ) {
      this.setState({ loading: true })
      await fetchInventoryCategoryReplacements(websiteId)
    }
    this.setState({ loading: false })
  }

  initializeForm() {
    const { initialize, categories, categoryHierarchy, categoryReplacements } =
      this.props
    if (categories.length > 0) {
      initialize({
        categories: categoryHierarchy,
        categoryReplacements
      })
    }
  }

  renderCategoryTreeView() {
    const { selectedCategory } = this.state
    return (
      <MDBCol className='category-tree-column'>
        <CategoryTree
          updateSelectedCategory={this.updateSelectedCategory}
          selectedCategory={selectedCategory}
        />
      </MDBCol>
    )
  }

  renderCategoryOptimizationForm() {
    const { selectedCategory, categoryVisibile } = this.state
    return (
      <MDBCol>
        {categoryVisibile && !_.isEmpty(selectedCategory) && (
          <SelectedCategoryForm category={selectedCategory} />
        )}
      </MDBCol>
    )
  }

  renderFooter() {
    return (
      <MDBCardFooter>
        <CustomerHelper
          helperName={'Not sure what to do?'}
          articleId={'edit-categories'}
          bookAMeetingType={'feedops'}
        />
      </MDBCardFooter>
    )
  }

  renderSavePrompt() {
    const { isDirty } = this.props
    return (
      <SaveChangesPrompt
        when={isDirty}
        message='You have unsaved changes, are you sure you want to leave?'
      />
    )
  }

  renderLoading() {
    return <IndeterminateProgressIndicator className='loading-indicator' />
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return this.renderLoading()
    }
    return (
      <>
        {this.renderSavePrompt()}
        <MDBCard className='category-title-optimiser category-optimizations'>
          <MDBCardBody>
            <MDBRow>
              {this.renderCategoryTreeView()}
              {this.renderCategoryOptimizationForm()}
            </MDBRow>
            <MDBRow className='actions-footer'>
              <FormButton
                isCancel={false}
                reduxFormName={REDUX_FORM_NAME}
                isShowTooltips={true}
                toolTipTitle='Changes cannot be saved until all duplicated category names are resolved.'
                toolTipMessage='These duplicates can be identified with a red line next to the category name. Any questions on this please reach out to the team.'
              />
              <FormButton isCancel={true} reduxFormName={REDUX_FORM_NAME} />
            </MDBRow>
          </MDBCardBody>
          {this.renderFooter()}
        </MDBCard>
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  categories: inventoryFeedOpsCategoryTreeSelector(state, props),
  categoryHierarchy: inventoryFeedOpsCategoryHierarchySelector(state, props),
  categoryReplacements: websiteCategoryReplacementsSelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
  isDirty: isDirty(REDUX_FORM_NAME)(state) || false
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerField,
      fetchInventoryFeedOpsCategories,
      fetchInventoryCategoryReplacements,
      saveInventoryCategoryReplacements
    },
    dispatch
  )

const CategoryOptimizationsCardRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryOptimizationsCard)

export const REDUX_FORM_NAME = 'CategoryOptimizations'

export const handleSubmit = async (values, dispatch, props) => {
  const {
    saveInventoryCategoryReplacements,
    fetchInventoryCategoryReplacements,
    fetchInventoryFeedOpsCategories,
    websiteId
  } = props
  try {
    const replacements = values.categoryReplacements
    const categories = values.categories
    await saveInventoryCategoryReplacements(websiteId, replacements, categories)
    toastr.success('Category settings applied. Your feed is being generated.')
    await fetchInventoryFeedOpsCategories(websiteId)
    await fetchInventoryCategoryReplacements(websiteId)
  } catch (error) {
    toastr.error(
      'An error occurred while saving your category settings. Please contact support for assistance.'
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(CategoryOptimizationsCardRedux)
    )
  )
)
