/* eslint-disable no-loop-func */
export const ALL_CATEGORIES = 'All'
export const ENABLED_CATEGORIES = 'Enabled'
export const DISABLED_CATEGORIES = 'Disabled'

export const matchesKeyword = (searchKeyword, value) => {
  var lowerKeyword = searchKeyword && searchKeyword.toLowerCase()
  return (
    !searchKeyword ||
    searchKeyword.length === 0 ||
    (value && value.toLowerCase().includes(lowerKeyword))
  )
}

const matchesCategoryFilter = (categoryFilter, category) => {
  if (categoryFilter === ALL_CATEGORIES) {
    return true
  } else {
    return categoryFilter === DISABLED_CATEGORIES
      ? category.excluded
      : !category.excluded
  }
}

const hasChildrenThatMatchFilter = (category, filteredCategories) => {
  if (category.hasChildren && filteredCategories[category.depth + 1]) {
    const isChildOfCat = (c) => c.parentId === category.id
    return (
      Object.values(filteredCategories[category.depth + 1]).findIndex(
        isChildOfCat
      ) >= 0
    )
  }
  return false
}

const processCategory = (filterValues, category, filteredCategories) => {
  var searchKeyword = filterValues.searchKeyword
  var expanded =
    searchKeyword && searchKeyword.length > 0
      ? hasChildrenThatMatchFilter(category, filteredCategories)
      : filterValues.categoryFilter !== 'All'
      ? hasChildrenThatMatchFilter(category, filteredCategories)
      : false
  var add = expanded

  if (
    filterValues.matchFunction(category, searchKeyword) &&
    matchesCategoryFilter(filterValues.categoryFilter, category)
  ) {
    add = true
  }

  if (add) {
    filteredCategories[category.depth][category.id] = { ...category, expanded }
  }
  return filteredCategories
}

const loopCategoryLevels = (levels, filterValues) => {
  var filteredCategories = {}
  levels.reverse()
  levels.forEach((level, i) => {
    var depth = levels.length - i - 1
    filteredCategories[depth] = {}
    Object.values(level).forEach((category) => {
      filteredCategories = processCategory(
        filterValues,
        category,
        filteredCategories
      )
    })
  })

  return filteredCategories
}

export const matchOnCategoryName = (category, searchKeyword) =>
  matchesKeyword(searchKeyword, category.name)

export const filterCategories = (
  searchKeyword,
  categories,
  matchFunction = matchOnCategoryName,
  categoryFilter = 'All'
) => {
  var filterValues = {
    searchKeyword,
    categories,
    categoryFilter,
    matchFunction
  }
  var levels = [...categories]

  return Object.values(loopCategoryLevels(levels, filterValues))
}
