import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import PopoverContainer from './popover_container'
import {
  RULE_ACTIONS_NAME,
  actionsToFilters,
  filtersToActions
} from './rules_engine_util'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import { actionTypesSelector } from 'selectors/inventories/rules'

import './actions_container.scss'

export class ActionsContainer extends Component {
  static propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    ruleActionTypes: PropTypes.object.isRequired,
    handleActionsChange: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = { actionFilters: [] }
  }

  computeActionFilters() {
    const { actions, availableAttributes, ruleActionTypes } = this.props
    const actionFilters =
      actions && actions.length > 0
        ? actionsToFilters(actions, availableAttributes, ruleActionTypes)
        : []

    this.setState({ actionFilters })
  }

  componentDidMount() {
    this.computeActionFilters()
  }

  componentDidUpdate(prevProps) {
    const { actions } = this.props
    if (actions !== prevProps.actions) {
      this.computeActionFilters()
    }
  }

  computeActionsChange(updatedRuleActions) {
    const {
      actions,
      availableAttributes,
      ruleActionTypes,
      handleActionsChange
    } = this.props

    const updatedActions =
      updatedRuleActions.length > 0
        ? filtersToActions(
            actions,
            updatedRuleActions,
            availableAttributes,
            ruleActionTypes
          )
        : []
    handleActionsChange(updatedActions)
  }

  render() {
    const { actionFilters } = this.state

    return (
      <div className='actions-container'>
        <PopoverContainer
          actionName={RULE_ACTIONS_NAME}
          appliedFilters={actionFilters}
          computeRulesChange={this.computeActionsChange.bind(this)}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  availableAttributes: websiteAllAttributesSelector(state, props),
  ruleActionTypes: actionTypesSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsContainer)
)
