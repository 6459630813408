import camelCaseKeys from 'camelcase-keys'
import { store } from 'containers/ad_platform_context'
import { setMicrosoftUser } from 'actions/microsoft/user'

// Shims to facilitate testing
var theStore = store
export const setStore = (newStore) => (theStore = newStore)

var theWindow = global.window
export const setWindow = (newWindow) => (theWindow = newWindow)
// End shims

const handleMicrosoftApiUserChanged = async (email, scope, response) => {
  await theStore.dispatch(
    setMicrosoftUser({
      ...response,
      email,
      scope
    })
  )
}

const authorizeUser = async (scope) => {
  var popup = theWindow.open(`/microsoft_api/oauth/login?scope=${scope}`)
  return new Promise((resolve, reject) => {
    var timeout = setInterval(() => {
      if (popup.closed) {
        reject('Window closed')
        clearInterval(timeout)
      }
    }, 1000)

    theWindow.addEventListener('message', (event) => {
      if (event.source === popup) {
        const { data } = event
        if (data.email && data.accessToken) {
          resolve(data)
        } else {
          reject('An error occurred.')
        }
        clearInterval(timeout)
      }
    })
  })
}

export const microsoftAuthRequestToken = async (scope) => {
  var response = await authorizeUser(scope)
  const camelCaseResponse = camelCaseKeys(response, { deep: true })
  await handleMicrosoftApiUserChanged(response.email, scope, camelCaseResponse)
  return camelCaseResponse
}
