import { FETCH_PRODUCT_URL_SETTING_RESPONSE } from 'actions/product_url_settings'

const initialState = {}

export default (state = initialState, action) => {
  const websiteData = state[action.websiteId] || {}
  switch (action.type) {
    case FETCH_PRODUCT_URL_SETTING_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          ...websiteData,
          feedId: action.feedId,
          utmSource: action.utmSource || '',
          utmMedium: action.utmMedium || '',
          additionalTagging: action.additionalTagging
            ? Object.entries(action.additionalTagging)
            : [],
          autoTracking:
            action.autoTracking === undefined ? true : action.autoTracking,
          adsTagging: action.adsTagging ? Object.entries(action.adsTagging) : []
        }
      }
    default:
      return state
  }
}
