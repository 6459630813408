import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { fetchApiImportSourceHeaders } from 'actions/product_feeds/replacements/products/api_single_import'
import AuthenticationButtons from './authentication_buttons'
import AsyncAccountSelect from './async_account_select'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import toastr from 'toastr'
import { websiteIdSelector } from 'selectors/websites'

import { CHANNEL_GOOGLE } from 'util/supported_channels'
import { GOOGLE_SHEET_IMPORT_SOURCE } from '../google_sheets_source'
import './api_source.scss'

export class ApiSource extends Component {
  static propTypes = {
    channel: PropTypes.string.isRequired,
    sourceName: PropTypes.string.isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
    asyncAccountName: PropTypes.string.isRequired,
    asyncAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    asyncAccountId: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    accountId: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    accessToken: PropTypes.string.isRequired,
    handleOnScopeGranted: PropTypes.func.isRequired,
    handleAsyncAccountChange: PropTypes.func,
    handleAccountChange: PropTypes.func.isRequired,
    fetchApiImportSourceHeaders: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      sourceInvalid: false,
      invalidMessage: '',
      validatingAndFetching: false
    }
  }

  componentDidUpdate(prevProps) {
    const { asyncAccounts, accounts } = this.props

    if (
      !_.isEqual(asyncAccounts, prevProps.asyncAccounts) ||
      !_.isEqual(accounts, prevProps.accounts)
    ) {
      this.setState({ validatingAndFetching: false })
    }
  }

  handleOnScopeGranted() {
    this.setState({ validatingAndFetching: true })
    const { handleOnScopeGranted } = this.props
    handleOnScopeGranted()
  }

  handleAsyncAccountChange(asyncAccount) {
    this.setState({ validatingAndFetching: true })
    const { handleAsyncAccountChange } = this.props
    handleAsyncAccountChange(asyncAccount)
  }

  renderAsyncAccountSelect() {
    const { asyncAccountName, asyncAccounts, asyncAccountId } = this.props

    return (
      <div className='async-account-selector-row'>
        <span className='label-column'>{asyncAccountName}</span>
        <AsyncAccountSelect
          shouldLoadAsyncAccounts={true}
          defaultAccounts={asyncAccounts}
          defaultAccountId={asyncAccountId}
          handleValueChange={this.handleAsyncAccountChange.bind(this)}
        />
      </div>
    )
  }

  computeAccessSettings(account) {
    const { sourceName, accessToken, asyncAccountId } = this.props

    if (sourceName === GOOGLE_SHEET_IMPORT_SOURCE) {
      return {
        source: sourceName,
        accessToken,
        spreadsheetId: asyncAccountId,
        sheetId: account.label
      }
    } else {
      return { source: sourceName, accessToken, accountId: account.value }
    }
  }

  setFileError(message) {
    this.setState({
      sourceInvalid: true,
      invalidMessage: message
    })
  }

  handleFetchResponse(response, account) {
    if (response.error) {
      this.setFileError(response.error)
    } else if (!response.headers || response.headers.length === 0) {
      this.setFileError('No attributes found in file headers')
    } else {
      const { handleAccountChange, accessToken } = this.props
      const { headers, mappings } = response

      handleAccountChange(account, accessToken, headers, mappings)
    }

    this.setState({ validatingAndFetching: false })
  }

  async handleAccountChange(account) {
    this.setState({ sourceInvalid: false, validatingAndFetching: true })
    const { sourceName, websiteId, fetchApiImportSourceHeaders } = this.props

    toastr.info(
      `Fetching ${_.startCase(sourceName)} data, this can take some time.`
    )
    try {
      const response = await fetchApiImportSourceHeaders(
        websiteId,
        this.computeAccessSettings(account)
      )
      this.handleFetchResponse(response, account)
    } catch (error) {
      this.handleFetchResponse(error.responseJSON, account)
    }
  }

  renderAccountSelect() {
    const { accounts, accountName } = this.props

    return (
      <div className='account-selector-row'>
        <span className='label-column'>{accountName}</span>
        <AsyncAccountSelect
          shouldLoadAsyncAccounts={false}
          defaultAccounts={accounts}
          handleValueChange={this.handleAccountChange.bind(this)}
        />
      </div>
    )
  }

  conditionallyRenderAccountAndSubAccountSelect() {
    const { asyncAccountName, asyncAccounts, asyncAccountId, accounts } =
      this.props
    const asyncAccountCheck = asyncAccountName ? asyncAccountId : true

    return (
      <>
        {asyncAccountName &&
          !_.isEmpty(asyncAccounts) &&
          asyncAccounts.length > 0 &&
          this.renderAsyncAccountSelect()}
        {asyncAccountCheck &&
          !_.isEmpty(accounts) &&
          accounts.length > 0 &&
          this.renderAccountSelect()}
      </>
    )
  }

  render() {
    const { sourceInvalid, invalidMessage, validatingAndFetching } = this.state
    const { channel, sourceName, scopes } = this.props

    return (
      <div className='connection-source'>
        <AuthenticationButtons
          channel={channel}
          btnName={`Authorize ${_.startCase(sourceName)} Access`}
          requiredScopes={scopes}
          onScopeGranted={this.handleOnScopeGranted.bind(this)}
        />
        {this.conditionallyRenderAccountAndSubAccountSelect()}
        {sourceInvalid && <div className='source-error'>{invalidMessage}</div>}
        {validatingAndFetching && <IndeterminateProgressIndicator />}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const accessToken =
    props.channel === CHANNEL_GOOGLE
      ? state.google.user.accessToken
      : state.microsoft.user.refreshToken
  return {
    websiteId: websiteIdSelector(state, props),
    accessToken
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchApiImportSourceHeaders }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApiSource)
)
