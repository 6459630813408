import QueryString from 'query-string'
import { createSelector } from 'reselect'
import { websiteIdSelector } from 'selectors/websites'

export const productFeedsReplacementsSelector = (state, _props) =>
  state.productFeedsReplacements || {}

export const websiteReplacementsSelector = createSelector(
  [productFeedsReplacementsSelector, websiteIdSelector],
  (productFeedsReplacements, websiteId) =>
    productFeedsReplacements[websiteId] || {}
)

export const websiteCategoryReplacementsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.categoryReplacements || []
)

export const websiteAttributesSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.attributes || {}
)

const columnCompareFunction = (a, b) => {
  if (b.columnCategoryPosition < a.columnCategoryPosition) {
    return 1
  } else if (
    b.columnCategoryPosition === a.columnCategoryPosition ||
    b.columnPosition < a.columnPosition
  ) {
    return 1
  } else if (
    b.columnCategoryPosition === a.columnCategoryPosition &&
    b.columnPosition === a.columnPosition
  ) {
    return 0
  } else {
    return -1
  }
}
export const websiteAllAttributesSelector = createSelector(
  [websiteReplacementsSelector],
  (website) =>
    Object.values(website.allAttributes || {}).sort(columnCompareFunction)
)

export const websiteAttributeReplacementsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.attributeReplacements || {}
)

export const websiteUpdatedAttributeReplacementsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.updatedAttributeReplacements || {}
)

export const websiteProductReplacementsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.productReplacements || []
)

export const websiteProductOptimisationSuggestionsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.productOptimisationSuggestions || []
)

export const websiteProductReplacementsMetaDataSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.productReplacementsMetaData || {}
)

export const websiteProductReplacementsPageSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.page || 1
)

export const websiteProductReplacementsPerPageSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.perPage || 50
)

export const websiteProductReplacementsSearchTermSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.searchTerm || ''
)

export const websiteProductReplacementsFiltersSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.filters || []
)

export const websiteProductReplacementsSelectedColumnsSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.selectedSuggestionColumns || []
)

export const websiteUpdatedProductAttributesSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.updatedProductAttributes || {}
)

export const websiteUpdatedItemsDisabledSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.updatedInventoryItems || {}
)

export const suggestionBatchIdsSelector = (_state, props) => {
  if (props.location) {
    const qsAttributes = QueryString.parse(props.location.search)
    const { batch_ids } = qsAttributes
    if (batch_ids) {
      return batch_ids.split(',').map((id) => parseInt(id))
    } else {
      return null
    }
  } else {
    return null
  }
}

export const isProductRowSelectedSelector = createSelector(
  [productFeedsReplacementsSelector],
  (productFeedsReplacements) =>
    productFeedsReplacements.isProductRowSelected || false
)

export const websiteQueryChangedStatusSelector = createSelector(
  [websiteReplacementsSelector],
  (website) => website.queryChanged || false
)
