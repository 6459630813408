import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  SELECT_ACCOUNT_ACTION,
  ADD_USER_ACTION,
  DELETE_USER_ACTION
} from '../overview_util'
import AccountSelector from './account_selector'
import { MDBBtn, MDBIcon } from 'mdbreact'

export class TableActions extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    accountId: PropTypes.number,
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowData: PropTypes.object.isRequired,
    handleAccountIdChanged: PropTypes.func.isRequired,
    handleDeleteUserClick: PropTypes.func.isRequired
  }

  handleAccountIdChanged(accountId) {
    const { handleAccountIdChanged } = this.props
    handleAccountIdChanged(accountId)
  }

  renderAccountSelector() {
    const { accountId, accountList } = this.props

    return (
      <AccountSelector
        accountId={accountId}
        accountList={accountList}
        handleAccountIdChanged={this.handleAccountIdChanged.bind(this)}
      />
    )
  }

  handleAddUserClick() {
    const { accountId } = this.props
    this.props.history.push(
      `/feed_ops/accounts/add_user?account_id=${accountId}`
    )
  }

  renderAddUserBtn() {
    const { accountId } = this.props

    return accountId ? (
      <MDBBtn
        tag='span'
        className='btn-toolbar-action'
        onClick={this.handleAddUserClick.bind(this)}
      >
        Add User
      </MDBBtn>
    ) : null
  }

  handleDeleteUserClick(rowData) {
    const { handleDeleteUserClick } = this.props
    handleDeleteUserClick(rowData)
  }

  renderDeleteUserBtn() {
    const { rowData } = this.props

    return (
      <MDBBtn
        tag='span'
        className='btn-row-action'
        onClick={this.handleDeleteUserClick.bind(this, rowData)}
      >
        <MDBIcon icon='trash' />
      </MDBBtn>
    )
  }

  render() {
    const { actionName } = this.props
    var actionComponent = null

    switch (actionName) {
      case SELECT_ACCOUNT_ACTION:
        actionComponent = this.renderAccountSelector()
        break
      case ADD_USER_ACTION:
        actionComponent = this.renderAddUserBtn()
        break
      case DELETE_USER_ACTION:
        actionComponent = this.renderDeleteUserBtn()
        break
      default:
        break
    }

    return actionComponent
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableActions)
)
