import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MDBBtn } from 'mdbreact'

export class DontHaveAnAccountYetPrompt extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  followLink() {
    window.open('https://feedops.com/pricing', '_self')
  }

  render() {
    return (
      <p className={'no-account-yet'}>
        Don't have an account yet?{' '}
        <MDBBtn className='btn-href-style' onClick={this.followLink.bind(this)}>
          Sign up
        </MDBBtn>
      </p>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default withRouter(
  connect(mapStateToProps, null)(DontHaveAnAccountYetPrompt)
)
