import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Poller extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    periodMs: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    this.state = {
      timerVar: null
    }
  }

  componentDidMount() {
    this.startPolling()
  }

  componentWillUnmount() {
    this.stopPolling()
  }

  poll() {
    const { action, disabled } = this.props
    if (disabled !== true) {
      action()
    }
  }

  startPolling() {    
    if (this.state.timerVar === null) {
      const { periodMs } = this.props
      var timerVar = setInterval(this.poll.bind(this), periodMs)
      this.setState({timerVar})
    }
  }

  stopPolling() {
    if (this.state.timerVar) {
      clearInterval(this.state.timerVar);
    }
  }

  render() {
    return null
  }
}
