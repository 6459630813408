import { createSelector } from 'reselect'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import _ from 'lodash'
import changeCase from 'change-case'

const titlesSelector = (state, _props) => state.productFeedsTitles || {}

export const websiteProductTitlesSelector = (websiteId) =>
  createSelector([titlesSelector], (titles) => titles[websiteId] || {})

export const websiteProductTitlesAvailableFieldsSelector = (websiteId) =>
  createSelector(
    [websiteProductTitlesSelector(websiteId)],
    (titles) => titles.availableFields || []
  )

const sortedFieldsWithDisplayNames = (availableFields, attributes) => {
  var allDisplayFields = {}
  availableFields.forEach((field) => {
    const attribute = attributes.find(
      (attribute) => attribute.name === changeCase.titleCase(field)
    )
    allDisplayFields[
      !_.isEmpty(attribute) ? attribute.columnName : changeCase.titleCase(field)
    ] = field
  })

  var sortedDisplayFields = {}
  _(allDisplayFields)
    .keys()
    .sort()
    .each((key) => {
      sortedDisplayFields[key] = allDisplayFields[key]
    })

  return sortedDisplayFields
}

export const websiteProductTitlesSortedAvailableFieldsSelector = (websiteId) =>
  createSelector(
    [
      websiteProductTitlesAvailableFieldsSelector(websiteId),
      websiteAllAttributesSelector
    ],
    (availableFields, attributes) => {
      return sortedFieldsWithDisplayNames(availableFields, attributes)
    }
  )

export const websiteProductTitlesAllAvailableAttributesSelector = (websiteId) =>
  createSelector(
    [websiteProductTitlesSelector(websiteId)],
    (titles) => titles.allAvailableAttributes || []
  )
