import { NEXT_STEP, SET_STEP, TOGGLE_ADVANCED, SET_ADVANCED } from 'actions/workflow'

const initialState = {
  currentStepNum: 1,
  showAdvanced: false
}

export default (state = initialState, action) => {
  const { currentStepNum } = state
  switch (action.type) {

  case NEXT_STEP:
    return { ...state, currentStepNum: currentStepNum + 1 }

  case SET_STEP:
    return { ...state, currentStepNum: action.stepNum }

  case TOGGLE_ADVANCED:
    return { ...state, showAdvanced: !state.showAdvanced }

  case SET_ADVANCED:
    return { ...state, showAdvanced: action.showAdvanced }

  default:
    return state
  }
}
