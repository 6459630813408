import {
  LOAD_STRUCTURED_SNIPPETS,
  SAVE_STRUCTURED_SNIPPETS
} from 'actions/structured_snippet';

const structuredSnippetReducer = (state = { snippets: {} }, action) => {
  switch(action.type) {
    case LOAD_STRUCTURED_SNIPPETS:
    case SAVE_STRUCTURED_SNIPPETS:
      return {
        ...state,
        snippets: action.snippets
      };
    default:
      return state;
  }
}

export default structuredSnippetReducer;
