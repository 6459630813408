import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { saveProductAttributeReplacements } from 'actions/product_feeds/replacements'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import toastr from 'toastr'
import { OP_EQUALS } from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { websiteProductReplacementsFiltersSelector } from 'selectors/product_feeds/replacements'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class DisableEnableProducts extends Component {
  static propTypes = {
    allRowsSelected: PropTypes.bool.isRequired,
    forSingleRow: PropTypes.bool.isRequired,
    actionName: PropTypes.string.isRequired,
    disableItems: PropTypes.bool.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    toastr: PropTypes.object.isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired,
    saveProductAttributeReplacements: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  static defaultProps = {
    toastr
  }

  buildFilters() {
    const { allRowsSelected, selectedRows } = this.props
    if (allRowsSelected) {
      return this.props.filters
    } else {
      return [
        {
          attributeName: 'Sku',
          filterType: OP_EQUALS,
          values: selectedRows.map((r) => r.override.sku)
        }
      ]
    }
  }

  async handleDisableEnableProducts(disableItem) {
    const { forSingleRow, disableItems } = this.props
    var updatedItemDisabled = forSingleRow ? disableItem : disableItems

    const { saveProductAttributeReplacements, websiteId, feedId, channel } =
      this.props

    var filters = this.buildFilters()

    var replacements = {
      enabled: !updatedItemDisabled
    }

    try {
      await saveProductAttributeReplacements(
        websiteId,
        feedId,
        channel,
        filters,
        replacements
      )
      toastr.success('Your changes have been saved.')
    } catch (ex) {
      toastr.error('An error occurred saving your changes. Please try again.')
    }

    const { onSaveProductAttributesReplacements } = this.props
    await onSaveProductAttributesReplacements()
  }

  renderSingleRowIcon() {
    const { selectedRows } = this.props
    const rowData = selectedRows[0]
    const itemDisabled = rowData.itemDisabled

    const colour = itemDisabled ? 'black' : '#0F9D58'

    return itemDisabled ? (
      <ToggleOffIcon
        style={{ fontSize: '30px', color: `${colour}` }}
        onClick={this.handleDisableEnableProducts.bind(this, false)}
      />
    ) : (
      <ToggleOnIcon
        style={{ fontSize: '30px', color: `${colour}` }}
        onClick={this.handleDisableEnableProducts.bind(this, true)}
      />
    )
  }

  render() {
    const { forSingleRow, actionName } = this.props

    return forSingleRow ? (
      this.renderSingleRowIcon()
    ) : (
      <div
        className='btn-action'
        onClick={this.handleDisableEnableProducts.bind(this)}
      >
        {actionName}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  channel: channelSelector(state, props),
  filters: websiteProductReplacementsFiltersSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveProductAttributeReplacements }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisableEnableProducts)
)
