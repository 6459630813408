import _ from 'lodash'
import { REQUEST_LOAD_KEYWORDS, RECEIVE_LOAD_KEYWORDS } from 'actions/scenario/keywords'

const keywordsReducer = (state = { requestedIds: [] }, action) => {
  switch(action.type) {
    case REQUEST_LOAD_KEYWORDS:
      return {
        ...state,
        requestedIds: action.keywordIds
      }
    case RECEIVE_LOAD_KEYWORDS:
      var requestedIds = _.difference(state.requestedIds, Object.keys(action.keywords).map(id => parseInt(id, 10)))
      return {
        ...state,
        ...action.keywords,
        requestedIds
      };
    default:
      return state
  }
}

export default keywordsReducer