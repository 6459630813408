import React, { Component } from 'react'
import IconButton from 'components/ad_champion/common/icon_button'

export default class CalculatedTargetHelper extends Component {
  getTooltipText() {
    return `Target conversion value is calculated by multiplying budget by target ROAS. If no target ROAS has been set, this will be left blank.`
  }

  render() {
    return (
      <IconButton
        className={'index-icon link-icon help-icon'}
        iconClass={'fa fa-question-circle'}

        tooltipText={this.getTooltipText()}
      />
    )
  }
}
