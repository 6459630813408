import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_WEBSITE_CUSTOMER_REQUEST = 'FETCH_WEBSITE_CUSTOMER_REQUEST'
export const FETCH_WEBSITE_CUSTOMER_RESPONSE = 'FETCH_WEBSITE_CUSTOMER_RESPONSE'
export const CREATE_WEBSITE_CUSTOMER_REQUEST = 'CREATE_WEBSITE_CUSTOMER_REQUEST'
export const CREATE_WEBSITE_CUSTOMER_RESPONSE =
  'CREATE_WEBSITE_CUSTOMER_RESPONSE'
export const UPDATE_WEBSITE_CUSTOMER_REQUEST = 'UPDATE_WEBSITE_CUSTOMER_REQUEST'
export const UPDATE_WEBSITE_CUSTOMER_RESPONSE =
  'UPDATE_WEBSITE_CUSTOMER_RESPONSE'
export const USE_EXISTING_CONTACT_DETAILS_REQUEST =
  'USE_EXISTING_CONTACT_DETAILS_REQUEST'
export const USE_EXISTING_CONTACT_DETAILS_RESPONSE =
  'USE_EXISTING_CONTACT_DETAILS_RESPONSE'
export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST'
export const FETCH_PLANS_RESPONSE = 'FETCH_PLANS_RESPONSE'
export const FETCH_PLAN_REQUEST = 'FETCH_PLAN_REQUEST'
export const FETCH_PLAN_RESPONSE = 'FETCH_PLAN_RESPONSE'
export const FETCH_ADDON_REQUEST = 'FETCH_ADDON_REQUEST'
export const FETCH_ADDON_RESPONSE = 'FETCH_ADDON_RESPONSE'
export const FETCH_WEBSITE_SUBSCRIPTION_REQUEST =
  'FETCH_WEBSITE_SUBSCRIPTION_REQUEST'
export const FETCH_WEBSITE_SUBSCRIPTION_RESPONSE =
  'FETCH_WEBSITE_SUBSCRIPTION_RESPONSE'
export const FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST =
  'FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST'
export const FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE =
  'FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE'
export const FETCH_ACCOUNT_SUBSCRIPTION_REQUEST =
  'FETCH_ACCOUNT_SUBSCRIPTION_REQUEST'
export const FETCH_ACCOUNT_SUBSCRIPTION_RESPONSE =
  'FETCH_ACCOUNT_SUBSCRIPTION_RESPONSE'
export const CREATE_ACCOUNT_SUBSCRIPTION_REQUEST =
  'CREATE_ACCOUNT_SUBSCRIPTION_REQUEST'
export const CREATE_ACCOUNT_SUBSCRIPTION_RESPONSE =
  'CREATE_ACCOUNT_SUBSCRIPTION_RESPONSE'
export const UPDATE_ACCOUNT_SUBSCRIPTION_REQUEST =
  'UPDATE_ACCOUNT_SUBSCRIPTION_REQUEST'
export const UPDATE_ACCOUNT_SUBSCRIPTION_RESPONSE =
  'UPDATE_ACCOUNT_SUBSCRIPTION_RESPONSE'
export const UPDATE_WEBSITE_SUBSCRIPTION_REQUEST =
  'UPDATE_WEBSITE_SUBSCRIPTION_REQUEST'
export const UPDATE_WEBSITE_SUBSCRIPTION_RESPONSE =
  'UPDATE_WEBSITE_SUBSCRIPTION_RESPONSE'
export const UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST =
  'UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST'
export const UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE =
  'UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE'
export const SET_EXISTING_ACCOUNT_FOR_CONTACT_DETAILS =
  'SET_EXISTING_ACCOUNT_FOR_CONTACT_DETAILS'
export const FETCH_EXISTING_WEBSITE_CUSTOMER_REQUEST =
  'FETCH_EXISTING_WEBSITE_CUSTOMER_REQUEST'
export const FETCH_EXISTING_WEBSITE_CUSTOMER_RESPONSE =
  'FETCH_EXISTING_WEBSITE_CUSTOMER_RESPONSE'
export const FETCH_EXISTING_ACCOUNT_CUSTOMER_REQUEST =
  'FETCH_EXISTING_ACCOUNT_CUSTOMER_REQUEST'
export const FETCH_EXISTING_ACCOUNT_CUSTOMER_RESPONSE =
  'FETCH_EXISTING_ACCOUNT_CUSTOMER_RESPONSE'
export const FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_REQUEST =
  'FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_REQUEST'
export const FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_RESPONSE =
  'FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_RESPONSE'

const fetchWebsiteCustomerRequest = (websiteId) => ({
  type: FETCH_WEBSITE_CUSTOMER_REQUEST,
  websiteId
})

const fetchWebsiteCustomerResponse = (websiteId, response) => ({
  type: FETCH_WEBSITE_CUSTOMER_RESPONSE,
  customer: response.customer,
  sharedDetails: response.sharedDetails,
  websiteId
})

export const fetchWebsiteCustomer = (websiteId) => async (dispatch) => {
  if (!websiteId) {
    return Promise.resolve()
  }

  dispatch(fetchWebsiteCustomerRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/customer`,
    dataType: 'json'
  })
  dispatch(
    fetchWebsiteCustomerResponse(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const createWebsiteCustomerRequest = (websiteId) => ({
  type: CREATE_WEBSITE_CUSTOMER_REQUEST,
  websiteId
})

const createWebsiteCustomerResponse = (websiteId) => ({
  type: CREATE_WEBSITE_CUSTOMER_RESPONSE,
  websiteId
})

export const createWebsiteCustomer = (websiteId, payload) => (dispatch) => {
  dispatch(createWebsiteCustomerRequest(websiteId))
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/websites/${websiteId}/customer`,
    dataType: 'json',
    data: snakeCaseKeys({ customer: payload }),
    success: (response) => {
      return dispatch(createWebsiteCustomerResponse(websiteId))
    }
  })
}

const updateWebsiteCustomerRequest = (websiteId) => ({
  type: UPDATE_WEBSITE_CUSTOMER_REQUEST,
  websiteId
})

const updateWebsiteCustomerResponse = (websiteId, response) => ({
  type: UPDATE_WEBSITE_CUSTOMER_RESPONSE,
  websiteId,
  response
})

export const updateWebsiteCustomer = (websiteId, payload) => (dispatch) => {
  dispatch(updateWebsiteCustomerRequest(websiteId))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/websites/${websiteId}/customer`,
    dataType: 'json',
    data: snakeCaseKeys({ customer: payload }),
    success: (response) => {
      return dispatch(
        updateWebsiteCustomerResponse(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const useExistingContactDetailsRequest = (
  websiteId,
  accountType,
  accountTypeId
) => ({
  type: USE_EXISTING_CONTACT_DETAILS_REQUEST,
  websiteId,
  accountType,
  accountTypeId
})

const useExistingContactDetailsResponse = (
  websiteId,
  accountType,
  accountTypeId,
  response
) => ({
  type: USE_EXISTING_CONTACT_DETAILS_RESPONSE,
  websiteId,
  accountType,
  accountTypeId,
  response
})

export const useExistingContactDetails =
  (websiteId, accountType, accountTypeId) => (dispatch) => {
    dispatch(
      useExistingContactDetailsRequest(websiteId, accountType, accountTypeId)
    )

    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/customer/use_existing_contact_details`,
      dataType: 'json',
      data: snakeCaseKeys({ websiteId, accountType, accountTypeId }),
      success: (response) => {
        return dispatch(
          useExistingContactDetailsResponse(
            websiteId,
            accountType,
            accountTypeId,
            camelCaseKeys(response, { deep: true })
          )
        )
      }
    })
  }

const fetchPlansRequest = () => ({
  type: FETCH_PLANS_REQUEST
})

const fetchPlansResponse = (plans) => ({
  type: FETCH_PLANS_RESPONSE,
  plans
})

export const fetchPlans = () => (dispatch) => {
  dispatch(fetchPlansRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/chargebee/plans`,
    success: (response) => {
      return dispatch(
        fetchPlansResponse(
          response.plans.map((p) => camelCaseKeys(p, { deep: true }))
        )
      )
    }
  })
}

const fetchPlanRequest = (planId) => ({
  type: FETCH_PLAN_REQUEST,
  planId
})

const fetchPlanResponse = (planId, plan) => ({
  type: FETCH_PLAN_RESPONSE,
  planId,
  plan
})

export const fetchPlan = (planId) => (dispatch) => {
  dispatch(fetchPlanRequest(planId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/chargebee/plans/${planId}`,
    success: (response) => {
      return dispatch(
        fetchPlanResponse(planId, camelCaseKeys(response.plan, { deep: true }))
      )
    }
  })
}

const fetchAddonRequest = (addonId) => ({
  type: FETCH_ADDON_REQUEST,
  addonId
})

const fetchAddonResponse = (addonId, addon) => ({
  type: FETCH_ADDON_RESPONSE,
  addonId,
  addon
})

export const fetchAddon = (addonId) => (dispatch) => {
  dispatch(fetchAddonRequest(addonId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/chargebee/addons/${addonId}`,
    success: (response) => {
      return dispatch(
        fetchAddonResponse(addonId, camelCaseKeys(response, { deep: true }))
      )
    }
  })
}

const fetchWebsiteSubscriptionRequest = (websiteId) => ({
  type: FETCH_WEBSITE_SUBSCRIPTION_REQUEST,
  websiteId
})

const fetchWebsiteSubscriptionResponse = (websiteId, subscription) => ({
  type: FETCH_WEBSITE_SUBSCRIPTION_RESPONSE,
  websiteId,
  subscription
})

export const fetchWebsiteSubscription = (websiteId) => (dispatch) => {
  if (!websiteId) {
    return Promise.resolve()
  }

  dispatch(fetchWebsiteSubscriptionRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/subscription`,
    success: (response) => {
      return dispatch(
        fetchWebsiteSubscriptionResponse(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const fetchAccountAndWebsiteSubscriptionRequest = (websiteId) => ({
  type: FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST,
  websiteId
})

const fetchAccountAndWebsiteSubscriptionResponse = (
  websiteId,
  subscriptions
) => ({
  type: FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE,
  websiteId,
  ...subscriptions
})

export const fetchAccountAndWebsiteSubscription = (websiteId) => (dispatch) => {
  if (!websiteId) {
    return Promise.resolve()
  }

  dispatch(fetchAccountAndWebsiteSubscriptionRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/subscription?both=true`,
    success: (response) => {
      var camelCaseResponse = camelCaseKeys(response, { deep: true })
      dispatch(
        fetchAccountAndWebsiteSubscriptionResponse(websiteId, camelCaseResponse)
      )
      return camelCaseResponse
    }
  })
}

const fetchAccountSubscriptionRequest = (accountId) => ({
  type: FETCH_ACCOUNT_SUBSCRIPTION_REQUEST,
  accountId
})

const fetchAccountSubscriptionResponse = (accountId, response) => ({
  type: FETCH_ACCOUNT_SUBSCRIPTION_RESPONSE,
  accountId,
  response
})

export const fetchAccountSubscription = (accountId) => (dispatch) => {
  if (!accountId) {
    return Promise.resolve()
  }

  dispatch(fetchAccountSubscriptionRequest(accountId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/subscriptions`,
    success: (response) => {
      return dispatch(
        fetchAccountSubscriptionResponse(
          accountId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const createAccountSubscriptionRequest = (
  accountId,
  appId,
  planId,
  managedService
) => ({
  type: CREATE_ACCOUNT_SUBSCRIPTION_REQUEST,
  accountId,
  appId,
  planId,
  managedService
})

const createAccountSubscriptionResponse = (
  accountId,
  appId,
  planId,
  managedService,
  response
) => ({
  type: CREATE_ACCOUNT_SUBSCRIPTION_RESPONSE,
  accountId,
  appId,
  planId,
  managedService,
  response
})

export const createAccountSubscription =
  (accountId, appId, planId, managedService) => (dispatch) => {
    dispatch(
      createAccountSubscriptionRequest(accountId, appId, planId, managedService)
    )
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/accounts/${accountId}/subscriptions`,
      dataType: 'json',
      data: snakeCaseKeys({ appId, planId, managedService }, { deep: true }),
      success: (response) => {
        var camelCaseResponse = camelCaseKeys(response, { deep: true })
        dispatch(
          createAccountSubscriptionResponse(
            accountId,
            appId,
            planId,
            managedService,
            camelCaseResponse
          )
        )
        return camelCaseResponse
      }
    })
  }

const updateAccountSubscriptionRequest = (accountId, data) => ({
  type: UPDATE_ACCOUNT_SUBSCRIPTION_REQUEST,
  accountId,
  data
})

const updateAccountSubscriptionResponse = (accountId, data, response) => ({
  type: UPDATE_ACCOUNT_SUBSCRIPTION_RESPONSE,
  accountId,
  data,
  response
})

export const updateAccountSubscription = (accountId, data) => (dispatch) => {
  dispatch(updateAccountSubscriptionRequest(accountId, data))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/accounts/${accountId}/subscriptions`,
    data: snakeCaseKeys(data, { deep: true }),
    success: (response) => {
      var camelCaseResponse = camelCaseKeys(response, { deep: true })
      dispatch(
        updateAccountSubscriptionResponse(accountId, data, camelCaseResponse)
      )
      return camelCaseResponse
    }
  })
}

const updateWebsiteSubscriptionRequest = (websiteId, data) => ({
  type: UPDATE_WEBSITE_SUBSCRIPTION_REQUEST,
  websiteId,
  data
})

const updateWebsiteSubscriptionResponse = (websiteId, data, response) => ({
  type: UPDATE_WEBSITE_SUBSCRIPTION_RESPONSE,
  websiteId,
  data,
  response
})

export const updateWebsiteSubscription = (websiteId, data) => (dispatch) => {
  dispatch(updateWebsiteSubscriptionRequest(websiteId, data))
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/websites/${websiteId}/subscription`,
    data: snakeCaseKeys(data, { deep: true }),
    success: (response) => {
      var camelCaseResponse = camelCaseKeys(response, { deep: true })
      dispatch(
        updateWebsiteSubscriptionResponse(websiteId, data, camelCaseResponse)
      )
      return camelCaseResponse
    }
  })
}

const updateAccountAndWebsiteSubscriptionRequest = (
  websiteId,
  accountSubscription,
  websiteSubscription
) => ({
  type: UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_REQUEST,
  websiteId,
  accountSubscription,
  websiteSubscription
})

const updateAccountAndWebsiteSubscriptionResponse = (
  websiteId,
  accountSubscription,
  websiteSubscription,
  response
) => ({
  type: UPDATE_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE,
  websiteId,
  accountSubscription,
  websiteSubscription,
  response
})

export const updateAccountAndWebsiteSubscription =
  (websiteId, accountSubscription, websiteSubscription) => (dispatch) => {
    dispatch(
      updateAccountAndWebsiteSubscriptionRequest(
        websiteId,
        accountSubscription,
        websiteSubscription
      )
    )
    return $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/subscription/update_subscriptions`,
      data: {
        account_subscription: snakeCaseKeys(accountSubscription, {
          deep: true
        }),
        website_subscription: snakeCaseKeys(websiteSubscription, { deep: true })
      },
      dataType: 'json',
      success: (response) => {
        var camelCaseResponse = camelCaseKeys(response, { deep: true })
        dispatch(
          updateAccountAndWebsiteSubscriptionResponse(
            websiteId,
            accountSubscription,
            websiteSubscription,
            camelCaseResponse
          )
        )
        return camelCaseResponse
      }
    })
  }

const setExistingAccountForContactDetails = (accountType, accountTypeId) => ({
  type: SET_EXISTING_ACCOUNT_FOR_CONTACT_DETAILS,
  accountType,
  accountTypeId
})

export const setExistingAccount =
  (accountType, accountTypeId) => (dispatch) => {
    dispatch(setExistingAccountForContactDetails(accountType, accountTypeId))
  }

const fetchExistingWebsiteCustomerRequest = (websiteId) => ({
  type: FETCH_EXISTING_WEBSITE_CUSTOMER_REQUEST,
  websiteId
})

const fetchExistingWebsiteCustomerResponse = (websiteId, response) => ({
  type: FETCH_EXISTING_WEBSITE_CUSTOMER_RESPONSE,
  customer: response,
  websiteId
})

export const fetchExistingWebsiteCustomer = (websiteId) => (dispatch) => {
  if (!websiteId) {
    return Promise.resolve()
  }

  dispatch(fetchExistingWebsiteCustomerRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/customer`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchExistingWebsiteCustomerResponse(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const fetchExistingAccountCustomerRequest = (accountId) => ({
  type: FETCH_EXISTING_ACCOUNT_CUSTOMER_REQUEST,
  accountId
})

const fetchExistingAccountCustomerResponse = (accountId, response) => ({
  type: FETCH_EXISTING_ACCOUNT_CUSTOMER_RESPONSE,
  customer: response,
  accountId
})

export const fetchExistingAccountCustomer = (accountId) => (dispatch) => {
  if (!accountId) {
    return Promise.resolve()
  }

  dispatch(fetchExistingAccountCustomerRequest(accountId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/customer`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchExistingAccountCustomerResponse(
          accountId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const fetchExistingMasterAccountCustomerRequest = (masterAccountId) => ({
  type: FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_REQUEST,
  masterAccountId
})

const fetchExistingMasterAccountCustomerResponse = (
  masterAccountId,
  response
) => ({
  type: FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_RESPONSE,
  customer: response,
  masterAccountId
})

export const fetchExistingMasterAccountCustomer =
  (masterAccountId) => (dispatch) => {
    if (!masterAccountId) {
      return Promise.resolve()
    }

    dispatch(fetchExistingMasterAccountCustomerRequest(masterAccountId))
    return $.ajax({
      method: 'GET',
      url: `/ad_champion/master_accounts/${masterAccountId}/customer`,
      dataType: 'json',
      success: (response) => {
        return dispatch(
          fetchExistingMasterAccountCustomerResponse(
            masterAccountId,
            camelCaseKeys(response, { deep: true })
          )
        )
      }
    })
  }

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST'
export const UPDATE_PAYMENT_METHOD_RESPONSE = 'UPDATE_PAYMENT_METHOD_RESPONSE'

const updateWebsitePaymentMethodRequest = (websiteId, token) => ({
  type: UPDATE_PAYMENT_METHOD_REQUEST,
  websiteId,
  token
})

const updateWebsitePaymentMethodResponse = (websiteId, token, response) => ({
  type: UPDATE_PAYMENT_METHOD_RESPONSE,
  websiteId,
  token,
  response
})

export const updateWebsitePaymentMethod =
  (websiteId, token) => async (dispatch) => {
    dispatch(updateWebsitePaymentMethodRequest(websiteId, token))
    var response = await $.ajax({
      url: `/ad_champion/websites/${websiteId}/customer/update_payment_method`,
      method: 'POST',
      data: snakeCaseKeys({
        token
      })
    })
    response = camelCaseKeys(response, { deep: true })
    dispatch(updateWebsitePaymentMethodResponse(websiteId, token, response))
    return response
  }
