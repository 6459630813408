import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  genProductDataAudit,
  fetchProductDataAudits,
  updateAuditSchedule
} from 'actions/inventories'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn
} from 'mdbreact'
import toastr from 'toastr'
import { websiteRetailInventoryIdsSelector } from 'selectors/websites'
import {
  inventoryProductDataAuditsSelector,
  inventoryAuditScheduleMessageSelector
} from 'selectors/inventory'
import changeCase from 'change-case'

const auditSchedules = ['quarterly', 'monthly', 'weekly', 'never']

export class SaveProductDataAudit extends Component {
  static propTypes = {
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    allAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduleMessage: PropTypes.string.isRequired,
    toastr: PropTypes.object.isRequired,
    genProductDataAudit: PropTypes.func.isRequired,
    fetchProductDataAudits: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  constructor(props) {
    super(props)
    this.state = { generating: false }
  }

  async handleChangeSchedule(schedule) {
    const { updateAuditSchedule, retailInventoryIds } = this.props
    await updateAuditSchedule(retailInventoryIds[0], schedule)
  }

  renderScheduleMessage(generatingAudit) {
    const { generating } = this.state
    const { scheduleMessage } = this.props

    return (
      <>
        <span>{scheduleMessage} </span>
        <MDBDropdown disabled={generating || !!!generatingAudit}>
          <MDBDropdownToggle>Change Schedule</MDBDropdownToggle>
          <MDBDropdownMenu>
            {auditSchedules.map((schedule) => (
              <MDBDropdownItem
                key={schedule}
                onClick={this.handleChangeSchedule.bind(this, schedule)}
              >
                {changeCase.titleCase(schedule)}
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>
        <span> or </span>
      </>
    )
  }

  async handlePrintAudit() {
    this.setState({ generating: true })
    const { toastr, genProductDataAudit, retailInventoryIds } = this.props
    toastr.info(
      'We are commencing your product data audit. You can stay on this screen and wait, or come back later.'
    )

    const auditUrl = await genProductDataAudit(retailInventoryIds[0])
    toastr.clear()
    if (auditUrl) {
      window.open(auditUrl, '_blank')
      toastr.success('FeedOps Audit PDF downloaded.')

      const { fetchProductDataAudits } = this.props
      await fetchProductDataAudits(retailInventoryIds[0])
    }
    this.setState({ generating: false })
  }

  render() {
    const { generating } = this.state
    const { allAudits, retailInventoryIds } = this.props
    const generatingAudit = allAudits.filter(
      (audit) => audit.url === 'Generating'
    )

    return retailInventoryIds && retailInventoryIds.length > 0 ? (
      <div className='generate-audit-container'>
        {this.renderScheduleMessage(generatingAudit)}
        <MDBBtn
          color='link'
          disabled={generating || !!!generatingAudit}
          onClick={this.handlePrintAudit.bind(this)}
        >
          Generate An Audit Now
        </MDBBtn>
      </div>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  retailInventoryIds: websiteRetailInventoryIdsSelector(state, props),
  allAudits: inventoryProductDataAuditsSelector(state, props),
  scheduleMessage: inventoryAuditScheduleMessageSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { genProductDataAudit, fetchProductDataAudits, updateAuditSchedule },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SaveProductDataAudit)
)
