import { createSelector } from 'reselect'
import { countries } from 'country-data'
import { stripProtocolFromWebsiteUrl } from 'util/website'
import { isBigCommerceAppSelector } from './app'
import { currentUserSelector, superUserSelector } from './user'
import { websiteListSelector } from './websites'

export const billingSelector = (state) => state.billing || {}

export const accountSubscriptionSelector = createSelector(
  [billingSelector],
  (billing) => billing.accountSubscription || {}
)

export const websiteSubscriptionSelector = createSelector(
  [billingSelector],
  (billing) => billing.websiteSubscription || {}
)

export const subscriptionSelector = createSelector(
  [billingSelector],
  (billing) => billing.subscription || {}
)

export const currentPlanIdSelector = createSelector(
  [subscriptionSelector],
  (s) => s.planId || ''
)

export const isManagedPlanSelector = createSelector(
  [currentPlanIdSelector],
  (planId) => (planId ? planId.includes('managed') : false)
)

export const currentSubscriptionStatusSelector = createSelector(
  [subscriptionSelector],
  (s) => s.status || ''
)

export const customerSelector = createSelector(
  [billingSelector],
  (billing) => billing.customer || {}
)

export const CUSTOMER_FEATURE_ASK_QUESTIONS = 'canAskQuestions'
export const CUSTOMER_FEATURE_BOOK_MEETINGS = 'canBookMeetings'
export const CUSTOMER_FEATURE_ADD_TEAM_MEMBERS = 'canAddTeamMembers'
export const CUSTOMER_FEATURE_ADD_WEBSITES = 'canAddWebsites'

const ALL_CUSTOMER_FEATURES = [
  CUSTOMER_FEATURE_ASK_QUESTIONS,
  CUSTOMER_FEATURE_BOOK_MEETINGS,
  CUSTOMER_FEATURE_ADD_TEAM_MEMBERS,
  CUSTOMER_FEATURE_ADD_WEBSITES
]

export const customerFeaturesSelector = createSelector(
  [customerSelector, superUserSelector],
  (c, s) =>
    s
      ? ALL_CUSTOMER_FEATURES.reduce((enabled, feature) => {
          enabled[feature] = true
          return enabled
        }, {})
      : (c.metaData && c.metaData.features) || {}
)

export const customerBillingAddressSelector = createSelector(
  customerSelector,
  (c) => c.billingAddress || {}
)

export const customerCountrySelector = createSelector(
  customerBillingAddressSelector,
  (ba) => ba.country
)

export const plansSelector = createSelector(
  [billingSelector],
  (billing) => billing.plans || {}
)

export const plansArraySelector = createSelector(plansSelector, (plans) =>
  Object.values(plans)
)

export const planCurrencies = createSelector(plansArraySelector, (plans) => [
  ...new Set(plans.map((p) => p.currencyCode))
])

export const customerCurrencySelector = createSelector(
  [customerCountrySelector, subscriptionSelector, planCurrencies],
  (countryCode, subscription, currencies) => {
    var currencyForCountry =
      countries[countryCode] && countries[countryCode].currencies[0]
    return (
      subscription.currencyCode ||
      (currencies.indexOf(currencyForCountry) >= 0 && currencyForCountry) ||
      'USD'
    )
  }
)

export const customerPreferredCurrencySelector = createSelector(
  [customerSelector],
  (customer) => {
    return customer.preferredCurrencyCode || 'USD'
  }
)

export const existingAccountForContactDetailsSelector = createSelector(
  [billingSelector],
  (billing) => billing.existingAccountForContactDetails || {}
)

export const existingAccountCustomerSelector = createSelector(
  [existingAccountForContactDetailsSelector],
  (account) => account.customer
)
export const existingAccountAccountTypeSelector = createSelector(
  [existingAccountForContactDetailsSelector],
  (account) => account.accountType
)
export const existingAccountAccountTypeIdSelector = createSelector(
  [existingAccountForContactDetailsSelector],
  (account) => account.accountTypeId
)

export const existingAccountTypeLabelSelector = createSelector(
  [
    existingAccountAccountTypeSelector,
    existingAccountAccountTypeIdSelector,
    currentUserSelector,
    websiteListSelector
  ],
  (accountType, accountId, user, websitesList) => {
    switch (accountType) {
      case 'master_accounts':
        return (
          (user.masterAccountList.find((ma) => ma.id === accountId) || {})
            .label || ''
        )
      case 'accounts':
        return (
          (user.accountList.find((a) => a.id === accountId) || {}).label || ''
        )
      case 'websites':
        return (
          stripProtocolFromWebsiteUrl(
            (websitesList.find((w) => w.id === accountId) || {}).url
          ) || ''
        )
      default:
        return ''
    }
  }
)

export const subscriptionPlanSelector = createSelector(
  [subscriptionSelector],
  (subscription) => subscription.plan || {}
)

export const subscriptionPlanNameSelector = createSelector(
  [subscriptionPlanSelector],
  (plan) => plan.name
)

export const subscriptionTrialEndSelector = createSelector(
  [subscriptionSelector],
  (subscription) => new Date(subscription.trialEnd * 1000)
)

export const subscriptionCurrencySelector = createSelector(
  [subscriptionSelector],
  (subscription) => subscription.currencyCode || 'USD'
)

export const subscriptionPlanAmountSelector = createSelector(
  [subscriptionSelector],
  (subscription) => subscription.planAmount / 100.0
)

export const subscriptionPlanUnitPriceSelector = createSelector(
  [subscriptionSelector],
  (s) => (s ? s.planUnitPrice / 100.0 : 0.0)
)

export const subscriptionIdSelector = createSelector(
  [subscriptionSelector],
  (s) => (s ? s.id : '')
)
export const PLAN_FEATURE_DIY = 'isDiy'
export const PLAN_FEATURE_CATEGORY_SEO_TITLES = 'canDoCategoryLevelSeoTitles'
export const PLAN_FEATURE_BULK_OPTIMISATION = 'canDoBulkOptimisation'
export const PLAN_FEATURE_GENERATE_FEEDS = 'canGenerateFeeds'

export const ALL_PLAN_FEATURES = [
  PLAN_FEATURE_DIY,
  PLAN_FEATURE_CATEGORY_SEO_TITLES,
  PLAN_FEATURE_BULK_OPTIMISATION,
  PLAN_FEATURE_GENERATE_FEEDS
]

export const planFeaturesSelector = createSelector(
  [subscriptionPlanSelector, superUserSelector],
  (plan, s) =>
    s
      ? ALL_PLAN_FEATURES.reduce((enabled, feature) => {
          enabled[feature] = true
          return enabled
        }, {})
      : plan.features || {}
)

const createPlanFeatureSelector = (requiredFeature) =>
  createSelector(
    [planFeaturesSelector],
    (features) => features[requiredFeature] || false
  )

export const planCanDoCategoryLevelSeoTitlesSelector =
  createPlanFeatureSelector(PLAN_FEATURE_CATEGORY_SEO_TITLES)

export const planCanGenerateFeedsSelector = createPlanFeatureSelector(
  PLAN_FEATURE_GENERATE_FEEDS
)

export const promptForCreditCardSelector = createSelector(
  [customerSelector, isBigCommerceAppSelector],
  (customer, isBigCommerce) =>
    isBigCommerce && customer && customer.cardStatus === 'no_card'
)
