import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import { MDBRow, MDBCol } from 'mdbreact'
import BackButton from '../../common/back_button'
import GoogleAdsConnectionCard from 'components/websites/merchant_center_diagnostics/overview/google_ads_connection_card'
import MicrosoftAdsConnectionCard from 'components/feed_ops/account_connection/microsoft_ads_connection_card'
import { channelSelector } from 'selectors/channel'
import {
  websiteAdwordsSelector,
  websiteMicrosoftAdwordsSelector
} from 'selectors/websites'
import { SUPPORTED_CHANNELS, CHANNEL_MICROSOFT } from 'util/supported_channels'

import './ads_account.scss'

export class ConnectAdsAccount extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS),
    adwordsAccount: PropTypes.object.isRequired,
    microsoftAdwordsAccount: PropTypes.object.isRequired
  }

  componentDidUpdate(prevProps) {
    const { channel, adwordsAccount, microsoftAdwordsAccount, history } =
      this.props
    const adwordsConnected = (
      channel === CHANNEL_MICROSOFT
        ? microsoftAdwordsAccount && microsoftAdwordsAccount.customerAccountId
        : adwordsAccount && adwordsAccount.adwordsAccountId
    )
      ? true
      : false

    if (adwordsConnected) {
      history.goBack()
    }
  }

  renderActions(colClassName) {
    return (
      <MDBRow className='actions-row'>
        <MDBCol />
        <MDBCol className={colClassName}>
          <BackButton label='GO BACK' float='left' />
        </MDBCol>
        <MDBCol />
      </MDBRow>
    )
  }

  renderConnectionByChannel() {
    const { channel } = this.props

    return channel === CHANNEL_MICROSOFT ? (
      <MicrosoftAdsConnectionCard />
    ) : (
      <GoogleAdsConnectionCard mcLinkRequired={true} showFooter={false} />
    )
  }

  renderConnectionComponent(colClassName) {
    return (
      <MDBRow className='options-row'>
        <MDBCol />
        <MDBCol className={colClassName}>
          {this.renderConnectionByChannel()}
        </MDBCol>
        <MDBCol />
      </MDBRow>
    )
  }

  render() {
    return (
      <div className={`connect-main-content${isMobileOS ? '-mobile' : ''}`}>
        {this.renderActions(isMobileOS ? 'col-12' : 'col-8')}
        {this.renderConnectionComponent(isMobileOS ? 'col-12' : 'col-8')}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    channel: channelSelector(state, props),
    adwordsAccount: websiteAdwordsSelector(state, props),
    microsoftAdwordsAccount: websiteMicrosoftAdwordsSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const ConnectAdsAccountWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectAdsAccount)

export default withRouter(ConnectAdsAccountWithRedux)
