import {
  FETCH_RULES_RESPONSE,
  PREVIEW_RULES_RESPONSE
} from 'actions/inventories/rules'
import actionTypesReducer from './rules/action_types'
const initialState = { actionTypes: {} }

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RULES_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          rules: action.rules
        }
      }
    case PREVIEW_RULES_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          ...state[action.websiteId],
          previewData: action.preview
        }
      }
    default:
      return {
        ...state,
        actionTypes: actionTypesReducer(state.actionTypes || {}, action)
      }
  }
}
