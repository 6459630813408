import { createSelector } from 'reselect'
import _ from 'lodash'

export const accountsSelector = (state) => state.accounts || {}

export const accountSelector = (accountId) =>
  createSelector([accountsSelector], (accounts) => accounts[accountId] || {})

export const accountUsersSelector = (accountId) =>
  createSelector([accountSelector(accountId)], (account) =>
    _.orderBy(account.users || [], 'name', 'asc')
  )
