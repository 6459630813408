import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import { setBookAMeetingModalOpen } from 'actions/app'
import {
  bookAMeetingModalOpenSelector,
  bookAMeetingMeetingTypeSelector,
  bookAMeetingJsonDataSelector
} from 'selectors/app'
import { accountTypeSelector } from 'selectors/user'
import { currentUserSelector } from 'selectors/user'

export class BookAMeetingModal extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    accountType: PropTypes.string.isRequired,
    bookAMeetingModalOpen: PropTypes.bool.isRequired,
    bookAMeetingMeetingType: PropTypes.string.isRequired,
    bookAMeetingJsonData: PropTypes.object,
    setBookAMeetingModalOpen: PropTypes.func.isRequired
  }

  toggleBookAMeetingModal() {
    const { setBookAMeetingModalOpen, bookAMeetingModalOpen } = this.props
    setBookAMeetingModalOpen(!bookAMeetingModalOpen)
  }

  getAppointmentType() {
    const { bookAMeetingMeetingType } = this.props
    switch (bookAMeetingMeetingType) {
      case 'general':
        return process.env.ACUITY_SCHEDULING_APPOINTMENT_TYPE_GENERAL
      case 'recommendation':
        return process.env.ACUITY_SCHEDULING_APPOINTMENT_TYPE_RECOMMENDATION
      case 'integration':
        return process.env
          .ACUITY_SCHEDULING_APPOINTMENT_TYPE_PLATFORM_INTEGRATION
      case 'feedops':
        return process.env.ACUITY_SCHEDULING_APPOINTMENT_TYPE_FEEDOPS
      default:
        return process.env.ACUITY_SCHEDULING_APPOINTMENT_TYPE_GENERAL
    }
  }

  getSchedulingLink() {
    const { user, location, bookAMeetingJsonData } = this.props
    const ownerId = process.env.ACUITY_SCHEDULING_OWNER_ID
    var appointmentType = this.getAppointmentType()
    const { firstName, lastName, mobilePhone, workEmail, company } =
      user.profile || {}
    const email = workEmail || user.email
    const appUriFieldId = process.env.ACUITY_SCHEDULING_APP_URI_FIELD_ID
    const { pathname } = location
    const appUri = `${process.env.APP_URI_PREFIX}${pathname}`
    const jsonDataFieldId = process.env.ACUITY_SCHEDULING_JSON_DATA_FIELD_ID
    const companyNameFieldId =
      process.env.ACUITY_SCHEDULING_COMPANY_NAME_FIELD_ID

    return `https://app.acuityscheduling.com/schedule.php?
            owner=${ownerId}&
            appointmentType=${appointmentType}&
            firstName=${firstName}&
            lastName=${lastName}&
            phone=${mobilePhone || ''}&
            email=${email}&
            field:${appUriFieldId}=${appUri}&
            field:${jsonDataFieldId}=${JSON.stringify(bookAMeetingJsonData)}&
            field:${companyNameFieldId}=${company}`
  }

  render() {
    const { bookAMeetingModalOpen } = this.props
    return (
      <MDBModal
        isOpen={bookAMeetingModalOpen}
        toggle={this.toggleBookAMeetingModal.bind(this)}
        size='lg'
      >
        <MDBModalHeader toggle={this.toggleBookAMeetingModal.bind(this)}>
          Schedule Appointment
        </MDBModalHeader>
        <MDBModalBody>
          <iframe
            src={process.env.BOOK_A_MEETING_LINK}
            title='Schedule Appointment'
            className='meetings-iframe-container'
            width='100%'
            height='700'
            frameBorder='0'
          />
        </MDBModalBody>
      </MDBModal>
    )
  }
}

const mapStateToProps = (state, props) => {
  const accountType = accountTypeSelector(state, props)
  return {
    accountType,
    bookAMeetingModalOpen: bookAMeetingModalOpenSelector(state),
    bookAMeetingMeetingType: bookAMeetingMeetingTypeSelector(state),
    bookAMeetingJsonData: bookAMeetingJsonDataSelector(state),
    user: currentUserSelector(state)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setBookAMeetingModalOpen }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookAMeetingModal)
)
