import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, change } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { MDBBtn } from 'mdbreact'
import RulesEngineModal from './rules_engine_modal'

import './add_new_rule.scss'

export class AddNewRule extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOnRulesChange(rule) {
    const { formValues, change } = this.props
    const { rules } = formValues

    change(REDUX_FORM_NAME, 'rules', [
      ...rules,
      { ...rule, position: rules.length + 1, draftStatus: 'Added' }
    ])
  }

  renderModal() {
    const { isOpen } = this.state

    return (
      <RulesEngineModal
        isOpen={isOpen}
        rule={{ conditions: [], actions: [] }}
        onModalStateChange={() => this.setState({ isOpen: !isOpen })}
        handleOnRulesChange={this.handleOnRulesChange.bind(this)}
      />
    )
  }

  render() {
    return (
      <div className='add-new-rule-btn'>
        <MDBBtn
          tag='span'
          color='custom'
          onClick={() => this.setState({ isOpen: true })}
        >
          <i className='fa fa-plus' /> Add Rule
        </MDBBtn>
        {this.renderModal()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewRule)
)
