import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBSelect,
  MDBIcon
} from 'mdbreact'
import { enableDisableChannelSettings } from 'actions/channel_settings'
import {
  listMerchantCenterAccounts,
  listMcCatalogs,
  selectMerchantCenterAccount
} from 'actions/microsoft/merchant_center'
import {
  fetchWebsiteDetail,
  fetchWebsiteMerchantCenterStatus
} from 'actions/websites'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import {
  merchantCenterAccountsSelector,
  merchantCenterCatalogsSelector
} from 'selectors/microsoft/merchant_center'
import { getMicrosoftUser } from 'selectors/microsoft/user'
import {
  websiteIdSelector,
  websiteMicrosoftMerchantCenterSelector
} from 'selectors/websites'
import { microsoftAuthRequestToken } from 'util/microsoft/microsoft_apis'
import { CHANNEL_MICROSOFT } from 'util/supported_channels'

const MERCHANT_CENTER_SCOPE = 'https://ads.microsoft.com/msads.manage'

export class MicrosoftMerchantCenterConnection extends Component {
  static propTypes = {
    fetchWebsiteDetail: PropTypes.func.isRequired,
    fetchWebsiteMerchantCenterStatus: PropTypes.func.isRequired,
    listMerchantCenterAccounts: PropTypes.func.isRequired,
    listMcCatalogs: PropTypes.func.isRequired,
    merchantCenterAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    merchantCenterCatalogs: PropTypes.arrayOf(PropTypes.object),
    websiteId: PropTypes.number.isRequired,
    microsoftAuthRequestToken: PropTypes.func.isRequired,
    existingMerchantCenterAccountId: PropTypes.number.isRequired,
    selectMerchantCenterAccount: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    appId: PropTypes.string.isRequired,
    enableDisableChannelSettings: PropTypes.func.isRequired
  }

  static defaultProps = {
    microsoftAuthRequestToken
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: true,
      busy: true,
      fetchingAccounts: false,
      fetchingCatalogs: false,
      firstLoadComplete: false,
      merchantCenterAccountId: null,
      catalogId: null
    }
  }

  async componentDidMount() {
    await this.fetchWebsiteDetail()

    const { existingMerchantCenterAccountId } = this.props
    this.setState({
      firstLoadComplete: true,
      merchantCenterAccountId: existingMerchantCenterAccountId,
      busy: false
    })
  }

  async componentDidUpdate(prevProps) {
    const {
      websiteId,
      grantedScope,
      refreshToken,
      existingMerchantCenterAccountId
    } = this.props
    if (prevProps.websiteId !== websiteId) {
      await this.fetchWebsiteDetail()
    }

    if (
      (prevProps.grantedScope !== grantedScope ||
        prevProps.refreshToken !== refreshToken) &&
      this.scopeGranted()
    ) {
      this.fetchMerchantCenterAccounts()
      return null
    }

    if (
      prevProps.existingMerchantCenterAccountId !==
      existingMerchantCenterAccountId
    ) {
      this.setState({
        merchantCenterAccountId: existingMerchantCenterAccountId
      })
    }
  }

  async fetchWebsiteDetail() {
    const { fetchWebsiteDetail, websiteId } = this.props

    if (websiteId > 0) {
      this.setState({ busy: true })
      await fetchWebsiteDetail(websiteId)
      this.setState({ busy: false })
    }
  }

  async fetchMerchantCenterAccounts() {
    const { listMerchantCenterAccounts } = this.props
    this.setState({
      busy: true,
      fetchingAccounts: true
    })
    try {
      await listMerchantCenterAccounts()
    } catch (error) {
      toastr.error(error.responseJSON.error)
    }
    this.setState({
      busy: false,
      firstLoadComplete: true,
      fetchingAccounts: false
    })
  }

  async startLogin() {
    const { microsoftAuthRequestToken } = this.props
    await microsoftAuthRequestToken(MERCHANT_CENTER_SCOPE)
  }

  handleChange(e) {
    const { merchantCenterAccountId } = this.state
    if (e[0] && e[0] !== merchantCenterAccountId) {
      this.setState({ merchantCenterAccountId: e[0], catalogId: null })
      this.fetchCatalogs(e[0])
    }
  }

  handleCatalogChange(e) {
    const { catalogId } = this.state
    if (e[0] && e[0] !== catalogId) {
      this.setState({ catalogId: e[0] })
    }
  }

  scopeGranted() {
    const { grantedScope } = this.props
    if (grantedScope.search(MERCHANT_CENTER_SCOPE) < 0) {
      return false
    }

    return true
  }

  selectedMerchantCenterAccount() {
    const { merchantCenterAccounts } = this.props
    const { merchantCenterAccountId } = this.state
    return merchantCenterAccounts.find((a) => a.id === merchantCenterAccountId)
  }

  async fetchCatalogs(merchantCenterAccountId) {
    const { listMcCatalogs, merchantCenterAccounts } = this.props
    const merchantCenter = merchantCenterAccounts.find(
      (mc) => mc.id === merchantCenterAccountId
    )
    const { customerId, customerAccountId } = merchantCenter

    this.setState({ fetchingCatalogs: true })
    await listMcCatalogs(merchantCenterAccountId, customerId, customerAccountId)
    this.setState({ fetchingCatalogs: false })
  }

  renderCatalogsSelect() {
    const { fetchingCatalogs, merchantCenterAccountId, catalogId } = this.state

    if (merchantCenterAccountId) {
      const { merchantCenterCatalogs } = this.props
      if (fetchingCatalogs) {
        return (
          <IndeterminateProgressIndicator message='Loading your catalogs (may take a few minutes).' />
        )
      }

      var options = merchantCenterCatalogs.map((c) => ({
        text: `${c.name} (${c.id})`,
        value: `${c.id}`
      }))

      var selectedOption = options.find((o) => o.value === catalogId) || {}
      const disabled = options.length === 0 ? 'disabled' : ''

      return (
        <>
          <MDBSelect
            className={disabled + 'merchant-center-selector'}
            options={options}
            labelClass='merchantCenterSelectField'
            selected={selectedOption.text || 'Select Catalog'}
            getValue={this.handleCatalogChange.bind(this)}
          />
          {catalogId && this.renderUploadFeedButton()}
        </>
      )
    }

    return null
  }

  renderSelect() {
    const { merchantCenterAccounts } = this.props
    const { fetchingAccounts, merchantCenterAccountId } = this.state
    if (fetchingAccounts) {
      return (
        <IndeterminateProgressIndicator message='Loading your accounts (may take a few minutes).' />
      )
    }

    var options = merchantCenterAccounts.map((a) => ({
      text: `${a.name} (${a.id})`,
      value: `${a.id}`
    }))

    var selectedOption =
      options.find((o) => o.value === merchantCenterAccountId) || {}

    const disabled = options.length === 0 ? 'disabled' : ''
    return (
      <div>
        {merchantCenterAccounts.length === 0 ? (
          'There is no Merchant Center available under the current Microsoft account. Please use another Microsoft account.'
        ) : (
          <>
            <MDBSelect
              className={disabled + 'merchant-center-selector'}
              options={options}
              labelClass='merchantCenterSelectField'
              selected={selectedOption.text || 'Select Merchant Center'}
              getValue={this.handleChange.bind(this)}
            />
            {this.renderCatalogsSelect()}
          </>
        )}
      </div>
    )
  }

  conditionallyRenderNext() {
    return this.scopeGranted() ? (
      this.renderSelect()
    ) : (
      <MDBBtn onClick={this.startLogin.bind(this)}>
        Authorize Merchant Center access
      </MDBBtn>
    )
  }

  renderSignedInAs() {
    const { email } = this.props
    if (!email) {
      return null
    }

    var signedInAsText = 'Using the wrong Microsoft account?'

    return (
      <p>
        Signed in as {email}. {signedInAsText}{' '}
        <MDBBtn className='btn-href-style' onClick={this.startLogin.bind(this)}>
          Change
        </MDBBtn>
      </p>
    )
  }

  async connectMerchantCenterAccount() {
    const { merchantCenterAccounts, selectMerchantCenterAccount, websiteId } =
      this.props
    const { merchantCenterAccountId, catalogId } = this.state
    const merchantCenter = merchantCenterAccounts.find(
      (mc) => mc.id === merchantCenterAccountId
    )
    const { name, customerId, customerAccountId } = merchantCenter
    this.setState({ busy: true })

    try {
      await selectMerchantCenterAccount(
        websiteId,
        merchantCenterAccountId,
        name,
        catalogId,
        customerId,
        customerAccountId
      )
      await this.afterConnection()
    } catch (error) {
      this.setState({ busy: false })
    }
  }

  async afterConnection() {
    const {
      fetchWebsiteDetail,
      fetchWebsiteMerchantCenterStatus,
      websiteId,
      enableDisableChannelSettings
    } = this.props
    await enableDisableChannelSettings(websiteId, CHANNEL_MICROSOFT, true)
    await fetchWebsiteDetail(websiteId)
    await fetchWebsiteMerchantCenterStatus(websiteId)
    this.setState({ busy: false })
    this.props.history.goBack()
  }

  renderUploadFeedButton() {
    const { busy } = this.state
    var uploadFeedButtonText
    var uploadingFeedButtonText

    uploadFeedButtonText = 'Connect Merchant Center'
    uploadingFeedButtonText = 'Connecting Merchant Center'

    const buttonText = busy ? (
      <div>
        <MDBIcon spin fixed icon='spinner' />
        {uploadingFeedButtonText}
      </div>
    ) : (
      uploadFeedButtonText
    )

    return (
      <MDBBtn
        disabled={busy}
        onClick={this.connectMerchantCenterAccount.bind(this)}
        className='upload-button'
      >
        {buttonText}
      </MDBBtn>
    )
  }

  renderEditable() {
    const { email } = this.props
    return (
      <div className='merchant-center-form'>
        {email.length === 0 && (
          <p>To connect an existing Merchant Center, sign in with Microsoft.</p>
        )}
        <div className={'connect-or-create'}>{this.renderSignedInAs()}</div>
        {this.conditionallyRenderNext()}
      </div>
    )
  }

  render() {
    const { firstLoadComplete } = this.state
    if (!firstLoadComplete) {
      return <IndeterminateProgressIndicator />
    }

    return (
      <MDBCard className='product-measures-diagnostics'>
        <MDBCardBody>
          <MDBCardTitle>Merchant Center Connection</MDBCardTitle>
          {this.renderEditable()}
        </MDBCardBody>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  const { email, refreshToken, scope } = getMicrosoftUser(() => state)
  const { merchantCenterAccountId } = websiteMicrosoftMerchantCenterSelector(
    state,
    props
  )
  return {
    websiteId,
    email,
    refreshToken,
    grantedScope: scope,
    merchantCenterAccounts: merchantCenterAccountsSelector(state),
    merchantCenterCatalogs: merchantCenterCatalogsSelector(state),
    existingMerchantCenterAccountId: merchantCenterAccountId
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteDetail,
      fetchWebsiteMerchantCenterStatus,
      listMerchantCenterAccounts,
      listMcCatalogs,
      selectMerchantCenterAccount,
      enableDisableChannelSettings
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MicrosoftMerchantCenterConnection)
)
