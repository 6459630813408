import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  getAttributeOptimisationSuggestion,
  getDisplayValue
} from '../../common/attributes_util'
import { channelSelector } from 'selectors/channel'
import { taxonomiesSelector } from 'selectors/google/taxonomies'
import {
  websiteProductReplacementsMetaDataSelector,
  websiteProductOptimisationSuggestionsSelector
} from 'selectors/product_feeds/replacements'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class ColumnSuggestionContent extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    attributeName: PropTypes.string.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    googleTaxonomies: PropTypes.object.isRequired,
    metaData: PropTypes.object.isRequired,
    optimisationSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  getDisplayValue(value) {
    const { attributeName, metaData, googleTaxonomies, channel } = this.props
    return getDisplayValue(
      {
        attributeName,
        currency: metaData.currency,
        googleTaxonomies,
        channel
      },
      value
    )
  }

  render() {
    const { rowData, optimisationSuggestions, attributeName } = this.props
    const suggestionValue = getAttributeOptimisationSuggestion(
      optimisationSuggestions,
      rowData,
      attributeName
    )

    if (suggestionValue) {
      return (
        <div
          className='suggested-value'
          dangerouslySetInnerHTML={{
            __html: `Suggested: ${this.getDisplayValue(suggestionValue)}`
          }}
        />
      )
    }

    return null
  }
}

export const mapStateToProps = (state, props) => ({
  channel: channelSelector(state, props),
  googleTaxonomies: taxonomiesSelector(state, props),
  metaData: websiteProductReplacementsMetaDataSelector(state, props),
  optimisationSuggestions: websiteProductOptimisationSuggestionsSelector(
    state,
    props
  )
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ColumnSuggestionContent)
)
