import camelCaseKeys from 'camelcase-keys'
import { schemeSelector } from 'selectors/build_initilalizer'

import {
  fetchAccountAdvertisingBudgets,
  fetchAccountAdvertisingGoalType,
  checkAccountPublicationAccountTypes
} from './_advertising/accounts'
import {
  fetchBudgetCategoryAdvertisingBudgets,
  fetchBudgetCategoryAdvertisingGoals,
  fetchBudgetCategoryAdvertisingGoalType,
  updateBudgetCategoryAdvertisingGoals,
  updateBudgetCategoryAdvertisingGoalType,
  updateBudgetCategoryAdvertisingBudgets
} from './_advertising/budget_categories'
import {
  fetchMasterAccountAdvertisingBudgets,
  fetchMasterAccountAdvertisingGoalType,
  checkMasterAccountPublicationAccountTypes
} from './_advertising/master_accounts'
import {
  fetchWebsiteAdvertisingBudgets,
  fetchWebsiteAdvertisingGoals,
  fetchWebsiteAdvertisingGoalType,
  fetchWebsiteAdvertisingPlan,
  updateWebsiteAdvertisingBudgets,
  updateWebsiteAdvertisingGoalType,
  updateWebsiteAdvertisingGoals,
  checkWebsitePublicationAccountTypes
} from './_advertising/websites'

export const FETCH_ADVERTISING_GOAL_TYPE_REQUEST =
  'FETCH_ADVERTISING_GOAL_TYPE_REQUEST'
export const FETCH_ADVERTISING_GOAL_TYPE_SUCCESS =
  'FETCH_ADVERTISING_GOAL_TYPE_SUCCESS'
export const UPDATE_ADVERTISING_GOAL_TYPE_REQUEST =
  'UPDATE_ADVERTISING_GOAL_TYPE_REQUEST'
export const UPDATE_ADVERTISING_GOAL_TYPE_SUCCESS =
  'UPDATE_ADVERTISING_GOAL_TYPE_SUCCESS'
export const FETCH_ADVERTISING_BUDGETS_REQUEST =
  'FETCH_ADVERTISING_BUDGETS_REQUEST'
export const FETCH_ADVERTISING_BUDGETS_SUCCESS =
  'FETCH_ADVERTISING_BUDGETS_SUCCESS'
export const UPDATE_ADVERTISING_BUDGETS_REQUEST =
  'UPDATE_ADVERTISING_BUDGETS_REQUEST'
export const UPDATE_ADVERTISING_BUDGETS_SUCCESS =
  'UPDATE_ADVERTISING_BUDGETS_RESPONSE'
export const FETCH_ADVERTISING_GOALS_REQUEST = 'FETCH_ADVERTISING_GOALS_REQUEST'
export const FETCH_ADVERTISING_GOALS_SUCCESS = 'FETCH_ADVERTISING_GOALS_SUCCESS'
export const UPDATE_ADVERTISING_GOALS_REQUEST =
  'UPDATE_ADVERTISING_GOALS_REQUEST'
export const UPDATE_ADVERTISING_GOALS_SUCCESS =
  'UPDATE_ADVERTISING_GOALS_RESPONSE'
export const CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST =
  'CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST'
export const CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS =
  'CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS'

export const FETCH_ADVERTISING_STATUS_REQUEST =
  'FETCH_ADVERTISING_STATUS_REQUEST'
export const FETCH_ADVERTISING_STATUS_SUCCESS =
  'FETCH_ADVERTISING_STATUS_SUCCESS'

const throwUnhandledAccountType = (accountType) => {
  throw new Error(`unhandled accountType ${accountType}`)
}

export { fetchWebsiteAdvertisingPlan }
export const fetchAdvertisingBudgets = (accountType, id, channel) => {
  switch (accountType) {
    case 'accounts':
      return fetchAccountAdvertisingBudgets(accountType, id, channel)
    case 'budget_categories':
      return fetchBudgetCategoryAdvertisingBudgets(id)
    case 'master_accounts':
      return fetchMasterAccountAdvertisingBudgets(accountType, id, channel)
    case 'websites':
      return fetchWebsiteAdvertisingBudgets(accountType, id, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const fetchAdvertisingGoals = (accountType, id, goalType, channel) => {
  switch (accountType) {
    case 'budget_categories':
      return fetchBudgetCategoryAdvertisingGoals(id, goalType)
    case 'websites':
      return fetchWebsiteAdvertisingGoals(id, goalType, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const fetchAdvertisingGoalType = (accountType, id, channel) => {
  switch (accountType) {
    case 'accounts':
      return fetchAccountAdvertisingGoalType(accountType, id, channel)
    case 'budget_categories':
      return fetchBudgetCategoryAdvertisingGoalType(id)
    case 'master_accounts':
      return fetchMasterAccountAdvertisingGoalType(accountType, id, channel)
    case 'websites':
      return fetchWebsiteAdvertisingGoalType(accountType, id, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const updateAdvertisingBudgets = (accountType, id, budgets, channel) => {
  switch (accountType) {
    case 'budget_categories':
      return updateBudgetCategoryAdvertisingBudgets(id, budgets)
    case 'websites':
      return updateWebsiteAdvertisingBudgets(id, budgets, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const updateAdvertisingGoals = (
  accountType,
  id,
  goalType,
  goals,
  channel
) => {
  switch (accountType) {
    case 'budget_categories':
      return updateBudgetCategoryAdvertisingGoals(id, goalType, goals)
    case 'websites':
      return updateWebsiteAdvertisingGoals(id, goalType, goals, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const updateAdvertisingGoalType = (
  accountType,
  id,
  goalType,
  channel
) => {
  switch (accountType) {
    case 'budget_categories':
      return updateBudgetCategoryAdvertisingGoalType(id, goalType)
    case 'websites':
      return updateWebsiteAdvertisingGoalType(id, goalType, channel)
    default:
      throwUnhandledAccountType(accountType)
  }
}

export const checkPublicationAccountTypes = (accountType, id) => {
  switch (accountType) {
    case 'websites':
      return checkWebsitePublicationAccountTypes(accountType, id)
    case 'accounts':
      return checkAccountPublicationAccountTypes(accountType, id)
    case 'master_accounts':
      return checkMasterAccountPublicationAccountTypes(accountType, id)
    default:
      throwUnhandledAccountType(accountType)
  }
}

const requestAdvertisingStatus = (websiteId, buildType) => ({
  type: FETCH_ADVERTISING_STATUS_REQUEST,
  websiteId,
  buildType
})

const receiveAdvertisingStatusSuccess = (
  websiteId,
  buildType,
  advertisingStatus
) => ({
  type: FETCH_ADVERTISING_STATUS_SUCCESS,
  websiteId,
  buildType,
  advertisingStatus
})

export const fetchAdvertisingStatus =
  (websiteId, buildType, getSchemeId = schemeSelector) =>
  (dispatch) => {
    const schemeId = getSchemeId({}, buildType)
    dispatch(requestAdvertisingStatus(websiteId, buildType))
    return $.ajax({
      method: 'GET',
      url: `/ad_champion/advertising/${websiteId}/status?scheme_id=${schemeId}`,
      dataType: 'json',
      success: (response) => {
        return dispatch(
          receiveAdvertisingStatusSuccess(
            websiteId,
            buildType,
            camelCaseKeys(response, { deep: true })
          )
        )
      }
    })
  }
