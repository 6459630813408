import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  getAllFilterableAttributes,
  attributeCategoryNames
} from '../common/attributes_util'
import { addNewFilters } from 'components/feed_ops/common/popover/popover_util'
import AttributePopoverContainer from './popover_section/attribute_popover_container'
import ProductFilterPopoverContainer from './popover_section/product_filter_popover_container'
import ActionFilterPopoverContainer from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal/action_filter_popover_container'
import { RULE_ACTIONS_NAME } from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal/rules_engine_util'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'

export class PopoverSection extends Component {
  static propTypes = {
    sectionFor: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClosePopover: PropTypes.func.isRequired,
    updateAttributeFilters: PropTypes.func.isRequired
  }

  static defaultProps = {
    sectionFor: 'Product'
  }

  constructor() {
    super()
    this.state = {
      availableAttributes: [],
      categoryNames: [],
      selectedAttribute: null
    }
  }

  updateAvailableAttributesAndCategoryNames() {
    const { sectionFor, attributes, appliedFilters } = this.props
    var availableAttributes = attributes
    if (sectionFor === RULE_ACTIONS_NAME) {
      availableAttributes = getAllFilterableAttributes(
        attributes,
        appliedFilters
      )
    }
    const categoryNames = attributeCategoryNames(availableAttributes)

    this.setState({ availableAttributes, categoryNames })
  }

  componentDidMount() {
    this.updateAvailableAttributesAndCategoryNames()
  }

  componentDidUpdate(prevProps) {
    const { appliedFilters } = this.props

    if (!_.isEqual(appliedFilters, prevProps.appliedFilters)) {
      this.updateAvailableAttributesAndCategoryNames()
    }
  }

  handleSelectedAttributeChange(selectedAttribute) {
    this.setState({ selectedAttribute })
  }

  handleClosePopover() {
    const { handleClosePopover } = this.props
    handleClosePopover()
  }

  handleApplyFilterClick(
    attributeName,
    selectedFilterOption,
    filterValues,
    metaData = {}
  ) {
    const { appliedFilters, updateAttributeFilters } = this.props
    const updatedAppliedFilters = addNewFilters(
      appliedFilters,
      attributeName,
      selectedFilterOption,
      filterValues,
      metaData
    )

    updateAttributeFilters(updatedAppliedFilters)
  }

  conditionallyRenderFilterPopoverContainer() {
    const { selectedAttribute } = this.state
    const { sectionFor } = this.props

    return sectionFor === RULE_ACTIONS_NAME ? (
      <ActionFilterPopoverContainer
        selectedAttribute={selectedAttribute}
        handleClosePopover={this.handleClosePopover.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    ) : (
      <ProductFilterPopoverContainer
        selectedAttribute={selectedAttribute}
        handleClosePopover={this.handleClosePopover.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    )
  }

  render() {
    const { selectedAttribute, availableAttributes, categoryNames } = this.state
    const selectedAttributeEmpty = _.isEmpty(selectedAttribute)

    return selectedAttributeEmpty ? (
      <AttributePopoverContainer
        availableAttributes={availableAttributes}
        categoryNames={categoryNames}
        handleSelectedAttributeChange={this.handleSelectedAttributeChange.bind(
          this
        )}
      />
    ) : (
      this.conditionallyRenderFilterPopoverContainer()
    )
  }
}

export const mapStateToProps = (state, props) => ({
  attributes: websiteAllAttributesSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopoverSection)
)
