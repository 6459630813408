import { createSelector } from 'reselect'
import { websiteIdSelector } from 'selectors/websites'

export const rulesSelector = (state) => state.rules || {}

export const websiteRulesSelector = createSelector(
  [rulesSelector, websiteIdSelector],
  (rules, websiteId) => rules[websiteId] || {}
)

export const actionTypesSelector = createSelector(
  [rulesSelector],
  (rules) => rules.actionTypes || {}
)

export const actionTypeSelector = (actionTypeId) =>
  createSelector(
    [actionTypesSelector],
    (actionTypes) => actionTypes[actionTypeId] || {}
  )
