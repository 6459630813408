import { FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_SUCCESS } from 'actions/performance/websites'
import {
  FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_SUCCESS,
  FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS
} from 'actions/google/_performance/budget_categories'

const initialState = {
  accounts: {},
  masterAccounts: {},
  websites: {},
  budgetCategories: {}
}

export default (state = initialState, action) => {
  const budgetCategoryData =
    (action.budgetCategoryId &&
      state.budgetCategories[action.budgetCategoryId]) ||
    {}
  const websiteData =
    (action.websiteId && state.websites[action.websiteId]) || {}

  switch (action.type) {
    case FETCH_WEBSITE_PERFORMANCE_BY_BUDGET_CATEGORY_SUCCESS:
      return {
        ...state,
        websites: {
          ...state.websites,
          [action.websiteId]: {
            ...websiteData,
            websiteByBudgetCategory: {
              budgetCategoryReports: action.websiteReport,
              count: action.count,
              page: action.page,
              perPage: action.perPage,
              dateRange: action.dateRange
            }
          }
        }
      }
    case FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: {
            ...budgetCategoryData,
            ...action.budgetCategoryReport
          }
        }
      }
    case FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: {
            ...budgetCategoryData,
            performanceByCampaignType: action.budgetCategoryReport
          }
        }
      }
    default:
      return state
  }
}
