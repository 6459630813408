import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Button, Container } from 'reactstrap'
import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import toastr from 'toastr'

import { enableDisableChannelSettings } from 'actions/channel_settings'
import { fetchWebsiteDetail } from 'actions/websites'
import ChannelJustification from './channel_justification'
import {
  websiteIdSelector,
  websiteInventoryIdWithDefaultSelector,
  websiteFeedByInventoryIdAndChannelSelector
} from 'selectors/websites'
import {
  getDisplayName,
  CHANNEL_GOOGLE,
  CHANNEL_MICROSOFT
} from 'util/supported_channels'
import timeAgoInWords from 'util/time_ago_in_words'

import './item.scss'

export class ConnectedChannelItem extends Component {
  static propTypes = {
    channelSetting: PropTypes.object.isRequired,
    websiteId: PropTypes.number.isRequired,
    feed: PropTypes.object.isRequired,
    navigator: PropTypes.object.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    enableDisableChannelSettings: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr,
    navigator: global.navigator
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  renderTitle() {
    const { channelSetting } = this.props
    const { channel, enabled } = channelSetting
    const title = getDisplayName(channel)

    var classes = ['channel-title']
    if (enabled) {
      classes.push('enabled')
    }
    return (
      <span className={classes.join(' ')}>
        {enabled && <i className='fa fa-check-circle' />}
        {title}
      </span>
    )
  }

  renderLastGeneratedAt() {
    const { feed } = this.props
    const { lastGeneratedAt } = feed || {}

    return (
      <div className='last-generated-at'>
        <span>
          Last updated:{' '}
          {!lastGeneratedAt ? 'Never' : timeAgoInWords(lastGeneratedAt)}
        </span>
      </div>
    )
  }

  getUrl(location) {
    const { channelSetting, feed } = this.props
    const { channel } = channelSetting
    return `${location}?channel=${channel}&feed_id=${feed.id}`
  }

  navigateTo(url) {
    const { history } = this.props
    history.push(this.getUrl(url))
  }

  async fetchWebsiteDetails() {
    const { fetchWebsiteDetail, websiteId } = this.props
    await fetchWebsiteDetail(websiteId)
  }

  isMerchantCenterChannel() {
    const { channelSetting } = this.props
    const { channel } = channelSetting

    if (channel === CHANNEL_GOOGLE || channel === CHANNEL_MICROSOFT) {
      return true
    }
    return false
  }

  async handleConnectChannelClick(event) {
    const { enableDisableChannelSettings, websiteId, channelSetting } =
      this.props
    event.stopPropagation()

    if (this.isMerchantCenterChannel()) {
      this.navigateTo(this.getUrl('connect_merchant_center_account'))
    } else {
      await enableDisableChannelSettings(
        websiteId,
        channelSetting.channel,
        true
      )
      this.fetchWebsiteDetails()
      this.navigateTo(this.getUrl('feed_settings'))
    }
  }

  renderDisabledActions() {
    return (
      <Button
        color='primary'
        onClick={this.handleConnectChannelClick.bind(this)}
      >
        {this.isMerchantCenterChannel() ? 'Connect' : 'Enable'}
      </Button>
    )
  }

  handleFeedSettingsClick(event) {
    this.navigateTo(this.getUrl('feed_settings'))
    event.stopPropagation()
  }

  handlePlaybookClick(event) {
    this.navigateTo(this.getUrl('playbook'))
  }

  renderEnabledActions() {
    return (
      <AccordionActions>
        <Button
          color='outline'
          onClick={this.handlePlaybookClick.bind(this)}
          className='shadow-none float-right'
        >
          <i className='fa fa-book-open' /> Playbook
        </Button>
        <Button
          color='outline'
          className='shadow-none float-right'
          onClick={this.handleFeedSettingsClick.bind(this)}
        >
          <i className='fa fa-sliders-h' /> Settings
        </Button>
      </AccordionActions>
    )
  }

  renderSummary() {
    const { channelSetting } = this.props
    const { enabled } = channelSetting
    var summaryProps = {}
    if (!enabled) {
      summaryProps['expandIcon'] = <ExpandMoreIcon />
    }
    return (
      <AccordionSummary {...summaryProps}>
        <Container>
          {this.renderTitle()} <br />
          {enabled && this.renderLastGeneratedAt()}
        </Container>
        {enabled ? this.renderEnabledActions() : this.renderDisabledActions()}
      </AccordionSummary>
    )
  }

  render() {
    const { channelSetting } = this.props
    const { enabled, channel } = channelSetting
    var accordionProps = { className: 'channel-item', defaultExpanded: false }

    if (!enabled && channel === CHANNEL_GOOGLE) {
      accordionProps['defaultExpanded'] = true
    }
    return (
      <Accordion {...accordionProps}>
        {this.renderSummary()}
        {!enabled && (
          <AccordionDetails>
            <ChannelJustification channel={channel} />
          </AccordionDetails>
        )}
      </Accordion>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdWithDefaultSelector(state, props)
  return {
    websiteId: websiteIdSelector(state, props),
    feed: websiteFeedByInventoryIdAndChannelSelector(
      inventoryId,
      props.channelSetting.channel
    )(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWebsiteDetail, enableDisableChannelSettings },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedChannelItem)
)
