import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { MDBIcon } from 'mdbreact'
import LabelledValueWithExplicitEdit from 'components/ad_champion/common/labelled_value_input_with_explicit_edit'
import {
  fetchProductUrlSetting,
  saveProductUrlSetting
} from 'actions/product_url_settings'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { channelSelector } from 'selectors/channel'
import { websiteProductUrlSettingsSelector } from 'selectors/product_url_settings'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import {
  validate,
  findAdditionalTaggingDuplicates,
  findAdsTaggingDuplicates
} from './validate'
import { SUPPORTED_CHANNELS, hasFreeListings } from 'util/supported_channels'
import changeCase from 'change-case'

export class AnalyticsTaggingCard extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    feedId: PropTypes.number.isRequired,
    productUrlSettings: PropTypes.object.isRequired,
    fetchProductUrlSetting: PropTypes.func.isRequired,
    saveProductUrlSetting: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    syncErrors: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    hasFreeListings: PropTypes.bool.isRequired
  }

  async componentDidMount() {
    await this.fetchProductUrlSetting()
    const { productUrlSettings, initialize } = this.props
    initialize(productUrlSettings)
  }

  async componentDidUpdate(prevProps) {
    const { channel, feedId } = this.props
    if (prevProps.channel !== channel || prevProps.feedId !== feedId) {
      await this.fetchProductUrlSetting()
    }
    const { initialize, productUrlSettings } = this.props
    if (!_.isEqual(prevProps.productUrlSettings, productUrlSettings)) {
      initialize(productUrlSettings)
    }
  }

  async fetchProductUrlSetting() {
    const { fetchProductUrlSetting, websiteId, feedId } = this.props
    try {
      await fetchProductUrlSetting(websiteId, feedId)
    } catch (error) {
      toastr.error(error)
    }
  }

  handleAddLabelledValue(propertyName) {
    const { change, formValues } = this.props
    var index = formValues[propertyName].length
    change(REDUX_FORM_NAME, `${propertyName}[${index}]`, ['', ''])
  }

  handleCustomTagChange(propertyName, index, label, value, deleteTag) {
    const { change } = this.props
    if (index < 0) {
      change(REDUX_FORM_NAME, `${propertyName}`, value)
    } else if (deleteTag) {
      this.handleDeleteTag(propertyName, index)
    } else {
      change(REDUX_FORM_NAME, `${propertyName}[${index}]`, [label, value])
    }
  }

  handleDeleteTag(propertyName, index) {
    const { change, formValues } = this.props
    var array = [...formValues[propertyName]]
    if (propertyName === 'adsTagging' && array.length === 1) {
      change(REDUX_FORM_NAME, 'autoTracking', true)
    }
    array.splice(index, 1)
    change(REDUX_FORM_NAME, `${propertyName}`, array)
  }

  renderLabelledValueWithExplicitEdit(
    keyValue,
    setting,
    labelEditable,
    duplicates,
    index = -1
  ) {
    return (
      <LabelledValueWithExplicitEdit
        key={`${keyValue[0]}${index}`}
        keyValue={keyValue}
        index={index}
        setting={setting}
        labelEditable={labelEditable}
        changeFormValues={this.handleCustomTagChange.bind(this)}
        duplicates={duplicates}
      />
    )
  }

  renderCustomProductListingsSettings(propertyName, duplicates) {
    const { formValues } = this.props
    var customTags = []
    if (formValues.hasOwnProperty(propertyName)) {
      for (const index in formValues[propertyName]) {
        customTags.push(
          this.renderLabelledValueWithExplicitEdit(
            formValues[propertyName][index],
            propertyName,
            true,
            duplicates,
            index
          )
        )
      }
    }
    return customTags
  }

  renderAddCustomButton(setting) {
    return (
      <div className='add-custom-button'>
        <MDBIcon
          icon='plus-circle'
          onClick={this.handleAddLabelledValue.bind(this, setting)}
        />
      </div>
    )
  }

  renderErrors(setting, duplicates) {
    const { syncErrors } = this.props
    const errors = syncErrors[setting]
    return (
      <>
        {errors && errors.includes('empty') && (
          <div className='error'>There are {errors}</div>
        )}
        {duplicates.length > 0 && (
          <div className='error'>There are duplicate parameters</div>
        )}
      </>
    )
  }

  renderProductListingsSettings() {
    const { syncErrors, formValues } = this.props
    const duplicates =
      syncErrors.additionalTagging === 'duplicates'
        ? findAdditionalTaggingDuplicates(formValues)
        : []
    return (
      <React.Fragment>
        <h6>Free Product Listings</h6>
        <p>
          You can add parameters to your product links for product listings by
          entering them below.
        </p>
        {this.renderCustomProductListingsSettings(
          'additionalTagging',
          duplicates
        )}
        {this.renderAddCustomButton('additionalTagging')}
        {this.renderErrors('additionalTagging', duplicates)}
      </React.Fragment>
    )
  }

  renderShoppingAdsSetttings() {
    const { syncErrors, channel, formValues, hasFreeListings } = this.props
    const duplicates =
      syncErrors.adsTagging === 'duplicates'
        ? findAdsTaggingDuplicates(formValues)
        : []
    return (
      <React.Fragment>
        {hasFreeListings && (
          <h6>{changeCase.titleCase(channel)} Shopping Ads</h6>
        )}
        <p>
          You can add parameters to your product links for ads by entering them
          below.
        </p>
        {this.renderCustomProductListingsSettings('adsTagging', duplicates)}
        {this.renderAddCustomButton('adsTagging')}
        {this.renderErrors('adsTagging', duplicates)}
      </React.Fragment>
    )
  }

  render() {
    const { hasFreeListings } = this.props
    return (
      <div className='analytics-tagging'>
        {hasFreeListings && this.renderProductListingsSettings()}
        {this.renderShoppingAdsSetttings()}
      </div>
    )
  }
}

export const handleSubmit = async (values, dispatch, props) => {
  const { saveProductUrlSetting, fetchProductUrlSetting, websiteId, feedId } =
    props
  var formattedAdditionalTagging = Object.fromEntries(values.additionalTagging)
  var formattedAdsTagging = Object.fromEntries(values.adsTagging)
  var formattedValues = { ...values }
  formattedValues.additionalTagging = formattedAdditionalTagging
  formattedValues.adsTagging = formattedAdsTagging
  try {
    await saveProductUrlSetting(websiteId, feedId, formattedValues)
    await fetchProductUrlSetting(websiteId, feedId)
  } catch (error) {
    toastr.error(error)
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  const channel = channelSelector(state, props)
  return {
    websiteId,
    channel,
    hasFreeListings: hasFreeListings(channel),
    feedId: websiteFeedIdByChannelSelector(state, props),
    productUrlSettings: websiteProductUrlSettingsSelector(websiteId)(
      state,
      props
    ),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    syncErrors: getFormSyncErrors(REDUX_FORM_NAME)(state)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      fetchProductUrlSetting,
      saveProductUrlSetting
    },
    dispatch
  )

export const REDUX_FORM_NAME = 'AnalyticsTagging'

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(AnalyticsTaggingCard))
  )
)
