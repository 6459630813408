import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuditHeaderTemplate from './header_template'
import { MDBRow, MDBCol, MDBBadge } from 'mdbreact'
import Summary from '../summary'
import AuditTableTemplate from './table_template'
import {
  websiteDetailSelector,
  websiteRetailInventoryDataSelector
} from 'selectors/websites'
import {
  HEALTH_IN_ORDER,
  channelHealthSelector,
  channelOptimisationScoreSelector
} from 'selectors/playbook/playbook_progress'
import {
  CHANNEL_ALL,
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT
} from 'util/supported_channels'
import { getHealthColour, getOptScoreColour } from '../playbook_util'

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }

export class AuditOverview extends Component {
  static propTypes = {
    websiteDetail: PropTypes.object.isRequired,
    retailInventoryData: PropTypes.object.isRequired,
    googleHealth: PropTypes.oneOf(HEALTH_IN_ORDER).isRequired,
    microsoftHealth: PropTypes.oneOf(HEALTH_IN_ORDER).isRequired,
    facebookHealth: PropTypes.oneOf(HEALTH_IN_ORDER).isRequired,
    googleOptimisationScore: PropTypes.number.isRequired,
    microsoftOptimisationScore: PropTypes.number.isRequired,
    facebookOptimisationScore: PropTypes.number.isRequired
  }

  generateWebsiteOverview() {
    const { websiteDetail, retailInventoryData } = this.props

    return (
      <div className='audit-overview-detail'>
        <MDBRow>
          <MDBCol md='3'>Generated on:</MDBCol>
          <MDBCol>
            <MDBBadge>
              {new Date().toLocaleDateString('en-US', dateOptions)}
            </MDBBadge>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md='3'>Website:</MDBCol>
          <MDBCol>{websiteDetail.name}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md='3'>Products:</MDBCol>
          <MDBCol>{retailInventoryData.items}</MDBCol>
        </MDBRow>
      </div>
    )
  }

  renderOverviewCharts() {
    return (
      <>
        {this.generateWebsiteOverview()}
        <Summary channel={CHANNEL_ALL} showHideButton={false} />
      </>
    )
  }

  renderPillStyleCell(data, borderColor) {
    return (
      <div
        className='common-audit-cell-container'
        style={{ borderColor: borderColor }}
      >
        {data}
      </div>
    )
  }

  getChannelSummaryColumns() {
    return [
      { name: 'Channel', selector: (row) => row.channel, center: true },
      {
        name: 'Health',
        selector: (row) => row.health,
        center: true,
        cell: (row) =>
          this.renderPillStyleCell(row.health, getHealthColour(row.health))
      },
      {
        name: 'Optimisation Score (%)',
        selector: (row) => row.optScore,
        center: true,
        cell: (row) =>
          this.renderPillStyleCell(
            row.optScore,
            getOptScoreColour(row.optScore)
          )
      }
    ]
  }

  generateChannelSummaryData(channel, health, optScore) {
    return {
      id: channel,
      channel: channel,
      health: health,
      optScore: `${parseFloat(optScore.toFixed(2), 10)}`
    }
  }

  getChannelSummaryData() {
    const {
      googleHealth,
      microsoftHealth,
      facebookHealth,
      googleOptimisationScore,
      microsoftOptimisationScore,
      facebookOptimisationScore
    } = this.props

    return [
      this.generateChannelSummaryData(
        'Google',
        googleHealth,
        googleOptimisationScore
      ),
      this.generateChannelSummaryData(
        'Microsoft',
        microsoftHealth,
        microsoftOptimisationScore
      ),
      this.generateChannelSummaryData(
        'Facebook',
        facebookHealth,
        facebookOptimisationScore
      )
    ]
  }

  generateChannelSummary() {
    const columns = this.getChannelSummaryColumns()
    const data = this.getChannelSummaryData()

    return <AuditTableTemplate columns={columns} data={data} />
  }

  renderChannelSummary() {
    return (
      <>
        <h4>All channel summary</h4>
        {this.generateChannelSummary()}
      </>
    )
  }

  renderGeneralInfo() {
    return (
      <div className='general-info-container'>
        <span>Health: </span>Products are tested against each channel's data
        requirements. A health of OK means that no problems were detected.
        Warning means that some products have issues that may prevent them from
        showing in the channel. Critical means that the channel may be at risk
        of stopping working altogether.
        <br />
        <br />
        <span>Optimisation Score: </span>Product data is tested and scored based
        on the criteria of each advertising channel. You get a score for your
        entire product data, a score per channel and a score for each
        optimization opportunity.
      </div>
    )
  }

  render() {
    return (
      <div id='overview-audit'>
        <AuditHeaderTemplate headerText='Product data audit' />
        <div className='audit-page-body'>
          {this.renderOverviewCharts()}
          {this.renderChannelSummary()}
          {this.renderGeneralInfo()}
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteDetail: websiteDetailSelector(state, props),
  retailInventoryData: websiteRetailInventoryDataSelector(state, props),
  googleHealth: channelHealthSelector(CHANNEL_GOOGLE)(state, props),
  microsoftHealth: channelHealthSelector(CHANNEL_MICROSOFT)(state, props),
  facebookHealth: channelHealthSelector(CHANNEL_FACEBOOK)(state, props),
  googleOptimisationScore: channelOptimisationScoreSelector(CHANNEL_GOOGLE)(
    state,
    props
  ),
  microsoftOptimisationScore: channelOptimisationScoreSelector(
    CHANNEL_MICROSOFT
  )(state, props),
  facebookOptimisationScore: channelOptimisationScoreSelector(CHANNEL_FACEBOOK)(
    state,
    props
  )
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditOverview)
)
