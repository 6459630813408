import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MDBNavbarNav, MDBNavItem, MDBIcon } from 'mdbreact'
import { isMobileOS } from 'util/operating_systems'
import { setSideNavOpen } from 'actions/app'
import { loggedInSelector, profileCompleteSelector } from 'selectors/user'
import { isEmbeddedAppSelector, appIdSelector } from 'selectors/app'
import dcLogo from 'images/ad_champion/dc_logo_horiz.png'
import feedOpsLogo from 'images/feedops_logo_horiz.png'

export class LeftPrimaryHeader extends Component {
  static propTypes = {
    sideNav: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profileComplete: PropTypes.bool.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    setSideNavOpen: PropTypes.func.isRequired
  }

  componentDidUpdate(prevProps) {
    const { sideNav, setSideNavOpen } = this.props
    if (prevProps.sideNav !== sideNav) {
      setSideNavOpen(sideNav)
    }
  }

  toggleSideNav() {
    const { setSideNavOpen, sideNavOpen } = this.props
    setSideNavOpen(!sideNavOpen)
  }

  conditionallyRenderSideNavToggle() {
    const { isEmbeddedApp, sideNav, loggedIn, profileComplete } = this.props

    return (isMobileOS ? isEmbeddedApp : true) &&
      sideNav &&
      loggedIn &&
      profileComplete ? (
      <MDBNavItem
        className='side-nav-toggle'
        onClick={this.toggleSideNav.bind(this)}
      >
        <MDBIcon icon={'bars'} color='white' />
      </MDBNavItem>
    ) : null
  }

  conditionallyRenderLogo() {
    const { appId, isEmbeddedApp, history } = this.props
    var logo

    if (isEmbeddedApp || appId === 'feed_ops' || appId === 'sign_up') {
      logo = <img className='feedops-logo' alt='FeedOps' src={feedOpsLogo} />
    } else {
      logo = <img className='dc-logo' alt='Dynamic Creative' src={dcLogo} />
    }

    return <div onClick={() => appId && history.push(`/${appId}`)}>{logo}</div>
  }

  render() {
    return (
      <MDBNavbarNav left>
        {this.conditionallyRenderSideNavToggle()}
        {this.conditionallyRenderLogo()}
      </MDBNavbarNav>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    loggedIn: loggedInSelector(state),
    profileComplete: profileCompleteSelector(state),
    sideNavOpen: state.app.sideNavOpen,
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    appId: appIdSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSideNavOpen }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftPrimaryHeader)
)
