import camelCaseKeys from 'camelcase-keys'
import { store } from 'containers/ad_platform_context'
import { loginUser, logoutUser } from 'actions/users'
import { setAnalyticsApiReady } from 'actions/google/analytics'
import { setGoogleUser } from 'actions/google/user'

// Shims to facilitate testing
var theStore = store
export const setStore = (newStore) => (theStore = newStore)

var theWindow = global.window
export const setWindow = (newWindow) => (theWindow = newWindow)
// End shims

const loadScript = (d, s, id, jsSrc) =>
  new Promise((resolve, reject) => {
    const element = d.getElementsByTagName(s)[0]
    const fjs = element
    let js = element
    js = d.createElement(s)
    js.id = id
    js.src = jsSrc
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs)
    } else {
      d.head.appendChild(js)
    }
    js.addEventListener('load', () => resolve(js))
    js.addEventListener('error', (e) => {
      reject(new Error(`${jsSrc} failed to load. ${e.message}`))
    })
  })

const handleGoogleApiUserChanged = async (email, scope, response) => {
  await theStore.dispatch(
    setGoogleUser({
      ...response,
      email,
      scope
    })
  )
}

const fetchProfilePicture = async (accessToken) => {
  const response = await fetch(
    'https://people.googleapis.com/v1/people/me?personFields=photos',
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
  const data = await response.json()
  return data.photos?.[0]?.url
}

const handleCurrentUserChanged = async (
  idToken = null,
  appId = null,
  profilePicture = null
) => {
  if (idToken) {
    await theStore.dispatch(loginUser(idToken, appId, profilePicture))
  } else {
    await theStore.dispatch(logoutUser())
  }
}

export const googleAPIsLoad = async () => {
  for (var i = 0; i < 3; ++i) {
    try {
      await loadScript(
        document,
        'script',
        'google-login',
        'https://apis.google.com/js/api.js'
      )
      break
    } catch (ex) {
      if (i === 2) {
        throw ex
      }
    }
  }

  global.gapi.load('picker', () => {})
  global.gapi.load('auth2:analytics', () => {
    theStore.dispatch(setAnalyticsApiReady())
  })
}

const authorizeUser = async (scope) => {
  var popup = theWindow.open(`/google_api/oauth/login?scope=${scope}`)
  return new Promise((resolve, reject) => {
    var timeout = setInterval(() => {
      if (popup.closed) {
        reject('Window closed')
        clearInterval(timeout)
      }
    }, 1000)

    theWindow.addEventListener('message', (event) => {
      if (event.source === popup) {
        const { data } = event
        if (data.email && data.accessToken) {
          resolve(data)
        } else {
          reject('An error occurred.')
        }
        clearInterval(timeout)
      }
    })
  })
}

// This function MUST be called from a user interaction,
// otherwise most browsers will block the popup. This
// is why it is not an action (which is called from
// dispatch).
export const googleAuthLogin = async (appId) => {
  var response = await authorizeUser('')
  const profilePicture = await fetchProfilePicture(response.accessToken)
  await handleCurrentUserChanged(response.idToken, appId, profilePicture)
}

export const googleAuthRequestToken = async (scope) => {
  var response = await authorizeUser(scope)
  const camelCaseResponse = camelCaseKeys(response, { deep: true })
  await handleGoogleApiUserChanged(response.email, scope, camelCaseResponse)
  return camelCaseResponse
}

export const openGoogleDrivePicker = (userAccessToken, pickerCallback) => {
  const picker = new global.google.picker.PickerBuilder()
    .setOrigin(theWindow.location.protocol + '//' + theWindow.location.host)
    .setDeveloperKey(process.env.GOOGLE_DEVELOPER_API_KEY)
    .setOAuthToken(userAccessToken)
    .setAppId('649152089032')
    .addView(global.google.picker.ViewId.SPREADSHEETS)
    .enableFeature(global.google.picker.Feature.NAV_HIDDEN)
    .setCallback((data) => {
      pickerCallback(data)
    })
    .build()

  picker.setVisible(true)
}

export const googleAuthLogout = async () => {
  handleCurrentUserChanged()
}
