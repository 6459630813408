import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { reduxForm, getFormValues } from 'redux-form'
import _ from 'lodash'
import { MDBCollapseHeader, MDBCollapse, MDBIcon } from 'mdbreact'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import CustomFieldMappings from './attribute_mapping/custom_field_mappings'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  fetchInventoryFcAttributes,
  saveInventoryFcAttributes
} from 'actions/inventories'
import {
  inventoryMappableAttributesSelector,
  inventoryAutoMappableAttributesSelector,
  inventoryStoreAttributesSelector,
  inventoryExistingAttributesMappingSelector
} from 'selectors/inventory'

export class AttributeMapping extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    mappableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    autoMappedAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    storeAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    existingMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    fetchInventoryFcAttributes: PropTypes.func.isRequired,
    saveInventoryFcAttributes: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      openSections: { auto: true, custom: true }
    }
  }

  async componentDidMount() {
    await this.fetchInventoryFcAttributes()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (prevProps.websiteId !== websiteId && websiteId !== 0) {
      await this.fetchInventoryFcAttributes()
    }
  }

  async fetchInventoryFcAttributes() {
    const { fetchInventoryFcAttributes, websiteId } = this.props
    if (websiteId !== 0) {
      try {
        this.setState({ loading: true })
        await fetchInventoryFcAttributes(websiteId)
        this.initializeForm()
      } catch (error) {
        toastr.error(
          'An error occurred while fetching your attributes. Please contact support for assistance.'
        )
      }
      this.setState({ loading: false })
    }
  }

  initializeForm() {
    const { initialize, existingMappings } = this.props
    initialize({ existingMappings })
  }

  handleCollapse(field) {
    const { openSections } = this.state
    var newOpenSections = { ...openSections }
    newOpenSections[field] = !openSections[field]
    this.setState({ openSections: newOpenSections })
  }

  renderCollapsableComponent(field, title, component) {
    const { openSections } = this.state
    const isOpen = openSections[field]

    return (
      <>
        <MDBCollapseHeader
          id={field}
          onClick={this.handleCollapse.bind(this, field)}
        >
          {title} <MDBIcon icon={isOpen ? 'angle-up' : 'angle-down'} />
        </MDBCollapseHeader>
        <MDBCollapse id={field} isOpen={isOpen}>
          {component}
        </MDBCollapse>
      </>
    )
  }

  getUpdatedAutoMappedAttributes() {
    const { autoMappedAttributes, formValues } = this.props
    const { existingMappings } = formValues
    const existingFieldNames = !_.isEmpty(existingMappings)
      ? existingMappings.map((field) => field.fieldName)
      : []
    var updatedAutoMappedAttributes = [...autoMappedAttributes]
    if (existingMappings && existingMappings.length > 0) {
      updatedAutoMappedAttributes = updatedAutoMappedAttributes.filter(
        (attribute) => {
          return !existingFieldNames.includes(attribute.name)
        }
      )
    }

    return updatedAutoMappedAttributes
  }

  automaticallyMappedFieldsComponent() {
    const updatedAutoMappedAttributes = this.getUpdatedAutoMappedAttributes()

    return (
      <>
        The following fields are automatically detected and mapped by FeedOps.
        If you need to, you can override them with custom field mappings below.
        <div className='auto-mapped-attributes'>
          {updatedAutoMappedAttributes && updatedAutoMappedAttributes.length > 0
            ? updatedAutoMappedAttributes
                .map((attribute) => attribute.displayName)
                .join(', ')
            : 'All fields are custom mapped.'}
        </div>
      </>
    )
  }

  renderAutomaticallyMappedFields() {
    return this.renderCollapsableComponent(
      'auto',
      'Automatically Mapped Fields',
      this.automaticallyMappedFieldsComponent()
    )
  }

  customFieldMappingsComponent() {
    const { websiteId, mappableAttributes, storeAttributes, formValues } =
      this.props
    const { existingMappings } = formValues

    return (
      <>
        Attribute mappings control where in your website data FeedOps will get
        feed data from. Many fields will be mapped automatically, but if you
        need to adjust where data comes from, or map an extra field, you can do
        so here.
        <CustomFieldMappings
          websiteId={websiteId}
          mappableAttributes={mappableAttributes}
          storeAttributes={storeAttributes}
          existingMappings={existingMappings}
          reduxFormName={REDUX_FORM_NAME}
        />
      </>
    )
  }

  renderCustomFieldMappings() {
    return this.renderCollapsableComponent(
      'custom',
      'Custom Field Mappings',
      this.customFieldMappingsComponent()
    )
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return <IndeterminateProgressIndicator />
    }

    return (
      <div className='attribute-mapping-container'>
        {this.renderAutomaticallyMappedFields()}
        {this.renderCustomFieldMappings()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  mappableAttributes: inventoryMappableAttributesSelector(state, props),
  autoMappedAttributes: inventoryAutoMappableAttributesSelector(state, props),
  storeAttributes: inventoryStoreAttributesSelector(state, props),
  existingMappings: inventoryExistingAttributesMappingSelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchInventoryFcAttributes, saveInventoryFcAttributes },
    dispatch
  )

const AttributeMappingWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributeMapping)

export const REDUX_FORM_NAME = 'WebsiteAttributes'

export const handleSubmit = async (values, dispatch, props) => {
  const { saveInventoryFcAttributes, websiteId } = props
  try {
    const mappings = values.existingMappings
    await saveInventoryFcAttributes(websiteId, null, mappings)
    toastr.success('Attribute mappings applied. Reprocessing feed.')
  } catch (error) {
    toastr.error(
      'An error occurred while saving your attribute mappings. Please contact support for assistance.'
    )
  }
}

export const validator = (values) => {
  const mappings = values.existingMappings
  if (!_.isEmpty(mappings)) {
    for (var index = 0; index < mappings.length; index++) {
      const mapping = mappings[index]
      if (_.isEmpty(mapping.fieldName) || _.isEmpty(mapping.sourceId)) {
        return false
      }
    }
  }

  return true
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate: validator,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(AttributeMappingWithRedux))
  )
)
