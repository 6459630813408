import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
import { fetchIfNeeded } from './util';

export const FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_REQUEST = 'FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_REQUEST';
export const FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_SUCCESS = 'FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_SUCCESS';
export const SAVE_AD_DESCRIPTION_REQUEST = 'SAVE_AD_DESCRIPTION_REQUEST';
export const SAVE_AD_DESCRIPTION_SUCCESS = 'SAVE_AD_DESCRIPTION_SUCCESS';
export const SAVE_LANDING_PAGE_REQUEST = 'SAVE_LANDING_PAGE_REQUEST';
export const SAVE_LANDING_PAGE_SUCCESS = 'SAVE_LANDING_PAGE_SUCCESS';

const requestCampaignTypeConfigurations = scenarioId => ({
  type: FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_REQUEST,
  scenarioId
});

const receiveCampaignTypeConfigurations = (scenarioId, json) => ({
  type: FETCH_CAMPAIGN_TYPE_CONFIGURATIONS_SUCCESS,
  scenarioId,
  campaignTypeConfigurations: json
});

const shouldFetchCampaignTypeConfigurations = (state, scenarioId) => {
  var campaignTypeConfigurations = state.campaignTypeConfigurations || [];
  return campaignTypeConfigurations.length === 0 || campaignTypeConfigurations[0].scenarioId !== scenarioId
}

const fetchCampaignTypeConfigurations = scenarioId => (dispatch) => {
  dispatch(requestCampaignTypeConfigurations(scenarioId));

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_type_configurations.json`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        receiveCampaignTypeConfigurations(
          scenarioId,
          response.campaign_type_configurations.map(config => camelCaseKeys(config))
        )
      );
    }
  });
};

export const fetchCampaignTypeConfigurationsIfNeeded = scenarioId => (dispatch, getState) => {
  return fetchIfNeeded(scenarioId, shouldFetchCampaignTypeConfigurations, fetchCampaignTypeConfigurations, dispatch, getState);
}

const saveAdDescriptionRequest = (scenarioId, adDescription) => ({
  type: SAVE_AD_DESCRIPTION_REQUEST,
  adDescription
});

const saveAdDescriptionSuccess = (scenarioId, adDescription) => ({
  type: SAVE_AD_DESCRIPTION_SUCCESS,
  scenarioId,
  adDescription
});

const saveCampaignTypeConfiguration = (dispatch, scenarioId, payload, requestAction, successAction) => {
  dispatch(requestAction(scenarioId, payload));
  var snakeCasePayload = snakeCaseKeys({campaignTypeConfiguration: payload});

  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_type_configurations/${payload.id}.json`,
    data: snakeCasePayload,
    dataType: 'json',
    success: (response) => {
      return dispatch(successAction(scenarioId, payload));
    }
  });
}

export const saveAdDescription = (scenarioId, adDescription) => dispatch => {
  return saveCampaignTypeConfiguration(
    dispatch,
    scenarioId, 
    adDescription,
    saveAdDescriptionRequest,
    saveAdDescriptionSuccess);
}

const saveLandingPageRequest = (scenarioId, landingPage) => ({
  type: SAVE_LANDING_PAGE_REQUEST,
  landingPage
});

const saveLandingPageSuccess = (scenarioId, landingPage) => ({
  type: SAVE_LANDING_PAGE_SUCCESS,
  scenarioId,
  landingPage
});

export const saveLandingPage = (scenarioId, landingPage) => dispatch => {
  return saveCampaignTypeConfiguration(
    dispatch,
    scenarioId,
    landingPage,
    saveLandingPageRequest,
    saveLandingPageSuccess);
}

export const bulkSaveLandingPages = (scenarioId, landingPages) => dispatch => {
  return bulkSaveCampaignTypeConfigurations(
    dispatch,
    scenarioId,
    landingPages,
    saveLandingPageRequest,
    saveLandingPageSuccess);
}


export const bulkSaveAdDescriptions = (scenarioId, adDescriptions) => dispatch => {
  return bulkSaveCampaignTypeConfigurations(
    dispatch,
    scenarioId, 
    adDescriptions,
    saveAdDescriptionRequest,
    saveAdDescriptionSuccess);
}

const bulkSaveCampaignTypeConfigurations = (dispatch, scenarioId, payload, requestAction, successAction) => {
  dispatch(requestAction(scenarioId, payload));
  var snakeCasePayload = snakeCaseKeys({campaignTypeConfigurations: payload});
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_type_configurations/bulk_update`,
    data: snakeCasePayload,
    dataType: 'json',
    success: (response) => {
      return dispatch(successAction(scenarioId, payload));
    }
  });
}