import { createSelector } from 'reselect'

export const taxonomiesSelector = (state, _props) =>
  (state.google || {}).taxonomies || {}

export const taxonomiesOptionsSelector = createSelector(
  [taxonomiesSelector],
  (taxonomies) => {
    var options = []
    Object.entries(taxonomies).forEach(([id, fullName]) => {
      options.push({ label: fullName, value: id })
    })

    return options
  }
)
