import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import changeCase from 'change-case'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'
import { bindActionCreators } from 'redux'
import { toggleDisplayConversionValueOnCost } from 'actions/advertising_goal_types'
import CalculatedTargetHelper from 'components/feed_ops/website/product_feeds/calculated_target_helper'
import { displayConversionValueOnCostSelector } from 'selectors/advertising_goal_types'
import {
  getGoalTypeTitle,
  currencySelector
} from 'selectors/cost_and_goal/general'
import { accountTypeIdSelector, accountTypeSelector } from 'selectors/user'
import { formatNumber } from 'util/format_number'

export class TargetActualCard extends Component {
  static propTypes = {
    advertisingGoalType: PropTypes.string.isRequired,
    cardType: PropTypes.oneOf(['cost', 'goal']).isRequired,
    actualData: PropTypes.number.isRequired,
    targetData: PropTypes.number,
    editBudgetsAndGoals: PropTypes.func,
    displayConversionValueOnCost: PropTypes.bool.isRequired,
    toggleDisplayConversionValueOnCost: PropTypes.func.isRequired,
    showZeroValues: PropTypes.bool
  }

  static defaultProps = {
    showZeroValues: true
  }

  renderGoalTypeDropdown(titleSuffix) {
    return (
      <MDBDropdown className='goal-dropdown'>
        <MDBDropdownToggle caret={true}>{titleSuffix}</MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem onClick={() => this.handleGoalTypeDisplayOnClick()}>
            {titleSuffix === 'ROAS' ? 'Conv. value / cost' : 'ROAS'}
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  handleGoalTypeDisplayOnClick() {
    const { toggleDisplayConversionValueOnCost } = this.props
    toggleDisplayConversionValueOnCost()
  }

  getGoalPrefix() {
    const { advertisingGoalType } = this.props
    switch (advertisingGoalType) {
      case 'cpa':
        return '$'
      case 'cpc':
        return '$'
      case 'max_conversion_value':
        return '$'
      default:
        return ''
    }
  }

  getGoalValue(goalValue) {
    const { advertisingGoalType, displayConversionValueOnCost } = this.props
    switch (advertisingGoalType) {
      case 'roas':
        if (displayConversionValueOnCost) {
          return formatNumber(goalValue / 100, 0)
        } else {
          return `${formatNumber(goalValue, 0)}%`
        }
      default:
        return formatNumber(goalValue, 0)
    }
  }

  goalTypeTitle() {
    const { advertisingGoalType, displayConversionValueOnCost } = this.props
    return getGoalTypeTitle(advertisingGoalType, displayConversionValueOnCost)
  }

  getPrefix() {
    const { cardType } = this.props
    return changeCase.titleCase(cardType)
  }

  getSuffix() {
    const { cardType, currency } = this.props
    if (cardType === 'goal') {
      return this.goalTypeTitle()
    } else {
      return currency
    }
  }

  isGoalTypeMixedOrMissing() {
    const { advertisingGoalType } = this.props

    return (
      advertisingGoalType === 'mixed' ||
      advertisingGoalType === 'missing' ||
      advertisingGoalType === 'mixed & missing'
    )
  }

  getFormattedValue(data) {
    const { cardType } = this.props
    const mixedOrMissing = this.isGoalTypeMixedOrMissing()

    if ((mixedOrMissing && cardType === 'goal') || data === 'N/A') {
      return 'N/A'
    }

    if (cardType === 'goal') {
      return `${this.getGoalPrefix()}${this.getGoalValue(data)}`
    } else {
      return `$${formatNumber(data, 0)}`
    }
  }

  hideZeroValues(label) {
    const { targetData, cardType, showZeroValues } = this.props
    return (
      label === 'Target' &&
      cardType === 'goal' &&
      !showZeroValues &&
      targetData === 0
    )
  }

  renderPercentageOfTarget() {
    const { actualData, targetData, cardType, editBudgetsAndGoals } = this.props
    return (
      <MDBCardText
        className={`percentage-spent ${
          (cardType === 'goal' && editBudgetsAndGoals) ||
          this.hideZeroValues('Target')
            ? 'invisible'
            : ''
        }`}
      >
        {formatNumber((actualData * 100) / targetData, 0)}%
      </MDBCardText>
    )
  }

  isRoas() {
    const titleSuffix = this.getSuffix().toLowerCase()
    return (
      titleSuffix.includes('roas') || titleSuffix.includes('conv. value / cost')
    )
  }

  renderTitle() {
    const { cardType, editBudgetsAndGoals } = this.props
    const titlePrefix = this.getPrefix()
    const titleSuffix = this.getSuffix()
    const isRoas = this.isRoas()
    return (
      <MDBCardTitle className={`${cardType}-card`}>
        {`${titlePrefix} (`}
        {isRoas ? this.renderGoalTypeDropdown(titleSuffix) : titleSuffix}
        {')'}
        {editBudgetsAndGoals && (
          <MDBBtn
            flat
            className='float-right edit'
            onClick={editBudgetsAndGoals}
          >
            <MDBIcon icon={'pencil'} />
          </MDBBtn>
        )}
      </MDBCardTitle>
    )
  }

  renderValue(label, value, dashed) {
    const { cardType, editBudgetsAndGoals } = this.props
    var formattedActual = this.hideZeroValues(label)
      ? ''
      : this.getFormattedValue(value)
    //&#xfeff; is to keep the vertical alignment when value is empty
    return (
      <>
        <MDBCardText className='value'>
          {formattedActual}&#xfeff;
          {label === 'Target' &&
            cardType === 'goal' &&
            !editBudgetsAndGoals && <CalculatedTargetHelper />}
          <svg className='float-right'>
            <g fill='none'>
              <path className={dashed ? 'dashed' : ''} d='m5 8 l42 0' />
            </g>
          </svg>
        </MDBCardText>
        <MDBCardText>{label}</MDBCardText>
      </>
    )
  }

  render() {
    const { cardType, actualData, targetData, advertisingGoalType } = this.props
    const showTargetPercent = !(
      (advertisingGoalType === 'mixed' || advertisingGoalType === 'missing') &&
      cardType === 'goal'
    )
    return (
      <MDBCard className={`${cardType}-card`}>
        <MDBCardBody>
          {this.renderTitle()}
          <div className='content'>
            {this.renderValue('Actual', actualData, false)}
            {this.renderValue('Target', targetData, true)}
            {showTargetPercent ? this.renderPercentageOfTarget() : null}
          </div>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => {
  const accountTypeId = accountTypeIdSelector(state, props)
  const accountType = accountTypeSelector(state, props)
  return {
    currency: currencySelector(accountType, accountTypeId)(state, props),
    displayConversionValueOnCost: displayConversionValueOnCostSelector(
      state,
      props
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleDisplayConversionValueOnCost }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TargetActualCard)
)
