import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InputError extends Component {
  static propTypes = {
    error: PropTypes.string,
    cssClass: PropTypes.string
  }

  render() {
    const { error, cssClass } = this.props
    if (!error) {
      return null
    }
    return (
      <div className={cssClass}>
        <i className='fa fa-exclamation-circle text-danger'></i>
        <span> {error} </span>
      </div>
    )
  }
}
