import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showFeedOpsFeedbackModal } from 'actions/feed_ops/feedbacks'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBIcon
} from 'mdbreact'
import {
  PLAYBOOK_CHANNELS,
  getQueryStringByChannel
} from 'util/supported_channels'
import DropdownWithIcons from './common/dropdown_with_icons'
import Dismiss from './playbook_dismiss_button'
import ChannelLogos from '../../common/channel_logo'
import ImpactOrOpportunityIndicator from './impact_or_opportunity_indicator'
import PlaybookNavAction from './playbook_nav_action'

export class PlaybookItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    showFeedOpsFeedbackModal: PropTypes.func.isRequired
  }

  showFeedbackModal() {
    const { showFeedOpsFeedbackModal, item } = this.props
    showFeedOpsFeedbackModal('Playbook Item', item.id)
  }

  renderEllipsisMenu() {
    const { item } = this.props
    const list = [
      {
        label: 'Leave feedback',
        icon: <MDBIcon far icon='comment-alt' />,
        action: this.showFeedbackModal.bind(this)
      }
    ]
    if (!item.isDismissed) {
      list.push({
        label: this.renderPlaybookDismissButton(),
        icon: <MDBIcon far icon='times-circle' />
      })
    }
    return (
      <DropdownWithIcons
        dropdownItems={list}
        shouldRotateIcon={false}
        toggleIcon={<MDBIcon icon='ellipsis-v' />}
        className=''
      />
    )
  }

  renderIsProductType() {
    const { item } = this.props
    return item.title === 'Increase Product Type Depth'
  }

  renderTitleRightSection() {
    const { item } = this.props
    return (
      <div className='opportunity-box'>
        <span className='opportunity'>
          <ImpactOrOpportunityIndicator
            health={item.health}
            opportunity={item.opportunity}
          />
        </span>
        {this.renderEllipsisMenu()}
      </div>
    )
  }

  renderCardTitle() {
    const { item } = this.props
    return (
      <MDBCardTitle className='card-title'>
        <div className='playbook-item-card-header'>
          <span className='title-logo-row'>
            <>
              <ChannelLogos item={item} />
            </>
            <>{item.title}</>
          </span>
          {this.renderTitleRightSection()}
        </div>
      </MDBCardTitle>
    )
  }

  renderPlaybookDismissButton() {
    const { item, channel } = this.props
    if (!item.id) return null

    return (
      <Dismiss
        id={item.id}
        label={item.actionName}
        destination={item.destination.replace(
          'replaceChannel',
          getQueryStringByChannel(channel)
        )}
        enabled={item.enabled}
        isDismissed={item.isDismissed}
        tooltip={item.tooltip}
      />
    )
  }

  renderCardBody(description) {
    return (
      <MDBCardBody>
        {this.renderCardTitle()}
        <hr />
        <MDBCardText className='content'>
          <span
            className='playbook-description'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </MDBCardText>
      </MDBCardBody>
    )
  }

  render() {
    const { item, channel } = this.props

    return (
      <MDBCard className='playbook-item-card'>
        {this.renderCardBody(item.description)}
        <MDBCardFooter>
          <PlaybookNavAction item={item} channel={channel} />
        </MDBCardFooter>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showFeedOpsFeedbackModal }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlaybookItem)
)
