import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import FeedOpsNewWebsiteForm from './new_website_form'
import { fetchFullWebsiteList } from 'actions/websites'
import { appIdSelector } from 'selectors/app'
import { websiteIdSelector } from 'selectors/websites'

export class FeedOpsWebsiteServiceOptionsDesktop extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    fetchFullWebsiteList: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  async componentDidMount() {
    const { fetchFullWebsiteList } = this.props

    await fetchFullWebsiteList()
    this.setState({ loading: false })
  }

  renderWebsiteForm() {
    const { appId } = this.props
    return <FeedOpsNewWebsiteForm appId={appId} />
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return <AdChampionIndeterminateProgressIndicator />
    }

    return this.renderWebsiteForm()
  }
}

export const mapStateToProps = (state, props) => {
  return {
    appId: appIdSelector(state, props),
    websiteId: websiteIdSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFullWebsiteList }, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeedOpsWebsiteServiceOptionsDesktop)
)
