import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { enableDisableChannelSettings } from 'actions/channel_settings'
import { fetchWebsiteDetail } from 'actions/websites'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdbreact'
import BackButton from '../common/back_button'
import FeedLink from '../common/feed_link'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import {
  websiteIdSelector,
  websiteMerchantCenterSelector,
  websiteMicrosoftMerchantCenterSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  getDisplayName,
  getLogoByChannel,
  SUPPORTED_CHANNELS,
  API_CONNECTABLE_CHANNELS,
  CHANNEL_GOOGLE,
  CHANNEL_MICROSOFT
} from 'util/supported_channels'

import './mobile_container.scss'

export class FeedSettingsMobileContainer extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    googleAccount: PropTypes.object.isRequired,
    microsoftAccount: PropTypes.object.isRequired,
    enableDisableChannelSettings: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      firstClick: false,
      updating: false
    }
  }

  computeChannelAccountDetails() {
    const { channel, googleAccount, microsoftAccount } = this.props
    const accountDetail =
      channel === CHANNEL_GOOGLE
        ? googleAccount
        : channel === CHANNEL_MICROSOFT
        ? microsoftAccount
        : null

    return accountDetail
      ? `${accountDetail.merchantCenterAccountName} (${accountDetail.merchantCenterAccountId})`
      : ''
  }

  computeCardText() {
    const { channel } = this.props
    const { firstClick } = this.state
    const dispName = getDisplayName(channel)
    const accOrFeed = API_CONNECTABLE_CHANNELS.includes(channel)
      ? `Account: ${this.computeChannelAccountDetails()}`
      : 'Feed'
    const disconnectOrDisable = API_CONNECTABLE_CHANNELS.includes(channel)
      ? 'disconnect'
      : 'disable'

    if (firstClick) {
      return `You are about to ${disconnectOrDisable} your ${dispName} ${accOrFeed}. Your products will no longer be synced. Are you sure you want to proceed?`
    } else {
      return <>{`${dispName} ${accOrFeed}`}</>
    }
  }

  renderCardText() {
    const { firstClick } = this.state

    return (
      <>
        <MDBCardText>{this.computeCardText()}</MDBCardText>
        {!firstClick && <FeedLink />}
      </>
    )
  }

  async handleDisableChannelClick() {
    const { firstClick } = this.state

    if (!firstClick) {
      this.setState({ firstClick: true })
    } else {
      const {
        enableDisableChannelSettings,
        fetchWebsiteDetail,
        websiteId,
        channel
      } = this.props
      this.setState({ updating: true })
      await enableDisableChannelSettings(websiteId, channel, false)
      await fetchWebsiteDetail(websiteId)
      this.props.history.goBack()
    }
  }

  renderCardFooter() {
    const { channel } = this.props
    const { firstClick, updating } = this.state
    const btnName = `${firstClick ? 'YES PLEASE ' : ''}${
      API_CONNECTABLE_CHANNELS.includes(channel) ? 'DISCONNECT' : 'DISABLE FEED'
    }`

    return (
      <MDBCardFooter>
        <MDBBtn
          disabled={updating}
          onClick={this.handleDisableChannelClick.bind(this)}
        >
          {btnName}
        </MDBBtn>
      </MDBCardFooter>
    )
  }

  renderFeedSettings() {
    const { channel } = this.props

    return (
      <MDBCard>
        <MDBCardBody>
          <MDBRow>
            <MDBCol size='4'>
              <img src={getLogoByChannel(channel)} alt={channel} />
            </MDBCol>
            <MDBCol>{this.renderCardText()}</MDBCol>
          </MDBRow>
        </MDBCardBody>
        {this.renderCardFooter()}
      </MDBCard>
    )
  }

  render() {
    const { updating } = this.state

    return (
      <div className='feed-settings-container feed-settings-container-mobile'>
        <MDBRow>
          <MDBCol>
            <BackButton label='BACK' float='left' />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>{this.renderFeedSettings()}</MDBCol>
        </MDBRow>
        <MDBRow className='progress-indicator-row'>
          <MDBCol>
            {updating && <AdChampionIndeterminateProgressIndicator />}
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  channel: channelSelector(state, props),
  googleAccount: websiteMerchantCenterSelector(state, props),
  microsoftAccount: websiteMicrosoftMerchantCenterSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { enableDisableChannelSettings, fetchWebsiteDetail },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedSettingsMobileContainer)
)
