import camelCaseKeys from 'camelcase-keys'

export const FETCH_INVENTORY_PROCESSING_STATUS_REQUEST =
  'FETCH_INVENTORY_PROCESSING_STATUS_REQUEST'
export const FETCH_INVENTORY_PROCESSING_STATUS_RESPONSE =
  'FETCH_INVENTORY_PROCESSING_STATUS_RESPONSE'

const fetchInventoryProcessingStatusResponse = (inventoryId, response) => ({
  type: FETCH_INVENTORY_PROCESSING_STATUS_RESPONSE,
  inventoryId,
  ...response
})

export const fetchInventoryProcessingStatus =
  (inventoryId) => async (dispatch) => {
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/inventories/${inventoryId}/processing_status`,
      dataType: 'json'
    })

    response = camelCaseKeys(response, { deep: true })
    dispatch(fetchInventoryProcessingStatusResponse(inventoryId, response))
  }
