import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'reactstrap'
import { reduxForm, Field } from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { createValidator } from 'components/util/validation'
import FeedPlatformSelector from './feed_platform_selector'

export class FeedPlatformForm extends Component {
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    selectedPlatform: PropTypes.string,
    readOnly: PropTypes.bool,
    advanced: PropTypes.bool.isRequired
  }

  static defaultProps = {
    advanced: true
  }

  componentDidMount() {
    const { selectedPlatform } = this.props
    if (selectedPlatform) {
      this.setPlatform(selectedPlatform)
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedPlatform } = this.props
    if (prevProps.selectedPlatform !== selectedPlatform) {
      this.setPlatform(selectedPlatform)
    }
  }

  setPlatform(p) {
    const { change } = this.props
    change('selectedPlatform', p)
  }

  renderPlatforms({ input: { value } }) {
    const { selectedPlatform, readOnly, advanced } = this.props
    return (
      <FeedPlatformSelector
        selectedPlatform={value || selectedPlatform}
        readOnly={readOnly}
        advanced={advanced}
        onChange={this.setPlatform.bind(this)}
      />
    )
  }

  render() {
    return (
      <Container className='supported-platforms'>
        <Row>
          <Field
            name='selectedPlatform'
            component={this.renderPlatforms.bind(this)}
          />
        </Row>
      </Container>
    )
  }
}

export const REDUX_FORM_NAME = 'feedPlatformForm'
export default reduxForm({
  form: REDUX_FORM_NAME,
  validate: createValidator(['selectedPlatform']),
  onSubmit: () => {},
  ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
})(FeedPlatformForm)
