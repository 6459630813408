import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { isDirty } from 'redux-form'
import { REDUX_FORM_NAME } from '../form'
import {
  ORDER_NOTICE_ACTION,
  PREVIEW_RULES_ACTION,
  ADD_RULE_ACTION,
  EDIT_RULE_ACTION,
  DELETE_RULE_ACTION,
  REORDER_RULE_ACTION
} from '../overview_util'
import RuleOrderNotice from './table_actions/rule_order_notice'
import PreviewRules from './table_actions/preview_rules'
import AddNewRule from './table_actions/add_new_rule'
import EditRule from './table_actions/edit_rule'
import DeleteRule from './table_actions/delete_rule'

export class TableActions extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    rowData: PropTypes.object.isRequired,
    isDirty: PropTypes.bool.isRequired
  }

  renderRuleOrderNotice() {
    return <RuleOrderNotice />
  }

  renderPreviewRules() {
    return <PreviewRules />
  }

  renderAddRule() {
    return <AddNewRule />
  }
  renderEditRule() {
    const { rowData } = this.props

    return <EditRule rule={rowData} />
  }

  renderDeleteRule() {
    const { rowData } = this.props

    return <DeleteRule rule={rowData} />
  }

  renderReorderRule() {
    return <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.6" cy="1.6" r="1.6" fill="#292D32"/>
    <circle cx="1.6" cy="7.99941" r="1.6" fill="#292D32"/>
    <circle cx="1.6" cy="14.3998" r="1.6" fill="#292D32"/>
    <circle cx="7.99941" cy="1.6" r="1.6" fill="#292D32"/>
    <circle cx="7.99941" cy="7.99941" r="1.6" fill="#292D32"/>
    <circle cx="7.99941" cy="14.3998" r="1.6" fill="#292D32"/>
    </svg>
    
  }

  render() {
    const { actionName } = this.props
    var actionComponent = null

    switch (actionName) {
      case ORDER_NOTICE_ACTION:
        actionComponent = this.renderRuleOrderNotice()
        break
      case PREVIEW_RULES_ACTION:
        actionComponent = this.renderPreviewRules()
        break
      case ADD_RULE_ACTION:
        actionComponent = this.renderAddRule()
        break
      case EDIT_RULE_ACTION:
        actionComponent = this.renderEditRule()
        break
      case DELETE_RULE_ACTION:
        actionComponent = this.renderDeleteRule()
        break
      case REORDER_RULE_ACTION:
        actionComponent = this.renderReorderRule()
        break
      default:
        break
    }

    return actionComponent
  }
}

export const mapStateToProps = (state, props) => ({
  isDirty: isDirty(REDUX_FORM_NAME)(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableActions)
)
