import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBtn, MDBIcon } from 'mdbreact'
import { Popover } from '@material-ui/core'
import AccountFilterPopoverContainer from 'components/feed_ops/accounts/overview/apply_filters_section/account_filter_popover_container'
import ProductFilterPopoverContainer from 'components/feed_ops/website/product_optimisations/attribute_filters_section/popover_section/product_filter_popover_container'
import ActionFilterPopoverContainer from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal/action_filter_popover_container'
import { getAppliedFilterText, updateAppliedFilters } from './popover_util'
import { FILTER_ATTRIBUTES } from 'components/feed_ops/accounts/overview/attributes_util'
import {
  RULE_ACTIONS_NAME,
  getActionFilterText
} from 'components/feed_ops/website/rules_engine/overview/table_actions/rules_engine_modal/rules_engine_util'

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
}
const transformOrigin = {
  vertical: 'top',
  horizontal: 'center'
}

export default class AppliedFiltersSection extends Component {
  static propTypes = {
    forFeature: PropTypes.string.isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleRemoveFilterAtIndex: PropTypes.func.isRequired,
    handleApplyFilterClick: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      anchorEl: {},
      popoverOpen: {}
    }
  }

  handleFilterClick(index, event) {
    const { anchorEl, popoverOpen } = this.state
    var newPopoverOpen = {}
    for (const k in Object.keys(popoverOpen)) {
      newPopoverOpen[k] = false
    }
    newPopoverOpen[index] = true

    this.setState({
      anchorEl: { ...anchorEl, [index]: event.target },
      popoverOpen: newPopoverOpen
    })
  }

  getAttributeDisplayName(attributeName) {
    const { availableAttributes } = this.props
    const attribute = availableAttributes.find((a) => a.name === attributeName)
    return attribute && Object.keys(attribute)
      ? attribute.columnName
      : attributeName
  }

  getFilterText(filter) {
    const { forFeature, availableAttributes } = this.props
    const { attributeName, filterType, values } = filter
    return forFeature === RULE_ACTIONS_NAME
      ? getActionFilterText(
          this.getAttributeDisplayName(attributeName),
          filterType,
          values,
          availableAttributes
        )
      : getAppliedFilterText(
          this.getAttributeDisplayName(attributeName),
          filterType,
          values
        )
  }

  handleRemoveFilterAtIndex(index, event) {
    const { appliedFilters, handleRemoveFilterAtIndex } = this.props
    var updatedFilters = [...appliedFilters]
    updatedFilters.splice(index, 1)

    handleRemoveFilterAtIndex(updatedFilters)
    this.handleClosePopover()
    event.stopPropagation()
  }

  renderAppliedFilterButton(filter, index) {
    const { forFeature } = this.props
    const { metaData } = filter
    var textUnderline = false
    if (forFeature === RULE_ACTIONS_NAME) {
      const { replaceManual } = metaData ? metaData : { replaceManual: false }
      textUnderline = replaceManual
    }

    return (
      <MDBBtn
        key={`btn-${filter.attributeName}-${index}`}
        tag='span'
        className={`filter-btn ${textUnderline ? 'text-underline' : ''}`}
        onClick={this.handleFilterClick.bind(this, index)}
      >
        {this.getFilterText(filter)}{' '}
        <MDBIcon
          icon='times-circle'
          onClick={this.handleRemoveFilterAtIndex.bind(this, index)}
        />
      </MDBBtn>
    )
  }

  handleClosePopover(index) {
    const { popoverOpen } = this.state
    var newPopoverOpen = { ...popoverOpen, [index]: false }
    this.setState({ popoverOpen: newPopoverOpen })
  }

  handleApplyFilterClick(
    index,
    attributeName,
    selectedFilterOption,
    filterValues,
    metaData = {}
  ) {
    const { appliedFilters, handleApplyFilterClick } = this.props
    const updatedAppliedFilters = updateAppliedFilters({
      index,
      appliedFilters,
      attributeName,
      selectedFilterOption,
      filterValues,
      metaData
    })

    handleApplyFilterClick(updatedAppliedFilters)
    this.handleClosePopover(index)
  }

  renderAccountFilterPopoverContainer(filter, index) {
    const { filteredAccounts } = this.props
    const selectedAttribute = FILTER_ATTRIBUTES[filter.attributeName]

    return (
      <AccountFilterPopoverContainer
        filteredAccounts={filteredAccounts}
        selectedAttribute={selectedAttribute}
        appliedFilter={filter}
        handleClosePopover={this.handleClosePopover.bind(this, index)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this, index)}
      />
    )
  }

  computePopoverContainerProps(filter, index) {
    const { availableAttributes } = this.props
    const selectedAttribute = availableAttributes.find(
      (attribute) => attribute.name === filter.attributeName
    )

    return {
      selectedAttribute,
      appliedFilter: filter,
      handleClosePopover: this.handleClosePopover.bind(this, index),
      handleApplyFilterClick: this.handleApplyFilterClick.bind(this, index)
    }
  }

  renderActionFilterPopoverContainer(filter, index) {
    return (
      <ActionFilterPopoverContainer
        {...this.computePopoverContainerProps(filter, index)}
      />
    )
  }

  renderProductFilterPopoverContainer(filter, index) {
    return (
      <ProductFilterPopoverContainer
        {...this.computePopoverContainerProps(filter, index)}
      />
    )
  }

  conditionallyRenderFilterPopoverContainer(filter, index) {
    const { forFeature } = this.props
    switch (forFeature) {
      case 'Account':
        return this.renderAccountFilterPopoverContainer(filter, index)
      case RULE_ACTIONS_NAME:
        return this.renderActionFilterPopoverContainer(filter, index)
      default:
        return this.renderProductFilterPopoverContainer(filter, index)
    }
  }

  renderPopover(filter, index) {
    const { anchorEl, popoverOpen } = this.state
    const { forFeature } = this.props
    const id = `po-${filter.attributeName}-${index}`.replace(' ', '')

    return (
      <Popover
        id={id}
        key={id}
        anchorEl={anchorEl && anchorEl[index]}
        open={popoverOpen[index] || false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={
          forFeature === 'Account' ? 'accounts-popover' : 'attribute-popover'
        }
        onClose={this.handleClosePopover.bind(this, index)}
      >
        {this.conditionallyRenderFilterPopoverContainer(filter, index)}
      </Popover>
    )
  }

  render() {
    const { appliedFilters } = this.props
    var appliedFilterButtons = []
    appliedFilters.forEach((filter, index) => {
      appliedFilterButtons.push(
        <span key={`fs-${filter.attributeName}-${index}`}>
          {this.renderAppliedFilterButton(filter, index)}
          {this.renderPopover(filter, index)}
        </span>
      )
    })

    return <div className='applied-filters'>{appliedFilterButtons}</div>
  }
}
