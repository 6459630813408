import { openBookAMeeting } from 'components/util/help'

export const ACCOUNT_OVERVIEW = 'Overview'
export const PLAYBOOK = 'Playbook'
export const COST_AND_GOAL = 'Cost & goal'
export const PRODUCT_LEVEL_OPTIMIZATIONS = 'Products'

export const WEBSITE_SETTINGS = 'Website settings'
export const WEBSITE_CONNECTION = 'Connection'
export const PRODUCT_FEED_CHANNELS = 'Product feeds'
export const FEED_RULES = 'Feed rules'
export const EDIT_PRODUCT_TYPES = 'Categories'
export const EDIT_PRODUCT_ATTRIBUTES = 'Attributes'
export const SUBSCRIPTION_DETAILS = 'Subscription'

export const ACCOUNT_SETTINGS = 'Account settings'
export const ADD_USER = 'Add a user'
export const ADD_WEBSITE = 'Add a website'
export const MANAGE_USERS = 'Manage users'
export const ACCOUNT_DETAILS = 'Account details'

export const BOOK_A_MEETING = 'Book a meeting'

export const REQUIRED_SETTING_AD_ACCOUNT_CONNECTED = 'adAccountRequired'
export const REQUIRED_SETTING_CHANNEL_ENABLED = 'channelEnabled'
export const REQUIRED_SETTING_INITIAL_RECONCILE_COMPLETE =
  'initialInventoryReconciledRequired'
export const REQUIRED_SETTING_NOT_EMBEDDED_APP = 'notEmbeddedApp'
export const REQUIRED_SETTING_NOT_USER_OVERVIEW = 'notUserOverview'
export const AI_OPTIMISATION = 'Playbook > Optimize with AI'

export const FEED_OPS_MENU_ITEMS = [
  {
    header: true,
    title: ACCOUNT_OVERVIEW,
    path: 'accounts/accounts_overview',
    absolutePath: true,
    requiredSettings: REQUIRED_SETTING_NOT_EMBEDDED_APP
  },
  {
    header: true,
    title: PLAYBOOK,
    path: 'website/playbook',
    tertiaryHeader: {
      showChannelSelection: true
    },
    requiredSettings: REQUIRED_SETTING_CHANNEL_ENABLED,
    toolTip: 'Please enable one or more channels.'
  },
  {
    header: false,
    title: AI_OPTIMISATION,
    path: 'website/ai_optimisation',
    requiredSettings: REQUIRED_SETTING_CHANNEL_ENABLED,
    leftNavbar: false
  },
  {
    header: true,
    title: COST_AND_GOAL,
    path: 'product_feeds/goal_budget_management',
    tertiaryHeader: {
      showChannelSelection: true,
      dateRangePicker: true
    },
    requiredSettings: REQUIRED_SETTING_AD_ACCOUNT_CONNECTED,
    toolTip: 'Please connect an ads account.'
  },
  {
    header: true,
    title: PRODUCT_LEVEL_OPTIMIZATIONS,
    path: 'product_feeds/product_level_optimizations',
    tertiaryHeader: {
      showChannelSelection: true,
      showFeedSelector: true,
      accessFeed: true
    },
    requiredSettings: REQUIRED_SETTING_CHANNEL_ENABLED,
    toolTip: 'Please enable one or more channels.'
  },
  {
    title: WEBSITE_SETTINGS,
    subMenuItems: [
      {
        title: WEBSITE_CONNECTION,
        path: 'website/store_details',
        tertiaryHeader: {
          showInventorySelector: true
        }
      },
      {
        title: PRODUCT_FEED_CHANNELS,
        path: 'website/connected_channels',
        tertiaryHeader: {
          showInventorySelector: true
        },
        toolTip: 'Please wait while we fetch product data from your website.'
      },
      {
        header: true,
        title: FEED_RULES,
        path: 'website/data_rules',
        requiredSettings: REQUIRED_SETTING_CHANNEL_ENABLED,
        toolTip: 'Please enable one or more channels.'
      },
      {
        title: EDIT_PRODUCT_TYPES,
        path: 'website/category_optimization',
        tertiaryHeader: true,
        requiredSettings: REQUIRED_SETTING_INITIAL_RECONCILE_COMPLETE,
        toolTip: 'Please wait while we fetch product data from your website.'
      },
      {
        title: EDIT_PRODUCT_ATTRIBUTES,
        path: 'website/attribute_optimization',
        tertiaryHeader: true,
        requiredSettings: REQUIRED_SETTING_INITIAL_RECONCILE_COMPLETE,
        toolTip: 'Please wait while we fetch product data from your website.'
      },
      {
        title: SUBSCRIPTION_DETAILS,
        path: 'settings',
        tertiaryHeader: true
      }
    ]
  },
  {
    title: ACCOUNT_SETTINGS,
    subMenuItems: [
      {
        title: ADD_WEBSITE,
        path: 'feed_ops/options',
        absolutePath: true,
        requiredSettings: REQUIRED_SETTING_NOT_EMBEDDED_APP
      },
      {
        title: MANAGE_USERS,
        path: 'feed_ops/accounts/users_overview',
        absolutePath: true,
        requiredSettings: REQUIRED_SETTING_NOT_EMBEDDED_APP
      },
      {
        title: ACCOUNT_DETAILS,
        path: 'feed_ops/accounts/account_details',
        absolutePath: true,
        requiredSettings: REQUIRED_SETTING_NOT_USER_OVERVIEW
      }
    ]
  },
  {
    footer: true,
    title: BOOK_A_MEETING,
    action: (url) => {
      openBookAMeeting()
    }
  }
]
