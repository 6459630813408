import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import sleep from 'util/sleep'

export const LIST_FEEDS_REQUEST = 'LIST_FEEDS_REQUEST'
export const LIST_FEEDS_RESPONSE = 'LIST_FEEDS_RESPONSE'
export const GET_FEED_FIELDS_REQUEST = 'GET_FEED_FIELDS_REQUEST'
export const GET_FEED_FIELDS_RESPONSE = 'GET_FEED_FIELDS_RESPONSE'
export const GET_FEED_REQUEST = 'GET_FEED_REQUEST'
export const GET_FEED_RESPONSE = 'GET_FEED_RESPONSE'
export const SAVE_FEED_REQUEST = 'SAVE_FEED_REQUEST'
export const SAVE_FEED_RESPONSE = 'SAVE_FEED_RESPONSE'
export const CLAIM_FEED_REQUEST = 'CLAIM_FEED_REQUEST'
export const CLAIM_FEED_RESPONSE = 'CLAIM_FEED_RESPONSE'
export const TEST_FEED_ENQUEUE_REQUEST = 'TEST_FEED_REQUEST'
export const TEST_FEED_ENQUEUE_RESPONSE = 'TEST_FEED_RESPONSE'
export const TEST_FEED_STATUS_REQUEST = 'TEST_FEED_STATUS_REQUEST'
export const TEST_FEED_STATUS_RESPONSE = 'TEST_FEED_STATUS_RESPONSE'
export const SET_FEED_BILLING_REQUEST = 'SET_FEED_BILLING_REQUEST'
export const SET_FEED_BILLING_RESPONSE = 'SET_FEED_BILLING_RESPONSE'
export const CREATE_CUSTOM_FEED_REQUEST = 'CREATE_CUSTOM_FEED_REQUEST'
export const CREATE_CUSTOM_FEED_RESPONSE = 'CREATE_CUSTOM_FEED_RESPONSE'
export const UPDATE_CUSTOM_FEED_REQUEST = 'UPDATE_CUSTOM_FEED_REQUEST'
export const UPDATE_CUSTOM_FEED_RESPONSE = 'UPDATE_CUSTOM_FEED_RESPONSE'
export const FEED_STATUS_REQUEST = 'FEED_STATUS_REQUEST'
export const FEED_STATUS_RESPONSE = 'FEED_STATUS_RESPONSE'

const listFeedsRequest = () => ({
  type: LIST_FEEDS_REQUEST
})

const listFeedsResponse = (feeds) => ({
  type: LIST_FEEDS_RESPONSE,
  feeds
})

export const listFeeds = () => (dispatch) => {
  dispatch(listFeedsRequest())
  return $.ajax({
    method: 'GET',
    dataType: 'json',
    url: `/ad_champion/feed_app/feeds`,
    success: (response) =>
      dispatch(
        listFeedsResponse(response.map((f) => camelCaseKeys(f, { deep: true })))
      )
  })
}

const newFeedRequest = (platform) => ({
  type: GET_FEED_FIELDS_REQUEST,
  platform
})

const newFeedResponse = (platform, payload) => ({
  type: GET_FEED_FIELDS_RESPONSE,
  platform,
  payload
})

export const getFeedFields = (platform) => (dispatch) => {
  dispatch(newFeedRequest(platform))
  return $.ajax({
    method: 'GET',
    dataType: 'json',
    url: `/ad_champion/feed_app/feeds/${platform}/fields`,
    success: (response) =>
      dispatch(
        newFeedResponse(platform, camelCaseKeys(response, { deep: true }))
      )
  })
}

const getFeedRequest = (id) => ({
  type: GET_FEED_REQUEST,
  id
})

const getFeedResponse = (id, feed) => ({
  type: GET_FEED_RESPONSE,
  id,
  feed
})

export const getFeed = (id) => (dispatch) => {
  dispatch(getFeedRequest(id))
  return $.ajax({
    method: 'GET',
    dataType: 'json',
    url: `/ad_champion/feed_app/feeds/${id}`,
    success: (response) => {
      dispatch(getFeedResponse(id, camelCaseKeys(response, { deep: true })))
    }
  })
}

const createFeed = (platform, channel, feed) => {
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    data: snakeCaseKeys({ ...feed, channel }, { deep: true }),
    url: `/ad_champion/feed_app/feeds/${platform}`
  })
}

const updateFeed = (feed) => {
  return $.ajax({
    method: 'PUT',
    dataType: 'json',
    data: snakeCaseKeys(feed, { deep: true }),
    url: `/ad_champion/feed_app/feeds/${feed.id}`
  })
}

const saveFeedRequest = (platform, feed) => ({
  type: SAVE_FEED_REQUEST,
  platform,
  feed
})

const saveFeedResponse = (platform, feed, response) => ({
  type: SAVE_FEED_RESPONSE,
  platform,
  feed,
  response
})

export const saveFeed = (platform, feed, channel) => (dispatch) => {
  dispatch(saveFeedRequest(platform, feed))
  var action = null
  if (feed.id) {
    action = updateFeed(feed)
  } else {
    action = createFeed(platform, channel, feed)
  }
  return action.then((response) => {
    dispatch(
      saveFeedResponse(platform, feed, camelCaseKeys(response, { deep: true }))
    )
  })
}

const testFeedEnqueueRequest = (platform, data) => ({
  type: TEST_FEED_ENQUEUE_REQUEST,
  platform,
  data
})

const testFeedEnqueueResponse = (platform, data, response) => ({
  type: TEST_FEED_ENQUEUE_RESPONSE,
  platform,
  data,
  response
})

export const testFeedEnqueue = (platform, data) => (dispatch) => {
  dispatch(testFeedEnqueueRequest(platform, data))
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    data: snakeCaseKeys(data, { deep: true }),
    url: `/ad_champion/feed_app/test_connection/${platform}/enqueue`,
    success: (response) =>
      dispatch(
        testFeedEnqueueResponse(
          platform,
          data,
          camelCaseKeys(response, { deep: true })
        )
      )
  })
}

const testFeedStatusRequest = (token) => ({
  type: TEST_FEED_STATUS_REQUEST,
  token
})

const testFeedStatusResponse = (token, status) => ({
  type: TEST_FEED_STATUS_RESPONSE,
  token,
  status
})

export const testFeedStatus = (token) => (dispatch) => {
  dispatch(testFeedStatusRequest(token))
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    data: { connection_token: token },
    url: '/ad_champion/feed_app/test_connection/status',
    success: (response) =>
      dispatch(
        testFeedStatusResponse(token, camelCaseKeys(response, { deep: true }))
      )
  })
}

export const testFeed = (platform, data) => async (dispatch) => {
  var response = await testFeedEnqueue(platform, data)(dispatch)
  var token = response.connection_token
  do {
    await sleep(500)
    response = await testFeedStatus(token)(dispatch)
    var canConnect = response.can_connect
    if (canConnect) {
      return canConnect
    }
  } while (token)
}

const createCustomFeedRequest = (feedUrl, websiteId, channel) => ({
  type: CREATE_CUSTOM_FEED_REQUEST,
  feedUrl,
  websiteId,
  channel
})

const createCustomFeedResponse = (feedUrl, websiteId, channel, response) => ({
  type: CREATE_CUSTOM_FEED_RESPONSE,
  feedUrl,
  websiteId,
  channel,
  response
})

export const createCustomFeed =
  (feedUrl, websiteId, channel) => async (dispatch) => {
    dispatch(createCustomFeedRequest(feedUrl, websiteId, channel))
    var response = await $.ajax({
      method: 'POST',
      datatType: 'json',
      data: snakeCaseKeys({ feedUrl, websiteId, channel }, { deep: true }),
      url: `/ad_champion/feed_app/feeds/create_custom_feed`
    })

    var camelCaseResponse = camelCaseKeys(response, { deep: true })
    dispatch(createCustomFeedResponse(feedUrl, websiteId, channel, response))
    return camelCaseResponse
  }

const updateCustomFeedRequest = (inventoryId, feedUrl) => ({
  type: UPDATE_CUSTOM_FEED_REQUEST,
  inventoryId,
  feedUrl
})

const updateCustomFeedResponse = (inventoryId, feedUrl, response) => ({
  type: UPDATE_CUSTOM_FEED_RESPONSE,
  inventoryId,
  feedUrl,
  response
})

export const updateCustomFeed = (inventoryId, feedUrl) => async (dispatch) => {
  dispatch(updateCustomFeedRequest(inventoryId, feedUrl))
  var response = await $.ajax({
    method: 'PUT',
    datatType: 'json',
    data: snakeCaseKeys({ inventoryId, feedUrl }, { deep: true }),
    url: `/ad_champion/feed_app/feeds/update_custom_feed`
  })

  var camelCaseResponse = camelCaseKeys(response, { deep: true })
  dispatch(updateCustomFeedResponse(inventoryId, feedUrl, response))
  return camelCaseResponse
}

const feedStatusRequest = (feedChampionFeedId) => ({
  type: FEED_STATUS_REQUEST,
  feedChampionFeedId
})

const feedStatusResponse = (feedChampionFeedId, response) => ({
  type: FEED_STATUS_RESPONSE,
  feedChampionFeedId,
  response
})

export const fetchFeedStatus = (feedChampionFeedId) => async (dispatch) => {
  dispatch(feedStatusRequest(feedChampionFeedId))
  var response = await $.ajax({
    method: 'GET',
    datatType: 'json',
    data: snakeCaseKeys({ id: feedChampionFeedId }, { deep: true }),
    url: `/ad_champion/feed_app/feeds/feed_status`
  })

  var camelCaseResponse = camelCaseKeys(response, { deep: true })
  dispatch(feedStatusResponse(feedChampionFeedId, response))
  return camelCaseResponse
}
