import camelCaseKeys from 'camelcase-keys'

export const ENABLE_DISABLE_CHANNEL_SETTINGS_REQUEST =
  'ENABLE_DISABLE_CHANNEL_SETTINGS_REQUEST'
export const ENABLE_DISABLE_CHANNEL_SETTINGS_RESPONSE =
  'ENABLE_DISABLE_CHANNEL_SETTINGS_RESPONSE'

const enableDisableChannelSettingsRequest = (websiteId, channel, enable) => ({
  type: ENABLE_DISABLE_CHANNEL_SETTINGS_REQUEST,
  websiteId,
  channel,
  enable
})

const enableDisableChannelSettingsReponse = (
  websiteId,
  channel,
  enable,
  response
) => ({
  type: ENABLE_DISABLE_CHANNEL_SETTINGS_RESPONSE,
  websiteId,
  channel,
  enable,
  ...response
})

export const enableDisableChannelSettings =
  (websiteId, channel, enable) => async (dispatch) => {
    dispatch(enableDisableChannelSettingsRequest(websiteId, channel, enable))
    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/channel_settings/enable_disable_channel?channel=${channel}&enable_channel=${enable}`,
      dataType: 'json'
    })

    response = camelCaseKeys(response, { deep: true })
    dispatch(
      enableDisableChannelSettingsReponse(websiteId, channel, enable, response)
    )
    return response
  }
