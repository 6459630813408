import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import './column_content.scss'
const NO_VALUE = 'Empty'

export class ColumnContent extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    attributeName: PropTypes.string.isRequired
  }

  computeContentData() {
    const { rowData, attributeName } = this.props
    const rawData = rowData[attributeName]['raw'] || NO_VALUE
    const overrideData = rowData[attributeName]['override']

    const contentData = rowData.showRaw ? rawData : overrideData || rawData

    return contentData.toString()
  }

  render() {
    const contentData = this.computeContentData()
    const { rowData, attributeName } = this.props

    return (
      <div
        key={rowData.itemId}
        className={`column-content`}
        title={contentData}
      >
        {rowData.showRaw ? (
          contentData
        ) : (
          <span className={rowData[attributeName]['source']}>
            {contentData}
          </span>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ColumnContent)
)
