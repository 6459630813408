import url from 'url'

export const addProtocolToWebsiteUrl = websiteUrl => {
  var urlTest = /^http(s)?:\/\//i
  if (urlTest.exec(websiteUrl) === null) {
    return `https://${websiteUrl || ''}`
  }
  return websiteUrl
}

export const stripProtocolFromWebsiteUrl = websiteUrl => {
  return url.parse(websiteUrl || '').hostname || websiteUrl
}
