import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import { fetchWebsiteDetail } from 'actions/websites'
import { fetchWebsiteSubscription, fetchWebsiteCustomer } from 'actions/billing'
import { fetchProductDataAudits } from 'actions/inventories'
import InventoryStatusSnackbar from 'components/feed_ops/common/inventory_status_snackbar'
import FeedOpsWebsiteServiceOptionsMobile from './website/service_options/mobile'
import FeedOpsWebsiteProductFeedsMobile from 'components/feed_ops/website/product_feeds/mobile'
import FeedOpsWebsiteServiceOptionsDesktop from './website/service_options/desktop'
import PromptForCreditCard from 'components/feed_ops/prompt_for_credit_card'
import FeedOpsWebsiteProductFeedsDesktop from 'components/feed_ops/website/product_feeds/desktop'
import WebsiteSettings from 'components/feed_ops/website/settings'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import FeedOpsWebsiteDefaultRoute from './website/default_route'
import { promptForCreditCardSelector } from 'selectors/billing'
import { isEmbeddedAppSelector } from 'selectors/app'
import { channelSelector } from 'selectors/channel'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import { accountTypeIdSelector } from 'selectors/user'
import {
  websiteFeedIdByChannelSelector,
  websiteRetailInventoryIdsSelector
} from 'selectors/websites'

export class FeedOpsWebsite extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isMobileOS: PropTypes.bool.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    fetchWebsiteCustomer: PropTypes.func.isRequired,
    fetchProductDataAudits: PropTypes.func.isRequired,
    promptForCreditCard: PropTypes.bool.isRequired,
    inventoryReconciledStatus: PropTypes.bool.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  async componentDidMount() {
    await this.fetchWebsiteData()
    await this.fetchAudits()
    this.setState({ loading: false })
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (websiteId !== prevProps.websiteId) {
      this.setState({ loading: true })
      await this.fetchWebsiteData()
      await this.fetchAudits()
      this.setState({ loading: false })
    }
  }

  async fetchWebsiteData() {
    const {
      websiteId,
      fetchWebsiteDetail,
      fetchWebsiteSubscription,
      fetchWebsiteCustomer
    } = this.props
    await Promise.all([
      fetchWebsiteDetail(websiteId),
      fetchWebsiteCustomer(websiteId),
      fetchWebsiteSubscription(websiteId)
    ])
  }

  async fetchAudits() {
    const { retailInventoryIds, fetchProductDataAudits } = this.props
    if (retailInventoryIds && retailInventoryIds.length > 0) {
      await fetchProductDataAudits(retailInventoryIds[0])
    }
  }

  renderOptionsRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/options`}
        component={
          isMobileOS
            ? FeedOpsWebsiteServiceOptionsMobile
            : FeedOpsWebsiteServiceOptionsDesktop
        }
        error={() => <FeedOpsWebsiteDefaultRoute />}
      />
    )
  }

  renderProductFeedsOrWebsiteRoutes(match) {
    const { isEmbeddedApp } = this.props

    return (
      <GuardedRoute
        path={`${match.path}/(product_feeds|website)`}
        component={
          isMobileOS
            ? isEmbeddedApp
              ? FeedOpsWebsiteProductFeedsMobile
              : FeedOpsWebsiteServiceOptionsMobile
            : FeedOpsWebsiteProductFeedsDesktop
        }
        error={() => <FeedOpsWebsiteDefaultRoute />}
      />
    )
  }

  renderSettingRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/settings`}
        component={WebsiteSettings}
      />
    )
  }

  renderRoutes() {
    const { match, inventoryReconciledStatus } = this.props
    return (
      <div className='platform-website'>
        <Switch>
          {this.renderOptionsRoute(match)}
          {this.renderProductFeedsOrWebsiteRoutes(match)}
          {this.renderSettingRoute(match)}
          <GuardedRoute
            exact
            path={`${match.path}`}
            render={() => <FeedOpsWebsiteDefaultRoute />}
          />
          <GuardedRoute
            path='*'
            render={() => <FeedOpsWebsiteDefaultRoute />}
          />
        </Switch>
        { !inventoryReconciledStatus && <InventoryStatusSnackbar /> }
      </div>
    )
  }

  render() {
    const { promptForCreditCard } = this.props
    const { loading } = this.state

    if (loading) {
      return <AdChampionIndeterminateProgressIndicator />
    }

    if (promptForCreditCard) {
      return <PromptForCreditCard />
    }

    return this.renderRoutes()
  }
}

const mapStateToProps = (state, props) => ({
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  websiteId: accountTypeIdSelector(state, props),
  channel: channelSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  retailInventoryIds: websiteRetailInventoryIdsSelector(state, props),
  promptForCreditCard: promptForCreditCardSelector(state, props),
  inventoryReconciledStatus: initialReconciledStatusSelector(state, props)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteDetail,
      fetchWebsiteSubscription,
      fetchWebsiteCustomer,
      fetchProductDataAudits
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsWebsite)
)
