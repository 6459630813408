import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { updateAccountOverviewFilters } from 'actions/users/accounts'
import AppliedFiltersSection from 'components/feed_ops/common/popover/applied_filters_section'
import { currentUserAccountsOverviewFiltersSelector } from 'selectors/user'

export class AccountAppliedFiltersSection extends Component {
  static propTypes = {
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateAccountOverviewFilters: PropTypes.func.isRequired
  }

  handleRemoveFilterAtIndex(updatedFilters) {
    const { updateAccountOverviewFilters } = this.props
    updateAccountOverviewFilters(updatedFilters)
  }

  handleApplyFilterClick(updatedAppliedFilters) {
    const { updateAccountOverviewFilters } = this.props
    updateAccountOverviewFilters(updatedAppliedFilters)
  }

  render() {
    const { filteredAccounts, appliedFilters } = this.props

    return (
      <AppliedFiltersSection
        forFeature='Account'
        availableAttributes={[]}
        filteredAccounts={filteredAccounts}
        appliedFilters={appliedFilters}
        handleRemoveFilterAtIndex={this.handleRemoveFilterAtIndex.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  appliedFilters: currentUserAccountsOverviewFiltersSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateAccountOverviewFilters }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountAppliedFiltersSection)
)
