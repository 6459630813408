import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  FILE_UPLOAD_IMPORT_SOURCE,
  FILE_URL_IMPORT_SOURCE,
  GOOGLE_SHEET_IMPORT_SOURCE,
  GOOGLE_MC_CONTENT_API_IMPORT_SOURCE,
  MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE,
  SourceImportDropdown
} from './source_import_dropdown'
import FileImportSource from './file_import_source'
import GoogleSheetsSource from './google_sheets_source'
import GoogleContentApiSource from './google_content_api_source'
import MicrosoftContentApiSource from './microsoft_content_api_source'
import AttributesMapping from './attributes_mapping'

import './source_import_container.scss'

export class SourceImportContainer extends Component {
  static propTypes = {
    handleIsValidChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedSource: '',
      fileUrl: '',
      accessSettings: {},
      headers: [],
      mappings: []
    }
  }

  componentDidMount() {
    const { headers } = this.state
    const { handleIsValidChange } = this.props
    handleIsValidChange(headers.length > 0)
  }

  renderIntroText() {
    return (
      <div className='intro-text'>
        We support files in the following formats:
        <ul>
          <li>Comma Separated (.csv)</li>
          <li>Tab Separated (.txt or .tsv)</li>
          <li>Extensible Markup Language (.xml)</li>
        </ul>
        We also support compressed versions of each of these file types such as:
        <ul>
          <li>Gunzip (.gzip)</li>
          <li>Zip (.zip)</li>
          <li>BZip2 (.bz2)</li>
        </ul>
      </div>
    )
  }

  handleInputSourceChange(source) {
    this.setState({ selectedSource: source, fileUrl: '', headers: [] })
  }

  handleFileDataChange(fileUrl, headers, mappings) {
    this.setState({
      fileUrl,
      accessSettings: {},
      headers,
      mappings
    })
  }

  renderFileInputSource(selectedSource) {
    return (
      <FileImportSource
        sourceType={selectedSource}
        handleFileDataChange={this.handleFileDataChange.bind(this)}
      />
    )
  }

  handleApiSourceChange(accessSettings, headers, mappings) {
    this.setState({ fileUrl: '', accessSettings, headers, mappings })
  }

  renderGoogleSheetsSource() {
    return (
      <GoogleSheetsSource
        handleApiSourceChange={this.handleApiSourceChange.bind(this)}
      />
    )
  }

  renderGoogleContentApiSource() {
    return (
      <GoogleContentApiSource
        handleApiSourceChange={this.handleApiSourceChange.bind(this)}
      />
    )
  }

  renderMicrosoftContentApiSource() {
    return (
      <MicrosoftContentApiSource
        handleApiSourceChange={this.handleApiSourceChange.bind(this)}
      />
    )
  }

  conditionallyRenderSourceInputFields() {
    const { selectedSource } = this.state

    switch (selectedSource) {
      case FILE_UPLOAD_IMPORT_SOURCE:
      case FILE_URL_IMPORT_SOURCE:
        return this.renderFileInputSource(selectedSource)
      case GOOGLE_SHEET_IMPORT_SOURCE:
        return this.renderGoogleSheetsSource()
      case GOOGLE_MC_CONTENT_API_IMPORT_SOURCE:
        return this.renderGoogleContentApiSource()
      case MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE:
        return this.renderMicrosoftContentApiSource()
      default:
        return null
    }
  }

  conditionallyRenderAttributesMapping() {
    const { fileUrl, accessSettings, headers, mappings } = this.state
    return (
      <AttributesMapping
        fileUrl={fileUrl}
        accessSettings={accessSettings}
        importHeaders={headers}
        initialMappings={mappings}
        handleIsValidChange={this.props.handleIsValidChange}
      />
    )
  }

  render() {
    const { selectedSource, headers } = this.state

    return (
      <div className='source-import-container'>
        {this.renderIntroText()}
        <SourceImportDropdown
          onInputSourceChange={this.handleInputSourceChange.bind(this)}
        />
        {selectedSource && this.conditionallyRenderSourceInputFields()}
        {headers &&
          headers.length > 0 &&
          this.conditionallyRenderAttributesMapping()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SourceImportContainer)
)
