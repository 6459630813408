require('jquery')
var ReactRailsUJS = require('react_ujs')
require('components')

$(document).ready(function() {
  $('.pagination')
    .children()
    .addClass('page-item')
  $('.page-item')
    .children()
    .addClass('page-link')
  $('.pagination')
    .children('.active')
    .addClass('disabled')
  ReactRailsUJS.mountComponents()
})

window.FeedOpsApp = require('containers/feed_ops_app')
