import {
  CREATE_VARIATIONS_SHEET_REQUEST,
  CREATE_VARIATIONS_SHEET_SUCCESS
} from 'actions/variations_sheet'

const initialState = {
  sheetId: ''
}

const variationsSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_VARIATIONS_SHEET_SUCCESS:
      return { ...state, sheetId: action.sheetId }
    default:
      return state
  }
}

export default variationsSheetReducer
