import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, isValid, registerField } from 'redux-form'
import {
  remoteFormSubmissionHandlers,
  remoteSubmitAndWait
} from 'actions/forms'
import {
  fetchInventoryDetail,
  fetchInventoriesListByWebsiteId
} from 'actions/inventories'
import {
  createWebsite,
  updateWebsite,
  deleteWebsite,
  fetchFullWebsiteList
} from 'actions/websites'
import { fetchWebsiteSubscription } from 'actions/billing'
import FeedPlatformForm, {
  REDUX_FORM_NAME as FEED_PLATFORM_FORM
} from 'components/inventory/feed_platform_form'
import FeedUrlForm, {
  REDUX_FORM_NAME as URL_FORM
} from 'components/inventory/feed_url_form'
import CredentialsForm, {
  REDUX_FORM_NAME as CREDENTIALS_FORM
} from 'components/inventory/feed_app/credentials_form'
import FeedChampionForm from 'components/inventory/feed_champion_form'
import OtherPlatformForm, {
  REDUX_FORM_NAME as OTHER_PLATFORM_FORM
} from 'components/inventory/other_platform_form'
import { createValidator } from 'components/util/validation'
import { appIdSelector } from 'selectors/app'
import { firstInventoryInWebsiteSelector } from 'selectors/inventory'
import { websiteIdSelector, websiteAccountIdSelector } from 'selectors/websites'
import { trackEvent, WEBSITE_LEAD } from 'util/tag_manager'
import { accountTypeIdSelector, accountTypeSelector } from 'selectors/user'
import { settingsValueSelector } from 'selectors/settings'
import {
  PLATFORM_CUSTOM_XML,
  PLATFORM_GOOGLE_SHEET,
  PLATFORM_OTHER
} from 'util/supported_platforms'

const FORM_FIELDS = [
  'selectedPlatform',
  'credentialsValid',
  'websiteUrl',
  'websiteId'
]

export class FeedForm extends Component {
  static propTypes = {
    selectedPlatform: PropTypes.string.isRequired,
    credentialsValid: PropTypes.bool.isRequired,
    registerField: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    inventoryId: PropTypes.number,
    inventory: PropTypes.object,
    websiteId: PropTypes.number.isRequired,
    fetchInventoryDetail: PropTypes.func.isRequired,
    fetchInventoriesListByWebsiteId: PropTypes.func.isRequired,
    showAdvanced: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
    createWebsite: PropTypes.func.isRequired,
    updateWebsite: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    fetchWebsiteSubscription: PropTypes.func.isRequired
  }

  static defaultProps = {
    showAdvanced: true
  }

  componentDidMount() {
    const {
      initialize,
      selectedPlatform,
      credentialsValid,
      registerField,
      fetchInventoryDetail,
      inventoryId,
      websiteId,
      fetchInventoriesListByWebsiteId,
      websiteUrl
    } = this.props
    if (inventoryId) {
      fetchInventoryDetail(inventoryId)
    }

    if (websiteId) {
      fetchInventoriesListByWebsiteId(websiteId)
    }

    FORM_FIELDS.forEach((f) => registerField(REDUX_FORM_NAME, f, 'Field'))
    initialize({ selectedPlatform, credentialsValid, websiteUrl, websiteId })
  }

  updateValues(prevProps) {
    const { change } = this.props
    FORM_FIELDS.forEach((f) => {
      if (this.props[f] !== prevProps[f]) {
        change(f, this.props[f])
      }
    })
  }

  fetchData(prevProps) {
    const {
      fetchInventoryDetail,
      fetchInventoriesListByWebsiteId,
      inventoryId,
      websiteId
    } = this.props
    if (inventoryId && inventoryId !== prevProps.inventoryId) {
      fetchInventoryDetail(inventoryId)
    }

    if (websiteId && websiteId !== prevProps.websiteId) {
      fetchInventoriesListByWebsiteId(websiteId)
    }
  }

  componentDidUpdate(prevProps) {
    this.fetchData(prevProps)
    this.updateValues(prevProps)
  }

  renderCredentialsForm() {
    const { selectedPlatform, inventory } = this.props

    const { websiteId } = this.props.formValues
    switch (selectedPlatform) {
      case PLATFORM_CUSTOM_XML:
        return (
          <FeedUrlForm
            inventory={inventory}
            websiteId={websiteId}
            workflowType={inventory.id ? 'edit' : 'new'}
            showCurrency={true}
          />
        )
      case PLATFORM_GOOGLE_SHEET:
        return (
          <FeedChampionForm
            selectedPlatform={selectedPlatform}
            inventory={inventory}
            websiteId={websiteId}
          />
        )
      case PLATFORM_OTHER:
        return <OtherPlatformForm websiteId={websiteId} />
      default:
        return (
          <CredentialsForm
            platform={selectedPlatform}
            feedId={inventory && parseInt(inventory.feedChampionReference, 10)}
            showAdvancedToggle={true}
            websiteId={websiteId}
          />
        )
    }
  }

  render() {
    const { selectedPlatform, inventoryId, showAdvanced } = this.props
    return (
      <div>
        <FeedPlatformForm
          selectedPlatform={selectedPlatform}
          readOnly={inventoryId > 0}
          advanced={showAdvanced}
        />
        {selectedPlatform && this.renderCredentialsForm()}
      </div>
    )
  }
}

export const selectedPlatform = (state, props) => {
  const formValues = getFormValues(FEED_PLATFORM_FORM)(state) || {}
  var selectedPlatform = formValues.selectedPlatform
  var settingsPlatform = settingsValueSelector(state, 'platform')
  var inventory = firstInventoryInWebsiteSelector(state, props)
  if (inventory.id > 0) {
    selectedPlatform = inventory.feedChampionPlatform || 'xml'
  }

  return selectedPlatform || settingsPlatform
}

const formCheck = (form, state) => {
  var value = {
    credentialsValid: true,
    websiteUrl: ''
  }
  if (form) {
    value.credentialsValid = isValid(form)(state)
    value.websiteUrl = (getFormValues(form)(state) || {}).websiteUrl
  }
  return value
}

export const mapStateToProps = (state, props) => {
  const inventory = firstInventoryInWebsiteSelector(state, props)
  const accountTypeId = accountTypeIdSelector(state, props)
  const accountType = accountTypeSelector(state, props)
  var platform = selectedPlatform(state, props)
  var formValues = formCheck(selectedPlatformToForm(platform), state)
  var credentialsValid = formValues.credentialsValid
  var websiteUrl = formValues.websiteUrl

  return {
    accountId: websiteAccountIdSelector(state, props),
    accountTypeId,
    accountType,
    appId: appIdSelector(state, props),
    selectedPlatform: selectedPlatform(state, props),
    chargebeePlan: settingsValueSelector(state, 'plan_id'),
    credentialsValid,
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    inventoryId: inventory.id,
    inventory: {
      ...inventory,
      feedChampionUrl: state.inventories.feedChampionUrl
    },
    trackEvent,
    websiteId: websiteIdSelector(state, props),
    websiteUrl
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerField,
      fetchInventoryDetail,
      fetchInventoriesListByWebsiteId,
      createWebsite,
      updateWebsite,
      deleteWebsite,
      remoteSubmitAndWait,
      fetchFullWebsiteList,
      fetchWebsiteSubscription
    },
    dispatch
  )

const FeedFormWithRedux = connect(mapStateToProps, mapDispatchToProps)(FeedForm)

const selectedPlatformToForm = (selectedPlatform) => {
  switch (selectedPlatform) {
    case 'xml':
      return URL_FORM
    case 'google_sheet':
      return null
    case 'other':
      return OTHER_PLATFORM_FORM
    default:
      return CREDENTIALS_FORM
  }
}

const createWebsiteIfRequired = async (values, props) => {
  const { websiteId, accountId, accountType, accountTypeId, appId } = props
  const { selectedPlatform, websiteUrl } = values
  var data = {}
  if (websiteId) {
    await props.updateWebsite(websiteId, {
      website: { url: websiteUrl }
    })
    data = { accountId: accountId }
  } else if (accountType === 'accounts') {
    data = { accountId: accountTypeId }
  }

  const response = await props.createWebsite({
    appId,
    website: { platform: selectedPlatform, url: websiteUrl, ...data }
  })
  await props.change('websiteId', response.websiteId)
  await props.fetchWebsiteSubscription(response.websiteId)
  return response
}

export const handleSubmit = async (values, dispatch, props) => {
  const { selectedPlatform } = values
  const { websiteId, newWebsite } = await createWebsiteIfRequired(values, props)
  var form = selectedPlatformToForm(selectedPlatform)
  if (form) {
    try {
      await props.remoteSubmitAndWait(form)
      if (newWebsite) {
        props.trackEvent(WEBSITE_LEAD)
      }
      await props.fetchFullWebsiteList()
      toastr.info('Fetching product data from your website.')
    } catch (ex) {
      if (newWebsite) {
        await props.deleteWebsite(websiteId, true)
      }
      throw ex
    }
  }
}

export const REDUX_FORM_NAME = 'FeedForm'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate: createValidator(['selectedPlatform', 'credentialsValid']),
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(FeedFormWithRedux)
  )
)
