export const FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_ERROR = 'FETCH_CURRENCIES_ERROR';

const fetchCurrenciesRequest = () => ({
  type: FETCH_CURRENCIES_REQUEST
});

const receiveCurrencies = (currencies) => ({
  type: FETCH_CURRENCIES_SUCCESS,
  currencies: currencies
});

const receiveCurrenciesError = () => ({
  type: FETCH_CURRENCIES_ERROR,
  errorMessage: 'An error occurred while fetching the currencies list.'
});

export const requestCurrencies = () => dispatch => {
  dispatch(fetchCurrenciesRequest());
  return $.ajax({
    method: 'GET',
    url: '/currencies',
    dataType: 'json',
    success: (response) => {
      dispatch(receiveCurrencies(response));
    },
    error: (response) => {
      dispatch(receiveCurrenciesError());
    }
  })
};
