import { trackPageView } from 'util/tag_manager'

var currentPage = ''

const isLocationChangeAction = action =>
  action.type === '@@router/LOCATION_CHANGE'

const updateCurrentPageAndTrack = action => {
  const nextPage = `${action.payload.pathname}${action.payload.search}`
  if (currentPage !== nextPage) {
    currentPage = nextPage
    trackPageView(nextPage)
  }
}

const pageTrackingMiddleware = store => next => action => {
  if (isLocationChangeAction(action)) {
    updateCurrentPageAndTrack(action)
  }

  return next(action)
}

export default pageTrackingMiddleware
