import {
  CREATE_SHOPPING_MAPPING_SHEET_REQUEST,
  CREATE_SHOPPING_MAPPING_SHEET_SUCCESS,
  APPLY_SHOPPING_MAPPING_SHEET_REQUEST,
  APPLY_SHOPPING_MAPPING_SHEET_SUCCESS,
  APPLY_SHOPPING_MAPPING_SHEET_ERROR
} from 'actions/shopping_mappings_sheet'

const initialState = {
  sheetId: '',
  loading: true,
  saving: false,
  feedUrl: ''
}

const shoppingMappingsSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SHOPPING_MAPPING_SHEET_REQUEST:
      return { ...state, loading: true }
    case CREATE_SHOPPING_MAPPING_SHEET_SUCCESS:
      return {
        ...state,
        sheetId: action.sheetId,
        feedUrl: action.feedUrl,
        loading: false
      }
    case APPLY_SHOPPING_MAPPING_SHEET_REQUEST:
      return { ...state, saving: true }
    case APPLY_SHOPPING_MAPPING_SHEET_SUCCESS:
      return { ...state, sheetId: '', saving: false }
    case APPLY_SHOPPING_MAPPING_SHEET_ERROR:
      return { ...state, saving: false }
    default:
      return state
  }
}

export default shoppingMappingsSheetReducer
