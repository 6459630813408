import _ from 'lodash'
import {
  OP_ONE_OF,
  OP_EQUALS,
  OP_LESS_THAN,
  OP_GREATER_THAN
} from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'

export const ACCOUNT_NAME_ATTRIBUTE = 'Account Name'
export const WEBSITE_ATTRIBUTE = 'Website'
export const SKU_ATTRIBUTE = '#SKU'
export const HEALTH_ATTRIBUTE = 'Health'
export const SCORE_ATTRIBUTE = 'Score'

export const DEFAULT_AVAILABLE_ATTRIBUTES = [
  WEBSITE_ATTRIBUTE,
  SKU_ATTRIBUTE,
  HEALTH_ATTRIBUTE,
  SCORE_ATTRIBUTE
]

export const NUMERIC_OPERATORS = [OP_EQUALS, OP_LESS_THAN, OP_GREATER_THAN]

export const FILTER_ATTRIBUTES = {
  [ACCOUNT_NAME_ATTRIBUTE]: {
    name: ACCOUNT_NAME_ATTRIBUTE,
    filterOptions: [OP_ONE_OF]
  },
  [WEBSITE_ATTRIBUTE]: {
    name: WEBSITE_ATTRIBUTE,
    filterOptions: [OP_ONE_OF]
  },
  [SKU_ATTRIBUTE]: {
    name: SKU_ATTRIBUTE,
    filterOptions: NUMERIC_OPERATORS
  },
  [HEALTH_ATTRIBUTE]: {
    name: HEALTH_ATTRIBUTE,
    filterOptions: [OP_ONE_OF]
  },
  [SCORE_ATTRIBUTE]: {
    name: SCORE_ATTRIBUTE,
    filterOptions: NUMERIC_OPERATORS
  }
}

export const searchedAttributeValues = (
  attributeName,
  filteredAccounts,
  searchText
) => {
  var values = []

  if (attributeName === ACCOUNT_NAME_ATTRIBUTE) {
    values = _.sortBy(filteredAccounts.map((account) => account.accountName))
  } else {
    values = _.sortBy(
      filteredAccounts.map((account) => account.websites).flat(),
      'websiteName'
    ).map((website) => website.websiteName)
  }

  if (!_.isEmpty(searchText)) {
    const regex = new RegExp(searchText, 'i')
    return _.sortBy(values.filter((value) => regex.test(value)))
  }

  return values
}

const filterByAccountName = (accountsOverview, filter) => {
  return accountsOverview.filter((account) =>
    filter.values.includes(account.accountName)
  )
}

const filterByWebsiteName = (accountsOverview, filter) => {
  return accountsOverview.map((account) => ({
    ...account,
    websites: account.websites.filter((website) =>
      filter.values.includes(website.websiteName)
    )
  }))
}

const filterByNumericValues = (accountsOverview, filter, attributeName) => {
  return accountsOverview.map((account) => ({
    ...account,
    websites: account.websites.filter((website) => {
      const filterValues = parseInt(filter.values)
      if (filter.filterType === OP_EQUALS) {
        return filterValues === website[attributeName]
      } else if (filter.filterType === OP_LESS_THAN) {
        return website[attributeName] < filterValues
      } else {
        return website[attributeName] > filterValues
      }
    })
  }))
}

const filterByHealth = (accountsOverview, filter) => {
  return accountsOverview.map((account) => ({
    ...account,
    websites: account.websites.filter((website) =>
      filter.values.includes(website.health)
    )
  }))
}

export const filterAccountsOverview = (accountsOverview, appliedFilters) => {
  var filteredAccounts = [...accountsOverview]

  appliedFilters.forEach((filter) => {
    switch (filter.attributeName) {
      case ACCOUNT_NAME_ATTRIBUTE:
        filteredAccounts = filterByAccountName([...filteredAccounts], filter)
        break
      case WEBSITE_ATTRIBUTE:
        filteredAccounts = filterByWebsiteName([...filteredAccounts], filter)
        break
      case SKU_ATTRIBUTE:
        filteredAccounts = filterByNumericValues(
          [...filteredAccounts],
          filter,
          'skus'
        )
        break
      case HEALTH_ATTRIBUTE:
        filteredAccounts = filterByHealth([...filteredAccounts], filter)
        break
      case SCORE_ATTRIBUTE:
        filteredAccounts = filterByNumericValues(
          [...filteredAccounts],
          filter,
          'score'
        )
        break
      default:
        break
    }
  })

  return filteredAccounts
}
