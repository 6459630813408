import { createSelector } from 'reselect'

export const getGoogleUser = (getState) => {
  const state = getState()
  const { user } = state.google
  return user
}

export const getAccessToken = (getState) => {
  const { accessToken } = getGoogleUser(getState)
  return accessToken
}

export const getRefreshToken = (getState) => {
  const { refreshToken } = getGoogleUser(getState)
  return refreshToken
}

const googleSelector = (state) => state.google || {}

export const googleUserAccessTokenSelector = createSelector(
  googleSelector,
  (google) => {
    const { accessToken } = google.user || {}
    return accessToken
  }
)
