import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
import { isMobileOS } from 'util/operating_systems'
import { MDBNavbar, MDBNavbarNav } from 'mdbreact'
import Header from 'components/header'
import BottomBanner from './common/bottom_banner'
import { profileCompleteSelector, loggedInSelector } from 'selectors/user'
import {
  showSideNavSelector,
  isEmbeddedAppSelector,
  isActionsPageSelector
} from 'selectors/app'
import feedOpsLogo from 'images/feedops_logo_horiz.png'

export class FeedOpsHeader extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    profileComplete: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    showSideNav: PropTypes.bool.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    isActionsPage: PropTypes.bool.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  renderMobileHeader() {
    return (
      <MDBNavbar
        style={{ paddingLeft: '16px' }}
        className='mobile-navbar'
        double={true}
        expand='md'
        fixed='top'
      >
        <MDBNavbarNav>
          <img className='feedops-logo' alt='FeedOps' src={feedOpsLogo} />
        </MDBNavbarNav>
      </MDBNavbar>
    )
  }

  renderHeader() {
    const { showSideNav, isActionsPage, profileComplete, loggedIn, menuItems } =
      this.props

    return (
      <>
        <BottomBanner />
        <Header
          sideNav={showSideNav}
          menuItems={menuItems}
          websitesMenu={false}
          helpButton={true}
          settingsMenu={loggedIn}
          secondaryHeader={profileComplete && !isActionsPage}
        />
      </>
    )
  }

  render() {
    const { match } = this.props

    return (
      <Switch>
        <Route
          path={[`${match.path}/sign_up`, `${match.path}/sign_in`]}
          render={() =>
            isMobileOS ? this.renderMobileHeader() : this.renderHeader()
          }
        />
        <Route path={`${match.path}`} render={() => this.renderHeader()} />
      </Switch>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  showSideNav: showSideNavSelector(state, props),
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  isActionsPage: isActionsPageSelector(state, props),
  profileComplete: profileCompleteSelector(state, props),
  loggedIn: loggedInSelector(state, props)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsHeader)
)
