import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import feedOpsLogo from 'images/feed_ops/feed_ops_logo_horiz_black.png'

export default class AuditHeaderTemplate extends Component {
  static propTypes = {
    headerText: PropTypes.string.isRequired
  }

  render() {
    const { headerText } = this.props

    return (
      <MDBContainer className='audit-page-header'>
        <MDBRow>
          <MDBCol>
            <img
              src={feedOpsLogo}
              alt='FeedOps Pty. Ltd.'
              className='img-fluid'
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className='header-text-col'>
            <h1>{headerText}</h1>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}
