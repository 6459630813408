const findDuplicates = (array) => {
  var uniq = (array || [])
    .map((keyValue) => {
      return {
        count: 1,
        key: keyValue[0]
      }
    })
    .reduce((a, b) => {
      a[b.key] = (a[b.key] || 0) + b.count
      return a
    }, {})

  return Object.keys(uniq).filter((a) => uniq[a] > 1)
}

export const findAdditionalTaggingDuplicates = (values) => {
  return findDuplicates(values.additionalTagging || [])
}

export const findAdsTaggingDuplicates = (values) => {
  return findDuplicates(values.adsTagging)
}

const findIfEmpty = (array) => {
  var keys = (array || []).map((keyValue) => {
    return keyValue[0]
  })
  var values = (array || []).map((keyValue) => {
    return keyValue[1]
  })

  return keys.includes('')
    ? values.includes('')
      ? 'empty parameters & values'
      : 'empty parameters'
    : values.includes('')
    ? 'empty values'
    : ''
}

const additionalTaggingValidationErrors = (
  additionalTaggingIsEmpty,
  additionalTaggingDuplicates
) => {
  return additionalTaggingIsEmpty
    ? { additionalTagging: additionalTaggingIsEmpty }
    : additionalTaggingDuplicates.length > 0
    ? { additionalTagging: 'duplicates' }
    : {}
}

const adsTaggingValidationErrors = (
  adsTaggingIsEmpty,
  adsTaggingDuplicates
) => {
  return adsTaggingIsEmpty
    ? { adsTagging: adsTaggingIsEmpty }
    : adsTaggingDuplicates.length > 0
    ? { adsTagging: 'duplicates' }
    : {}
}

export const validate = (values) => {
  const additionalTaggingIsEmpty = findIfEmpty(values.additionalTagging)
  const additionalTaggingDuplicates = findAdditionalTaggingDuplicates(values)
  const adsTaggingIsEmpty = findIfEmpty(values.adsTagging)
  const adsTaggingDuplicates = findAdsTaggingDuplicates(values)
  return {
    ...additionalTaggingValidationErrors(
      additionalTaggingIsEmpty,
      additionalTaggingDuplicates
    ),
    ...adsTaggingValidationErrors(adsTaggingIsEmpty, adsTaggingDuplicates)
  }
}
