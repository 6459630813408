import camelCaseKeys from 'camelcase-keys'

export const FETCH_VALUE_OPERATORS_REQUEST = 'FETCH_VALUE_OPERATORS_REQUEST'
export const FETCH_VALUE_OPERATORS_RESPONSE = 'FETCH_VALUE_OPERATORS_RESPONSE'

const fetchValueOperatorsRequest = () => ({
  type: FETCH_VALUE_OPERATORS_REQUEST
})

const fetchValueOperatorsResponse = (response) => ({
  type: FETCH_VALUE_OPERATORS_RESPONSE,
  ...response
})

export const fetchValueOperators = () => async (dispatch) => {
  dispatch(fetchValueOperatorsRequest())
  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/value/operators',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchValueOperatorsResponse(response))
  return response
}
