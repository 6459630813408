import { FETCH_DRIVE_DOC_RESPONSE } from 'actions/google/drive_docs'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVE_DOC_RESPONSE:
      const splitTitle = action.title.split(' progress ')
      return {
        ...state,
        [action.articleId]: {
          title: splitTitle[0],
          description: action.description,
          progress: splitTitle[1]
        }
      }
    default:
      return state
  }
}
