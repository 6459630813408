import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { updateAttributeFilters } from 'actions/product_feeds/replacements'
import { MDBBtn } from 'mdbreact'
import { Popover } from '@material-ui/core'
import PopoverSection from './attribute_filters_section/popover_section'
import { websiteIdSelector } from 'selectors/websites'

export class AttributeFiltersSection extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateAttributeFilters: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      anchorEl: null,
      popoverOpen: false
    }
  }

  handleUpdateAttributeFilters(updatedAttributeFilters) {
    const { updateAttributeFilters, websiteId } = this.props
    updateAttributeFilters(websiteId, updatedAttributeFilters)

    this.setState({
      popoverOpen: false
    })
  }

  handleAddFiltersClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      popoverOpen: true
    })
  }

  renderAttributeFilterBtn() {
    return (
      <MDBBtn
        color='link'
        tag='span'
        className='btn-column'
        onClick={this.handleAddFiltersClick.bind(this)}
      >
        <i className='fas fa-filter'></i>
        {'Add Filters'}
      </MDBBtn>
    )
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  renderPopover() {
    const { anchorEl, popoverOpen } = this.state
    const { appliedFilters } = this.props

    return (
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className='attribute-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        <PopoverSection
          appliedFilters={appliedFilters}
          handleClosePopover={this.handleClosePopover.bind(this)}
          updateAttributeFilters={this.handleUpdateAttributeFilters.bind(this)}
        />
      </Popover>
    )
  }

  render() {
    return (
      <>
        {this.renderAttributeFilterBtn()}
        {this.renderPopover()}
      </>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateAttributeFilters }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttributeFiltersSection)
)
