import camelCaseKeys from 'camelcase-keys'

export const FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST =
  'FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST'
export const FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS =
  'FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS'
export const FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST =
  'FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST'
export const FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS =
  'FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS'
export const FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_REQUEST =
  'FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_REQUEST'
export const FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_SUCCESS =
  'FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_SUCCESS'

const fetchMerchantCenterProductInfoAndMeasuresRequest = (websiteId) => ({
  type: FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST,
  websiteId
})

const fetchMerchantCenterProductInfoAndMeasuresSuccess = (
  websiteId,
  websiteProductInfoAndMeasures
) => ({
  type: FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS,
  websiteId,
  websiteProductInfoAndMeasures:
    websiteProductInfoAndMeasures.mcProductInfoAndMeasure
})

export const fetchMerchantCenterProductInfoAndMeasures = (websiteId) => async (
  dispatch
) => {
  dispatch(fetchMerchantCenterProductInfoAndMeasuresRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/merchant_center_product_info_and_measure/`,
    dataType: 'json'
  })

  dispatch(
    fetchMerchantCenterProductInfoAndMeasuresSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchSystemWideMerchantCenterProductInfoAndMeasuresRequest = () => ({
  type: FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_REQUEST
})

const fetchSystemWideMerchantCenterProductInfoAndMeasuresSuccess = (
  systemWideProductInfoAndMeasures
) => ({
  type: FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS,
  systemWideProductInfoAndMeasures:
    systemWideProductInfoAndMeasures.systemWideMcDiagnostics
})

export const fetchSystemWideMerchantCenterProductInfoAndMeasures = () => async (
  dispatch
) => {
  dispatch(fetchSystemWideMerchantCenterProductInfoAndMeasuresRequest())
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/system_wide_data/merchant_center_product_info_and_measure`,
    dataType: 'json'
  })

  dispatch(
    fetchSystemWideMerchantCenterProductInfoAndMeasuresSuccess(
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchAccountMerchantCenterStatusRequest = (accountId) => ({
  type: FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_REQUEST,
  accountId
})

const fetchAccountMerchantCenterStatusSuccess = (
  accountId,
  accountMerchantCenterStatus
) => ({
  type: FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_SUCCESS,
  accountId,
  accountMerchantCenterStatus:
    accountMerchantCenterStatus.merchantCenterStatuses
})

export const fetchAccountMerchantCenterStatus = (accountId) => async (
  dispatch
) => {
  dispatch(fetchAccountMerchantCenterStatusRequest(accountId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/status/get_merchant_center_statuses`,
    dataType: 'json'
  })

  dispatch(
    fetchAccountMerchantCenterStatusSuccess(
      accountId,
      camelCaseKeys(response, { deep: true })
    )
  )
}
