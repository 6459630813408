import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact'

export default class FilterPopoverHeader extends Component {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    handleCloseFilterPopover: PropTypes.func.isRequired
  }

  handleCloseFilterPopover() {
    const { handleCloseFilterPopover } = this.props
    handleCloseFilterPopover()
  }

  renderPopoverHeader() {
    const { attributeName } = this.props

    return (
      <>
        <MDBCol size='10'>{attributeName}</MDBCol>
        <MDBCol size='2'>
          <div className='float-right'>
            <MDBIcon
              icon='times'
              className='close-icon'
              onClick={this.handleCloseFilterPopover.bind(this)}
            />
          </div>
        </MDBCol>
      </>
    )
  }

  render() {
    return (
      <MDBRow className='selected-attribute-row'>
        {this.renderPopoverHeader()}
      </MDBRow>
    )
  }
}
