import {
  CLEAR_REQUEST_QUEUE,
  SET_SIDE_NAV_OPEN,
  SET_BOOK_A_MEETING_MODAL_OPEN,
  SET_CHANNEL_FILTER_MODAL_OPEN,
  SET_CHANNEL_SETTINGS_MODAL_OPEN,
  SET_WORK_EMAIL_MODAL_OPEN
} from 'actions/app'

const responseTypes = ['receive', 'response', 'error', 'success']

const initialState = {
  pendingRequests: [],
  sideNavOpen: false,
  bookAMeetingModalOpen: false,
  bookAMeetingMeetingType: '',
  bookAMeetingJsonData: {},
  channelFilterModalOpen: false,
  workEmailModalOpen: false,
  channelSettingsModalOpen: false
}

const updatePendingRequests = (state, action) => {
  var type = action.type.toLowerCase()
  var requests = state.pendingRequests.slice(0)

  if (type.indexOf('request') >= 0) {
    requests.push(type)
  } else {
    var requestType = type
    responseTypes.forEach((rt) => {
      var responseType = requestType.replace(rt, 'request')
      var requestIndex = requests.findIndex((r) => r === responseType)
      if (requestIndex >= 0) {
        requests.splice(requestIndex, 1)
      }
    })
  }

  return { ...state, pendingRequests: requests }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REQUEST_QUEUE:
      return { ...state, pendingRequests: [] }
    case SET_SIDE_NAV_OPEN:
      return { ...state, sideNavOpen: action.open }
    case SET_BOOK_A_MEETING_MODAL_OPEN:
      return {
        ...state,
        bookAMeetingModalOpen: action.open,
        bookAMeetingMeetingType: action.meetingType,
        bookAMeetingJsonData: action.jsonData
      }
    case SET_CHANNEL_FILTER_MODAL_OPEN:
      return {
        ...state,
        channelFilterModalOpen: action.open
      }
    case SET_CHANNEL_SETTINGS_MODAL_OPEN:
      return {
        ...state,
        channelSettingsModalOpen: action.open
      }
    case SET_WORK_EMAIL_MODAL_OPEN:
      return {
        ...state,
        workEmailModalOpen: action.open
      }
    default:
      return updatePendingRequests(state, action)
  }
}
