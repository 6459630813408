import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isSubmitting, isValid } from 'redux-form'
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact'
import {
  EDIT_CONNECTION_DETAILS,
  CONFIGURE_ATTRIBUTE_MAPPINGS,
  SELECT_WEBSITE_CATEGORIES
} from '../store_connection'
import OtherPlatformForm, {
  REDUX_FORM_NAME as OTHER_PLATFORM_FORM
} from 'components/inventory/other_platform_form'
import CredentialsForm, {
  REDUX_FORM_NAME as CREDENTIALS_FORM
} from 'components/inventory/feed_app/credentials_form'
import AttributeMapping, {
  REDUX_FORM_NAME as ATTRIBUTES_FORM
} from './attribute_mapping'
import CategoryTree, {
  REDUX_FORM_NAME as CATEGORIES_FORM
} from './category_tree'
import { remoteSubmitAndWait } from 'actions/forms'
import {
  triggerInventoryDownloadByInventoryId,
  fetchInventoryProcessingStatus
} from 'actions/inventories'
import { inventoryDetailSelector } from 'selectors/inventory'

export class ConnectionOptionsModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    platform: PropTypes.string,
    inventoryId: PropTypes.number.isRequired,
    feedChampionFeedId: PropTypes.number.isRequired,
    inboundFeedUrl: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    triggerInventoryDownloadByInventoryId: PropTypes.func.isRequired,
    fetchInventoryProcessingStatus: PropTypes.func.isRequired
  }

  static defaultProps = {
    isModalOpen: false
  }

  async triggerDownload() {
    const { inventoryId, triggerInventoryDownloadByInventoryId } = this.props

    await triggerInventoryDownloadByInventoryId(inventoryId, true)
  }

  async handleUpdating(formName, openModal) {
    const { remoteSubmitAndWait, closeModal, title } = this.props

    await remoteSubmitAndWait(formName)
    var response = await this.triggerDownload()
    if (response && !!response.error) {
      toastr.error(
        'An error occurred while reprocessing your feed. Please contact support for assistance.'
      )
    } else {
      toastr.success('Reprocessing feed')
      const { fetchInventoryProcessingStatus, inventoryId } = this.props
      await fetchInventoryProcessingStatus(inventoryId)
    }
    closeModal(openModal, title)
  }

  renderOtherPlatformForm() {
    const { inventoryId, websiteId, inboundFeedUrl } = this.props

    return (
      <OtherPlatformForm
        updating={true}
        inventoryId={inventoryId}
        websiteId={websiteId}
        feedUrl={inboundFeedUrl}
      />
    )
  }

  renderEditConnectionDetails() {
    const { websiteId, platform, feedChampionFeedId } = this.props

    return (
      <>
        <CredentialsForm
          websiteId={websiteId}
          platform={platform}
          feedId={feedChampionFeedId}
        />
        <div className='btn-connect-container'>
          <MDBBtn
            tag='span'
            onClick={this.handleUpdating.bind(this, CREDENTIALS_FORM, true)}
          >
            Test Connection
          </MDBBtn>
        </div>
      </>
    )
  }

  conditionallyRenderConnectionDetails() {
    const { platform } = this.props

    if (['xml', 'other'].includes(platform)) {
      return this.renderOtherPlatformForm()
    } else {
      return this.renderEditConnectionDetails()
    }
  }

  renderAttributeMappings() {
    const { websiteId } = this.props
    return <AttributeMapping websiteId={websiteId} />
  }

  renderWebsiteCategories() {
    const { websiteId } = this.props
    return <CategoryTree websiteId={websiteId} />
  }

  getModalBodyComponent() {
    const { title } = this.props
    var component = null

    switch (title) {
      case EDIT_CONNECTION_DETAILS:
        component = this.conditionallyRenderConnectionDetails()
        break
      case CONFIGURE_ATTRIBUTE_MAPPINGS:
        component = this.renderAttributeMappings()
        break
      case SELECT_WEBSITE_CATEGORIES:
        component = this.renderWebsiteCategories()
        break
      default:
        break
    }

    return component
  }

  handleCancel() {
    const { closeModal } = this.props
    closeModal(false)
  }

  handleSave() {
    const { title, platform } = this.props
    switch (title) {
      case EDIT_CONNECTION_DETAILS:
        this.handleUpdating(
          ['xml', 'other'].includes(platform)
            ? OTHER_PLATFORM_FORM
            : CREDENTIALS_FORM,
          false
        )
        break
      case CONFIGURE_ATTRIBUTE_MAPPINGS:
        this.handleUpdating(ATTRIBUTES_FORM, false)
        break
      case SELECT_WEBSITE_CATEGORIES:
        this.handleUpdating(CATEGORIES_FORM, false)
        break
      default:
        break
    }
  }

  renderModalFooter() {
    const { isSubmitting, isValid } = this.props
    const disabled = isSubmitting || !isValid

    return (
      <MDBModalFooter className='common-modal-footer'>
        <MDBBtn
          color='dark'
          tag='span'
          outline
          onClick={this.handleCancel.bind(this)}
        >
          Cancel
        </MDBBtn>
        <MDBBtn
          id='save-and-continue'
          color='primary'
          tag='span'
          disabled={disabled}
          onClick={this.handleSave.bind(this)}
        >
          Save
        </MDBBtn>
      </MDBModalFooter>
    )
  }

  render() {
    const { isModalOpen, title } = this.props
    var component = this.getModalBodyComponent()

    return (
      <MDBModal
        isOpen={isModalOpen}
        position='right'
        size='lg'
        backdrop={false}
        fullHeight
      >
        <MDBModalHeader className='full-height-right-modal-header'>
          {title}
        </MDBModalHeader>
        <MDBModalBody className='full-height-right-modal-body'>
          {component}
        </MDBModalBody>
        {this.renderModalFooter()}
      </MDBModal>
    )
  }
}

export const mapStateToProps = (state, props) => {
  var submitting = false
  var valid = true
  switch (props.title) {
    case EDIT_CONNECTION_DETAILS:
      submitting = isSubmitting(CREDENTIALS_FORM)(state) || false
      valid = isValid(CREDENTIALS_FORM)(state) || true
      break
    case CONFIGURE_ATTRIBUTE_MAPPINGS:
      submitting = isSubmitting(ATTRIBUTES_FORM)(state) || false
      valid = isValid(ATTRIBUTES_FORM)(state) || true
      break
    case SELECT_WEBSITE_CATEGORIES:
      submitting = isSubmitting(CATEGORIES_FORM)(state) || false
      valid = isValid(CATEGORIES_FORM)(state) || true
      break
    default:
      break
  }

  const inventoryDetail = inventoryDetailSelector(props.inventoryId)(
    state,
    props
  )
  const { feedChampionReference, feedChampionPlatform } = inventoryDetail
  const feedChampionFeedId = parseInt(feedChampionReference, 10)
  return {
    isSubmitting: submitting,
    isValid: valid,
    feedChampionFeedId,
    platform: feedChampionPlatform
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      remoteSubmitAndWait,
      triggerInventoryDownloadByInventoryId,
      fetchInventoryProcessingStatus
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectionOptionsModal)
)
