import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBtn } from 'mdbreact'

import './rule_pill_content.scss'

export default class RulePillContent extends Component {
  static propTypes = {
    btnText: PropTypes.string.isRequired
  }

  render() {
    const { btnText } = this.props

    return (
      <MDBBtn
        key={btnText.replace(' ', '')}
        className='filter-btn'
        tag='span'
        disabled
      >
        {btnText}
      </MDBBtn>
    )
  }
}
