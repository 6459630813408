import { createSelector } from 'reselect'
import {
  advertisingBudgetsSelector,
  advertisingGoalsSelector,
  hasBudgetCategoriesSelector
} from 'selectors/advertising'
import { goalTypeSelector } from 'selectors/advertising_goal_types'
import {
  parseTargetsAsFloats,
  calculateGoalTargetByGoalType,
  selectDataUnit,
  getDataUnit
} from './helper'
import { performanceDataSelector } from 'selectors/performance'
import {
  performanceWithGoalAndBudgetSelector,
  convValueTotalSelector,
  clicksTotalSelector,
  conversionsTotalSelector,
  targetSpendToHitDailyGoalTotalSelector
} from 'selectors/cost_and_goal/general'

export const websitePerformanceWithGoalAndBudgetSelector = (websiteId) =>
  createSelector(
    [
      advertisingBudgetsSelector('websites', websiteId),
      advertisingGoalsSelector('websites', websiteId),
      performanceDataSelector('websites', websiteId),
      goalTypeSelector('websites', websiteId),
      hasBudgetCategoriesSelector(websiteId)
    ],
    (budgets, goals, data, goalType, hasBudgetCategories) => {
      const currentBudget = budgets[budgets.length - 1]
      const currentGoal = goals[goals.length - 1]
      var websitePerformanceWithGoalAndBudget
      if (hasBudgetCategories) {
        websitePerformanceWithGoalAndBudget = data.map(
          (websitePerformance) => ({
            ...websitePerformance,
            goal: calculateGoalTargetByGoalType(
              goalType,
              websitePerformance.targetSpendToHitDailyGoal,
              getDataUnit(goalType, websitePerformance)
            )
          })
        )
      } else {
        websitePerformanceWithGoalAndBudget = parseTargetsAsFloats(
          data || [],
          goals,
          'goal',
          currentGoal
        )
      }

      websitePerformanceWithGoalAndBudget = parseTargetsAsFloats(
        websitePerformanceWithGoalAndBudget || [],
        budgets,
        'budget',
        currentBudget
      )
      return websitePerformanceWithGoalAndBudget
    }
  )

export const websiteGoalTargetSelector = (websiteId) =>
  createSelector(
    [
      performanceWithGoalAndBudgetSelector('websites', websiteId),
      goalTypeSelector('websites', websiteId),
      convValueTotalSelector('websites', websiteId),
      conversionsTotalSelector('websites', websiteId),
      clicksTotalSelector('websites', websiteId),
      targetSpendToHitDailyGoalTotalSelector('websites', websiteId),
      hasBudgetCategoriesSelector(websiteId)
    ],
    (
      websitePerformanceWithGoalAndBudget,
      goalType,
      convValue,
      conversions,
      clicks,
      targetSpendToHitDailyGoal,
      hasBudgetCategories
    ) => {
      if (hasBudgetCategories) {
        return calculateGoalTargetByGoalType(
          goalType,
          targetSpendToHitDailyGoal,
          selectDataUnit(goalType, convValue, conversions, clicks)
        )
      }

      var totalGoal = websitePerformanceWithGoalAndBudget.reduce(
        (total, day) => (total += day.goal),
        0
      )
      return totalGoal / websitePerformanceWithGoalAndBudget.length
    }
  )

export const websiteCurrencySelector = (websiteId) =>
  createSelector(
    [performanceDataSelector('websites', websiteId)],
    (data) => (data[0] && data[0].currency) || '$'
  )
