import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setWorkEmailModalOpen } from 'actions/app'
import { updateUser } from 'actions/users'
import {
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBBtn,
  MDBIcon
} from 'mdbreact'
import toastr from 'toastr'
import { workEmailModalOpenSelector } from 'selectors/app'
import { workEmailSelector, currentUserSelector } from 'selectors/user'
import { validateEmail } from 'components/util/validation'

import './work_email_modal.scss'

export class WorkEmailModal extends Component {
  static propTypes = {
    workEmailModalOpen: PropTypes.bool.isRequired,
    workEmail: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    setWorkEmailModalOpen: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = { waiting: false }
  }

  closeWorkEmailModal() {
    const { setWorkEmailModalOpen } = this.props
    setWorkEmailModalOpen(false)
  }

  async updateWorkEmail() {
    const { updateUser, currentUser } = this.props
    this.setState({ error: '', waiting: true })

    var user = currentUser
    const newWorkEmail = document.getElementById('workEmail').value
    const emailValidationError = validateEmail(newWorkEmail)

    if (!emailValidationError) {
      user.profile.workEmail = newWorkEmail
      const response = await updateUser(user)

      if (!response.error) {
        toastr.success('Work Email Updated')
        this.closeWorkEmailModal()
      } else {
        toastr.error(response.error)
      }
    } else {
      toastr.error('Please enter a valid email')
    }

    this.setState({ waiting: false })
  }

  renderModalFooter() {
    return (
      <MDBModalFooter>
        <MDBBtn
          size='sm'
          disabled={this.state.waiting}
          onClick={this.closeWorkEmailModal.bind(this)}
          outline
        >
          Cancel
        </MDBBtn>
        <MDBBtn
          size='sm'
          disabled={this.state.waiting}
          onClick={this.updateWorkEmail.bind(this)}
        >
          {this.state.waiting ? <MDBIcon spin fixed icon='spinner' /> : ''} Save
        </MDBBtn>
      </MDBModalFooter>
    )
  }

  render() {
    const { workEmailModalOpen, workEmail } = this.props

    return (
      <MDBModal
        className={'work-email-modal'}
        centered={true}
        isOpen={workEmailModalOpen}
        toggle={this.closeWorkEmailModal.bind(this)}
      >
        <MDBModalBody>
          Work Email Address
          <MDBInput id='workEmail' type='email' valueDefault={workEmail} />
          {this.renderModalFooter()}
        </MDBModalBody>
      </MDBModal>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    workEmailModalOpen: workEmailModalOpenSelector(state),
    workEmail: workEmailSelector(state, props),
    currentUser: currentUserSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setWorkEmailModalOpen, updateUser }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkEmailModal)
)
