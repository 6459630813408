import React, { Component } from 'react'
import { MDBCard, MDBCardBody } from 'mdbreact'
import SignInOptions from 'components/sign_up/sign_in_options'

export default class PlatformSignInDesktop extends Component {
  render() {
    return (
      <MDBCard className='sign-in'>
        <MDBCardBody>
          <SignInOptions />
        </MDBCardBody>
      </MDBCard>
    )
  }
}
