import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import changeCase from 'change-case'
import { feedopsAdvertisingTotals } from 'selectors/performance'
import { formatNumber } from 'util/format_number'

export class FeedOpsPerformanceStatistics extends Component {
  static propTypes = {
    statistics: PropTypes.shape({
      impressions: PropTypes.number,
      clicks: PropTypes.number,
      conversions: PropTypes.number,
      roas: PropTypes.number
    }).isRequired
  }

  renderStatistic(key) {
    const { statistics } = this.props
    const value = formatNumber(statistics[key], 0)
    return (
      <span className='statistic' key={key}>
        {key === 'roas'
          ? `${value}% ROAS`
          : `${value} ${changeCase.titleCase(key)}`}
      </span>
    )
  }

  render() {
    return (
      <div className='feed-ops-performance-statistics'>
        {['impressions', 'clicks', 'conversions', 'roas'].map((stat) =>
          this.renderStatistic(stat)
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  statistics: feedopsAdvertisingTotals(state, props)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsPerformanceStatistics)
)
