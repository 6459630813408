import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import toastr from 'toastr'
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import { fetchInventoryAttributes } from 'actions/product_feeds/replacements'
import { websiteIdSelector } from 'selectors/websites'
import AttributeValueList from './product_attributes/attribute_value_list'

export class AttributeList extends Component {
  static propTypes = {
    fetchInventoryAttributes: PropTypes.func.isRequired,
    attributes: PropTypes.object.isRequired,
    attributeValues: PropTypes.object.isRequired,
    toastr: PropTypes.object.isRequired,
    onToggleAttribute: PropTypes.func.isRequired,
    onCollapseAllAttributes: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    ChildComponent: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  constructor(props) {
    super(props)
    this.state = {
      loadingAttributes: true
    }
  }

  async componentDidMount() {
    await this.fetchAttributeData()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (prevProps.websiteId !== websiteId) {
      await this.fetchAttributeData()
    }
  }

  async fetchAttributeData() {
    this.setState({ loadingAttributes: true })
    const { fetchInventoryAttributes, websiteId, toastr } = this.props
    try {
      await fetchInventoryAttributes(websiteId, true)
    } catch (error) {
      toastr.error(
        'An error occurred while fetching your attributes. Please contact support for assistance.'
      )
    }
    this.setState({ loadingAttributes: false })
  }

  async collapseAll() {
    const { onCollapseAllAttributes } = this.props
    await onCollapseAllAttributes()
  }

  handleSearch(event) {
    const { onSearchKeywordChanged } = this.props
    onSearchKeywordChanged(event.target.value)
  }

  renderCollapseAllButton() {
    return (
      <MDBBtn
        className={'collapse-all btn-href-style'}
        onClick={this.collapseAll.bind(this)}
      >
        Collapse all
      </MDBBtn>
    )
  }

  renderControlBar() {
    const { searchKeyword, attributes } = this.props
    return (
      <div className={'control-bar'}>
        <div className={'search-bar'}>
          <MDBIcon icon={'search'} />
          <MDBInput
            placeholder={`Search attributes`}
            onChange={this.handleSearch.bind(this)}
            value={searchKeyword}
          />
        </div>
        {Object.keys(attributes).length > 2 && this.renderCollapseAllButton()}
      </div>
    )
  }

  renderAttributeListContent() {
    const {
      attributes,
      attributeValues,
      ChildComponent,
      onToggleAttribute
    } = this.props
    return (
      <div className={'attribute-list-content'}>
        {this.renderControlBar()}
        <div className={'attribute-list'}>
          <div className='attribute-list-children'>
            {Object.values(attributes || {}).map((attr) => (
              <AttributeValueList
                key={attr.id}
                attribute={attr}
                ChildComponent={ChildComponent}
                onToggleAttribute={onToggleAttribute}
                attributeValues={attributeValues[attr.id] || {}}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { loadingAttributes } = this.state
    if (loadingAttributes) {
      return (
        <AdChampionIndeterminateProgressIndicator
          message='Loading attributes'
          className='loading-indicator'
        />
      )
    }

    return (
      <div className={'attribute-list'}>
        {this.renderAttributeListContent()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryAttributes
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttributeList)
)
