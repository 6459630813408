import { createSelector } from 'reselect'
import changeCase from 'change-case'
import { hasBudgetCategoriesSelector } from 'selectors/advertising'
import {
  budgetCategoryRecommendationSelector,
  budgetCategoryPerformanceByCampaignTypeSelector,
  websitePerformanceByBudgetCategorySelector
} from 'selectors/google/performance'
import { defaultGoogleChannelSelector } from 'selectors/channel'
import { websiteIdSelector } from 'selectors/websites'

const performanceSelector = (state) => state.performance || {}

const performanceWebsitesSelector = createSelector(
  [performanceSelector],
  (p) => p.websites || {}
)

export const performanceWebsiteSelector = (websiteId) =>
  createSelector(
    [performanceWebsitesSelector],
    (websites) => websites[websiteId] || {}
  )

export const performanceWebsiteChannelSelector = (websiteId, channel) =>
  createSelector(
    [performanceWebsiteSelector(websiteId)],
    (website) => website[channel] || {}
  )

export const websiteRecommendationSelector = (accountType, websiteId) =>
  createSelector(
    [
      performanceChannelSelector(accountType, websiteId),
      hasBudgetCategoriesSelector(websiteId)
    ],
    (channel, hasBudgetCategories) => {
      const recommendations = channel.recommendations || []
      if (hasBudgetCategories) {
        return formatRecommendations(recommendations)
      }

      return recommendations
    }
  )

export const recommendationSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryRecommendationSelector(id)
    case 'websites':
      return websiteRecommendationSelector(accountType, id)
    case 'accounts':
      return formattedRecommendationList(accountType, id)
    case 'master_accounts':
      return formattedRecommendationList(accountType, id)
    default:
      return () => ({})
  }
}

const performanceAccountsSelector = createSelector(
  [performanceSelector],
  (p) => p.accounts || {}
)

export const performanceAccountSelector = (accountId) =>
  createSelector(
    [performanceAccountsSelector],
    (accounts) => accounts[accountId] || { data: [] }
  )

const performanceMasterAccountsSelector = createSelector(
  [performanceSelector],
  (p) => p.masterAccounts || {}
)

export const performanceMasterAccountSelector = (masterAccountId) =>
  createSelector(
    [performanceMasterAccountsSelector],
    (masterAccounts) => masterAccounts[masterAccountId] || { data: [] }
  )

const performanceRecommendationsSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.recommendations || []
  )

export const formattedRecommendationList = (accountType, id) =>
  createSelector(
    [performanceRecommendationsSelector(accountType, id)],
    (recommendations) => formatRecommendations(recommendations)
  )

function formatRecommendations(recommendations) {
  return recommendations.map((recommendation) => {
    const status =
      recommendation.type[0].toUpperCase() + recommendation.type.slice(1)
    return {
      website: recommendation.websiteName,
      budgetCategory: recommendation.budgetCategoryName,
      status: status,
      id: recommendation.websiteId || recommendation.budgetCategoryId
    }
  })
}

export const performanceByCampaignTypeSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.performanceByCampaignType || []
  )

const performanceAccountTypeSelector = (accountType) =>
  createSelector(
    [performanceSelector],
    (performance) => performance[changeCase.camelCase(accountType)] || {}
  )

const performanceAccountTypeByIdSelector = (accountType, id) =>
  createSelector(
    [performanceAccountTypeSelector(accountType)],
    (accountType) => accountType[id] || {}
  )

const performanceChannelSelector = (accountType, id) =>
  createSelector(
    [
      performanceAccountTypeByIdSelector(accountType, id),
      defaultGoogleChannelSelector
    ],
    (accountTypeById, channel) => accountTypeById[channel] || {}
  )

const performanceBySubAccountTypeSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.performanceBySubAccountType || []
  )

export const performanceDataSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.data || []
  )

export const performanceDownloadErrorsSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.errors || []
  )

const calculatedValues = (row, totalCost) => {
  const { cost, totalConvValue, conversions, clicks } = row
  const conversionValuePerCost = cost === 0 ? 0 : totalConvValue / cost
  const ROAS = cost === 0 ? 0 : (totalConvValue * 100) / cost
  const CPA = cost === 0 ? 0 : cost / conversions
  const CPC = cost === 0 ? 0 : cost / clicks
  const percentageOfTotalCost = totalCost === 0 ? 0 : (cost * 100) / totalCost
  return {
    conversionValuePerCost,
    ROAS,
    CPA,
    CPC,
    percentageOfTotalCost
  }
}

const performanceReportWithCalculatedRows = (reportSelector) =>
  createSelector([reportSelector], (rows) => {
    const totalCost = rows.reduce((total, row) => total + row.cost, 0)
    return rows.map((r) => ({
      ...r,
      ...calculatedValues(r, totalCost)
    }))
  })

export const accountOrWebsiteBreakdownReportSelector = (
  accountType,
  groupType,
  id
) => {
  var reportSelector
  switch (accountType) {
    case 'budget_categories':
      reportSelector = budgetCategoryPerformanceByCampaignTypeSelector(id)
      break
    case 'websites':
      if (groupType === 'campaign_types') {
        reportSelector = performanceByCampaignTypeSelector(accountType, id)
      } else {
        reportSelector = websitePerformanceByBudgetCategorySelector(id)
      }
      break
    case 'accounts':
      reportSelector = performanceBySubAccountTypeSelector(accountType, id)
      break
    case 'master_accounts':
      reportSelector = performanceBySubAccountTypeSelector(accountType, id)
      break
    default:
      return () => {}
  }
  return performanceReportWithCalculatedRows(reportSelector)
}

export const subAccountTypeCountSelector = (accountType, id) =>
  createSelector(
    [performanceChannelSelector(accountType, id)],
    (channel) => channel.subAccountTypeCount || 0
  )

const performanceFeedopsAdvertisingSelector = createSelector(
  [performanceSelector],
  (performance) => performance.feedopsAdvertising || {}
)

export const feedopsAdvertisingByChannelAndWebsiteSelector = createSelector(
  [
    performanceFeedopsAdvertisingSelector,
    defaultGoogleChannelSelector,
    websiteIdSelector
  ],
  (performance, channel, websiteId) =>
    performance[channel] &&
    Object.keys(performance[channel]).length > 0 &&
    performance[channel][websiteId]
      ? performance[channel][websiteId]
      : {}
)

export const feedopsAdvertisingAllTotals = createSelector(
  [feedopsAdvertisingByChannelAndWebsiteSelector],
  (perf) => perf.totalValues || []
)

export const feedopsAdvertisingTotals = createSelector(
  [feedopsAdvertisingAllTotals],
  (allTotals) =>
    allTotals && allTotals.length > 0
      ? allTotals.find((values) => values.type === 'Total')
      : {}
)

export const advertisingTotalsByType = (campaignType) =>
  createSelector(
    [feedopsAdvertisingAllTotals],
    (allTotals) =>
      allTotals.find((values) => values.type === campaignType) || {}
  )

export const feedopsAdvertisingGoalTypes = createSelector(
  [feedopsAdvertisingByChannelAndWebsiteSelector],
  (perf) => perf.goalTypes || {}
)

export const advertisingGoalTypeByType = (campaignType) =>
  createSelector(
    [feedopsAdvertisingGoalTypes],
    (goals) => goals[campaignType] || { goalType: 'missing' }
  )

export const feedopsAdvertisingRecommendations = createSelector(
  [feedopsAdvertisingByChannelAndWebsiteSelector],
  (perf) => perf.recommendations || {}
)

export const advertisingRecommendationsByType = (campaignType) =>
  createSelector(
    [feedopsAdvertisingRecommendations],
    (recommendations) => recommendations[campaignType] || {}
  )
