import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Select from 'react-select'
import { countriesGeoTargetSelector } from 'selectors/geo_targets'

export class CountrySelectorDropdown extends Component {
  static propTypes = {
    countries: PropTypes.object.isRequired,
    selectedCountryChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { geoTarget: null }
  }

  componentDidMount() {
    const { countries, geoTargetId } = this.props

    if (geoTargetId) {
      this.setState({
        geoTarget: { label: countries[geoTargetId], value: geoTargetId }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { geoTargetId } = this.props
    if (!_.isEqual(prevProps.geoTargetId, geoTargetId)) {
      const { countries } = this.props

      this.setState({
        geoTarget: geoTargetId
          ? { label: countries[geoTargetId], value: geoTargetId }
          : null
      })
    }
  }

  generateOptions() {
    const { countries } = this.props
    var options = []

    Object.entries(countries).forEach(([geoTargetId, countryName]) => {
      options.push({ label: countryName, value: geoTargetId })
    })

    return options
  }

  handleCountryChange(geoTarget) {
    const { selectedCountryChange } = this.props
    selectedCountryChange(geoTarget.value)
    this.setState({ geoTarget })
  }

  render() {
    const { geoTarget } = this.state
    const options = this.generateOptions()

    return (
      <Select
        classNamePrefix='geo-target'
        options={options}
        value={geoTarget}
        isSearchable={true}
        placeholder='Select Country'
        onChange={this.handleCountryChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    countries: countriesGeoTargetSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CountrySelectorDropdown)
)
