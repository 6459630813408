import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class AdChampionMessage extends Component {
  static propTypes = {
    messageShort: PropTypes.node,
    messageLong: PropTypes.node,
    messageType: PropTypes.string
  }

  componentDidMount() {
    $(function () {
      $('[data-toggle="popover"]').popover({html: true});
    });
  }

  render() {
    var alertClass = '';
    if(this.props.messageType){
      alertClass = `alert alert-${this.props.messageType}`;
    }
    return (
      <blockquote className="blockquote bq-primary">
        <div className={`message ${alertClass}`}>
          {this.props.messageShort}          
          {this.props.messageLong &&
            <i className="fa fa-question-circle" data-content={this.props.messageLong} data-placement="bottom" data-toggle="popover" data-trigger="click"></i>
          }
        </div>
      </blockquote>
    );
  }
}
