import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST =
  'FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST'
export const FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE =
  'FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE'
export const UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_REQUEST =
  'UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_REQUEST'
export const UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_RESPONSE =
  'UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_RESPONSE'
export const UPDATE_PRODUCT_ATTRIBUTE_REPLACEMENTS =
  'UPDATE_PRODUCT_ATTRIBUTE_REPLACEMENTS'
export const SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST =
  'SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST'
export const SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE =
  'SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE'
export const UPDATE_DISABLED_INVENTORY_ITEMS = 'UPDATE_DISABLED_INVENTORY_ITEMS'
export const CLEAR_UPDATED_ITEMS_AND_REPLACEMENTS =
  'CLEAR_UPDATED_ITEMS_AND_REPLACEMENTS'
export const UPDATE_ATTRIBUTE_FILTERS = 'UPDATE_ATTRIBUTE_FILTERS'
export const UPDATE_SELECTED_SUGGESTION_COLUMNS =
  'UPDATE_SELECTED_SUGGESTION_COLUMNS'
export const SAVE_FEED_SETTINGS_REQUEST = 'SAVE_FEED_SETTINGS_REQUEST'
export const SAVE_FEED_SETTINGS_RESPONSE = 'SAVE_FEED_SETTINGS_RESPONSE'
export const PRODUCT_ATTRIBUTE_REPLACEMENTS_QUERY_CHANGED_STATUS =
  'PRODUCT_ATTRIBUTE_REPLACEMENTS_QUERY_CHANGED_STATUS'
export const UPDATE_PRODUCT_ROW_SELECTION_STATUS =
  'UPDATE_PRODUCT_ROW_SELECTION_STATUS'

const fetchProductAttributeReplacementsRequest = (
  websiteId,
  feedId,
  channel,
  page,
  perPage,
  filters,
  displayedColumns,
  batchIds
) => ({
  type: FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST,
  websiteId,
  feedId,
  channel,
  page,
  perPage,
  filters,
  displayedColumns,
  batchIds
})

const fetchProductAttributeReplacementsResponse = (
  websiteId,
  feedId,
  channel,
  page,
  perPage,
  filters,
  displayedColumns,
  batchIds,
  response
) => ({
  type: FETCH_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  websiteId,
  feedId,
  channel,
  page,
  perPage,
  filters,
  displayedColumns,
  batchIds,
  ...response
})

const buildFilterPayload = (filters) =>
  JSON.stringify(filters.map((f) => snakeCaseKeys(f)))

const getProductAttributeReplacements = async (
  websiteId,
  feedId,
  channel,
  page,
  perPage,
  filters,
  displayedColumns,
  batchIds
) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  var filterPayload = buildFilterPayload(filters)
  const url = `/ad_champion/websites/${websiteId}/replacements/products?feed_id=${feedId}&page=${page}&per_page=${perPage}&channel=${channel}&suggestions_batch_ids=${encodeURIComponent(
    batchIds
  )}&filters=${encodeURIComponent(
    filterPayload
  )}&displayed_columns=${encodeURIComponent(displayedColumns)}`

  var startTime = new Date()
  var response
  while (status === 202) {
    response = await $.ajax({ method: 'GET', url, statusCode })
    var elapsedTime = new Date() - startTime
    if (response.replacements.length >= perPage && elapsedTime > 3000) {
      break
    }
    await sleep(500)
  }
  return camelCaseKeys(response, { deep: true })
}

export const fetchProductAttributeReplacements =
  (
    websiteId,
    feedId,
    channel,
    page,
    perPage,
    filters,
    displayedColumns,
    batchIds = null
  ) =>
  async (dispatch) => {
    var args = [
      websiteId,
      feedId,
      channel,
      page,
      perPage,
      filters,
      displayedColumns,
      batchIds
    ]
    dispatch(fetchProductAttributeReplacementsRequest(...args))

    var response = await getProductAttributeReplacements(...args)

    dispatch(fetchProductAttributeReplacementsResponse(...args, response))
    return response
  }

export const exportProductAttributeReplacements =
  (websiteId, feedId, channel, filters, columns) => async (dispatch) => {
    var status = 202
    var statusCode = {
      200: () => {
        status = 200
      },
      202: () => {
        status = 202
      }
    }

    var filterPayload = buildFilterPayload(filters)
    const url = `/ad_champion/websites/${websiteId}/replacements/products/export?feed_id=${feedId}&channel=${channel}&filters=${encodeURIComponent(
      filterPayload
    )}&columns=${encodeURIComponent(JSON.stringify(columns))}`

    var response = await $.ajax({ method: 'GET', url, statusCode })

    while (status === 202) {
      await sleep(1000)
      response = await $.ajax({ method: 'GET', url, statusCode })
    }
    return camelCaseKeys(response, { deep: true })
  }

const updateProductAttributeOptimisationSuggestionsRequest = (
  websiteId,
  channel,
  feedId,
  filters,
  columns,
  applySuggestions,
  sources
) => ({
  type: UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_REQUEST,
  websiteId,
  channel,
  feedId,
  filters,
  columns,
  applySuggestions,
  sources
})

const updateProductAttributeOptimisationSuggestionsResponse = (
  websiteId,
  channel,
  feedId,
  filters,
  columns,
  applySuggestions,
  sources,
  response
) => ({
  type: UPDATE_PRODUCT_ATTRIBUTE_OPTIMISATION_SUGGESTIONS_RESPONSE,
  websiteId,
  channel,
  feedId,
  filters,
  columns,
  applySuggestions,
  sources,
  response
})

const postUpdateProductAttributeOptimisationSuggestions = async (data) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const { websiteId, filters, columns } = data

  var response
  while (status === 202) {
    response = await $.ajax({
      url: `/ad_champion/websites/${websiteId}/replacements/products/update_optimisation_suggestions`,
      data: snakeCaseKeys({
        ...data,
        filters: buildFilterPayload(filters),
        columns: JSON.stringify(columns)
      }),
      method: 'POST',
      statusCode
    })
    if (status === 202) {
      await sleep(500)
    }
  }
  return response
}

export const updateProductAttributeOptimisationSuggestions =
  (websiteId, channel, feedId, filters, columns, applySuggestions, sources) =>
  async (dispatch) => {
    var data = {
      websiteId,
      channel,
      feedId,
      filters,
      columns,
      applySuggestions,
      sources
    }
    dispatch(
      updateProductAttributeOptimisationSuggestionsRequest(
        ...Object.values(data)
      )
    )

    var response = await postUpdateProductAttributeOptimisationSuggestions(data)

    dispatch(
      updateProductAttributeOptimisationSuggestionsResponse(
        ...Object.values(data),
        response
      )
    )
    return response
  }

export const updateProductAttributeReplacements =
  (websiteId, feedId, payload) => (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_ATTRIBUTE_REPLACEMENTS,
      websiteId,
      feedId,
      payload
    })
  }

const saveProductAttributeReplacementsRequest = (
  websiteId,
  feedId,
  channel,
  filters,
  replacements,
  generate,
  saveToAllChannels,
  saveOptimisationSuggestions
) => ({
  type: SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_REQUEST,
  websiteId,
  feedId,
  channel,
  filters,
  replacements,
  generate,
  saveToAllChannels,
  saveOptimisationSuggestions
})

const saveProductAttributeReplacementsResponse = (
  websiteId,
  feedId,
  channel,
  filters,
  replacements,
  generate,
  saveToAllChannels,
  saveOptimisationSuggestions,
  response
) => ({
  type: SAVE_PRODUCT_ATTRIBUTE_REPLACEMENTS_RESPONSE,
  websiteId,
  feedId,
  channel,
  generate,
  saveToAllChannels,
  saveOptimisationSuggestions,
  filters,
  replacements,
  response
})

const postProductReplacements = async (
  websiteId,
  feedId,
  channel,
  filters,
  replacements,
  generate,
  saveToAllChannels,
  saveOptimisationSuggestions
) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  var data = snakeCaseKeys({
    channel,
    feedId,
    filters: buildFilterPayload(filters),
    replacements: JSON.stringify(snakeCaseKeys(replacements)),
    generate,
    saveToAllChannels,
    saveOptimisationSuggestions
  })

  var response
  while (status === 202) {
    response = await $.ajax({
      url: `/ad_champion/websites/${websiteId}/replacements/products`,
      data,
      statusCode,
      method: 'POST'
    })
    if (status === 202) {
      await sleep(500)
    }
  }
  return camelCaseKeys(response)
}

export const saveProductAttributeReplacements =
  (
    websiteId,
    feedId,
    channel,
    filters,
    replacements,
    generate = false,
    saveToAllChannels = false,
    saveOptimisationSuggestions = false
  ) =>
  async (dispatch) => {
    var args = [
      websiteId,
      feedId,
      channel,
      filters,
      replacements,
      generate,
      saveToAllChannels,
      saveOptimisationSuggestions
    ]

    dispatch(saveProductAttributeReplacementsRequest(...args))

    var response = await postProductReplacements(...args)

    dispatch(saveProductAttributeReplacementsResponse(...args, response))
    return response
  }

export const updateDisableInventoryItems =
  (websiteId, payload) => (dispatch) => {
    dispatch({
      type: UPDATE_DISABLED_INVENTORY_ITEMS,
      websiteId,
      payload
    })
  }

export const clearUpdatedItemsAndReplacements = (websiteId) => (dispatch) => {
  dispatch({
    type: CLEAR_UPDATED_ITEMS_AND_REPLACEMENTS,
    websiteId
  })
}

export const updateRowSelectionStatus =
  (isProductRowSelected) => (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_ROW_SELECTION_STATUS,
      isProductRowSelected
    })
  }

export const updateAttributeFilters = (websiteId, filters) => (dispatch) => {
  dispatch({
    type: UPDATE_ATTRIBUTE_FILTERS,
    websiteId,
    filters
  })
}

export const updateSelectedSuggestionColumns =
  (websiteId, selectedColumns) => (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_SUGGESTION_COLUMNS,
      websiteId,
      selectedColumns
    })
  }

const saveFeedSettingsRequest = (
  websiteId,
  channel,
  sourceChannel,
  copy,
  allowRichTextDescription
) => ({
  type: SAVE_FEED_SETTINGS_REQUEST,
  websiteId,
  channel,
  sourceChannel,
  copy,
  allowRichTextDescription
})

const saveFeedSettingsResponse = (
  websiteId,
  channel,
  sourceChannel,
  copy,
  allowRichTextDescription,
  response
) => ({
  type: SAVE_FEED_SETTINGS_RESPONSE,
  websiteId,
  channel,
  sourceChannel,
  copy,
  response
})

export const saveFeedSettings =
  (websiteId, channel, sourceChannel, copy, allowRichTextDescription) =>
  async (dispatch) => {
    dispatch(
      saveFeedSettingsRequest(
        websiteId,
        channel,
        sourceChannel,
        copy,
        allowRichTextDescription
      )
    )

    var response = await $.post({
      url: `/ad_champion/websites/${websiteId}/replacements/products/settings`,
      data: snakeCaseKeys({
        channel,
        sourceChannel,
        copy,
        allowRichTextDescription
      })
    })

    response = camelCaseKeys(response, { deep: true })
    dispatch(
      saveFeedSettingsResponse(
        websiteId,
        channel,
        sourceChannel,
        copy,
        allowRichTextDescription,
        response
      )
    )
    return response
  }

export const productAttributeReplacementsQueryChangedStatus =
  (websiteId, queryChangedStatus) => (dispatch) => {
    dispatch({
      type: PRODUCT_ATTRIBUTE_REPLACEMENTS_QUERY_CHANGED_STATUS,
      websiteId,
      queryChangedStatus
    })
  }
