import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { isSubmitting } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { Button } from 'reactstrap'
import PreviewRulesModal from './preview_rules_modal'

import './preview_rules.scss'

export class PreviewRules extends Component {
  static propTypes = {
    isSubmitting: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  renderModal() {
    const { isOpen } = this.state

    return (
      <PreviewRulesModal
        isOpen={isOpen}
        onModalStateChange={() => this.setState({ isOpen: !isOpen })}
      />
    )
  }

  render() {
    const { isSubmitting } = this.props

    return (
      <div className='preview-rule-btn'>
        <Button
          tag='span'
          color='custom'
          disabled={isSubmitting}
          onClick={() => this.setState({ isOpen: true })}
        >
          <i className='far fa-file-search' /> Preview
        </Button>
        {this.renderModal()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  isSubmitting: isSubmitting(REDUX_FORM_NAME)(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewRules)
)
