import { createSelector } from 'reselect'

export const appSelector = (state) => state.app

const matchSelector = (state, props) => props.match || {}

const paramsSelector = createSelector(
  [matchSelector],
  (match) => match.params || {}
)

export const appIdSelector = createSelector(
  [paramsSelector],
  (params) => params.appId || ''
)

export const BIG_COMMERCE_APP_ID = 'big_commerce'
const EMBEDDED_APPS = [BIG_COMMERCE_APP_ID, 'shopify_embedded', 'neto']
export const isEmbeddedApp = (appId) => EMBEDDED_APPS.includes(appId)

export const isEmbeddedAppSelector = createSelector([appIdSelector], (appId) =>
  isEmbeddedApp(appId)
)

export const isBigCommerceAppSelector = createSelector(
  [appIdSelector],
  (appId) => appId === BIG_COMMERCE_APP_ID
)

export const isFeedAppSelector = createSelector(
  [appIdSelector, isEmbeddedAppSelector],
  (appId, isEmbedded) => appId === 'feed_ops' || isEmbedded
)

export const isActionsPageSelector = (_state, props) => {
  const pathNames = [
    'options',
    'new',
    'initial_reconciling',
    'website_check',
    'feed_settings',
    'connect_',
    'ads_configurator',
    'add_user'
  ]
  return props.location && props.location.pathname
    ? pathNames.some((element) => props.location.pathname.includes(element))
    : false
}

export const showSideNavSelector = createSelector(
  [isActionsPageSelector],
  (isActionsPage) => !isActionsPage
)

export const bookAMeetingModalOpenSelector = createSelector(
  [appSelector],
  (app) => app.bookAMeetingModalOpen
)

export const bookAMeetingJsonDataSelector = createSelector(
  [appSelector],
  (app) => app.bookAMeetingJsonData
)

export const bookAMeetingMeetingTypeSelector = createSelector(
  [appSelector],
  (app) => app.bookAMeetingMeetingType
)

export const channelFilterModalOpenSelector = createSelector(
  [appSelector],
  (app) => app.channelFilterModalOpen
)

export const channelSettingsModalOpenSelector = createSelector(
  [appSelector],
  (app) => app.channelSettingsModalOpen
)

export const workEmailModalOpenSelector = createSelector(
  [appSelector],
  (app) => app.workEmailModalOpen
)
