import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_PLAYBOOK_PROGRESS_REQUEST = 'FETCH_PLAYBOOK_PROGRESS_REQUEST'
export const FETCH_PLAYBOOK_PROGRESS_RESPONSE =
  'FETCH_PLAYBOOK_PROGRESS_RESPONSE'
export const DISMISS_PLAYBOOK_PROGRESS_REQUEST =
  'DISMISS_PLAYBOOK_PROGRESS_REQUEST'
export const DISMISS_PLAYBOOK_PROGRESS_RESPONSE =
  'DISMISS_PLAYBOOK_PROGRESS_RESPONSE'
export const SET_PLAYBOOK_DISMISS_SWITCH = 'SET_PLAYBOOK_DISMISS_SWITCH'

const fetchPlaybookProgressRequest = (websiteId) => ({
  type: FETCH_PLAYBOOK_PROGRESS_REQUEST,
  websiteId
})

const fetchPlaybookProgressResponse = (websiteId, response) => ({
  type: FETCH_PLAYBOOK_PROGRESS_RESPONSE,
  websiteId,
  ...response
})

export const fetchPlaybookProgress = (websiteId) => async (dispatch) => {
  dispatch(fetchPlaybookProgressRequest(websiteId))

  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }
  const url = `/ad_champion/playbook/playbook_progress?website_id=${websiteId}`
  var response = await $.ajax({ method: 'GET', url, statusCode })
  var camelResponse = null

  while (status === 202) {
    camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(fetchPlaybookProgressResponse(websiteId, camelResponse))
    await sleep(1000)
    response = await $.ajax({ method: 'GET', url, statusCode })
  }

  camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(fetchPlaybookProgressResponse(websiteId, camelResponse))

  return camelResponse.progressDetails
}

const dismissPlaybookProgressRequest = (websiteId, websitePlaybookItemId) => ({
  type: DISMISS_PLAYBOOK_PROGRESS_REQUEST,
  websiteId,
  websitePlaybookItemId
})

const dismissPlaybookProgressResponse = (
  websiteId,
  websitePlaybookItemId,
  response
) => ({
  type: DISMISS_PLAYBOOK_PROGRESS_RESPONSE,
  websiteId,
  websitePlaybookItemId,
  response
})

export const dismissPlaybookProgress =
  (websiteId, websitePlaybookItemId) => async (dispatch) => {
    dispatch(dismissPlaybookProgressRequest(websiteId, websitePlaybookItemId))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/playbook/playbook_progress/dismiss_website_playbook_item`,
      data: snakeCaseKeys({ websiteId, websitePlaybookItemId }, { deep: true }),
      dataType: 'json'
    })

    dispatch(
      dismissPlaybookProgressResponse(
        websiteId,
        websitePlaybookItemId,
        response
      )
    )
    return response
  }

export const setDismissSwitch = (show) => ({
  type: SET_PLAYBOOK_DISMISS_SWITCH,
  show
})
