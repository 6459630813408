import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import AppliedFiltersSection from 'components/feed_ops/common/popover/applied_filters_section'
import { MDBBtn } from 'mdbreact'
import { Popover } from '@material-ui/core'
import PopoverSection from 'components/feed_ops/website/product_optimisations/attribute_filters_section/popover_section'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'

import './popover_container.scss'

export class PopoverContainer extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    computeRulesChange: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      anchorEl: null,
      popoverOpen: false
    }
  }

  handleRemoveFilterAtIndex(updatedRules) {
    const { computeRulesChange } = this.props
    computeRulesChange(updatedRules)
  }

  handleApplyFilterClick(updatedRules) {
    const { computeRulesChange } = this.props
    computeRulesChange(updatedRules)
  }

  renderAppliedConditions() {
    const { actionName, availableAttributes, appliedFilters } = this.props

    return (
      <AppliedFiltersSection
        forFeature={actionName}
        availableAttributes={availableAttributes}
        filteredAccounts={[]}
        appliedFilters={appliedFilters}
        handleRemoveFilterAtIndex={this.handleRemoveFilterAtIndex.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    )
  }

  handleAddBtnClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
      popoverOpen: true
    })
  }

  renderAddBtn() {
    const { actionName } = this.props

    return (
      <MDBBtn
        tag='span'
        className='add-btn'
        onClick={this.handleAddBtnClick.bind(this)}
      >
        {`Add ${actionName}`}
      </MDBBtn>
    )
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  handleUpdateRuleConditions(updatedRules) {
    const { computeRulesChange } = this.props
    computeRulesChange(updatedRules)
    this.handleClosePopover()
  }

  renderPopoverSection() {
    const { actionName, appliedFilters } = this.props

    return (
      <PopoverSection
        sectionFor={actionName}
        appliedFilters={appliedFilters}
        handleClosePopover={this.handleClosePopover.bind(this)}
        updateAttributeFilters={this.handleUpdateRuleConditions.bind(this)}
      />
    )
  }

  renderPopover() {
    const { anchorEl, popoverOpen } = this.state

    return (
      <Popover
        anchorEl={anchorEl}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className='attribute-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        {this.renderPopoverSection()}
      </Popover>
    )
  }

  render() {
    return (
      <div className='popover-container'>
        {this.renderAddBtn()}
        {this.renderAppliedConditions()}
        {this.renderPopover()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  availableAttributes: websiteAllAttributesSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopoverContainer)
)
