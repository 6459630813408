import { SET_GOOGLE_USER } from 'actions/google/user'

const initialState = {
  email: '',
  scope: '',
  accessToken: ''
}

export default (state = initialState, action) => {
  switch (action.type) {

  case SET_GOOGLE_USER:    
    return { ...state, ...action.user }

  default:
    return state
  }
}
