import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isMobileOS } from 'util/operating_systems'
import _ from 'lodash'
import { MDBNavbarNav } from 'mdbreact'
import ChannelSelectorDropdown from 'components/feed_ops/header/channel_selector_dropdown'
import InventorySelectorDropdown from 'components/feed_ops/header/inventory_selector_dropdown'
import FeedSelectorDropdown from 'components/feed_ops/header/feed_selector_dropdown'
import { appIdSelector } from 'selectors/app'
import {
  superUserSelector,
  accountTypeIdSelector,
  accountTypeSelector
} from 'selectors/user'
import { channelPresentSelector, channelSelector } from 'selectors/channel'
import { websiteDetailSelector } from 'selectors/websites'
import { initialReconciledStatusSelector } from 'selectors/inventory'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class LeftTertiaryHeader extends Component {
  static propTypes = {
    isSuperUser: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    appId: PropTypes.string.isRequired,
    channelPresent: PropTypes.bool.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    accountType: PropTypes.string.isRequired,
    websiteDetail: PropTypes.object.isRequired,
    menuItem: PropTypes.object.isRequired,
    isMobileOS: PropTypes.bool.isRequired,
    inventoryReconcileStatus: PropTypes.bool.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  conditionallyRenderLeftTertiaryHeader() {
    const { isMobileOS, channelPresent, menuItem } = this.props
    const shouldRender = menuItem.tertiaryHeader.channelCheck
      ? channelPresent
      : true

    return !isMobileOS && shouldRender
  }

  computeIsDropdownDisabled() {
    const { accountType, websiteDetail, inventoryReconcileStatus } = this.props

    return (
      accountType === 'budget_categories' ||
      _.isEmpty(websiteDetail) ||
      !inventoryReconcileStatus
    )
  }

  renderChannelDropdown(disabled, showAllOption) {
    return (
      <ChannelSelectorDropdown
        float='left'
        disabled={disabled}
        showAllOption={showAllOption}
      />
    )
  }

  conditionallyRenderChannelSelector() {
    const { location } = this.props
    const { pathname } = location

    const isPlaybookPage = pathname.includes('playbook')
    const disabled = this.computeIsDropdownDisabled()

    return this.renderChannelDropdown(
      isPlaybookPage ? false : disabled,
      isPlaybookPage
    )
  }

  conditionallyRenderInventorySelector() {
    return (
      <InventorySelectorDropdown
        float='left'
        disabled={this.computeIsDropdownDisabled()}
      />
    )
  }

  conditionallyRenderFeedSelector() {
    return (
      <FeedSelectorDropdown
        float='left'
        disabled={this.computeIsDropdownDisabled()}
      />
    )
  }

  render() {
    const { menuItem } = this.props
    const { tertiaryHeader } = menuItem
    const { showChannelSelection, showInventorySelector, showFeedSelector } =
      tertiaryHeader || {}

    return this.conditionallyRenderLeftTertiaryHeader() ? (
      <MDBNavbarNav left>
        {showChannelSelection && this.conditionallyRenderChannelSelector()}
        {showInventorySelector && this.conditionallyRenderInventorySelector()}
        {showFeedSelector && this.conditionallyRenderFeedSelector()}
      </MDBNavbarNav>
    ) : null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    isSuperUser: superUserSelector(state, props),
    id: accountTypeIdSelector(state, props),
    appId: appIdSelector(state, props),
    channelPresent: channelPresentSelector(state, props),
    channel: channelSelector(state, props),
    accountType: accountTypeSelector(state, props),
    websiteDetail: websiteDetailSelector(state, props),
    inventoryReconcileStatus:
      initialReconciledStatusSelector(state, props) || false
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftTertiaryHeader)
)
