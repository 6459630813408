import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBBox, MDBInput } from 'mdbreact'

export class TreeItemLabel extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    displayCheckbox: PropTypes.bool.isRequired
  }

  computeLabelClass() {
    const { category } = this.props
    return category.hasError
      ? 'MuiTreeItem-labelHasError'
      : 'MuiTreeItem-labelNoError'
  }

  render() {
    const { category, displayCheckbox } = this.props
    const { excluded } = category

    return (
      <div className={this.computeLabelClass()}>
        {displayCheckbox ? (
          <MDBBox className='MuiTreeItem-Checkbox'>
            <MDBInput checked={!excluded} type='checkbox'>
              {category.label}
            </MDBInput>
          </MDBBox>
        ) : (
          <MDBBox className='MuiTreeItem-Name'> {category.label}</MDBBox>
        )}
      </div>
    )
  }
}
