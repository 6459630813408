import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import changeCase from 'change-case'
import {
  fetchInventoryAttributes,
  productAttributeReplacementsQueryChangedStatus
} from 'actions/product_feeds/replacements'
import { showFeedOpsFeedbackModal } from 'actions/feed_ops/feedbacks'
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardFooter,
  Button
} from 'reactstrap'
import ApplyDismissSuggestions from 'components/feed_ops/website/product_optimisations/optimiser/table_actions/apply_dismiss_suggestions'
import { websiteIdSelector } from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { isProductRowSelectedSelector } from 'selectors/product_feeds/replacements'
import {
  websiteProductReplacementsMetaDataSelector,
  websiteProductReplacementsSelectedColumnsSelector,
  suggestionBatchIdsSelector
} from 'selectors/product_feeds/replacements'
import { getLogoByChannel } from 'util/supported_channels'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

import './optimisation_type_card.scss'

export class OptimisationTypeCard extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    isProductRowSelected: PropTypes.bool.isRequired,
    suggestionsMetaData: PropTypes.object.isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    batchIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fetchInventoryAttributes: PropTypes.func.isRequired,
    productAttributeReplacementsQueryChangedStatus: PropTypes.func.isRequired,
    showFeedOpsFeedbackModal: PropTypes.func.isRequired
  }

  renderCardTitle() {
    const { channel } = this.props

    return (
      <CardTitle className='channel-title'>
        <img src={getLogoByChannel(channel)} alt='Channel Logo' />
        <h3>Product types to Level 5</h3>
      </CardTitle>
    )
  }

  renderCardBody() {
    const { suggestionsMetaData } = this.props
    const { totalItems } = suggestionsMetaData

    return (
      <CardBody>
        <CardText className='description'>
          {changeCase.sentenceCase(totalItems || 0)} products optimized. Approve
          all or Reject all selected columns and rows, or use filters to reduce
          the list.
        </CardText>
      </CardBody>
    )
  }

  async handleOnSaveProductAttributesReplacements(btnText) {
    const {
      fetchInventoryAttributes,
      websiteId,
      productAttributeReplacementsQueryChangedStatus
    } = this.props
    await fetchInventoryAttributes(websiteId)
    productAttributeReplacementsQueryChangedStatus(websiteId, true)

    if (btnText === 'Reject All') {
      const { showFeedOpsFeedbackModal, batchIds } = this.props
      const latestBatchId = _.max(batchIds)
      showFeedOpsFeedbackModal('AI Optimisations', latestBatchId)
    }
  }

  renderBtn(btnText, isDisabled) {
    return (
      <ApplyDismissSuggestions
        allRowsSelected={true}
        applySuggestions={true}
        suggestionBtnText={btnText}
        selectedRows={[]}
        attributes={[]}
        suggestionSource='open_ai'
        disableOnRowsSelected={isDisabled}
        onSaveProductAttributesReplacements={this.handleOnSaveProductAttributesReplacements.bind(
          this,
          btnText
        )}
      />
    )
  }

  renderCardFooter() {
    const { isProductRowSelected, selectedColumns } = this.props
    const isDisabled = isProductRowSelected || selectedColumns.length === 0

    return (
      <CardFooter>
        <Button disabled={isDisabled} color='primary' className='primary-btn'>
          {this.renderBtn('Approve All', isDisabled)}
        </Button>
        <Button disabled={isDisabled} className='secondary-btn'>
          {this.renderBtn('Reject All', isDisabled)}
        </Button>
      </CardFooter>
    )
  }

  render() {
    return (
      <Card className='optimisation-card'>
        {this.renderCardTitle()}
        {this.renderCardBody()}
        {this.renderCardFooter()}
      </Card>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  channel: channelSelector(state, props),
  isProductRowSelected: isProductRowSelectedSelector(state, props),
  suggestionsMetaData: websiteProductReplacementsMetaDataSelector(state, props),
  selectedColumns: websiteProductReplacementsSelectedColumnsSelector(
    state,
    props
  ),
  batchIds: suggestionBatchIdsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryAttributes,
      productAttributeReplacementsQueryChangedStatus,
      showFeedOpsFeedbackModal
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OptimisationTypeCard)
)
