import {
  FETCH_FEED_DETAIL_RESPONSE,
  FETCH_FEED_DETAILS_BY_ID_RESPONSE,
  GENERATE_FEED_REQUEST,
  GENERATE_FEED_RESPONSE,
  CREATE_FEED_RESPONSE
} from 'actions/feeds'
const initialState = {
  details: {},
  response: {}
}

const feedDetailReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FEED_DETAIL_RESPONSE:
      return {
        ...state,
        details: {
          ...state.details,
          [action.feedDetail.id]: action.feedDetail
        },
        id: null
      }
    case FETCH_FEED_DETAILS_BY_ID_RESPONSE:
      return {
        ...state,
        details: action.feeds.reduce(
          (obj, feed) => ({ ...obj, [feed.id]: feed }),
          state.details
        )
      }
    default:
      return state
  }
}

const feedsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_FEED_REQUEST:
      return {
        ...state,
        response: {}
      }
    case GENERATE_FEED_RESPONSE:
      return {
        ...state,
        response: { ...action.response, requestedAt: new Date() }
      }
    case CREATE_FEED_RESPONSE:
      return {
        ...state,
        id: action.response.feedId
      }
    default:
      return feedDetailReducer(state, action)
  }
}

export default feedsReducer
