import { SEARCH, SHOPPING, SMART_SHOPPING, BRAND_SEARCH } from 'util/build_type'
import { createSelector } from 'reselect'

const buildScheme = {
  [SEARCH]: '',
  [SHOPPING]: 'Retail::Shopping::V201709',
  [SMART_SHOPPING]: 'Retail::SmartShopping::V202104',
  [BRAND_SEARCH]: 'Retail::Search::V202107Brand',
  smart_shopping: 'Retail::SmartShopping::V202104'
}

const scheme = (_state, type) => buildScheme[type]

export const schemeSelector = createSelector(scheme, (scheme) => {
  return scheme
})
