import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { schemeSelector } from 'selectors/build_initilalizer'

import {
  FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  FETCH_ADVERTISING_BUDGETS_REQUEST,
  FETCH_ADVERTISING_BUDGETS_SUCCESS,
  FETCH_ADVERTISING_GOALS_REQUEST,
  FETCH_ADVERTISING_GOALS_SUCCESS,
  UPDATE_ADVERTISING_GOAL_TYPE_REQUEST,
  UPDATE_ADVERTISING_GOAL_TYPE_SUCCESS,
  UPDATE_ADVERTISING_BUDGETS_REQUEST,
  UPDATE_ADVERTISING_BUDGETS_SUCCESS,
  UPDATE_ADVERTISING_GOALS_REQUEST,
  UPDATE_ADVERTISING_GOALS_SUCCESS,
  CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS
} from 'actions/advertising'

export const FETCH_ADVERTISING_PLAN_REQUEST = 'FETCH_ADVERTISING_PLAN_REQUEST'
export const FETCH_ADVERTISING_PLAN_SUCCESS = 'FETCH_ADVERTISING_PLAN_SUCCESS'
export const FETCH_HAS_BUDGET_CATEGORIES_REQUEST =
  'FETCH_HAS_BUDGET_CATEGORIES_REQUEST'
export const FETCH_HAS_BUDGET_CATEGORIES_SUCCESS =
  'FETCH_HAS_BUDGET_CATEGORIES_SUCCESS'
export const CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_REQUEST =
  'CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_REQUEST'
export const CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_RESPONSE =
  'CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_RESPONSE'
export const FETCH_WEBSITE_BUDGET_REQUEST = 'FETCH_WEBSITE_BUDGET_REQUEST'
export const FETCH_WEBSITE_BUDGET_RESPONSE = 'FETCH_WEBSITE_BUDGET_RESPONSE'
export const FETCH_WEBSITE_CAMPAIGN_IDS_REQUEST =
  'FETCH_WEBSITE_CAMPAIGN_IDS_REQUEST'
export const FETCH_WEBSITE_CAMPAIGN_IDS_RESPONSE =
  'FETCH_WEBSITE_CAMPAIGN_IDS_RESPONSE'

const fetchWebsiteAdvertisingBudgetsRequest = (
  accountType,
  websiteId,
  channel
) => ({
  type: FETCH_ADVERTISING_BUDGETS_REQUEST,
  accountType,
  id: websiteId,
  channel
})

const fetchWebsiteAdvertisingGoalsRequest = (websiteId, goalType, channel) => ({
  type: FETCH_ADVERTISING_GOALS_REQUEST,
  websiteId,
  goalType,
  channel
})

const fetchWebsiteAdvertisingBudgetsSuccess = (
  accountType,
  websiteId,
  channel,
  advertisingBudgets
) => ({
  type: FETCH_ADVERTISING_BUDGETS_SUCCESS,
  accountType,
  id: websiteId,
  channel,
  ...advertisingBudgets
})

const fetchWebsiteAdvertisingGoalsSuccess = (
  websiteId,
  goalType,
  channel,
  advertisingGoals
) => ({
  type: FETCH_ADVERTISING_GOALS_SUCCESS,
  accountType: 'websites',
  id: websiteId,
  goalType,
  channel,
  ...advertisingGoals
})

export const fetchWebsiteAdvertisingBudgets =
  (accountType, websiteId, channel) => async (dispatch) => {
    dispatch(
      fetchWebsiteAdvertisingBudgetsRequest(accountType, websiteId, channel)
    )
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/load_budgets`,
      data: snakeCaseKeys({ channel }, { deep: true }),
      dataType: 'json'
    })

    dispatch(
      fetchWebsiteAdvertisingBudgetsSuccess(
        accountType,
        websiteId,
        channel,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

export const fetchWebsiteAdvertisingGoals =
  (websiteId, goalType, channel) => async (dispatch) => {
    dispatch(fetchWebsiteAdvertisingGoalsRequest(websiteId, goalType, channel))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/load_goals`,
      data: snakeCaseKeys({ goalType, channel }, { deep: true }),
      dataType: 'json'
    })

    dispatch(
      fetchWebsiteAdvertisingGoalsSuccess(
        websiteId,
        goalType,
        channel,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const fetchWebsiteAdvertisingPlanRequest = (websiteId) => ({
  type: FETCH_ADVERTISING_PLAN_REQUEST,
  websiteId
})

const fetchWebsiteAdvertisingPlanSuccess = (websiteId, advertisingPlan) => ({
  type: FETCH_ADVERTISING_PLAN_SUCCESS,
  websiteId,
  ...advertisingPlan
})

export const fetchWebsiteAdvertisingPlan = (websiteId) => async (dispatch) => {
  dispatch(fetchWebsiteAdvertisingPlanRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/advertising/load_advertising_plan`,
    dataType: 'json'
  })

  dispatch(
    fetchWebsiteAdvertisingPlanSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const updateWebsiteAdvertisingBudgetsRequest = (
  websiteId,
  budgets,
  channel
) => ({
  type: UPDATE_ADVERTISING_BUDGETS_REQUEST,
  id: websiteId,
  budgets,
  channel
})

const updateWebsiteAdvertisingBudgetsResponse = (
  websiteId,
  budgets,
  channel,
  response
) => ({
  type: UPDATE_ADVERTISING_BUDGETS_SUCCESS,
  id: websiteId,
  budgets,
  channel,
  response
})

export const updateWebsiteAdvertisingBudgets =
  (websiteId, budgets, channel) => (dispatch) => {
    dispatch(
      updateWebsiteAdvertisingBudgetsRequest(websiteId, budgets, channel)
    )
    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/advertising/update_budgets`,
      data: snakeCaseKeys(
        { advertisingBudget: budgets, channel },
        { deep: true }
      ),
      dataType: 'json',
      success: (response) => {
        var camelCaseResponse = camelCaseKeys(response, { deep: true })
        dispatch(
          updateWebsiteAdvertisingBudgetsResponse(
            websiteId,
            budgets,
            channel,
            camelCaseResponse
          )
        )
        return camelCaseResponse
      }
    })
  }

const updateWebsiteAdvertisingGoalsRequest = (
  websiteId,
  goalType,
  goals,
  channel
) => ({
  type: UPDATE_ADVERTISING_GOALS_REQUEST,
  id: websiteId,
  goalType,
  goals,
  channel
})

const updateWebsiteAdvertisingGoalsResponse = (
  websiteId,
  goalType,
  goals,
  channel,
  response
) => ({
  type: UPDATE_ADVERTISING_GOALS_SUCCESS,
  id: websiteId,
  goalType,
  goals,
  channel,
  response
})

export const updateWebsiteAdvertisingGoals =
  (websiteId, goalType, goals, channel) => (dispatch) => {
    dispatch(
      updateWebsiteAdvertisingGoalsRequest(websiteId, goalType, goals, channel)
    )
    return $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/advertising/update_goals`,
      data: snakeCaseKeys({ goalType, goals, channel }, { deep: true }),
      dataType: 'json',
      success: (response) => {
        var camelCaseResponse = camelCaseKeys(response, { deep: true })
        dispatch(
          updateWebsiteAdvertisingGoalsResponse(
            websiteId,
            goalType,
            goals,
            channel,
            camelCaseResponse
          )
        )
        return camelCaseResponse
      }
    })
  }

const fetchWebsiteAdvertisingGoalTypeRequest = (
  accountType,
  websiteId,
  channel
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  accountType,
  id: websiteId,
  channel
})

const fetchWebsiteAdvertisingGoalTypeResponse = (
  accountType,
  websiteId,
  channel,
  advertisingGoalType
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  accountType,
  id: websiteId,
  channel,
  advertisingGoalType
})

export const fetchWebsiteAdvertisingGoalType =
  (accountType, websiteId, channel) => async (dispatch) => {
    dispatch(
      fetchWebsiteAdvertisingGoalTypeRequest(accountType, websiteId, channel)
    )
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/load_goal_type`,
      data: snakeCaseKeys({ channel }, { deep: true }),
      dataType: 'json'
    })
    dispatch(
      fetchWebsiteAdvertisingGoalTypeResponse(
        accountType,
        websiteId,
        channel,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const updateWebsiteAdvertisingGoalTypeRequest = (
  websiteId,
  goalType,
  channel
) => ({
  type: UPDATE_ADVERTISING_GOAL_TYPE_REQUEST,
  id: websiteId,
  goalType,
  channel
})

const updateWebsiteAdvertisingGoalTypeSuccess = (
  websiteId,
  goalType,
  channel,
  response
) => ({
  type: UPDATE_ADVERTISING_GOAL_TYPE_SUCCESS,
  id: websiteId,
  goalType,
  channel,
  response
})

export const updateWebsiteAdvertisingGoalType =
  (websiteId, goalType, channel) => async (dispatch) => {
    dispatch(
      updateWebsiteAdvertisingGoalTypeRequest(websiteId, goalType, channel)
    )
    var response = await $.ajax({
      method: 'PUT',
      url: `/ad_champion/websites/${websiteId}/advertising/update_goal_type`,
      data: snakeCaseKeys({ goalType, channel }, { deep: true }),
      dataType: 'json'
    })
    dispatch(
      updateWebsiteAdvertisingGoalTypeSuccess(
        websiteId,
        goalType,
        channel,
        response
      )
    )
  }

const fetchHasBudgetCategoriesRequest = (websiteId) => ({
  type: FETCH_HAS_BUDGET_CATEGORIES_REQUEST,
  websiteId
})

const fetchHasBudgetCategoriesSuccess = (websiteId, response) => ({
  type: FETCH_HAS_BUDGET_CATEGORIES_SUCCESS,
  websiteId,
  ...response
})

export const fetchHasBudgetCategories = (websiteId) => async (dispatch) => {
  dispatch(fetchHasBudgetCategoriesRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/advertising/has_budget_categories`,
    dataType: 'json'
  })
  dispatch(
    fetchHasBudgetCategoriesSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const checkWebsitePublicationAccountTypesRequest = (
  accountType,
  websiteId
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  accountType,
  id: websiteId
})

const checkWebsitePublicationAccountTypesSuccess = (
  accountType,
  websiteId,
  publicationAccountTypes
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS,
  accountType,
  id: websiteId,
  ...publicationAccountTypes
})

export const checkWebsitePublicationAccountTypes =
  (accountType, websiteId) => async (dispatch) => {
    dispatch(checkWebsitePublicationAccountTypesRequest(accountType, websiteId))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/check_publication_account_types`,
      dataType: 'json'
    })

    dispatch(
      checkWebsitePublicationAccountTypesSuccess(
        accountType,
        websiteId,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const createWebsiteDefaultSharedBudgetRequest = (websiteId) => ({
  type: CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_REQUEST,
  websiteId
})

const createWebsiteDefaultSharedBudgetResponse = (websiteId, response) => ({
  type: CREATE_WEBSITE_DEFAULT_SHARED_BUDGET_RESPONSE,
  websiteId,
  response
})

export const createWebsiteDefaultSharedBudget =
  (websiteId) => async (dispatch) => {
    dispatch(createWebsiteDefaultSharedBudgetRequest(websiteId))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/advertising/create_default_shared_budget`,
      dataType: 'json'
    })

    dispatch(createWebsiteDefaultSharedBudgetResponse(websiteId, response))
    return response
  }

const fetchWebsiteBudgetRequest = (websiteId, buildType) => ({
  type: FETCH_WEBSITE_BUDGET_REQUEST,
  websiteId,
  buildType
})

const fetchWebsiteBudgetResponse = (websiteId, buildType, response) => ({
  type: FETCH_WEBSITE_BUDGET_RESPONSE,
  websiteId,
  buildType,
  response
})

export const fetchWebsiteBudget =
  (websiteId, buildType, getSchemeId = schemeSelector) =>
  async (dispatch) => {
    const schemeId = getSchemeId({}, buildType)
    dispatch(fetchWebsiteBudgetRequest(websiteId, buildType))

    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/load_website_budget?scheme_id=${schemeId}`,
      dataType: 'json'
    })

    dispatch(
      fetchWebsiteBudgetResponse(
        websiteId,
        buildType,
        camelCaseKeys(response, { deep: true })
      )
    )
  }

const fetchWebsiteCampaignIdsRequest = (websiteId, buildType) => ({
  type: FETCH_WEBSITE_CAMPAIGN_IDS_REQUEST,
  websiteId,
  buildType
})

const fetchWebsiteCampaignIdsResponse = (websiteId, buildType, response) => ({
  type: FETCH_WEBSITE_CAMPAIGN_IDS_RESPONSE,
  websiteId,
  buildType,
  response
})

export const fetchWebsiteCampaignIds =
  (websiteId, buildType, getSchemeId = schemeSelector) =>
  async (dispatch) => {
    const schemeId = getSchemeId({}, buildType)
    dispatch(fetchWebsiteCampaignIdsRequest(websiteId, buildType))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/advertising/load_campaign_ids?scheme_id=${schemeId}`,
      dataType: 'json'
    })

    dispatch(
      fetchWebsiteCampaignIdsResponse(
        websiteId,
        buildType,
        camelCaseKeys(response, { deep: true })
      )
    )
  }
