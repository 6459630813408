export const SET_STEP = 'SET_STEP'
export const NEXT_STEP = 'NEXT_STEP'
export const TOGGLE_ADVANCED = 'TOGGLE_ADVANCED'
export const SET_ADVANCED = 'SET_ADVANCED'

export const setStep = stepNum => ({
  type: SET_STEP,
  stepNum
})

export const nextStep = () => ({
  type: NEXT_STEP
})

export const toggleAdvanced = () => ({
  type: TOGGLE_ADVANCED
})

export const setAdvanced = showAdvanced => ({
  type: SET_ADVANCED,
  showAdvanced
})