import { FETCH_RULE_ACTION_TYPES_RESPONSE } from 'actions/inventories/rules/action_types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RULE_ACTION_TYPES_RESPONSE:
      const actionTypes = action.actionTypes.reduce(
        (index, at) => ({ ...index, [at.id]: at }),
        {}
      )
      return {
        ...state,
        ...actionTypes
      }

    default:
      return state
  }
}
