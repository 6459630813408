import { createSelector } from 'reselect'
const reducerSelector = (state) => state.adwordsAccount

export const adwordsAccountIdSelector = (state) =>
  createSelector([reducerSelector], (reducer) => reducer.adwordsAccountId || '')

export const adwordsAccountsSelector = createSelector(
  [reducerSelector],
  (reducer) => reducer.adwordsAccounts || []
)

export const adwordsAccountsSelectOptionsSelector = createSelector(
  [adwordsAccountsSelector],
  (accounts) =>
    accounts.map((a) => ({
      text: a.name ? `${a.name} (${a.id})` : `${a.id}`,
      value: a.id
    }))
)
