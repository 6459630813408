import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBCard, MDBCardBody, MDBRow, MDBAlert } from 'mdbreact'
import { websiteFeedDetailSelector } from 'selectors/websites'
import { planCanGenerateFeedsSelector } from 'selectors/billing'
import { getLogoByChannel } from 'util/supported_channels'
import ProductLevelOptimiser from 'components/feed_ops/website/product_optimisations/optimiser'
import ProductLevelOptimiserBtn from './form_btn'

export class ProductLevelOptimiserForm extends Component {
  static propTypes = {
    feedDetail: PropTypes.object.isRequired,
    canGenerateFeed: PropTypes.bool.isRequired
  }

  renderChannelLogo(channel) {
    return <img key={channel} src={getLogoByChannel(channel)} alt={channel} />
  }

  isEditable() {
    const { channel, sourceChannel } = this.props.feedDetail
    return channel === sourceChannel
  }

  renderLinkedChannels() {
    const { linkedChannels } = this.props.feedDetail
    if (linkedChannels && linkedChannels.length > 0) {
      return (
        <MDBAlert color='warning' className='linked-channels-warning'>
          Making changes to this feed will also impact these linked feeds
          {linkedChannels.map(this.renderChannelLogo)}
        </MDBAlert>
      )
    }

    if (!this.isEditable()) {
      return (
        <MDBAlert color='warning' className='source-channel-warning'>
          <strong>View only:</strong> This feed is using optimizations from{' '}
          another feed. You can change this in settings.
        </MDBAlert>
      )
    }

    return null
  }

  renderContactSales() {
    return (
      <div className='footer-container'>
        <div className='footer-message'>
          To publish changes please contact
          <a href='mailto:sales@feedops.com'>sales@feedops.com</a>
        </div>
      </div>
    )
  }

  render() {
    const { canGenerateFeed } = this.props

    return (
      <MDBCard className='product-level-optimiser'>
        <MDBCardBody>
          {this.renderLinkedChannels()}
          <ProductLevelOptimiser />
          <MDBRow className='action-btn-row'>
            {canGenerateFeed ? (
              <ProductLevelOptimiserBtn isCancel={false} />
            ) : (
              this.renderContactSales()
            )}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => ({
  feedDetail: websiteFeedDetailSelector(state, props),
  canGenerateFeed: planCanGenerateFeedsSelector(state, props) || false
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductLevelOptimiserForm)
)
