import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBSelect } from 'mdbreact'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, registerField } from 'redux-form'
import { countries } from 'country-data'
import { remoteFormSubmissionHandlers } from 'actions/forms'

export class CountryOfSaleSelector extends Component {
  componentDidMount() {
    const { registerField, initialize } = this.props
    registerField(REDUX_FORM_NAME, 'country', 'Field')
    initialize({ country: undefined })
  }

  countryOptions() {
    var options = countries.all.map((c) => ({ text: c.name, value: c.alpha2 }))
    options.sort((a, b) => a.text.localeCompare(b.text))
    return options
  }

  handleChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      change('country', e[0])
    }
  }

  render() {
    const { formValues } = this.props
    const { country } = formValues
    const countryOptions = this.countryOptions()
    var selectedCountryOption =
      countryOptions.find((option) => option.value === country) || {}
    return (
      <div className={'country-of-sale'}>
        <MDBSelect
          search
          options={countryOptions}
          className={'country-of-sale-selector'}
          selected={
            selectedCountryOption.text || 'Select initial country of sale'
          }
          getValue={this.handleChange.bind(this)}
        />
        <p>
          A country of sale is the country where the products included in your
          feed are sold and will be shipped to. You can change your country of
          sale or advertise to additional countries at any time by changing your
          settings in Merchant Center.
        </p>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ registerField }, dispatch)

export const CountryOfSaleSelectorWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryOfSaleSelector)
export const REDUX_FORM_NAME = 'CountryOfSaleSelector'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(CountryOfSaleSelectorWithRedux)
  )
)
