import { SubmissionError } from 'redux-form'
import { updateInventoryDetail, saveNewFeed } from 'actions/inventories'

const handleSubmit = (values, dispatch, props) => {
  const { inventory } = props
  if (inventory && inventory.id) {
    return dispatch(updateInventoryDetail(inventory.id, values)).then(
      () => {},
      error => {
        throw new SubmissionError(error.responseJSON.errors)
      }
    )
  }

  return dispatch(saveNewFeed(null, {...values, websiteId: values.websiteId || props.websiteId }))
}

export default handleSubmit