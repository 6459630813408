import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, isDirty } from 'redux-form'
import toastr from 'toastr'
import { REDUX_FORM_NAME } from 'components/feed_ops/website/rules_engine/form'
import { remoteSubmitAndWait } from 'actions/forms'
import { exportRulesPreview } from 'actions/inventories/rules'
import { ModalFooter, Col, Button } from 'reactstrap'
import { websiteFeedPerChannelSelector } from 'selectors/websites'

import './modal_footer_container.scss'
const EXPORT_BTN_ACTION = 'Export Google Sheet'
const APPLY_BTN_ACTION = 'Apply'

export class ModalFooterContainer extends Component {
  static propTypes = {
    channel: PropTypes.string.isRequired,
    feedId: PropTypes.number.isRequired,
    rules: PropTypes.arrayOf(PropTypes.object).isRequired,
    isDirty: PropTypes.bool.isRequired,
    onModalStateChange: PropTypes.func.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    exportRulesPreview: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  constructor(props) {
    super(props)
    this.state = { isSaving: false, isExporting: false }
  }

  async handleExportClick() {
    const { toastr, exportRulesPreview, feedId, rules } = this.props
    toastr.options.timeOut = 0
    toastr.options.extendedTimeout = 0
    toastr.info(
      'Preparing your export. This will open in a new tab once it is ready.'
    )

    try {
      this.setState({ isExporting: true })
      var response = await exportRulesPreview(feedId, rules)
      this.setState({ isExporting: false })
      toastr.clear()

      var url = `https://docs.google.com/spreadsheets/d/${response.spreadsheetId}/edit`
      toastr.success(
        `Export completed. Click <a href='${url}' target='_blank'>here</a> to open.`
      )

      toastr.options = {}
      window.open(url, '_blank')
    } catch (ex) {
      toastr.error(
        'An error occurred exporting your product data. Please contact support for assistance.'
      )
    }
  }

  async handleActionBtnClick(actionName) {
    if (actionName === EXPORT_BTN_ACTION) {
      this.handleExportClick()
    } else {
      const { remoteSubmitAndWait, onModalStateChange } = this.props

      this.setState({ isSaving: true })
      await remoteSubmitAndWait(REDUX_FORM_NAME)
      this.setState({ isSaving: false })
      onModalStateChange()
    }
  }

  renderActionButton(actionName, btnId, outline, color, disabled) {
    return (
      <Button
        key={actionName}
        id={btnId}
        tag='span'
        color={color}
        outline={outline}
        disabled={disabled}
        onClick={this.handleActionBtnClick.bind(this, actionName)}
      >
        {actionName}
      </Button>
    )
  }

  renderFooterActions() {
    const { isDirty } = this.props
    const { isSaving, isExporting } = this.state

    return (
      <>
        {this.renderActionButton(
          APPLY_BTN_ACTION,
          'apply-and-continue',
          false,
          'primary',
          !isDirty || isSaving
        )}
        {this.renderActionButton(
          EXPORT_BTN_ACTION,
          'export-rules-preview',
          true,
          'dark',
          isExporting
        )}
      </>
    )
  }

  renderReferenceDots() {
    return (
      <>
        <span className='feed-rules-dot' />
        <span>Feed Rules</span>
        <span className='manual-edit-dot' />
        <span>Manual Edit</span>
      </>
    )
  }

  render() {
    return (
      <ModalFooter className='preview-modal-footer-container'>
        <Col className='reference-dots-col'>{this.renderReferenceDots()}</Col>
        <Col>{this.renderFooterActions()}</Col>
      </ModalFooter>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(REDUX_FORM_NAME)(state) || {}
  const feedsPerChannel = websiteFeedPerChannelSelector(state, props) || {}
  const feeds = feedsPerChannel[props.channel] || []
  const feed = feeds[0] || {}
  return {
    rules: formValues.rules || [],
    feedId: feed.id,
    isDirty: isDirty(REDUX_FORM_NAME)(state) || false
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ remoteSubmitAndWait, exportRulesPreview }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalFooterContainer)
)
