import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBListGroup, MDBListGroupItem, MDBInput } from 'mdbreact'

export default class AttributeStaticValues extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    handleStaticValueChange: PropTypes.func.isRequired
  }

  handleTextChange(event) {
    const { handleStaticValueChange } = this.props
    handleStaticValueChange(event.target.value)
  }

  render() {
    const { value } = this.props

    return (
      <MDBListGroup>
        <MDBListGroupItem key='value'>
          <MDBInput
            label='Value'
            value={value}
            onChange={this.handleTextChange.bind(this)}
          />
        </MDBListGroupItem>
      </MDBListGroup>
    )
  }
}
