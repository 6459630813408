import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import changeCase from 'change-case'
import _ from 'lodash'
import { MDBInput } from 'mdbreact'
import TitleDescriptionBuilder from 'components/feed_ops/website/product_optimisations/common/title_description_builder'
import EnumListItem from 'components/feed_ops/website/product_optimisations/attribute_filters_section/popover_section/enum_list_item'
import AttributeDatePicker from 'components/feed_ops/website/product_optimisations/attribute_filters_section/popover_section/attribute_date_picker'
import GoogleProductCategoryDropdown from 'components/feed_ops/website/product_optimisations/attribute_filters_section/popover_section/google_product_category_dropdown'
import { TITLE, DESCRIPTION } from 'util/product_attributes'
import { templateToFieldArray, fieldArrayToTemplate } from 'util/format_string'
import {
  NUMERIC_FORMATS,
  DATE_OPERATORS,
  GOOGLE_PRODUCT_CATEGORY_OPERATORS
} from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'
import { websiteIdSelector } from 'selectors/websites'
import { websiteProductTitlesAllAvailableAttributesSelector } from 'selectors/product_feeds/titles'

import './filter_values.scss'

export class ActionFilterPopoverValues extends Component {
  static propTypes = {
    selectedAttribute: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    showValidationErrors: PropTypes.bool.isRequired,
    allAvailableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    valueChanged: PropTypes.func.isRequired
  }

  computeValueType() {
    const { selectedAttribute } = this.props
    const { name, enumValues, filterOptions } = selectedAttribute
    const attributeName = changeCase.lowerCase(name)
    var valueType = 'text'

    if (attributeName === TITLE || attributeName === DESCRIPTION) {
      valueType = 'templated'
    } else if (!_.isEmpty(enumValues)) {
      valueType = 'enum'
    } else if (
      DATE_OPERATORS.every((operator) => filterOptions.includes(operator))
    ) {
      valueType = 'date'
    } else if (_.isEqual(filterOptions, GOOGLE_PRODUCT_CATEGORY_OPERATORS)) {
      valueType = 'googleProductCategory'
    }

    return valueType
  }

  generateDefaultParts() {
    const { selectedAttribute, allAvailableFields } = this.props
    const attributeName = changeCase.lowerCase(selectedAttribute.name)
    const fieldValue =
      attributeName === DESCRIPTION ? '{Description}' : '{Title}'

    return templateToFieldArray(`${fieldValue}`, allAvailableFields)
  }

  generateTemplateFields() {
    const { value, allAvailableFields } = this.props
    const defaultParts = this.generateDefaultParts()
    var templateFields = []

    if (value !== '') {
      templateFields = templateToFieldArray(value || '', allAvailableFields)
    }

    if (templateFields.length <= 0) {
      templateFields = [...defaultParts]
    }

    return templateFields
  }

  handleTemplateFieldsChanged(newParts) {
    const { valueChanged, allAvailableFields } = this.props
    valueChanged(fieldArrayToTemplate(newParts, allAvailableFields))
  }

  conditionallyRenderTemplatedValue() {
    const defaultParts = this.generateDefaultParts()
    const templateFields = this.generateTemplateFields()

    return (
      <TitleDescriptionBuilder
        defaultFields={defaultParts}
        templateFields={templateFields}
        onTemplateFieldsChanged={this.handleTemplateFieldsChanged.bind(this)}
      />
    )
  }

  handleEnumOrDateValueChanged(value) {
    const { valueChanged } = this.props
    valueChanged(value)
  }

  conditionallyRenderEnumValue(selectedAttribute, value) {
    return (
      <EnumListItem
        attribute={selectedAttribute}
        filterValues={value}
        singleSelectionOnly={true}
        enumValueChanged={this.handleEnumOrDateValueChanged.bind(this)}
      />
    )
  }

  conditionallyRenderDateValue(value) {
    return (
      <AttributeDatePicker
        filterValue={value}
        dateValueChanged={this.handleEnumOrDateValueChanged.bind(this)}
      />
    )
  }

  conditionallyRenderGoogleProductCategoryValue(value) {
    return (
      <GoogleProductCategoryDropdown
        filterValue={value || ''}
        singleSelectionOnly={true}
        valueChanged={this.handleEnumOrDateValueChanged.bind(this)}
      />
    )
  }

  handleFilterValuesChange(event) {
    const { valueChanged } = this.props
    valueChanged(event.target.value)
  }

  renderInputOrText(isNumeric, value) {
    return (
      <MDBInput
        placeholder='Value'
        type={isNumeric ? 'number' : 'text'}
        value={value}
        onChange={this.handleFilterValuesChange.bind(this)}
      />
    )
  }

  filterValuesEmpty(value) {
    return _.isEmpty(value.replace(/^\s+|\s+$/g, ''))
  }

  renderErrorText(value) {
    const { showValidationErrors } = this.props
    if (!showValidationErrors) {
      return null
    }

    const isEmpty = this.filterValuesEmpty(value)

    return isEmpty ? (
      <span className={isEmpty ? 'error-text' : ''}>
        Value cannot be empty.
      </span>
    ) : null
  }

  conditionallyRenderInput(isNumeric, value) {
    return (
      <>
        {this.renderInputOrText(isNumeric, value)}
        <br />
        {this.renderErrorText(value)}
      </>
    )
  }

  computeFilterValueComponent() {
    const valueType = this.computeValueType()
    const { selectedAttribute, value } = this.props

    switch (valueType) {
      case 'templated':
        return this.conditionallyRenderTemplatedValue()
      case 'enum':
        return this.conditionallyRenderEnumValue(selectedAttribute, value)
      case 'date':
        return this.conditionallyRenderDateValue(value)
      case 'googleProductCategory':
        return this.conditionallyRenderGoogleProductCategoryValue()
      default:
        return this.conditionallyRenderInput(
          NUMERIC_FORMATS.includes(selectedAttribute.format),
          value
        )
    }
  }

  render() {
    return (
      <div className='action-filter-values'>
        {this.computeFilterValueComponent()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    allAvailableFields:
      websiteProductTitlesAllAvailableAttributesSelector(websiteId)(state)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionFilterPopoverValues)
)
