import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { updateAccountOverviewFilters } from 'actions/users/accounts'
import {
  DEFAULT_AVAILABLE_ATTRIBUTES,
  ACCOUNT_NAME_ATTRIBUTE,
  FILTER_ATTRIBUTES
} from '../attributes_util'
import { addNewFilters } from 'components/feed_ops/common/popover/popover_util'
import { MDBListGroup, MDBListGroupItem } from 'mdbreact'
import AccountFilterPopoverContainer from './account_filter_popover_container'
import { currentUserAccountsOverviewFiltersSelector } from 'selectors/user'

export class PopoverSection extends Component {
  static propTypes = {
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClosePopover: PropTypes.func.isRequired,
    updateAccountOverviewFilters: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      availableAttributes: [],
      selectedAttribute: null
    }
  }

  updateAvailableAttributes() {
    const { filteredAccounts, appliedFilters } = this.props
    const appliedFiltersAttributes =
      appliedFilters.map((filter) => filter.attributeName) || []
    var availableAttributes = [...DEFAULT_AVAILABLE_ATTRIBUTES]

    if (filteredAccounts.length > 1) {
      availableAttributes = [ACCOUNT_NAME_ATTRIBUTE, ...availableAttributes]
    }

    this.setState({
      availableAttributes: availableAttributes.filter(
        (name) => !appliedFiltersAttributes.includes(name)
      )
    })
  }

  componentDidMount() {
    this.updateAvailableAttributes()
  }

  handleAttributeItemClick(attribute) {
    this.setState({ selectedAttribute: FILTER_ATTRIBUTES[attribute] })
  }

  renderAvailableFilterAttributes() {
    const { availableAttributes } = this.state
    const attributeItems = availableAttributes.map((attribute) => (
      <MDBListGroupItem
        key={attribute}
        hover
        onClick={this.handleAttributeItemClick.bind(this, attribute)}
      >
        {attribute}
      </MDBListGroupItem>
    ))

    return <MDBListGroup>{attributeItems}</MDBListGroup>
  }

  handleClosePopover() {
    const { handleClosePopover } = this.props
    handleClosePopover()
  }

  handleApplyFilterClick(attributeName, selectedFilterOption, filterValues) {
    const { updateAccountOverviewFilters, appliedFilters } = this.props
    updateAccountOverviewFilters(
      addNewFilters(
        appliedFilters,
        attributeName,
        selectedFilterOption,
        filterValues
      )
    )
    this.handleClosePopover()
  }

  render() {
    const { selectedAttribute } = this.state
    const selectedAttributeEmpty = _.isEmpty(selectedAttribute)
    const { filteredAccounts } = this.props

    return selectedAttributeEmpty ? (
      this.renderAvailableFilterAttributes()
    ) : (
      <AccountFilterPopoverContainer
        filteredAccounts={filteredAccounts}
        selectedAttribute={selectedAttribute}
        handleClosePopover={this.handleClosePopover.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  appliedFilters: currentUserAccountsOverviewFiltersSelector(state, props) || []
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateAccountOverviewFilters }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopoverSection)
)
