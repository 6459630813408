import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { MDBCol } from 'mdbreact'
import PlatformSignUpCommon from './common'
import FeedopsFeatures from 'components/feed_ops/common/feedops_features'
import { isEmbeddedAppSelector } from 'selectors/app'
import { loggedInSelector } from 'selectors/user'

export class PlatformSignUpDesktop extends Component {
  static propTypes = {
    isEmbeddedApp: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    onComplete: PropTypes.func
  }

  handleRedirect() {
    const { onComplete } = this.props
    if (onComplete) {
      onComplete()
    }
  }

  renderCard() {
    return <PlatformSignUpCommon onComplete={this.handleRedirect.bind(this)} />
  }

  render() {
    const { isEmbeddedApp, loggedIn } = this.props

    if (isEmbeddedApp) {
      return this.renderCard()
    }

    return (
      <>
        <MDBCol md='12' lg='6' className={loggedIn ? '' : 'center-content-col'}>
          {this.renderCard()}
        </MDBCol>
        <MDBCol
          md='12'
          lg='6'
          className={`features-container ${
            loggedIn && 'hide-features-container'
          }`}
        >
          <FeedopsFeatures />
        </MDBCol>
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  loggedIn: loggedInSelector(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlatformSignUpDesktop)
)
