import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm, isDirty } from 'redux-form'
import {
  remoteFormSubmissionHandlers,
  remoteSubmitAndWait
} from 'actions/forms'
import { fetchVendors } from 'actions/inventories/vendors'
import { fetchVerticalFields } from 'actions/vertical/fields'
import { fetchValueOperators } from 'actions/value/operators'
import { fetchRuleActionTypes } from 'actions/inventories/rules/action_types'
import { fetchInventoryAttributes } from 'actions/product_feeds/replacements'
import { fetchGoogleTaxonomies } from 'actions/google/taxonomies'
import { fetchRules, updateAllRules } from 'actions/inventories/rules'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import UnsavedChangesModal from 'components/feed_ops/common/unsaved_changes_modal'
import { MDBCard, MDBCardBody } from 'mdbreact'
import RulesEngineOverview from './overview'
import toastr from 'toastr'
import {
  websiteIdSelector,
  websiteFeedPerChannelSelector
} from 'selectors/websites'
import { websiteRulesSelector } from 'selectors/inventories/rules'

import './form.scss'

export class RulesEngineForm extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedPerChannel: PropTypes.object.isRequired,
    websiteRules: PropTypes.object.isRequired,
    isDirty: PropTypes.bool,
    fetchVendors: PropTypes.func.isRequired,
    fetchVerticalFields: PropTypes.func.isRequired,
    fetchValueOperators: PropTypes.func.isRequired,
    fetchRuleActionTypes: PropTypes.func.isRequired,
    fetchInventoryAttributes: PropTypes.func.isRequired,
    fetchGoogleTaxonomies: PropTypes.func.isRequired,
    fetchRules: PropTypes.func.isRequired,
    updateAllRules: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = { loading: false }
  }

  computeFeedId() {
    const { feedPerChannel } = this.props
    const feedsPerChannel = Object.values(feedPerChannel)
    for (var index = 0; index < feedsPerChannel.length; index++) {
      const feed = feedsPerChannel[index][0]
      if (feed) {
        return feed.id
      }
    }
  }

  initializeForm() {
    const { websiteRules, initialize } = this.props
    const { rules } = websiteRules
    initialize({
      rules: rules.map((rule) => ({ ...rule, draftStatus: '' })) || []
    })
  }

  async componentDidMount() {
    this.setState({ loading: true })

    const {
      websiteId,
      fetchVendors,
      fetchVerticalFields,
      fetchValueOperators,
      fetchRuleActionTypes,
      fetchInventoryAttributes,
      fetchGoogleTaxonomies,
      fetchRules
    } = this.props
    await Promise.all([
      fetchVendors(),
      fetchVerticalFields(),
      fetchValueOperators(),
      fetchRuleActionTypes(),
      fetchInventoryAttributes(websiteId),
      fetchGoogleTaxonomies(this.computeFeedId()),
      fetchRules(websiteId)
    ])

    this.initializeForm()
    this.setState({ loading: false })
  }

  componentDidUpdate(prevProps) {
    const { websiteRules } = this.props
    const { rules } = websiteRules

    if (rules !== prevProps.websiteRules.rules) {
      this.initializeForm()
    }
  }

  renderUnsavedChangesModal() {
    const { isDirty, remoteSubmitAndWait } = this.props

    return (
      <UnsavedChangesModal
        enabled={isDirty}
        headerText='Unsaved Draft Feed Rules'
        descriptionText='Please apply or discard the changes before leaving this page.'
        btnNavigateAwayText='Discard'
        btnStayText='Apply'
        onStayActionClick={() => remoteSubmitAndWait(REDUX_FORM_NAME)}
      />
    )
  }

  render() {
    const { loading } = this.state

    return loading ? (
      <AdChampionIndeterminateProgressIndicator />
    ) : (
      <>
        {this.renderUnsavedChangesModal()}
        <MDBCard className='rules-engine-form'>
          <MDBCardBody>
            <RulesEngineOverview />
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedPerChannel: websiteFeedPerChannelSelector(state, props),
  websiteRules: websiteRulesSelector(state, props) || {},
  isDirty: isDirty(REDUX_FORM_NAME)(state) || false
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVendors,
      fetchVerticalFields,
      fetchValueOperators,
      fetchRuleActionTypes,
      fetchInventoryAttributes,
      fetchGoogleTaxonomies,
      fetchRules,
      updateAllRules,
      remoteSubmitAndWait
    },
    dispatch
  )

const RulesEngineFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesEngineForm)

export const REDUX_FORM_NAME = 'RulesEngineForm'

export const handleSubmit = async (values, dispatch, props) => {
  const { rules } = values
  const { websiteId, updateAllRules, fetchRules } = props

  try {
    const filteredRules = rules.filter((rule) => rule.draftStatus !== 'Deleted')
    await updateAllRules(websiteId, filteredRules)
    await fetchRules(websiteId)

    toastr.success(
      'Feed Rules saved successfully. It may take some time to reflect the changes.'
    )
  } catch (error) {
    toastr.error('Failed to save draft feed rules. Please contact support.')
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(RulesEngineFormWithRedux))
  )
)
