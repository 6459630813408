import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  APPLIED_FILTERS_ACTION,
  APPLY_FILTERS_ACTION,
  ADD_WEBSITE_ACTION
} from '../overview_util'
import {
  DEFAULT_AVAILABLE_ATTRIBUTES,
  ACCOUNT_NAME_ATTRIBUTE
} from './attributes_util'
import AccountAppliedFiltersSection from './account_applied_filters_section'
import ApplyFiltersSection from './apply_filters_section'
import AddNewWebsite from './add_new_website'
import { currentUserAccountsOverviewFiltersSelector } from 'selectors/user'

export class TableActions extends Component {
  static propTypes = {
    actionName: PropTypes.string.isRequired,
    filteredAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  renderAppliedFilters() {
    const { filteredAccounts } = this.props
    return <AccountAppliedFiltersSection filteredAccounts={filteredAccounts} />
  }

  checkIsSingleWebsite() {
    const { filteredAccounts } = this.props
    const accountWebsites = filteredAccounts
      .map((account) => account.websites)
      .flat()

    return accountWebsites && accountWebsites.length > 0
      ? accountWebsites.length === 1
      : true
  }

  checkAllFiltersApplied() {
    const { filteredAccounts, appliedFilters } = this.props
    const appliedFiltersAttributes =
      appliedFilters.map((filter) => filter.attributeName) || []
    var availableAttributes = [...DEFAULT_AVAILABLE_ATTRIBUTES]

    if (filteredAccounts.length > 1) {
      availableAttributes = [ACCOUNT_NAME_ATTRIBUTE, ...availableAttributes]
    }

    return _.isEqual(
      _.sortBy(availableAttributes),
      _.sortBy(appliedFiltersAttributes)
    )
  }

  conditionallyRenderApplyFilters() {
    const { filteredAccounts } = this.props
    const isSingleWebsite = this.checkIsSingleWebsite()
    const allFiltersApplied = this.checkAllFiltersApplied()

    return isSingleWebsite || allFiltersApplied ? null : (
      <ApplyFiltersSection filteredAccounts={filteredAccounts} />
    )
  }

  render() {
    const { actionName } = this.props
    var actionComponent = null

    switch (actionName) {
      case APPLIED_FILTERS_ACTION:
        actionComponent = this.renderAppliedFilters()
        break
      case APPLY_FILTERS_ACTION:
        actionComponent = this.conditionallyRenderApplyFilters()
        break
      case ADD_WEBSITE_ACTION:
        actionComponent = <AddNewWebsite />
        break
      default:
        break
    }

    return actionComponent
  }
}

export const mapStateToProps = (state, props) => ({
  appliedFilters: currentUserAccountsOverviewFiltersSelector(state, props) || []
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableActions)
)
