import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBIcon,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'

import './source_import_dropdown.scss'

export const FILE_UPLOAD_IMPORT_SOURCE = 'File'
export const FILE_URL_IMPORT_SOURCE = 'URL'
export const GOOGLE_SHEET_IMPORT_SOURCE = 'Google Sheet'
export const GOOGLE_MC_CONTENT_API_IMPORT_SOURCE = 'Google Content API'
export const MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE = 'Microsoft Content API'
export const IMPORT_SOURCES = [
  FILE_UPLOAD_IMPORT_SOURCE,
  FILE_URL_IMPORT_SOURCE,
  GOOGLE_SHEET_IMPORT_SOURCE,
  GOOGLE_MC_CONTENT_API_IMPORT_SOURCE,
  MICROSOFT_MC_CONTENT_API_IMPORT_SOURCE
]

export class SourceImportDropdown extends Component {
  static propTypes = {
    onInputSourceChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedSource: 'Select your import source'
    }
  }

  handleSourceChange(source) {
    const { onInputSourceChange } = this.props
    onInputSourceChange(source)
    this.setState({ selectedSource: source })
  }

  renderDropdownToggle() {
    const { selectedSource } = this.state

    return (
      <MDBDropdownToggle tag='div' className='source-import-dropdown-toggle'>
        {selectedSource}
        <MDBIcon icon='caret-down' />
      </MDBDropdownToggle>
    )
  }

  renderDropdownMenu() {
    return (
      <MDBDropdownMenu>
        {IMPORT_SOURCES.map((source, index) => (
          <MDBDropdownItem
            key={index}
            tag='span'
            onClick={this.handleSourceChange.bind(this, source)}
          >
            {source}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  renderDropdown() {
    const { isOpen } = this.state

    return (
      <MDBDropdown
        tag='span'
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: !isOpen })
        }}
      >
        {this.renderDropdownToggle()}
        {this.renderDropdownMenu()}
      </MDBDropdown>
    )
  }

  render() {
    return (
      <div className='source-import-dropdown-container'>
        <span>Import from: </span>
        {this.renderDropdown()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SourceImportDropdown)
)
