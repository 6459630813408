import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBIcon } from 'mdbreact'
import DataTable from 'react-data-table-component'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import {
  fetchWebsiteMerchantCenterErrorCategories,
  fetchMerchantCenterErrorCategories
} from 'actions/merchant_center_product_errors'
import {
  websiteErrorCategoryDetailsSelector,
  websiteErrorCategoryIdsSelector
} from 'selectors/google/merchant_center_product_errors'
import { websiteIdSelector } from 'selectors/websites'
import CategoryDetail from './category_detail'

export class CategoryList extends Component {
  static propTypes = {
    categoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteId: PropTypes.number.isRequired,
    fetchWebsiteMerchantCenterErrorCategories: PropTypes.func.isRequired,
    fetchMerchantCenterErrorCategories: PropTypes.func.isRequired,
    scope: PropTypes.oneOf(['account', 'product']).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedCategory: props.categories[0],
      loading: true
    }
  }

  async componentDidMount() {
    await this.fetchWebsiteMerchantCenterErrorCategories()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId, scope, refreshAt } = this.props
    if (
      prevProps.websiteId !== websiteId ||
      prevProps.scope !== scope ||
      prevProps.refreshAt !== refreshAt
    ) {
      await this.fetchWebsiteMerchantCenterErrorCategories()
    }

    if (!_.isEqual(prevProps.categories, this.props.categories)) {
      this.setState({ selectedCategory: this.props.categories[0] })
    }
  }

  async fetchWebsiteMerchantCenterErrorCategories() {
    const {
      fetchWebsiteMerchantCenterErrorCategories,
      websiteId,
      scope
    } = this.props
    if (!websiteId) {
      return
    }

    this.setState({ loading: true })
    await fetchWebsiteMerchantCenterErrorCategories(websiteId, scope)

    const { fetchMerchantCenterErrorCategories, categoryIds } = this.props
    await fetchMerchantCenterErrorCategories(categoryIds)
    this.setState({ loading: false })
  }

  getColumns() {
    return [
      {
        name: '',
        selector: 'level',
        format: (row) => (
          <MDBIcon icon='exclamation-triangle' className={row.level} />
        )
      },
      {
        name: 'Error Message',
        selector: 'title',
        grow: 7,
        format: (row) =>
          row.title === 'Account Uncategorised' ||
          row.title === 'Product Uncategorised'
            ? 'Other'
            : row.title
      }
    ]
  }

  selectCategory(selectedCategory) {
    this.setState({ selectedCategory })
  }

  conditionalRowStyles() {
    const { selectedCategory } = this.state
    return [
      {
        when: (row) => selectedCategory === row,
        style: {
          backgroundColor: 'rgba(0,0,0,.08)'
        }
      }
    ]
  }

  render() {
    const { categories, refreshAt, scope } = this.props
    const { selectedCategory, loading } = this.state
    return (
      <div className={loading ? 'loading' : ''}>
        <DataTable
          className={'feed-errors'}
          columns={this.getColumns()}
          data={categories}
          pagination={true}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onRowClicked={(row) => this.selectCategory(row)}
          noHeader
          noTableHead
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={this.conditionalRowStyles()}
        />
        {selectedCategory && (
          <CategoryDetail
            category={selectedCategory}
            refreshAt={refreshAt}
            scope={scope}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  const { scope } = props
  const refreshAt = (state.google.merchantCenterProductErrors || {}).refreshAt
  return {
    websiteId,
    refreshAt,
    categoryIds: websiteErrorCategoryIdsSelector(websiteId, scope)(
      state,
      props
    ),
    categories: websiteErrorCategoryDetailsSelector(websiteId, scope)(
      state,
      props
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteMerchantCenterErrorCategories,
      fetchMerchantCenterErrorCategories
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryList)
)
