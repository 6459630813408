import { FETCH_INVENTORY_PROCESSING_STATUS_RESPONSE } from 'actions/inventories'

export const inventoryProcessingStatusReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_PROCESSING_STATUS_RESPONSE:
      return {
        ...state,
        processingStatus: {
          ...state.processingStatus,
          [action.inventoryId]: {
            message: action.processingStatus,
            value: action.processingValue
          }
        }
      }

    default:
      return state
  }
}
