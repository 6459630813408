import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  uploadImportFile,
  fetchFileImportSourceHeaders
} from 'actions/product_feeds/replacements/products/file_single_import'
import { FILE_UPLOAD_IMPORT_SOURCE } from './source_import_dropdown'
import { MDBInput } from 'mdbreact'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import toastr from 'toastr'
import { websiteIdSelector } from 'selectors/websites'

import './file_import_source.scss'

export class FileImportSource extends Component {
  static propTypes = {
    sourceType: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    handleFileDataChange: PropTypes.func.isRequired,
    uploadImportFile: PropTypes.func.isRequired,
    fetchFileImportSourceHeaders: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      file: null,
      fileUrl: '',
      invalidFileUrl: false,
      invalidMessage: '',
      validatingAndFetching: false
    }
  }

  componentDidUpdate(prevProps) {
    const { sourceType } = this.props

    if (sourceType !== prevProps.sourceType) {
      this.setState({
        file: null,
        fileUrl: '',
        invalidFileUrl: false,
        invalidMessage: '',
        validatingAndFetching: false
      })
    }
  }

  setFileError(message) {
    this.setState({
      invalidFileUrl: true,
      invalidMessage: message
    })
  }

  handleFetchResponse(response) {
    if (response.error) {
      this.setFileError(response.error)
    } else if (!response.headers || response.headers.length === 0) {
      this.setFileError('No attributes found in file headers')
    } else {
      const { handleFileDataChange } = this.props
      const { fileUrl } = this.state
      const { headers, mappings } = response

      handleFileDataChange(fileUrl, headers, mappings)
    }

    this.setState({ validatingAndFetching: false })
  }

  async fetchFileUrlData() {
    const { websiteId, fetchFileImportSourceHeaders } = this.props
    const { fileUrl } = this.state

    if (!fileUrl) {
      this.setFileError('File URL cannot be empty')
    } else {
      this.setState({ invalidFileUrl: false, validatingAndFetching: true })
      toastr.info('Fetching file data, this can take some time.')

      try {
        const response = await fetchFileImportSourceHeaders(websiteId, fileUrl)
        this.handleFetchResponse(response)
      } catch (error) {
        this.handleFetchResponse(error.responseJSON)
      }
    }
  }

  async handleUploadFileResponse(response) {
    if (response.fileUrl) {
      this.setState({ fileUrl: response.fileUrl })
      await this.fetchFileUrlData()
    } else {
      this.handleFetchResponse(response)
    }
  }

  async uploadFileData(file) {
    const { handleFileDataChange, websiteId, uploadImportFile } = this.props
    this.setState({
      fileUrl: '',
      invalidFileUrl: !!!file
    })
    handleFileDataChange('', [], [])

    if (!file) {
      this.setFileError('File cannot be empty')
    } else {
      this.setState({ invalidFileUrl: false, validatingAndFetching: true })
      toastr.info('Validating file data, this can take some time.')

      try {
        const formData = new FormData()
        formData.append('file', file)
        const response = await uploadImportFile(websiteId, formData)
        await this.handleUploadFileResponse(response)
      } catch (error) {
        this.handleFetchResponse(error.responseJSON)
      }
    }
  }

  renderFileUploadInput() {
    return (
      <MDBInput
        id='fileUrl'
        accept='.csv,.txt,.xml,.gz,.zip,.bz2'
        type='file'
        onChange={(event) => {
          this.uploadFileData(event.target.files[0])
        }}
      />
    )
  }

  renderFileInput() {
    const { fileUrl } = this.state

    return (
      <MDBInput
        id='fileUrl'
        label='File URL'
        value={fileUrl}
        onChange={(event) =>
          this.setState({ fileUrl: event.target.value, file: null })
        }
        onBlur={this.fetchFileUrlData.bind(this)}
      />
    )
  }

  render() {
    const { invalidFileUrl, invalidMessage, validatingAndFetching } = this.state
    const { sourceType } = this.props

    return (
      <div className='file-input-source'>
        {sourceType === FILE_UPLOAD_IMPORT_SOURCE
          ? this.renderFileUploadInput()
          : this.renderFileInput()}
        {invalidFileUrl && (
          <div className='file-url-error'>{invalidMessage}</div>
        )}
        {validatingAndFetching && <AdChampionIndeterminateProgressIndicator />}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { uploadImportFile, fetchFileImportSourceHeaders },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileImportSource)
)
