import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

export const CREATE_VARIATIONS_SHEET_REQUEST = 'CREATE_VARIATIONS_SHEET_REQUEST'
export const CREATE_VARIATIONS_SHEET_SUCCESS = 'CREATE_VARIATIONS_SHEET_SUCCESS'
export const APPLY_VARIATIONS_SHEET_REQUEST = 'APPLY_VARIATIONS_SHEET_REQUEST'
export const APPLY_VARIATIONS_SHEET_SUCCESS = 'APPLY_VARIATIONS_SHEET_SUCCESS'

const createVariationsSheetRequest = inventoryId => ({
  type: CREATE_VARIATIONS_SHEET_REQUEST,
  inventoryId
})

const createVariationsSheetSuccess = (inventoryId, sheetId) => ({
  type: CREATE_VARIATIONS_SHEET_SUCCESS,
  inventoryId,
  sheetId
})

export const createVariationsSheet = inventoryId => async dispatch => {
  dispatch(createVariationsSheetRequest(inventoryId))
  var response = await $.ajax({
    url: `/ad_champion/inventories/${inventoryId}/variations_sheets/edit`,
    dataType: 'json'
  })
  dispatch(createVariationsSheetSuccess(inventoryId, response.spreadsheet_id))
  return camelCaseKeys(response, { deep: true })
}

const applyVariationsSheetRequest = (inventoryId, spreadsheetId) => ({
  type: APPLY_VARIATIONS_SHEET_REQUEST,
  inventoryId,
  spreadsheetId
})

const applyVariationsSheetSuccess = (inventoryId, spreadsheetId) => ({
  type: APPLY_VARIATIONS_SHEET_SUCCESS,
  inventoryId,
  spreadsheetId
})

export const applyVariationsSheet = (
  inventoryId,
  spreadsheetId
) => async dispatch => {
  dispatch(applyVariationsSheetRequest(inventoryId, spreadsheetId))

  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/inventories/${inventoryId}/variations_sheets`,
    dataType: 'json',
    data: snakeCaseKeys({ spreadsheetId })
  })

  dispatch(applyVariationsSheetSuccess(inventoryId, spreadsheetId))

  return camelCaseKeys(response, { deep: true })
}
