import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, isSubmitting, isValid } from 'redux-form'
import { isMobileOS } from 'util/operating_systems'
import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardTitle,
  MDBCardText
} from 'mdbreact'
import CircularProgress from '@material-ui/core/CircularProgress'
import changeCase from 'change-case'
import { createAccount } from 'actions/accounts'
import { trackAffiliateSignUp } from 'actions/affiliates'
import { remoteSubmitAndWait } from 'actions/forms'
import { fetchInventoryDetail } from 'actions/inventories'
import { fetchUserMasterAccounts } from 'actions/users'
import InventoryStatusPoller from 'components/feed_ops/common/inventory_status_poller'
import SignUpOptions from 'components/sign_up/sign_up_options'
import UserProfileForm, {
  REDUX_FORM_NAME as USER_PROFILE_FORM
} from 'components/user_profile_form'
import { isEmbeddedAppSelector, appIdSelector } from 'selectors/app'
import { loggedInSelector } from 'selectors/user'
import {
  inventoryItemCountSelector,
  inventoryProcessingTotalProductsSelector
} from 'selectors/inventory'
import { websiteInventoryIdSelector } from 'selectors/websites'

import './common.scss'

export class PlatformSignUpCommon extends Component {
  static propTypes = {
    isMobileOS: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    onComplete: PropTypes.func,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    formValid: PropTypes.bool.isRequired,
    formSubmitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object.isRequired,
    trackAffiliateSignUp: PropTypes.func.isRequired,
    createAccount: PropTypes.func.isRequired,
    fetchUserMasterAccounts: PropTypes.func.isRequired,
    itemCount: PropTypes.number.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  constructor(props) {
    super(props)
    this.state = { isSubmitting: false }
  }

  async handleSubmit() {
    const {
      createAccount,
      formValues,
      trackAffiliateSignUp,
      fetchUserMasterAccounts,
      onComplete,
      remoteSubmitAndWait
    } = this.props
    this.setState({ isSubmitting: true })

    await remoteSubmitAndWait(USER_PROFILE_FORM)
    await createAccount(formValues.profile.company)

    const trackingId = document.cookie.replace(
      /(?:(?:^|.*;\s*)_fprom_track\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    )
    trackAffiliateSignUp(formValues.id, trackingId)
    fetchUserMasterAccounts()

    this.setState({ isSubmitting: false })

    if (onComplete) {
      onComplete()
    }
  }

  renderCreateAccountBtn() {
    const { formValid, formSubmitting } = this.props
    const { isSubmitting } = this.state

    return (
      <div className='create-account-container'>
        <MDBBtn
          disabled={!formValid || formSubmitting || isSubmitting}
          className={'create-account-btn'}
          onClick={this.handleSubmit.bind(this)}
          color='primary'
        >
          CREATE YOUR ACCOUNT
        </MDBBtn>
        {isSubmitting && <CircularProgress />}
      </div>
    )
  }

  getAppName() {
    const { appId } = this.props
    switch (appId) {
      case 'shopify_embedded':
        return 'Shopify'
      case 'big_commerce':
        return 'BigCommerce'
      case 'neto':
        return 'Neto'
      default:
        return changeCase.titleCase(appId)
    }
  }

  renderTitle() {
    const { isEmbeddedApp, itemCount } = this.props

    return (
      <MDBCardTitle>
        {isEmbeddedApp ? (
          <>
            <div className='install-successful'>
              <i className='fa fa-check-circle' /> Your FeedOps{' '}
              {this.getAppName()} App installation is successful.
            </div>
            {itemCount > 0 && (
              <div className='install-successful-details'>
                We've detected {itemCount} SKUs in your store. They're now ready
                to sync with channels.
              </div>
            )}
          </>
        ) : (
          <>Create your account</>
        )}
      </MDBCardTitle>
    )
  }

  renderForms() {
    return (
      <>
        {this.renderTitle()}
        <MDBCardText>
          Create your FeedOps account now, add your first channel and activate
          optimization tools.
        </MDBCardText>
        <UserProfileForm />
        {this.renderCreateAccountBtn()}
      </>
    )
  }

  render() {
    const { loggedIn, isEmbeddedApp } = this.props
    return (
      <>
        <MDBCard
          className={`sign-in${isMobileOS ? '-mobile' : ''} sign-up-common`}
        >
          <MDBCardBody>
            {loggedIn ? this.renderForms() : <SignUpOptions />}
            {!isEmbeddedApp && (
              <MDBCardText className='credit-card-text'>
                No credit card required. Free for 30 days.
              </MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
        <InventoryStatusPoller />
      </>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdSelector(state, props)
  const inventoryItemCount = inventoryItemCountSelector(inventoryId)(
    state,
    props
  )
  const inventoryProcessingTotalProducts =
    inventoryProcessingTotalProductsSelector(state, props)

  var itemCount = 0
  if (inventoryItemCount > 0) {
    itemCount = inventoryItemCount
  } else if (inventoryProcessingTotalProducts > 0) {
    itemCount = inventoryProcessingTotalProducts
  }

  return {
    appId: appIdSelector(state, props),
    inventoryId,
    loggedIn: loggedInSelector(state),
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    formValid: isValid(USER_PROFILE_FORM)(state),
    formSubmitting: isSubmitting(USER_PROFILE_FORM)(state),
    formValues: getFormValues(USER_PROFILE_FORM)(state) || {},
    itemCount
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      remoteSubmitAndWait,
      trackAffiliateSignUp,
      createAccount,
      fetchUserMasterAccounts,
      fetchInventoryDetail
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlatformSignUpCommon)
)
