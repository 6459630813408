import { SET_MICROSOFT_USER } from 'actions/microsoft/user'

const initialState = {
  email: '',
  scope: '',
  accessToken: ''
}

export default (state = initialState, action) => {
  switch (action.type) {

  case SET_MICROSOFT_USER:
    return { ...state, ...action.user }

  default:
    return state
  }
}
