import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, change } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { MDBIcon } from 'mdbreact'
import RulesEngineModal from './rules_engine_modal'
import { updateDraftRuleStatus } from '../../overview_util'

export class EditRule extends Component {
  static propTypes = {
    rule: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOnRulesChange(rule) {
    const { formValues, change } = this.props
    var rules = [...formValues.rules]

    const updatedRules = updateDraftRuleStatus(
      rules,
      rule,
      rule.id ? 'Edited' : 'Added'
    )

    change(REDUX_FORM_NAME, 'rules', updatedRules)
  }

  renderModal() {
    const { isOpen } = this.state
    const { rule } = this.props

    return (
      <RulesEngineModal
        isOpen={isOpen}
        rule={rule}
        onModalStateChange={() => this.setState({ isOpen: !isOpen })}
        handleOnRulesChange={this.handleOnRulesChange.bind(this)}
      />
    )
  }

  render() {
    return (
      <div>
        <MDBIcon
          far
          icon='edit'
          onClick={() => this.setState({ isOpen: true })}
        />
        {this.renderModal()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditRule)
)
