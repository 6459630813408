import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import changeCase from 'change-case'
import RulePillContent from './rule_pill_content'
import { getActionFilterText } from '../table_actions/rules_engine_modal/rules_engine_util'
import { actionTypeSelector } from 'selectors/inventories/rules'
import { verticalFieldSelector } from 'selectors/vertical/fields'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'

import './rule_action.scss'

export class RuleAction extends Component {
  static propTypes = {
    action: PropTypes.object.isRequired,
    actionType: PropTypes.object.isRequired,
    verticalField: PropTypes.object.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  formatActionText() {
    const { action, actionType, verticalField, attributes } = this.props
    return getActionFilterText(
      verticalField.displayName || verticalField.name,
      changeCase.sentenceCase(actionType.displayName),
      [action.value],
      attributes
    )
  }

  render() {
    return (
      <div className='rule-action' title={this.formatActionText()}>
        <RulePillContent btnText={this.formatActionText()} />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const { verticalFieldId, actionTypeId } = props.action || {}
  return {
    actionType: actionTypeSelector(actionTypeId)(state),
    verticalField: verticalFieldSelector(verticalFieldId)(state),
    attributes: websiteAllAttributesSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RuleAction)
)
