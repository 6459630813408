import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_PRODUCT_URL_SETTING_REQUEST =
  'FETCH_PRODUCT_URL_SETTING_REQUEST'
export const FETCH_PRODUCT_URL_SETTING_RESPONSE =
  'FETCH_PRODUCT_URL_SETTING_RESPONSE'
export const SAVE_PRODUCT_URL_SETTING_REQUEST =
  'SAVE_PRODUCT_URL_SETTING_REQUEST'
export const SAVE_PRODUCT_URL_SETTING_RESPONSE =
  'SAVE_PRODUCT_URL_SETTING_RESPONSE'

const fetchProductUrlSettingRequest = (websiteId, feedId) => ({
  type: FETCH_PRODUCT_URL_SETTING_REQUEST,
  websiteId,
  feedId
})

const fetchProductUrlSettingResponse = (websiteId, feedId, response) => ({
  type: FETCH_PRODUCT_URL_SETTING_RESPONSE,
  websiteId,
  feedId,
  ...response
})

export const fetchProductUrlSetting =
  (websiteId, feedId = null) =>
  async (dispatch) => {
    if (!feedId) {
      return {}
    }

    dispatch(fetchProductUrlSettingRequest(websiteId, feedId))

    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/merchant_center_product_url_setting`,
      data: { feed_id: feedId },
      dataType: 'json'
    })

    var camelResponse = camelCaseKeys(response)
    camelResponse.productUrlSetting = camelCaseKeys(
      response.product_url_setting
    )

    dispatch(
      fetchProductUrlSettingResponse(
        websiteId,
        feedId,
        camelResponse.productUrlSetting
      )
    )
    return camelResponse.productUrlSetting
  }

const saveProductUrlSettingRequest = (
  websiteId,
  feedId,
  productUrlSetting
) => ({
  type: SAVE_PRODUCT_URL_SETTING_REQUEST,
  websiteId,
  feedId,
  productUrlSetting
})

const saveProductUrlSettingResponse = (websiteId, feedId, response) => ({
  type: SAVE_PRODUCT_URL_SETTING_RESPONSE,
  websiteId,
  feedId,
  response
})

export const saveProductUrlSetting =
  (websiteId, feedId, productUrlSetting) => async (dispatch) => {
    dispatch(saveProductUrlSettingRequest(websiteId, feedId, productUrlSetting))
    productUrlSetting.additionalTagging = JSON.stringify(
      productUrlSetting.additionalTagging
    )
    productUrlSetting.adsTagging = JSON.stringify(productUrlSetting.adsTagging)
    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/merchant_center_product_url_setting`,
      data: {
        feed_id: feedId,
        product_url_setting: snakeCaseKeys(productUrlSetting)
      }
    })

    dispatch(saveProductUrlSettingResponse(websiteId, feedId, response))
    return response
  }
