export const formatNumber = (data, digits = 2) => {
  return data || data === 0
    ? data.toLocaleString('en-AU', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
    : '-'
}

export const getCurrencyPrefix = (currency) => {
  switch (currency) {
    case 'AUD':
      return 'A$'
    default:
      return '$'
  }
}

export const formatCurrency = (currency, data, digits = 2) => {
  return data === 0
    ? '-'
    : `${getCurrencyPrefix(currency)}${formatNumber(data, digits)}`
}
