import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdbreact'
import { deleteWebsite } from 'actions/websites'
import { websiteIdSelector } from 'selectors/websites'
import { isEmbeddedAppSelector } from 'selectors/app'

export class FeedOpsDeleteWebsite extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    deleteWebsite: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      deleteText: ''
    }
  }

  handleChange(event) {
    this.setState({ deleteText: event.target.value })
  }

  async handleDeleteWebsiteClick() {
    const { deleteWebsite, websiteId } = this.props
    try {
      await deleteWebsite(websiteId, true)
      toastr.success('Website deleted successfully')
      this.props.history.push('/feed_ops/')
    } catch (error) {
      toastr.error(
        'Some error occurred while deleting your website. Please contact support.'
      )
    }
  }

  renderFooter() {
    const { deleteText } = this.state

    return (
      <MDBCardFooter>
        <input
          placeholder='Type DELETE'
          value={deleteText}
          onChange={this.handleChange.bind(this)}
        />
        <MDBBtn
          disabled={deleteText !== 'DELETE'}
          outline={true}
          onClick={this.handleDeleteWebsiteClick.bind(this)}
        >
          Delete Website
        </MDBBtn>
      </MDBCardFooter>
    )
  }

  render() {
    const { isEmbeddedApp } = this.props

    return !isEmbeddedApp ? (
      <MDBCard className='delete-website-card'>
        <MDBCardBody>
          <MDBCardTitle>Danger Zone</MDBCardTitle>
          <MDBCardText>
            Delete this website?
            <br />
            Once you delete a website, any optimizations you have made will be
            lost and cannot be recovered.
          </MDBCardText>
        </MDBCardBody>
        {this.renderFooter()}
      </MDBCard>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  isEmbeddedApp: isEmbeddedAppSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteWebsite }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsDeleteWebsite)
)
