import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { updateAttributeFilters } from 'actions/product_feeds/replacements'
import AppliedFiltersSection from 'components/feed_ops/common/popover/applied_filters_section'
import { websiteIdSelector } from 'selectors/websites'

export class ProductAppliedFiltersSection extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateAttributeFilters: PropTypes.func.isRequired
  }

  handleRemoveFilterAtIndex(updatedFilters) {
    const { updateAttributeFilters, websiteId } = this.props
    updateAttributeFilters(websiteId, updatedFilters)
  }

  handleApplyFilterClick(updatedAppliedFilters) {
    const { updateAttributeFilters, websiteId } = this.props
    updateAttributeFilters(websiteId, updatedAppliedFilters)
  }

  render() {
    const { availableAttributes, appliedFilters } = this.props

    return (
      <AppliedFiltersSection
        forFeature='Product'
        availableAttributes={availableAttributes}
        filteredAccounts={[]}
        appliedFilters={appliedFilters}
        handleRemoveFilterAtIndex={this.handleRemoveFilterAtIndex.bind(this)}
        handleApplyFilterClick={this.handleApplyFilterClick.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateAttributeFilters }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAppliedFiltersSection)
)
