import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, change } from 'redux-form'
import { REDUX_FORM_NAME } from './form'
import _ from 'lodash'
import MaterialTable, { MTableBodyRow } from '@material-table/core'
import ColumnContent from './overview/column_content'
import TableActions from './overview/table_actions'
import {
  COLUMNS,
  ACTION_NAMES_TO_DISABLE,
  generateTableActions,
  generateTableOptions,
  generateTableLocalization
} from './overview_util'

import './overview.scss'

export class RulesEngineOverview extends Component {
  tableRef = React.createRef()
  dragState = { ruleId: -1, position: -1, newPosition: -1 }

  static propTypes = {
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      windowHeight: 0
    }
  }

  handleWindowResize() {
    this.setState({ windowHeight: window.innerHeight })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    this.handleWindowResize()
  }

  componentDidUpdate(prevProps) {
    const { formValues } = this.props
    if (!_.isEqual(formValues, prevProps.formValues)) {
      this.tableRef.current && this.tableRef.current.onQueryChange()
    }
  }

  async fetchRulesData(query) {
    const { formValues } = this.props
    const { rules } = formValues

    const tableData = { data: rules }
    return tableData
  }

  getDisplayColumns() {
    var displayedColumnsData = []
    COLUMNS.forEach((columnData) => {
      displayedColumnsData.push({
        ...columnData,
        render: (rowData) => (
          <ColumnContent columnData={columnData} rowData={rowData} />
        )
      })
    })

    return displayedColumnsData
  }

  generateTableAction(actionName, position, tooltip) {
    return {
      position: position,
      action: (rowData) => ({
        icon: () => <TableActions actionName={actionName} rowData={rowData} />,
        tooltip: tooltip,
        disabled:
          ACTION_NAMES_TO_DISABLE.includes(actionName) &&
          rowData.draftStatus === 'Deleted'
      })
    }
  }

  getTableActions() {
    const tableActions = generateTableActions().map((action) =>
      this.generateTableAction(
        action.actionName,
        action.position,
        action.tooltip
      )
    )

    return tableActions
  }

  async reorderRow() {
    const { position, newPosition } = this.dragState
    const { formValues, change } = this.props
    const { rules } = formValues

    var reorderedRules = [...rules]
    const [removed] = reorderedRules.splice(position, 1)
    reorderedRules.splice(newPosition, 0, { ...removed, draftStatus: 'Edited' })

    change(REDUX_FORM_NAME, 'rules', reorderedRules)
  }

  renderTableBodyRow(props) {
    return (
      <MTableBodyRow
        {...props}
        draggable='true'
        onDragStart={(e) => {
          this.dragState.position = props.index
          this.dragState.ruleId = props.data.id
        }}
        onDragEnter={(e) => {
          e.preventDefault()
          if (props.index !== this.dragState.position) {
            this.dragState.newPosition = props.index
          }
        }}
        onDragEnd={(e) => {
          if (this.dragState.newPosition !== -1) {
            this.reorderRow()
          }
          this.dragState = { ruleId: -1, position: -1, newPosition: -1 }
        }}
      />
    )
  }

  render() {
    const { windowHeight } = this.state

    return (
      <div className='rules-table-container'>
        <MaterialTable
          tableRef={this.tableRef}
          data={this.fetchRulesData.bind(this)}
          columns={this.getDisplayColumns()}
          actions={this.getTableActions()}
          options={generateTableOptions(windowHeight)}
          localization={generateTableLocalization()}
          components={{
            Row: (props) => this.renderTableBodyRow(props)
          }}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RulesEngineOverview)
)
