import {
  FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS,
  FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS,
  FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_SUCCESS
} from 'actions/merchant_center_product_info_and_measures'
import { FETCH_WEBSITE_MERCHANT_CENTER_STATUS_SUCCESS } from 'actions/_websites/_status'

const initialState = {
  websites: {},
  accounts: {},
  systemWideProductInfoAndMeasures: {}
}

const merchantCenterProductInfoAndMeasuresReducer = (
  state = initialState,
  action
) => {
  const websiteData = (state.websites && state.websites[action.websiteId]) || {}

  switch (action.type) {
    case FETCH_WEBSITE_MERCHANT_CENTER_STATUS_SUCCESS:
      return {
        ...state,
        websites: {
          ...state.websites,
          [action.websiteId]: {
            ...websiteData,
            merchantCenterStatus: action.websiteMerchantCenterStatus
          }
        }
      }
    case FETCH_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS:
      return {
        ...state,
        websites: {
          ...state.websites,
          [action.websiteId]: {
            ...websiteData,
            ...action.websiteProductInfoAndMeasures
          }
        }
      }
    case FETCH_SYSTEM_WIDE_MERCHANT_CENTER_PRODUCT_INFO_AND_MEASURES_SUCCESS:
      return {
        ...state,
        systemWideProductInfoAndMeasures: {
          ...action.systemWideProductInfoAndMeasures
        }
      }
    case FETCH_ACCOUNT_MERCHANT_CENTER_STATUS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.accountId]: {
            ...state.accounts[action.accountId],
            accountMerchantCenterStatus: action.accountMerchantCenterStatus
          }
        }
      }
    default:
      return state
  }
}

export default merchantCenterProductInfoAndMeasuresReducer
