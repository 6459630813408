import _ from 'lodash'
import { RECEIVE_LOAD_PRODUCT_IDS, REQUEST_LOAD_PRODUCTS, RECEIVE_LOAD_PRODUCTS, SAMPLE_PRODUCTS_RESPONSE, SAMPLE_PRODUCTS_REQUEST } from 'actions/scenario/inventory/products'

const productsReducer = (state = { available: 0, productIds: {}, requestedIds: [], products: {}}, action) => {
  switch(action.type) {
    case RECEIVE_LOAD_PRODUCT_IDS:
      return {
        ...state,
        available: action.available,
        productIds: {
          ...state.productIds,
          [action.scenarioId]: action.productIds
        }
      }
    case REQUEST_LOAD_PRODUCTS:
      return {
        ...state,
        requestedIds: action.productIds
      }
    case RECEIVE_LOAD_PRODUCTS:
      var requestedIds = _.difference(state.requestedIds, Object.keys(action.products).map(id => parseInt(id, 10)))
      return {
        ...state,
        ...action.products,
        requestedIds
      };
    case SAMPLE_PRODUCTS_RESPONSE:
      return {
      ...state,
        sample: action.products
      }
    case SAMPLE_PRODUCTS_REQUEST:
      return {
      ...state
      }
    default:
      return state
  }
}

export default productsReducer