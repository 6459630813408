import { createSelector } from 'reselect'

const microsoftSelector = (state) => state.microsoft || {}

const adwordsSelector = createSelector(
  [microsoftSelector],
  (microsoft) => microsoft.adwords || {}
)

export const adwordsAccountsSelector = createSelector(
  [adwordsSelector],
  (adwords) => adwords.accounts || []
)
