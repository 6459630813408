import { createSelector } from 'reselect'
import { websiteIdSelector } from 'selectors/websites'
import { noDefaultChannelSelector } from 'selectors/channel'
import { CHANNEL_ALL, PLAYBOOK_CHANNELS } from 'util/supported_channels'
import _ from 'lodash'

export const playbookSelector = (state, _props) => state.playbook || {}

export const showDismissPlaybookItemsSelector = createSelector(
  [playbookSelector],
  (playbookProgress) => playbookProgress.showDismissPlaybookItems || false
)

export const playbookProgressWebsiteSelector = createSelector(
  [playbookSelector, websiteIdSelector],
  (playbookProgress, websiteId) =>
    !_.isEmpty(playbookProgress[websiteId])
      ? playbookProgress[websiteId].progressDetails
      : {}
)

export const playbookProgressWebsiteGeneratedAtSelector = createSelector(
  [playbookSelector, websiteIdSelector],
  (playbookProgress, websiteId) =>
    !_.isEmpty(playbookProgress[websiteId])
      ? playbookProgress[websiteId].generatedDetailsTime
      : null
)

export const playbookProgressWebsiteActiveItemsSelector = createSelector(
  [playbookProgressWebsiteSelector],
  (websiteProgress) => {
    return (Object.values(websiteProgress).flat(1) || []).filter(
      (item) => !item.isDismissed && item.display
    )
  }
)

export const playbookProgressWebsiteChannelSelector = createSelector(
  [
    playbookProgressWebsiteSelector,
    noDefaultChannelSelector,
    showDismissPlaybookItemsSelector
  ],
  (websiteProgress, channel, showDismiss) => {
    var allItems =
      !channel || channel === CHANNEL_ALL
        ? PLAYBOOK_CHANNELS.map(
            (orderedChannel) =>
              websiteProgress[_.camelCase(orderedChannel)] || []
          ).flat(1)
        : websiteProgress[channel] || []
    allItems = allItems
      .map((i) => ({
        ...i,
        channelOrder: PLAYBOOK_CHANNELS.findIndex((c) => c === i.channel)
      }))
      .filter((i) => (showDismiss ? i.isDismissed : !i.isDismissed))
    return _.orderBy(
      allItems.filter((i) => i.enabled && i.display),
      ['channelOrder', 'opportunity'],
      ['asc', 'desc']
    )
  }
)

export const playbookItemsByChannelSelector = (channel) =>
  createSelector([playbookProgressWebsiteSelector], (websiteProgress) => {
    var items

    if (channel === CHANNEL_ALL) {
      items = Object.values(websiteProgress).flat(1) || []
    } else {
      items = websiteProgress[channel] || []
    }
    return items
  })

export const channelOptimisationScoreSelector = (channel) =>
  createSelector([playbookItemsByChannelSelector(channel)], (items) => {
    var possibleScore = items.reduce(
      (accumulator, item) => accumulator + item.possibleScore,
      0
    )

    if (possibleScore <= 0) {
      return 0
    }

    var score = items.reduce((accumulator, item) => accumulator + item.score, 0)
    return parseInt((score * 100) / possibleScore)
  })

export const HEALTH_CRITICAL = 'Critical'
export const HEALTH_ERROR = 'Error'
export const HEALTH_WARNING = 'Warning'
export const HEALTH_OK = 'OK'
export const HEALTH_IN_ORDER = [
  HEALTH_OK,
  HEALTH_WARNING,
  HEALTH_ERROR,
  HEALTH_CRITICAL
]

export const channelHealthSelector = (channel) =>
  createSelector([playbookItemsByChannelSelector(channel)], (items) => {
    var healths = items.map((i) =>
      HEALTH_IN_ORDER.findIndex((h) => h === i.health)
    )
    var index = Math.max(...healths)
    return index > 0 ? HEALTH_IN_ORDER[index] : HEALTH_OK
  })
