import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import BackButton from 'components/feed_ops/common/back_button'

export default class HeaderActions extends Component {
  render() {
    return (
      <MDBRow className='actions-row'>
        <MDBCol className='col-3' />
        <MDBCol>
          <BackButton label='GO BACK' float='left' />
        </MDBCol>
        <MDBCol className='col-3' />
      </MDBRow>
    )
  }
}
