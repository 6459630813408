import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
export const SAVE_MERCHANT_CENTER_SETTING_SUCCESS  = 'SAVE_MERCHANT_CENTER_SETTING_SUCCESS';
export const LOAD_MERCHANT_CENTER_SETTING_SUCCESS  = 'LOAD_MERCHANT_CENTER_SETTING_SUCCESS';

const updateMerchantCenterSettings = (merchantCenterSetting) => ({
  type: LOAD_MERCHANT_CENTER_SETTING_SUCCESS,
  merchantCenterSetting: merchantCenterSetting
});

const saveMerchantCenterSettingsSuccess = (merchantCenterSetting) => ({
  type: SAVE_MERCHANT_CENTER_SETTING_SUCCESS,
  merchantCenterSetting: merchantCenterSetting
});

export const loadMerchantCenterSetting = scenarioId => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/merchant_center_setting/edit`,
    dataType: 'json',
    success: (response) => {
      return dispatch(updateMerchantCenterSettings(camelCaseKeys(response.merchant_center_setting)));
    }
  });
};

export const saveMerchantCenterSetting = (scenarioId, merchantCenterSetting) => dispatch => {
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/merchant_center_setting`,
    data: snakeCaseKeys({merchantCenterSetting: merchantCenterSetting}),
    dataType: 'json',
    success: (response) => {
      return dispatch(saveMerchantCenterSettingsSuccess(merchantCenterSetting));
    }
  });
};


