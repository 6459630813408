import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'
import {
  ALL_CATEGORIES,
  ENABLED_CATEGORIES,
  DISABLED_CATEGORIES
} from 'components/feed/optimisation/filter'

export class ControlBar extends Component {
  static propTypes = {
    searchKeyword: PropTypes.string.isRequired,
    displayEnableDisableAllBtns: PropTypes.bool.isRequired,
    handleFilter: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleEnableDisableAll: PropTypes.func
  }

  static defaultProps = {
    displayEnableDisableAllBtns: false
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedFilter: ALL_CATEGORIES
    }
  }

  handleEnableDisableClick(enabled) {
    const { handleEnableDisableAll } = this.props
    handleEnableDisableAll(enabled)
  }

  renderEnableDisableAllBtns(enabled) {
    return (
      <MDBBtn
        tag='span'
        className='btn-card-nav'
        onClick={this.handleEnableDisableClick.bind(this, enabled)}
      >
        {enabled ? 'Enable All' : 'Disable All'}
      </MDBBtn>
    )
  }

  handleOnChange(event) {
    const { handleSearch } = this.props
    handleSearch(event.target.value)
  }

  renderSearchBox() {
    const { searchKeyword } = this.props
    return (
      <div className='right-div'>
        <MDBIcon icon={'search'} />
        <MDBInput
          placeholder={`Search categories`}
          onChange={this.handleOnChange.bind(this)}
          value={searchKeyword}
        />
      </div>
    )
  }

  handleDropdownItemClick(event) {
    const { handleFilter } = this.props
    this.setState({ selectedFilter: event.target.id })
    handleFilter(event.target.id)
  }

  renderDropdownItem(item) {
    return (
      <MDBDropdownItem
        key={item}
        id={item}
        onClick={this.handleDropdownItemClick.bind(this)}
      >
        {item}
      </MDBDropdownItem>
    )
  }

  renderDropdown() {
    const { selectedFilter } = this.state
    const dropDownItems = [
      ALL_CATEGORIES,
      DISABLED_CATEGORIES,
      ENABLED_CATEGORIES
    ]
    var items = []
    dropDownItems.forEach((item) => {
      items.push(this.renderDropdownItem(item))
    })

    return (
      <MDBDropdown size='sm'>
        <MDBDropdownToggle>Show: {selectedFilter}</MDBDropdownToggle>
        <MDBDropdownMenu basic>{items}</MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  render() {
    const { displayEnableDisableAllBtns } = this.props

    return (
      <MDBRow className={'control-bar'}>
        {displayEnableDisableAllBtns && (
          <>
            <MDBCol md='2' className='enable-disable-col'>
              {this.renderEnableDisableAllBtns(true)}
            </MDBCol>
            <MDBCol md='2' className='enable-disable-col'>
              {this.renderEnableDisableAllBtns(false)}
            </MDBCol>
          </>
        )}
        <MDBCol md='4'>{this.renderDropdown()}</MDBCol>
        <MDBCol className='search-bar'>{this.renderSearchBox()}</MDBCol>
      </MDBRow>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ControlBar)
)
