import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isValid, isDirty, isSubmitting, reset } from 'redux-form'
import { bindActionCreators } from 'redux'
import { MDBBtn } from 'mdbreact'
import { remoteSubmitAndWait } from 'actions/forms'
import ReactTooltip from 'react-tooltip'

export class FormButton extends Component {
  static propTypes = {
    isCancel: PropTypes.bool.isRequired,
    label: PropTypes.string,
    reduxFormName: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isShowTooltips: PropTypes.bool,
    toolTipTitle: PropTypes.string,
    toolTipMessage: PropTypes.string,
    remoteSubmitAndWait: PropTypes.func.isRequired
  }

  handleSubmit() {
    const { isCancel, reduxFormName, remoteSubmitAndWait, reset } = this.props
    isCancel ? reset(reduxFormName) : remoteSubmitAndWait(reduxFormName)
  }

  computeNameAndClass() {
    const { label, isCancel } = this.props
    const className = `category-optimizations-action ${
      isCancel && 'cancel-action'
    } ${label && 'variable-width'}`
    const name = label ? label : `${isCancel ? 'Discard' : 'Apply'} Changes`

    return { name, className }
  }

  render() {
    const {
      isValid,
      isDirty,
      isSubmitting,
      isShowTooltips = false,
      toolTipTitle = '',
      toolTipMessage = ''
    } = this.props
    const nameAndClass = this.computeNameAndClass()
    const buttonDisabled = !isValid || !isDirty || isSubmitting

    return (
      <span>
        <span data-tip data-for={nameAndClass.name}>
          <MDBBtn
            type={'submit'}
            color={'primary'}
            className={nameAndClass.className}
            onClick={this.handleSubmit.bind(this)}
            size='sm'
            disabled={buttonDisabled}
          >
            {nameAndClass.name}
          </MDBBtn>
        </span>
        <ReactTooltip
          id='Apply Changes'
          direction='down-start'
          place='right'
          effect='solid'
          disable={!buttonDisabled || !isShowTooltips || isValid}
        >
          <div> {toolTipTitle} </div>
          <div> {toolTipMessage} </div>
        </ReactTooltip>
      </span>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isValid: isValid(props.reduxFormName)(state) || false,
    isDirty: isDirty(props.reduxFormName)(state) || false,
    isSubmitting: isSubmitting(props.reduxFormName)(state) || false
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ remoteSubmitAndWait, reset }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormButton)
)
