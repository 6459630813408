import {
  SAVE_MERCHANT_CENTER_SETTING_SUCCESS,
  LOAD_MERCHANT_CENTER_SETTING_SUCCESS
} from 'actions/shopping_link'

const INITIAL_STATE = {
  targetSalesCountry: '',
  merchantCenterId: '',
  linkStatus: '',
  countryCodes: []
}

const shoppingLinkReducer = (
  state = { merchantCenterSetting: INITIAL_STATE },
  action
) => {
  switch (action.type) {
    case LOAD_MERCHANT_CENTER_SETTING_SUCCESS:
      return {
        ...state,
        merchantCenterSetting: action.merchantCenterSetting
      }
    case SAVE_MERCHANT_CENTER_SETTING_SUCCESS:
      return {
        ...state,
        merchantCenterSetting: action.merchantCenterSetting
      }
    default:
      return state
  }
}

export default shoppingLinkReducer
