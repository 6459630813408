import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MDBListGroup, MDBListGroupItem } from 'mdbreact'

export default class AttributeListItem extends Component {
  static propTypes = {
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAttributeItemClick: PropTypes.func.isRequired
  }

  handleAttributeItemClick(attribute) {
    const { handleAttributeItemClick } = this.props
    handleAttributeItemClick(attribute)
  }

  render() {
    const { attributes } = this.props
    const attributeItems = _.sortBy(attributes, 'columnPosition').map(
      (attribute) => (
        <MDBListGroupItem
          key={attribute.columnName}
          hover
          onClick={this.handleAttributeItemClick.bind(this, attribute)}
        >
          {attribute.columnName}
        </MDBListGroupItem>
      )
    )

    return <MDBListGroup>{attributeItems}</MDBListGroup>
  }
}
