import { createSelector } from 'reselect'
import changeCase from 'change-case'
import { defaultGoogleChannelSelector } from 'selectors/channel'

export const advertisingGoalTypeSelector = (state) =>
  state.advertisingGoalTypes || {}

export const advertisingGoalTypesSelector = createSelector(
  [advertisingGoalTypeSelector],
  (a) => a.types || []
)

const advertisingGoalTypeBudgetCategoriesSelector = createSelector(
  [advertisingGoalTypeSelector],
  (a) => a.budgetCategories || {}
)

const advertisingGoalTypeBudgetCategorySelector = (budgetCategoryId) =>
  createSelector(
    [advertisingGoalTypeBudgetCategoriesSelector],
    (budgetCategories) => budgetCategories[budgetCategoryId] || {}
  )

export const budgetCategoryGoalTypeSelector = (budgetCategoryId) =>
  createSelector(
    [advertisingGoalTypeBudgetCategorySelector(budgetCategoryId)],
    (a) => a.goalType || ''
  )

const advertisingGoalTypesAccountTypeSelector = (accountType) =>
  createSelector(
    [advertisingGoalTypeSelector],
    (advertisingGoalType) =>
      advertisingGoalType[changeCase.camelCase(accountType)] || {}
  )

const advertisingGoalTypesAccountTypeByIdSelector = (accountType, id) =>
  createSelector(
    [advertisingGoalTypesAccountTypeSelector(accountType)],
    (accountType) => accountType[id] || {}
  )

const advertisingGoalTypesChannelSelector = (accountType, id) =>
  createSelector(
    [
      advertisingGoalTypesAccountTypeByIdSelector(accountType, id),
      defaultGoogleChannelSelector
    ],
    (accountTypeById, channel) => accountTypeById[channel] || {}
  )

export const displayConversionValueOnCostSelector = createSelector(
  [advertisingGoalTypeSelector],
  (advertisingGoalTypes) =>
    advertisingGoalTypes.displayConversionValueOnCost || false
)

export const goalTypeSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryGoalTypeSelector(id)
    case 'websites':
      return createSelector(
        [advertisingGoalTypesChannelSelector(accountType, id)],
        (channel) => channel.goalType || ''
      )
    case 'accounts':
      return createSelector(
        [advertisingGoalTypesChannelSelector(accountType, id)],
        (channel) => channel.goalType || ''
      )
    case 'master_accounts':
      return createSelector(
        [advertisingGoalTypesChannelSelector(accountType, id)],
        (channel) => channel.goalType || ''
      )
    default:
      return () => ''
  }
}

export const getGoalSuffix = (goalType, displayConversionValueOnCost) => {
  switch (goalType) {
    case 'roas':
      return displayConversionValueOnCost ? '' : '%'
    default:
      return ''
  }
}
