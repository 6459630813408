import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from 'mdbreact'
import { deleteMerchantCenterAccount } from 'actions/google/merchant_center'
import { fetchWebsiteDetail } from 'actions/websites'
import { websiteIdSelector } from 'selectors/websites'

export class DisconnectMerchantCenterButton extends Component {
  static propTypes = {
    fetchWebsiteDetail: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    deleteMerchantCenterAccount: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      busy: false,
      removeMCModalOpen: false
    }
  }

  async componentDidMount() {}

  async fetchWebsiteDetail() {
    const { websiteId, fetchWebsiteDetail } = this.props
    this.setState({ busy: true })
    try {
      await fetchWebsiteDetail(websiteId)
    } catch (error) {
      //Do nothing
    }

    this.setState({ busy: false })
  }

  async removeMerchantCenterAccount() {
    const { deleteMerchantCenterAccount, websiteId } = this.props
    this.setState({ busy: true })
    await deleteMerchantCenterAccount(websiteId)
    this.fetchWebsiteDetail()
    this.setState({ busy: false, removeMCModalOpen: false })
  }

  setRemoveMCModalOpen(open) {
    this.setState({ removeMCModalOpen: open })
  }

  render() {
    return (
      <div>
        <MDBBtn
          size='sm'
          color='danger'
          outline
          style={{ float: 'right' }}
          onClick={this.setRemoveMCModalOpen.bind(this, true)}
        >
          Disconnect Merchant Center Connection
        </MDBBtn>
        <MDBModal
          centered={true}
          isOpen={this.state.removeMCModalOpen}
          toggle={this.setRemoveMCModalOpen.bind(this, false)}
        >
          <MDBModalHeader>
            You Are About To Disconnect Your Merchant Center
          </MDBModalHeader>
          <MDBModalBody>
            Doing this will stop your feed in Merchant Center from being kept in
            sync. After disconnecting your existing Merchant Center you will
            again be able to connect an existing Merchant Center.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size='sm'
              color='dark'
              outline
              disabled={this.state.busy}
              onClick={this.setRemoveMCModalOpen.bind(this, false)}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              size='sm'
              color='danger'
              disabled={this.state.busy}
              onClick={this.removeMerchantCenterAccount.bind(this)}
            >
              {this.state.busy ? <MDBIcon spin fixed icon='spinner' /> : ''}{' '}
              Disconnect
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteDetail,
      deleteMerchantCenterAccount
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisconnectMerchantCenterButton)
)
