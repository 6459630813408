import {
  FETCH_ADVERTISING_PLAN_SUCCESS,
  FETCH_HAS_BUDGET_CATEGORIES_SUCCESS,
  FETCH_WEBSITE_BUDGET_RESPONSE,
  FETCH_WEBSITE_CAMPAIGN_IDS_RESPONSE
} from 'actions/_advertising/websites'
import {
  FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_SUCCESS,
  FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_SUCCESS
} from 'actions/_advertising/budget_categories'
import { DESTROY_BUDGET_CATEGORY_SUCCESS } from 'actions/_budget_categories/management'
import {
  FETCH_ADVERTISING_BUDGETS_SUCCESS,
  FETCH_ADVERTISING_GOALS_SUCCESS,
  CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS,
  FETCH_ADVERTISING_STATUS_SUCCESS
} from 'actions/advertising'

const initialState = {
  masterAccounts: {},
  accounts: {},
  websites: {},
  budgetCategories: {},
  status: {},
  budget: {},
  campaignIds: {}
}

const generateAdvertisingStatusDetails = (state, action) => {
  var statusDetails = state.status[action.websiteId]
    ? { ...state.status[action.websiteId] }
    : {}

  statusDetails[action.buildType] = {
    buildId: action.advertisingStatus.buildId || 0,
    lastPublishedAt: action.advertisingStatus.lastPublishedAt,
    generating: action.advertisingStatus.generating,
    publishing: action.advertisingStatus.publishing,
    status: action.advertisingStatus.status
  }
  return statusDetails
}

const generateWebsiteBudget = (state, action) => {
  var budget = state.budget[action.websiteId]
    ? { ...state.budget[action.websiteId] }
    : {}

  budget[action.buildType] = action.response.budget || 0
  return budget
}

const generateCampaignIds = (state, action) => {
  const websiteData = state.campaignIds[action.websiteId] || {}
  var campaignIds = {}
  campaignIds[action.buildType] = action.response.campaignIds || []
  return {
    ...websiteData,
    ...campaignIds
  }
}

const advertisingReducer = (state = initialState, action) => {
  const accountTypeData =
    (action.accountType && action.id && state[action.accountType][action.id]) ||
    {}

  const accountTypeChannelData =
    (action.channel && accountTypeData[action.channel]) || {}

  const budgetCategoriesData =
    (action.budgetCategoryId &&
      state.budgetCategories[action.budgetCategoryId]) ||
    {}
  const websiteData =
    (action.websiteId && state.websites[action.websiteId]) || {}

  switch (action.type) {
    case FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: {
            ...budgetCategoriesData,
            budgets: action.budget
          }
        }
      }
    case FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: {
            ...budgetCategoriesData,
            goals: action.goal
          }
        }
      }
    case DESTROY_BUDGET_CATEGORY_SUCCESS:
      return {
        ...state,
        budgetCategories: {
          ...state.budgetCategories,
          [action.budgetCategoryId]: undefined
        }
      }
    case FETCH_ADVERTISING_PLAN_SUCCESS:
      return {
        ...state,
        websites: {
          ...state.websites,
          [action.websiteId]: {
            ...websiteData,
            advertisingPlan: action.advertisingPlan
          }
        }
      }
    case FETCH_ADVERTISING_GOALS_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              goals: action.goal
            }
          }
        }
      }
    case FETCH_ADVERTISING_BUDGETS_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            [action.channel]: {
              ...accountTypeChannelData,
              budgets: action.budget
            }
          }
        }
      }
    case FETCH_HAS_BUDGET_CATEGORIES_SUCCESS:
      return {
        ...state,
        websites: {
          ...state.websites,
          [action.websiteId]: {
            ...websiteData,
            hasBudgetCategories: action.hasBudgetCategories
          }
        }
      }
    case CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        [action.accountType]: {
          ...state[action.accountType],
          [action.id]: {
            ...accountTypeData,
            ...action.publicationAccountTypes
          }
        }
      }
    case FETCH_ADVERTISING_STATUS_SUCCESS:
      const generatedAdvertisingStatusDetails =
        generateAdvertisingStatusDetails(state, action)
      return {
        ...state,
        status: {
          ...state.status,
          [action.websiteId]: {
            ...state[action.websiteId],
            ...generatedAdvertisingStatusDetails
          }
        }
      }
    case FETCH_WEBSITE_BUDGET_RESPONSE:
      const generatedWebsiteBudget = generateWebsiteBudget(state, action)
      return {
        ...state,
        budget: {
          ...state.budget,
          [action.websiteId]: {
            ...state[action.websiteId],
            ...generatedWebsiteBudget
          }
        }
      }
    case FETCH_WEBSITE_CAMPAIGN_IDS_RESPONSE:
      const generatedCampaignIds = generateCampaignIds(state, action)
      return {
        ...state,
        campaignIds: {
          ...state.campaignIds,
          [action.websiteId]: generatedCampaignIds
        }
      }
    default:
      return state
  }
}

export default advertisingReducer
