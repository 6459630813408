import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { PLAYBOOK_CHANNELS } from 'util/supported_channels'
import HealthScoreCard from './health_score_card'
import OptimisationScoreCard from './optimisation_score_card'
import AuditsCard from './audits_card'
import { channelSelector } from 'selectors/channel'

export class PlaybookSummary extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    showHideButton: PropTypes.bool.isRequired
  }

  static defaultProps = {
    showHideButton: true
  }

  constructor(props) {
    super(props)
    this.state = {
      collapsed: false
    }
  }

  handleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed })
  }

  renderChannelSummary() {
    const { channel, showHideButton } = this.props
    return (
      <MDBRow className='playbook-charts'>
        <MDBCol md='4'>
          <HealthScoreCard channel={channel} />
        </MDBCol>
        <MDBCol md='4'>
          <OptimisationScoreCard channel={channel} />
        </MDBCol>
        {showHideButton && (
          <MDBCol md='4'>
            <AuditsCard />
          </MDBCol>
        )}
      </MDBRow>
    )
  }

  render() {
    const { collapsed } = this.state
    const { showHideButton } = this.props

    return (
      <>
        {!collapsed && this.renderChannelSummary()}
        {showHideButton && (
          <MDBRow className='playbook-charts-show-hide'>
            <MDBBtn color='link' onClick={this.handleCollapsed.bind(this)}>
              {collapsed ? 'Show Summary' : 'Hide Summary'}
            </MDBBtn>
          </MDBRow>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  channel: props.channel || channelSelector(state, props)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlaybookSummary)
)
