import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { updateSelectedSuggestionColumns } from 'actions/product_feeds/replacements'
import Checkbox from '@material-ui/core/Checkbox'
import { websiteIdSelector } from 'selectors/websites'
import {
  websiteProductOptimisationSuggestionsSelector,
  websiteProductReplacementsSelectedColumnsSelector
} from 'selectors/product_feeds/replacements'

import './column_title.scss'

export class ColumnTitle extends Component {
  static propTypes = {
    columnName: PropTypes.string.isRequired,
    attribute: PropTypes.object.isRequired,
    websiteId: PropTypes.number.isRequired,
    optimisationSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    updateSelectedSuggestionColumns: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = { showCheckbox: false, checked: true }
  }

  async computeSuggestionColumns() {
    const { attribute, optimisationSuggestions, selectedColumns } = this.props
    const suggestionFields = _.uniq(
      _.flatten(optimisationSuggestions.map((s) => Object.keys(s.suggestions)))
    )

    if (suggestionFields.includes(attribute.field)) {
      const { updateSelectedSuggestionColumns, websiteId } = this.props
      const newSelectedColumns = [...selectedColumns, ...suggestionFields]

      await updateSelectedSuggestionColumns(websiteId, newSelectedColumns)
      this.setState({ showCheckbox: true })
    }
  }

  async componentDidMount() {
    const { attribute, optimisationSuggestions } = this.props
    if (
      attribute &&
      attribute.field &&
      attribute.columnName &&
      optimisationSuggestions &&
      optimisationSuggestions.length > 0
    ) {
      await this.computeSuggestionColumns()
    }
  }

  async componentDidUpdate(prevProps) {
    const { attribute, optimisationSuggestions } = this.props
    if (
      !_.isEqual(prevProps.attribute, attribute) ||
      !_.isEqual(prevProps.optimisationSuggestions, optimisationSuggestions)
    ) {
      await this.computeSuggestionColumns()
    }
  }

  async handleChange() {
    const { checked } = this.state
    const {
      attribute,
      selectedColumns,
      updateSelectedSuggestionColumns,
      websiteId
    } = this.props

    const newSelectedColumns = checked
      ? [...selectedColumns].filter((c) => c !== attribute.field) || []
      : [...selectedColumns, attribute.field]

    await updateSelectedSuggestionColumns(websiteId, newSelectedColumns)
    this.setState({ checked: !checked })
  }

  renderCheckbox() {
    const { columnName } = this.props
    const { checked } = this.state

    return (
      <div id={`col-title-${columnName}`}>
        <Checkbox
          className='column-checkbox'
          checked={checked}
          onChange={this.handleChange.bind(this)}
        />
        {columnName}
      </div>
    )
  }

  render() {
    const { showCheckbox } = this.state
    const { columnName } = this.props

    return <>{showCheckbox ? this.renderCheckbox() : columnName}</>
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  optimisationSuggestions: websiteProductOptimisationSuggestionsSelector(
    state,
    props
  ),
  selectedColumns: websiteProductReplacementsSelectedColumnsSelector(
    state,
    props
  )
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateSelectedSuggestionColumns }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ColumnTitle)
)
