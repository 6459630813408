import {
  LOAD_ADWORDS_ACCOUNT_SUCCESS,
  UPDATE_ADWORDS_ACCOUNT_SUCCESS,
  UPDATE_ADWORDS_ACCOUNT_ERROR,
  SET_ADWORDS_ACCOUNT_ID_RESPONSE,
  LIST_ADWORDS_ACCOUNTS_RESPONSE
} from 'actions/adwords_account'

const initialState = {
  errors: {},
  adwordAccountClientIds: [],
  linked: false,
  invitationSent: false
}

const adwordsAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ADWORDS_ACCOUNT_SUCCESS:
      return { ...state, ...action.adwordsAccount }
    case UPDATE_ADWORDS_ACCOUNT_SUCCESS:
      return {
        ...state,
        errors: {},
        ...action.adwordsAccount
      }
    case UPDATE_ADWORDS_ACCOUNT_ERROR:
      return { ...state, ...action.adwordsAccount }
    case SET_ADWORDS_ACCOUNT_ID_RESPONSE:
      return {
        ...state,
        adwordsAccountId: action.adwordsAccountId,
        connectedWebsitesIds: action.connectedWebsitesIds
      }
    case LIST_ADWORDS_ACCOUNTS_RESPONSE:
      return { ...state, adwordsAccounts: action.adwordsAccounts }
    default:
      return state
  }
}

export default adwordsAccountReducer
