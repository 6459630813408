import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  fetchWebsiteDetail,
  createWebsite,
  updateWebsite
} from 'actions/websites'
import { fetchWebsiteSubscription } from 'actions/billing'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBBtn,
  MDBCardFooter
} from 'mdbreact'
import HeaderActions from './new_website_form/header_actions'
import AccountsSelector from 'components/feed_ops/common/accounts_selector'
import CircularProgress from '@material-ui/core/CircularProgress'
import SuperUserBadge from 'components/feed_ops/common/super_user_badge'
import { superUserSelector } from 'selectors/user'
import queryString from 'query-string'

const isUrlRegEx =
  /^(?:https:\/\/)(?:www\.)?[\w.-]+(?:\.[\w]{2,})+\/?(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?$/

export class FeedOpsNewWebsiteForm extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    createWebsite: PropTypes.func.isRequired,
    updateWebsite: PropTypes.func.isRequired,
    fetchWebsiteSubscription: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      websiteUrl: 'https://',
      invalidWebsiteUrl: false,
      newAccountCheck: false,
      accountName: '',
      accountId: null,
      creatingWebsite: false,
      planId: null
    }
  }

  componentDidMount() {
    const { location } = this.props
    this.setState({ planId: queryString.parse(location.search).plan_id })
  }

  handleAccountIdChanged(accountId) {
    this.setState({ accountId })
  }

  validateWebsiteUrl() {
    const { websiteUrl } = this.state
    if (websiteUrl) {
      this.setState({ invalidWebsiteUrl: !isUrlRegEx.test(websiteUrl) })
    }
  }

  renderWebsiteUrlInput() {
    const { websiteUrl, invalidWebsiteUrl } = this.state

    return (
      <>
        <MDBInput
          id='newWebsiteUrl'
          label='Add your website URL'
          value={websiteUrl}
          onBlur={this.validateWebsiteUrl.bind(this)}
          onChange={(event) => {
            this.setState({ websiteUrl: event.target.value })
          }}
        />
        {invalidWebsiteUrl && (
          <div className='website-url-error'>Please enter a valid URL.</div>
        )}
      </>
    )
  }

  renderNewAccountCheckbox() {
    const { newAccountCheck } = this.state

    return (
      <MDBInput
        id='newAccountCheckbox'
        type='checkbox'
        label='Create new website under a new account?'
        checked={newAccountCheck}
        onChange={() => {
          this.setState({ newAccountCheck: !newAccountCheck })
        }}
      />
    )
  }

  renderNewAccount() {
    const { accountName } = this.state

    return (
      <MDBInput
        id='newAccountName'
        label='Add new account name'
        value={accountName}
        onChange={(event) => {
          this.setState({ accountName: event.target.value })
        }}
      />
    )
  }

  renderCardBody() {
    const { newAccountCheck, accountId } = this.state
    const { isSuperUser } = this.props

    return (
      <MDBCardBody>
        <MDBCardTitle>Add New Website</MDBCardTitle>
        <MDBCardText>
          Adding a new website will trigger the first free product data audit,
          enable the AI recommendations, the playbook and editing tools.
        </MDBCardText>
        <AccountsSelector
          forWebsite={true}
          accountId={accountId}
          handleAccountIdChanged={this.handleAccountIdChanged.bind(this)}
        />
        {this.renderWebsiteUrlInput()}
        {isSuperUser && (
          <>
            <SuperUserBadge />
            {this.renderNewAccountCheckbox()}
            {newAccountCheck && this.renderNewAccount()}
          </>
        )}
      </MDBCardBody>
    )
  }

  isUnderNewAccount() {
    const { newAccountCheck, accountName } = this.state
    return newAccountCheck && accountName.length > 0
  }

  generateCreateWebsiteParams() {
    const { websiteUrl, accountName, accountId, planId } = this.state

    var websiteParams = {
      url: websiteUrl,
      planId
    }
    Object.assign(
      websiteParams,
      this.isUnderNewAccount()
        ? { name: accountName }
        : { accountId: accountId }
    )

    return websiteParams
  }

  async handleAddWebsiteClick() {
    this.setState({ creatingWebsite: true })
    const { createWebsite, appId } = this.props
    const websiteParams = this.generateCreateWebsiteParams()

    try {
      var response = await createWebsite({
        appId,
        website: websiteParams
      })

      const { fetchWebsiteDetail, fetchWebsiteSubscription } = this.props
      await fetchWebsiteDetail(response.websiteId)
      await fetchWebsiteSubscription(response.websiteId)
      this.setState({ creatingWebsite: false })
      this.props.history.push(
        `/${appId}/${response.websiteId}/website/connect_store`
      )
    } catch (ex) {
      toastr.error('Something went wrong. Please contact support.')
    }
    this.setState({ creatingWebsite: false })
  }

  renderCardFooter() {
    const {
      websiteUrl,
      newAccountCheck,
      accountName,
      accountId,
      creatingWebsite
    } = this.state

    return (
      <MDBCardFooter className='new-website-card-footer'>
        <MDBBtn
          disabled={
            creatingWebsite ||
            !websiteUrl ||
            !isUrlRegEx.test(websiteUrl) ||
            (newAccountCheck ? !accountName : accountId === null)
          }
          onClick={this.handleAddWebsiteClick.bind(this)}
        >
          Add Website
        </MDBBtn>
        {creatingWebsite && <CircularProgress />}
      </MDBCardFooter>
    )
  }

  render() {
    return (
      <div className='main-content'>
        <HeaderActions />
        <MDBRow className='options-row'>
          <MDBCol className='col-3' />
          <MDBCol>
            <MDBCard>
              {this.renderCardBody()}
              {this.renderCardFooter()}
            </MDBCard>
          </MDBCol>
          <MDBCol className='col-3' />
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  isSuperUser: superUserSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebsiteDetail,
      createWebsite,
      updateWebsite,
      fetchWebsiteSubscription
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsNewWebsiteForm)
)
