import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  fetchUserAccounts,
  updateAccountOverviewFilters
} from 'actions/users/accounts'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import { MDBCard, MDBCardBody, MDBBtn } from 'mdbreact'
import MaterialTable from '@material-table/core'
import TableActions from './overview/table_actions'
import {
  WEBSITE_NAME_FIELD,
  SKUS_FIELD,
  ACCOUNT_NAME_COLUMN,
  COLUMNS,
  generateTableActions,
  generateSummaryRow,
  generateTableOptions,
  generateTableLocalization
} from './overview_util'
import { filterAccountsOverview } from './overview/attributes_util'
import {
  currentUserSelector,
  currentUserAccountsOverviewSelector,
  currentUserAccountsOverviewFiltersSelector
} from 'selectors/user'

export class AccountsOverview extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    accountsOverview: PropTypes.arrayOf(PropTypes.object).isRequired,
    appliedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchUserAccounts: PropTypes.func.isRequired,
    updateAccountOverviewFilters: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      windowHeight: 0,
      filteredAccounts: []
    }
  }

  handleWindowResize() {
    this.setState({ windowHeight: window.innerHeight })
  }

  initialize() {
    const { accountsOverview } = this.props
    this.setState({ filteredAccounts: [...accountsOverview] })
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    this.handleWindowResize()

    const { fetchUserAccounts, user, updateAccountOverviewFilters } = this.props

    await fetchUserAccounts(user.id)
    updateAccountOverviewFilters([])
    this.setState({ loading: false })
    this.initialize()
  }

  filterAccounts(prevProps) {
    const { accountsOverview, appliedFilters } = this.props

    if (!_.isEqual(appliedFilters, prevProps.appliedFilters)) {
      if (appliedFilters.length === 0) {
        this.initialize()
      } else {
        this.setState({
          filteredAccounts: filterAccountsOverview(
            accountsOverview,
            appliedFilters
          )
        })
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { user, accountsOverview } = this.props

    if (prevProps.user !== user && accountsOverview.length === 0) {
      const { fetchUserAccounts, updateAccountOverviewFilters } = this.props

      this.setState({ loading: true })
      await fetchUserAccounts(user.id)
      updateAccountOverviewFilters([])
      this.setState({ loading: false })
      this.initialize()
    }

    this.filterAccounts(prevProps)
  }

  handleWebsiteClick(websiteId) {
    const { history } = this.props
    history.push(`/feed_ops/${websiteId}`)
  }

  renderCellData(columnData, index, rowData) {
    const emptyValue = rowData[columnData.field].toString() === '0'

    return columnData.field === WEBSITE_NAME_FIELD ? (
      <MDBBtn
        id={`${columnData.field}${index}${rowData.websiteName}`}
        className='btn-href-style'
        onClick={this.handleWebsiteClick.bind(this, rowData.websiteId)}
        tag='span'
      >
        {rowData[columnData.field].toString()}
      </MDBBtn>
    ) : (
      <span id={`${columnData.field}${index}${rowData.websiteName}`}>
        {emptyValue ? '-' : rowData[columnData.field].toLocaleString('en-AU')}
      </span>
    )
  }

  getDisplayColumns() {
    const { accountsOverview } = this.props
    const columns =
      accountsOverview.length === 1
        ? COLUMNS
        : [ACCOUNT_NAME_COLUMN, ...COLUMNS]

    var displayedColumnsData = []
    columns.forEach((columnData, index) => {
      displayedColumnsData.push({
        ...columnData,
        render: (rowData) => this.renderCellData(columnData, index, rowData)
      })
    })

    return displayedColumnsData
  }

  genAccountsOverviewData(filteredAccounts) {
    return _.orderBy(
      filteredAccounts.map((account) => account.websites).flat(),
      [SKUS_FIELD],
      ['desc']
    )
  }

  generateTableAction(actionName, position, tooltip) {
    const { filteredAccounts } = this.state

    return {
      position: position,
      action: (rowData) => ({
        icon: () => (
          <TableActions
            actionName={actionName}
            filteredAccounts={filteredAccounts}
          />
        ),
        tooltip: tooltip
      })
    }
  }

  getTableActions() {
    const tableActions = generateTableActions().map((action) =>
      this.generateTableAction(
        action.actionName,
        action.position,
        action.tooltip
      )
    )

    return tableActions
  }

  generateSummaryRow(column) {
    const { accountsOverview } = this.props
    const { filteredAccounts } = this.state
    const tableData = this.genAccountsOverviewData(filteredAccounts)
    return generateSummaryRow(column, tableData, accountsOverview.length === 1)
  }

  renderTable() {
    const { filteredAccounts, windowHeight } = this.state

    return (
      <MaterialTable
        columns={this.getDisplayColumns()}
        data={this.genAccountsOverviewData(filteredAccounts)}
        actions={this.getTableActions()}
        renderSummaryRow={({ column }) => this.generateSummaryRow(column)}
        options={generateTableOptions(windowHeight)}
        localization={generateTableLocalization()}
      />
    )
  }

  render() {
    const { loading, filteredAccounts } = this.state

    return loading && filteredAccounts.length === 0 ? (
      <IndeterminateProgressIndicator />
    ) : (
      <MDBCard className='accounts-overview'>
        <MDBCardBody>
          <div className='accounts-table-container'>{this.renderTable()}</div>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  user: currentUserSelector(state, props),
  accountsOverview: currentUserAccountsOverviewSelector(state, props) || [],
  appliedFilters: currentUserAccountsOverviewFiltersSelector(state, props) || []
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchUserAccounts, updateAccountOverviewFilters },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountsOverview)
)
