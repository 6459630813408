import { createSelector } from 'reselect'
import { deleteMerchantCenterAccount as deleteGoogleMerchantCenterAccount } from 'actions/google/merchant_center'
import { deleteMerchantCenterAccount as deleteMicrosoftMerchantCenterAccount } from 'actions/microsoft/merchant_center'
import { store } from 'containers/ad_platform_context'
import {
  websiteDetailSelector,
  websiteFeedDetailSelector,
  websiteMerchantCenterSelector,
  websiteAdwordsSelector,
  websiteMicrosoftMerchantCenterSelector,
  websiteMicrosoftAdwordsSelector
} from 'selectors/websites'
import {
  googleMerchantCenterLogo,
  googleAdsLogo,
  facebookLogo,
  microsoftLogo,
  bazaarvoiceLogo,
  reebeloLogo,
  pinterestLogo
} from 'util/supported_channels'
import timeAgoInWords from 'util/time_ago_in_words'

export const channelSettingsSelector = createSelector(
  [websiteDetailSelector],
  (websiteDetail) => websiteDetail.channelSettings || []
)

export const enabledChannelSettingsSelector = createSelector(
  [channelSettingsSelector],
  (channelSettings) => channelSettings.filter((channel) => channel.enabled)
)

export const enabledChannelsSelector = createSelector(
  [enabledChannelSettingsSelector],
  (channelSettings) => channelSettings.map((channel) => channel.channel)
)

const getFeedStatusByChannelSelector = createSelector(
  [websiteFeedDetailSelector],
  (feedDetail) =>
    feedDetail && feedDetail.updatedAt
      ? timeAgoInWords(feedDetail.updatedAt)
      : 'Generating'
)

export const googleAccountSelector = createSelector(
  [
    websiteMerchantCenterSelector,
    websiteAdwordsSelector,
    getFeedStatusByChannelSelector
  ],
  (merchantCenterAccount, adwordsAccount, feedStatus) => {
    const { merchantCenterAccountId, merchantCenterAccountName, oauthUser } =
      merchantCenterAccount || {}
    const { adwordsAccountId } = adwordsAccount || {}

    return [
      {
        title: 'Google Merchant Center',
        image: googleMerchantCenterLogo,
        accountId: merchantCenterAccountId,
        accountName: merchantCenterAccountName,
        connectedBy: oauthUser,
        updatedAt: feedStatus,
        connectPath: 'website/connect_merchant_center_account',
        disconnect: (websiteId) =>
          store.dispatch(deleteGoogleMerchantCenterAccount(websiteId)),
        disabled: false
      },
      {
        title: 'Google Ads',
        image: googleAdsLogo,
        accountId: adwordsAccountId,
        connectedBy: oauthUser,
        connectPath: 'website/connect_ads_account',
        disabled: !!!merchantCenterAccountId
      }
    ]
  }
)

export const facebookAccountSelector = createSelector(
  [getFeedStatusByChannelSelector],
  (feedStatus) => [
    {
      title: 'Facebook Catalogue',
      image: facebookLogo,
      updatedAt: feedStatus,
      showAccessFeed: true
    }
  ]
)

export const microsoftAccountSelector = createSelector(
  [websiteMicrosoftMerchantCenterSelector, websiteMicrosoftAdwordsSelector],
  (merchantCenterAccount, adwordsAccount) => {
    const { merchantCenterAccountId, merchantCenterAccountName, oauthUser } =
      merchantCenterAccount || {}
    const { customerAccountId } = adwordsAccount || {}

    return [
      {
        title: 'Microsoft Merchant Center',
        image: microsoftLogo,
        accountId: merchantCenterAccountId,
        accountName: merchantCenterAccountName,
        connectedBy: oauthUser,
        connectPath:
          'website/connect_merchant_center_account?channel=microsoft',
        disabled: false,
        disconnect: (websiteId) =>
          store.dispatch(deleteMicrosoftMerchantCenterAccount(websiteId))
      },
      {
        title: 'Microsoft Ads',
        image: microsoftLogo,
        accountId: customerAccountId,
        connectedBy: oauthUser,
        connectPath: 'website/connect_ads_account?channel=microsoft',
        disabled: !!!merchantCenterAccountId
      }
    ]
  }
)

export const bazaarvoiceAccountSelector = createSelector(
  [getFeedStatusByChannelSelector],
  (feedStatus) => [
    {
      title: 'Bazaarvoice',
      image: bazaarvoiceLogo,
      updatedAt: feedStatus,
      showAccessFeed: true
    }
  ]
)

export const reebeloAccountSelector = createSelector(
  [getFeedStatusByChannelSelector],
  (feedStatus) => [
    {
      title: 'Reebelo',
      image: reebeloLogo,
      updatedAt: feedStatus,
      showAccessFeed: true
    }
  ]
)

export const pinterestAccountSelector = createSelector(
  [getFeedStatusByChannelSelector],
  (feedStatus) => [
    {
      title: 'Pinterest',
      image: pinterestLogo,
      updatedAt: feedStatus,
      showAccessFeed: true
    }
  ]
)
