import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'

export default class ColumnTooltip extends Component {
  static propTypes = {
    tooltipId: PropTypes.string.isRequired,
    displayValues: PropTypes.object.isRequired
  }

  getTooltipValue(value) {
    var updatedValue = value
    if (updatedValue && updatedValue.length > 100) {
      updatedValue = updatedValue.substring(0, 100) + '...'
    }

    return updatedValue
  }

  render() {
    const { tooltipId, displayValues } = this.props

    return (
      <UncontrolledTooltip
        target={tooltipId}
        placement='top'
        modifiers={{ flip: { behavior: ['top'] } }}
      >
        Website value is: {this.getTooltipValue(displayValues.raw)}
      </UncontrolledTooltip>
    )
  }
}
