import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, Form, getFormValues, registerField } from 'redux-form'
import { MDBInput, MDBBtn } from 'mdbreact'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { selectAdwordsAccount } from 'actions/google/adwords'
import { fetchWebsiteDetail } from 'actions/websites'
import GenericContent from 'components/websites/workflow/generic_content'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import Switch from 'components/ad_champion/common/switch'
import TextInputGroup from 'components/ad_champion/common/text_input_group'
import { validatePresence, validateAdwordsId } from 'components/util/validation'
import {
  adwordsAccountIdSelector,
  createAdwordsAccountSelector
} from 'selectors/google/accounts'
import { websiteIdSelector } from 'selectors/websites'

export class GoogleAdwordsForm extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    selectAdwordsAccount: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    initialize: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    adwordsAccountId: PropTypes.string.isRequired,
    useExistingAccount: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { editing: false, busy: false }
  }

  componentDidMount() {
    const { websiteId, registerField } = this.props
    registerField(REDUX_FORM_NAME, 'adwordsAccountId', 'Field')
    registerField(REDUX_FORM_NAME, 'useExistingAccount', 'Field')
    if (websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
  }

  componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (websiteId > 0 && prevProps.websiteId !== websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
  }

  async fetchWebsiteDetail(websiteId) {
    const { fetchWebsiteDetail } = this.props
    await fetchWebsiteDetail(websiteId)

    const { initialize, adwordsAccountId, useExistingAccount } = this.props
    initialize({
      adwordsAccountId,
      useExistingAccount
    })
  }

  renderInput() {
    return (
      <TextInputGroup name='adwordsAccountId' label={'Enter your Account ID'} />
    )
  }

  async handleSubmit(values) {
    const { websiteId, selectAdwordsAccount, dirty } = this.props
    const { adwordsAccountId, useExistingAccount } = values

    try {
      if (dirty) {
        await selectAdwordsAccount(websiteId, {
          adwordsAccountId,
          createAccount: !useExistingAccount
        })
        await this.fetchWebsiteDetail(websiteId)
      }
      this.setState({ editing: false })
    } catch (error) {
      toastr.error(error.responseJSON.error, 'ERROR')
      throw error
    }
  }

  renderUseExistingAccountCheckbox() {
    return (
      <GenericContent
        component={
          <Switch
            name='useExistingAccount'
            labelLeft={null}
            labelRight='Use an existing account'
          />
        }
        help={
          'Unless you wish to use an existing account, a new account will be created for you.'
        }
      />
    )
  }

  renderSaveButton() {
    const { showSaveButton, submit, valid } = this.props
    return (
      showSaveButton && (
        <MDBBtn onClick={submit} disabled={!valid}>
          Save
        </MDBBtn>
      )
    )
  }

  renderEdit() {
    return <GenericContent component={this.renderInput()} />
  }

  renderEditable() {
    const { useExistingAccount } = this.props.formValues
    return (
      <div>
        {this.renderUseExistingAccountCheckbox()}
        {useExistingAccount && this.renderEdit()}
        {this.renderSaveButton()}
      </div>
    )
  }

  renderForm() {
    const { handleSubmit } = this.props

    return (
      <Form
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        className='adwords-form'
      >
        {!this.state.editing ? this.renderReadOnly() : this.renderEditable()}
      </Form>
    )
  }

  renderReadOnlyInput() {
    const { adwordsAccountId, useExistingAccount } = this.props
    return (
      <MDBInput
        label='Account ID'
        value={
          useExistingAccount
            ? `${adwordsAccountId}`
            : 'A new account will be created'
        }
        name='adwordsAccountId'
        readOnly
      />
    )
  }

  renderEditButton() {
    return (
      <MDBBtn
        outline
        color='primary'
        onClick={() => this.setState({ editing: true })}
      >
        Edit
      </MDBBtn>
    )
  }

  renderReadOnly() {
    const { appId } = this.props
    return (
      <React.Fragment>
        <GenericContent component={this.renderReadOnlyInput()} />
        {!(appId === 'platform') && this.renderEditButton()}
      </React.Fragment>
    )
  }

  render() {
    const { initialized } = this.props

    if (!initialized) {
      return <IndeterminateProgressIndicator />
    }

    return this.renderForm()
  }
}

export const REDUX_FORM_NAME = 'GoogleAdsForm'

const mapStateToProps = (state, props) => {
  return {
    appId: props.match.params.appId,
    adwordsAccountId: adwordsAccountIdSelector(state, props),
    useExistingAccount: !createAdwordsAccountSelector(state, props),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    websiteId: websiteIdSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWebsiteDetail, registerField, selectAdwordsAccount },
    dispatch
  )
const GoogleAdwordsFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAdwordsForm)

export const validator = (values) => {
  var errors = {}
  if (values.useExistingAccount) {
    errors.adwordsAccountId =
      validatePresence(values.adwordsAccountId) ||
      validateAdwordsId(values.adwordsAccountId)
    return errors
  }
  return true
}

export default withRouter(
  reduxForm({
    form: REDUX_FORM_NAME,
    validate: validator,
    ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
  })(GoogleAdwordsFormWithRedux)
)
