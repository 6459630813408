import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { listGoogleSpreadsheets } from 'actions/google/sheets'
import AsyncSelect from 'react-select/async'

import './async_account_select.scss'

export class AsyncAccountSelect extends Component {
  static propTypes = {
    shouldLoadAsyncAccounts: PropTypes.bool.isRequired,
    defaultAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultAccountId: PropTypes.string,
    handleValueChange: PropTypes.func.isRequired,
    listGoogleSpreadsheets: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      options: [],
      selectedOption: null
    }
  }

  convertToOptions(accounts) {
    return accounts.map((account) => ({
      value: account.id,
      label: account.name
    }))
  }

  componentDidMount() {
    const { defaultAccounts, defaultAccountId } = this.props
    this.setState({ options: this.convertToOptions(defaultAccounts) })

    if (defaultAccountId) {
      const selectedAccount = defaultAccounts.find(
        (account) => account.id === defaultAccountId
      )
      const selectedOption = {
        value: selectedAccount.id,
        label: selectedAccount.name
      }

      this.setState({ selectedOption })
      this.handleSelectionChange(selectedOption)
    }
  }

  filterDefaultOptions(inputValue) {
    const { defaultAccounts } = this.props
    return defaultAccounts.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  async loadAccountsBySearch(inputValue) {
    const { shouldLoadAsyncAccounts } = this.props
    var accounts = this.filterDefaultOptions(inputValue)

    if (shouldLoadAsyncAccounts && accounts.length === 0) {
      const { listGoogleSpreadsheets } = this.props
      accounts = await listGoogleSpreadsheets(inputValue)
    }

    return this.convertToOptions(accounts)
  }

  handleSelectionChange(selectedOption) {
    const { handleValueChange } = this.props
    handleValueChange(selectedOption)
    this.setState({
      selectedOption: selectedOption
    })
  }

  render() {
    const { options, selectedOption } = this.state

    return (
      <AsyncSelect
        backspaceRemovesValue={false}
        cacheOptions
        className='one-time-import-select'
        classNamePrefix='async-account'
        defaultOptions={options}
        isClearable={false}
        loadOptions={this.loadAccountsBySearch.bind(this)}
        placeholder='Select / Search'
        value={selectedOption}
        onChange={this.handleSelectionChange.bind(this)}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listGoogleSpreadsheets }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsyncAccountSelect)
)
