import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm, isDirty } from 'redux-form'
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBRow
} from 'mdbreact'
import {
  remoteFormSubmissionHandlers,
  remoteSubmitAndWait
} from 'actions/forms'
import ProductAttributeList, {
  REDUX_FORM_NAME as PRODUCT_ATTRIBUTE_LIST_FORM
} from 'components/feed/optimisation/product_attributes/attribute_list'
import SaveChangesPrompt from 'components/save_changes_prompt'
import FormButton from 'components/form_button'
import CustomerHelper from 'components/customer_helper/customer_helper'

export class ProductAttributeOptimiserForm extends Component {
  static propTypes = {
    remoteSubmitAndWait: PropTypes.func.isRequired
  }

  renderDescription() {
    return (
      <MDBCardText className={'description'}>
        When unchanged, product attribute values will appear in Google exactly
        as they appear on the website. When rewritten, the product attribute
        values that appear in Google can be optimized for better search engine
        ranking by better matching the way people search.
      </MDBCardText>
    )
  }

  renderActionButtons() {
    return (
      <MDBRow className='action-btn-row'>
        <FormButton
          isCancel={false}
          reduxFormName={PRODUCT_ATTRIBUTE_LIST_FORM}
          isShowTooltips={true}
          toolTipTitle='Changes cannot be saved until all issues are resolved.'
          toolTipMessage='Issues can be identified with a red line next to the attribute name. Any questions on this please reach out to the team.'
        />
        <FormButton
          isCancel={true}
          reduxFormName={PRODUCT_ATTRIBUTE_LIST_FORM}
        />
      </MDBRow>
    )
  }

  renderFooter() {
    return (
      <MDBCardFooter>
        <CustomerHelper
          helperName='Not sure what to do?'
          articleId='optimizing-your-product-attributes'
          bookAMeetingType={'feedops'}
        />
      </MDBCardFooter>
    )
  }

  renderSavePrompt() {
    const { isDirty } = this.props
    return (
      <SaveChangesPrompt
        when={isDirty}
        message='You have unsaved changes, are you sure you want to leave?'
      />
    )
  }

  render() {
    return (
      <>
        {this.renderSavePrompt()}
        <MDBCard className='product-attribute-optimiser'>
          <MDBCardBody>
            {this.renderDescription()}
            <ProductAttributeList />
            {this.renderActionButtons()}
          </MDBCardBody>
          {this.renderFooter()}
        </MDBCard>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isDirty: isDirty(PRODUCT_ATTRIBUTE_LIST_FORM)(state) || false
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ remoteSubmitAndWait }, dispatch)

const ProductAttributeOptimiserRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAttributeOptimiserForm)

export const REDUX_FORM_NAME = 'ProductAttributeOptimiser'

export const handleSubmit = async (values, dispatch, props) => {
  const { remoteSubmitAndWait } = props
  await remoteSubmitAndWait(PRODUCT_ATTRIBUTE_LIST_FORM)
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(ProductAttributeOptimiserRedux)
  )
)
