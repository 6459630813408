import { createSelector } from 'reselect'
import {
  websiteIdSelector,
  websiteDetailSelector,
  websiteRetailInventoryIdsSelector
} from 'selectors/websites'

export const inventoriesSelector = (state) => state.inventories || {}

export const inventoryDetailsSelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.details || {}
)

export const inventoryDetailSelector = (inventoryId) =>
  createSelector(
    inventoryDetailsSelector,
    (details) => details[inventoryId] || { errors: [] }
  )

export const inventoryItemCountSelector = (inventoryId) =>
  createSelector(
    [inventoryDetailSelector(inventoryId)],
    (detail) => detail.itemCount
  )

export const firstInventoryInWebsiteSelector = createSelector(
  [websiteDetailSelector, inventoryDetailsSelector],
  (websiteDetail, inventoryDetails) => {
    if (!inventoryDetails || !websiteDetail || !websiteDetail.inventoryIds) {
      return {}
    }
    return inventoryDetails[websiteDetail.inventoryIds[0]] || {}
  }
)

const inventoryFcAttributesSelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.fcAttributes || {}
)

export const inventoryMappableAttributesSelector = createSelector(
  [inventoryFcAttributesSelector],
  (fcAttributes) => fcAttributes.mappableAttributes || []
)

export const inventoryAutoMappableAttributesSelector = createSelector(
  [inventoryFcAttributesSelector],
  (fcAttributes) => fcAttributes.autoMappedAttributes || []
)

export const inventoryStoreAttributesSelector = createSelector(
  [inventoryFcAttributesSelector],
  (fcAttributes) => fcAttributes.storeAttributes || []
)

export const inventoryExistingAttributesMappingSelector = createSelector(
  [inventoryFcAttributesSelector],
  (fcAttributes) => fcAttributes.existingMappings || []
)

export const inventoryFeedOpsCategoryTreeSelector = createSelector(
  [inventoriesSelector, websiteIdSelector],
  (inventories, websiteId) =>
    inventories.foCategoryTree &&
    Object.keys(inventories.foCategoryTree).length > 0
      ? inventories.foCategoryTree[websiteId]
      : []
)

export const inventoryFeedOpsCategoryHierarchySelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.foCategoryHierarchy || []
)

export const inventoryCategoryTreeSelector = createSelector(
  [inventoriesSelector, websiteIdSelector],
  (inventories, websiteId) =>
    inventories.categoryTree && Object.keys(inventories.categoryTree).length > 0
      ? inventories.categoryTree[websiteId]
      : []
)

export const inventoryCategoryHierarchySelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.categoryHierarchy || []
)

export const defaultCategoryDepthSelector = createSelector(
  [inventoryCategoryHierarchySelector],
  (hierarchy) => hierarchy[0] || {}
)

export const defaultCategorySelector = createSelector(
  [defaultCategoryDepthSelector],
  (defaultCategoryDepth) => defaultCategoryDepth[0] || { depth: 0, id: 0 }
)

export const hierarchyDepthSelector = (depth) =>
  createSelector(
    [inventoryCategoryHierarchySelector],
    (hierarchy) => hierarchy[depth] || {}
  )

export const hierarchyCategorySelector = (depth, categoryId) =>
  createSelector(
    [hierarchyDepthSelector(depth)],
    (hierarchyDepth) => hierarchyDepth[categoryId] || {}
  )

export const categoryDepthValueSelector = (_state, props) => props.categoryDepth

export const initialReconciledStatusSelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.initialReconciled || false
)

export const outboundFeedsGeneratedStatusSelector = createSelector(
  [inventoriesSelector],
  (inventories) => inventories.outboundFeedsGenerated || false
)

export const inventoryProcessingSelector = createSelector(
  [inventoriesSelector, websiteRetailInventoryIdsSelector],
  (inventories, retailInventoryIds) =>
    inventories.processingStatus &&
    Object.keys(inventories.processingStatus).length > 0 &&
    inventories.processingStatus[retailInventoryIds[0]]
      ? inventories.processingStatus[retailInventoryIds[0]] || {}
      : {}
)

export const inventoryProcessingStatusSelector = createSelector(
  [inventoryProcessingSelector],
  (processing) => processing.message || ''
)

export const inventoryProcessingValueSelector = createSelector(
  [inventoryProcessingSelector],
  (processing) => processing.value || 0
)

export const inventoryProcessingTotalProductsSelector = createSelector(
  [inventoryProcessingSelector],
  (processing) => processing.totalProducts || 0
)

export const inventoryProductDataAuditsSelector = createSelector(
  [inventoriesSelector, websiteRetailInventoryIdsSelector],
  (inventories, retailInventoryIds) =>
    inventories.audits &&
    Object.keys(inventories.audits).length > 0 &&
    inventories.audits[retailInventoryIds[0]]
      ? inventories.audits[retailInventoryIds[0]].inventoryAudits || []
      : []
)

export const inventoryAuditScheduleMessageSelector = createSelector(
  [inventoriesSelector, websiteRetailInventoryIdsSelector],
  (inventories, retailInventoryIds) =>
    inventories.audits &&
    Object.keys(inventories.audits).length > 0 &&
    inventories.audits[retailInventoryIds[0]]
      ? inventories.audits[retailInventoryIds[0]].scheduleMessage || ''
      : ''
)

export const inventoryShowAllAuditsSelector = createSelector(
  [inventoriesSelector],
  (inventories) =>
    inventories.audits && Object.keys(inventories.audits).length > 0
      ? inventories.audits.showAllAudits
      : false
)
