import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import GoogleProductCategorySelect from 'components/feed_ops/common/google_product_category_select'
import { taxonomiesOptionsSelector } from 'selectors/google/taxonomies'

export class GoogleProductCategoryDropdown extends Component {
  static propTypes = {
    filterValue: PropTypes.string.isRequired,
    singleSelectionOnly: PropTypes.bool.isRequired,
    googleTaxonomyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueChanged: PropTypes.func.isRequired
  }

  static defaultProps = {
    singleSelectionOnly: false
  }

  constructor(props) {
    super(props)
    this.state = {
      defaultOption: null
    }
  }

  componentDidMount() {
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    const { filterValue } = this.props
    if (!_.isEqual(prevProps.filterValue, filterValue)) {
      this.setDefaults()
    }
  }

  setDefaults() {
    const { filterValue, googleTaxonomyOptions } = this.props
    if (!_.isEmpty(filterValue)) {
      const values = filterValue.split('\n')
      const option = googleTaxonomyOptions.filter((o) =>
        values.includes(o.label)
      )
      this.setState({ defaultOption: option })
    }
  }

  handleTaxonomySelectionChange(selectedOptions) {
    const { valueChanged } = this.props
    var currentValues =
      selectedOptions.length > 0
        ? selectedOptions
            .map((option) => {
              return option.label
            })
            .join('\n')
        : ''
    valueChanged(currentValues)
    this.setState({ defaultOption: selectedOptions })
  }

  render() {
    const { defaultOption } = this.state
    const { googleTaxonomyOptions, singleSelectionOnly } = this.props

    return (
      <GoogleProductCategorySelect
        selectedValue={defaultOption}
        googleTaxonomyOptions={googleTaxonomyOptions}
        isMultiSelectEnabled={!singleSelectionOnly}
        valueChanged={this.handleTaxonomySelectionChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  googleTaxonomyOptions: taxonomiesOptionsSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleProductCategoryDropdown)
)
