import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import googleIcon from 'images/google-icon-dark.png'
import { MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdbreact'
import { googleAuthLogin } from 'util/google/google_apis'
import { appIdSelector } from 'selectors/app'

export class SignUpOptions extends Component {
  static propTypes = {
    googleAuthLogin: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired
  }

  static defaultProps = {
    googleAuthLogin
  }

  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = { loggingIn: false }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async login() {
    const { googleAuthLogin, appId } = this.props
    this.setState({ loggingIn: true })
    try {
      await googleAuthLogin(appId)
    } catch (error) {}

    if (this._isMounted) {
      this.setState({ loggingIn: false })
    }
  }

  renderSignUpButton() {
    const { loggingIn } = this.state

    return (
      <div className={'sign-up-options'}>
        <MDBBtn
          className={'sign-up-with-google'}
          onClick={this.login.bind(this)}
          disabled={loggingIn}
        >
          <img alt={'Google icon'} src={googleIcon} />
          <div>
            Sign up with google
            {loggingIn && ' '}
            {loggingIn && <MDBIcon spin fixed icon='spinner' />}
          </div>
        </MDBBtn>
      </div>
    )
  }

  render() {
    return (
      <>
        <MDBCardTitle>
          Start your 30 day free trial on our Optimize plan.
        </MDBCardTitle>
        <MDBCardText>
          Sign up with Google to create your account. See plan details{' '}
          <a href='https://feedops.com/pricing'>here</a>.
        </MDBCardText>
        {this.renderSignUpButton()}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  appId: appIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpOptions)
)
