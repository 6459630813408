import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { getFormValues, getFormSyncErrors, change } from 'redux-form'
import { MDBContainer } from 'mdbreact'
import { fetchGoogleTaxonomies } from 'actions/google/taxonomies'
import CategoryRewriteRow from '../category_optimizations/selected_category_form/category_rewrite_row'
import GoogleProductCategoryRow from '../category_optimizations/selected_category_form/google_product_category_row'
import TemplateRow, {
  TEMPLATE_TYPE_TITLE,
  TEMPLATE_TYPE_DESCRIPTION
} from '../category_optimizations/selected_category_form/template_row'
import { REDUX_FORM_NAME } from '../category_optimizations/card'
import { websiteFeedIdByChannelSelector } from 'selectors/websites'
import {
  taxonomiesSelector,
  taxonomiesOptionsSelector
} from 'selectors/google/taxonomies'
import { inventoryFeedOpsCategoryHierarchySelector } from 'selectors/inventory'
import { CHANNEL_GOOGLE } from 'util/supported_channels'

export class SelectedCategoryForm extends Component {
  static propTypes = {
    feedId: PropTypes.number.isRequired,
    googleTaxonomies: PropTypes.object.isRequired,
    googleTaxonomyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    category: PropTypes.object.isRequired,
    categoryHierarchy: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    formErrors: PropTypes.object.isRequired,
    fetchGoogleTaxonomies: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.handleRewriteChange = this.handleRewriteChange.bind(this)
    this.handleGoogleTaxonomyChange = this.handleGoogleTaxonomyChange.bind(this)
    this.state = {
      formCategory: {}
    }
  }

  async componentDidMount() {
    await this.fetchGoogleTaxonomies(this.props.feedId)
    this.updateInitialState()
  }

  componentDidUpdate(prevProps) {
    const { googleTaxonomies, feedId } = this.props
    if (
      !_.isEqual(prevProps.googleTaxonomies, googleTaxonomies) ||
      !_.isEqual(prevProps.feedId, feedId)
    ) {
      this.fetchGoogleTaxonomies(feedId)
    }

    const { category } = this.props
    if (!_.isEqual(prevProps.category, category)) {
      this.updateInitialState()
    } else {
      this.formValuesUpdated(prevProps)
    }
  }

  async fetchGoogleTaxonomies(feedId) {
    const { fetchGoogleTaxonomies } = this.props
    if (feedId && feedId > 0) {
      await fetchGoogleTaxonomies(feedId)
    }
  }

  formValuesUpdated(prevProps) {
    const { category, formValues } = this.props
    const { depth, id } = category
    if (
      depth &&
      id &&
      prevProps.formValues.categories[depth] &&
      !_.isEqual(
        prevProps.formValues.categories[depth][id],
        formValues.categories[depth][id]
      )
    ) {
      this.updateInitialState()
    }
  }

  updateInitialState() {
    const { category, formValues } = this.props
    const { depth, id } = category

    if (depth && id && formValues && Object.keys(formValues).length > 0) {
      this.setState({ formCategory: formValues.categories[depth][id] })
    }
  }

  handleRewriteChange(value) {
    const { change } = this.props
    const { formCategory } = this.state
    const { depth, id } = formCategory
    change(REDUX_FORM_NAME, `categoryReplacements[${depth}][${id}]`, value)
  }

  renderRewriteRow() {
    const { formValues, formErrors } = this.props
    const { formCategory } = this.state

    return (
      <CategoryRewriteRow
        category={formCategory}
        formValues={formValues}
        formErrors={formErrors}
        isDisabled={formCategory.excluded}
        handleRewriteChange={this.handleRewriteChange}
      />
    )
  }

  handleGoogleTaxonomyChange(currentValue) {
    const { change } = this.props
    const { formCategory } = this.state
    const { depth, id } = formCategory

    change(
      REDUX_FORM_NAME,
      `categories[${depth}][${id}][taxonomyId]`,
      currentValue
    )
  }

  renderGoogleCategoryRow() {
    const { googleTaxonomyOptions } = this.props
    const { formCategory } = this.state

    return (
      <GoogleProductCategoryRow
        category={formCategory}
        isDisabled={formCategory.excluded}
        googleTaxonomyOptions={googleTaxonomyOptions}
        handleGoogleTaxonomyChange={this.handleGoogleTaxonomyChange}
      />
    )
  }

  handleTemplateChange(templateType, value) {
    const { change } = this.props
    const { formCategory } = this.state
    const { depth, id } = formCategory

    change(
      REDUX_FORM_NAME,
      `categories[${depth}][${id}][${templateType}Template]`,
      value
    )
  }

  renderTemplateRow(templateType) {
    const { formCategory } = this.state
    return (
      <TemplateRow
        template={formCategory[`${templateType}Template`]}
        onTemplateChange={this.handleTemplateChange.bind(this, templateType)}
        templateType={templateType}
      />
    )
  }

  render() {
    const { formCategory } = this.state

    return formCategory && Object.keys(formCategory).length > 0 ? (
      <MDBContainer className='selected-category-container'>
        {this.renderRewriteRow()}
        {this.renderGoogleCategoryRow()}
        {this.renderTemplateRow(TEMPLATE_TYPE_TITLE)}
        {this.renderTemplateRow(TEMPLATE_TYPE_DESCRIPTION)}
      </MDBContainer>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  feedId: websiteFeedIdByChannelSelector(state, {
    ...props,
    location: { search: `?channel=${CHANNEL_GOOGLE}` }
  }),
  googleTaxonomies: taxonomiesSelector(state, props),
  googleTaxonomyOptions: taxonomiesOptionsSelector(state, props),
  categoryHierarchy: inventoryFeedOpsCategoryHierarchySelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
  formErrors: getFormSyncErrors(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGoogleTaxonomies, change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectedCategoryForm)
)
