import { FETCH_ANALYTICS_ACCESS_TOKEN_SUCCESS } from 'actions/google/analytics'

const initialState = {
  AnalyticsAccessToken: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANALYTICS_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        AnalyticsAccessToken: action.accessToken
      }
    default:
      return state
  }
}
