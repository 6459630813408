import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
  MDBScrollbar
} from 'mdbreact'
import {
  supportedPlatforms,
  PLATFORM_GOOGLE_SHEET,
  PLATFORM_CUSTOM_XML
} from 'util/supported_platforms'

export default class FeedPlatformSelector extends Component {
  static propTypes = {
    selectedPlatform: PropTypes.string,
    defaultPlatform: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    advanced: PropTypes.bool
  }

  static defaultProps = {
    advanced: true
  }

  renderPlatformImage(p, active) {
    return (
      <img
        alt={p.label}
        className={`platform-icon${active ? ' active' : ''}`}
        src={p.image}
      />
    )
  }

  handleClick(platform) {
    const { onChange } = this.props
    onChange(platform)
  }

  supportedPlatforms() {
    const { advanced } = this.props
    return advanced
      ? supportedPlatforms
      : supportedPlatforms.filter(function (p) {
          if (
            p.value === PLATFORM_GOOGLE_SHEET ||
            p.value === PLATFORM_CUSTOM_XML
          ) {
            return false
          }
          return true
        })
  }

  selectedPlatform() {
    const { selectedPlatform, defaultPlatform } = this.props
    return this.supportedPlatforms().find(
      (p) => p.value === (selectedPlatform || defaultPlatform)
    )
  }

  renderToggle() {
    var platform = this.selectedPlatform()
    var imageOrText = platform ? (
      this.renderPlatformImage(platform, true)
    ) : (
      <div className='platform-selector-placeholder'>
        Select the eCommerce platform the website is built with
      </div>
    )
    return (
      <MDBDropdownToggle tag='span' disabled={this.props.readOnly}>
        {imageOrText} <i className='fa fa-chevron-down' />
      </MDBDropdownToggle>
    )
  }

  renderScroll() {
    var platform = this.selectedPlatform()
    return (
      <MDBScrollbar className='platform-scroll'>
        {this.supportedPlatforms().map((p) => (
          <MDBDropdownItem
            key={p.value}
            onClick={() => this.handleClick(p.value)}
          >
            {this.renderPlatformImage(p, p.value === (platform || {}).value)}
          </MDBDropdownItem>
        ))}
      </MDBScrollbar>
    )
  }

  render() {
    var platform = this.selectedPlatform()
    return (
      <MDBDropdown className={platform ? 'set' : ''}>
        {this.renderToggle()}
        <MDBDropdownMenu>
          <MDBContainer
            style={{ width: '240px', height: '578px', padding: '0' }}
          >
            {this.renderScroll()}
          </MDBContainer>
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }
}
