import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { openGoogleDrivePicker } from 'util/google/google_apis'
import { listGoogleSpreadsheetSheets } from 'actions/google/sheets'
import ApiSource from './common/api_source'
import { googleUserAccessTokenSelector } from 'selectors/google/user'
import { CHANNEL_GOOGLE } from 'util/supported_channels'

export const GOOGLE_SHEET_IMPORT_SOURCE = 'google_sheet'
const GOOGLE_SHEETS_SCOPES = ['https://www.googleapis.com/auth/drive.file']

export class GoogleSheetsSource extends Component {
  static propTypes = {
    userAccessToken: PropTypes.string.isRequired,
    handleApiSourceChange: PropTypes.func.isRequired,
    openGoogleDrivePicker: PropTypes.func.isRequired,
    listGoogleSpreadsheetSheets: PropTypes.func.isRequired
  }

  static defaultProps = {
    openGoogleDrivePicker
  }

  constructor(props) {
    super(props)
    this.state = {
      spreadsheets: [],
      spreadsheetId: '',
      sheets: [],
      sheetId: ''
    }
  }

  handlePickerResponse(data) {
    if (data.action === global.google.picker.Action.PICKED) {
      const { docs } = data
      const spreadsheets = docs.map((doc) => ({
        id: doc.id,
        name: doc.name
      }))

      this.setState({ spreadsheets, spreadsheetId: spreadsheets[0].id })
    }
  }

  async handleOnScopeGranted() {
    const { openGoogleDrivePicker, userAccessToken } = this.props
    openGoogleDrivePicker(userAccessToken, this.handlePickerResponse.bind(this))
  }

  async handleSpreadsheetChange(spreadsheet) {
    const spreadsheetId = spreadsheet.value
    const { listGoogleSpreadsheetSheets, handleApiSourceChange } = this.props
    const sheets = await listGoogleSpreadsheetSheets(spreadsheetId)

    this.setState({ spreadsheetId, sheets, sheetId: '' })
    handleApiSourceChange({}, [], [])
  }

  async handleSheetChange(sheet, accessToken, headers, mappings) {
    this.setState({
      sheetId: sheet.label
    })

    if (headers && headers.length > 0) {
      const { handleApiSourceChange } = this.props
      const { spreadsheetId } = this.state

      handleApiSourceChange(
        {
          source: GOOGLE_SHEET_IMPORT_SOURCE,
          accessToken,
          spreadsheetId,
          sheetId: sheet.label
        },
        headers,
        mappings
      )
    }
  }

  render() {
    const { spreadsheets, spreadsheetId, sheets, sheetId } = this.state

    return (
      <ApiSource
        channel={CHANNEL_GOOGLE}
        sourceName={GOOGLE_SHEET_IMPORT_SOURCE}
        scopes={GOOGLE_SHEETS_SCOPES}
        asyncAccountName='Spreadsheet'
        asyncAccounts={spreadsheets}
        asyncAccountId={spreadsheetId}
        accountName='Sheet'
        accounts={sheets}
        accountId={sheetId}
        handleOnScopeGranted={this.handleOnScopeGranted.bind(this)}
        handleAsyncAccountChange={this.handleSpreadsheetChange.bind(this)}
        handleAccountChange={this.handleSheetChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  userAccessToken: googleUserAccessTokenSelector(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listGoogleSpreadsheetSheets }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleSheetsSource)
)
