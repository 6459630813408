import snakeCaseKeys from 'snakecase-keys'
import { getAccessToken, getGoogleUser } from 'selectors/google/user'

export const SELECT_ANALYTICS_PROPERTY_REQUEST =
  'SELECT_ANALYTICS_PROPERTY_REQUEST'
export const SELECT_ANALYTICS_PROPERTY_RESPONSE =
  'SELECT_ANALYTICS_PROPERTY_RESPONSE'
export const SELECT_ANALYTICS_PROPERTY_ERROR = 'SELECT_ANALYTICS_PROPERTY_ERROR'
export const CREATE_ANALYTICS_PROPERTY_REQUEST =
  'CREATE_ANALYTICS_PROPERTY_REQUEST'
export const CREATE_ANALYTICS_PROPERTY_RESPONSE =
  'CREATE_ANALYTICS_PROPERTY_RESPONSE'

export const selectAnalyticsPropertyRequest = (websiteId, formValues) => ({
  type: SELECT_ANALYTICS_PROPERTY_REQUEST,
  websiteId,
  ...formValues
})

export const selectAnalyticsPropertyResponse = (
  websiteId,
  formValues,
  response
) => ({
  type: SELECT_ANALYTICS_PROPERTY_RESPONSE,
  websiteId,
  ...formValues
})

export const selectAnalyticsPropertyError = (error) => ({
  type: SELECT_ANALYTICS_PROPERTY_ERROR,
  error
})

const selectAnalyticsPropertyAjax = (
  websiteId,
  formValues,
  accessToken,
  email,
  refreshToken
) => {
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/google/analytics/accounts/select_property`,
    data: {
      ...snakeCaseKeys({ ...formValues, accessToken, websiteId, refreshToken, email })
    },
    dataType: 'json'
  })
}

export const selectAnalyticsProperty = (websiteId, formValues) => async (
  dispatch,
  getState
) => {
  dispatch(selectAnalyticsPropertyRequest(websiteId, formValues))
  try {
    const { refreshToken, email, accessToken } = getGoogleUser(getState)
    var response = await selectAnalyticsPropertyAjax(
      websiteId,
      formValues,
      accessToken,
      email,
      refreshToken
    )
    dispatch(selectAnalyticsPropertyResponse(websiteId, formValues, response))
  } catch (error) {
    dispatch(selectAnalyticsPropertyError(error.responseJSON.error))
    throw error
  }
}

export const createAnalyticsPropertyRequest = (
  analyticsAccountId,
  webPropertyName
) => ({
  type: CREATE_ANALYTICS_PROPERTY_REQUEST,
  analyticsAccountId,
  webPropertyName
})

export const createAnalyticsPropertyResponse = (
  analyticsAccountId,
  webPropertyName,
  response
) => ({
  type: CREATE_ANALYTICS_PROPERTY_RESPONSE,
  analyticsAccountId,
  webPropertyName,
  response
})

export const createAnalyticsProperty = (
  analyticsAccountId,
  webPropertyName
) => (dispatch, getState) => {
  const accessToken = getAccessToken(getState)
  dispatch(createAnalyticsPropertyRequest(analyticsAccountId, webPropertyName))
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/google/analytics/accounts/create_property`,
    data: {
      access_token: accessToken,
      account_id: analyticsAccountId,
      web_property_name: webPropertyName
    },
    dataType: 'json',
    success: (response) => {
      return dispatch(
        createAnalyticsPropertyResponse(
          analyticsAccountId,
          webPropertyName,
          response
        )
      )
    }
  })
}
