import camelCase from 'camelcase'
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

export const LOAD_PROMOTIONS_REQUEST  = 'LOAD_PROMOTIONS_REQUEST';
export const LOAD_PROMOTIONS_SUCCESS  = 'LOAD_PROMOTIONS_SUCCESS';
export const LOAD_PROMOTIONS_ERROR  = 'LOAD_PROMOTIONS_ERROR';


export const SAVE_PROMOTIONS_REQUEST  = 'SAVE_PROMOTIONS_REQUEST';
export const SAVE_PROMOTIONS_SUCCESS  = 'SAVE_PROMOTIONS_SUCCESS';
export const SAVE_PROMOTIONS_ERROR  = 'SAVE_PROMOTIONS_ERROR';

export const LOAD_PROMOTION_DETAIL_REQUEST = 'LOAD_PROMOTION_DETAIL_REQUEST';
export const LOAD_PROMOTION_DETAIL_SUCCESS = 'LOAD_PROMOTION_DETAIL_SUCCESS';
export const LOAD_PROMOTION_DETAIL_ERROR = 'LOAD_PROMOTION_DETAIL_ERROR';

export const LOAD_PROMOTION_FEED_URL_REQUEST = 'LOAD_PROMOTION_FEED_URL_REQUEST';
export const LOAD_PROMOTION_FEED_URL_SUCCESS = 'LOAD_PROMOTION_FEED_URL_SUCCESS';
export const LOAD_PROMOTION_FEED_URL_ERROR = 'LOAD_PROMOTION_FEED_URL_ERROR';


export const SET_SORT_COLUMN = 'SET_SORT_COLUMN'
export const SET_ITEM_SORT_COLUMN = 'SET_ITEM_SORT_COLUMN'

const loadPromotionFeedUrlRequest = () => ({
  type: LOAD_PROMOTION_FEED_URL_REQUEST
});

const loadPromotionFeedUrlSuccess = (response) => ({
  type: LOAD_PROMOTION_FEED_URL_SUCCESS,
  promotionFeedUrl: response['promotionFeedUrl']
});

const loadPromotionFeedUrlError = (response) => ({
  type: LOAD_PROMOTION_FEED_URL_ERROR,
  promotionFeedUrl: response['promotionFeedUrl']
});

export const fetchPromotionFeedUrl = (id, type = 'scenarios') => dispatch => {
  dispatch(loadPromotionFeedUrlRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/${type}/${id}/promotion_feed/url`,
    dataType: 'json',
    success:(response) => {
      return dispatch(loadPromotionFeedUrlSuccess(camelCaseKeys(response)));
    },
    error:(response) => {
      return dispatch(loadPromotionFeedUrlError(camelCaseKeys(response)));
    }
  })
};

const loadPromotionsRequest = () => ({
  type: LOAD_PROMOTIONS_REQUEST
});

const loadPromotionsSuccess = (response) => ({
  type: LOAD_PROMOTIONS_SUCCESS,
  list: response['inventoryPromotions'],
  activateNewPromotions: response['activateNewPromotions'],
  error: response['error']
});

const loadPromotionsError = (response) => ({
  type: LOAD_PROMOTIONS_ERROR,
  list: response['inventoryPromotions'],
  activateNewPromotions: response['activateNewPromotions'],
  error: response['error']
});

export const fetchPromotionsSettings = (id, type = 'scenarios') => dispatch => {
  dispatch(loadPromotionsRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/${type}/${id}/promotion_feed/edit`,
    dataType: 'json',
    success:(response) => {
      return dispatch(loadPromotionsSuccess(camelCaseKeys(response, {deep: true})));
    },
    error:(response) => {
      return dispatch(loadPromotionsError(camelCaseKeys(response, {deep: true})));
    }
  })
};

const loadPromotionDetailRequest = () => ({
  type: LOAD_PROMOTION_DETAIL_REQUEST
});

const loadPromotionDetailSuccess = (response) => ({
  type: LOAD_PROMOTION_DETAIL_SUCCESS,
  promotionDetail: response
});

export const fetchPromotionDetails = (id, promotionId, type = 'scenarios') => dispatch => {
  dispatch(loadPromotionDetailRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/${type}/${id}/promotion_feed/promotion_detail/${promotionId}`,
    dataType: 'json',
    success:(response) => {
      return dispatch(loadPromotionDetailSuccess(camelCaseKeys(response)));
    }
  })
};

const savePromotionsRequest = () => ({
  type: SAVE_PROMOTIONS_REQUEST
});

const savePromotionsSuccess = (promotions, error) => ({
  type: SAVE_PROMOTIONS_SUCCESS,
  error: error,
  list: promotions
});

const savePromotionsError = (promotions, error) => ({
  type: SAVE_PROMOTIONS_ERROR,
  error: error,
  list: promotions
});

export const savePromotionsSettings = (id, settings, type = 'scenarios') => dispatch => {
  var payload = { promotion_feed_settings: settings }
  dispatch(savePromotionsRequest())
  return $.ajax({
    method: 'PUT',
    url:  `/ad_champion/${type}/${id}/promotion_feed`,
    dataType: 'json',
    data: snakeCaseKeys(payload),
    success:(response) => {
      return dispatch(savePromotionsSuccess(settings.promotions, response['error']));
    },
    error:(response) => {
      return dispatch(savePromotionsError(camelCaseKeys(response)));
    }
  });
};

export const setSortColumn = (column) => ({
  type: SET_SORT_COLUMN,
  column: camelCase(column)
})

export const setItemSortColumn = (column) => ({
  type: SET_ITEM_SORT_COLUMN,
  column: camelCase(column)
})