import _ from 'lodash'
import changeCase from 'change-case'
import { filterTypeRequiresValue } from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'

export const RULE_CONDTIONS_NAME = 'Condition(s)'
export const RULE_ACTIONS_NAME = 'Action(s)'
export const SET_ATTRIBUTE_ACTION_NAME = 'set attribute'

export const getAttribute = (availableAttributes, verticalFieldId) => {
  return availableAttributes.find(
    (attribute) => attribute.id === verticalFieldId
  )
}

const getFilterValue = (id, attribute, filterType, value, metaData = {}) => {
  var filterTypeName = changeCase.lowerCase(
    filterType.displayName || filterType.name
  )
  return {
    id,
    attributeName: attribute.name,
    filterType: filterTypeName,
    values: _.isEmpty(value)
      ? calculateEmptyFilterValue(filterTypeName, value)
      : value.split('\n'),
    metaData
  }
}

export const calculateEmptyFilterValue = (filterTypeName, value) => {
  return filterTypeRequiresValue(filterTypeName) ? value : []
}

export const conditionsToFilters = (
  conditions,
  availableAttributes,
  operators
) => {
  const filters = conditions.map((condition) => {
    const { id, verticalFieldId, operatorId, value } = condition
    const attribute = getAttribute(availableAttributes, verticalFieldId)
    const operator = operators[operatorId]

    return getFilterValue(id, attribute, operator, value)
  })

  return filters
}

export const actionsToFilters = (actions, availableAttributes, actionTypes) => {
  const filters = actions.map((action) => {
    const { id, verticalFieldId, actionTypeId, value, replaceManual } = action
    const attribute = getAttribute(availableAttributes, verticalFieldId)
    const actionType = actionTypes[actionTypeId]
    const metaData = { replaceManual }
    return getFilterValue(id, attribute, actionType, value, metaData)
  })

  return filters
}

const getVerticalFieldId = (availableAttributes, attributeName) => {
  return Object.values(availableAttributes).find(
    (attribute) => attribute.name === attributeName
  ).id
}

const getOperatorOrActionTypes = (values, filterType) => {
  return Object.values(values).find(
    (value) =>
      changeCase.lowerCase(value.displayName || value.name) ===
      changeCase.lowerCase(filterType)
  )
}

const getExistingValue = (values, id) => {
  return values.find((value) => value.id === id) || {}
}

const filtersToValues = (params) => {
  const { valueFor, ruleValues, filters, availableAttributes, filterTypes } =
    params
  if (_.isEmpty(availableAttributes) || _.isEmpty(filterTypes)) {
    return []
  }
  var updatedValues = []
  filters.forEach((filter) => {
    const { id, attributeName, filterType, values, metaData } = filter
    const verticalFieldId = getVerticalFieldId(
      availableAttributes,
      attributeName
    )
    const type = getOperatorOrActionTypes(filterTypes, filterType)
    const ruleValue = getExistingValue(ruleValues, id || -1)

    var newValue = {
      ...ruleValue,
      verticalFieldId: verticalFieldId,
      value: values.join('\n')
    }

    if (valueFor === RULE_CONDTIONS_NAME) {
      newValue = { ...newValue, operatorId: type.id }
    } else {
      const { replaceManual } = metaData
      newValue = { ...newValue, actionTypeId: type.id, replaceManual }
    }

    updatedValues.push(newValue)
  })

  return updatedValues
}

export const filtersToConditions = (
  conditions,
  filters,
  availableAttributes,
  operators
) => {
  return filtersToValues({
    valueFor: RULE_CONDTIONS_NAME,
    ruleValues: conditions,
    filters,
    availableAttributes,
    filterTypes: operators
  })
}

export const filtersToActions = (
  actions,
  filters,
  availableAttributes,
  actionTypes
) => {
  return filtersToValues({
    valueFor: RULE_ACTIONS_NAME,
    ruleValues: actions,
    filters,
    availableAttributes,
    filterTypes: actionTypes
  })
}

export const getActionFilterText = (
  attributeName,
  filterType,
  values,
  availableAttributes
) => {
  var filterText = `${changeCase.sentenceCase(filterType)} ${attributeName}`
  if (values && values.length > 0 && values[0] !== '') {
    filterText += ` to ${values.join(', ')}`

    if (filterText.includes('{') && filterText.includes('}')) {
      filterText = filterText.replace(
        /\{([^}]+)\}/g,
        (match, attributeName) => {
          const attribute = availableAttributes.find(
            (attr) => attr.name.replace(' ', '') === attributeName
          )
          return attribute ? `{${attribute.columnName}}` : match
        }
      )
    }
  }

  return filterText
}
