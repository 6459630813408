export const SET_DATE_RANGE = 'SET_DATE_RANGE'

const setDateRangeAction = (keyName, dateRange) => ({
  type: SET_DATE_RANGE,
  keyName,
  dateRange
})

export const setDateRange = (keyName, dateRange) => async dispatch => {
  dispatch(setDateRangeAction(keyName, dateRange))
}
