export const SAVE_AD_HEADLINE_LIST_ITEMS_SUCCESS  = 'SAVE_AD_HEADLINE_LIST_ITEMS_SUCCESS';
export const SAVE_AD_HEADLINE_LIST_ITEMS_ERROR    = 'SAVE_AD_HEADLINE_LIST_ITEMS_ERROR';
export const LOAD_AD_HEADLINE_LIST_ITEMS_SUCCESS  = 'LOAD_AD_HEADLINE_LIST_ITEMS_SUCCESS';
export const LOAD_AD_HEADLINE_LIST_ITEMS_ERROR    = 'LOAD_AD_HEADLINE_LIST_ITEMS_ERROR';

const updateAllAdHeadlineListItems = (items) => ({
  type: LOAD_AD_HEADLINE_LIST_ITEMS_SUCCESS,
  items: items
});

export const loadAdHeadlineListItems = scenarioId => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/ad_headline_list/edit`,
    dataType: 'json',
    success: (response) => {
      return dispatch(updateAllAdHeadlineListItems(response.items));
    }
  });
};

export const saveAdHeadlineListItems = (scenarioId, items) => dispatch => {
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/ad_headline_list`,
    data: {items: JSON.stringify(items)},
    dataType: 'json',
    success: (response) => {
      return dispatch(saveAdHeadlineListItemsSuccess(items));
    },
    error: (response) => {
      return dispatch(ajaxErrorMessage('save'));
    }
  });
};


const ajaxErrorMessage = (messageType) => ({
  type: messageType === 'save' ? SAVE_AD_HEADLINE_LIST_ITEMS_ERROR : LOAD_AD_HEADLINE_LIST_ITEMS_ERROR,
  errorMessage: `A connection error has occurred while trying to ${messageType} the ad headline list items.`
});

const saveAdHeadlineListItemsSuccess = (items) => ({
  type: SAVE_AD_HEADLINE_LIST_ITEMS_SUCCESS,
  items: items
});
