import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getLogoByChannel, CHANNEL_ALL } from 'util/supported_channels'

export default class ChannelLogos extends Component {
  static propTypes = {
    item: PropTypes.shape({
      title: PropTypes.string,
      channel: PropTypes.string
    }).isRequired
  }

  renderLogos(logos, title) {
    return logos
      .reverse()
      .map((logo, index) => <img key={title + index} src={logo} alt={title} />)
  }

  render() {
    const { item } = this.props
    const { title, channel } = item
    const imgKey = title + channel
    const logos = getLogoByChannel(channel)

    if (channel === CHANNEL_ALL) {
      return <>{this.renderLogos(logos, title)}</>
    }

    return <img key={imgKey} src={logos} alt={title} />
  }
}
