import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { withRouter } from 'react-router'
import { MDBCard, MDBCardBody, MDBCardText, MDBCardFooter } from 'mdbreact'
import GaugeChart from 'react-gauge-chart'
import { channelOptimisationScoreSelector } from 'selectors/playbook/playbook_progress'
import { PLAYBOOK_CHANNELS, CHANNEL_ALL } from 'util/supported_channels'

export class OptimisationScoreCard extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    optimisationScore: PropTypes.number.isRequired
  }

  renderAllChannelOptimisation() {
    const { channel, optimisationScore } = this.props
    return channel === CHANNEL_ALL ? (
      <span style={{ display: 'none' }} id='all-channel-optimisation-score'>
        {optimisationScore}
      </span>
    ) : null
  }

  renderCharts() {
    const { optimisationScore } = this.props

    return (
      <ReactResizeDetector handleWidth handleHeight>
        <GaugeChart
          className='chart'
          colors={['#FF5F6D', '#00C300']}
          textColor='#000000'
          needleColor='#999999'
          nrOfLevels={20}
          percent={parseFloat((optimisationScore / 100).toFixed(3), 10)}
          animate={false}
        />
      </ReactResizeDetector>
    )
  }

  render() {
    return (
      <MDBCard className='channel-summary'>
        <MDBCardBody>
          {this.renderAllChannelOptimisation()}
          {this.renderCharts()}
        </MDBCardBody>
        <MDBCardFooter>
          <MDBCardText className='text-center'>Optimization Score</MDBCardText>
        </MDBCardFooter>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => ({
  optimisationScore: channelOptimisationScoreSelector(props.channel)(
    state,
    props
  )
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OptimisationScoreCard)
)
