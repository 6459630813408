import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import camelCase from 'camel-case'
import { Nav, NavItem, NavLink } from 'reactstrap'
import MaterialTable from '@material-table/core'
import ColumnContent from './column_content'
import { websiteRulesSelector } from 'selectors/inventories/rules'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import {
  generatePreviewTableOptions,
  generatePreviewTableLocalization
} from 'components/feed_ops/website/rules_engine/overview_util'

import './preview_tabs_container.scss'
export const MODIFIED_DATA_TAB = 'modifiedData'
export const ORIGINAL_DATA_TAB = 'originalData'

export class PreviewTabsContainer extends Component {
  tableRef = React.createRef()

  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    websiteRules: PropTypes.object.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      windowHeight: 0,
      activeTab: MODIFIED_DATA_TAB
    }
  }

  handleWindowResize() {
    this.setState({ windowHeight: window.innerHeight })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    this.handleWindowResize()
  }

  componentDidUpdate(prevProps) {
    const { websiteRules } = this.props
    if (!_.isEqual(websiteRules, prevProps.websiteRules)) {
      this.tableRef.current && this.tableRef.current.onQueryChange()
    }
  }

  handleNavItemClick(tabId) {
    this.setState({ activeTab: tabId })
    this.tableRef.current && this.tableRef.current.onQueryChange()
  }

  renderNavItem(tabId, tabName) {
    const { activeTab } = this.state

    return (
      <NavItem>
        <NavLink
          to='#'
          role='link'
          active={activeTab === tabId}
          className={activeTab === tabId ? 'active-tab' : ''}
          onClick={() => this.handleNavItemClick(tabId)}
        >
          {tabName}
        </NavLink>
      </NavItem>
    )
  }

  renderNav() {
    return (
      <Nav tabs>
        {this.renderNavItem(MODIFIED_DATA_TAB, 'Modified Data')}
        {this.renderNavItem(ORIGINAL_DATA_TAB, 'Original Data')}
      </Nav>
    )
  }

  async previewRulesData(query) {
    const { activeTab } = this.state
    const { websiteRules } = this.props
    const { previewData } = websiteRules

    var updatedData = []
    if (previewData && previewData.length > 0) {
      updatedData = [...previewData].map((data) => ({
        ...data,
        showRaw: activeTab === ORIGINAL_DATA_TAB
      }))
    }

    const tableData = { data: updatedData }
    return tableData
  }

  getColumnNameByAttributeName(attributeName) {
    const { attributes } = this.props
    const attribute = attributes.find(
      (attr) => camelCase(attr.name) === attributeName
    )

    return attribute.columnName
  }

  getDisplayColumns() {
    const { websiteRules } = this.props
    const { previewData } = websiteRules
    const attributeNames = Object.keys(
      (previewData && previewData[0]) || {}
    ).filter((name) => name !== 'itemId')
    var displayedColumnsData = []

    attributeNames.forEach((attributeName) => {
      const columnName = this.getColumnNameByAttributeName(attributeName)
      displayedColumnsData.push({
        title: columnName,
        field: attributeName,
        sorting: false,
        render: (rowData) => (
          <ColumnContent rowData={rowData} attributeName={attributeName} />
        )
      })
    })

    return displayedColumnsData
  }

  renderPreviewTable() {
    const { isFetching } = this.props
    const { windowHeight } = this.state

    return (
      <MaterialTable
        tableRef={this.tableRef}
        data={this.previewRulesData.bind(this)}
        columns={this.getDisplayColumns()}
        actions={[]}
        isLoading={isFetching}
        options={generatePreviewTableOptions(windowHeight)}
        localization={generatePreviewTableLocalization()}
      />
    )
  }

  render() {
    return (
      <div className='preview-tabs-container'>
        {this.renderNav()}
        {this.renderPreviewTable()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteRules: websiteRulesSelector(state, props),
  attributes: websiteAllAttributesSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewTabsContainer)
)
