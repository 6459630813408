import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { remoteSubmitAndWait } from 'actions/forms'
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import SourceImportContainer from '../import_product_data/source_import_container'
import AdChampionIndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import FooterActions from '../bulk_editor/footer_actions'
import { ONE_TIME_PRODUCT_DATA_IMPORT } from './table_actions_util'
import { REDUX_FORM_NAME as ATTRIBUTES_MAPPING_FORM } from '../import_product_data/attributes_mapping'

export class ProductOptimiserModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    headerText: PropTypes.string.isRequired,
    bodyComponent: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    onModalStateChange: PropTypes.func.isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired
  }

  static defaultProps = {
    isOpen: false
  }

  constructor(props) {
    super(props)
    this.state = {
      saveToAllChannels: false,
      isSaving: false,
      isSaveDisabled: true
    }
  }

  handleValidation(isValid) {
    this.setState({ isSaveDisabled: !isValid })
  }

  conditionallyRenderBodyComponent() {
    const { isSaving } = this.state
    const { bodyComponent } = this.props

    var component = null
    switch (bodyComponent) {
      case ONE_TIME_PRODUCT_DATA_IMPORT:
        component = (
          <SourceImportContainer
            handleIsValidChange={this.handleValidation.bind(this)}
          />
        )
        break
      default:
        break
    }

    return (
      <>
        {component}
        {isSaving && <AdChampionIndeterminateProgressIndicator />}
      </>
    )
  }

  computeReduxFormName() {
    const { bodyComponent } = this.props

    switch (bodyComponent) {
      case ONE_TIME_PRODUCT_DATA_IMPORT:
        return ATTRIBUTES_MAPPING_FORM
      default:
        return ''
    }
  }

  handleSaveToAllChannels() {
    const { change } = this.props
    const { saveToAllChannels } = this.state

    this.setState({ saveToAllChannels: !saveToAllChannels })
    change(this.computeReduxFormName(), 'saveToAllChannels', !saveToAllChannels)
  }

  async handleSave() {
    const {
      remoteSubmitAndWait,
      onSaveProductAttributesReplacements,
      onModalStateChange
    } = this.props

    this.setState({ isSaving: true })
    await remoteSubmitAndWait(this.computeReduxFormName())
    await onSaveProductAttributesReplacements()
    await onModalStateChange()
    this.setState({ isSaving: false })
  }

  renderFooterActions() {
    const { saveToAllChannels, isSaving, isSaveDisabled } = this.state

    return (
      <FooterActions
        saveToAllChannels={saveToAllChannels}
        isSaving={isSaving}
        isSaveDisabled={isSaveDisabled}
        reduxFormName={this.computeReduxFormName()}
        onHandleSaveToAllChannels={this.handleSaveToAllChannels.bind(this)}
        onHandleCancel={() => this.props.onModalStateChange()}
        onHandleSave={this.handleSave.bind(this)}
      />
    )
  }

  render() {
    const { isOpen, headerText } = this.props

    return (
      <MDBModal
        isOpen={isOpen}
        backdrop={false}
        fullHeight
        position='right'
        size='lg'
        className='optimiser-modal'
      >
        <MDBModalHeader>{headerText}</MDBModalHeader>
        <MDBModalBody>{this.conditionallyRenderBodyComponent()}</MDBModalBody>
        {this.renderFooterActions()}
      </MDBModal>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change, remoteSubmitAndWait }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductOptimiserModal)
)
