import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBSelect } from 'mdbreact'
import { FormGroup, Label } from 'reactstrap'
import { Field } from 'redux-form'
import InputError from './input_error'

export default class SelectInputGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    cssClass: PropTypes.string
  }

  onValueChange(value) {
    const {
      onValueChange
    } = this.props
    onValueChange(value[0])
  }

  renderInput({ input, label, meta: { touched, error }, ...custom }) {
    const {
      name,
      options,
      value,
      disabled,
      placeholder
    } = this.props
    return (
      <div>
        <MDBSelect
          name={name}
          selected={value}
          options={options}
          disabled={disabled}
          getValue={this.onValueChange.bind(this)}
          placeholder={placeholder ? placeholder : 'Select...'}
          search={options.length > 10}
          {...custom}
        />
        {touched && <InputError error={error} />}
      </div>
    )
  }

  render() {
    const { name, label, cssClass } = this.props
    return (
      <FormGroup
        className={cssClass ? cssClass : 'md-form'}
        onBlur={this.props.handleOnBlur}
      >
        {label && (
          <Label for={name} className='active'>
            {label}
          </Label>
        )}
        <Field
          id={name}
          name={name}
          component={this.renderInput.bind(this)}
          disabled={this.props.disabled}
        />
      </FormGroup>
    )
  }
}
