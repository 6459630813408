import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBTooltip } from 'mdbreact'
import { bindActionCreators } from 'redux'
import { dismissPlaybookProgress } from 'actions/playbook/playbook_progress'
import { websiteIdSelector } from 'selectors/websites'
import { appIdSelector } from 'selectors/app'
import { commonPropTypes } from './propTypes'
import { Button } from 'reactstrap'
export class ReviewButton extends Component {
  static propTypes = commonPropTypes

  static defaultProps = {
    showIcon: true,
    outline: false
  }

  replaceLinkVariables(destination) {
    const { appId, websiteId } = this.props
    return destination
      .replace(/:appId/g, appId)
      .replace(/:websiteId/g, websiteId)
  }

  pushPath(destination) {
    const { location } = this.props
    var path = this.replaceLinkVariables(destination)
    this.props.history.push(path, { prevPath: location.pathname })
  }

  genButtonProps() {
    const { destination, enabled } = this.props
    const isAbsolutePath =
      destination.startsWith('http://') || destination.startsWith('https://')
    return isAbsolutePath
      ? { href: destination, target: '_blank' }
      : {
          onClick: enabled ? this.pushPath.bind(this, destination) : () => {}
        }
  }

  renderButton() {
    const { id, label } = this.props
    const btnProps = this.genButtonProps()

    return (
      <Button
        color='primary'
        className='primary-btn'
        key={`${id}-action`}
        {...btnProps}
      >
        {label}
      </Button>
    )
  }

  render() {
    const { tooltip, enabled } = this.props

    if (!enabled) {
      return (
        <>
          <MDBTooltip placement='right' material>
            {this.renderButton()}
            <p> {tooltip} </p>
          </MDBTooltip>
        </>
      )
    }

    return <>{this.renderButton()}</>
  }
}

export const mapStateToProps = (state, props) => {
  return {
    appId: appIdSelector(state, props),
    websiteId: websiteIdSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dismissPlaybookProgress }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReviewButton)
)
