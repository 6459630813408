import { store } from 'containers/ad_platform_context'
import { clearRequestQueue } from 'actions/app'
import toastr from 'toastr'

export const clearRequestQueueAndLog = (url, message) => {
  if (process.env.NODE_ENV !== 'production') {
    global.toastr.error(
      '[Debug] Accessing ' +
        url +
        ' - ' +
        `${message || 'An Unhandled Error Occurred.'}`
    )
  }
  store.dispatch(clearRequestQueue())
  return false
}

export const register = () => {
  global.toastr = global.toastr || toastr

  $(document).ajaxError((_event, request, settings) => {
    clearRequestQueueAndLog(
      settings.url,
      request.responseJSON && request.responseJSON.error
    )
  })
}
