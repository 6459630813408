import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MDBBtn, MDBIcon } from 'mdbreact'

export class BackButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    float: PropTypes.string.isRequired
  }

  render() {
    const { label, float } = this.props
    const btnProps = {
      onClick: () => {
        this.props.history.goBack()
      }
    }

    return (
      <MDBBtn className={`btn-card-nav float-${float}`} {...btnProps}>
        <MDBIcon icon='arrow-left' /> {label}
      </MDBBtn>
    )
  }
}

export default withRouter(BackButton)
