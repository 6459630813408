import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getFormValues, isValid, isSubmitting } from 'redux-form'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardTitle,
  MDBBtn,
  MDBIcon
} from 'mdbreact'
import { remoteSubmitAndWait } from 'actions/forms'
import { appIdSelector } from 'selectors/app'
import { superUserSelector } from 'selectors/user'
import FeedForm, {
  REDUX_FORM_NAME
} from 'components/inventory/feed_app/feed_form'
import GenericContent from 'components/websites/workflow/generic_content'
import CustomerHelper from 'components/customer_helper/customer_helper'
import { getPlatformHelpDoc } from 'util/supported_platforms'

export class FeedOpsWebsiteNewDesktop extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    formValid: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired
  }

  async submitForm() {
    const { remoteSubmitAndWait, history } = this.props
    await remoteSubmitAndWait(REDUX_FORM_NAME)

    const { websiteId, appId } = this.props
    history.push(`/${appId}/${websiteId}/website/initial_reconciling`)
  }

  renderConnectButton() {
    const { formValid, isSubmitting } = this.props
    const buttonText = isSubmitting ? (
      <div>
        <MDBIcon spin fixed icon='spinner' />
        Connecting
      </div>
    ) : (
      'Connect'
    )
    return (
      <div className={'connection-button'}>
        <MDBBtn
          color='primary'
          className={'next-action'}
          onClick={this.submitForm.bind(this)}
          disabled={!formValid || isSubmitting}
        >
          {buttonText}
        </MDBBtn>
        {isSubmitting && 'This may take a few minutes.'}
      </div>
    )
  }

  renderFeedForm() {
    const { selectedPlatform } = this.props

    return (
      <div>
        <FeedForm showAdvanced={false} />
        {selectedPlatform && this.renderConnectButton()}
      </div>
    )
  }

  suggestArticle() {
    const { selectedPlatform } = this.props
    return getPlatformHelpDoc(selectedPlatform)
  }

  renderFooter() {
    return (
      <MDBCardFooter>
        <CustomerHelper
          helperName='Not sure what to do?'
          articleId={this.suggestArticle()}
          bookAMeetingType={'feedops'}
        />
      </MDBCardFooter>
    )
  }

  render() {
    return (
      <div className='main-content'>
        <MDBRow className='options-row'>
          <MDBCol />
          <MDBCol className='col-10'>
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Connect your store to FeedOps</MDBCardTitle>
                <div className='content'>
                  <GenericContent component={this.renderFeedForm()} />
                </div>
              </MDBCardBody>
              {this.renderFooter()}
            </MDBCard>
          </MDBCol>
          <MDBCol />
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const { selectedPlatform, websiteId } = getFormValues(REDUX_FORM_NAME)(
    state
  ) || { websiteId: 0 }
  return {
    selectedPlatform,
    isSuperUser: superUserSelector(state, props),
    appId: appIdSelector(state, props),
    websiteId: websiteId,
    formValid: isValid(REDUX_FORM_NAME)(state),
    isSubmitting: isSubmitting(REDUX_FORM_NAME)(state)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ remoteSubmitAndWait }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsWebsiteNewDesktop)
)
