import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { dismissPlaybookProgress } from 'actions/playbook/playbook_progress'
import { websiteIdSelector } from 'selectors/websites'
import { appIdSelector } from 'selectors/app'
import { commonPropTypes } from './propTypes'

export class Dismiss extends Component {
  static propTypes = commonPropTypes
  static defaultProps = {
    showIcon: true,
    outline: false
  }

  async handleDismissButtonClick() {
    const { dismissPlaybookProgress, websiteId, id } = this.props
    await dismissPlaybookProgress(websiteId, id)
  }

  renderDismissButton() {
    const { isDismissed, id } = this.props
    return (
      !isDismissed && (
        <span
          key={`${id}-dismiss`}
          onClick={this.handleDismissButtonClick.bind(this)}
        >
          Dismiss
        </span>
      )
    )
  }

  render() {
    return <>{this.renderDismissButton()}</>
  }
}

export const mapStateToProps = (state, props) => {
  return {
    appId: appIdSelector(state, props),
    websiteId: websiteIdSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dismissPlaybookProgress }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dismiss))
