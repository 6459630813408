import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Redirect } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import ConnectedChannelsMobileContainer from 'components/feed_ops/website/connected_channels/mobile_container'
import ConnectMerchantCenterAccount from '../connect/merchant_center_account'
import FeedSettingsMobileContainer from 'components/feed_ops/feed_settings/mobile_container'
import ConnectAdsAccount from '../connect/ads_account'

export class FeedOpsWebsiteProductFeedsMobile extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  computePath(featureName) {
    const { location, match } = this.props
    const { url } = match
    return `${url}/${featureName}${location.search}`
  }

  renderRedirectToDefaultRoute() {
    return <Redirect to={this.computePath('connected_channels')} />
  }

  render() {
    const { match } = this.props

    return (
      <div>
        <Switch>
          <GuardedRoute
            path={`${match.path}/connected_channels`}
            component={ConnectedChannelsMobileContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/connect_merchant_center_account`}
            component={ConnectMerchantCenterAccount}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/connect_ads_account`}
            component={ConnectAdsAccount}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path={`${match.path}/feed_settings`}
            component={FeedSettingsMobileContainer}
            error={this.renderRedirectToDefaultRoute.bind(this)}
          />
          <GuardedRoute
            path='*'
            render={this.renderRedirectToDefaultRoute.bind(this)}
          />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedOpsWebsiteProductFeedsMobile)
