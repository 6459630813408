import React, { Component } from 'react'
import PropTypes from 'prop-types'
import storeConnectLogo from 'images/feed_ops/platforms/store-connect.png'
import bigCommerceLogo from 'images/feed_ops/platforms/bigcommerce.png'
import googleSheetLogo from 'images/feed_ops/platforms/google-sheets.png'
import lightspeedLogo from 'images/feed_ops/platforms/lightspeed.png'
import magentoLogo from 'images/feed_ops/platforms/magento.png'
import netoLogo from 'images/feed_ops/platforms/neto.png'
import nopCommerceLogo from 'images/feed_ops/platforms/nopcommerce.png'
import octoberCmsLogo from 'images/feed_ops/platforms/october-cms.png'
import openCartLogo from 'images/feed_ops/platforms/opencart.png'
import prestashopLogo from 'images/feed_ops/platforms/prestashop.png'
import salesforceLogo from 'images/feed_ops/platforms/salesforce.png'
import shopifyLogo from 'images/feed_ops/platforms/shopify.png'
import shopwareLogo from 'images/feed_ops/platforms/shopware.png'
import wixLogo from 'images/feed_ops/platforms/wix.png'
import wooCommerceLogo from 'images/feed_ops/platforms/woocommerce.png'
import googleShoppingFeedImage from 'images/feed_ops/google/google-merchant-center.png'
import otherLogo from 'images/feed_ops/platforms/other.png'

const platformIcons = {
  none: storeConnectLogo,
  big_commerce: bigCommerceLogo,
  google_sheet: googleSheetLogo,
  magento_v1: magentoLogo,
  magento_v2: magentoLogo,
  neto: netoLogo,
  open_cart: openCartLogo,
  salesforce: salesforceLogo,
  shopify: shopifyLogo,
  woo_commerce: wooCommerceLogo,
  wix: wixLogo,
  google_shopping_feed: googleShoppingFeedImage,
  other: otherLogo
}

export default class PlatformIcon extends Component {
  static propTypes = {
    platformName: PropTypes.string.isRequired,
    width: PropTypes.string
  }

  render() {
    const { platformName, width } = this.props
    return (
      <img
        src={platformIcons[platformName] || platformIcons['none']}
        alt={platformName}
        width={width}
      />
    )
  }
}
