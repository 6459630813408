import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { inventoryFeedOpsCategoryHierarchySelector } from 'selectors/inventory'

export const FETCH_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST =
  'FETCH_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST'
export const FETCH_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE =
  'FETCH_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE'
export const SAVE_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST =
  'SAVE_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST'
export const SAVE_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE =
  'SAVE_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE'

const fetchInventoryCategoryReplacementsRequest = (websiteId) => ({
  type: FETCH_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST,
  websiteId
})

const fetchInventoryCategoryReplacementsResponse = (websiteId, response) => ({
  type: FETCH_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE,
  websiteId,
  ...response
})

const getReplacementsApi = async (websiteId, data) => {
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/replacements/category_replacements`,
    data,
    dataType: 'json'
  })
  return camelCaseKeys(response, { deep: true })
}

const handleReplacementsResponse = (allReplacements, response, batch) => {
  allReplacements.push(
    response.replacements.map((r) => ({
      ...r,
      fcId: batch.find((c) => c[0] === r.id)[1]
    }))
  )
}

const categoriesInDepth = (hierarchyLevel) => {
  return Object.values(hierarchyLevel || {}).map((c) => [c.dcId, c.id])
}

export const fetchInventoryCategoryReplacements =
  (websiteId) => async (dispatch, getState) => {
    dispatch(fetchInventoryCategoryReplacementsRequest(websiteId))
    var allReplacements = []
    var perPage = 50
    var depth = 1
    var hierarchy = inventoryFeedOpsCategoryHierarchySelector(getState())
    var categoryIds = categoriesInDepth(hierarchy[depth])
    do {
      var batch = categoryIds.splice(0, perPage)
      var data = snakeCaseKeys({
        depth,
        categoryIds: batch.map((c) => c[0])
      })
      var response = await getReplacementsApi(websiteId, data)
      handleReplacementsResponse(allReplacements, response, batch)
      if (categoryIds.length === 0) {
        depth += 1
        categoryIds = categoriesInDepth(hierarchy[depth])
      }
    } while (categoryIds.length > 0 || depth < hierarchy.length)
    var replacements = { replacements: allReplacements.flat() }

    dispatch(
      fetchInventoryCategoryReplacementsResponse(websiteId, replacements)
    )
    return replacements
  }

const saveInventoryCategoryReplacementsRequest = (websiteId, replacements) => ({
  type: SAVE_INVENTORY_CATEGORY_REPLACEMENTS_REQUEST,
  websiteId,
  ...replacements
})

const saveInventoryCategoryReplacementsResponse = (
  websiteId,
  replacements,
  response
) => ({
  type: SAVE_INVENTORY_CATEGORY_REPLACEMENTS_RESPONSE,
  websiteId,
  ...replacements,
  response
})

const generateObject = (content) => {
  var generatedObject = content.reduce((out, contentsAtDepth, depth) => {
    if (Object.entries(contentsAtDepth).length > 0) {
      out[depth] = contentsAtDepth
    }
    return out
  }, {})

  return generatedObject
}

const generateApiData = (replacements, categories) => {
  var replacementObject = generateObject(replacements)
  return {
    replacements: JSON.stringify(replacementObject),
    categories: JSON.stringify(categories)
  }
}

export const saveInventoryCategoryReplacements =
  (websiteId, replacements, categories) => async (dispatch) => {
    dispatch(saveInventoryCategoryReplacementsRequest(websiteId, replacements))

    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/websites/${websiteId}/replacements/save_category_replacements`,
      data: generateApiData(replacements, categories),
      dataType: 'json'
    })

    dispatch(
      saveInventoryCategoryReplacementsResponse(
        websiteId,
        replacements,
        response
      )
    )
    return response
  }
