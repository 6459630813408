import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

export default class AttributeDatePicker extends Component {
  static propTypes = {
    filterValue: PropTypes.string.isRequired,
    dateValueChanged: PropTypes.func.isRequired
  }

  handleChangeDate(updatedDate) {
    const { dateValueChanged } = this.props
    dateValueChanged(updatedDate.toDateString())
  }

  render() {
    const { filterValue } = this.props
    const dateValue = !_.isEmpty(filterValue)
      ? new Date(filterValue)
      : new Date()

    return (
      <Calendar value={dateValue} onChange={this.handleChangeDate.bind(this)} />
    )
  }
}
