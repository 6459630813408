import moment from 'moment'

const timeAgoInWords = (timeStamp, format = 'YYYY-MM-DD hh:mm:ss ZZ') => {
  if (!timeStamp) {
    return 'Never'
  }

  return moment(timeStamp, format).fromNow()
}

export default timeAgoInWords
