import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label } from 'reactstrap'
import AutosizeInput from 'react-input-autosize'
import { renderError } from 'components/ad_champion/common/text_input_group'

export default class ShopifyDomain extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
  }

  render() {
    const {
      input,
      meta: { touched, error }
    } = this.props
    return (
      <FormGroup className='md-form shopify-domain'>
        <AutosizeInput name={input.name} minWidth='20' {...input} />
        <Label htmlFor={input.name} className='active'>
          Shopify Domain
          <span className='required-field'> * </span>
        </Label>
        <span>.myshopify.com</span>
        {renderError(touched, error)}
      </FormGroup>
    )
  }
}
