import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import { bindActionCreators } from 'redux'
import UsersOverview from './account/users/overview'
import AddUser from './account/users/add_user'

export class FeedOpsAccount extends Component {
  renderUserManagementRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/users_overview`}
        component={UsersOverview}
      />
    )
  }

  renderAddUserRoute(match) {
    return <GuardedRoute path={`${match.path}/add_user`} component={AddUser} />
  }

  render() {
    const { match } = this.props

    return (
      <Switch>
        {this.renderUserManagementRoute(match)}
        {this.renderAddUserRoute(match)}
      </Switch>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsAccount)
)
