import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MDBSelect } from 'mdbreact'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, registerField } from 'redux-form'
import { remoteFormSubmissionHandlers } from 'actions/forms'

// https://support.google.com/merchants/answer/160637?hl=en
const SUPPORTED_LANGUAGES = [
  { name: 'Arabic', code: 'AR' },
  { name: 'Brazilian Portuguese', code: 'PT' },
  { name: 'Chinese', code: 'ZH' },
  { name: 'Czech', code: 'CS' },
  { name: 'Danish', code: 'DA' },
  { name: 'Dutch', code: 'NL' },
  { name: 'English', code: 'EN' },
  { name: 'Finnish', code: 'FI' },
  { name: 'French', code: 'FR' },
  { name: 'German', code: 'DE' },
  { name: 'Hebrew', code: 'HE' },
  { name: 'Hungarian', code: 'HU' },
  { name: 'Indonesian', code: 'ID' },
  { name: 'Italian', code: 'IT' },
  { name: 'Japanese', code: 'JA' },
  { name: 'Korean', code: 'KO' },
  { name: 'Modern Greek', code: 'EL' },
  { name: 'Norwegian', code: 'NO' },
  { name: 'Polish', code: 'PL' },
  { name: 'Portuguese', code: 'PT' },
  { name: 'Romanian', code: 'RO' },
  { name: 'Russian', code: 'RU' },
  { name: 'Slovak', code: 'SK' },
  { name: 'Spanish', code: 'ES' },
  { name: 'Swedish', code: 'SV' },
  { name: 'Thai', code: 'TH' },
  { name: 'Traditional Chinese', code: 'ZH' },
  { name: 'Turkish', code: 'TR' },
  { name: 'Ukrainian', code: 'UK' },
  { name: 'Vietnamese', code: 'VI' }
]

export class LanguageSelector extends Component {
  componentDidMount() {
    const { registerField, initialize } = this.props
    registerField(REDUX_FORM_NAME, 'language', 'Field')
    initialize({ language: undefined })
  }

  languageOptions() {
    var options = SUPPORTED_LANGUAGES.map((c) => ({
      text: c.name,
      value: c.code
    }))
    options.sort((a, b) => a.text.localeCompare(b.text))
    return options
  }

  handleChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      change('language', e[0])
    }
  }

  render() {
    const { formValues } = this.props
    const { language } = formValues
    const languageOptions = this.languageOptions()
    var selectedLanguageOption =
      languageOptions.find((option) => option.value === language) || {}
    return (
      <div className={'language-container'}>
        <MDBSelect
          search
          options={languageOptions}
          className={'language-selector'}
          selected={selectedLanguageOption.text || 'Select language'}
          getValue={this.handleChange.bind(this)}
        />
        <p>
          The language of the items in the feed. You can change your feed
          language at any time by changing your settings in Merchant Center.
        </p>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ registerField }, dispatch)

export const LanguageSelectorWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector)
export const REDUX_FORM_NAME = 'LanguageSelector'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(LanguageSelectorWithRedux)
  )
)
