import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  Form,
  reduxForm,
  getFormValues,
  registerField,
  isValid
} from 'redux-form'
import { MDBInput, MDBBtn, MDBSelect } from 'mdbreact'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  listMerchantCenterAccounts,
  selectMerchantCenterAccount,
  createMerchantCenterAccount
} from 'actions/google/merchant_center'
import { fetchWebsiteDetail } from 'actions/websites'
import GenericContent from 'components/websites/workflow/generic_content'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import GoogleUserContext from 'components/google/google_user_context'
import Switch from 'components/ad_champion/common/switch'
import { createValidator } from 'components/util/validation'
import {
  merchantCenterAccountIdSelector,
  merchantCenterAccountNameSelector,
  createMerchantCenterAccountSelector
} from 'selectors/google/accounts'
import { websiteIdSelector } from 'selectors/websites'

export class MerchantCenterForm extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    listMerchantCenterAccounts: PropTypes.func.isRequired,
    selectMerchantCenterAccount: PropTypes.func.isRequired,
    createMerchantCenterAccount: PropTypes.func.isRequired,
    merchantCenterAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    initialize: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    merchantCenterAccountId: PropTypes.string.isRequired,
    merchantCenterAccountName: PropTypes.string.isRequired,
    useExistingAccount: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { editing: false, busy: false, radio: '' }
  }

  componentDidMount() {
    const { websiteId, registerField } = this.props
    registerField(REDUX_FORM_NAME, 'merchantCenterAccountId', 'Field')
    registerField(REDUX_FORM_NAME, 'useExistingAccount', 'Field')
    if (websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
  }

  componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (websiteId > 0 && websiteId !== prevProps.websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
  }

  async fetchWebsiteDetail(websiteId) {
    const { fetchWebsiteDetail } = this.props
    await fetchWebsiteDetail(websiteId)

    const {
      initialize,
      merchantCenterAccountId,
      useExistingAccount
    } = this.props
    initialize({
      merchantCenterAccountId,
      useExistingAccount
    })
  }

  handleChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      change('merchantCenterAccountId', e[0])
    }
  }

  onRadioButtonClick(radioButton) {
    this.setState({ radio: radioButton })
  }

  renderSelect() {
    const { merchantCenterAccounts, formValues } = this.props
    const { busy } = this.state
    if (busy) {
      return (
        <IndeterminateProgressIndicator message='Loading your accounts (may take a few minutes).' />
      )
    }
    var selectedAccountId = parseInt(formValues.merchantCenterAccountId, 10)
    var options = merchantCenterAccounts.map((a) => ({
      text: `${a.name} (${a.id})`,
      value: a.id
    }))
    var selectedOption =
      options.find((o) => o.value === selectedAccountId) || {}
    return (
      <MDBSelect
        search
        className={options.length === 0 ? 'disabled' : null}
        options={options}
        labelClass='merchantCenterSelectField'
        selected={selectedOption.text}
        getValue={this.handleChange.bind(this)}
      />
    )
  }

  async handleSubmit(values, _dispatch, props) {
    const {
      websiteId,
      createMerchantCenterAccount,
      selectMerchantCenterAccount,
      dirty
    } = this.props
    try {
      if (dirty) {
        if (!values.useExistingAccount) {
          await createMerchantCenterAccount(websiteId)
        } else {
          await selectMerchantCenterAccount(
            websiteId,
            values.merchantCenterAccountId
          )
        }
        await this.fetchWebsiteDetail(websiteId)
      }
      this.setState({ editing: false })
    } catch (error) {
      toastr.error(error.responseJSON.error, 'ERROR')
      throw error
    }
  }

  async fetchMerchantCenterAccounts() {
    this.setState({ busy: true })
    try {
      await this.props.listMerchantCenterAccounts()
    } catch (error) {
      toastr.error(error.responseJSON.error)
    }
    this.setState({ busy: false })
  }

  renderUseExistingAccountCheckbox() {
    return (
      <GenericContent
        component={
          <Switch
            name='useExistingAccount'
            labelLeft={null}
            labelRight='Use an existing account'
          />
        }
        help={
          'Unless you wish to use an existing account, a new account will be created for you.'
        }
      />
    )
  }

  renderSaveButton() {
    const { showSaveButton, submit, valid } = this.props
    return (
      showSaveButton && (
        <MDBBtn onClick={submit} disabled={!valid}>
          Save
        </MDBBtn>
      )
    )
  }

  renderEdit() {
    return (
      <GoogleUserContext
        scope='https://www.googleapis.com/auth/content'
        help={<div>Sign in with Google to select your existing account.</div>}
        onSuccess={this.fetchMerchantCenterAccounts.bind(this)}
      >
        <GenericContent
          component={this.renderSelect()}
          help='Select your account'
        />
      </GoogleUserContext>
    )
  }

  renderEditable() {
    const { useExistingAccount } = this.props.formValues
    return (
      <div>
        {this.renderUseExistingAccountCheckbox()}
        {useExistingAccount && this.renderEdit()}
        {this.renderSaveButton()}
      </div>
    )
  }

  renderForm() {
    const { handleSubmit, appId } = this.props
    const { editing, radio } = this.state

    return appId !== 'product_feeds' ? (
      <Form
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        className='merchant-center-form'
      >
        {!editing ? this.renderReadOnly() : this.renderEditable()}
      </Form>
    ) : (
      <React.Fragment>
        <MDBInput
          onClick={this.onRadioButtonClick.bind(this, 'Create new')}
          checked={radio === 'Create new'}
          label='Create new'
          type='radio'
          id='createNewRadioButton'
        />
        <MDBInput
          onClick={this.onRadioButtonClick.bind(this, 'Already have one')}
          checked={radio === 'Already have one'}
          label='I have one already'
          type='radio'
          id='alreadyHaveOneRadioButton'
        />
      </React.Fragment>
    )
  }

  renderReadOnlyInput() {
    const {
      merchantCenterAccountName,
      merchantCenterAccountId,
      useExistingAccount
    } = this.props
    return (
      <MDBInput
        label='Merchant Center Account'
        value={
          !useExistingAccount
            ? 'A new account will be created'
            : `${merchantCenterAccountName} (${merchantCenterAccountId})`
        }
        name='merchantCenterAccount'
        readOnly
      />
    )
  }

  renderEditButton() {
    return (
      <MDBBtn
        outline
        color='primary'
        onClick={() => this.setState({ editing: true })}
      >
        Edit
      </MDBBtn>
    )
  }

  renderReadOnly() {
    const { appId } = this.props
    return (
      <React.Fragment>
        <GenericContent component={this.renderReadOnlyInput()} />
        {!(appId === 'platform') && this.renderEditButton()}
      </React.Fragment>
    )
  }

  render() {
    const { initialized } = this.props
    if (!initialized) {
      return <IndeterminateProgressIndicator />
    }

    return this.renderForm()
  }
}

const mapStateToProps = (state, props) => {
  return {
    appId: props.match.params.appId,
    merchantCenterAccountId: merchantCenterAccountIdSelector(state, props),
    merchantCenterAccountName: merchantCenterAccountNameSelector(state, props),
    useExistingAccount: !createMerchantCenterAccountSelector(state, props),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    valid: isValid(REDUX_FORM_NAME)(state),
    websiteId: websiteIdSelector(state, props),
    merchantCenterAccounts: state.google.merchantCenter.accounts || []
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listMerchantCenterAccounts,
      selectMerchantCenterAccount,
      fetchWebsiteDetail,
      registerField,
      createMerchantCenterAccount
    },
    dispatch
  )

export const MerchantCenterFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantCenterForm)
export const REDUX_FORM_NAME = 'MerchantCenterForm'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      validate: (values) => {
        if (values.useExistingAccount) {
          return createValidator(['merchantCenterAccountId'])(values)
        }
        return true
      },
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(MerchantCenterFormWithRedux)
  )
)
