import PropTypes from 'prop-types'

export const commonPropTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  destination: PropTypes.string,
  outline: PropTypes.bool.isRequired,
  enabled: PropTypes.bool,
  isDismissed: PropTypes.bool,
  tooltip: PropTypes.string,
  dismissPlaybookProgress: PropTypes.func
}
