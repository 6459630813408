import camelCaseKeys from 'camelcase-keys';

export const FETCH_CAMPAIGN_TYPES_REQUEST = 'FETCH_CAMPAIGN_TYPES_REQUEST';
export const FETCH_CAMPAIGN_TYPES_SUCCESS = 'FETCH_CAMPAIGN_TYPES_SUCCESS';
export const FETCH_CAMPAIGN_TYPE_DETAIL_REQUEST = 'FETCH_CAMPAIGN_TYPE_DETAIL_REQUEST';
export const FETCH_CAMPAIGN_TYPE_DETAIL_SUCCESS = 'FETCH_CAMPAIGN_TYPE_DETAIL_SUCCESS';
export const FETCH_CAMPAIGN_TYPE_DETAILS_REQUEST = 'FETCH_CAMPAIGN_TYPE_DETAILS_REQUEST';
export const FETCH_CAMPAIGN_TYPE_DETAILS_SUCCESS = 'FETCH_CAMPAIGN_TYPE_DETAILS_SUCCESS';

const requestCampaignTypes = scenarioId => ({
  type: FETCH_CAMPAIGN_TYPES_REQUEST,
  scenarioId: scenarioId
});

const receiveCampaignTypes = (scenarioId, json) => ({
  type: FETCH_CAMPAIGN_TYPES_SUCCESS,
  scenarioId: scenarioId,
  campaignTypes: json
});

export const fetchCampaignTypes = scenarioId => dispatch => {
  dispatch(requestCampaignTypes(scenarioId));

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_types`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        receiveCampaignTypes(
          scenarioId,
          response.campaign_types.map(
            (ct) => camelCaseKeys(ct, { deep: true })
          )
        )
      );
    }
  });
};

const requestCampaignTypeDetail = (scenarioId) => ({
  type: FETCH_CAMPAIGN_TYPE_DETAIL_REQUEST,
  scenarioId: scenarioId
});

const receiveCampaignTypeDetail = (scenarioId, json) => ({
  type: FETCH_CAMPAIGN_TYPE_DETAIL_SUCCESS,
  scenarioId,
  campaignTypeDetail: json
});

export const fetchCampaignTypeDetail = (scenarioId, campaignTypeId) => dispatch => {
  dispatch(requestCampaignTypeDetail(scenarioId));

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_types/${campaignTypeId}`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        receiveCampaignTypeDetail(
          scenarioId,
          camelCaseKeys(response, { deep: true })
        )
      );
    }
  });
};

const requestCampaignTypeDetails = scenarioId => ({
  type: FETCH_CAMPAIGN_TYPE_DETAILS_REQUEST,
  scenarioId: scenarioId
});

const receiveCampaignTypeDetails = (scenarioId, json) => ({
  type: FETCH_CAMPAIGN_TYPE_DETAILS_SUCCESS,
  scenarioId,
  campaignTypeDetails: json
});

export const fetchCampaignTypeDetails = scenarioId => dispatch => {
  dispatch(requestCampaignTypeDetails(scenarioId))

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/campaign_types/details`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        receiveCampaignTypeDetails(
          scenarioId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}