import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChannelAccountsContainer from 'components/feed_ops/channel_accounts/container'
import { channelSelector } from 'selectors/channel'
import {
  CHANNEL_GOOGLE,
  CHANNEL_MICROSOFT,
  SUPPORTED_CHANNELS
} from 'util/supported_channels'

import './linked_accounts_setting.scss'

const LINKABLE_CHANNELS = [CHANNEL_GOOGLE, CHANNEL_MICROSOFT]

export class LinkedAccountsSetting extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired
  }

  render() {
    const { channel } = this.props
    if (LINKABLE_CHANNELS.findIndex((c) => c === channel) < 0) {
      return null
    }
    return (
      <Accordion defaultExpanded={true} className='linked-accounts-setting'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Linked accounts
        </AccordionSummary>
        <AccordionDetails>
          <ChannelAccountsContainer />
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    channel: channelSelector(state, props)
  }
}

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinkedAccountsSetting)
)
