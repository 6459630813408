import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter
} from 'mdbreact'
import { fetchWebsiteSubscription } from 'actions/billing'
import { isEmbeddedAppSelector } from 'selectors/app'
import { websiteIdSelector } from 'selectors/websites'
import { subscriptionSelector } from 'selectors/billing'

export class FeedOpsPlanDetails extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    subscriptionDetails: PropTypes.object.isRequired,
    fetchWebsiteSubscription: PropTypes.func.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired
  }

  async componentDidMount() {
    const { subscriptionDetails, fetchWebsiteSubscription, websiteId } =
      this.props
    if (!subscriptionDetails || Object.keys(subscriptionDetails).length === 0) {
      await fetchWebsiteSubscription(websiteId)
    }
  }

  renderFooter() {
    return (
      <MDBCardFooter>
        <MDBCardText>
          If you would like to change your plan, contact{' '}
          <a href='mailto:sales@feedops.com'>sales@feedops.com</a>
        </MDBCardText>
      </MDBCardFooter>
    )
  }

  renderPlanDetails() {
    const { subscriptionDetails } = this.props
    const { plan, planId, planAmount, currencyCode, nextBillingAt } =
      subscriptionDetails
    const { name } = plan
    const nextBillingDate = new Date(nextBillingAt * 1000)

    return (
      <>
        <MDBCardText>
          {name} - {planAmount / 100} {currencyCode}
          <br />
          {planId !== process.env.FEEDOPS_FREE_ACCOUNT
            ? `Your next renewal is on ${nextBillingDate.toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }
              )}`
            : null}
        </MDBCardText>
      </>
    )
  }

  render() {
    const { subscriptionDetails, isEmbeddedApp } = this.props

    return subscriptionDetails &&
      Object.keys(subscriptionDetails).length > 0 ? (
      <MDBCard className='plan-details-card'>
        <MDBCardBody>
          <MDBCardTitle>Plan</MDBCardTitle>
          {this.renderPlanDetails()}
        </MDBCardBody>
        {!isEmbeddedApp && this.renderFooter()}
      </MDBCard>
    ) : null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props),
    subscriptionDetails: subscriptionSelector(state, props),
    isEmbeddedApp: isEmbeddedAppSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchWebsiteSubscription }, dispatch)

const FeedOpsPlanDetailsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedOpsPlanDetails)

export default withRouter(FeedOpsPlanDetailsWithRedux)
