import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Prompt, withRouter } from 'react-router'

export class SaveChangesPrompt extends Component {
  static propTypes = {
    when: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
  }

  render() {
    const { when, message, match } = this.props
    const { url } = match

    return (
      <Prompt
        when={when}
        message={(location, action) => {
          return location.pathname.includes(url) ? true : message
        }}
      />
    )
  }
}

export default withRouter(SaveChangesPrompt)
