import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import {
  FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  FETCH_ADVERTISING_BUDGETS_REQUEST,
  FETCH_ADVERTISING_BUDGETS_SUCCESS,
  CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS
} from 'actions/advertising'

const fetchAccountAdvertisingBudgetsRequest = (
  accountType,
  accountId,
  channel
) => ({
  type: FETCH_ADVERTISING_BUDGETS_REQUEST,
  accountType,
  id: accountId,
  channel
})

const fetchAccountAdvertisingBudgetsSuccess = (
  accountType,
  accountId,
  channel,
  advertisingBudgets
) => ({
  type: FETCH_ADVERTISING_BUDGETS_SUCCESS,
  accountType,
  id: accountId,
  channel,
  ...advertisingBudgets
})

export const fetchAccountAdvertisingBudgets = (
  accountType,
  accountId,
  channel
) => async (dispatch) => {
  dispatch(
    fetchAccountAdvertisingBudgetsRequest(accountType, accountId, channel)
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/advertising/load_budgets`,
    data: snakeCaseKeys({ channel }, { deep: true }),
    dataType: 'json'
  })

  dispatch(
    fetchAccountAdvertisingBudgetsSuccess(
      accountType,
      accountId,
      channel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchAccountAdvertisingGoalTypeRequest = (
  accountType,
  accountId,
  channel
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_REQUEST,
  accountType,
  id: accountId,
  channel
})

const fetchAccountAdvertisingGoalTypeSuccess = (
  accountType,
  accountId,
  channel,
  advertisingGoalType
) => ({
  type: FETCH_ADVERTISING_GOAL_TYPE_SUCCESS,
  accountType,
  id: accountId,
  channel,
  advertisingGoalType
})

export const fetchAccountAdvertisingGoalType = (
  accountType,
  accountId,
  channel
) => async (dispatch) => {
  dispatch(
    fetchAccountAdvertisingGoalTypeRequest(accountType, accountId, channel)
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/advertising/load_goal_type`,
    data: snakeCaseKeys({ channel }, { deep: true }),
    dataType: 'json'
  })

  dispatch(
    fetchAccountAdvertisingGoalTypeSuccess(
      accountType,
      accountId,
      channel,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const checkAccountPublicationAccountTypesRequest = (
  accountType,
  accountId
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_REQUEST,
  accountType,
  id: accountId
})

const checkAccountPublicationAccountTypesSuccess = (
  accountType,
  accountId,
  publicationAccountTypes
) => ({
  type: CHECK_PUBLICATION_ACCOUNT_TYPES_SUCCESS,
  accountType,
  id: accountId,
  ...publicationAccountTypes
})

export const checkAccountPublicationAccountTypes = (
  accountType,
  accountId
) => async (dispatch) => {
  dispatch(checkAccountPublicationAccountTypesRequest(accountType, accountId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/advertising/check_publication_account_types`,
    dataType: 'json'
  })

  dispatch(
    checkAccountPublicationAccountTypesSuccess(
      accountType,
      accountId,
      camelCaseKeys(response, { deep: true })
    )
  )
}
