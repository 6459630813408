import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showHideAllAuditsModal } from 'actions/inventories'
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveProductDataAudit from './save_product_data_audit'
import MaterialTable from '@material-table/core'
import {
  inventoryShowAllAuditsSelector,
  inventoryProductDataAuditsSelector
} from 'selectors/inventory'
import { websiteRetailInventoryIdsSelector } from 'selectors/websites'
import { generateTableLocalization } from 'components/feed_ops/website/product_optimisations/common/optimiser_util'

const COLUMNS = [
  { title: 'Date', field: 'createdAt', sorting: false },
  { title: 'Health', field: 'healthScore', sorting: false },
  {
    title: 'Optimisation Score (%)',
    field: 'optimisationScore',
    sorting: false
  },
  { title: 'Action', field: 'url', sorting: false }
]

export class AllAuditsModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    retailInventoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    allAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
    showHideAllAuditsModal: PropTypes.func.isRequired
  }

  static defaultProps = {
    isModalOpen: false
  }

  handelAuditUrlBtnClick(auditUrl) {
    window.open(auditUrl, '_blank')
  }

  renderAuditUrlBtn(auditUrl) {
    return (
      <MDBBtn
        tag='span'
        className='audit-url-btn'
        onClick={this.handelAuditUrlBtnClick.bind(this, auditUrl)}
      >
        Download
      </MDBBtn>
    )
  }

  renderGeneratingAudit() {
    return (
      <span className='generating-audit-indicator'>
        Generating <CircularProgress />
      </span>
    )
  }

  getDisplayColumns() {
    var displayedColumnsData = []
    COLUMNS.forEach((columnData, index) => {
      displayedColumnsData.push({
        ...columnData,
        title: columnData.title,
        render: (rowData) => (
          <span id={`${columnData.field}${index}${rowData.url}`}>
            {columnData.field === 'url'
              ? rowData[columnData.field].toString() !== 'Generating'
                ? this.renderAuditUrlBtn(rowData[columnData.field].toString())
                : this.renderGeneratingAudit()
              : rowData[columnData.field].toString()}
          </span>
        )
      })
    })

    return displayedColumnsData
  }

  getTableOptions() {
    return {
      idSynonym: 'url',
      columnResizable: false,
      debounceInterval: 1000,
      defaultExpanded: true,
      draggable: false,
      grouping: false,
      pageSize: 5,
      pageSizeOptions: [5, 10, 15, 20],
      showTitle: false,
      search: false,
      toolbar: false
    }
  }

  getModalBodyComponent() {
    const { allAudits, metaData } = this.props

    return (
      <div className='all-audits-container'>
        {allAudits && allAudits.length > 0 ? (
          <MaterialTable
            data={allAudits}
            columns={this.getDisplayColumns()}
            options={this.getTableOptions()}
            localization={generateTableLocalization({
              allRowsSelected: false,
              metaData
            })}
          />
        ) : (
          'No Audits Generated'
        )}
      </div>
    )
  }

  handleClose() {
    const { showHideAllAuditsModal } = this.props
    showHideAllAuditsModal(false)
  }

  renderModalFooter() {
    return (
      <MDBModalFooter className='common-modal-footer'>
        <MDBBtn
          color='primary'
          tag='span'
          onClick={this.handleClose.bind(this)}
        >
          Close
        </MDBBtn>
      </MDBModalFooter>
    )
  }

  render() {
    const { allAudits, isModalOpen } = this.props
    var component = this.getModalBodyComponent()

    return allAudits && allAudits.length > 0 ? (
      <MDBModal
        isOpen={isModalOpen}
        position='right'
        size='lg'
        backdrop={false}
        fullHeight
      >
        <MDBModalHeader className='full-height-right-modal-header'>
          All Audits
        </MDBModalHeader>
        <MDBModalBody className='full-height-right-modal-body'>
          {component}
          <SaveProductDataAudit />
        </MDBModalBody>
        {this.renderModalFooter()}
      </MDBModal>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  isModalOpen: inventoryShowAllAuditsSelector(state, props),
  retailInventoryIds: websiteRetailInventoryIdsSelector(state, props),
  allAudits: inventoryProductDataAuditsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showHideAllAuditsModal }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllAuditsModal)
)
