import camelCaseKeys from 'camelcase-keys'

export const FETCH_INVENTORY_FO_CATEGORIES_REQUEST =
  'FETCH_INVENTORY_FO_CATEGORIES_REQUEST'
export const FETCH_INVENTORY_FO_CATEGORIES_RESPONSE =
  'FETCH_INVENTORY_FO_CATEGORIES_RESPONSE'

const fetchInventoryFeedOpsCategoriesRequest = (websiteId) => ({
  type: FETCH_INVENTORY_FO_CATEGORIES_REQUEST,
  websiteId
})

const fetchInventoryFeedOpsCategoriesResponse = (
  websiteId,
  categories,
  inventoryId
) => ({
  type: FETCH_INVENTORY_FO_CATEGORIES_RESPONSE,
  websiteId,
  categories,
  inventoryId
})

export const fetchInventoryFeedOpsCategories =
  (websiteId) => async (dispatch) => {
    dispatch(fetchInventoryFeedOpsCategoriesRequest(websiteId))
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/websites/${websiteId}/inventories/feed_ops_categories`,
      dataType: 'json'
    })

    var categories = camelCaseKeys(JSON.parse(response.categories), {
      deep: true
    })
    var inventoryIdByFeed = response.inventory_id
    dispatch(
      fetchInventoryFeedOpsCategoriesResponse(
        websiteId,
        categories,
        inventoryIdByFeed
      )
    )
    return categories
  }
