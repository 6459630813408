import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardText, CardTitle, CardBody } from 'reactstrap'
import moment from 'moment'
import {
  subscriptionPlanNameSelector,
  subscriptionPlanAmountSelector,
  subscriptionTrialEndSelector,
  subscriptionCurrencySelector
} from 'selectors/billing'
import CreditCardForm from './credit_card_form'
import './prompt_for_credit_card.scss'

export class PromptForCreditCard extends Component {
  static propTypes = {
    planName: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    trialEnd: PropTypes.instanceOf(Date).isRequired
  }

  renderPlanDetails() {
    const { planName, price, currency } = this.props
    return (
      <p>
        Based on the number of SKUs, this website is eligible for the{' '}
        <strong>{planName}</strong> plan, which costs{' '}
        <strong>
          {currency} {price}
        </strong>{' '}
        per month after the free trial period.
      </p>
    )
  }

  renderTrialEndNote() {
    const { trialEnd } = this.props
    return (
      <p>
        Please enter your credit card information below. You will not be charged
        until your free trial expires on{' '}
        {moment(trialEnd).format('DD MMM YYYY')}.
      </p>
    )
  }

  render() {
    return (
      <Card className='prompt-for-credit-card'>
        <CardBody>
          <CardTitle>Approve subscription</CardTitle>
          <CardText>
            {this.renderPlanDetails()}

            {this.renderTrialEndNote()}
          </CardText>
          <CreditCardForm />
          <CardText>
            <p> You can cancel at any time by uninstalling the app. </p>
          </CardText>
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state, props) => ({
  planName: subscriptionPlanNameSelector(state, props),
  price: subscriptionPlanAmountSelector(state, props),
  currency: subscriptionCurrencySelector(state, props),
  trialEnd: subscriptionTrialEndSelector(state, props)
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PromptForCreditCard)
