import { FETCH_KEYWORD_IDEAS_RESPONSE } from 'actions/keyword_ideas'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KEYWORD_IDEAS_RESPONSE:
      return {
        ...state,
        [action.websiteId]: {
          ...state[action.websiteId],
          [action.field]: action.ideas.flat()
        }
      }
    default:
      return state
  }
}
