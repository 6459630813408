import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual } from 'lodash'
import { Line } from 'react-chartjs-2'
import { fetchWebsitePerformanceByFeedopsCampaigns } from 'actions/performance/websites'
import { websiteIdSelector } from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { dateRangeSelector } from 'selectors/date_ranges'
import { feedopsAdvertisingByChannelAndWebsiteSelector } from 'selectors/performance'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import {
  CHART_PARAMETERS,
  BLUE_LINE_COLOUR,
  YELLOW_LINE_COLOUR,
  GENERATE_DATASET
} from './chart_options'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'
import { LAST_30_DAYS_DATE_RANGE } from 'util/charts'

export class AdvertisingPerformanceChart extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    dateRange: PropTypes.object.isRequired,
    fill: PropTypes.bool.isRequired,
    displayFullChart: PropTypes.bool.isRequired,
    advertisingPerformance: PropTypes.object.isRequired,
    fetchWebsitePerformanceByFeedopsCampaigns: PropTypes.func.isRequired
  }

  static defaultProps = {
    fill: false,
    displayFullChart: false
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.fetchWebsitePerformanceByFeedopsCampaigns()
  }

  componentDidUpdate(prevProps) {
    const { websiteId, channel, dateRange } = this.props
    if (
      (websiteId !== 0 && !isEqual(prevProps.websiteId, websiteId)) ||
      !isEqual(prevProps.channel, channel) ||
      !isEqual(prevProps.dateRange, dateRange)
    ) {
      this.fetchWebsitePerformanceByFeedopsCampaigns()
    }
  }

  async fetchWebsitePerformanceByFeedopsCampaigns() {
    const {
      fetchWebsitePerformanceByFeedopsCampaigns,
      websiteId,
      channel,
      dateRange,
      displayFullChart
    } = this.props
    try {
      await fetchWebsitePerformanceByFeedopsCampaigns(
        websiteId,
        channel,
        displayFullChart ? dateRange : LAST_30_DAYS_DATE_RANGE
      )
    } catch (error) {
      toastr.error(
        'An error occurred while fetching your Advertising Performance data. Please try again later.'
      )
    }
  }

  isAdvertisingPerformanceEmpty(advertisingPerformance) {
    return (
      advertisingPerformance && Object.keys(advertisingPerformance).length > 0
    )
  }

  generateDatasets(dataValues) {
    const { fill, displayFullChart } = this.props
    var datasets = [
      GENERATE_DATASET(
        YELLOW_LINE_COLOUR,
        fill,
        'Conversion Value',
        dataValues.map((value) => value.totalConvValue.toFixed(2))
      ),
      GENERATE_DATASET(
        BLUE_LINE_COLOUR,
        fill,
        'Cost',
        dataValues.map((value) => value.cost.toFixed(2))
      )
    ]

    if (displayFullChart) {
      datasets.push(
        GENERATE_DATASET(
          YELLOW_LINE_COLOUR,
          fill,
          'Target Conversion Value',
          dataValues.map((value) => (value.goal ? value.goal.toFixed(2) : 0)),
          { borderDash: [5, 7], borderDashOffset: 5 }
        )
      )
      datasets.push(
        GENERATE_DATASET(
          BLUE_LINE_COLOUR,
          fill,
          'Target Cost',
          dataValues.map((value) =>
            value.budget ? value.budget.toFixed(2) : 0
          ),
          { borderDash: [5, 7] }
        )
      )
    }
    return datasets
  }

  populateData() {
    const { advertisingPerformance } = this.props
    if (!this.isAdvertisingPerformanceEmpty(advertisingPerformance)) {
      return {}
    }

    const dataValues = Object.values(advertisingPerformance.data)
    const datasets = this.generateDatasets(dataValues)

    const inputData = {
      labels: Object.keys(advertisingPerformance.data).map(
        (date) =>
          date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6)
      ),
      datasets
    }
    return inputData
  }

  populateOptions() {
    const { advertisingPerformance } = this.props
    if (!this.isAdvertisingPerformanceEmpty(advertisingPerformance)) {
      return CHART_PARAMETERS
    }

    var currency =
      Object.values(advertisingPerformance.data).slice(-1)[0]?.currency || ''
    var options = {
      ...CHART_PARAMETERS
    }
    options.scales.yAxes[0].ticks = {
      ...options.scales.yAxes[0].ticks,
      callback: (value) => `${currency} ${value}`
    }

    return options
  }

  renderChart() {
    return (
      <Line
        data={this.populateData()}
        height={80}
        responsive
        options={this.populateOptions()}
      />
    )
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <IndeterminateProgressIndicator />}
        {this.renderChart()}
      </>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    websiteId,
    channel: channelSelector(state, props),
    dateRange: dateRangeSelector('productFeeds/goalBudgetManagement')(
      state,
      props
    ),
    advertisingPerformance: feedopsAdvertisingByChannelAndWebsiteSelector(
      state,
      props
    )
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchWebsitePerformanceByFeedopsCampaigns }, dispatch)

const AdvertisingPerformanceChartWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvertisingPerformanceChart)

export default withRouter(AdvertisingPerformanceChartWithRedux)
