import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_WEBSITE_CONTACTS_REQUEST = 'FETCH_WEBSITE_CONTACTS_REQUEST'
export const FETCH_WEBSITE_CONTACTS_RESPONSE = 'FETCH_WEBSITE_CONTACTS_RESPONSE'
export const CREATE_OR_UPDATE_WEBSITE_CONTACTS_REQUEST =
  'CREATE_OR_UPDATE_WEBSITE_CONTACTS_REQUEST'
export const CREATE_OR_UPDATE_WEBSITE_CONTACTS_RESPONSE =
  'CREATE_OR_UPDATE_WEBSITE_CONTACTS_RESPONSE'

const fetchWebsiteContactsRequest = websiteId => ({
  type: FETCH_WEBSITE_CONTACTS_REQUEST,
  websiteId
})

const fetchWebsiteContactsResponse = (websiteId, response) => ({
  type: FETCH_WEBSITE_CONTACTS_RESPONSE,
  websiteId,
  contacts: response
})

export const fetchWebsiteContacts = websiteId => dispatch => {
  dispatch(fetchWebsiteContactsRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/contacts`,
    dataType: 'json',
    success: response => {
      return dispatch(
        fetchWebsiteContactsResponse(websiteId, camelCaseKeys(response))
      )
    }
  })
}

const createOrUpdateWebsiteContactsRequest = websiteId => ({
  type: CREATE_OR_UPDATE_WEBSITE_CONTACTS_REQUEST,
  websiteId
})

const createOrUpdateWebsiteContactsResponse = websiteId => ({
  type: CREATE_OR_UPDATE_WEBSITE_CONTACTS_RESPONSE,
  websiteId
})

export const createOrUpdateWebsiteContacts = (
  websiteId,
  payload
) => dispatch => {
  dispatch(createOrUpdateWebsiteContactsRequest(websiteId))
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/websites/${websiteId}/contacts`,
    dataType: 'json',
    data: snakeCaseKeys(payload),
    success: response => {
      return dispatch(createOrUpdateWebsiteContactsResponse(websiteId))
    }
  })
}