import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBBtn } from 'mdbreact'
import { isEmbeddedAppSelector } from 'selectors/app'
import { superUserSelector, accountTypeIdSelector } from 'selectors/user'
import {
  CUSTOMER_FEATURE_ADD_WEBSITES,
  customerFeaturesSelector
} from 'selectors/billing'

export class AddNewWebsite extends Component {
  static propTypes = {
    isEmbeddedApp: PropTypes.bool.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    customerFeatures: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired
  }

  handleAddWebsiteClick() {
    const { history } = this.props
    history.push(`/feed_ops/options`)
  }

  render() {
    const { isEmbeddedApp, customerFeatures, isSuperUser } = this.props

    return isEmbeddedApp ||
      (!customerFeatures[CUSTOMER_FEATURE_ADD_WEBSITES] &&
        !isSuperUser) ? null : (
      <MDBBtn
        id='add-new-website'
        tag='span'
        className='btn-toolbar-action'
        onClick={this.handleAddWebsiteClick.bind(this)}
      >
        Add Website
      </MDBBtn>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  isEmbeddedApp: isEmbeddedAppSelector(state, props),
  isSuperUser: superUserSelector(state, props),
  customerFeatures: customerFeaturesSelector(state, props),
  accountId: accountTypeIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewWebsite)
)
