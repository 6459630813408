import camelCaseKeys from 'camelcase-keys'

export const FETCH_VENDORS_REQUEST = 'FETCH_VENDORS_REQUEST'
export const FETCH_VENDORS_RESPONSE = 'FETCH_VENDORS_RESPONSE'

const fetchVendorsRequest = () => ({
  type: FETCH_VENDORS_REQUEST
})

const fetchVendorsResponse = (response) => ({
  type: FETCH_VENDORS_RESPONSE,
  ...response
})

export const fetchVendors = () => async (dispatch) => {
  dispatch(fetchVendorsRequest())
  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/inventories/vendors',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchVendorsResponse(response))
  return response
}
