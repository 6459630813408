export const PRODUCT_SKU = 'sku'
export const PRODUCT_URL = 'url'
export const IMAGE_URL = 'imageUrl'
export const TITLE = 'title'
export const DESCRIPTION = 'description'
export const QUANTITY = 'stockAmount'
export const GOOGLE_PRODUCT_CATEGORY = 'googleCategory'
export const PRODUCT_CREATED_AT = 'createdAt'
export const PRODUCT_UPDATED_AT = 'updatedAt'

export const IMAGE_ATTRIBUTES = 'image'
export const PRICE_ATTRIBUTES = 'price'
