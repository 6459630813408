import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import timeAgoInWords from 'util/time_ago_in_words'
import { MDBCardText, MDBBtn, MDBContainer } from 'mdbreact'
import { triggerInventoryDownloadByInventoryId } from 'actions/inventories'
import {
  initialReconciledStatusSelector,
  inventoryDetailSelector,
  inventoryProcessingStatusSelector
} from 'selectors/inventory'
import { websiteInventoryIdSelector } from 'selectors/websites'
import InventoryStatus from 'components/feed_ops/common/inventory_status'

import './inventory_details.scss'

export class InventoryDetails extends Component {
  static propTypes = {
    inventoryId: PropTypes.number.isRequired,
    inventoryDetail: PropTypes.object.isRequired,
    isInitialInventoryReconciled: PropTypes.bool.isRequired,
    triggerInventoryDownloadByInventoryId: PropTypes.func.isRequired,
    processingMessage: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { reprocessing: false }
  }

  isProcessingFailed() {
    const { processingMessage } = this.props
    return processingMessage.includes('Please contact support for assistance')
  }

  renderProcessingStatus() {
    var failed = this.isProcessingFailed()
    if (failed) {
      return this.renderReprocessFeedBtn()
    }

    return (
      <MDBContainer className='inventory-details-status'>
        <InventoryStatus />
      </MDBContainer>
    )
  }

  renderInventoryDetails() {
    const { inventoryDetail } = this.props
    const { itemCount, lastImported } = inventoryDetail

    return (
      <>
        <MDBCardText className='store-text'>
          SKUs: {itemCount}
          <br />
          Last downloaded: {timeAgoInWords(lastImported)}
        </MDBCardText>
        {this.renderReprocessFeedBtn()}
      </>
    )
  }

  async handleReprocessFeedClick() {
    var response = {}

    const { triggerInventoryDownloadByInventoryId, inventoryId } = this.props

    this.setState({ reprocessing: true })
    response = await triggerInventoryDownloadByInventoryId(inventoryId, true)
    this.setState({ reprocessing: false })

    if (response && !!response.error) {
      toastr.error(
        'An error occurred while reprocessing your feed. Please contact support for assistance.'
      )
    } else {
      toastr.success('Reprocessing feed')
    }
  }

  renderReprocessFeedBtn() {
    const { reprocessing } = this.state
    return (
      <div className='btn-connect-container reprocess-feed-btn'>
        <MDBBtn
          onClick={this.handleReprocessFeedClick.bind(this)}
          disabled={reprocessing}
        >
          Reprocess Feed
        </MDBBtn>
      </div>
    )
  }

  render() {
    const { isInitialInventoryReconciled, processingMessage } = this.props
    const { reprocessing } = this.state

    return (
      <div className='inventory-details'>
        {!isInitialInventoryReconciled || !!processingMessage || reprocessing
          ? this.renderProcessingStatus()
          : this.renderInventoryDetails()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const inventoryId = websiteInventoryIdSelector(state, props)
  return {
    inventoryId,
    inventoryDetail: inventoryDetailSelector(inventoryId)(state, props),
    isInitialInventoryReconciled:
      initialReconciledStatusSelector(state, props) || false,
    processingMessage: inventoryProcessingStatusSelector(state, props)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      triggerInventoryDownloadByInventoryId
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryDetails)
)
