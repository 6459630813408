import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

export default class Toggle extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  }

  render() {
    const { value, onClick, label } = this.props
    return (
      <div className="switch float-right">
      <label>
        {label}
        <Input type="checkbox" onClick={onClick} checked={value} readOnly/>
        <span className={value ? 'lever color-workflow' : 'lever'}></span>
      </label>
    </div>
    )
  }
}
