import {
  SHOW_FEEDOPS_FEEDBACK_MODAL,
  HIDE_FEEDOPS_FEEDBACK_MODAL
} from 'actions/feed_ops/feedbacks'

const initialState = {
  openModal: false,
  feedbackFor: null,
  feedbackForId: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FEEDOPS_FEEDBACK_MODAL:
      return {
        ...state,
        openModal: true,
        feedbackFor: action.feedbackFor,
        feedbackForId: action.feedbackForId
      }

    case HIDE_FEEDOPS_FEEDBACK_MODAL:
      return {
        ...state,
        openModal: false,
        feedbackFor: null,
        feedbackForId: null
      }

    default:
      return state
  }
}
