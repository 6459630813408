import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import './dropdown_with_icons.scss'

class DropdownWithIcons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen
    }))
  }

  renderDropdownItems = () => {
    const { dropdownItems } = this.props
    return dropdownItems.map((item, index) => (
      <DropdownItem key={index} onClick={() => item.action(item.label)}>
        {item.icon} <span className='label'>{item.label}</span>
      </DropdownItem>
    ))
  }

  render() {
    const { dropdownOpen } = this.state
    const { toggleIcon, shouldRotateIcon, className, label } = this.props

    return (
      <div
        className={`dropdown-container ${className} ${
          shouldRotateIcon ? 'rotate' : ''
        }`}
      >
        <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret>
            {label && <h5>{label.trim()}</h5>}
            <div className='icon'>{toggleIcon}</div>
          </DropdownToggle>
          <DropdownMenu right>{this.renderDropdownItems()}</DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

DropdownWithIcons.propTypes = {
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      icon: PropTypes.node.isRequired,
      action: PropTypes.func
    })
  ).isRequired,
  toggleIcon: PropTypes.node.isRequired,
  shouldRotateIcon: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
}

DropdownWithIcons.defaultProps = {
  className: '',
  shouldRotateIcon: false,
  label: ''
}

export default DropdownWithIcons
