import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FeedOpsNotificationsSettings from './settings/notifications'
import FeedOpsPlanDetails from './settings/plan_details'
import FeedOpsSubscriptions from './settings/subscriptions'
import FeedOpsDeleteWebsite from './settings/delete_website'
import { superUserSelector } from 'selectors/user'

export class WebsiteSettings extends Component {
  static propTypes = {
    isSuperUser: PropTypes.bool.isRequired
  }

  render() {
    const { isSuperUser } = this.props

    return (
      <>
        <FeedOpsNotificationsSettings />
        <FeedOpsPlanDetails />
        {isSuperUser && <FeedOpsSubscriptions />}
        <FeedOpsDeleteWebsite />
      </>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    isSuperUser: superUserSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const WebsiteSettingsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteSettings)

export default withRouter(WebsiteSettingsWithRedux)
