import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { trackEvent, ACCOUNT_CREATED } from 'util/tag_manager'

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_RESPONSE = 'CREATE_ACCOUNT_RESPONSE'
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST'
export const UPDATE_ACCOUNT_RESPONSE = 'UPDATE_ACCOUNT_RESPONSE'
export const FETCH_ACCOUNT_USERS_REQUEST = 'FETCH_ACCOUNT_USERS_REQUEST'
export const FETCH_ACCOUNT_USERS_RESPONSE = 'FETCH_ACCOUNT_USERS_RESPONSE'

const createAccountRequest = (accountName) => ({
  type: CREATE_ACCOUNT_REQUEST,
  accountName
})

const createAccountResponse = (accountName, response) => ({
  type: CREATE_ACCOUNT_RESPONSE,
  accountName,
  response
})

export const createAccount = (accountName) => async (dispatch) => {
  dispatch(createAccountRequest(accountName))

  var response = await $.ajax({
    method: 'POST',
    url: `/ad_champion/accounts`,
    dataType: 'json',
    data: snakeCaseKeys({ accountName })
  })
  const camelCaseResponse = camelCaseKeys(response)
  trackEvent(ACCOUNT_CREATED)
  dispatch(createAccountResponse(accountName, camelCaseResponse))
  return camelCaseResponse
}

const updateAccountRequest = (accountId, accountName, companyUrl) => ({
  type: UPDATE_ACCOUNT_REQUEST,
  accountId,
  accountName,
  companyUrl
})

const updateAccountResponse = (accountId, response) => ({
  type: UPDATE_ACCOUNT_RESPONSE,
  accountId,
  ...response
})

export const updateAccount =
  (accountId, accountName, companyUrl) => async (dispatch) => {
    dispatch(updateAccountRequest(accountId, accountName, companyUrl))

    var response = await $.ajax({
      method: 'PUT',
      url: `/ad_champion/accounts/${accountId}`,
      dataType: 'json',
      data: snakeCaseKeys({ accountName, companyUrl })
    })

    response = camelCaseKeys(response, { deep: true })
    dispatch(updateAccountResponse(accountId, response))
    return response
  }

const fetchAccountUsersRequest = (accountId) => ({
  type: FETCH_ACCOUNT_USERS_REQUEST,
  accountId
})

const fetchAccountUsersResponse = (accountId, response) => ({
  type: FETCH_ACCOUNT_USERS_RESPONSE,
  accountId,
  ...response
})

export const fetchAccountUsers = (accountId) => async (dispatch) => {
  dispatch(fetchAccountUsersRequest(accountId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/accounts/${accountId}/users`,
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchAccountUsersResponse(accountId, response))
  return response
}
