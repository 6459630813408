import camelCaseKeys from 'camelcase-keys'
import { getAccessToken } from 'selectors/google/user'

export const LIST_GOOGLE_SPREADSHEETS_REQUEST =
  'LIST_GOOGLE_SPREADSHEETS_REQUEST'
export const LIST_GOOGLE_SPREADSHEETS_RESPONSE =
  'LIST_GOOGLE_SPREADSHEETS_RESPONSE'
export const LIST_GOOGLE_SPREADSHHET_SHEETS_REQUEST =
  'LIST_GOOGLE_SPREADSHHET_SHEETS_REQUEST'
export const LIST_GOOGLE_SPREADSHHET_SHEETS_RESPONSE =
  'LIST_GOOGLE_SPREADSHHET_SHEETS_RESPONSE'

const listGoogleSpreadsheetsRequest = () => ({
  type: LIST_GOOGLE_SPREADSHEETS_REQUEST
})

const listGoogleSpreadsheetsResponse = (spreadsheets) => ({
  type: LIST_GOOGLE_SPREADSHEETS_RESPONSE,
  spreadsheets
})

export const listGoogleSpreadsheets =
  (fileName) => async (dispatch, getState) => {
    const accessToken = getAccessToken(getState)
    dispatch(listGoogleSpreadsheetsRequest())

    const response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/google/spreadsheets?access_token=${accessToken}&file_name=${fileName}`
    })

    const spreadsheets = camelCaseKeys(response.spreadsheets, { deep: true })
    dispatch(listGoogleSpreadsheetsResponse(spreadsheets))

    return spreadsheets
  }

const listGoogleSpreadsheetSheetsRequest = (spreadsheetId) => ({
  type: LIST_GOOGLE_SPREADSHHET_SHEETS_REQUEST,
  spreadsheetId
})

const listGoogleSpreadsheetSheetsResponse = (spreadsheetId, sheets) => ({
  type: LIST_GOOGLE_SPREADSHHET_SHEETS_RESPONSE,
  spreadsheetId,
  sheets
})

export const listGoogleSpreadsheetSheets =
  (spreadsheetId) => async (dispatch, getState) => {
    const accessToken = getAccessToken(getState)
    dispatch(listGoogleSpreadsheetSheetsRequest(spreadsheetId))

    const response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/google/spreadsheets/sheets?access_token=${accessToken}&spreadsheet_id=${spreadsheetId}`
    })

    const sheets = camelCaseKeys(response.sheets, { deep: true })
    dispatch(listGoogleSpreadsheetSheetsResponse(spreadsheetId, sheets))

    return sheets
  }
