import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import './unsaved_changes_modal.scss'

export class UnsavedChangesModal extends Component {
  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    headerText: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    btnStayText: PropTypes.string.isRequired,
    btnNavigateAwayText: PropTypes.string.isRequired,
    onStayActionClick: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      lastLocation: null,
      confirmedNavigation: false
    }
  }

  componentDidUpdate(prevProps) {
    const { history, enabled } = this.props
    const { confirmedNavigation } = this.state

    if (enabled && !prevProps.enabled) {
      this.unblock = history.block((nextLocation) => {
        if (enabled && !confirmedNavigation) {
          this.setState({
            isOpen: true,
            lastLocation: nextLocation
          })
          return false
        }
        return true
      })
    } else if (!enabled && prevProps.enabled) {
      if (this.unblock) {
        this.unblock()
      }
      this.setState({ isOpen: false })
    }
  }

  componentWillUnmount() {
    if (this.unblock) {
      this.unblock()
    }
  }

  handleToggleModal() {
    this.setState({ isOpen: false })
  }

  renderModalHeader() {
    const { headerText } = this.props

    return (
      <ModalHeader>
        {headerText}
        <Button onClick={this.handleToggleModal.bind(this)}>
          <i className='fa fa-times' />
        </Button>
      </ModalHeader>
    )
  }

  handleActionBtnClick(actionName) {
    const { btnNavigateAwayText, history, onStayActionClick } = this.props

    if (actionName === btnNavigateAwayText) {
      this.setState({ isOpen: false, confirmedNavigation: true }, () => {
        if (this.unblock) {
          this.unblock()
        }
        const { lastLocation } = this.state
        history.push(lastLocation.pathname + lastLocation.search)
      })
    } else {
      this.setState({ isOpen: false })
      onStayActionClick()
    }
  }

  renderActionButton(actionName, btnId, outline, color) {
    return (
      <Button
        key={actionName}
        id={btnId}
        tag='span'
        color={color}
        outline={outline}
        onClick={this.handleActionBtnClick.bind(this, actionName)}
      >
        {actionName}
      </Button>
    )
  }

  renderActionButtons() {
    const { btnNavigateAwayText, btnStayText } = this.props

    return (
      <>
        {this.renderActionButton(
          btnNavigateAwayText,
          'cancel-changes',
          true,
          'dark'
        )}
        {this.renderActionButton(btnStayText, 'save-changes', false, 'primary')}
      </>
    )
  }

  render() {
    const { isOpen } = this.state
    const { descriptionText } = this.props

    return (
      <Modal
        size='md'
        centered={true}
        isOpen={isOpen}
        className='unsaved-changes-modal'
        toggle={this.handleToggleModal.bind(this)}
      >
        {this.renderModalHeader()}
        <ModalBody>{descriptionText}</ModalBody>
        <ModalFooter>{this.renderActionButtons()}</ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnsavedChangesModal)
)
