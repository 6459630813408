import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

export const FETCH_BUILDS= 'FETCH_BUILDS';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';

export const LOAD_BUILD_REQUEST = 'LOAD_BUILD_REQUEST'
export const LOAD_BUILD_RESPONSE = 'LOAD_BUILD_RESPONSE'

export const DELETE_BUILD_REQUEST = 'DELETE_BUILD_REQUEST'
export const DELETE_BUILD_SUCCESS = 'DELETE_BUILD_SUCCESS'
export const DELETE_BUILD_ERROR   = 'DELETE_BUILD_ERROR'

export const CREATE_BUILD_REQUEST = 'CREATE_BUILD_REQUEST'
export const CREATE_BUILD_RESPONSE = 'CREATE_BUILD_RESPONSE'

export const PUBLISH_BUILD_REQUEST = 'PUBLISH_BUILD_REQUEST'
export const PUBLISH_BUILD_RESPONSE = 'PUBLISH_BUILD_RESPONSE'

const loadBuildRequest = (buildId) => ({
  type: LOAD_BUILD_REQUEST,
  buildId
})

const loadBuildResponse = (buildId, build) => ({
  type: LOAD_BUILD_RESPONSE,
  buildId,
  build
})

const createBuildRequest = () => ({
  type: CREATE_BUILD_REQUEST
})

const createBuildResponse = () => ({
  type: CREATE_BUILD_RESPONSE
})

export const loadBuild = buildId => dispatch => {
  dispatch(loadBuildRequest(buildId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/builds/${buildId}`,
    success: response => {
      dispatch(loadBuildResponse(buildId, camelCaseKeys(response, { deep: true })))
    }
  })
}

const deleteBuildRequest = (id) => ({
  type: DELETE_BUILD_REQUEST,
  id
})

const deleteBuildSuccess = (id) => ({
  type: DELETE_BUILD_SUCCESS,
  id
})

const deleteBuildError = (response) => ({
  type: DELETE_BUILD_ERROR,
  error: response.error
})

export const deleteBuild = (buildId) => dispatch => {
  dispatch(deleteBuildRequest(buildId))
  return $.ajax({
    method: 'DELETE',
    url: `/ad_champion/builds/${buildId}`,
    dataType: 'json',
    success:(response) => { 
      dispatch(deleteBuildSuccess(buildId));
    },
    error: (response) => { 
      dispatch(deleteBuildError(response));
    }
  });
}

export const createBuild = (payload) => dispatch => {
  dispatch(createBuildRequest())
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/builds`,
    data: snakeCaseKeys(payload, {deep: true}),
    success: response => {
      dispatch(createBuildResponse())
    }
  })
}

export const publishBuild = (buildId , data) => dispatch => {
  dispatch(publishBuildRequest())
  return $.ajax({
    method: "POST",
    url: `/ad_champion/builds/${buildId}/publish`,
    dataType: 'json',
    data: snakeCaseKeys(data),
    error: (response) => { 
      dispatch(responseError(camelCaseKeys(response, {deep: true})));
    },
    success: () => {dispatch(publishBuildResponse())}
  });
}

const receiveBuildSuccess = (response) => ({
  type: FETCH_BUILDS,
  orders: response.orders,
  links: response.links,
  totalOrders: response.totalOrders
});

const responseError = (response) => ({
  type: RESPONSE_ERROR,
  error: response.error || null
});

const publishBuildRequest = () => ({
  type: PUBLISH_BUILD_REQUEST
})

const publishBuildResponse = () => ({
  type: PUBLISH_BUILD_RESPONSE
})

