export const OP_EMPTY = 'is empty'
export const OP_NOT_EMPTY = 'not empty'
export const OP_CONTAINS = 'contains'
export const OP_NOT_CONTAINS = 'does not contain'
export const OP_EQUALS = 'is equal to'
export const OP_NOT_EQUALS = 'not equals to'
export const OP_ONE_OF = 'is one of'
export const OP_NOT_ONE_OF = 'is not one of'
export const OP_LESS_THAN = 'less than'
export const OP_GREATER_THAN = 'greater than'
export const OP_BEFORE = 'before'
export const OP_AFTER = 'after'
export const OP_HAS_SUGGESTIONS = 'has suggestions'
export const OP_IS_INVALID = 'is invalid'

export const TEXT_OPERATORS = [
  OP_CONTAINS,
  OP_NOT_CONTAINS,
  OP_EQUALS,
  OP_ONE_OF,
  OP_NOT_ONE_OF
]

export const BOOLEAN_OPERATORS = [OP_EQUALS]

export const DATE_OPERATORS = [OP_BEFORE, OP_AFTER]

export const GOOGLE_PRODUCT_CATEGORY_OPERATORS = [
  OP_EQUALS,
  OP_NOT_EQUALS,
  OP_CONTAINS,
  OP_NOT_CONTAINS,
  OP_HAS_SUGGESTIONS
]

export const FORMAT_DATE = 'Date'
export const FORMAT_FLOAT = 'Float'
export const FORMAT_INTEGER = 'Integer'

export const NUMERIC_FORMATS = [FORMAT_FLOAT, FORMAT_INTEGER]

export const filterTypeRequiresValue = (filterTypeName) => {
  return ![
    OP_EMPTY,
    OP_NOT_EMPTY,
    OP_HAS_SUGGESTIONS,
    OP_IS_INVALID
  ].includes(filterTypeName)
}
