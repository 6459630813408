import {
  FETCH_PLANS_RESPONSE,
  FETCH_PLAN_RESPONSE,
  FETCH_ADDON_RESPONSE,
  FETCH_WEBSITE_CUSTOMER_RESPONSE,
  FETCH_WEBSITE_SUBSCRIPTION_RESPONSE,
  FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE,
  FETCH_ACCOUNT_SUBSCRIPTION_RESPONSE,
  SET_EXISTING_ACCOUNT_FOR_CONTACT_DETAILS,
  FETCH_EXISTING_WEBSITE_CUSTOMER_RESPONSE,
  FETCH_EXISTING_ACCOUNT_CUSTOMER_RESPONSE,
  FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_RESPONSE
} from 'actions/billing'

import arrayToObject from 'util/array_to_object'

const initialState = {
  customer: {
    metaData: {
      features: {
        canAskQuestions: false,
        canBookMeetings: false,
        canAddTeamMembers: false,
        canAddWebsites: false
      }
    }
  },
  existingAccountForContactDetails: {
    accountTypeId: 0,
    accountType: '',
    customer: {}
  },
  plans: {},
  addons: {},
  subscription: {},
  currency: 'AUD'
}

const plansReducer = (state, action) => {
  switch (action.type) {
    case FETCH_PLANS_RESPONSE:
      return {
        ...state,
        plans: arrayToObject('id', action.plans),
        currency: action.currency
      }
    case FETCH_PLAN_RESPONSE:
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.planId]: action.plan
        }
      }
    default:
      return state
  }
}

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEBSITE_CUSTOMER_RESPONSE:
      const customer = {
        ...action.customer,
        sharedDetails: action.sharedDetails
      }
      customer.metaData = {
        ...(state.customer || {}).metaData,
        ...customer.metaData
      }

      return {
        ...state,
        customer
      }
    case FETCH_WEBSITE_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        subscription: action.subscription
      }
    case FETCH_ACCOUNT_AND_WEBSITE_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        accountSubscription: action.accountSubscription,
        websiteSubscription: action.websiteSubscription
      }
    case FETCH_ACCOUNT_SUBSCRIPTION_RESPONSE:
      return {
        ...state,
        accountSubscription: action.response.accountSubscription
      }
    case FETCH_ADDON_RESPONSE:
      return {
        ...state,
        addons: {
          ...state.addons,
          [action.addonId]: action.addon
        }
      }
    case SET_EXISTING_ACCOUNT_FOR_CONTACT_DETAILS:
      const { accountType, accountTypeId } = action
      return {
        ...state,
        existingAccountForContactDetails: { accountType, accountTypeId }
      }
    case FETCH_EXISTING_WEBSITE_CUSTOMER_RESPONSE:
    case FETCH_EXISTING_ACCOUNT_CUSTOMER_RESPONSE:
    case FETCH_EXISTING_MASTER_ACCOUNT_CUSTOMER_RESPONSE:
      return {
        ...state,
        existingAccountForContactDetails: {
          ...state.existingAccountForContactDetails,
          ...action.customer
        },
        ...action.customer
      }
    default:
      return plansReducer(state, action)
  }
}

export default billingReducer
