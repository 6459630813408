import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { hideFeedOpsFeedbackModal } from 'actions/feed_ops/feedbacks'
import { addFeedOpsFeedback } from 'actions/feed_ops/feedbacks'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap'
import { Check } from '@material-ui/icons'
import FooterAction from './feedback_modal/footer_actions'
import { websiteIdSelector } from 'selectors/websites'
import { feedopsFeedbacksSelector } from 'selectors/feed_ops/feedbacks'

import './feedback_modal.scss'

export class FeedbackModal extends Component {
  static propTypes = {
    showSkipBtn: PropTypes.bool.isRequired,
    websiteId: PropTypes.number.isRequired,
    feedback: PropTypes.object.isRequired,
    hideFeedOpsFeedbackModal: PropTypes.func.isRequired,
    addFeedOpsFeedback: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      input: '',
      isError: false,
      isSuccessfullySubmitted: false,
      isSubmitting: false
    }
  }

  componentDidUpdate(prevProps) {
    const { feedback } = this.props
    const { openModal } = feedback

    if (openModal !== prevProps.feedback.openModal) {
      this.setState({
        input: '',
        isError: false,
        isSuccessfullySubmitted: false,
        isSubmitting: false
      })
    }
  }

  toggleModal() {
    const { isSubmitting } = this.state
    const { hideFeedOpsFeedbackModal } = this.props

    if (!isSubmitting) {
      hideFeedOpsFeedbackModal()
    }
  }

  renderSuccessMessage = () => {
    return (
      <div className='success-container'>
        <span className='success-icon'>
          <Check />
        </span>
        <span className='success-title'>Thank you for your feedback!</span>
        <span className='success-description'>
          Your suggestions are valuable and will help enhance FeedOps.
        </span>
      </div>
    )
  }

  renderErrorMessage = () => {
    const { isError, isFailure } = this.state

    return (
      <>
        {isError && (
          <FormText color='danger' className='error empty'>
            Feedback comment can't be empty
          </FormText>
        )}
        {isFailure && (
          <FormText color='danger' className='error failure'>
            There was an error submitting your feedback. Please try again later.
          </FormText>
        )}
      </>
    )
  }

  handleInputChange(event) {
    const input = event.target.value
    this.setState({ input, isError: !input.trim() })
  }

  renderCommentForm() {
    const { input, isError } = this.state

    return (
      <FormGroup>
        <Label for='feedbackTextArea' className='label'>
          Comment:
        </Label>
        <Input
          type='textarea'
          id='feedbackTextArea'
          placeholder='Enter your feedback here...'
          rows='3'
          value={input}
          invalid={isError}
          className={`comment-textarea ${isError ? 'error' : ''}`}
          onChange={this.handleInputChange.bind(this)}
        />
        {this.renderErrorMessage()}
      </FormGroup>
    )
  }

  renderHeaderAndBody = () => {
    return (
      <>
        <ModalHeader className='title' toggle={this.toggleModal.bind(this)}>
          Provide Feedback
        </ModalHeader>
        <hr />
        <ModalBody>
          <span className='description'>
            Please provide any comments or suggestions. Your feedback helps us
            improve.
          </span>
          {this.renderCommentForm()}
        </ModalBody>
      </>
    )
  }

  async handleSubmit() {
    const { addFeedOpsFeedback, websiteId, feedback } = this.props
    const { feedbackFor, feedbackForId } = feedback
    const { input } = this.state

    if (!input.trim()) {
      this.setState({ isError: true })
    } else {
      this.setState({ isSubmitting: true, isError: false })
      try {
        await addFeedOpsFeedback(websiteId, feedbackFor, feedbackForId, input)
        this.setState({ isSubmitting: false, isSuccessfullySubmitted: true })
      } catch (error) {
        this.setState({ isFailure: true, isSubmitting: false })
      }
    }
  }

  renderModalFooter() {
    const { showSkipBtn } = this.props
    const { isSubmitting, isSuccessfullySubmitted } = this.state

    return (
      <FooterAction
        showSkipBtn={showSkipBtn}
        isSubmitting={isSubmitting}
        isSuccessfullySubmitted={isSuccessfullySubmitted}
        toggleModal={this.toggleModal.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
      />
    )
  }

  render() {
    const { feedback } = this.props
    const { openModal } = feedback
    const { isSuccessfullySubmitted } = this.state

    return (
      <Modal
        fade={openModal}
        isOpen={openModal}
        centered={true}
        className='feedback-modal'
        toggle={this.toggleModal.bind(this)}
      >
        {isSuccessfullySubmitted
          ? this.renderSuccessMessage()
          : this.renderHeaderAndBody()}
        {this.renderModalFooter()}
      </Modal>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedback: feedopsFeedbacksSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ hideFeedOpsFeedbackModal, addFeedOpsFeedback }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackModal)
)
