import React from 'react'
import _ from 'lodash'

const LAST_LOGGED_IN_FIELD = 'lastLoggedIn'

export const COLUMNS = [
  { title: 'Name', field: 'name' },
  { title: 'Work Email', field: 'workEmail' },
  { title: 'Last Logged In', field: LAST_LOGGED_IN_FIELD }
]

export const PER_PAGE = 5
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50]

export const SELECT_ACCOUNT_ACTION = 'Select Account'
export const ADD_USER_ACTION = 'Add User'
export const DELETE_USER_ACTION = 'Delete User'

const invitationValues = ['Invitation Sent', 'Invitation Expired']

const orderUserDataByDate = (userA, userB, orderDirection) => {
  const dateA = invitationValues.includes(userA.date)
    ? new Date(0)
    : new Date(userA.date)
  const dateB = invitationValues.includes(userB.date)
    ? new Date(0)
    : new Date(userB.date)

  if (orderDirection === 'asc') {
    return dateA - dateB
  } else {
    return dateB - dateA
  }
}

const orderUserData = (orderedUsers, orderBy, orderDirection) => {
  var updatedOrderedUsers = orderedUsers
  if (orderBy.field === LAST_LOGGED_IN_FIELD) {
    updatedOrderedUsers = orderedUsers.sort((userA, userB) =>
      orderUserDataByDate(userA, userB, orderDirection)
    )
  } else {
    updatedOrderedUsers = _.orderBy(
      orderedUsers,
      orderBy.field,
      orderDirection || 'asc'
    )
  }

  return updatedOrderedUsers
}

const paginateUserData = (users, page, pageSize, query) => {
  const { orderBy, orderDirection } = query
  var orderedUsers = _.orderBy(users, 'name', 'asc')
  if (orderBy && orderBy.field) {
    orderedUsers = orderUserData(orderedUsers, orderBy, orderDirection)
  }

  const startIndex = page * pageSize
  const endIndex = startIndex + pageSize
  return orderedUsers.slice(startIndex, endIndex)
}

export const getTableData = (accountId, accounts, query) => {
  const { searchText, page, pageSize } = query
  var currentPage = page || 0
  const currentPageSize = PAGE_SIZE_OPTIONS.includes(pageSize)
    ? pageSize
    : PAGE_SIZE_OPTIONS[0]
  var users =
    accountId && accounts && accounts[accountId]
      ? accounts[accountId].users
      : []

  if (searchText && searchText.length > 0) {
    currentPage = 0
    users = users.filter((user) => {
      return (
        user.name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.workEmail.toLowerCase().includes(searchText.toLowerCase())
      )
    })
  }

  return {
    data: paginateUserData(users, currentPage, currentPageSize, query),
    page: currentPage,
    pageSize: currentPageSize,
    totalCount: users.length
  }
}

export const generateTableOptions = (windowHeight) => {
  return {
    idSynonym: 'key',
    actionsColumnIndex: -1,
    columnResizable: false,
    debounceInterval: 1000,
    defaultExpanded: true,
    draggable: false,
    grouping: false,
    maxBodyHeight: `${windowHeight - 325}px`,
    minBodyHeight: `${windowHeight - 325}px`,
    pageSize: PER_PAGE,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTitle: false,
    search: true,
    searchFieldAlignment: 'left',
    sorting: true,
    tableLayout: 'fixed',
    thirdSortClick: false,
    toolbar: true,
    toolbarButtonAlignment: 'right'
  }
}

export const generateTableLocalization = (accountId) => {
  return {
    header: {
      actions: ''
    },
    body: {
      emptyDataSourceMessage: (
        <span className='no-data-message'>
          {accountId ? 'No users found.' : 'Select an account to manage users.'}
        </span>
      )
    }
  }
}

export const generateTableActions = () => {
  var tableActions = [SELECT_ACCOUNT_ACTION, ADD_USER_ACTION].map((action) => ({
    actionName: action,
    position: 'toolbar',
    tooltip: null
  }))

  tableActions.push({
    actionName: DELETE_USER_ACTION,
    tooltip: DELETE_USER_ACTION
  })

  return tableActions
}
