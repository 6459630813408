import { RECEIVE_MERCHANT_CENTER_FEED } from 'actions/merchant_center_feed';

var defaultState = { feedUrl: null }

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_MERCHANT_CENTER_FEED:
      return { 
      ...state,
      ...action.data
      };
    default:
      return state;
  }
}

export default reducer;