import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import AuditHeaderTemplate from './header_template'
import Summary from '../summary'
import AuditTableTemplate from './table_template'
import { playbookItemsByChannelSelector } from 'selectors/playbook/playbook_progress'
import { PLAYBOOK_CHANNELS } from 'util/supported_channels'
import { getHealthColour, getSeverityColour } from '../playbook_util'
import changeCase from 'change-case'

export class AuditByChannel extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    channelPlaybookItems: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  renderPillStyleCell(data, borderColor) {
    return (
      <div
        className='common-audit-cell-container'
        style={{ borderColor: borderColor }}
      >
        {data}
      </div>
    )
  }

  getHealthTableColumns() {
    return [
      { name: 'Issue', selector: (row) => row.name },
      {
        name: 'Status',
        selector: (row) => row.health,
        center: true,
        width: '120px',
        cell: (row) =>
          this.renderPillStyleCell(row.health, getHealthColour(row.health))
      }
    ]
  }

  renderHealthTable() {
    const { channelPlaybookItems } = this.props
    const healthItems = channelPlaybookItems.filter(
      (item) =>
        item.possibleScore === 0 &&
        item.enabled &&
        item.title !== 'Connect Your eCommerce Store'
    )

    const columns = this.getHealthTableColumns()
    var data = []
    healthItems.forEach((item) => {
      data.push({ id: item.id, name: item.title, health: item.health })
    })

    return (
      <AuditTableTemplate
        columns={columns}
        data={data}
        noDataMessage='Health Check: OK'
      />
    )
  }

  renderHealthCheck(channel) {
    return (
      <>
        <h4>{changeCase.titleCase(channel)} health check</h4>
        {this.renderHealthTable()}
      </>
    )
  }

  getOptimisationTableColumns() {
    return [
      { name: 'Opportunity', selector: (row) => row.name },
      {
        name: 'Products Affected',
        selector: (row) => row.affectedProducts,
        center: true,
        width: '180px'
      },
      {
        name: 'Products Affected (%)',
        selector: (row) => row.affectedProductsPercent,
        center: true,
        width: '180px'
      },
      {
        name: 'Severity',
        selector: (row) => row.severity,
        center: true,
        width: '180px',
        cell: (row) =>
          this.renderPillStyleCell(
            row.severity,
            getSeverityColour(row.severity)
          )
      }
    ]
  }

  renderOptimisationTable() {
    const { channelPlaybookItems } = this.props
    const optItems = _.sortBy(channelPlaybookItems, 'score')
      .filter((item) => item.possibleScore !== 0 && item.display)
      .reverse()

    const columns = this.getOptimisationTableColumns()
    var data = []
    optItems.forEach((item) => {
      data.push({
        id: item.id,
        name: item.title,
        affectedProducts: item.affectedProducts,
        affectedProductsPercent: item.affectedProductsPercent,
        severity: item.severity
      })
    })

    return (
      <AuditTableTemplate
        columns={columns}
        data={data}
        noDataMessage='Optimisation Opportunities: OK'
      />
    )
  }

  renderOptimisationOpp(channel) {
    return (
      <>
        <h4>{changeCase.titleCase(channel)} optimisation opportunities</h4>
        {this.renderOptimisationTable()}
      </>
    )
  }

  render() {
    const { channel } = this.props

    return (
      <div id={`${channel}-audit`}>
        <AuditHeaderTemplate
          headerText={`${changeCase.titleCase(channel)} product data audit`}
        />
        <div className='audit-page-body'>
          <Summary channel={channel} showHideButton={false} />
          {this.renderHealthCheck(channel)}
          {this.renderOptimisationOpp(channel)}
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  channelPlaybookItems: playbookItemsByChannelSelector(props.channel)(
    state,
    props
  )
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditByChannel)
)
