import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import googleIcon from 'images/google-icon-dark.png'
import { MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdbreact'
import { googleAuthLogin } from 'util/google/google_apis'
import DontHaveAnAccountYetPrompt from 'components/sign_up/dont_have_an_account_yet_prompt'
import { appIdSelector } from 'selectors/app'

export class SignInOptions extends Component {
  static propTypes = {
    googleAuthLogin: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired
  }

  static defaultProps = {
    googleAuthLogin
  }

  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = { loggingIn: false }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleRedirect() {
    const { appId, history } = this.props
    history.push(`/${appId}`)
  }

  async login() {
    const { googleAuthLogin, appId } = this.props
    this.setState({ loggingIn: true })
    try {
      await googleAuthLogin(appId)
      this.handleRedirect()
    } catch (error) {}

    if (this._isMounted) {
      this.setState({ loggingIn: false })
    }
  }

  renderSignInButton() {
    const { loggingIn } = this.state

    return (
      <div className={'sign-in-options'}>
        <MDBBtn
          className={'sign-in-with-google'}
          onClick={this.login.bind(this)}
          disabled={loggingIn}
        >
          <img alt={'Google icon'} src={googleIcon} />
          <div>
            Sign in with google
            {loggingIn && ' '}
            {loggingIn && <MDBIcon spin fixed icon='spinner' />}
          </div>
        </MDBBtn>
      </div>
    )
  }

  render() {
    return (
      <>
        <MDBCardTitle>Sign in to FeedOps</MDBCardTitle>
        <MDBCardText>
          Sign in with Google to access your FeedOps account.
        </MDBCardText>
        {this.renderSignInButton()}
        <DontHaveAnAccountYetPrompt />
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  appId: appIdSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignInOptions)
)
