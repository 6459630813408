import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { MDBSideNav, MDBSideNavNav, MDBSideNavLink, MDBIcon } from 'mdbreact'
import { subscriptionSelector } from 'selectors/billing'

import './mobile_container.scss'

const IN_TRIAL_STATUS = 'in_trial'

export class SideNavMobileContainer extends Component {
  static propTypes = {
    sideNavOpen: PropTypes.bool.isRequired,
    subscriptionDetails: PropTypes.object.isRequired
  }

  renderProductFeedsLink() {
    const { match } = this.props

    return (
      <MDBSideNavLink to={`${match.url}/website/connected_channels`}>
        <MDBIcon icon='tags' className='fas fa-tags' />
        Product Feeds
      </MDBSideNavLink>
    )
  }

  renderSubscriptionDetails() {
    const { subscriptionDetails } = this.props
    const { plan, planAmount, currencyCode, status, nextBillingAt } =
      subscriptionDetails
    const { name } = plan
    const nextBillingDate = new Date(nextBillingAt * 1000)

    return (
      <MDBSideNavLink disabled={true} className='disabled'>
        {name} - {planAmount / 100} {currencyCode}
        <br />
        {status === IN_TRIAL_STATUS
          ? 'Your trial ends: '
          : 'Your next renewal is on: '}
        {nextBillingDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}
      </MDBSideNavLink>
    )
  }

  renderSideNavNavContent() {
    const { subscriptionDetails } = this.props

    return (
      <>
        {this.renderProductFeedsLink()}
        {!_.isEmpty(subscriptionDetails) && this.renderSubscriptionDetails()}
        <MDBSideNavLink disabled={true} className='disabled'>
          Log in with your PC to access feed optimization tools, automated
          rules, and other settings.
        </MDBSideNavLink>
      </>
    )
  }

  render() {
    const { sideNavOpen } = this.props

    return (
      <MDBSideNav
        triggerOpening={sideNavOpen}
        className='side-nav side-nav-light mobile side-nav-mobile'
        showOverlay={false}
        responsive={true}
      >
        <MDBSideNavNav>{this.renderSideNavNavContent()}</MDBSideNavNav>
      </MDBSideNav>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  sideNavOpen: state.app.sideNavOpen || false,
  subscriptionDetails: subscriptionSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideNavMobileContainer)
)
