import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_RULES_REQUEST = 'FETCH_RULES_REQUEST'
export const FETCH_RULES_RESPONSE = 'FETCH_RULES_RESPONSE'
export const UPDATE_ALL_RULES_REQUEST = 'UPDATE_ALL_RULES_REQUEST'
export const UPDATE_ALL_RULES_RESPONSE = 'UPDATE_ALL_RULES_RESPONSE'
export const PREVIEW_RULES_REQUEST = 'PREVIEW_RULES_REQUEST'
export const PREVIEW_RULES_RESPONSE = 'PREVIEW_RULES_RESPONSE'
export const EXPORT_RULES_PREVIEW_REQUEST = 'EXPORT_RULES_PREVIEW_REQUEST'
export const EXPORT_RULES_PREVIEW_RESPONSE = 'EXPORT_RULES_PREVIEW_RESPONSE'

const fetchRulesRequest = (websiteId) => ({
  type: FETCH_RULES_REQUEST,
  websiteId
})

const fetchRulesResponse = (websiteId, response) => ({
  type: FETCH_RULES_RESPONSE,
  websiteId,
  ...response
})

export const fetchRules = (websiteId) => async (dispatch) => {
  dispatch(fetchRulesRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/inventories/rules?website_id=${websiteId}`,
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchRulesResponse(websiteId, response))
  return response
}

const updateAllRulesRequest = (websiteId, rules) => ({
  type: UPDATE_ALL_RULES_REQUEST,
  websiteId,
  rules
})

const updateAllRulesResponse = (websiteId, rules, response) => ({
  type: UPDATE_ALL_RULES_RESPONSE,
  websiteId,
  rules,
  ...response
})

export const updateAllRules = (websiteId, rules) => async (dispatch) => {
  dispatch(updateAllRulesRequest(websiteId, rules))
  var response = await $.ajax({
    method: 'POST',
    url: `/ad_champion/inventories/rules/1/update_all_rules?website_id=${websiteId}`,
    data: {
      rules: JSON.stringify(
        rules.map((rule) => snakeCaseKeys(rule, { deep: true }))
      )
    },
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(updateAllRulesResponse(websiteId, rules, response))
  return response
}

const previewRulesRequest = (websiteId, feedId, rules, search) => ({
  type: PREVIEW_RULES_REQUEST,
  websiteId,
  feedId,
  rules,
  search
})

const previewRulesResponse = (websiteId, feedId, rules, search, response) => ({
  type: PREVIEW_RULES_RESPONSE,
  websiteId,
  feedId,
  rules,
  search,
  ...response
})

export const previewRules =
  (websiteId, feedId, rules, search) => async (dispatch) => {
    dispatch(previewRulesRequest(websiteId, feedId, rules, search))
    var data = {
      websiteId,
      feedId,
      rules: JSON.stringify(
        rules.map((rule) => snakeCaseKeys(rule, { deep: true }))
      ),
      search
    }
    var response = await $.ajax({
      method: 'POST',
      url: `/ad_champion/inventories/rules/preview`,
      dataType: 'json',
      data: snakeCaseKeys(data)
    })
    response = camelCaseKeys(response, { deep: true })
    dispatch(previewRulesResponse(websiteId, feedId, rules, search, response))
    return response
  }

const exportRulesPreviewRequest = (feedId, rules) => ({
  type: EXPORT_RULES_PREVIEW_REQUEST,
  feedId,
  rules
})

const exportRulesPreviewResponse = (feedId, rules, response) => ({
  type: EXPORT_RULES_PREVIEW_RESPONSE,
  feedId,
  rules,
  ...response
})

export const exportRulesPreview = (feedId, rules) => async (dispatch) => {
  dispatch(exportRulesPreviewRequest(feedId, rules))

  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }
  var data = {
    feedId,
    rules: JSON.stringify(rules.map((r) => snakeCaseKeys(r, { deep: true })))
  }

  var args = {
    method: 'POST',
    url: '/ad_champion/inventories/rules/preview/export',
    dataType: 'json',
    data: snakeCaseKeys(data, { deep: true }),
    statusCode
  }
  var response = await $.ajax(args)
  args.data.operation_id = response.operation_id
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax(args)
  }
  response = camelCaseKeys(response, { deep: true })
  dispatch(exportRulesPreviewResponse(feedId, rules, response))
  return response
}
