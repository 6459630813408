import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  change,
  reduxForm,
  getFormValues,
  registerField,
  getFormSyncErrors
} from 'redux-form'
import _ from 'lodash'
import toastr from 'toastr'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  fetchInventoryAttributeReplacements,
  searchInventoryAttributeReplacements,
  saveInventoryAttributeReplacements,
  clearUpdatedInventoryAttributeReplacements,
  clearInventoryAttributeReplacements
} from 'actions/product_feeds/replacements'
import {
  websiteAttributesSelector,
  websiteAttributeReplacementsSelector,
  websiteUpdatedAttributeReplacementsSelector
} from 'selectors/product_feeds/replacements'
import { websiteIdSelector } from 'selectors/websites'
import AttributeList from 'components/feed/optimisation/attribute_list'
import ProductAttributeListNode from './attribute_list_node'
import { validate } from './validate'

export class ProductAttributeList extends Component {
  timer
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    attributes: PropTypes.object.isRequired,
    attributeValues: PropTypes.object.isRequired,
    updatedAttributeValues: PropTypes.object.isRequired,
    fetchInventoryAttributeReplacements: PropTypes.func.isRequired,
    searchInventoryAttributeReplacements: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  }

  static defaultProps = {
    toastr
  }

  constructor(props) {
    super(props)
    this.state = {
      searchKeyword: '',
      filteredAttributes: {}
    }
  }

  async componentDidMount() {
    const { registerField, attributes } = this.props
    registerField(REDUX_FORM_NAME, 'attributeValues', 'Field')
    if (Object.values(attributes).length > 0) {
      this.setState({ filteredAttributes: attributes })
    }
  }

  setExpandedForExisting() {
    const { attributes } = this.props
    var filteredAttributes = { ...attributes }
    var prevAttr = this.state.filteredAttributes
    if (Object.values(prevAttr).length > 0) {
      Object.values(filteredAttributes).forEach((attribute) => {
        filteredAttributes[attribute.id].expanded =
          prevAttr[attribute.id].expanded
      })
    }
    this.setState({ filteredAttributes: filteredAttributes })
  }

  async componentDidUpdate(prevProps) {
    const { attributes, attributeValues, formErrors } = this.props

    if (
      !_.isEqual(prevProps.attributes, attributes) ||
      !_.isEqual(prevProps.attributeValues, attributeValues)
    ) {
      this.setExpandedForExisting()
    }

    if (!_.isEqual(prevProps.formErrors, formErrors)) {
      const { filteredAttributes } = this.state
      const { formValues } = this.props
      this.applyErrors(filteredAttributes, formValues.attributeValues)
    }
  }

  async fetchInventoryAttributeReplacements(attributeId) {
    const { fetchInventoryAttributeReplacements, websiteId, toastr } =
      this.props
    try {
      await fetchInventoryAttributeReplacements(websiteId, attributeId)
    } catch (error) {
      toastr.error(
        'An error occurred while fetching your attributes. Please contact support for assistance.'
      )
    }
  }

  setLoadingForAttributes(value) {
    const { filteredAttributes } = this.state
    var newFilteredAttributes = { ...filteredAttributes }
    Object.keys(filteredAttributes).forEach((attrId) => {
      newFilteredAttributes[attrId].isLoading = value
    })
    this.setState({ filteredAttributes: newFilteredAttributes })
  }

  async searchInventoryAttributeReplacements() {
    const { attributes, websiteId, searchInventoryAttributeReplacements } =
      this.props
    const { searchKeyword } = this.state
    this.setLoadingForAttributes(true)
    try {
      if (searchKeyword) {
        await searchInventoryAttributeReplacements(
          websiteId,
          attributes,
          searchKeyword
        )
      }
    } catch (error) {
      toastr.error(
        'An error occurred while searching your attributes. Please contact support for assistance.'
      )
    }
    this.setLoadingForAttributes(false)
  }

  updateAttributeReplacements() {
    const { attributeValues, updatedAttributeValues, change } = this.props
    if (updatedAttributeValues) {
      Object.entries(updatedAttributeValues).forEach(
        ([attributeId, attribute]) => {
          Object.entries(attribute).forEach(([id, value]) => {
            if (attributeValues[attributeId][id]) {
              change(
                REDUX_FORM_NAME,
                `attributeValues[${attributeId}][${id}].replacement`,
                value
              )
              change(
                REDUX_FORM_NAME,
                `enabled-${attributeId}-${id}`,
                value ? true : false
              )
            }
          })
        }
      )
    }
  }

  findError(attribute) {
    const { attributeValues } = this.props.formErrors
    return (
      attributeValues &&
      attributeValues[attribute.parentId] &&
      attributeValues[attribute.parentId][attribute.id]
    )
  }

  applyErrors(filteredAttributes, filteredAttributeValues) {
    Object.values(filteredAttributes).forEach((attribute) => {
      attribute.childHasError = false
      Object.values(filteredAttributeValues[attribute.id] || {}).forEach(
        (attrValue) => {
          attrValue.error = this.findError(attrValue)
          if (attrValue.error) {
            attribute.childHasError = true
          }
        }
      )
    })
  }

  async initializeForm() {
    const { initialize, attributes, attributeValues } = this.props
    if (
      Object.keys(attributes).length > 0 &&
      Object.keys(attributeValues).length > 0
    ) {
      await initialize({
        attributeValues,
        ...computeEnabledRewrites(attributeValues)
      })
    }
  }

  expandMatches() {
    const { searchKeyword, filteredAttributes } = this.state
    const { attributes, attributeValues } = this.props
    if (searchKeyword && searchKeyword.length > 0) {
      var newFilteredAttributes = { ...filteredAttributes }
      Object.keys(attributes).forEach((attrId) => {
        if (Object.entries(attributeValues[attrId]).length > 0) {
          newFilteredAttributes[attrId].expanded = true
        }
      })
      this.setState({ filteredAttributes: newFilteredAttributes })
    } else {
      this.handleCollapseAllAttributes()
    }
  }

  async filterAttributeValues() {
    const { clearInventoryAttributeReplacements, websiteId } = this.props
    const { searchKeyword } = this.state
    if (searchKeyword === '') {
      await clearInventoryAttributeReplacements(websiteId)
      await this.initializeForm()
    } else {
      await this.searchInventoryAttributeReplacements()
      await this.initializeForm()
      this.updateAttributeReplacements()
    }
    this.handleNoResults()
    this.expandMatches()
  }

  handleSearchKeywordChanged(newSearchValue) {
    this.setState({ searchKeyword: newSearchValue })

    window.clearTimeout(this.timer)
    this.timer = window.setTimeout(() => {
      this.filterAttributeValues()
    }, 1000)
  }

  handleNoResults() {
    const { attributeValues } = this.props
    const { filteredAttributes, searchKeyword } = this.state
    var newFilteredAttributes = { ...filteredAttributes }
    Object.values(newFilteredAttributes).forEach((attr) => {
      attr.noResults =
        searchKeyword != '' && Object.keys(attributeValues[attr.id]).length < 1
    })
    this.setState({ filteredAttributes: newFilteredAttributes })
  }

  handleCollapseAllAttributes() {
    const { filteredAttributes } = this.state
    var newFilteredAttributes = { ...filteredAttributes }
    Object.values(newFilteredAttributes).forEach((attr) => {
      attr.expanded = false
    })

    this.setState({ filteredAttributes: newFilteredAttributes })
  }

  async handleCollapseAttribute(attributeId) {
    const { attributeValues } = this.props
    if (!attributeValues[attributeId]) {
      await this.fetchInventoryAttributeReplacements(attributeId)
      await this.initializeForm()
      this.updateAttributeReplacements()
    }

    const { filteredAttributes } = this.state
    var newFilteredAttributes = { ...filteredAttributes }
    var filteredAttribute = newFilteredAttributes[attributeId]
    filteredAttribute.expanded = !filteredAttribute.expanded
    this.setState({ filteredAttributes: newFilteredAttributes })
  }

  render() {
    const { searchKeyword, filteredAttributes } = this.state
    const { formValues } = this.props
    return (
      <AttributeList
        ChildComponent={ProductAttributeListNode}
        searchKeyword={searchKeyword}
        onSearchKeywordChanged={this.handleSearchKeywordChanged.bind(this)}
        onToggleAttribute={this.handleCollapseAttribute.bind(this)}
        onCollapseAllAttributes={this.handleCollapseAllAttributes.bind(this)}
        attributes={filteredAttributes}
        attributeValues={formValues.attributeValues || {}}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  attributes: websiteAttributesSelector(state, props),
  attributeValues: websiteAttributeReplacementsSelector(state, props),
  updatedAttributeValues: websiteUpdatedAttributeReplacementsSelector(
    state,
    props
  ),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
  formErrors: getFormSyncErrors(REDUX_FORM_NAME)(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      registerField,
      fetchInventoryAttributeReplacements,
      searchInventoryAttributeReplacements,
      saveInventoryAttributeReplacements,
      clearUpdatedInventoryAttributeReplacements,
      clearInventoryAttributeReplacements
    },
    dispatch
  )

export const REDUX_FORM_NAME = 'ProductAttributesOptimiserList'

export const computeEnabledRewrites = (attributeValues) => {
  var attributeValuesEnabled = {}
  Object.entries(attributeValues).forEach(([attributeId, attrVals]) => {
    Object.values(attrVals).forEach((attrVal) => {
      attributeValuesEnabled[`enabled-${attributeId}-${attrVal.id}`] =
        attrVal.replacement !== null
    })
  })
  return attributeValuesEnabled
}

const computeAttributeValues = (formValues) => {
  var attributeValues = {}
  Object.entries(formValues.attributeValues).forEach(
    ([attributeId, attrVals]) => {
      attributeValues[attributeId] = {}
      Object.values(attrVals).forEach((attrVal) => {
        const enabled = formValues[`enabled-${attributeId}-${attrVal.id}`]
        const replacement = enabled ? attrVal.replacement : null
        attributeValues[attributeId][attrVal.id] = {
          ...attrVal,
          replacement
        }
      })
    }
  )
  return attributeValues
}

export const handleSubmit = async (values, dispatch, props) => {
  const {
    saveInventoryAttributeReplacements,
    clearUpdatedInventoryAttributeReplacements,
    initialize,
    websiteId
  } = props
  try {
    await saveInventoryAttributeReplacements(
      websiteId,
      computeAttributeValues(values)
    )
    toastr.success(
      'Attribute optimisations applied. Your feed is being generated.'
    )
    const attributeValues = values.attributeValues
    await clearUpdatedInventoryAttributeReplacements(websiteId, attributeValues)
    await initialize({
      attributeValues,
      ...computeEnabledRewrites(attributeValues)
    })
  } catch (error) {
    toastr.error(
      'An error occurred while saving your attributes. Please contact support for assistance.'
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      validate,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(ProductAttributeList))
  )
)
