import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GaugeChart from 'react-gauge-chart'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { withRouter } from 'react-router'
import { MDBCard, MDBCardBody, MDBCardText, MDBCardFooter } from 'mdbreact'
import {
  HEALTH_IN_ORDER,
  HEALTH_ERROR,
  HEALTH_CRITICAL,
  HEALTH_WARNING,
  channelHealthSelector
} from 'selectors/playbook/playbook_progress'

import { PLAYBOOK_CHANNELS, CHANNEL_ALL } from 'util/supported_channels'
import { COLOR_RED, COLOR_GREEN, getHealthColour } from './playbook_util'

export class HealthScoreCard extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(PLAYBOOK_CHANNELS).isRequired,
    health: PropTypes.oneOf(HEALTH_IN_ORDER).isRequired
  }

  chartPercentage() {
    const { health } = this.props

    switch (health) {
      case HEALTH_CRITICAL:
      case HEALTH_ERROR:
        return 0.17
      case HEALTH_WARNING:
        return 0.5
      default:
        return 0.83
    }
  }

  renderAllChannelHealth() {
    const { channel, health } = this.props
    return channel === CHANNEL_ALL ? (
      <span style={{ display: 'none' }} id='all-channel-health-score'>
        {health}
      </span>
    ) : null
  }

  render() {
    const { health } = this.props

    return (
      <MDBCard className='channel-summary'>
        <MDBCardBody>
          {this.renderAllChannelHealth()}
          <div className='d-flex justify-content-center'>
            <ReactResizeDetector handleWidth handleHeight>
              <GaugeChart
                className='chart'
                colors={[COLOR_RED, COLOR_GREEN]}
                textColor={getHealthColour(health)}
                needleColor='#999999'
                nrOfLevels={3}
                percent={this.chartPercentage()}
                animate={false}
                formatTextValue={(value) => health}
              />
            </ReactResizeDetector>
          </div>
        </MDBCardBody>
        <MDBCardFooter>
          <MDBCardText className='text-center'>Health</MDBCardText>
        </MDBCardFooter>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => ({
  health: channelHealthSelector(props.channel)(state, props)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HealthScoreCard)
)
