import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST'
export const FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_SUCCESS =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_SUCCESS'
export const FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST'
export const FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_SUCCESS =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_SUCCESS'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_RESPONSE =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_RESPONSE'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_RESPONSE =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_RESPONSE'
export const FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST'
export const FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS =
  'FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST'
export const UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS =
  'UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS'

const fetchBudgetCategoryAdvertisingBudgetsRequest = (budgetCategoryId) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST,
  budgetCategoryId
})

const fetchBudgetCategoryAdvertisingGoalsRequest = (
  budgetCategoryId,
  goalType
) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST,
  budgetCategoryId,
  goalType
})

const fetchBudgetCategoryAdvertisingBudgetsSuccess = (
  budgetCategoryId,
  advertisingBudgets
) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_BUDGETS_SUCCESS,
  budgetCategoryId,
  ...advertisingBudgets
})

const fetchBudgetCategoryAdvertisingGoalsSuccess = (
  budgetCategoryId,
  goalType,
  advertisingGoals
) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_GOALS_SUCCESS,
  budgetCategoryId,
  goalType,
  ...advertisingGoals
})

export const fetchBudgetCategoryAdvertisingBudgets = (
  budgetCategoryId
) => async (dispatch) => {
  dispatch(fetchBudgetCategoryAdvertisingBudgetsRequest(budgetCategoryId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/load_budgets`,
    dataType: 'json'
  })

  dispatch(
    fetchBudgetCategoryAdvertisingBudgetsSuccess(
      budgetCategoryId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

export const fetchBudgetCategoryAdvertisingGoals = (
  budgetCategoryId,
  goalType
) => async (dispatch) => {
  dispatch(
    fetchBudgetCategoryAdvertisingGoalsRequest(budgetCategoryId, goalType)
  )
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/load_goals`,
    data: { goal_type: goalType }
  })

  dispatch(
    fetchBudgetCategoryAdvertisingGoalsSuccess(
      budgetCategoryId,
      goalType,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const updateBudgetCategoryAdvertisingBudgetsRequest = (
  budgetCategoryId,
  budgets
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_REQUEST,
  budgetCategoryId,
  budgets
})

const updateBudgetCategoryAdvertisingBudgetsResponse = (
  budgetCategoryId,
  budgets,
  response
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_BUDGETS_RESPONSE,
  budgetCategoryId,
  budgets,
  response
})

export const updateBudgetCategoryAdvertisingBudgets = (
  budgetCategoryId,
  budgets
) => async (dispatch) => {
  dispatch(
    updateBudgetCategoryAdvertisingBudgetsRequest(budgetCategoryId, budgets)
  )
  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/update_budgets`,
    data: snakeCaseKeys({ advertising_budget: budgets }, { deep: true })
  })
  var camelCaseResponse = camelCaseKeys(response, { deep: true })
  dispatch(
    updateBudgetCategoryAdvertisingBudgetsResponse(
      budgetCategoryId,
      budgets,
      camelCaseResponse
    )
  )
  return camelCaseResponse
}

const updateBudgetCategoryAdvertisingGoalsRequest = (
  budgetCategoryId,
  goalType,
  goals
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_REQUEST,
  budgetCategoryId,
  goalType,
  goals
})

const updateBudgetCategoryAdvertisingGoalsResponse = (
  budgetCategoryId,
  goalType,
  goals,
  response
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_GOALS_RESPONSE,
  budgetCategoryId,
  goalType,
  goals,
  response
})

export const updateBudgetCategoryAdvertisingGoals = (
  budgetCategoryId,
  goalType,
  goals
) => async (dispatch) => {
  dispatch(
    updateBudgetCategoryAdvertisingGoalsRequest(
      budgetCategoryId,
      goalType,
      goals
    )
  )
  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/update_goals`,
    data: snakeCaseKeys({ goalType: goalType, advertising_goal: goals })
  })

  var camelCaseResponse = camelCaseKeys(response, { deep: true })
  dispatch(
    updateBudgetCategoryAdvertisingGoalsResponse(
      budgetCategoryId,
      goalType,
      goals,
      camelCaseResponse
    )
  )
}

const fetchBudgetCategoryAdvertisingGoalTypeRequest = (budgetCategoryId) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST,
  budgetCategoryId
})

const fetchBudgetCategoryAdvertisingGoalTypeSuccess = (
  budgetCategoryId,
  advertisingGoalType
) => ({
  type: FETCH_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS,
  budgetCategoryId,
  advertisingGoalType
})

export const fetchBudgetCategoryAdvertisingGoalType = (
  budgetCategoryId
) => async (dispatch) => {
  dispatch(fetchBudgetCategoryAdvertisingGoalTypeRequest(budgetCategoryId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/load_goal_type`
  })
  dispatch(
    fetchBudgetCategoryAdvertisingGoalTypeSuccess(
      budgetCategoryId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const updateBudgetCategoryAdvertisingGoalTypeRequest = (
  budgetCategoryId,
  goalType
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_REQUEST,
  budgetCategoryId,
  goalType
})

const updateBudgetCategoryAdvertisingGoalTypeSuccess = (
  budgetCategoryId,
  goalType,
  response
) => ({
  type: UPDATE_BUDGET_CATEGORY_ADVERTISING_GOAL_TYPE_SUCCESS,
  budgetCategoryId,
  goalType,
  response
})

export const updateBudgetCategoryAdvertisingGoalType = (
  budgetCategoryId,
  goalType
) => async (dispatch) => {
  dispatch(
    updateBudgetCategoryAdvertisingGoalTypeRequest(budgetCategoryId, goalType)
  )
  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/budget_categories/${budgetCategoryId}/advertising/update_goal_type`,
    data: { goal_type: goalType },
    dataType: 'json'
  })

  dispatch(
    updateBudgetCategoryAdvertisingGoalTypeSuccess(
      budgetCategoryId,
      goalType,
      response
    )
  )
}
