import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchWebsiteDetail } from 'actions/websites'
import AuditOverview from './audit_overview'
import AuditByChannel from './audit_by_channel'
import { websiteIdSelector } from 'selectors/websites'
import { playbookProgressWebsiteChannelSelector } from 'selectors/playbook/playbook_progress'
import {
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT
} from 'util/supported_channels'

export class AuditTemplate extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    playbookItems: PropTypes.array.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  async componentDidMount() {
    const { fetchWebsiteDetail, websiteId } = this.props
    await fetchWebsiteDetail(websiteId)
  }

  async componentDidUpdate(prevProps) {
    const { fetchWebsiteDetail, websiteId } = this.props
    if (prevProps.websiteId !== websiteId) {
      await fetchWebsiteDetail(websiteId)
    }
  }

  renderGoogleAudit() {
    return <AuditByChannel channel={CHANNEL_GOOGLE} />
  }

  renderFacebookAudit() {
    return <AuditByChannel channel={CHANNEL_FACEBOOK} />
  }

  renderMicrosoftAudit() {
    return <AuditByChannel channel={CHANNEL_MICROSOFT} />
  }

  renderAllChannel() {
    return (
      <>
        <AuditOverview />
        <hr />
        {this.renderGoogleAudit()}
        <hr />
        {this.renderFacebookAudit()}
        <hr />
        {this.renderMicrosoftAudit()}
      </>
    )
  }

  renderAudits() {
    return (
      <div id='product-data-audit-report' className='audit-report-container'>
        {this.renderAllChannel()}
      </div>
    )
  }

  render() {
    const { playbookItems } = this.props
    return playbookItems && playbookItems.length > 0
      ? this.renderAudits()
      : null
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  playbookItems: playbookProgressWebsiteChannelSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchWebsiteDetail }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditTemplate)
)
