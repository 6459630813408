import { createSelector } from 'reselect'

const performanceSelector = (state) => (state.google || {}).performance || {}

const performanceWebsitesSelector = createSelector(
  [performanceSelector],
  (p) => p.websites || {}
)

const performanceWebsiteSelector = (websiteId) =>
  createSelector(
    [performanceWebsitesSelector],
    (websites) => websites[websiteId] || {}
  )

const performanceBudgetCategoriesSelector = createSelector(
  [performanceSelector],
  (performance) => performance.budgetCategories || {}
)

export const performanceBudgetCategorySelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategoriesSelector],
    (budgetCategories) => budgetCategories[budgetCategoryId] || { data: [] }
  )

export const budgetCategoryPerformanceByCampaignTypeSelector = (
  budgetCategoryId
) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategory) => budgetCategory.performanceByCampaignType || []
  )

export const budgetCategoryRecommendationSelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategory) => budgetCategory.recommendations || {}
  )

export const websitePerformanceByBudgetCategoryReportSelector = (websiteId) =>
  createSelector(
    [performanceWebsiteSelector(websiteId)],
    (website) => website.websiteByBudgetCategory || {}
  )

export const websitePerformanceByBudgetCategorySelector = (websiteId) =>
  createSelector(
    [websitePerformanceByBudgetCategoryReportSelector(websiteId)],
    (websiteByBudgetCategory) =>
      websiteByBudgetCategory.budgetCategoryReports || []
  )
