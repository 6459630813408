export const findDuplicates = (array) => {
  var uniq = (array || [])
    .map((name) => {
      return {
        count: 1,
        name: name
      }
    })
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count
      return a
    }, {})

  return Object.keys(uniq).filter((a) => uniq[a] > 1)
}

export const findEmptyTitles = (array) => {
  return (array || [])
    .filter((field) => field.value.replace(/\s/g, '') === '')
    .map((field) => field.value)
}
