import {
  filterCategories,
  matchesKeyword
} from 'components/feed/optimisation/filter'

const fetchAllParentErrorNodes = (errorCategory, depth, categories) => {
  var parentErrorNodes = []
  var currentDepth = depth
  var parentCategory

  while (currentDepth > 1) {
    const id =
      currentDepth === depth ? errorCategory.parentId : parentCategory.parentId
    parentCategory = categories[currentDepth - 1][id]
    parentErrorNodes.push(parentCategory.id)
    currentDepth -= 1
  }

  return parentErrorNodes
}

const updateErrorNodes = (
  errorCategory,
  errorNodes,
  depth,
  categories,
  onlyParentNodes
) => {
  if (errorCategory.parentId === 0) {
    errorNodes.push(errorCategory.id)
  } else {
    if (!onlyParentNodes) {
      errorNodes.push(errorCategory.id)
    }
    errorNodes.push(fetchAllParentErrorNodes(errorCategory, depth, categories))
  }

  return errorNodes
}

const fetchErrorNodes = (
  errorNodes,
  errors,
  depth,
  categories,
  onlyParentNodes
) => {
  Object.keys(errors).forEach((id) => {
    const errorCategory = categories[depth][id]
    errorNodes = updateErrorNodes(
      errorCategory,
      errorNodes,
      depth,
      categories,
      onlyParentNodes
    )
  })

  return errorNodes.flat(1)
}

const loopErrorCategories = (
  errorNodes,
  categoryErrors,
  categories,
  onlyParentNodes
) => {
  categoryErrors.forEach((errors, depth) => {
    if (errors && Object.keys(errors).length > 0) {
      errorNodes = fetchErrorNodes(
        errorNodes,
        errors,
        depth,
        categories,
        onlyParentNodes
      )
    }
  })

  return errorNodes
}

export const calculateErrorNodes = (
  formErrors,
  categories,
  onlyParentNodes = false
) => {
  var errorNodes = []
  const categoryErrors =
    formErrors &&
    Object.keys(formErrors).length > 0 &&
    formErrors.categoryReplacements

  if (categoryErrors && categoryErrors.length > 0) {
    errorNodes = loopErrorCategories(
      errorNodes,
      categoryErrors,
      categories,
      onlyParentNodes
    )
  }

  errorNodes = errorNodes.length > 0 ? [...new Set(errorNodes)] : []
  return errorNodes
}

const checkIfMatchReplacement = (category, replacements, searchKeyword) => {
  var replacement =
    replacements[category.depth] && replacements[category.depth][category.id]
  return matchesKeyword(searchKeyword, replacement)
}

const matchOnCategoryNameOrReplacement =
  (replacements) => (category, searchKeyword) => {
    var match = matchesKeyword(searchKeyword, category.name || category.label)
    if (!match) {
      match = checkIfMatchReplacement(category, replacements, searchKeyword)
    }
    return match
  }

const generateAllTreeNodes = (keywordMatchingCategories, errorNodes) => {
  keywordMatchingCategories.shift()
  var allCategories = keywordMatchingCategories
    .map((level) => Object.values(level))
    .flat(1)
  var allTreeNodes = []

  allCategories.forEach((category) => {
    allTreeNodes.push({
      id: category.id,
      taxonomyId: category.taxonomyId,
      key: category.id,
      label: category.name,
      depth: category.depth,
      parentId: category.parentId,
      excluded: category.excluded,
      sortOrder: category.sortOrder,
      hasError: errorNodes && errorNodes.includes(category.id),
      nodes: []
    })
  })

  return allTreeNodes
}

const generateCategoryTreeHierarchy = (allTreeNodes, filteredValues) => {
  var filteredCategories = filteredValues.filteredCategories
  var openNodes = filteredValues.openNodes

  allTreeNodes.forEach((node) => {
    if (node.parentId === 0) {
      openNodes.push(node.key.toString())
      filteredCategories.push(node)
    } else {
      const parentKey = node.parentId
      var parentNode = allTreeNodes.find((n) => n.key === parentKey)
      openNodes.push(parentNode.key.toString())
      parentNode.nodes.push(node)
    }
  })
  openNodes = openNodes.length > 0 ? [...new Set(openNodes)] : []
  if (filteredCategories[0] && filteredCategories[0].sortOrder !== null)
    sortCategories(filteredCategories)
  return { filteredCategories, openNodes }
}

const sortCategories = (categories) => {
  categories = categories.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
  categories.forEach((category) => {
    if (category.nodes.length > 0) sortCategories(category.nodes)
  })
}

export const filterCategoriesTreeBySearchTerm = (
  categoryFilter,
  searchKeyword,
  formValues,
  formErrors
) => {
  const categories = formValues.categories || []
  const categoryReplacements = formValues.categoryReplacements || []
  var errorNodes = calculateErrorNodes(formErrors, categories)
  var filteredValues = { filteredCategories: [], openNodes: [] }
  var keywordMatchingCategories = filterCategories(
    searchKeyword,
    categories,
    matchOnCategoryNameOrReplacement(categoryReplacements),
    categoryFilter
  )

  if (keywordMatchingCategories.length > 0) {
    const allTreeNodes = generateAllTreeNodes(
      keywordMatchingCategories,
      errorNodes
    )
    filteredValues = generateCategoryTreeHierarchy(allTreeNodes, filteredValues)
  }

  return filteredValues
}
