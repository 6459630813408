import {
  fetchAccountPerformanceByDay,
  fetchAccountPerformanceByWebsite
} from 'actions/performance/accounts'
import {
  fetchMasterAccountPerformanceByDay,
  fetchMasterAccountPerformanceByWebsite
} from 'actions/performance/master_accounts'
import {
  fetchWebsiteDailyPerformance,
  fetchWebsitePerformanceByBudgetCategories,
  fetchWebsitePerformanceByCampaignType
} from 'actions/performance/websites'
import {
  fetchBudgetCategoryPerformanceByDay,
  fetchBudgetCategoryPerformanceByCampaignType
} from 'actions/google/_performance/budget_categories'

export const FETCH_PERFORMANCE_BY_DAY_REQUEST =
  'FETCH_PERFORMANCE_BY_DAY_REQUEST'
export const FETCH_PERFORMANCE_BY_DAY_SUCCESS =
  'FETCH_PERFORMANCE_BY_DAY_SUCCESS'
export const FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_REQUEST =
  'FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_REQUEST'
export const FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS =
  'FETCH_PERFORMANCE_BY_SUB_ACCOUNT_TYPE_SUCCESS'
export const FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST =
  'FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST'
export const FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS =
  'FETCH_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS'

export const fetchDailyPerformance = (accountType, id, channel, dateRange) => {
  switch (accountType) {
    case 'budget_categories':
      return fetchBudgetCategoryPerformanceByDay(id, dateRange)
    case 'websites':
      return fetchWebsiteDailyPerformance(accountType, id, channel, dateRange)
    case 'accounts':
      return fetchAccountPerformanceByDay(accountType, id, channel, dateRange)
    case 'master_accounts':
      return fetchMasterAccountPerformanceByDay(
        accountType,
        id,
        channel,
        dateRange
      )
    default:
      return () => ({})
  }
}

export const fetchPerformanceSummary = (
  accountType,
  groupType,
  id,
  channel,
  dateRange,
  page,
  perPage,
  force = false
) => {
  switch (accountType) {
    case 'budget_categories':
      return fetchBudgetCategoryPerformanceByCampaignType(id, dateRange)
    case 'websites':
      if (groupType === 'campaign_types') {
        return fetchWebsitePerformanceByCampaignType(
          accountType,
          id,
          channel,
          dateRange
        )
      } else {
        return fetchWebsitePerformanceByBudgetCategories(
          id,
          dateRange,
          page,
          perPage,
          force
        )
      }
    case 'accounts':
      return fetchAccountPerformanceByWebsite(
        accountType,
        id,
        channel,
        dateRange,
        page,
        perPage
      )
    case 'master_accounts':
      return fetchMasterAccountPerformanceByWebsite(
        accountType,
        id,
        channel,
        dateRange,
        page,
        perPage
      )
    default:
      return () => {}
  }
}
