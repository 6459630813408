import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { listMerchantCenterAccounts } from 'actions/google/merchant_center'
import ApiSource from './common/api_source'
import { CHANNEL_GOOGLE } from 'util/supported_channels'

export const GOOGLE_MC_CONTENT_API_IMPORT_SOURCE = 'google_content_api'
const GOOGLE_MC_SCOPES = ['https://www.googleapis.com/auth/content']

export class GoogleContentApiSource extends Component {
  static propTypes = {
    handleApiSourceChange: PropTypes.func.isRequired,
    listMerchantCenterAccounts: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      mcAccounts: [],
      mcAccountId: ''
    }
  }

  async handleOnScopeGranted() {
    const { listMerchantCenterAccounts } = this.props

    try {
      const mcAccounts = await listMerchantCenterAccounts()
      this.setState({ mcAccounts })
    } catch (error) {}
  }

  async handleAccountChange(mcAccount, accessToken, headers, mappings) {
    this.setState({ mcAccountId: mcAccount.value })

    if (headers && headers.length > 0) {
      const { handleApiSourceChange } = this.props

      handleApiSourceChange(
        {
          source: GOOGLE_MC_CONTENT_API_IMPORT_SOURCE,
          accessToken,
          accountId: mcAccount.value
        },
        headers,
        mappings
      )
    }
  }

  render() {
    const { mcAccounts, mcAccountId } = this.state

    return (
      <ApiSource
        channel={CHANNEL_GOOGLE}
        sourceName={GOOGLE_MC_CONTENT_API_IMPORT_SOURCE}
        scopes={GOOGLE_MC_SCOPES}
        asyncAccountName=''
        asyncAccounts={[]}
        asyncAccountId={''}
        accountName='Merchant Center Account'
        accounts={mcAccounts}
        accountId={mcAccountId}
        handleOnScopeGranted={this.handleOnScopeGranted.bind(this)}
        handleAccountChange={this.handleAccountChange.bind(this)}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listMerchantCenterAccounts }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleContentApiSource)
)
