import camelCaseKeys from 'camelcase-keys'

export const FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST =
  'FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST'
export const FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS =
  'FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS'
export const FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST =
  'FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST'
export const FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS =
  'FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS'
export const FETCH_WEBSITE_SAVINGS_VALUES_REQUEST =
  'FETCH_WEBSITE_SAVINGS_VALUES_REQUEST'
export const FETCH_WEBSITE_SAVINGS_VALUES_RESPONSE =
  'FETCH_WEBSITE_SAVINGS_VALUES_RESPONSE'
export const DOWNLOAD_WEBSITE_KEYWORD_REPORT_REQUEST =
  'DOWNLOAD_WEBSITE_KEYWORD_REPORT_REQUEST'
export const DOWNLOAD_WEBSITE_KEYWORD_REPORT_RESPONSE =
  'DOWNLOAD_WEBSITE_KEYWORD_REPORT_RESPONSE'
export const FETCH_WEBSITE_WIDE_QS_SCORES_REQUEST =
  'FETCH_WEBSITE_WIDE_QS_SCORES_REQUEST'
export const FETCH_WEBSITE_WIDE_QS_SCORES_SUCCESS =
  'FETCH_WEBSITE_WIDE_QS_SCORES_SUCCESS'
export const FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_REQUEST =
  'FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_REQUEST'
export const FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_RESPONSE =
  'FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_RESPONSE'

const fetchAverageQualityScoreComponentsRequest = websiteId => ({
  type: FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST,
  websiteId
})

const fetchAverageQualityScoreComponentsSuccess = (
  websiteId,
  averageQualityScoreComponents
) => ({
  type: FETCH_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS,
  websiteId,
  ...averageQualityScoreComponents
})

export const fetchAverageQualityScoreComponents = websiteId => async dispatch => {
  dispatch(fetchAverageQualityScoreComponentsRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/load_average_quality_score_components`,
    dataType: 'json'
  })

  dispatch(
    fetchAverageQualityScoreComponentsSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchWebsiteSavingsValuesRequest = websiteId => ({
  type: FETCH_WEBSITE_SAVINGS_VALUES_REQUEST,
  websiteId
})

const fetchWebsiteSavingsValuesResponse = (websiteId, response) => ({
  type: FETCH_WEBSITE_SAVINGS_VALUES_RESPONSE,
  websiteId,
  websiteSavingsValues: response.websiteSavingsValues
})

export const fetchWebsiteSavingsValues = websiteId => async dispatch => {
  dispatch(fetchWebsiteSavingsValuesRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/load_website_savings_values`,
    dataType: 'json'
  })

  dispatch(
    fetchWebsiteSavingsValuesResponse(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchSystemAverageQualityScoreComponentsRequest = websiteId => ({
  type: FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_REQUEST,
  websiteId
})

const fetchSystemAverageQualityScoreComponentsSuccess = (
  websiteId,
  systemAverageQualityScoreComponents
) => ({
  type: FETCH_SYSTEM_AVERAGE_QUALITY_SCORE_COMPONENTS_SUCCESS,
  websiteId,
  ...systemAverageQualityScoreComponents
})

export const fetchSystemAverageQualityScoreComponents = websiteId => async dispatch => {
  dispatch(fetchSystemAverageQualityScoreComponentsRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/load_system_average_quality_score_components`,
    dataType: 'json'
  })

  dispatch(
    fetchSystemAverageQualityScoreComponentsSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchWebsiteWideQsScoresRequest = websiteId => ({
  type: FETCH_WEBSITE_WIDE_QS_SCORES_REQUEST,
  websiteId
})

const fetchWebsiteWideQsScoresSuccess = (websiteId, websiteWideQsScores) => ({
  type: FETCH_WEBSITE_WIDE_QS_SCORES_SUCCESS,
  websiteId,
  websiteWideQsScores: websiteWideQsScores.websiteWideQsScores
})

export const fetchWebsiteWideQsScores = websiteId => async dispatch => {
  dispatch(fetchWebsiteWideQsScoresRequest(websiteId))
  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/load_website_wide_qs_scores`,
    dataType: 'json'
  })

  dispatch(
    fetchWebsiteWideQsScoresSuccess(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const downloadWebsiteKeywordReportRequest = websiteId => ({
  type: DOWNLOAD_WEBSITE_KEYWORD_REPORT_REQUEST,
  websiteId
})

const downloadWebsiteKeywordReportResponse = (websiteId, response) => ({
  type: DOWNLOAD_WEBSITE_KEYWORD_REPORT_RESPONSE,
  websiteId,
  error: response.error
})

export const downloadWebsiteKeywordReport = (
  websiteId,
  adwordsAccountId
) => async dispatch => {
  dispatch(downloadWebsiteKeywordReportRequest(websiteId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/download_keyword_report_and_populate_database?adwords_id=${adwordsAccountId}`,
    dataType: 'json'
  })

  dispatch(
    downloadWebsiteKeywordReportResponse(
      websiteId,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchSearchDiagnosticsReportUrlRequest = websiteId => ({
  type: FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_REQUEST,
  websiteId
})

const fetchSearchDiagnosticsReportUrlResponse = (websiteId, response) => ({
  type: FETCH_SEARCH_DIAGNOSTICS_REPORT_URL_RESPONSE,
  websiteId,
  ...response
})

export const fetchSearchDiagnosticsReportUrl = (
  websiteId,
  adwordsAccountId
) => async dispatch => {
  dispatch(fetchSearchDiagnosticsReportUrlRequest(websiteId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/websites/${websiteId}/quality_score_components/export_search_diagnostics_report?adwords_id=${adwordsAccountId}`,
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(
    fetchSearchDiagnosticsReportUrlResponse(
      websiteId,
      camelResponse
    )
  )
  return camelResponse
}
