import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import ColumnTooltip from './column_content/column_tooltip'
import ColumnSuggestionPopover from './column_content/column_suggestion_popover'
import ColumnSuggestionContent from './column_content/column_suggestion_content'
import {
  NO_VALUE,
  getAttributeOptimisationSuggestion,
  getAttributeOptimisationSource,
  getDisplayValue
} from '../common/attributes_util'
import { channelSelector } from 'selectors/channel'
import {
  websiteProductReplacementsMetaDataSelector,
  websiteProductOptimisationSuggestionsSelector
} from 'selectors/product_feeds/replacements'
import { websiteFeedDetailSelector } from 'selectors/websites'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'
import {
  PRODUCT_SKU,
  PRODUCT_URL,
  TITLE,
  DESCRIPTION,
  IMAGE_ATTRIBUTES,
  IMAGE_URL
} from 'util/product_attributes'

const isUrlRegEx = /^https?:\/\//

export class ColumnContent extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    attributeName: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    metaData: PropTypes.object.isRequired,
    optimisationSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    feedDetail: PropTypes.object.isRequired
  }

  getAttributeRowDataValues() {
    const { rowData, attributeName } = this.props
    const raw = rowData.raw[attributeName] || ''
    const override = rowData.override[attributeName] || ''
    return { raw, override }
  }

  isTemplatedAttribute() {
    const { attributeName } = this.props
    return attributeName === TITLE || attributeName === DESCRIPTION
  }

  getDisplayValue(value) {
    const { attributeName, metaData, channel } = this.props
    return getDisplayValue(
      {
        attributeName,
        currency: metaData.currency,
        channel
      },
      value
    )
  }

  checkIfAttributeOverriden() {
    const { raw, override } = this.getAttributeRowDataValues()
    var rawDisplayValue = this.getDisplayValue(raw)
    var overrideDisplayValue = this.getDisplayValue(override)
    return rawDisplayValue !== overrideDisplayValue
  }

  renderColumnTooltip(overridden, tooltipId) {
    return (
      overridden && (
        <ColumnTooltip
          tooltipId={tooltipId}
          displayValues={this.getAttributeRowDataWebsiteValue()}
        />
      )
    )
  }

  getAttributeRowDataWebsiteValue() {
    const { raw } = { ...this.getAttributeRowDataValues() }
    return {
      raw: this.getDisplayValue(raw)
    }
  }

  getAttributeDisplayValue() {
    const { override } = this.getAttributeRowDataValues()
    return this.getDisplayValue(override)
  }

  renderSkuWithImageThumbnail(rowData) {
    const urlValue = rowData.override[PRODUCT_URL]
    const imageUrlValue = rowData.override[IMAGE_URL]
    const displayValue = this.getAttributeDisplayValue()

    return (
      <>
        <img className='img-thumbnail' src={imageUrlValue} alt='Thumbnail' />{' '}
        <a href={urlValue} target='_blank'>
          {displayValue}
        </a>
      </>
    )
  }

  renderImageWithLink(rowData, attributeName) {
    const urlValue = rowData.override[attributeName]
    const imageUrlValue = rowData.override[attributeName]
    const displayValue = this.getAttributeDisplayValue()

    return displayValue === NO_VALUE ? (
      <div
        className='formatted-cell-value'
        dangerouslySetInnerHTML={{ __html: displayValue }}
      />
    ) : (
      <a href={urlValue} target='_blank'>
        <img className='img-additional' src={imageUrlValue} alt='Thumbnail' />{' '}
      </a>
    )
  }

  renderCellContent() {
    const { rowData, attributeName } = this.props
    if (attributeName === PRODUCT_SKU) {
      return this.renderSkuWithImageThumbnail(rowData)
    }
    if (attributeName.toLowerCase().includes(IMAGE_ATTRIBUTES)) {
      return this.renderImageWithLink(rowData, attributeName)
    }

    const override = rowData.override[attributeName] || ''
    const displayValue = this.getAttributeDisplayValue()
    return isUrlRegEx.test(override) ? (
      <a href={override} target='_blank'>
        {displayValue}
      </a>
    ) : (
      <div
        className='formatted-cell-value'
        dangerouslySetInnerHTML={{ __html: displayValue }}
      />
    )
  }

  getOptimisationSource() {
    const { optimisationSuggestions, rowData, attributeName } = this.props
    return getAttributeOptimisationSource(
      optimisationSuggestions,
      rowData,
      attributeName
    )
  }

  conditionallyRenderSuggestionPopover() {
    const { optimisationSuggestions, rowData, attributeName, columnName } =
      this.props
    const suggestionValue = getAttributeOptimisationSuggestion(
      optimisationSuggestions,
      rowData,
      attributeName
    )
    if (suggestionValue && suggestionValue.length > 0) {
      return (
        <ColumnSuggestionPopover
          rowData={rowData}
          columnName={columnName}
          originalValue={this.getAttributeDisplayValue()}
          suggestionValue={
            this.isTemplatedAttribute()
              ? suggestionValue
              : this.getDisplayValue(suggestionValue)
          }
          suggestionSource={this.getOptimisationSource()}
        />
      )
    }
    return null
  }

  renderCellAndSuggestionContent() {
    const { rowData, attributeName } = this.props

    return (
      <div className='cell-and-suggestion-container'>
        {this.conditionallyRenderSuggestionPopover()}
        <div>
          {this.renderCellContent()}
          <ColumnSuggestionContent
            rowData={rowData}
            attributeName={attributeName}
          />
        </div>
      </div>
    )
  }

  render() {
    const { rowData, attributeName } = this.props
    const tooltipId = `item-${rowData.itemId}-${attributeName}-original-tooltip`
    const overridden = this.checkIfAttributeOverriden()

    return (
      <div
        id={tooltipId}
        className={`product-level-table-cell${overridden ? ' override' : ''}`}
      >
        {this.renderColumnTooltip(overridden, tooltipId)}
        {this.renderCellAndSuggestionContent()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  channel: channelSelector(state, props),
  metaData: websiteProductReplacementsMetaDataSelector(state, props),
  optimisationSuggestions: websiteProductOptimisationSuggestionsSelector(
    state,
    props
  ),
  feedDetail: websiteFeedDetailSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ColumnContent)
)
