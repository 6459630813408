import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBCardTitle, MDBCardText } from 'mdbreact'
import AccountsSelector from '../../../common/accounts_selector'

export class HeaderSection extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    handleOnAccountIdChanged: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { accountId: props.accountId }
  }

  handleAccountIdChanged(accountId) {
    this.setState({ accountId })
    this.props.handleOnAccountIdChanged(accountId)
  }

  renderAccountSelector() {
    const { accountId } = this.state

    return (
      <AccountsSelector
        forWebsite={false}
        accountId={accountId}
        handleAccountIdChanged={this.handleAccountIdChanged.bind(this)}
      />
    )
  }

  render() {
    return (
      <>
        <MDBCardTitle>Add User</MDBCardTitle>
        <MDBCardText>
          To add a new user, enter their email address below. We will send them
          an email inviting them into your account.
        </MDBCardText>
        {this.renderAccountSelector()}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderSection)
)
