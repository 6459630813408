import camelCaseKeys from 'camelcase-keys'

export const FETCH_FEEDOPS_FEATURES_REQUEST = 'FETCH_FEEDOPS_FEATURES_REQUEST'
export const FETCH_FEEDOPS_FEATURES_RESPONSE = 'FETCH_FEEDOPS_FEATURES_RESPONSE'

const fetchFeedopsFeaturesRequest = () => ({
  type: FETCH_FEEDOPS_FEATURES_REQUEST
})

const fetchFeedopsFeaturesResponse = (response) => ({
  type: FETCH_FEEDOPS_FEATURES_RESPONSE,
  ...response
})

export const fetchFeedopsFeatures = () => async (dispatch) => {
  dispatch(fetchFeedopsFeaturesRequest())

  var response = await $.ajax({
    method: 'GET',
    url: `/feedops_features`,
    dataType: 'json'
  })

  var camelResponse = camelCaseKeys(response, { deep: true })
  dispatch(fetchFeedopsFeaturesResponse(camelResponse))

  return camelResponse.feedopsFeatures
}
