import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput
} from 'mdbreact'
import { fetchFullWebsiteList } from 'actions/websites'
import { isEmbeddedAppSelector } from 'selectors/app'
import {
  accountTypeSelector,
  accountTypeLabelSelector,
  accountTypeIdSelector,
  superUserSelector
} from 'selectors/user'
import {
  constructedWebsiteListSelector,
  accountsWithContactDetailsListSelector
} from 'selectors/websites'
import { abbreviateUrls, scrubDuplicate } from 'util/format_string'
import { isMobileOS } from 'util/operating_systems'

export class WebsitesMenu extends Component {
  static propTypes = {
    isEmbeddedApp: PropTypes.bool.isRequired,
    accountsWithContactDetails: PropTypes.bool,
    constructedList: PropTypes.array.isRequired,
    accountType: PropTypes.string.isRequired,
    accountTypeId: PropTypes.number.isRequired,
    accountLabel: PropTypes.string.isRequired,
    fetchFullWebsiteList: PropTypes.func.isRequired,
    showGenericToggleName: PropTypes.bool.isRequired,
    canSelectMasterAccount: PropTypes.bool.isRequired,
    canSelectAccount: PropTypes.bool.isRequired,
    canSelectWebsite: PropTypes.bool.isRequired,
    canSelectParentWithSingleChild: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    superUser: PropTypes.bool.isRequired,
    isMobileOS: PropTypes.bool.isRequired
  }

  static defaultProps = {
    showGenericToggleName: true,
    canSelectMasterAccount: true,
    canSelectAccount: true,
    canSelectWebsite: true,
    canSelectParentWithSingleChild: false,
    navigateToSubPath: false,
    isMobileOS
  }

  state = {
    searchKeyword: ''
  }

  async componentDidMount() {
    const { fetchFullWebsiteList } = this.props
    await fetchFullWebsiteList()
  }

  handleClick(id, type, label) {
    const { onClick } = this.props
    onClick(id, type, label)
    this.setState({ searchKeyword: '' })
  }

  mapConstructedListToDropdownItem() {
    const {
      accountType,
      accountTypeId,
      canSelectMasterAccount,
      canSelectAccount,
      canSelectWebsite,
      canSelectParentWithSingleChild,
      superUser
    } = this.props

    const filteredList = this.filterData()
    let displayList = []
    filteredList.forEach((m) => {
      if (m) {
        if (m.accounts) {
          this.addMasterAccountsToDisplayList(
            canSelectMasterAccount,
            canSelectAccount,
            canSelectWebsite,
            canSelectParentWithSingleChild,
            m,
            displayList,
            accountType,
            accountTypeId,
            superUser
          )
        }

        if (m.websites) {
          m.websites.forEach((w) => {
            this.addWebsitesToDisplayList(
              displayList,
              accountType,
              accountTypeId,
              w,
              canSelectWebsite
            )
          })
        }

        displayList.push(
          <div key={`div-ma${m.id}`} className='dropdown-divider' />
        )
      }
    })
    return displayList
  }

  addMasterAccountsToDisplayList(
    canSelectMasterAccount,
    canSelectAccount,
    canSelectWebsite,
    canSelectParentWithSingleChild,
    m,
    displayList,
    accountType,
    accountTypeId,
    superUser
  ) {
    var disabled =
      !canSelectMasterAccount ||
      (!canSelectParentWithSingleChild && m.accounts.length < 2) ||
      m.disabled
    displayList.push(
      <MDBDropdownItem
        className={`master-account ${disabled ? 'disabled' : ''}`}
        active={accountType === 'master_accounts' && m.id === accountTypeId}
        key={`ma${m.id}`}
        onClick={() =>
          !disabled && this.handleClick(m.id, 'master_accounts', m.label)
        }
      >
        {abbreviateUrls(m.label)}
      </MDBDropdownItem>
    )

    m.accounts.forEach((a) => {
      this.addAccountsToDisplayList(
        canSelectAccount,
        canSelectWebsite,
        canSelectParentWithSingleChild,
        a,
        displayList,
        accountType,
        accountTypeId,
        superUser
      )
    })
  }

  addAccountsToDisplayList(
    canSelectAccount,
    canSelectWebsite,
    canSelectParentWithSingleChild,
    a,
    displayList,
    accountType,
    accountTypeId,
    superUser
  ) {
    var disabled =
      !canSelectAccount ||
      (!canSelectParentWithSingleChild && a.websites.length < 2) ||
      a.disabled
    displayList.push(
      <MDBDropdownItem
        className={`account ${disabled ? 'disabled' : ''}`}
        active={accountType === 'accounts' && a.id === accountTypeId}
        key={`a${a.id}`}
        onClick={() => !disabled && this.handleClick(a.id, 'accounts', a.label)}
      >
        {superUser
          ? abbreviateUrls(a.label)
          : abbreviateUrls(scrubDuplicate(a.label))}
      </MDBDropdownItem>
    )

    a.websites.forEach((w) => {
      this.addWebsitesToDisplayList(
        displayList,
        accountType,
        accountTypeId,
        w,
        canSelectWebsite
      )
    })
  }

  addWebsitesToDisplayList(
    displayList,
    accountType,
    accountTypeId,
    w,
    canSelectWebsite
  ) {
    displayList.push(
      <MDBDropdownItem
        className={`website ${!canSelectWebsite ? 'disabled' : ''}`}
        active={accountType === 'websites' && w.id === accountTypeId}
        key={`w${w.id}`}
        onClick={() =>
          canSelectWebsite && this.handleClick(w.id, 'websites', w.url)
        }
      >
        {abbreviateUrls(w.url)}
      </MDBDropdownItem>
    )
  }

  handleSearch(event) {
    this.setState({ searchKeyword: event.target.value })
  }

  filterData() {
    const { searchKeyword } = this.state
    const { constructedList } = this.props

    return constructedList
      .map((m) => {
        if (m.label.toLowerCase().includes(searchKeyword.toLowerCase())) {
          return m
        }

        if (m.accounts) {
          var selectedAccounts = m.accounts
            .map((a) => {
              if (a.label.toLowerCase().includes(searchKeyword.toLowerCase())) {
                return a
              }

              var selectedWebsites = a.websites
                .map((w) => {
                  return w.url &&
                    w.url.toLowerCase().includes(searchKeyword.toLowerCase())
                    ? w
                    : null
                })
                .filter((w) => {
                  return w !== null
                })

              return selectedWebsites.length > 0
                ? { ...a, websites: selectedWebsites }
                : null
            })
            .filter((a) => {
              return a !== null
            })

          return selectedAccounts.length > 0
            ? { ...m, accounts: selectedAccounts }
            : null
        }

        if (m.websites) {
          var selectedWebsites = m.websites
            .map((w) => {
              return w.url &&
                w.url.toLowerCase().includes(searchKeyword.toLowerCase())
                ? w
                : null
            })
            .filter((w) => {
              return w !== null
            })

          return selectedWebsites.length > 0
            ? { ...m, websites: selectedWebsites }
            : null
        }
      })
      .filter((m) => {
        return m !== null
      })
  }

  handleOnclick() {
    this.setState({ searchKeyword: '' })
  }

  conditionallyRenderToggle() {
    const {
      constructedList,
      isEmbeddedApp,
      showGenericToggleName,
      accountLabel
    } = this.props
    return constructedList.length > 0 ? (
      <MDBDropdownToggle
        nav={true}
        caret={!isEmbeddedApp}
        disabled={isEmbeddedApp}
        onClick={this.handleOnclick.bind(this)}
      >
        <span className='website-url'>
          {showGenericToggleName
            ? abbreviateUrls(accountLabel)
            : 'Select a website'}
        </span>
      </MDBDropdownToggle>
    ) : null
  }

  render() {
    const { accountsWithContactDetails, isMobileOS } = this.props
    const displayType = isMobileOS ? 'mobile' : 'desktop'
    return (
      <MDBDropdown className={`websites-menu ${displayType}`}>
        {this.conditionallyRenderToggle()}
        <MDBDropdownMenu right={accountsWithContactDetails}>
          <MDBInput
            onChange={this.handleSearch.bind(this)}
            hint='Search'
            type='text'
            containerClass='mt-0'
            vaue={this.state.searchKeyword}
          />
          {this.mapConstructedListToDropdownItem()}
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isEmbeddedApp: isEmbeddedAppSelector(state, props),
    accountType: props.accountType || accountTypeSelector(state, props),
    accountTypeId: props.accountTypeId || accountTypeIdSelector(state, props),
    constructedList: props.accountsWithContactDetails
      ? accountsWithContactDetailsListSelector(state, props)
      : constructedWebsiteListSelector(state, props),
    accountLabel:
      props.accountLabel !== undefined
        ? props.accountLabel
        : accountTypeLabelSelector(state, props),
    superUser: superUserSelector(state)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFullWebsiteList, isMobileOS }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebsitesMenu)
)
