import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, Form, registerField } from 'redux-form'
import { MDBInput, MDBSelect, MDBBtn } from 'mdbreact'
import {
  listAnalyticsAccounts,
  createAnalyticsAccount
} from 'actions/google/analytics'
import {
  createAnalyticsProperty,
  selectAnalyticsProperty
} from 'actions/google/analytics_property'
import { fetchWebsiteDetail } from 'actions/websites'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { createValidator } from 'components/util/validation'
import GenericContent from 'components/websites/workflow/generic_content'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import GoogleUserContext from 'components/google/google_user_context'
import Switch from 'components/ad_champion/common/switch'
import {
  analyticsAccountIdSelector,
  analyticsAccountNameSelector,
  analyticsWebPropertyIdSelector,
  analyticsWebPropertyNameSelector,
  createAnalyticsAccountSelector
} from 'selectors/google/accounts'
import { websiteIdSelector } from 'selectors/websites'

export class AnalyticsForm extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    listAnalyticsAccounts: PropTypes.func.isRequired,
    createAnalyticsProperty: PropTypes.func.isRequired,
    selectAnalyticsProperty: PropTypes.func.isRequired,
    createAnalyticsAccount: PropTypes.func.isRequired,
    analyticsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired,
    initialize: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    analyticsAccountId: PropTypes.string.isRequired,
    analyticsAccountName: PropTypes.string.isRequired,
    analyticsWebPropertyId: PropTypes.string.isRequired,
    analyticsWebPropertyName: PropTypes.string.isRequired,
    useExistingAccount: PropTypes.bool.isRequired,
    showSaveButton: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired
  }

  static defaultProps = {
    hasReadOnlyMode: true,
    showSaveButton: false
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      newWebPropertyName: '',
      busy: false
    }
  }

  componentDidMount() {
    const { websiteId, registerField } = this.props
    registerField(REDUX_FORM_NAME, 'accountId', 'Field')
    registerField(REDUX_FORM_NAME, 'webPropertyId', 'Field')
    if (websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
    this.fetchAnalyticsAccounts()
  }

  componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (websiteId > 0 && websiteId !== prevProps.websiteId) {
      this.fetchWebsiteDetail(websiteId)
    }
  }

  async fetchWebsiteDetail(websiteId) {
    const { fetchWebsiteDetail } = this.props
    await fetchWebsiteDetail(websiteId)

    const {
      initialize,
      analyticsAccountId,
      analyticsAccountName,
      analyticsWebPropertyId,
      analyticsWebPropertyName,
      useExistingAccount
    } = this.props
    initialize({
      accountId: analyticsAccountId,
      accountName: analyticsAccountName,
      webPropertyId: analyticsWebPropertyId,
      webPropertyName: analyticsWebPropertyName,
      useExistingAccount
    })
  }

  async dirtySubmit(values) {
    const {
      websiteId,
      preventNewAccountCreation,
      createAnalyticsAccount,
      selectAnalyticsProperty
    } = this.props
    if (!preventNewAccountCreation && !values.useExistingAccount) {
      await createAnalyticsAccount(websiteId)
    } else {
      await selectAnalyticsProperty(websiteId, values)
    }
    await this.fetchWebsiteDetail(websiteId)
  }

  async handleSubmit(values, _dispatch, _props) {
    const { dirty } = this.props

    try {
      if (dirty) {
        this.dirtySubmit(values)
      }
      this.setState({ editing: false })
    } catch (error) {
      toastr.error(error.responseJSON.error, 'ERROR')
      throw error
    }
  }

  handleAccountChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      const { analyticsAccounts } = this.props
      var account = analyticsAccounts.find((a) => a.id === e[0])
      change('accountId', account.id)
      change('accountName', account.name)
    }
  }

  renderSelectLabel(account) {
    return `${account.name} (${account.id})`
  }

  renderAccountSelect() {
    const { analyticsAccounts, formValues } = this.props
    var options = analyticsAccounts.map((a) => ({
      value: a.id,
      text: this.renderSelectLabel(a)
    }))
    options = options.reduce((acc, val) => acc.concat(val), [])
    var selectedOption =
      options.find((o) => o.value === formValues.accountId) || {}
    return (
      <MDBSelect
        search
        className={options.length === 0 ? 'disabled' : null}
        options={options}
        selected={selectedOption.text}
        labelClass='analyticsSelectField'
        getValue={this.handleAccountChange.bind(this)}
      />
    )
  }

  handlePropertyChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      change('webPropertyId', e[0].webPropertyId)
      change('webPropertyName', e[0].webPropertyName)
    }
  }

  renderPropertySelect() {
    const { analyticsAccounts, formValues } = this.props
    var analyticsAccount = analyticsAccounts.filter(
      (acc) => acc.id === formValues.accountId
    )
    var options = analyticsAccount.map((a) =>
      a.webProperties.map((wp) => ({
        value: {
          webPropertyId: wp.id,
          webPropertyName: wp.name
        },
        text: this.renderSelectLabel(wp)
      }))
    )
    options = options.reduce((acc, val) => acc.concat(val), [])
    var selectedOption =
      options.find((o) => o.value.webPropertyId === formValues.webPropertyId) ||
      {}
    return (
      <MDBSelect
        search
        className={options.length === 0 ? 'disabled' : null}
        options={options}
        labelClass='analyticsSelectField'
        selected={selectedOption.text}
        getValue={this.handlePropertyChange.bind(this)}
      />
    )
  }

  renderPropertyForm() {
    if (this.props.analyticsAccounts.length !== 0) {
      return <div>{this.renderPropertySelect()}</div>
    }
  }

  renderAccountForm() {
    if (this.props.analyticsAccounts.length === 0) {
      return <IndeterminateProgressIndicator />
    }
    return this.renderAccountSelect()
  }

  async fetchAnalyticsAccounts() {
    this.setState({ busy: true })
    try {
      await this.props.listAnalyticsAccounts()
    } catch (error) {
      toastr.error(error.responseJSON.error)
    }
    this.setState({ busy: false })
  }

  renderEdit() {
    const { formValues } = this.props
    return (
      <GoogleUserContext
        scope='https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.readonly'
        help={
          <div>
            Sign in with Google to select your existing Google Analytics account
            and property.
          </div>
        }
        signInButtonText='Authorize Google Analytics access'
        onSuccess={this.fetchAnalyticsAccounts.bind(this)}
      >
        <GenericContent
          component={this.renderAccountForm()}
          help='Select your account'
        />
        {formValues.accountId && formValues.accountId.length > 0 && (
          <GenericContent
            component={this.renderPropertyForm()}
            help='Select your property'
          />
        )}
        {this.renderSaveButton()}
      </GoogleUserContext>
    )
  }

  renderReadOnlyAccountName() {
    const {
      analyticsAccountName,
      analyticsAccountId,
      useExistingAccount
    } = this.props
    return (
      <MDBInput
        label='Account name'
        value={
          useExistingAccount
            ? `${analyticsAccountName} (${analyticsAccountId})`
            : 'A new account will be created'
        }
        name='accountName'
        readOnly
      />
    )
  }

  renderReadOnlyWebProperty() {
    const {
      analyticsWebPropertyName,
      analyticsWebPropertyId,
      useExistingAccount
    } = this.props
    return (
      <MDBInput
        label='Web property'
        name='webPropertyName'
        value={
          useExistingAccount
            ? `${analyticsWebPropertyName} (${analyticsWebPropertyId})`
            : 'A new property will be created'
        }
        readOnly
      />
    )
  }

  renderReadOnlyInput() {
    return (
      <div>
        {this.renderReadOnlyAccountName()}
        {this.renderReadOnlyWebProperty()}
      </div>
    )
  }

  renderEditButton() {
    return (
      <MDBBtn
        outline
        color='primary'
        onClick={() => this.setState({ editing: true })}
      >
        Edit
      </MDBBtn>
    )
  }

  renderReadOnly() {
    const { appId } = this.props
    return (
      <React.Fragment>
        <GenericContent component={this.renderReadOnlyInput()} />
        {!(appId === 'platform') && this.renderEditButton()}
      </React.Fragment>
    )
  }

  renderUseExistingAccountCheckbox() {
    return (
      <GenericContent
        component={
          <Switch
            name='useExistingAccount'
            labelLeft={null}
            labelRight='Use an existing account'
          />
        }
        help={
          'Unless you wish to use an existing account, a new account will be created for you.'
        }
      />
    )
  }

  renderSaveButton() {
    const { showSaveButton, submit, valid } = this.props
    return (
      showSaveButton && (
        <MDBBtn
          onClick={submit}
          disabled={!valid}
          color={'primary'}
          className={'next-action'}
        >
          Connect Analytics
        </MDBBtn>
      )
    )
  }

  renderEditable() {
    const { preventNewAccountCreation } = this.props
    const { useExistingAccount } = this.props.formValues
    return (
      <div>
        {!preventNewAccountCreation && this.renderUseExistingAccountCheckbox()}
        {(preventNewAccountCreation || useExistingAccount) && this.renderEdit()}
      </div>
    )
  }

  renderForm() {
    const { hasReadOnlyMode, handleSubmit } = this.props
    return (
      <Form
        className='analytics-form'
        onSubmit={handleSubmit(this.handleSubmit.bind(this))}
      >
        {hasReadOnlyMode && !this.state.editing
          ? this.renderReadOnly()
          : this.renderEditable()}
      </Form>
    )
  }

  render() {
    const { initialized } = this.props

    if (!initialized) {
      return <IndeterminateProgressIndicator />
    }

    return this.renderForm()
  }
}

const mapStateToProps = (state, props) => {
  return {
    appId: props.match.params.appId,
    analyticsAccountId: analyticsAccountIdSelector(state, props),
    analyticsAccountName: analyticsAccountNameSelector(state, props),
    analyticsWebPropertyId: analyticsWebPropertyIdSelector(state, props),
    analyticsWebPropertyName: analyticsWebPropertyNameSelector(state, props),
    useExistingAccount: !createAnalyticsAccountSelector(state, props),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    websiteId: websiteIdSelector(state, props),
    analyticsAccounts: state.google.analytics.accounts || []
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAnalyticsAccount,
      listAnalyticsAccounts,
      selectAnalyticsProperty,
      createAnalyticsProperty,
      fetchWebsiteDetail,
      registerField
    },
    dispatch
  )

export const AnalyticsFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsForm)
export const REDUX_FORM_NAME = 'AnalyticsForm'
export default withRouter(
  reduxForm({
    form: REDUX_FORM_NAME,
    validate: (values, props) => {
      if (props.preventNewAccountCreation || values.useExistingAccount) {
        return createValidator(['accountId', 'webPropertyId'])(values)
      }
      return true
    },
    ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
  })(AnalyticsFormWithRedux)
)
