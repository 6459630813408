import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import sleep from 'util/sleep'

export const FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_REQUEST =
  'FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_REQUEST'
export const FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_SUCCESS =
  'FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_SUCCESS'
export const FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST =
  'FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST'
export const FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS =
  'FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS'

const fetchBudgetCategoryPerformanceByDayRequest = (
  budgetCategoryId,
  dateRange
) => ({
  type: FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_REQUEST,
  budgetCategoryId,
  dateRange
})

const fetchBudgetCategoryPerformanceByDaySuccess = (
  budgetCategoryId,
  dateRange,
  dailyPerformance
) => ({
  type: FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_DAY_SUCCESS,
  budgetCategoryId,
  dateRange,
  ...dailyPerformance
})

export const fetchBudgetCategoryPerformanceByDay = (
  budgetCategoryId,
  dateRange
) => async (dispatch) => {
  dispatch(
    fetchBudgetCategoryPerformanceByDayRequest(budgetCategoryId, dateRange)
  )

  var status = 202
  var response
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  while (status === 202) {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/budget_categories/${budgetCategoryId}/performance`,
      data: snakeCaseKeys(dateRange, { deep: true }),
      dataType: 'json',
      statusCode
    })
    if (status === 202) {
      await sleep(1000)
    }
  }

  dispatch(
    fetchBudgetCategoryPerformanceByDaySuccess(
      budgetCategoryId,
      dateRange,
      camelCaseKeys(response, { deep: true })
    )
  )
}

const fetchBudgetCategoryPerformanceByCampaignTypeRequest = (
  budgetCategoryId,
  dateRange
) => ({
  type: FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_REQUEST,
  budgetCategoryId,
  dateRange
})

export const fetchBudgetCategoryPerformanceByCampaignTypeSuccess = (
  budgetCategoryId,
  dateRange,
  performanceReport
) => ({
  type: FETCH_BUDGET_CATEGORY_PERFORMANCE_BY_CAMPAIGN_TYPE_SUCCESS,
  budgetCategoryId,
  dateRange,
  ...performanceReport
})

export const fetchBudgetCategoryPerformanceByCampaignType = (
  budgetCategoryId,
  dateRange
) => async (dispatch) => {
  dispatch(
    fetchBudgetCategoryPerformanceByCampaignTypeRequest(
      budgetCategoryId,
      dateRange
    )
  )

  var status = 202
  var response
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  while (status === 202) {
    response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/budget_categories/${budgetCategoryId}/performance/show_budget_category_performance_by_campaign_type`,
      data: snakeCaseKeys(dateRange, { deep: true }),
      dataType: 'json',
      statusCode
    })
    if (status === 202) {
      await sleep(1000)
    }
  }

  dispatch(
    fetchBudgetCategoryPerformanceByCampaignTypeSuccess(
      budgetCategoryId,
      dateRange,
      camelCaseKeys(response, { deep: true })
    )
  )
}
