import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
export const LOAD_STRUCTURED_SNIPPETS  = 'LOAD_STRUCTURED_SNIPPETS';
export const SAVE_STRUCTURED_SNIPPETS = 'SAVE_STRUCTURED_SNIPPETS';

const loadAction = (snippets) => ({
  type: LOAD_STRUCTURED_SNIPPETS,
  snippets
});

const saveAction = (snippets) => ({
  type: SAVE_STRUCTURED_SNIPPETS,
  snippets
})

export const loadStructuredSnippets = (scenarioId) => dispatch => {
  return $.ajax({
    method: 'GET',
    url:  `/ad_champion/scenarios/${scenarioId}/structured_snippet/edit`,
    dataType: 'json',
    success: (response) => {
      return dispatch(loadAction(camelCaseKeys(response.snippets)));
    }
  });
};

export const saveStructuredSnippets = (scenarioId, data) => dispatch => {
  var payload = {snippets: data} 
  return $.ajax({
    method: 'PUT',
    url:  `/ad_champion/scenarios/${scenarioId}/structured_snippet`,
    dataType: 'json',
    data: snakeCaseKeys(payload),
    success: (response) => {
      return dispatch(saveAction(camelCaseKeys(response.snippets)));
    }
  });
};
