import { createSelector } from 'reselect'

const rootSelector = (state) =>
  (state.google || {}).merchantCenterProductErrors || {}

export const websiteMerchantCenterProductErrorsSelector = (websiteId) =>
  createSelector(
    [rootSelector],
    (root) => root[websiteId] || { itemCount: 0, errors: [] }
  )

const websiteDetailSelector = (websiteId) =>
  createSelector([rootSelector], (root) => root.websites[websiteId] || {})

export const websiteErrorCategoryIdsSelector = (websiteId, scope) =>
  createSelector(
    [websiteDetailSelector(websiteId)],
    (detail) => (detail.categoryIds || {})[scope] || []
  )

export const categoriesSelector = () =>
  createSelector([rootSelector], (root) => root.categories || {})

export const websiteErrorCategoryDetailsSelector = (websiteId, scope) =>
  createSelector(
    [categoriesSelector(), websiteErrorCategoryIdsSelector(websiteId, scope)],
    (categories, ids) => {
      return ids.map((id) => categories[id] || {})
    }
  )

const errorsSelector = () =>
  createSelector([rootSelector], (root) => root.errors || {})

const errorsListSelector = () =>
  createSelector([errorsSelector()], (errors) => errors.errors || [])

export const errorTypeIdsSelector = () =>
  createSelector([errorsListSelector()], (errorsList) =>
    errorsList.map((error) => error.errorTypeId)
  )

const errorTypesSelector = () =>
  createSelector([rootSelector], (root) => root.errorTypes || {})

export const errorTypeCategoryIdsSelector = () =>
  createSelector([errorTypesSelector()], (errorTypes) => {
    var categoryIds = []
    for (var id in errorTypes) {
      const errorType = errorTypes[id]
      categoryIds.push(errorType.categoryId)
    }

    return categoryIds
  })

export const errorCountSelector = () =>
  createSelector(
    [categoriesSelector(), errorTypeCategoryIdsSelector()],
    (categories, categoryIds) => {
      var errorCount = 0
      for (var categoryId of categoryIds) {
        categories[categoryId].level === 'error' && errorCount++
      }

      return errorCount
    }
  )

export const warningCountSelector = () =>
  createSelector(
    [categoriesSelector(), errorTypeCategoryIdsSelector()],
    (categories, categoryIds) => {
      var warningCount = 0
      for (var categoryId of categoryIds) {
        categories[categoryId].level === 'warning' && warningCount++
      }

      return warningCount
    }
  )
