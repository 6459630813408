import {
  INVITATION_ERROR,
  USER_MASTER_ACCOUNT_LIST_RESPONSE,
  SELECT_MASTER_ACCOUNT,
  UPDATE_USER_RESPONSE,
  LOGIN_USER_RESPONSE,
  LOGOUT_USER_RESPONSE
} from 'actions/users'
import {
  FETCH_USER_ACCOUNTS_RESPONSE,
  UPDATE_ACCOUNT_OVERVIEW_FILTERS
} from 'actions/users/accounts'

const initialState = {
  error: null,
  currentUser: {
    email: null,
    masterAccountList: [],
    accountList: [],
    newUser: true,
    newUserFirstTimeSignIn: null,
    accountsOverview: [],
    accountsOverviewFilters: []
  }
}

const userAccountsOverviewReducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_ACCOUNTS_RESPONSE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accountsOverview: action.userAccounts
        }
      }
    case UPDATE_ACCOUNT_OVERVIEW_FILTERS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accountsOverviewFilters: action.filters
        }
      }
    default:
      return state
  }
}

const userMasterAccountListReducer = (state, action) => {
  switch (action.type) {
    case USER_MASTER_ACCOUNT_LIST_RESPONSE:
      var currentMasterAccountId = state.currentMasterAccountId
      if (
        currentMasterAccountId === 0 &&
        action.currentUser.masterAccountList.length === 1
      ) {
        currentMasterAccountId = action.currentUser.masterAccountList[0].id
      }

      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.currentUser },
        currentMasterAccountId
      }
    case SELECT_MASTER_ACCOUNT:
      return {
        ...state,
        currentMasterAccountId: action.masterAccountId
      }
    default:
      return state
  }
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITATION_ERROR:
      return {
        ...state,
        error: action.error
      }
    case USER_MASTER_ACCOUNT_LIST_RESPONSE:
    case SELECT_MASTER_ACCOUNT:
      return userMasterAccountListReducer(state, action)
    case UPDATE_USER_RESPONSE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.user,
          newUserFirstTimeSignIn: action.newUserFirstTimeSignIn
        }
      }
    case LOGOUT_USER_RESPONSE:
      return {
        ...state,
        currentUser: initialState.currentUser,
        currentMasterAccountId: 0
      }
    case LOGIN_USER_RESPONSE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          newUserFirstTimeSignIn: action.newUserFirstTimeSignIn
        }
      }
    case FETCH_USER_ACCOUNTS_RESPONSE:
    case UPDATE_ACCOUNT_OVERVIEW_FILTERS:
      return userAccountsOverviewReducer(state, action)
    default:
      return state
  }
}

export default usersReducer
