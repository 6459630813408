import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, Switch, Route, withRouter } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import { MDBContainer } from 'mdbreact'
import qs from 'qs'
import { isMobileOS } from 'util/operating_systems'
import PlatformSignInMobile from 'components/platform/sign_in/mobile'
import PlatformSignInDesktop from 'components/platform/sign_in/desktop'
import PlatformSignUpMobile from 'components/platform/sign_up/mobile'
import PlatformSignUpDesktop from 'components/platform/sign_up/desktop'
import FeedOpsWebsiteServiceOptionsMobile from './website/service_options/mobile'
import FeedOpsWebsiteServiceOptionsDesktop from './website/service_options/desktop'
import AccountsOverview from './accounts/overview'
import AccountDetails from './account/details'
import FeedOpsAccount from './account'
import FeedOpsWebsite from 'components/feed_ops/website'
import { appIdSelector, isEmbeddedAppSelector } from 'selectors/app'
import { noDefaultChannelSelector } from 'selectors/channel'
import {
  accountListSelector,
  loggedInSelector,
  profileCompleteSelector
} from 'selectors/user'
import { websiteListSelector } from 'selectors/websites'

import './content.scss'

export class FeedOpsContent extends Component {
  static propTypes = {
    isMobileOS: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired,
    channel: PropTypes.string,
    accountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    websiteList: PropTypes.arrayOf(PropTypes.object).isRequired,
    profileComplete: PropTypes.bool.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  redirectToSignUp() {
    const { isEmbeddedApp, appId } = this.props
    if (isEmbeddedApp) {
      const { history, match, channel } = this.props
      return isMobileOS ? (
        <PlatformSignUpMobile />
      ) : (
        <PlatformSignUpDesktop
          onComplete={() =>
            history.push(`${match.url}?${qs.stringify({ channel })}`)
          }
        />
      )
    }

    return window.open(`/sign_up?app_id=${appId}`, '_self')
  }

  renderSignUpRoute(match) {
    return (
      <Route
        path={`${match.path}/sign_up`}
        render={() => this.redirectToSignUp()}
      />
    )
  }

  renderWebsiteRoute() {
    const { match } = this.props
    return (
      <Route path={`${match.path}/:websiteId`} component={FeedOpsWebsite} />
    )
  }

  renderMobileRoutes() {
    const { match, loggedIn, profileComplete, isEmbeddedApp } = this.props

    return (
      <Switch>
        {!isEmbeddedApp && this.renderSignUpRoute(match)}
        {!loggedIn && (
          <Route path={match.path} component={PlatformSignInMobile} />
        )}
        {isEmbeddedApp && !profileComplete && (
          <Route
            path={`${match.path}/:websiteId?`}
            render={() => this.redirectToSignUp()}
          />
        )}
        {isEmbeddedApp && this.renderWebsiteRoute()}
        <Route path={`*`} component={FeedOpsWebsiteServiceOptionsMobile} />
      </Switch>
    )
  }

  renderOptionsRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/options`}
        component={FeedOpsWebsiteServiceOptionsDesktop}
      />
    )
  }

  renderAccountsOverviewRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/:accountType(accounts)/:accountId/accounts_overview`}
        component={AccountsOverview}
      />
    )
  }

  renderAccountDetailsRoute(match) {
    return (
      <GuardedRoute
        path={`${match.path}/:accountType(accounts)/:accountId/account_details`}
        component={AccountDetails}
      />
    )
  }

  renderAccountRoute() {
    const { match } = this.props
    return (
      <Route
        path={`${match.path}/:accountType(accounts)`}
        component={FeedOpsAccount}
      />
    )
  }

  newWebsitePath() {
    const { match, accountList } = this.props
    var path = match.url.replace(/\/$/, '')
    if (accountList.length > 0) {
      path = `${path}/options`
    }
    return path
  }

  renderDefaultRedirectRoute() {
    const { match, isEmbeddedApp } = this.props
    const { websiteList, accountList, location } = this.props
    var path = match.url.replace(/\/$/, '')
    if (websiteList.length === 0) {
      path = this.newWebsitePath()
    } else if (websiteList.length === 1 || isEmbeddedApp) {
      path = `${path}/${websiteList[0].id}`
    } else if (websiteList.length > 1 && accountList.length >= 1) {
      path = `${path}/accounts/${accountList[0].id}/accounts_overview`
    }

    path = `${path}${location.search}`

    return (
      <Route
        path={match.path}
        exact
        render={() => path && <Redirect to={path} />}
      />
    )
  }

  renderDesktopRoutes() {
    const { loggedIn, match, profileComplete } = this.props

    return (
      <Switch>
        {this.renderSignUpRoute(match)}
        {!loggedIn && (
          <Route path={match.path} component={PlatformSignInDesktop} />
        )}
        {!profileComplete && (
          <Route
            path={`${match.path}/:websiteId?`}
            render={() => this.redirectToSignUp()}
          />
        )}
        {this.renderOptionsRoute(match)}
        {this.renderAccountsOverviewRoute(match)}
        {this.renderAccountDetailsRoute(match)}
        {this.renderAccountRoute()}
        {this.renderWebsiteRoute()}
        {this.renderDefaultRedirectRoute()}
      </Switch>
    )
  }

  render() {
    const { location, appId, profileComplete } = this.props
    const mobileContentClass = isMobileOS ? 'mobile-content' : ''

    const contentClass =
      location.pathname.includes('sign_up') ||
      location.pathname.includes('sign_in') ||
      location.pathname === `/${appId}` ||
      !profileComplete
        ? 'feed-ops-content'
        : ''

    const fluid =
      location.pathname.includes('product_level_optimizations') ||
      location.pathname.includes('accounts_overview') ||
      location.pathname.includes('data_rules')

    return (
      <MDBContainer
        className={`content ${contentClass} ${mobileContentClass}`}
        fluid={fluid}
      >
        {isMobileOS ? this.renderMobileRoutes() : this.renderDesktopRoutes()}
      </MDBContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  loggedIn: loggedInSelector(state),
  accountList: accountListSelector(state),
  websiteList: websiteListSelector(state),
  profileComplete: profileCompleteSelector(state),
  channel: noDefaultChannelSelector(state, props),
  appId: appIdSelector(state, props),
  isEmbeddedApp: isEmbeddedAppSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsContent)
)
