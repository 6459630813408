import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isDirty, getFormSyncErrors } from 'redux-form'
import _ from 'lodash'
import { remoteSubmitAndWait } from 'actions/forms'
import { saveFeedSettings } from 'actions/product_feeds/replacements/products'
import { fetchWebsiteDetail } from 'actions/websites'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardFooter,
  MDBBtn
} from 'mdbreact'
import BackButton from '../common/back_button'
import DescriptionSetting from './container/description_setting'
import FeedSyncSetting from './container/feed_sync_setting'
import LinkedAccountsSetting from './container/linked_accounts_setting'
import UrlTaggingSetting from './container/url_tagging_setting'
import { REDUX_FORM_NAME as URL_TAGGING_REDUX_FORM_NAME } from 'components/websites/merchant_center_diagnostics/overview/analytics_tagging_card'
import {
  websiteIdSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'

import './container.scss'
export const OPTION_INDEPENDENT = 'independent'
export const OPTION_SYNC = 'sync'

export class FeedSettingsContainer extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedDetail: PropTypes.object.isRequired,
    isDirty: PropTypes.bool.isRequired,
    syncErrors: PropTypes.object.isRequired,
    saveFeedSettings: PropTypes.func.isRequired,
    remoteSubmitAndWait: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      syncOption: OPTION_INDEPENDENT,
      sourceChannel: '',
      copyOptimisations: false,
      allowRichTextDescription: false
    }
  }

  initialize() {
    const { feedDetail } = this.props
    var isIndependent = feedDetail.sourceChannel === feedDetail.channel

    this.setState({
      syncOption: isIndependent ? OPTION_INDEPENDENT : OPTION_SYNC,
      sourceChannel: isIndependent ? '' : feedDetail.sourceChannel,
      changed: false,
      allowRichTextDescription: feedDetail.allowRichTextDescription
    })
  }

  componentDidMount() {
    this.initialize()
  }

  renderActions() {
    return (
      <MDBRow className='actions-row'>
        <MDBCol />
        <MDBCol className='col-8'>
          <BackButton label='BACK' float='left' />
        </MDBCol>
        <MDBCol />
      </MDBRow>
    )
  }

  handleSyncOptionChange(syncOption) {
    this.setState({ syncOption })
  }

  handleSourceChannelChange(sourceChannel) {
    this.setState({ sourceChannel })
  }

  handleCopyOptimisationsChange(copyOptimisations) {
    this.setState({ copyOptimisations })
  }

  renderFeedSyncSetting() {
    const { syncOption, sourceChannel, copyOptimisations } = this.state

    return (
      <FeedSyncSetting
        syncOption={syncOption}
        sourceChannel={sourceChannel}
        copyOptimisations={copyOptimisations}
        syncOptionChange={this.handleSyncOptionChange.bind(this)}
        sourceChannelChange={this.handleSourceChannelChange.bind(this)}
        copyOptimisationsChange={this.handleCopyOptimisationsChange.bind(this)}
      />
    )
  }

  handleRichDescriptionChange(richDescription) {
    this.setState({ allowRichTextDescription: richDescription })
  }

  renderDescriptionSetting() {
    const { allowRichTextDescription } = this.state

    return (
      <DescriptionSetting
        applyRichDescription={allowRichTextDescription}
        applyRichDescriptionChange={this.handleRichDescriptionChange.bind(this)}
      />
    )
  }

  renderUrlTaggingSetting() {
    return <UrlTaggingSetting />
  }

  checkValuesChanged() {
    const { feedDetail } = this.props
    const { syncOption, sourceChannel, allowRichTextDescription } = this.state
    var changed = false
    if (syncOption === OPTION_SYNC) {
      changed = sourceChannel !== feedDetail.sourceChannel
    } else {
      changed = feedDetail.channel !== feedDetail.sourceChannel
    }

    if (feedDetail.allowRichTextDescription !== allowRichTextDescription) {
      changed = true
    }

    return changed
  }

  async handleSave() {
    const {
      saveFeedSettings,
      websiteId,
      feedDetail,
      isDirty,
      remoteSubmitAndWait,
      fetchWebsiteDetail
    } = this.props
    const { syncOption, sourceChannel, copy, allowRichTextDescription } =
      this.state
    toastr.info('Saving your feed settings. This can take some time.')

    this.checkValuesChanged() &&
      (await saveFeedSettings(
        websiteId,
        feedDetail.channel,
        syncOption === OPTION_SYNC ? sourceChannel : feedDetail.channel,
        copy,
        allowRichTextDescription
      ))
    isDirty && (await remoteSubmitAndWait(URL_TAGGING_REDUX_FORM_NAME))
    await fetchWebsiteDetail(websiteId)

    toastr.success('Your feed settings have been saved.')
    this.handleCancel()
  }

  handleCancel() {
    this.props.history.goBack()
  }

  renderCardFooter() {
    const { isDirty, syncErrors } = this.props
    const disabled =
      this.checkValuesChanged() || (isDirty && _.isEmpty(syncErrors))
        ? false
        : true

    return (
      <MDBCardFooter>
        <MDBBtn
          color='primary'
          disabled={disabled}
          onClick={this.handleSave.bind(this)}
        >
          Save
        </MDBBtn>
        <MDBBtn color='outline' onClick={this.handleCancel.bind(this)}>
          Cancel
        </MDBBtn>
      </MDBCardFooter>
    )
  }

  renderAccountLink() {
    return <LinkedAccountsSetting />
  }

  renderFeedSettings() {
    return (
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Product feed settings</MDBCardTitle>
          {this.renderAccountLink()}
          {this.renderFeedSyncSetting()}
          {this.renderDescriptionSetting()}
          {this.renderUrlTaggingSetting()}
        </MDBCardBody>
        {this.renderCardFooter()}
      </MDBCard>
    )
  }

  render() {
    return (
      <div className='feed-settings-container'>
        {this.renderActions()}
        <MDBRow className='options-row'>
          <MDBCol />
          <MDBCol className='col-8'>{this.renderFeedSettings()}</MDBCol>
          <MDBCol />
        </MDBRow>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedDetail: websiteFeedDetailSelector(state, props),
  isDirty: isDirty(URL_TAGGING_REDUX_FORM_NAME)(state) || false,
  syncErrors: getFormSyncErrors(URL_TAGGING_REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { saveFeedSettings, remoteSubmitAndWait, fetchWebsiteDetail },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedSettingsContainer)
)
