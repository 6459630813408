import { LIST_MERCHANT_CENTER_ACCOUNTS_RESPONSE } from 'actions/google/merchant_center'

const initialState = {
  accounts: []
}

export default (state = initialState, action) => {
  switch (action.type) {

  case LIST_MERCHANT_CENTER_ACCOUNTS_RESPONSE:
    return { ...state, accounts: action.accounts }

  default:
    return state
  }
}
