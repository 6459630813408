import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBBtn, MDBIcon } from 'mdbreact'
import { channelSelector } from 'selectors/channel'
import { websiteFeedIdByChannelSelector } from 'selectors/websites'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'

export class FeedSettingsButton extends Component {
  static propTypes = {
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    feedId: PropTypes.number.isRequired
  }

  handleFeedSettingsClick() {
    const { channel, feedId, history } = this.props
    history.push(`feed_settings?channel=${channel}&feed_id=${feedId}`)
  }

  render() {
    const { channel, feedId } = this.props

    return channel && feedId ? (
      <MDBBtn color='link' onClick={this.handleFeedSettingsClick.bind(this)}>
        <MDBIcon icon='sliders-h' /> <br />
        Settings
      </MDBBtn>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  channel: channelSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedSettingsButton)
)
