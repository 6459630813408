import { FETCH_VENDORS_RESPONSE } from 'actions/inventories/vendors'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDORS_RESPONSE:
      return [...action.vendors]

    default:
      return state
  }
}
