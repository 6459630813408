import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { MDBCard, MDBCardBody } from 'mdbreact'
import DataTable from 'react-data-table-component'

const columnNames = [
  {
    selector: 'type',
    name: 'Campaign Type',
    right: false,
    style: { textAlign: 'left' }
  },
  {
    selector: 'totalConvValue',
    name: 'Total Conv. value'
  },
  {
    selector: 'totalConversions',
    name: 'Total Conversions'
  },
  {
    selector: 'totalImpressions',
    name: 'Total Impressions'
  },
  {
    selector: 'totalClicks',
    name: 'Total Clicks'
  },
  {
    selector: 'actualCost',
    name: 'Cost'
  },
  {
    selector: 'actualRoas',
    name: 'ROAS'
  }
]

export class FeedOpsDetailChartSummaryTable extends Component {
  static propTypes = {
    totals: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  getColumns() {
    return columnNames.map((c) => {
      return {
        sortable: false,
        right: true,
        style: { textAlign: 'right' },
        wrap: true,
        ...c
      }
    })
  }

  render() {
    const { totals } = this.props
    if (totals && totals.length > 0) {
      return (
        <MDBCard>
          <MDBCardBody>
            <DataTable
              className='content'
              columns={this.getColumns()}
              data={totals}
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </MDBCardBody>
        </MDBCard>
      )
    } else {
      return null
    }
  }
}

export default withRouter(FeedOpsDetailChartSummaryTable)
