import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import {
  OptimizeAiSolidIcon,
  OptimizeAiOutlineIcon
} from 'components/icons/icons'
import { MDBIcon } from 'mdbreact'
import DropdownWithIcons from './common/dropdown_with_icons'
import ReviewButton from './playbook_review_button'
import { getQueryStringByChannel } from 'util/supported_channels'

class PlaybookNavAction extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    channel: PropTypes.string.isRequired
  }

  renderPlaybookReviewButton() {
    const { item, channel } = this.props
    const { id, destination, actionName, enabled, isDismissed, tooltip } = item
    if (!id) return null
    return (
      <ReviewButton
        id={id}
        label={actionName}
        destination={destination.replace(
          'replaceChannel',
          getQueryStringByChannel(channel)
        )}
        enabled={enabled}
        isDismissed={isDismissed}
        tooltip={tooltip}
      />
    )
  }

  renderNavDropdownOption() {
    const list = [
      {
        label: 'OPTIMIZE WITH AI',
        icon: <OptimizeAiOutlineIcon />,
        action: () => {}
      },
      {
        label: this.renderPlaybookReviewButton('OPTIMIZE MANUALLY'),
        icon: <MDBIcon fas icon='tools' />
      }
    ]
    return list
  }

  renderOptimizedropdown() {
    return (
      <Button className='optimize-btn'>
        <OptimizeAiSolidIcon />
        <DropdownWithIcons
          dropdownItems={this.renderNavDropdownOption()}
          shouldRotateIcon={true}
          toggleIcon={<MDBIcon fas icon='angle-down' />}
          label={'OPTIMIZE '}
          className={'optimize'}
        />
      </Button>
    )
  }

  render() {
    return <>{this.renderPlaybookReviewButton()}</>
  }
}

export default PlaybookNavAction
