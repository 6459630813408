import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import _ from 'lodash'
import {
  MDBModalFooter,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBIcon,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'
import { websiteFeedDetailSelector } from 'selectors/websites'
import { enabledChannelsSelector } from 'selectors/websites/channel_settings'
import { canGenManualOptSuggestionsSelector } from 'selectors/user'
import { getLogoByChannel } from 'util/supported_channels'

const CANCEL_BTN_ACTION = 'Cancel'
const SAVE_BTN_ACTION = 'Save'
const SAVE_AS_OPT_SUG_BTN_ACTION = 'Save As Suggestions'

export class FooterActions extends Component {
  static propTypes = {
    saveToAllChannels: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isSaveDisabled: PropTypes.bool.isRequired,
    reduxFormName: PropTypes.string.isRequired,
    feedDetail: PropTypes.object.isRequired,
    genManualOptSuggestions: PropTypes.bool.isRequired,
    onHandleSaveToAllChannels: PropTypes.func.isRequired,
    onHandleCancel: PropTypes.func.isRequired,
    onHandleSave: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    enabledChannels: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  static defaultProps = {
    isSaveDisabled: false,
    reduxFormName: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      saveActionName: ''
    }
  }

  componentDidMount() {
    const { genManualOptSuggestions } = this.props
    this.setState({
      saveActionName: genManualOptSuggestions
        ? SAVE_AS_OPT_SUG_BTN_ACTION
        : SAVE_BTN_ACTION
    })
  }

  handleCheckboxChange() {
    const { onHandleSaveToAllChannels } = this.props
    onHandleSaveToAllChannels()
  }

  renderSaveToAllChannelsAction() {
    const { saveToAllChannels, feedDetail, enabledChannels } = this.props
    const { linkedChannels } = feedDetail
    const saveToChannels = _.difference(enabledChannels, linkedChannels)

    return saveToChannels.length > 1 ? (
      <div
        className='save-to-channels-container'
        onClick={this.handleCheckboxChange.bind(this)}
      >
        <MDBInput
          type='checkbox'
          label='Save to these channels - '
          checked={saveToAllChannels}
        />
        {saveToChannels.map((channel) => (
          <img key={channel} src={getLogoByChannel(channel)} alt={channel} />
        ))}
      </div>
    ) : null
  }

  handleActionBtnClick(actionName) {
    const { onHandleCancel, onHandleSave } = this.props
    actionName === CANCEL_BTN_ACTION
      ? onHandleCancel()
      : onHandleSave(actionName === SAVE_AS_OPT_SUG_BTN_ACTION)
  }

  computeIsSaveDisabled(actionName) {
    const { isSaveDisabled } = this.props
    return actionName === CANCEL_BTN_ACTION ? false : isSaveDisabled
  }

  renderActionButton(actionName) {
    const { isSaving } = this.props
    const btnId =
      actionName === CANCEL_BTN_ACTION
        ? 'cancel-and-continue'
        : 'save-and-continue'

    return (
      <MDBBtn
        key={actionName}
        id={btnId}
        tag='span'
        outline={actionName === CANCEL_BTN_ACTION ? true : false}
        disabled={this.computeIsSaveDisabled(actionName) || isSaving}
        color={actionName === CANCEL_BTN_ACTION ? 'dark' : 'primary'}
        onClick={this.handleActionBtnClick.bind(this, actionName)}
      >
        {actionName}
      </MDBBtn>
    )
  }

  handleSaveActionChange(actionName) {
    this.setState({ saveActionName: actionName })

    const { reduxFormName } = this.props
    if (!_.isEmpty(reduxFormName)) {
      const { change } = this.props
      change(
        reduxFormName,
        'saveOptimisationSuggestions',
        actionName === SAVE_AS_OPT_SUG_BTN_ACTION
      )
    }
  }

  renderDropdownToggle() {
    const { isSaving } = this.props
    const isDisabled = this.computeIsSaveDisabled(SAVE_BTN_ACTION) || isSaving

    return (
      <MDBDropdownToggle
        split='true'
        tag='span'
        disabled={isDisabled}
        className={`${isDisabled && 'disabled'} btn-secondary`}
      >
        <MDBIcon icon='angle-up' />
      </MDBDropdownToggle>
    )
  }

  renderActionSplitButton() {
    const { saveActionName } = this.state

    return (
      <MDBDropdown className='btn-group'>
        {this.renderActionButton(saveActionName)}
        {this.renderDropdownToggle()}
        <MDBDropdownMenu tag='span'>
          {[SAVE_BTN_ACTION, SAVE_AS_OPT_SUG_BTN_ACTION].map((actionName) => (
            <MDBDropdownItem
              key={actionName}
              tag='span'
              onClick={this.handleSaveActionChange.bind(this, actionName)}
            >
              {actionName}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }

  render() {
    return (
      <MDBModalFooter className='common-modal-footer'>
        {this.renderSaveToAllChannelsAction()}
        {this.renderActionButton(CANCEL_BTN_ACTION)}
        {this.renderActionSplitButton()}
      </MDBModalFooter>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  feedDetail: websiteFeedDetailSelector(state, props),
  genManualOptSuggestions: canGenManualOptSuggestionsSelector(state) || false,
  enabledChannels: enabledChannelsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FooterActions)
)
