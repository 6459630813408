import { createSelector } from 'reselect'
import { websiteIdSelector } from 'selectors/websites'

export const keywordIdeasSelector = (state) => state.keywordIdeas || {}

export const websiteKeywordIdeasSelector = createSelector(
  [keywordIdeasSelector, websiteIdSelector],
  (keywordIdeas, websiteId) =>
    keywordIdeas && Object.keys(keywordIdeas).length > 0
      ? keywordIdeas[websiteId]
      : {}
)

export const websiteFieldKeywordIdeasSelector = (field) =>
  createSelector([websiteKeywordIdeasSelector], (websiteKeywordIdeas) =>
    websiteKeywordIdeas && Object.keys(websiteKeywordIdeas).length > 0
      ? websiteKeywordIdeas[field]
      : []
  )
