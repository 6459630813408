import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBBtn
} from 'mdbreact'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns'
import { setDateRange } from 'actions/date_ranges'
import { dateRangeSelector, getDateString } from 'selectors/date_ranges'

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
}

const dateRangeOptions = {
  isSelected() {
    return false
  }
}

export const defaultStaticRanges = [
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday
    }),
    ...dateRangeOptions
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday
    }),
    ...dateRangeOptions
  },

  {
    label: 'This week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek
    }),
    ...dateRangeOptions
  },
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: defineds.endOfYesterday
    }),
    ...dateRangeOptions
  },
  {
    label: 'Last week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek
    }),
    ...dateRangeOptions
  },
  {
    label: 'Last 14 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -14)),
      endDate: defineds.endOfYesterday
    }),
    ...dateRangeOptions
  },
  {
    label: 'This month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth
    }),
    ...dateRangeOptions
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: defineds.endOfYesterday
    }),
    ...dateRangeOptions
  },
  {
    label: 'Last month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    }),
    ...dateRangeOptions
  }
]

export const defaultInputRanges = [
  {
    label: 'days up to today',
    range(value) {
      return {
        startDate: addDays(defineds.startOfToday, (value - 1) * -1),
        endDate: defineds.endOfToday
      }
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) {
        return ''
      }

      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
    }
  },
  {
    label: 'days up to yesterday',
    range(value) {
      return {
        startDate: addDays(defineds.startOfYesterday, (value - 1) * -1),
        endDate: defineds.endOfYesterday
      }
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfYesterday)) {
        return ''
      }

      return (
        differenceInCalendarDays(defineds.endOfYesterday, range.startDate) + 1
      )
    }
  }
]

export class DateRangePickerDropdown extends Component {
  static propTypes = {
    keyName: PropTypes.string.isRequired,
    setDateRange: PropTypes.func.isRequired,
    dateRange: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.minDate = addDays(new Date(), -3650)
    this.maxDate = new Date()
    this.state = {
      dateRange: {
        startDate: this.props.dateRange.startDate,
        endDate: this.props.dateRange.endDate
      }
    }
  }

  handleSelect(payload) {
    this.setState({ dateRange: payload.selection })
  }

  handleApply() {
    const { setDateRange, keyName } = this.props
    const { dateRange } = this.state
    setDateRange(keyName, {
      ...dateRange
    })
  }

  handleCancel() {
    this.setState({
      dateRange: {
        startDate: this.props.dateRange.startDate,
        endDate: this.props.dateRange.endDate
      }
    })
  }

  render() {
    const { dateRange } = this.state
    return (
      <MDBDropdown className={'date-range-picker-dropdown'}>
        <MDBDropdownToggle nav caret>
          <label className='active'>Date Range</label>
          {`${getDateString(dateRange.startDate)} - ${getDateString(
            dateRange.endDate
          )}`}
        </MDBDropdownToggle>

        <MDBDropdownMenu right={true}>
          <DateRangePicker
            showMonthArrow={true}
            direction='vertical'
            scroll={{ enabled: true }}
            minDate={this.minDate}
            maxDate={this.maxDate}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={true}
            ranges={[{ ...dateRange, key: 'selection' }]}
            onChange={this.handleSelect.bind(this)}
            staticRanges={defaultStaticRanges}
            inputRanges={defaultInputRanges}
          />
          <span className={'cancel-and-apply-buttons'}>
            <MDBDropdownItem>
              <p
                className={'cancel-button'}
                onClick={this.handleCancel.bind(this)}
              >
                Cancel
              </p>
            </MDBDropdownItem>
            <MDBDropdownItem>
              <p
                className={'apply-button'}
                onClick={this.handleApply.bind(this)}
              >
                Apply
              </p>
            </MDBDropdownItem>
          </span>
        </MDBDropdownMenu>
      </MDBDropdown>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    dateRange: dateRangeSelector(props.keyName)(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDateRange
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateRangePickerDropdown)
