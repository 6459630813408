import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBMask, MDBCardText, MDBBtn } from 'mdbreact'

export class ChartOverlay extends Component {
  static propTypes = {
    linkPrefixText: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    destination: PropTypes.string,
    linkSuffixText: PropTypes.string.isRequired
  }

  pushPath() {
    const { destination, history } = this.props
    const url = `${destination}${history.location.search}`
    this.props.history.push(url, {})
  }

  render() {
    const { linkPrefixText, linkText, destination, linkSuffixText } = this.props
    const isAbsolutePath =
      destination.startsWith('http://') || destination.startsWith('https://')
    const btnProps = isAbsolutePath
      ? { href: destination, target: '_blank' }
      : { onClick: this.pushPath.bind(this) }

    return (
      <MDBMask overlay='white-light' className='flex-center'>
        <MDBCardText className='overlay-text'>
          {linkPrefixText}
          {linkText && <MDBBtn {...btnProps}>{linkText}</MDBBtn>}
          {linkSuffixText}
        </MDBCardText>
      </MDBMask>
    )
  }
}

export const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const ChartOverlayWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartOverlay)

export default withRouter(ChartOverlayWithRedux)
