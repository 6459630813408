import changeCase from 'change-case'
import googleMerchantCenterLogo from 'images/feed_ops/google/google-merchant-center.png'
import googleAdsLogo from 'images/feed_ops/google/google-ads.png'
import facebookLogo from 'images/feed_ops/facebook/meta.png'
import microsoftLogo from 'images/feed_ops/microsoft/microsoft.png'
import bazaarvoiceLogo from 'images/feed_ops/bazaarvoice/bazaarvoice.png'
import reebeloLogo from 'images/feed_ops/reebelo/reebelo.png'
import pinterestLogo from 'images/feed_ops/pinterest/pinterest.png'
import tiktokLogo from 'images/feed_ops/tiktok/tiktok.png'
import ebayLogo from 'images/feed_ops/ebay/ebay.png'
import amazonLogo from 'images/feed_ops/amazon/amazon.png'
import catchLogo from 'images/feed_ops/catch/catch.png'
import bunningsLogo from 'images/feed_ops/bunnings/bunnings.png'

export {
  googleMerchantCenterLogo,
  googleAdsLogo,
  facebookLogo,
  microsoftLogo,
  bazaarvoiceLogo,
  reebeloLogo,
  pinterestLogo
}

export const CHANNEL_ALL = 'all channels'
export const CHANNEL_GOOGLE = 'google'
export const CHANNEL_FACEBOOK = 'facebook'
export const CHANNEL_MICROSOFT = 'microsoft'
export const CHANNEL_BAZAARVOICE = 'bazaarvoice'
export const CHANNEL_REEBELO = 'reebelo'
export const CHANNEL_PINTEREST = 'pinterest'
export const CHANNEL_TIKTOK = 'tiktok'
export const CHANNEL_EBAY = 'ebay'
export const CHANNEL_AMAZON = 'amazon'
export const CHANNEL_CATCH_AU = 'catch_au'
export const CHANNEL_BUNNINGS = 'bunnings'
export const CHANNEL_OTHER = 'other'
export const SUPPORTED_CHANNELS = [
  CHANNEL_ALL,
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT,
  CHANNEL_BAZAARVOICE,
  CHANNEL_REEBELO,
  CHANNEL_PINTEREST,
  CHANNEL_TIKTOK,
  CHANNEL_EBAY,
  CHANNEL_AMAZON,
  CHANNEL_CATCH_AU,
  CHANNEL_BUNNINGS,
  CHANNEL_OTHER
]

export const PLAYBOOK_CHANNELS = [
  CHANNEL_ALL,
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT,
  CHANNEL_BAZAARVOICE,
  CHANNEL_REEBELO,
  CHANNEL_PINTEREST,
  CHANNEL_TIKTOK,
  CHANNEL_CATCH_AU,
  CHANNEL_BUNNINGS,
  CHANNEL_EBAY,
  CHANNEL_AMAZON
]

export const CONNECTABLE_CHANNELS = [
  CHANNEL_GOOGLE,
  CHANNEL_FACEBOOK,
  CHANNEL_MICROSOFT,
  CHANNEL_BAZAARVOICE,
  CHANNEL_REEBELO,
  CHANNEL_PINTEREST,
  CHANNEL_TIKTOK,
  CHANNEL_CATCH_AU,
  CHANNEL_BUNNINGS,
  CHANNEL_EBAY,
  CHANNEL_AMAZON
]

export const MARKETPLACE_CHANNELS = [
  CHANNEL_BAZAARVOICE,
  CHANNEL_REEBELO,
  CHANNEL_CATCH_AU,
  CHANNEL_BUNNINGS,
  CHANNEL_EBAY,
  CHANNEL_AMAZON
]

export const REPORTING_CHANNELS = [CHANNEL_GOOGLE, CHANNEL_MICROSOFT]

export const FREE_LISTING_CHANNELS = [CHANNEL_GOOGLE, CHANNEL_MICROSOFT]

export const API_CONNECTABLE_CHANNELS = [CHANNEL_GOOGLE, CHANNEL_MICROSOFT]

export const getLogoByChannel = (channel) => {
  switch (channel) {
    case CHANNEL_ALL:
      return [googleMerchantCenterLogo, facebookLogo, microsoftLogo]
    case CHANNEL_FACEBOOK:
      return facebookLogo
    case CHANNEL_MICROSOFT:
      return microsoftLogo
    case CHANNEL_BAZAARVOICE:
      return bazaarvoiceLogo
    case CHANNEL_REEBELO:
      return reebeloLogo
    case CHANNEL_PINTEREST:
      return pinterestLogo
    case CHANNEL_TIKTOK:
      return tiktokLogo
    case CHANNEL_EBAY:
      return ebayLogo
    case CHANNEL_AMAZON:
      return amazonLogo
    case CHANNEL_CATCH_AU:
      return catchLogo
    case CHANNEL_BUNNINGS:
      return bunningsLogo
    default:
      return googleMerchantCenterLogo
  }
}

export const getQueryStringByChannel = (channel) => {
  switch (channel) {
    case CHANNEL_FACEBOOK:
      return CHANNEL_FACEBOOK
    case CHANNEL_MICROSOFT:
      return CHANNEL_MICROSOFT
    default:
      return CHANNEL_GOOGLE
  }
}

const PROGRAMS = {
  [CHANNEL_GOOGLE]: [
    'Google Shopping Ads / Pmax',
    'Google Free Product Listings',
    'Google Dynamic Remarketing'
  ],
  [CHANNEL_MICROSOFT]: [
    'Bing Shopping Ads',
    'Bing Free Product Listings',
    'Microsoft MSAN'
  ],
  [CHANNEL_FACEBOOK]: [
    'Meta Advantage+ Catalog Ads',
    'Collection Ads',
    'Carousel Ads',
    'Ads With Product Tags'
  ],
  [CHANNEL_PINTEREST]: ['Pinterest Ads'],
  [CHANNEL_TIKTOK]: ['TikTok Shop Ads']
}

export const getChannelPrograms = (channel) => {
  return PROGRAMS[channel] || []
}

const FEEDOPS_FEATURES = {
  [CHANNEL_GOOGLE]: [
    'Send product data updates to Google Merchant Center',
    'Activate a playbook for better product visibility in Google',
    'Detect critical Google Merchant Center errors'
  ],
  [CHANNEL_MICROSOFT]: [
    'Send product data updates to Microsoft Merchant Center',
    'Activate a playbook for better product visibility in Microsoft',
    'Detect critical Microsoft Merchant Center errors'
  ],
  [CHANNEL_FACEBOOK]: ['Generate an XML feed that you can upload to Meta'],
  [CHANNEL_PINTEREST]: [
    'Generate an XML feed that you can upload to Pinterest'
  ],
  [CHANNEL_TIKTOK]: [
    'Generate an XML feed that you can upload to TikTok Ads Manager as a Catalog'
  ]
}

export const getFeaturesByChannel = (channel) => {
  return FEEDOPS_FEATURES[channel] || []
}

export const getDisplayName = (channel) => {
  switch (channel) {
    case CHANNEL_GOOGLE:
      return 'Google Merchant Center'
    case CHANNEL_MICROSOFT:
      return 'Microsoft Merchant Center'
    case CHANNEL_FACEBOOK:
      return 'Meta Catalogues'
    case CHANNEL_TIKTOK:
      return 'TikTok'
    default:
      return changeCase.titleCase(channel)
  }
}

export const hasFreeListings = (channel) =>
  FREE_LISTING_CHANNELS.includes(channel)

export default SUPPORTED_CHANNELS
