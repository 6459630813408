import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact'
import AvailableColumnSelectionSection from './available_column_selection_section'

export default class ModalBody extends Component {
  static propTypes = {
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAddAllColumnsClick: PropTypes.func.isRequired,
    handleRemoveAllColumnsClick: PropTypes.func.isRequired,
    handleAddRemoveColumnClick: PropTypes.func.isRequired,
    handleColumnOrderChange: PropTypes.func.isRequired
  }

  handleRemoveAllColumnsClick() {
    const { handleRemoveAllColumnsClick } = this.props
    handleRemoveAllColumnsClick()
  }

  handleRemoveColumnClick(columnName) {
    const { handleAddRemoveColumnClick } = this.props
    handleAddRemoveColumnClick(columnName, 'Remove')
  }

  handleColumnOrder(direction, columnName) {
    const { handleColumnOrderChange } = this.props
    handleColumnOrderChange(direction, columnName)
  }

  renderArrowIcon(cssClass, icon, clickable, direction, columnName) {
    return (
      <MDBIcon
        fal
        className={cssClass}
        icon={icon}
        {...(clickable && {
          onClick: this.handleColumnOrder.bind(this, direction, columnName)
        })}
      />
    )
  }

  renderOrderArrows(first, last, columnName) {
    return (
      <div className='add-custom-button'>
        {this.renderArrowIcon(
          first ? 'disabled' : '',
          'arrow-alt-up',
          !first,
          'up',
          columnName
        )}
        {this.renderArrowIcon(
          last ? 'disabled' : '',
          'arrow-alt-down',
          !last,
          'down',
          columnName
        )}
      </div>
    )
  }

  renderSelectedColumns(columnName, first, last) {
    const lockedColumn = columnName === 'Item ID'

    return (
      <MDBRow key={`${columnName}-selected`}>
        <MDBCol className='column-name-col'>
          {lockedColumn ? (
            <MDBIcon fal icon='lock' />
          ) : (
            this.renderOrderArrows(first, last, columnName)
          )}
          {columnName}
        </MDBCol>
        {!lockedColumn && (
          <MDBCol>
            <MDBBtn
              id={`${columnName}-remove-btn`}
              onClick={this.handleRemoveColumnClick.bind(this, columnName)}
              tag='span'
            >
              Remove
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>
    )
  }

  renderSelectedColumnsSection() {
    const { selectedColumns } = this.props

    return (
      <>
        <MDBRow>
          <MDBCol>
            <MDBBtn
              id='remove-all-btn'
              onClick={this.handleRemoveAllColumnsClick.bind(this)}
              tag='span'
            >
              Remove All
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        {selectedColumns.map((columnName, index) =>
          this.renderSelectedColumns(
            columnName,
            index === 1,
            index === selectedColumns.length - 1
          )
        )}
      </>
    )
  }

  handleAddAllColumnsClick(filteredColumns) {
    const { handleAddAllColumnsClick } = this.props
    handleAddAllColumnsClick(filteredColumns)
  }

  handleAddColumnClick(columnName) {
    const { handleAddRemoveColumnClick } = this.props
    handleAddRemoveColumnClick(columnName, 'Add')
  }

  render() {
    const { selectedColumns, availableColumns } = this.props

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol size='8'>
            <AvailableColumnSelectionSection
              selectedColumns={selectedColumns}
              availableColumns={availableColumns}
              handleAddAllColumnsClick={this.handleAddAllColumnsClick.bind(
                this
              )}
              handleAddColumnClick={this.handleAddColumnClick.bind(this)}
            />
          </MDBCol>
          <MDBCol size='4'>{this.renderSelectedColumnsSection()}</MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}
