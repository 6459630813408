import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdbreact'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import AttributeMappingPopover from './attribute_mapping_popover'
import { change } from 'redux-form'
import { fetchInventoryAttributes } from 'actions/product_feeds/replacements'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'

export class CustomFieldMappings extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    mappableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    storeAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    existingMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    reduxFormName: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchInventoryAttributes: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  async componentDidMount() {
    await this.fetchInventoryAttributes()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (prevProps.websiteId !== websiteId && websiteId !== 0) {
      await this.fetchInventoryAttributes()
    }
  }

  async fetchInventoryAttributes() {
    const { fetchInventoryAttributes, websiteId } = this.props
    await fetchInventoryAttributes(websiteId, false)
    this.setState({ loading: false })
  }

  handleRemoveMappingClick(mapping, index) {
    const { existingMappings, reduxFormName } = this.props
    var updatedExistingMappings = [...existingMappings]
    if (updatedExistingMappings[index] === mapping) {
      updatedExistingMappings.splice(index, 1)

      const { change } = this.props
      change(reduxFormName, 'existingMappings', updatedExistingMappings)
    }
  }

  handleFeedOpsAttributeChange(index, selectedAttribute) {
    const { mappableAttributes, existingMappings, change, reduxFormName } =
      this.props
    var updatedExistingMappings = [...existingMappings]
    updatedExistingMappings[index].fieldName = mappableAttributes.find(
      (a) => a.displayName === selectedAttribute.columnName
    ).name
    updatedExistingMappings[index].displayName = selectedAttribute.columnName
    change(reduxFormName, 'existingMappings', updatedExistingMappings)
  }

  renderFeedOpsAttributePopover(displayName, mapping, index) {
    const { attributes, mappableAttributes, existingMappings, reduxFormName } =
      this.props

    return (
      <AttributeMappingPopover
        isFeedOpsAttributePopover={true}
        mapping={mapping}
        name={displayName}
        attributes={attributes}
        mappableAttributes={mappableAttributes}
        existingMappings={existingMappings}
        reduxFormName={reduxFormName}
        handleFeedOpsAttributeChange={this.handleFeedOpsAttributeChange.bind(
          this,
          index
        )}
      />
    )
  }

  handleStoreAttributeChange(index, selectedAttribute) {
    const { existingMappings, change, reduxFormName } = this.props
    var updatedExistingMappings = [...existingMappings]
    updatedExistingMappings[index].sourceId = selectedAttribute.id
    updatedExistingMappings[index].sourceName = selectedAttribute.columnName
    change(reduxFormName, 'existingMappings', updatedExistingMappings)
  }

  handleStoreAttributeStaticValueChange(index, value) {
    const { existingMappings, change, reduxFormName } = this.props
    var updatedExistingMappings = [...existingMappings]
    if (!_.isEmpty(value)) {
      updatedExistingMappings[index].sourceId = `${value}@`
    } else {
      updatedExistingMappings[index].sourceId = ''
    }
    updatedExistingMappings[index].sourceName = value
    change(reduxFormName, 'existingMappings', updatedExistingMappings)
  }

  renderStoreAttributePopover(sourceName, mapping, index) {
    const { storeAttributes, reduxFormName } = this.props

    return (
      <AttributeMappingPopover
        isFeedOpsAttributePopover={false}
        mapping={mapping}
        name={sourceName}
        storeAttributes={storeAttributes}
        reduxFormName={reduxFormName}
        handleStoreAttributeChange={this.handleStoreAttributeChange.bind(
          this,
          index
        )}
        handleStoreAttributeStaticValueChange={this.handleStoreAttributeStaticValueChange.bind(
          this,
          index
        )}
      />
    )
  }

  renderCustomMappings() {
    const { existingMappings } = this.props
    var customMappingRows = []
    existingMappings.forEach((mapping, index) => {
      customMappingRows.push(
        <MDBRow
          key={mapping.displayName + index}
          className='custom-mapping-rows'
        >
          <MDBCol size='1'>
            <MDBIcon
              icon='times'
              onClick={this.handleRemoveMappingClick.bind(this, mapping, index)}
            />
          </MDBCol>
          {this.renderFeedOpsAttributePopover(
            mapping.displayName,
            mapping,
            index
          )}
          {this.renderStoreAttributePopover(mapping.sourceName, mapping, index)}
        </MDBRow>
      )
    })

    return customMappingRows
  }

  isEmptyMappingPresent() {
    const { existingMappings } = this.props
    for (var index = 0; index < existingMappings.length; index++) {
      const mapping = existingMappings[index]
      if (!!!mapping.fieldName || !!!mapping.sourceId) {
        return true
      }
    }

    return false
  }

  handleAddMappingClick() {
    const { existingMappings, change, reduxFormName } = this.props
    var updatedExistingMappings = [...existingMappings]
    updatedExistingMappings.push({
      displayName: '',
      fieldName: '',
      sourceId: '',
      sourceName: ''
    })

    change(reduxFormName, 'existingMappings', updatedExistingMappings)
  }

  renderAddMappingButton() {
    const disabled = this.isEmptyMappingPresent()

    return (
      <MDBRow key='add-mapping-row' className='add-mapping-row'>
        <MDBCol size='1' />
        <MDBCol>
          <MDBBtn
            tag='span'
            className='add-mapping-btn'
            disabled={disabled}
            onClick={this.handleAddMappingClick.bind(this)}
          >
            <MDBIcon icon='plus' /> Add Mapping
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    )
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return <IndeterminateProgressIndicator />
    }

    const { existingMappings } = this.props
    return (
      <div className='custom-field-mappings-section'>
        <MDBRow key='header-row' className='header-row'>
          <MDBCol size='1' />
          <MDBCol>FeedOps Field</MDBCol>
          <MDBCol>Source</MDBCol>
        </MDBRow>
        {existingMappings &&
          existingMappings.length > 0 &&
          this.renderCustomMappings()}
        {this.renderAddMappingButton()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  attributes: websiteAllAttributesSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInventoryAttributes, change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomFieldMappings)
)
