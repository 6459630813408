import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as searchReducer } from 'redux-search'
import campaignTypesReducer from './campaign_types'
import campaignTypeConfigurationsReducer from './campaign_type_configurations'
import websiteDetailsReducer from './website_details'
import keywordListReducer from './keyword_list'
import keywordSuggestionsReducer from './keyword_suggestions'
import searchTermRequestsReducer from './search_term_requests'
import adHeadlineListReducer from './ad_headline_list'
import shoppingMappingsSheetReducer from './shopping_mappings_sheet'
import shoppingLinkReducer from './shopping_link'
import variationsSheetReducer from './variations_sheet'
import scenarioReducer from './scenario'
import inventoriesReducer from './inventories'
import currenciesReducer from './currencies'
import adwordsAccountReducer from './adwords_account'
import buildsReducer from './builds'
import structuredSnippetReducer from './structured_snippet'
import promotionsReducer from './promotions'
import merchantCenterFeedReducer from './merchant_center_feed'
import productsReducer from './scenario/inventory/products'
import adsReducer from './scenario/ads'
import feedsReducer from './feeds'
import keywordsReducer from './scenario/keywords'
import { adGroupsReducers, adGroupsStoreName } from 'paginators/ad_groups'
import usersReducer from './users'
import websitesReducer from './websites'
import notificationsReducer from './notifications'
import googleReducer from './google'
import microsoftReducer from './microsoft'
import appReducer from './app'
import billingReducer from './billing'
import feedAppReducer from './feed_app/api'
import workflowReducer from './workflow'
import settingsReducer from './settings'
import advertisingGoalTypesReducer from './advertising_goal_types'
import advertisingReducer from './advertising'
import dateRangesReducer from './date_ranges'
import qualityScoreComponentsReducer from './quality_score_components'
import productFeedsTitlesReducer from './product_feeds/titles'
import productFeedsReplacementsReducer from './product_feeds/replacements'
import productUrlSettingsReducer from './product_url_settings'
import performanceReducer from './performance'
import driveDocsReducer from './google/drive_docs'
import playbookReducer from './playbook/playbook_progress'
import keywordIdeasReducer from './keyword_ideas'
import geoTargetsReducer from './geo_targets'
import feedopsFeaturesReducer from './feedops_features'
import accountsReducer from './accounts'
import valueReducer from './value/index'
import rulesReducer from './inventories/rules'
import verticalReducer from './vertical/index'
import vendorsReducer from './inventories/vendors'
import feedopsFeedbacksReducer from './feed_ops/feedbacks'
import { routerReducer } from 'react-router-redux'

const reducers = {
  app: appReducer,
  billing: billingReducer,
  feedApp: feedAppReducer,
  adwordsAccount: adwordsAccountReducer,
  websiteDetails: websiteDetailsReducer,
  feeds: feedsReducer,
  campaignTypes: campaignTypesReducer,
  campaignTypeConfigurations: campaignTypeConfigurationsReducer,
  keywordList: keywordListReducer,
  keywordSuggestions: keywordSuggestionsReducer,
  searchTermRequests: searchTermRequestsReducer,
  adHeadlineList: adHeadlineListReducer,
  scenario: scenarioReducer,
  inventories: inventoriesReducer,
  form: formReducer,
  shoppingMappingsSheet: shoppingMappingsSheetReducer,
  shoppingLink: shoppingLinkReducer,
  currencies: currenciesReducer,
  variationsSheet: variationsSheetReducer,
  builds: buildsReducer,
  structuredSnippets: structuredSnippetReducer,
  promotions: promotionsReducer,
  merchantCenterFeed: merchantCenterFeedReducer,
  search: searchReducer,
  products: productsReducer,
  ads: adsReducer,
  keywords: keywordsReducer,
  users: usersReducer,
  websites: websitesReducer,
  notifications: notificationsReducer,
  google: googleReducer,
  microsoft: microsoftReducer,
  performance: performanceReducer,
  [adGroupsStoreName]: adGroupsReducers,
  routing: routerReducer,
  workflow: workflowReducer,
  settings: settingsReducer,
  advertisingGoalTypes: advertisingGoalTypesReducer,
  advertising: advertisingReducer,
  dateRanges: dateRangesReducer,
  qualityScoreComponents: qualityScoreComponentsReducer,
  productFeedsTitles: productFeedsTitlesReducer,
  productFeedsReplacements: productFeedsReplacementsReducer,
  productUrlSettings: productUrlSettingsReducer,
  driveDocs: driveDocsReducer,
  playbook: playbookReducer,
  keywordIdeas: keywordIdeasReducer,
  geoTargets: geoTargetsReducer,
  feedopsFeatures: feedopsFeaturesReducer,
  accounts: accountsReducer,
  value: valueReducer,
  rules: rulesReducer,
  vertical: verticalReducer,
  vendors: vendorsReducer,
  feedopsFeedbacks: feedopsFeedbacksReducer
}
const reducer = combineReducers(reducers)
export default reducer
