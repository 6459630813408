import camelCaseKeys from 'camelcase-keys'
import { getAccessToken } from 'selectors/google/user'

export const LIST_ANALYTICS_ACCOUNTS_REQUEST = 'LIST_ANALYTICS_ACCOUNTS_REQUEST'
export const LIST_ANALYTICS_ACCOUNTS_RESPONSE =
  'LIST_ANALYTICS_ACCOUNTS_RESPONSE'
export const CREATE_ANALYTICS_ACCOUNT_REQUEST =
  'CREATE_ANALYTICS_ACCOUNT_REQUEST'
export const CREATE_ANALYTICS_ACCOUNT_RESPONSE =
  'CREATE_ANALYTICS_ACCOUNT_RESPONSE'
export const FETCH_ANALYTICS_ACCESS_TOKEN_REQUEST =
  'FETCH_ANALYTICS_ACCESS_TOKEN_REQUEST'
export const FETCH_ANALYTICS_ACCESS_TOKEN_SUCCESS =
  'FETCH_ANALYTICS_ACCESS_TOKEN_SUCCESS'
export const FETCH_ANALYTICS_REPORT_REQUEST = 'FETCH_ANALYTICS_REPORT_REQUEST'
export const FETCH_ANALYTICS_REPORT_SUCCESS = 'FETCH_ANALYTICS_REPORT_SUCCESS'
export const FETCH_ANALYTICS_REPORT_ERROR = 'FETCH_ANALYTICS_REPORT_ERROR'
export const SET_ANALYTICS_API_READY = 'SET_ANALYTICS_API_READY'
export const FETCH_ANALYTICS_VIEWS_REQUEST = 'FETCH_ANALYTICS_VIEWS_REQUEST'
export const FETCH_ANALYTICS_VIEWS_SUCCESS = 'FETCH_ANALYTICS_VIEWS_SUCCESS'
export const FETCH_ANALYTICS_VIEWS_ERROR = 'FETCH_ANALYTICS_VIEWS_ERROR'
export const SET_SELECTED_VIEW_ACTION = 'SET_SELECTED_VIEW_ACTION'
export const FETCH_ANALYTICS_FEEDOPS_REPORT_REQUEST =
  'FETCH_ANALYTICS_FEEDOPS_REPORT_REQUEST'
export const FETCH_ANALYTICS_FEEDOPS_REPORT_SUCCESS =
  'FETCH_ANALYTICS_FEEDOPS_REPORT_SUCCESS'
export const FETCH_ANALYTICS_FEEDOPS_REPORT_ERROR =
  'FETCH_ANALYTICS_FEEDOPS_REPORT_ERROR'

export const listAnalyticsAccountsRequest = () => ({
  type: LIST_ANALYTICS_ACCOUNTS_REQUEST
})

export const listAnalyticsAccountsResponse = (accounts) => ({
  type: LIST_ANALYTICS_ACCOUNTS_RESPONSE,
  accounts
})

export const listAnalyticsAccounts = () => (dispatch, getState) => {
  const accessToken = getAccessToken(getState)
  if (!accessToken) {
    return Promise.resolve()
  }

  dispatch(listAnalyticsAccountsRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/google/analytics/accounts?access_token=${accessToken}`,
    dataType: 'json',
    success: (response) => {
      return dispatch(
        listAnalyticsAccountsResponse(
          response.accounts.map((a) => camelCaseKeys(a, { deep: true }))
        )
      )
    }
  })
}

export const createAnalyticsAccountRequest = (websiteId) => ({
  type: CREATE_ANALYTICS_ACCOUNT_REQUEST,
  websiteId
})

export const createAnalyticsAccountResponse = (websiteId, response) => ({
  type: CREATE_ANALYTICS_ACCOUNT_RESPONSE,
  websiteId,
  response
})

export const createAnalyticsAccount = (websiteId) => (dispatch) => {
  dispatch(createAnalyticsAccountRequest(websiteId))
  return $.ajax({
    method: 'POST',
    url: `/ad_champion/google/analytics/accounts/create_account`,
    data: {
      website_id: websiteId
    },
    dataType: 'json',
    success: (response) => {
      return dispatch(createAnalyticsAccountResponse(websiteId, response))
    }
  })
}

export const fetchAnalyticsAccessTokenRequest = (websiteId) => ({
  type: FETCH_ANALYTICS_ACCESS_TOKEN_REQUEST,
  websiteId
})

export const fetchAnalyticsAccessTokenSuccess = (websiteId, response) => ({
  type: FETCH_ANALYTICS_ACCESS_TOKEN_SUCCESS,
  websiteId,
  accessToken: response.accessToken
})

export const fetchAnalyticsAccessToken = (websiteId) => async (dispatch) => {
  dispatch(fetchAnalyticsAccessTokenRequest(websiteId))
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/google/analytics/general/fetch_report_access_token`,
    data: {
      website_id: websiteId
    },
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchAnalyticsAccessTokenSuccess(
          websiteId,
          camelCaseKeys(response, { deep: true })
        )
      )
    }
  })
}

const setAnalyticsApiReadyAction = () => ({
  type: SET_ANALYTICS_API_READY,
  analyticsApiReady: true
})

export const setAnalyticsApiReady = () => (dispatch) => {
  dispatch(setAnalyticsApiReadyAction())
}

const fetchAnalyticsReportRequest = () => ({
  type: FETCH_ANALYTICS_REPORT_REQUEST
})

const fetchAnalyticsReportSuccess = (report) => ({
  type: FETCH_ANALYTICS_REPORT_SUCCESS,
  headers: report.columnHeaders,
  totals: report.totalsForAllResults,
  rows: report.rows
})

const fetchAnalyticsReportError = (response) => ({
  type: FETCH_ANALYTICS_REPORT_ERROR,
  error: response.error.status
})

const analyticsQuery = (accessToken, query) => {
  return new Promise((resolve, reject) => {
    global.gapi.analytics.auth.authorize({
      serverAuth: {
        access_token: accessToken
      }
    })
    var data = new global.gapi.analytics.report.Data({ query })
    data
      .once('success', (response) => {
        resolve(response)
      })
      .once('error', (response) => {
        reject(response)
      })
      .execute()
  })
}

const getDateString = (date) =>
  `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`

export const fetchAnalyticsReport =
  (accessToken, analyticsViewId, startDate, endDate) => async (dispatch) => {
    dispatch(fetchAnalyticsReportRequest())

    const query = {
      ids: `ga:${analyticsViewId}`,
      'start-date': getDateString(startDate),
      'end-date': getDateString(endDate),
      metrics: 'ga:sessions,ga:transactions,ga:transactionRevenue',
      dimensions: 'ga:channelGrouping'
    }
    try {
      var response = await analyticsQuery(accessToken, query)
      dispatch(fetchAnalyticsReportSuccess(response))
    } catch (error) {
      dispatch(fetchAnalyticsReportError(error))
    }
  }

const fetchAnalyticsViewsRequest = () => ({
  type: FETCH_ANALYTICS_VIEWS_REQUEST
})

const fetchAnalyticsViewsSuccess = (response) => ({
  type: FETCH_ANALYTICS_VIEWS_SUCCESS,
  views: response.views
})

const fetchAnalyticsViewsError = (response) => ({
  type: FETCH_ANALYTICS_VIEWS_ERROR,
  error: response.error
})

export const fetchAnalyticsViews = (websiteId) => async (dispatch) => {
  dispatch(fetchAnalyticsViewsRequest())
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/google/analytics/general/fetch_analytics_views`,
    data: {
      website_id: websiteId
    },
    dataType: 'json',
    success: (response) => {
      return dispatch(
        fetchAnalyticsViewsSuccess(camelCaseKeys(response, { deep: true }))
      )
    },
    error: (response) => {
      return dispatch(
        fetchAnalyticsViewsError(camelCaseKeys(response, { deep: true }))
      )
    }
  })
}

const setSelectedViewAction = (selectedView) => ({
  type: SET_SELECTED_VIEW_ACTION,
  selectedView
})

export const setSelectedView = (selectedView) => (dispatch) => {
  dispatch(setSelectedViewAction(selectedView))
}

const fetchAnalyticsFeedopsReportRequest = (
  utmSource,
  utmMedium,
  startDate,
  endDate
) => ({
  type: FETCH_ANALYTICS_FEEDOPS_REPORT_REQUEST,
  utmSource,
  utmMedium,
  startDate,
  endDate
})

const fetchAnalyticsFeedopsReportSuccess = (utmSource, utmMedium, report) => ({
  type: FETCH_ANALYTICS_FEEDOPS_REPORT_SUCCESS,
  utmSource,
  utmMedium,
  headers: report.columnHeaders,
  rows: report.rows
})

const fetchAnalyticsFeedopsReportError = (response) => ({
  type: FETCH_ANALYTICS_FEEDOPS_REPORT_ERROR,
  error: response.error.status
})

export const fetchAnalyticsFeedopsReport =
  (accessToken, analyticsViewId, startDate, endDate, utmSource, utmMedium) =>
  async (dispatch) => {
    dispatch(
      fetchAnalyticsFeedopsReportRequest(
        utmSource,
        utmMedium,
        startDate,
        endDate
      )
    )

    const filters = `ga:source==${utmSource},ga:medium==${utmMedium}`
    const query = {
      ids: `ga:${analyticsViewId}`,
      'start-date': getDateString(startDate),
      'end-date': getDateString(endDate),
      metrics: 'ga:sessions',
      dimensions: 'ga:date'
    }
    try {
      var totalResponse = {}
      var segmentResponse = await analyticsQuery(accessToken, {
        ...query,
        filters
      })
      totalResponse = await analyticsQuery(accessToken, query)
      const response = {
        ...totalResponse,
        rows: [
          ...segmentResponse.rows.map((row, i) => [
            ...row,
            totalResponse.rows[i][1]
          ])
        ]
      }
      dispatch(
        fetchAnalyticsFeedopsReportSuccess(utmSource, utmMedium, response)
      )
    } catch (error) {
      dispatch(fetchAnalyticsFeedopsReportError(error))
      throw error
    }
  }
