import {
  GET_NOTIFICATIONS_RESPONSE,
  ACKNOWLEDGE_NOTIFICATION_RESPONSE
} from 'actions/notifications'

const initialState = {
  list: []
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_NOTIFICATIONS_RESPONSE:
      return { ...state, list: action.notifications }
    case ACKNOWLEDGE_NOTIFICATION_RESPONSE:
      return { ...state, list: state.list.map(n => n.id === action.id ? { ...n, status: 'acknowledged' } : n)}
    default:
      return state
  }
}

export default reducer