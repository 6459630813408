import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { fetchProductUrlSetting } from 'actions/product_url_settings'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AnalyticsTaggingCard from 'components/websites/merchant_center_diagnostics/overview/analytics_tagging_card'
import {
  websiteIdSelector,
  websiteFeedDetailSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  CONNECTABLE_CHANNELS,
  MARKETPLACE_CHANNELS
} from 'util/supported_channels'

import './url_tagging_setting.scss'

export class UrlTaggingSetting extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedDetail: PropTypes.object.isRequired,
    channel: PropTypes.oneOf(CONNECTABLE_CHANNELS).isRequired,
    fetchProductUrlSetting: PropTypes.func.isRequired
  }

  async componentDidMount() {
    const { fetchProductUrlSetting, websiteId, feedDetail } = this.props
    if (feedDetail && Object.keys(feedDetail).length > 0) {
      await fetchProductUrlSetting(websiteId, feedDetail.id)
    }
  }

  render() {
    const { channel } = this.props

    return [...MARKETPLACE_CHANNELS].includes(channel) ? null : (
      <Accordion className='url-tagging-setting'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          URL tagging settings
        </AccordionSummary>
        <AccordionDetails>
          <AnalyticsTaggingCard />
        </AccordionDetails>
      </Accordion>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedDetail: websiteFeedDetailSelector(state, props),
  channel: channelSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProductUrlSetting }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UrlTaggingSetting)
)
