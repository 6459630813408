import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, registerField } from 'redux-form'
import {
  MDBIcon,
  MDBBtn,
  MDBSelect,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCardFooter
} from 'mdbreact'
import { googleAuthRequestToken } from 'util/google/google_apis'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import {
  listMerchantCenterAccounts,
  updateMerchantCenterAccount
} from 'actions/google/merchant_center'
import {
  fetchWebsiteDetail,
  fetchWebsiteMerchantCenterStatus
} from 'actions/websites'
import CardNavButton from 'components/card_nav_button'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import MerchantCenterFeedUploadForm from 'components/google/merchant_center_feed_upload_form'
import { REDUX_FORM_NAME as MERCHANT_CENTER_FEED_UPLOAD_FORM } from 'components/google/merchant_center_feed_upload_form'
import { REDUX_FORM_NAME as COUNTRY_OF_SALE_FORM } from 'components/google/country_of_sale_selector'
import { REDUX_FORM_NAME as LANGUAGE_FORM } from 'components/google/language_selector'
import GenericContent from 'components/websites/workflow/generic_content'
import CustomerHelper from 'components/customer_helper/customer_helper'
import {
  websiteIdSelector,
  websiteAdwordsSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import DisconnectMerchantCenterButton from 'components/google/disconnect_merchant_center_button'
import {
  merchantCenterAccountIdSelector,
  merchantCenterAccountOauthUserSelector,
  nextUploadRemoveExistingPrimaryFeedsSelector
} from 'selectors/google/accounts'
import { enableDisableChannelSettings } from 'actions/channel_settings'
import { channelSelector } from 'selectors/channel'
import { sendAdwordsAccountLink } from 'actions/google/merchant_center'
import { appIdSelector } from 'selectors/app'

const MERCHANT_CENTER_SCOPE = 'https://www.googleapis.com/auth/content'

export class MerchantCenterConnection extends Component {
  static propTypes = {
    fetchWebsiteDetail: PropTypes.func.isRequired,
    listMerchantCenterAccounts: PropTypes.func.isRequired,
    merchantCenterAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    websiteId: PropTypes.number.isRequired,
    updateMerchantCenterAccount: PropTypes.func.isRequired,
    countryOfSaleFormValues: PropTypes.object.isRequired,
    languageFormValues: PropTypes.object.isRequired,
    merchantCenterFeedUploadFormValues: PropTypes.object.isRequired,
    existingMerchantCenterId: PropTypes.string.isRequired,
    nextUploadRemoveExistingPrimaryFeeds: PropTypes.bool,
    grantedScope: PropTypes.string.isRequired,
    googleAuthRequestToken: PropTypes.func.isRequired,
    sendAdwordsAccountLink: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    showFooter: PropTypes.bool,
    enableDisableChannelSettings: PropTypes.func.isRequired
  }

  static defaultProps = {
    googleAuthRequestToken,
    showFooter: true
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: true,
      busy: false,
      fetchingAccounts: false,
      firstLoadComplete: false
    }
  }

  componentDidMount() {
    const { websiteId, registerField, initialize, merchantCenterAccounts } =
      this.props
    registerField(REDUX_FORM_NAME, 'merchantCenterAccountId', 'Field')
    initialize({
      merchantCenterAccountId: undefined
    })

    this.setState({ firstLoadComplete: !(merchantCenterAccounts.length === 0) })

    if (websiteId) {
      this.fetchWebsiteDetail()
    }
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props
    if (websiteId > 0 && websiteId !== prevProps.websiteId) {
      await this.fetchWebsiteDetail()
    }
  }

  async fetchWebsiteDetail() {
    const { websiteId, fetchWebsiteDetail } = this.props
    this.setState({ busy: true })
    try {
      await fetchWebsiteDetail(websiteId)
    } catch (error) {
      //Do nothing
    }

    this.setState({ busy: false })
  }

  async fetchMerchantCenterAccounts() {
    const { listMerchantCenterAccounts } = this.props
    this.setState({
      busy: true,
      fetchingAccounts: true
    })
    try {
      await listMerchantCenterAccounts()
    } catch (error) {
      toastr.error(error.responseJSON.error)
    }
    this.setState({
      busy: false,
      firstLoadComplete: true,
      fetchingAccounts: false
    })
  }

  async startLogin() {
    const { googleAuthRequestToken } = this.props
    this.setState({ busy: true })
    try {
      await googleAuthRequestToken(MERCHANT_CENTER_SCOPE)
      await this.fetchMerchantCenterAccounts()
    } catch (error) {
      // Ignore
    }
    this.setState({ busy: false })
  }

  async connectMerchantCenterAccount() {
    const {
      websiteId,
      updateMerchantCenterAccount,
      merchantCenterFeedUploadFormValues,
      countryOfSaleFormValues,
      languageFormValues
    } = this.props

    const { feedUploadMethod } = merchantCenterFeedUploadFormValues
    const { country } = countryOfSaleFormValues
    const { language } = languageFormValues
    this.setState({ busy: true })
    try {
      await updateMerchantCenterAccount(
        websiteId,
        feedUploadMethod === 'replace',
        country,
        language
      )
      await this.linkAdsAccountToMc()
    } catch (error) {
      //Do nothing
    }
  }

  async linkAdsAccountToMc() {
    const { websiteId, adwordsAccount, sendAdwordsAccountLink } = this.props
    const { adwordsAccountId } = adwordsAccount

    if (!!adwordsAccountId) {
      const response = await sendAdwordsAccountLink(websiteId)
      if (response.linked) {
        toastr.success(
          'Google Ads account & Merchant Center account linked successfully.'
        )
      } else {
        toastr.error(
          'An error occurred while linking your Google Ads account & Merchant Center. Please accept linking request from your Google Ads account.'
        )
      }
    }
  }

  handleChange(e) {
    const { change } = this.props
    if (e.length > 0) {
      change('merchantCenterAccountId', e[0])
    }
  }

  scopeGranted() {
    const { grantedScope } = this.props
    if (grantedScope.search(MERCHANT_CENTER_SCOPE) < 0) {
      return false
    }

    return true
  }

  renderSelect() {
    const { merchantCenterAccounts, formValues } = this.props
    const { merchantCenterAccountId } = formValues
    const { fetchingAccounts, firstLoadComplete } = this.state
    if (fetchingAccounts) {
      return (
        <IndeterminateProgressIndicator message='Loading your accounts (may take a few minutes).' />
      )
    }

    if (merchantCenterAccounts.length === 0 && !firstLoadComplete) {
      this.fetchMerchantCenterAccounts()
      return null
    }

    var options = merchantCenterAccounts.map((a) => ({
      text: `${a.name} (${a.id})`,
      value: `${a.id}`
    }))

    var selectedOption =
      options.find((o) => o.value === merchantCenterAccountId) || {}

    const disabled = options.length === 0 ? 'disabled' : ''
    return (
      <div>
        {merchantCenterAccounts.length === 0 ? (
          'There is no Merchant Center available under current google account. Please use another Google account'
        ) : (
          <>
            <MDBSelect
              className={disabled + 'merchant-center-selector'}
              options={options}
              labelClass='merchantCenterSelectField'
              selected={selectedOption.text || 'Select Merchant Center'}
              getValue={this.handleChange.bind(this)}
            />
            {merchantCenterAccountId && (
              <MerchantCenterFeedUploadForm
                merchantCenterId={merchantCenterAccountId}
              />
            )}
          </>
        )}
      </div>
    )
  }

  conditionallyRenderNext() {
    return this.scopeGranted() ? (
      this.renderSelect()
    ) : (
      <MDBBtn onClick={this.startLogin.bind(this)}>
        Authorize Merchant Center access
      </MDBBtn>
    )
  }

  renderSignedInAs() {
    const { email } = this.props
    if (!email) {
      return null
    }

    var signedInAsText = 'Using the wrong Google account?'

    return (
      <p>
        Signed in as {email}. {signedInAsText}{' '}
        <MDBBtn className='btn-href-style' onClick={this.startLogin.bind(this)}>
          Change
        </MDBBtn>
      </p>
    )
  }

  async afterConnection() {
    const {
      fetchWebsiteDetail,
      fetchWebsiteMerchantCenterStatus,
      websiteId,
      channel,
      enableDisableChannelSettings
    } = this.props
    await enableDisableChannelSettings(websiteId, channel, true)
    await fetchWebsiteDetail(websiteId)
    await fetchWebsiteMerchantCenterStatus(websiteId)
    this.setState({ busy: false })
    this.props.history.goBack()
  }

  renderUploadFeedButton() {
    const { busy } = this.state
    var uploadFeedButtonOnClick
    var uploadFeedButtonText
    var uploadingFeedButtonText

    uploadFeedButtonOnClick = async () => {
      await this.connectMerchantCenterAccount()
      await this.afterConnection()
    }

    uploadFeedButtonText = 'Connect Merchant Center and upload feed'
    uploadingFeedButtonText = 'Connecting Merchant Center and uploading feed'

    const buttonText = busy ? (
      <div>
        <MDBIcon spin fixed icon='spinner' />
        {uploadingFeedButtonText}
      </div>
    ) : (
      uploadFeedButtonText
    )

    return (
      <MDBBtn disabled={busy} onClick={uploadFeedButtonOnClick}>
        {buttonText}
      </MDBBtn>
    )
  }

  renderEditable() {
    const { email, languageFormValues } = this.props
    const { language } = languageFormValues
    return (
      <div className='merchant-center-form'>
        {email.length === 0 && (
          <p>To connect an existing Merchant Center, sign in with Google.</p>
        )}
        <div className={'connect-or-create'}>{this.renderSignedInAs()}</div>
        {this.conditionallyRenderNext()}
        {language && this.renderUploadFeedButton()}
      </div>
    )
  }

  render() {
    const {
      initialized,
      existingMerchantCenterId,
      existingMerchantCenterOauthUser,
      nextUploadRemoveExistingPrimaryFeeds,
      languageFormValues,
      showFooter
    } = this.props
    const { language } = languageFormValues
    if (!initialized) {
      return <IndeterminateProgressIndicator />
    }

    const removeExistingPrimaryFeed =
      nextUploadRemoveExistingPrimaryFeeds === null ||
      nextUploadRemoveExistingPrimaryFeeds === undefined

    return (
      <MDBCard className='product-measures-diagnostics'>
        <MDBCardBody>
          <MDBCardTitle>
            Merchant Center Connection
            {!removeExistingPrimaryFeed && (
              <CardNavButton
                label='ACCESS'
                destination='https://www.google.com/retail/solutions/merchant-center/'
                float='right'
              />
            )}
          </MDBCardTitle>
          {removeExistingPrimaryFeed ? (
            <GenericContent
              component={
                !existingMerchantCenterId ? (
                  this.renderEditable()
                ) : (
                  <div className='merchant-center-form'>
                    <MerchantCenterFeedUploadForm
                      merchantCenterId={existingMerchantCenterId}
                    />
                    {language && this.renderUploadFeedButton()}
                  </div>
                )
              }
            />
          ) : (
            <div className='content'>
              <MDBCardText tag='span'>
                <ul>
                  <li>Status: Connected</li>
                  <li>Merchant Center ID: {existingMerchantCenterId}</li>
                  <li>Authorized by: {existingMerchantCenterOauthUser}</li>
                </ul>
              </MDBCardText>
              <MDBCardFooter>
                <DisconnectMerchantCenterButton />
              </MDBCardFooter>
            </div>
          )}
        </MDBCardBody>
        {removeExistingPrimaryFeed && showFooter && (
          <MDBCardFooter>
            <CustomerHelper
              helperName='Not sure what to do?'
              articleId='google-merchant-center-connection'
              bookAMeetingType={'feedops'}
            />
          </MDBCardFooter>
        )}
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    email: state.google.user.email,
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    countryOfSaleFormValues: getFormValues(COUNTRY_OF_SALE_FORM)(state) || {},
    languageFormValues: getFormValues(LANGUAGE_FORM)(state) || {},
    merchantCenterFeedUploadFormValues:
      getFormValues(MERCHANT_CENTER_FEED_UPLOAD_FORM)(state) || {},
    websiteId,
    merchantCenterAccounts: state.google.merchantCenter.accounts || [],
    existingMerchantCenterId: merchantCenterAccountIdSelector(state, props),
    existingMerchantCenterOauthUser: merchantCenterAccountOauthUserSelector(
      state,
      props
    ),
    nextUploadRemoveExistingPrimaryFeeds:
      nextUploadRemoveExistingPrimaryFeedsSelector(state, props),
    grantedScope: state.google.user.scope,
    feedId: websiteFeedIdByChannelSelector(state, props),
    channel: channelSelector(state, props),
    adwordsAccount: websiteAdwordsSelector(state, props),
    appId: appIdSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listMerchantCenterAccounts,
      fetchWebsiteDetail,
      fetchWebsiteMerchantCenterStatus,
      registerField,
      updateMerchantCenterAccount,
      sendAdwordsAccountLink,
      enableDisableChannelSettings
    },
    dispatch
  )

export const MerchantCenterConnectionWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantCenterConnection)
export const REDUX_FORM_NAME = 'MerchantCenterConnection'
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(MerchantCenterConnectionWithRedux)
  )
)
