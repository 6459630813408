import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import camelCase from 'camelcase'
import { MDBNavbarNav } from 'mdbreact'
import { isMobileOS } from 'util/operating_systems'
import {
  PLAYBOOK,
  PRODUCT_LEVEL_OPTIMIZATIONS,
  EDIT_PRODUCT_TYPES,
  EDIT_PRODUCT_ATTRIBUTES
} from 'components/feed_ops/menu_items'
import PlaybookDismissSwitch from 'components/feed_ops/website/playbook/dismiss_switch'
import DateRangePickerDropdown from 'components/platform/website/date_range_picker_dropdown'
import FeedLink from 'components/feed_ops/common/feed_link'
import ProductLevelFeedSettingsButton from 'components/feed_ops/website/product_optimisations/feed_settings_button'
import ProductLevelOptimiserBtn from 'components/feed/optimisation/product_level/form_btn'
import FormButton from 'components/form_button'
import { REDUX_FORM_NAME as PRODUCT_TYPE_HIERARCHY_FORM } from 'components/feed_ops/website/category_optimizations/card'
import { REDUX_FORM_NAME as PRODUCT_ATTRIBUTE_HIERARCHY_FORM } from 'components/feed/optimisation/product_attributes/attribute_list'

export class RightTertiaryHeader extends Component {
  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    isMobileOS: PropTypes.bool.isRequired
  }

  static defaultProps = {
    isMobileOS
  }

  conditionallyRenderPlaybookDismissSwitch() {
    const { menuItem } = this.props
    return menuItem.title === PLAYBOOK ? <PlaybookDismissSwitch /> : null
  }

  conditionallyRenderDateRangePickerDropdown() {
    const { isMobileOS, menuItem } = this.props
    return !isMobileOS && menuItem.tertiaryHeader.dateRangePicker ? (
      <DateRangePickerDropdown keyName={camelCase(menuItem.path)} />
    ) : null
  }

  conditionallyRenderAccessFeedButton() {
    const { menuItem } = this.props
    return menuItem.tertiaryHeader.accessFeed && <FeedLink />
  }

  conditionallyRenderProductLevelButtons() {
    const { menuItem } = this.props
    return menuItem.title === PRODUCT_LEVEL_OPTIMIZATIONS ? (
      <>
        <ProductLevelFeedSettingsButton />
        <ProductLevelOptimiserBtn />
      </>
    ) : null
  }

  renderTitleButtons(reduxFormName) {
    return (
      <>
        <FormButton isCancel={true} reduxFormName={reduxFormName} />
        <FormButton isCancel={false} reduxFormName={reduxFormName} />
      </>
    )
  }

  conditionallyRenderProductTypesButtons() {
    const { menuItem } = this.props
    return menuItem.title.includes(EDIT_PRODUCT_TYPES)
      ? this.renderTitleButtons(PRODUCT_TYPE_HIERARCHY_FORM)
      : null
  }

  conditionallyRenderProductAttributesButtons() {
    const { menuItem } = this.props
    return menuItem.title.includes(EDIT_PRODUCT_ATTRIBUTES)
      ? this.renderTitleButtons(PRODUCT_ATTRIBUTE_HIERARCHY_FORM)
      : null
  }

  render() {
    return (
      <MDBNavbarNav right>
        {this.conditionallyRenderPlaybookDismissSwitch()}
        {this.conditionallyRenderDateRangePickerDropdown()}
        {this.conditionallyRenderAccessFeedButton()}
        {this.conditionallyRenderProductLevelButtons()}
        {this.conditionallyRenderProductTypesButtons()}
        {this.conditionallyRenderProductAttributesButtons()}
      </MDBNavbarNav>
    )
  }
}

export default withRouter(RightTertiaryHeader)
