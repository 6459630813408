import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setQueryString } from './header_util'
import HeaderSelectorDropdown from './header_selector_dropdown'
import {
  websiteInventoriesSelector,
  websiteInventoryIdSelector
} from 'selectors/websites'

export class InventorySelectorDropdown extends Component {
  static propTypes = {
    float: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    inventories: PropTypes.arrayOf(PropTypes.object).isRequired,
    inventoryId: PropTypes.number.isRequired
  }

  handleSelectInventoryChange(inventoryId) {
    const { history } = this.props
    history.push(setQueryString(history, { inventoryId }))
  }

  render() {
    const { float, disabled, inventories, inventoryId } = this.props

    return (
      <HeaderSelectorDropdown
        float={float}
        disabled={disabled}
        validOptions={inventories}
        selectedOptionId={inventoryId}
        optionChange={this.handleSelectInventoryChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    inventories: websiteInventoriesSelector(state, props),
    inventoryId: websiteInventoryIdSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventorySelectorDropdown)
)
