import { CHANNEL_ALL, CHANNEL_GOOGLE } from 'util/supported_channels'
import QueryString from 'query-string'
import { createSelector } from 'reselect'
export const noDefaultChannelSelector = (_state, props) => {
  if (props.location) {
    return QueryString.parse(props.location.search).channel
  } else {
    throw new Error('Missing location prop')
  }
}

export const channelSelector = createSelector(
  [noDefaultChannelSelector],
  (channel) => channel || CHANNEL_ALL
)

export const defaultGoogleChannelSelector = (_state, props) => {
  return noDefaultChannelSelector(_state, props) || CHANNEL_GOOGLE
}

export const channelPresentSelector = (_state, props) => {
  const channel = noDefaultChannelSelector(_state, props)
  return (channel && channel.length > 0) || false
}
