import { createSelector } from 'reselect'
import {
  websiteGoalTargetSelector,
  websiteCurrencySelector,
  websitePerformanceWithGoalAndBudgetSelector
} from './website'
import {
  budgetCategoryPerformanceWithGoalAndBudgetSelector,
  budgetCategoryGoalTargetSelector,
  budgetCategoryCurrencySelector,
  budgetCategoryGoalActualSelector,
  budgetCategoryCostActualSelector
} from './budget_category'
import { budgetIssueFormatter } from 'selectors/budget_error'
import { performanceDataSelector } from 'selectors/performance'
import { goalTypeSelector } from 'selectors/advertising_goal_types'
import {
  calculateGoalTargetByGoalType,
  getDataUnit,
  selectDataUnit,
  roasActualSelector,
  cpcActualSelector,
  cpaActualSelector
} from './helper'

export const costAndGoalSelector = (state) => state.costAndGoal

export const costTargetSelector = (accountType, id) =>
  createSelector(
    [performanceWithGoalAndBudgetSelector(accountType, id)],
    (performance) =>
      performance.reduce((total, day) => (total += day.budget), 0)
  )

export const costActualSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryCostActualSelector(id)
    default:
      return createSelector([performanceDataSelector(accountType, id)], (data) =>
      data.reduce((total, day) => (total += day.cost), 0)
    )
  }
}

const calculateGoalTargetSelector = (accountType, id) =>
  createSelector(
    [
      goalTypeSelector(accountType, id),
      convValueTotalSelector(accountType, id),
      conversionsTotalSelector(accountType, id),
      clicksTotalSelector(accountType, id),
      targetSpendToHitDailyGoalTotalSelector(accountType, id)
    ],
    (goalType, convValue, conversions, clicks, targetSpendToHitDailyGoal) =>
      calculateGoalTargetByGoalType(
        goalType,
        targetSpendToHitDailyGoal,
        selectDataUnit(goalType, convValue, conversions, clicks)
      )
  )

export const goalTargetSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryGoalTargetSelector(id)
    case 'websites':
      return websiteGoalTargetSelector(id)
    case 'accounts':
      return calculateGoalTargetSelector(accountType, id)
    case 'master_accounts':
      return calculateGoalTargetSelector(accountType, id)
    default:
      return () => 0
  }
}

const calculateGoalActualSelector = (accountType, id) =>
  createSelector(
    [
      goalTypeSelector(accountType, id),
      costActualSelector(accountType, id),
      convValueTotalSelector(accountType, id),
      clicksTotalSelector(accountType, id),
      conversionsTotalSelector(accountType, id)
    ],
    (goalType, cost, convValue, clicks, conversions) => {
      switch (goalType) {
        case 'roas':
          return roasActualSelector(cost, convValue)
        case 'cpc':
          return cpcActualSelector(cost, clicks)
        case 'cpa':
          return cpaActualSelector(cost, conversions)
        default:
          return 0
      }
    }
  )

export const goalActualSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryGoalActualSelector(id)
    case 'websites':
      return calculateGoalActualSelector(accountType, id)
    case 'accounts':
      return calculateGoalActualSelector(accountType, id)
    case 'master_accounts':
      return calculateGoalActualSelector(accountType, id)
    default:
      return () => 0
  }
}

export const getGoalTypeTitle = (goalType, displayConversionValueOnCost) => {
  switch (goalType) {
    case 'roas':
      return displayConversionValueOnCost ? 'Conv. value / cost' : 'ROAS'
    case 'cpc':
      return 'CPC'
    case 'cpa':
      return 'CPA'
    case 'max_conversion_value':
      return 'Conv. Value'
    case 'mixed' :
      return 'Mixed'
    default:
      return ''
  }
}

const calculatePerformanceWithGoalAndBudgetSelector = (accountType, id) =>
  createSelector(
    [
      performanceDataSelector(accountType, id),
      goalTypeSelector(accountType, id)
    ],
    (data, goalType) =>
      data.map((day) => ({
        ...day,
        goal: calculateGoalTargetByGoalType(
          goalType,
          day.targetSpendToHitDailyGoal,
          getDataUnit(goalType, day)
        ),
        budget: day.targetCost
      }))
  )

export const performanceWithGoalAndBudgetSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryPerformanceWithGoalAndBudgetSelector(id)
    case 'websites':
      return websitePerformanceWithGoalAndBudgetSelector(id)
    case 'accounts':
      return calculatePerformanceWithGoalAndBudgetSelector(accountType, id)
    case 'master_accounts':
      return calculatePerformanceWithGoalAndBudgetSelector(accountType, id)
    default:
      return () => ({})
  }
}

export const currencySelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return budgetCategoryCurrencySelector(id)
    case 'websites':
      return websiteCurrencySelector(id)
    case 'accounts':
      return budgetIssueFormatter(accountType, id)
    case 'master_accounts':
      return budgetIssueFormatter(accountType, id)
    default:
      return () => '$'
  }
}

export const convValueTotalSelector = (accountType, id) =>
  createSelector([performanceDataSelector(accountType, id)], (data) =>
    data.reduce((total, day) => (total += day.totalConvValue), 0)
  )

export const clicksTotalSelector = (accountType, id) =>
  createSelector([performanceDataSelector(accountType, id)], (data) =>
    data.reduce((total, day) => (total += day.clicks), 0)
  )

export const conversionsTotalSelector = (accountType, id) =>
  createSelector([performanceDataSelector(accountType, id)], (data) =>
    data.reduce((total, day) => (total += day.conversions), 0)
  )

export const targetSpendToHitDailyGoalTotalSelector = (accountType, id) =>
  createSelector([performanceDataSelector(accountType, id)], (data) =>
    data.reduce((total, day) => (total += day.targetSpendToHitDailyGoal), 0)
  )
