import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MDBIcon } from 'mdbreact'
import { getHealthColour } from './playbook_util'
import { HEALTH_OK } from 'selectors/playbook/playbook_progress'

export default class ImpactOrOpportunityIndicator extends Component {
  static propTypes = {
    health: PropTypes.string.isRequired,
    opportunity: PropTypes.number
  }

  render() {
    const { health, opportunity } = this.props

    if (health !== HEALTH_OK) {
      return (
        <span className='impact'>
          <MDBIcon
            icon='exclamation-circle'
            style={{ color: getHealthColour(health) }}
          />
        </span>
      )
    }

    if (opportunity > 0.0) {
      return <span className='opportunity'>+{opportunity.toFixed(1)}%</span>
    }

    return null
  }
}
