import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getFormValues, change } from 'redux-form'
import { REDUX_FORM_NAME } from '../../form'
import { MDBIcon } from 'mdbreact'
import DeleteModal from 'components/feed_ops/common/delete_modal'
import { updateDraftRuleStatus, removeDraftRule } from '../../overview_util'

export class DeleteRule extends Component {
  static propTypes = {
    rule: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  async handleDeleteRuleClick() {
    const { rule, formValues, change } = this.props
    var rules = [...formValues.rules]

    const updatedRules = rule.id
      ? updateDraftRuleStatus(rules, rule, 'Deleted')
      : removeDraftRule(rules, rule)

    change(REDUX_FORM_NAME, 'rules', updatedRules)
  }

  renderModal() {
    const { isOpen } = this.state

    return (
      <DeleteModal
        deleteModalOpen={isOpen}
        modalTitle='Delete Rule'
        modalMessage='delete the rule'
        handleDeleteModalClose={() => this.setState({ isOpen: false })}
        handleDeleteClick={this.handleDeleteRuleClick.bind(this)}
      />
    )
  }

  render() {
    return (
      <div>
        <MDBIcon
          far
          icon='trash-alt'
          onClick={() => this.setState({ isOpen: true })}
        />
        {this.renderModal()}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteRule)
)
