import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Container, Row, Col, Input } from 'reactstrap'
import ChannelMultiSelect from '../common/channel_multi_select'
import PreviewTabsContainer from './preview_tabs_container'

import './modal_body_container.scss'

export class ModalBodyContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    searchText: PropTypes.string.isRequired,
    vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChannelChanged: PropTypes.func.isRequired,
    onSearchTextChanged: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { channel: '', searchText: '', timer: null }
  }

  componentDidMount() {
    const { channel, searchText } = this.props
    this.setState({ channel, searchText })
  }

  componentDidUpdate(prevProps) {
    const { channel, searchText } = this.props
    if (channel !== prevProps.channel) {
      this.setState({ channel })
    }
    if (searchText !== prevProps.searchText) {
      this.setState({ searchText })
    }
  }

  handleChannelSelectorChange(channel) {
    const { onChannelChanged } = this.props
    onChannelChanged(channel)
  }

  renderChannelSelector() {
    const { vendors } = this.props
    const { channel } = this.state

    return channel ? (
      <ChannelMultiSelect
        channels={vendors.map((v) => v.channel)}
        defaultChannels={[channel]}
        allowMultipleSelection={false}
        onChange={this.handleChannelSelectorChange.bind(this)}
      />
    ) : null
  }

  handleInputOnChange(event) {
    this.setState({ searchText: event.target.value })

    const { timer } = this.state
    window.clearTimeout(timer)
    const newTimer = window.setTimeout(() => {
      const { searchText } = this.state
      this.props.onSearchTextChanged(searchText)
    }, 1000)
    this.setState({ timer: newTimer })
  }

  handleOnBlur() {
    const { searchText } = this.state
    this.props.onSearchTextChanged(searchText)
  }

  renderChannelSelectorAndSearchBox() {
    const { searchText } = this.state

    return (
      <Row>
        <Col>{this.renderChannelSelector()}</Col>
        <Col>
          <Input
            type='search'
            placeholder='Search by Item ID or Title'
            className='search-text'
            value={searchText || ''}
            onChange={(event) => this.handleInputOnChange(event)}
            onBlur={this.handleOnBlur.bind(this)}
          />
        </Col>
      </Row>
    )
  }

  renderPreviewInfoRow() {
    return (
      <Row>
        <Col className='preview-info-col'>
          This screen shows a sample of the changes that will be made by your
          rules. You can check a specific product by searching by Item ID or
          Title. You can also export all of the changes to a Google Sheet for
          preview.
        </Col>
      </Row>
    )
  }

  renderPreviewTabsContainerRow() {
    const { isFetching } = this.props

    return (
      <Row>
        <Col>
          <PreviewTabsContainer isFetching={isFetching} />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Container className='preview-modal-body-container'>
        {this.renderChannelSelectorAndSearchBox()}
        {this.renderPreviewInfoRow()}
        {this.renderPreviewTabsContainerRow()}
      </Container>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalBodyContainer)
)
