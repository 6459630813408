import React, { Component } from 'react'
import { MDBIcon } from 'mdbreact'

export default class KeyedValueWithExplicitEdit extends Component {
  constructor(props) {
    super(props)
    // Create a ref to store the input DOM element
    this.valueInput = React.createRef()
    this.labelValueInput = React.createRef()
    this.state = {}
  }

  componentDidMount() {
    this.setState({
      label: this.props.keyValue[0],
      tagValue: this.props.keyValue[1]
    })
  }

  componentDidUpdate(prevProps) {
    const { keyValue } = this.props
    if (prevProps.keyValue !== keyValue) {
      this.setState({ label: keyValue[0], tagValue: keyValue[1] })
    }
  }

  deleteInput() {
    const { setting, index } = this.props
    this.props.changeFormValues(setting, index, null, null, true)
  }

  editValueInput() {
    // Explicitly focus the input using the raw DOM API
    // Accessing 'current" to get the DOM node
    this.valueInput.current.focus()
  }

  editLabelValueInput() {
    // Explicitly focus the input using the raw DOM API
    // Accessing 'current" to get the DOM node
    this.labelValueInput.current.focus()
  }

  renderIcon(icon, func) {
    if (this.props.labelEditable) {
      return (
        <React.Fragment>
          {' '}
          <MDBIcon icon={icon} onClick={func.bind(this)} />{' '}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {' '}
        <MDBIcon icon='minus-circle' className='invisible' />{' '}
      </React.Fragment>
    )
  }

  labelTagOnChange(event) {
    this.setState({ label: event.target.value })
  }

  valueTagOnChange(event) {
    this.setState({ tagValue: event.target.value })
  }

  valueOnBlur() {
    const { label, tagValue } = this.state
    this.props.changeFormValues(
      this.props.setting,
      this.props.index,
      label,
      tagValue,
      false
    )
  }

  render() {
    const { label, tagValue } = this.state
    const { duplicates } = this.props
    return (
      <React.Fragment>
        <div>
          {this.renderIcon('minus-circle', this.deleteInput)}
          <input
            disabled={!this.props.labelEditable}
            value={label || ''}
            ref={this.labelValueInput}
            onChange={this.labelTagOnChange.bind(this)}
            onBlur={this.valueOnBlur.bind(this)}
            className={duplicates.includes(label) || !label ? 'error' : ''}
          />
          {this.renderIcon('pencil', this.editLabelValueInput)}
          <input
            ref={this.valueInput}
            value={tagValue || ''}
            onChange={this.valueTagOnChange.bind(this)}
            onBlur={this.valueOnBlur.bind(this)}
            className={!tagValue ? 'error' : ''}
          />{' '}
          <MDBIcon icon='pencil' onClick={this.editValueInput.bind(this)} />
        </div>
      </React.Fragment>
    )
  }
}
