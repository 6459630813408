export const openArticle = (articleId) => {
  let newTab = window.open()
  newTab.location.href = `https://kb.feedops.com/knowledge/${
    articleId ? articleId : 'feedops'
  }`
}

export const openBookAMeeting = () => {
  let newTab = window.open()
  newTab.location.href = `https://www.feedops.com/book-a-meeting`
}

export const openHubspot = () => {
  window.HubSpotConversations.widget.open()
}
