import {
  FETCH_WEBSITE_DETAILS_SUCCESS,
  UPDATE_WEBSITE_DETAILS_SUCCESS
} from 'actions/website_details'

const initialState = {
  website: { url: '' },
  characterLimits: {},
  usps: []
}

const websiteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEBSITE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.websiteDetails
      }
    case UPDATE_WEBSITE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.websiteDetails
      }
    default:
      return state
  }
}

export default websiteDetailsReducer
