import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { MDBModal, MDBModalBody, MDBIcon } from 'mdbreact'
import { detect } from 'detect-browser'
import qs from 'qs'
import { createOrUpdateSettings } from 'actions/settings'
import { fetchUserMasterAccounts } from 'actions/users'
import { openArticle } from 'components/util/help'
import { googleAPIsLoad } from 'util/google/google_apis'
import { register as registerErrorHandlers } from 'util/global_error_handlers'
import { isMobileOS, detectMobileOS } from 'util/operating_systems'

export class LoadingMessage extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    isMobileOS: PropTypes.bool.isRequired,
    detectMobileOS: PropTypes.func.isRequired
  }

  static defaultProps = {
    detectMobileOS,
    isMobileOS
  }

  renderLoading() {
    return <MDBIcon size='2x' spin fixed icon='spinner' />
  }

  renderError() {
    const { error } = this.props
    return (
      <div>
        <MDBIcon icon='exclamation-triangle' className='error' /> {error}
      </div>
    )
  }

  render() {
    const { error, loading, isMobileOS, messages } = this.props
    var showDetail = process.env.SHOW_APP_LOAD_DETAILS === 'true'
    return (
      <div className={`splash-screen${isMobileOS ? '-mobile' : ''}`}>
        <MDBModal isOpen={loading || error ? true : false} centered>
          {loading && this.renderLoading()}
          <MDBModalBody>
            <div className='message'>
              {error ? this.renderError() : 'Checking browser compatibility'}
              {showDetail && (
                <ul>
                  {messages.map((m, i) => (
                    <li key={i}>{m}</li>
                  ))}
                </ul>
              )}
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}

export class SplashScreen extends Component {
  static propTypes = {
    WrappedComponent: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired,
    fetchUserMasterAccounts: PropTypes.func.isRequired,
    googleAPIsLoad: PropTypes.func.isRequired,
    openArticle: PropTypes.func.isRequired,
    registerErrorHandlers: PropTypes.func.isRequired,
    detect: PropTypes.func.isRequired,
    detectMobileOS: PropTypes.func.isRequired,
    createOrUpdateSettings: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired
  }

  static defaultProps = {
    googleAPIsLoad,
    openArticle,
    registerErrorHandlers,
    detect,
    detectMobileOS,
    document
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: '',
      messages: []
    }
  }

  async componentDidMount() {
    const { detectMobileOS } = this.props
    try {
      await detectMobileOS()
      await this.registerErrorHandlers()
      await this.loadSettings()
      await this.checkBrowserSupport()
      await this.loadGoogleApis()
      await this.loadUserSession()
      this.setState({ loading: false })
    } catch (ex) {
      this.failAction()
      this.setState({ error: ex.message, loading: false })
    }
  }

  async registerErrorHandlers() {
    const { registerErrorHandlers } = this.props
    await registerErrorHandlers()
  }

  async loadSettings() {
    const { createOrUpdateSettings, location } = this.props
    var searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    await createOrUpdateSettings(searchParams)
  }

  async checkBrowserSupport() {
    const { detect } = this.props
    this.startAction('Checking browser support')
    const browser = await detect()
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      case 'safari':
      case 'edge-chromium':
      case 'ios':
      case 'crios':
      case 'chromium-webview':
      case 'ios-webview':
        break
      default:
        const { openArticle } = this.props
        openArticle('supported-browsers')
        throw new Error(`Your browser (${browser.name}) is unsupported.`)
    }
    this.completeAction()
  }

  requestThirdPartyCookies() {
    const { openArticle } = this.props
    openArticle('third-party-cookies')
    throw new Error(
      'This app requires third party cookies to function. Please enable third party cookies in your browser to continue.'
    )
  }

  async loadGoogleApis() {
    this.startAction('Loading Google APIs')
    const { googleAPIsLoad } = this.props
    try {
      await googleAPIsLoad()
    } catch (ex) {
      if (ex.error === 'idpiframe_initialization_failed') {
        this.requestThirdPartyCookies()
      } else {
        throw ex
      }
    }
    this.completeAction()
  }

  async loadUserSession() {
    this.startAction('Loading user session')

    const { fetchUserMasterAccounts } = this.props
    await fetchUserMasterAccounts()

    this.completeAction()
  }

  startAction(message) {
    const { messages } = this.state
    const newMessages = [...messages, `${message}...`]
    this.setState({ messages: newMessages })
  }

  completeAction(status = 'OK') {
    const { messages } = this.state
    var newMessages = [...messages]
    var lastMessage = newMessages.pop()
    this.setState({
      messages: [...newMessages, `${lastMessage}. ${status}.`]
    })
  }

  failAction() {
    this.completeAction('FAILED')
  }

  render() {
    const { WrappedComponent } = this.props
    const { loading, messages, error } = this.state

    if (loading || error) {
      return (
        <LoadingMessage loading={loading} messages={messages} error={error} />
      )
    }
    return <WrappedComponent {...this.props} />
  }
}

const withSplashScreen = (WrappedComponent, appId) => {
  const mapStateToProps = (state) => ({
    appId,
    WrappedComponent
  })

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      { fetchUserMasterAccounts, createOrUpdateSettings },
      dispatch
    )

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(SplashScreen))
}

export default withSplashScreen
