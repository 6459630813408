import camelCaseKeys from 'camelcase-keys'

export const SELECT_ADWORDS_ACCOUNT_REQUEST = 'SELECT_ADWORDS_ACCOUNT_REQUEST'
export const SELECT_ADWORDS_ACCOUNT_RESPONSE = 'SELECT_ADWORDS_ACCOUNT_RESPONSE'

const selectAdwordsAccountRequest = (
  websiteId,
  { adwordsAccountId, createAccount }
) => ({
  type: SELECT_ADWORDS_ACCOUNT_REQUEST,
  websiteId,
  adwordsAccountId,
  createAccount
})

const selectAdwordsAccountResponse = (
  websiteId,
  { adwordsAccountId, createAccount },
  response
) => ({
  type: SELECT_ADWORDS_ACCOUNT_RESPONSE,
  websiteId,
  adwordsAccountId,
  createAccount,
  response
})

export const selectAdwordsAccount = (
  websiteId,
  { adwordsAccountId, createAccount }
) => async dispatch => {
  dispatch(
    selectAdwordsAccountRequest(websiteId, {
      adwordsAccountId,
      createAccount
    })
  )
  var response = await $.ajax({
    method: 'POST',
    url: '/ad_champion/google/adwords/accounts/select',
    data: {
      website_id: websiteId,
      adwords_account_id: adwordsAccountId,
      create_account: createAccount
    }
  })
  dispatch(
    selectAdwordsAccountResponse(
      websiteId,
      { adwordsAccountId, createAccount },
      camelCaseKeys(response, { deep: true })
    )
  )
}
