import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import FeedbackModal from 'components/feed_ops/common/feedback_modal'
import BackBtn from 'components/feed_ops/common/back_button'
import OptimisationTypeCard from './container/optimisation_type_card'
import ProductLevelOptimiser from 'components/feed_ops/website/product_optimisations/optimiser'

import './container.scss'

export class AIOptimisationContainer extends Component {
  renderOptimisationCardsPanel() {
    return (
      <div className='header-cards'>
        <OptimisationTypeCard />
      </div>
    )
  }

  render() {
    return (
      <div className='ai-optimisation-container'>
        <FeedbackModal showSkipBtn={true} />
        <div>
          <BackBtn label='Go to playbook' float='left' />
        </div>
        {this.renderOptimisationCardsPanel()}
        <ProductLevelOptimiser />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AIOptimisationContainer)
)
