import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { diffWords } from 'diff'
import ReactDOMServer from 'react-dom/server'
import { MDBIcon } from 'mdbreact'
import { Popover } from '@material-ui/core'
import { DESCRIPTION_COLUMN_HEADER } from '../../common/optimiser_util'
import { OptimizeAiGenerating } from '../../../../../icons/icons'

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'left'
}
const transformOrigin = {
  vertical: 'center',
  horizontal: 'right'
}

export default class ColumnSuggestionPopover extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    columnName: PropTypes.string.isRequired,
    originalValue: PropTypes.string,
    suggestionValue: PropTypes.string,
    suggestionSource: PropTypes.string
  }

  constructor() {
    super()
    this.state = {
      top: 0,
      left: 0,
      popoverOpen: false
    }
  }

  handleIconClick(event) {
    this.setState({
      top: event.clientY,
      left: event.clientX,
      popoverOpen: true
    })
  }

  handleClosePopover() {
    this.setState({ popoverOpen: false })
  }

  renderContent(content) {
    return (
      <div
        className='formatted-cell-value'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )
  }

  changeInSuggestionValue() {
    const { originalValue, suggestionValue } = this.props
    const differences = diffWords(originalValue || '', suggestionValue || '')

    return ReactDOMServer.renderToString(
      <div>
        <span className='popover-title'>Suggestion</span>
        <br />
        {differences.map((part, index) => {
          if (part.removed) {
            return null
          }
          const className = part.added || part.value === ' ' ? 'changed' : ''
          return (
            <span key={index} className={className}>
              {part.value}
            </span>
          )
        })}
      </div>
    )
  }

  conditionallyRenderPopoverContent() {
    const { columnName, originalValue } = this.props

    return (
      <>
        <h6>{columnName}</h6>
        <div
          className={
            columnName === DESCRIPTION_COLUMN_HEADER ? 'vertical-container' : ''
          }
        >
          {this.renderContent(
            `<span class='popover-title'>Original</span><br />${originalValue}`
          )}
          <hr />
          {this.renderContent(this.changeInSuggestionValue())}
        </div>
      </>
    )
  }

  renderPopover() {
    const { top, left, popoverOpen } = this.state
    const { rowData, columnName } = this.props
    const id = `po-${rowData.raw.sku}-${columnName}`.replace(' ', '')

    return (
      <Popover
        id={id}
        key={id}
        anchorReference='anchorPosition'
        anchorPosition={{ top: top, left: left }}
        open={popoverOpen || false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className='suggestion-popover'
        onClose={this.handleClosePopover.bind(this)}
      >
        {this.conditionallyRenderPopoverContent()}
      </Popover>
    )
  }

  conditionallyRenderIcon() {
    const { rowData, columnName, suggestionSource } = this.props
    const id = `btn-${rowData.raw.sku}-${columnName}`.replace(' ', '')
    const isAiGeneratedSuggestion = suggestionSource === 'open_ai'

    return isAiGeneratedSuggestion ? (
      <OptimizeAiGenerating
        key={id}
        onClick={this.handleIconClick.bind(this)}
      />
    ) : (
      <MDBIcon key={id} icon='eye' onClick={this.handleIconClick.bind(this)} />
    )
  }

  render() {
    return (
      <div className='suggestion-icon-container'>
        {this.conditionallyRenderIcon()}
        {this.renderPopover()}
      </div>
    )
  }
}
