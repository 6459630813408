import {
  SELECT_SCENARIO,
  FETCH_SCENARIO_STATUS_REQUEST,
  FETCH_SCENARIO_STATUS_SUCCESS,
  FETCH_SCENARIO_STATUS_ERROR
} from 'actions/scenario';

const scenarioReducer = (state = {status: {}}, action) => {
  switch(action.type) {
    case SELECT_SCENARIO:
      return {
        ...state,
        id: action.id
      }
    case FETCH_SCENARIO_STATUS_REQUEST:
      return {
        ...state
      }
    case FETCH_SCENARIO_STATUS_SUCCESS:
      return {
        ...state,
        status: { ...action.status, statusFetched: Date() }
      }
    case FETCH_SCENARIO_STATUS_ERROR:
      return {
        ...state,
        status: { error: true }
      }
    default:
      return state;
  }
}

export default scenarioReducer;
