import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBSwitch } from 'mdbreact'
import { setDismissSwitch } from 'actions/playbook/playbook_progress'
import { showDismissPlaybookItemsSelector } from 'selectors/playbook/playbook_progress'

export class PlaybookDismissSwitch extends Component {
  static propTypes = {
    showDismissPlaybookItems: PropTypes.bool.isRequired,
    setDismissSwitch: PropTypes.func.isRequired
  }

  handleChange() {
    const { setDismissSwitch, showDismissPlaybookItems } = this.props
    setDismissSwitch(!showDismissPlaybookItems)
  }

  render() {
    const { showDismissPlaybookItems } = this.props

    return (
      <MDBSwitch
        className='playbook-dismiss-switch'
        labelLeft='Active'
        labelRight='Dismissed'
        checked={showDismissPlaybookItems}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

export const mapStateToProps = (state, props) => ({
  showDismissPlaybookItems: showDismissPlaybookItemsSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setDismissSwitch }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaybookDismissSwitch)
