import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import changeCase from 'change-case'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { getLogoByChannel } from 'util/supported_channels'

import './channel_multi_select.scss'

export class ChannelMultiSelect extends Component {
  static propTypes = {
    channels: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
    allowMultipleSelection: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    allowMultipleSelection: true
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  renderChannelText() {
    return <div className='channel-text-container'>{'Channel(s)'}</div>
  }

  renderDropdownToggle() {
    const { defaultChannels } = this.props
    const channel =
      defaultChannels.length > 0 ? defaultChannels[0] : 'Select Channel'
    const additionalCount =
      defaultChannels.length > 1 ? `+${defaultChannels.length - 1}` : ''
    const toggleText = `${changeCase.sentenceCase(channel)} ${additionalCount}`

    return (
      <DropdownToggle className='channel-dropdown-toggle' caret>
        {defaultChannels.length > 0 && (
          <div className='channel-logo-background' >
            <img src={getLogoByChannel(channel)} alt={channel} />
          </div>
        )}
        {toggleText}
      </DropdownToggle>
    )
  }

  handleDropdownItemClick(channel) {
    const { allowMultipleSelection, defaultChannels, onChange } = this.props

    if (allowMultipleSelection) {
      const newChannels = defaultChannels.includes(channel)
        ? defaultChannels.filter((c) => c !== channel)
        : [...defaultChannels, channel]
      onChange(newChannels)
    } else {
      onChange(channel)
      this.setState({ isOpen: false })
    }
  }

  renderDropdownItem(channel) {
    const { defaultChannels } = this.props
    const selectedChannel = defaultChannels.includes(channel)

    return (
      <DropdownItem
        key={channel}
        toggle={false}
        className={`channel-dropdown-item ${
          selectedChannel ? 'selected-menu-item' : ''
        }`}
        onClick={this.handleDropdownItemClick.bind(this, channel)}
      >
        <i
          className={`fa fa-check ${
            selectedChannel ? 'show-check' : 'hide-check'
          }`}
        />
        <div className='channel-logo-background' >
            <img src={getLogoByChannel(channel)} alt={channel} />
          </div>
        {changeCase.sentenceCase(channel)}
      </DropdownItem>
    )
  }

  renderDropdownMenu() {
    const { channels } = this.props

    return (
      <DropdownMenu>
        {channels.map((channel) => this.renderDropdownItem(channel))}
      </DropdownMenu>
    )
  }

  renderChannelDropdown() {
    const { isOpen } = this.state

    return (
      <Dropdown
        isOpen={isOpen}
        toggle={() => {
          this.setState({ isOpen: !isOpen })
        }}
      >
        {this.renderDropdownToggle()}
        {this.renderDropdownMenu()}
      </Dropdown>
    )
  }

  render() {
    return (
      <div className='channel-multi-select'>
        <span className='channel-multi-select-background' >
        {this.renderChannelText()}
        {this.renderChannelDropdown()}
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelMultiSelect)
