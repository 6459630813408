import {
  HEALTH_ERROR,
  HEALTH_CRITICAL,
  HEALTH_WARNING
} from 'selectors/playbook/playbook_progress'

export const SEVERITY_CRITICAL = 'Critical'
export const SEVERITY_HIGH = 'High'
export const SEVERITY_MEDIUM = 'Medium'

export const COLOR_GREEN = '#00C300'
export const COLOR_YELLOW = '#FFF511'
export const COLOR_RED = '#FF5F6D'

export const getHealthColour = (health) => {
  switch (health) {
    case HEALTH_CRITICAL:
    case HEALTH_ERROR:
      return COLOR_RED
    case HEALTH_WARNING:
      return COLOR_YELLOW
    default:
      return COLOR_GREEN
  }
}

export const getOptScoreColour = (optScore) => {
  const score = parseFloat(optScore)
  if (score > 65.0) {
    return COLOR_GREEN
  } else if (score > 50.0) {
    return COLOR_YELLOW
  } else {
    return COLOR_RED
  }
}

export const getSeverityColour = (severity) => {
  switch (severity) {
    case SEVERITY_CRITICAL:
    case SEVERITY_HIGH:
      return COLOR_RED
    case SEVERITY_MEDIUM:
      return COLOR_YELLOW
    default:
      return COLOR_GREEN
  }
}
