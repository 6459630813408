import camelCaseKeys from 'camelcase-keys'
import snakeCase from 'snake-case'

export const FETCH_SEARCH_TERM_REQUESTS_REQUEST =
  'FETCH_SEARCH_TERM_REQS_REQUEST'
export const FETCH_SEARCH_TERM_REQUESTS_SUCCESS =
  'FETCH_SEARCH_TERM_REQS_SUCCESS'
export const FETCH_SEARCH_TERM_REQUESTS_ERROR = 'FETCH_SEARCH_TERM_REQS_ERROR'
export const APPLY_SEARCH_TERM_REQUESTS_ACTION_REQUEST =
  'APPLY_SEARCH_TERM_REQS_ACTION_REQUEST'
export const APPLY_SEARCH_TERM_REQUESTS_ACTION_SUCCESS =
  'APPLY_SEARCH_TERM_REQS_ACTION_SUCCESS'
export const APPLY_SEARCH_TERM_REQUESTS_ACTION_ERROR =
  'APPLY_SEARCH_TERM_REQS_ACTION_ERROR'

const fetchSearchTermRequestsRequest = (
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  showAnalysed
) => ({
  type: FETCH_SEARCH_TERM_REQUESTS_REQUEST,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  showAnalysed
})

const fetchSearchTermRequestsSuccess = (
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  showAnalysed,
  searchTermRequests
) => ({
  type: FETCH_SEARCH_TERM_REQUESTS_SUCCESS,
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  showAnalysed,
  ...searchTermRequests
})

const fetchSearchTermRequestsError = (actionFilter, page, perPage, error) => ({
  type: FETCH_SEARCH_TERM_REQUESTS_ERROR,
  actionFilter,
  page,
  perPage,
  error
})

export const fetchSearchTermRequests = (
  actionFilter,
  page,
  perPage,
  searchText,
  orderBy,
  ascending,
  showAnalysed = false
) => async dispatch => {
  dispatch(
    fetchSearchTermRequestsRequest(
      actionFilter,
      page,
      perPage,
      searchText,
      orderBy,
      ascending,
      showAnalysed
    )
  )

  try {
    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/search_term_requests?action_filter=${actionFilter}&page=${page}&per_page=${perPage}&filter=${searchText}&order_by=${snakeCase(
        orderBy
      )}&ascending=${ascending}&show_analysed=${showAnalysed}`,
      dataType: 'json'
    })

    dispatch(
      fetchSearchTermRequestsSuccess(
        actionFilter,
        page,
        perPage,
        searchText,
        orderBy,
        ascending,
        showAnalysed,
        camelCaseKeys(response, { deep: true })
      )
    )
  } catch (error) {
    dispatch(
      fetchSearchTermRequestsError(
        actionFilter,
        page,
        perPage,
        camelCaseKeys(error)
      )
    )
  }
}

const applySearchTermRequestsActionRequest = (action, searchTerms) => ({
  type: APPLY_SEARCH_TERM_REQUESTS_ACTION_REQUEST,
  action,
  ...searchTerms
})

const applySearchTermRequestsActionSuccess = () => ({
  type: APPLY_SEARCH_TERM_REQUESTS_ACTION_SUCCESS
})

const applySearchTermRequestsActionError = error => ({
  type: APPLY_SEARCH_TERM_REQUESTS_ACTION_ERROR,
  error
})

export const applySearchTermRequestsAction = (
  action,
  searchTerms
) => async dispatch => {
  dispatch(applySearchTermRequestsActionRequest(action, searchTerms))
  try {
    var response = await $.ajax({
      method: 'PUT',
      url: `/ad_champion/search_term_requests`,
      data: {
        action_filter: action,
        search_terms: JSON.stringify(searchTerms)
      },
      dataType: 'json'
    })
    dispatch(applySearchTermRequestsActionSuccess())
  } catch (error) {
    dispatch(applySearchTermRequestsActionError(camelCaseKeys(error)))
  }
}
