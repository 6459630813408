import camelCaseKeys from 'camelcase-keys'

export const FETCH_GOOGLE_TAXONOMIES_REQUEST = 'FETCH_GOOGLE_TAXONOMIES_REQUEST'
export const FETCH_GOOGLE_TAXONOMIES_RESPONSE =
  'FETCH_GOOGLE_TAXONOMIES_RESPONSE'

const fetchGoogleTaxonomiesRequest = () => ({
  type: FETCH_GOOGLE_TAXONOMIES_REQUEST
})

const fetchGoogleTaxonomiesResponse = (response) => ({
  type: FETCH_GOOGLE_TAXONOMIES_RESPONSE,
  ...response
})

export const fetchGoogleTaxonomies =
  (feedId = null) =>
  async (dispatch) => {
    dispatch(fetchGoogleTaxonomiesRequest())

    var response = await $.ajax({
      method: 'GET',
      url: `/ad_champion/google/taxonomies?feed_id=${feedId}`,
      dataType: 'json'
    })

    var camelResponse = camelCaseKeys(response, { deep: true })
    dispatch(fetchGoogleTaxonomiesResponse(camelResponse))

    return camelResponse.taxonomies
  }
