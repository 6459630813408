import {
  FETCH_INVENTORIES_LIST,
  SAVE_SELECTED_INVENTORY_ERROR,
  SAVE_NEW_FEED_ERROR,
  RECEIVE_INVENTORY_STATUS,
  REQUEST_INVENTORY_DOWNLOAD,
  RECEIVE_INVENTORY_DOWNLOAD,
  FETCH_INVENTORY_DETAIL_RESPONSE,
  FETCH_FEED_CHAMPION_RESPONSE,
  OPEN_INVENTORY_SHEET_REQUEST,
  OPEN_INVENTORY_SHEET_SUCCESS,
  FETCH_INVENTORY_FO_CATEGORIES_RESPONSE,
  FETCH_INVENTORY_CATEGORIES_RESPONSE,
  RECEIVE_INVENTORY_FIRST_RECONCILE_STATUS,
  RECEIVE_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS
} from 'actions/inventories'
import { inventoryFcAttributesReducer } from './inventories/attributes'
import { inventoryProcessingStatusReducer } from './inventories/processing_status'
import { inventoryProductDataAuditsReducer } from './inventories/product_data_audits'
import moment from 'moment'

const initialState = {
  list: [],
  availableVerticalFields: [],
  status: { currency: 'AUD' },
  download: {},
  details: {
    itemCount: 0,
    promotionCount: 0,
    currency: ''
  },
  feedChampionUrl: '',
  feedChampionReference: null,
  feedChampionStatus: null,
  inventoryId: 0,
  fcAttributes: {},
  processingStatus: {},
  audits: {},
  categoryHierarchy: [],
  categoryTree: {},
  initialReconciled: false,
  outboundFeedsGenerated: false
}

const feedInfoFromAction = (action) => {
  const {
    availableVerticalFields,
    feedChampionUrl,
    feedChampionReference,
    feedChampionStatus,
    inventoryId
  } = action
  return {
    availableVerticalFields,
    feedChampionUrl,
    feedChampionReference,
    feedChampionStatus,
    inventoryId
  }
}

const inventoryChangeReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORIES_LIST:
      return {
        ...state,
        list: action.inventories,
        ...feedInfoFromAction(action)
      }
    case SAVE_SELECTED_INVENTORY_ERROR:
      return { ...state, busy: false, error: action.error }
    case SAVE_NEW_FEED_ERROR:
      return { ...state, busy: false, error: action.error }
    default:
      return state
  }
}

const shouldClearDownload = (state, action) => {
  if (state.download) {
    var downloadRequestDate = state.download.requestedAt
    var downloadDate = moment(
      action.status.lastDownloadTime,
      'YYYY-MM-DD hh:mm:ss ZZ'
    ).toDate()
    return downloadRequestDate && downloadRequestDate < downloadDate
  } else {
    return false
  }
}

const inventoryStatusReducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_INVENTORY_STATUS:
      var clearDownload = shouldClearDownload(state, action)
      return {
        ...state,
        status: { ...action.status, lastChecked: new Date() },
        download: clearDownload ? {} : state.download
      }
    case REQUEST_INVENTORY_DOWNLOAD:
      return { ...state, download: {} }
    case RECEIVE_INVENTORY_DOWNLOAD:
      return {
        ...state,
        download: { ...action.download, requestedAt: new Date() }
      }
    default:
      return state
  }
}

const inventoryDetailReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_DETAIL_RESPONSE:
      return {
        ...state,
        details: {
          ...state.details,
          [action.inventoryDetail.id]: action.inventoryDetail
        }
      }
    default:
      return state
  }
}

const feedChampionReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FEED_CHAMPION_RESPONSE:
      return {
        ...state,
        feedChampion: action.feedChampion
      }
    default:
      return state
  }
}

const inventoryErrorsReducer = (state, action) => {
  switch (action.type) {
    case OPEN_INVENTORY_SHEET_REQUEST:
    case OPEN_INVENTORY_SHEET_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [action.inventoryId]: {
            ...state.details[action.inventoryId],
            sheetGenerating: action.type === OPEN_INVENTORY_SHEET_REQUEST
          }
        }
      }
    default:
      return state
  }
}

const addCategoryToHierarchy = (hierarchy, category) => {
  hierarchy[category.depth][category.id] = {
    ...category,
    hasChildren: false
  }

  if (category.parentId && category.parentId !== 0) {
    hierarchy[category.depth - 1][category.parentId].hasChildren = true
  }
}

const generateCategoryHierarchy = (action) => {
  var hierarchy = []
  hierarchy.push({
    0: { id: 0, depth: 0, name: 'Default', parentId: null, hasChildren: false }
  })
  for (var category of action.categories) {
    while (category.depth > hierarchy.length - 1) {
      hierarchy.push({})
    }
    addCategoryToHierarchy(hierarchy, category)
  }

  return hierarchy
}

const generateCategoryTree = (action) => {
  var treeData = []
  var allTreeNodes = []
  for (const category of action.categories) {
    allTreeNodes.push({
      ...category,
      key: `${category.id}${category.depth}`,
      label: category.name,
      nodes: []
    })
  }

  for (const node of allTreeNodes) {
    if (node.parentId === 0) {
      treeData.push(node)
    } else {
      const parentKey = `${node.parentId}${node.depth - 1}`
      var parentNode = allTreeNodes.find((n) => n.key === parentKey)
      parentNode.nodes.push(node)
    }
  }

  return treeData
}

const inventoryFeedOpsCampaignsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_FO_CATEGORIES_RESPONSE:
      var generatedCategoryHierarchy = generateCategoryHierarchy(action)
      var generatedCategoryTree = generateCategoryTree(action)
      return {
        ...state,
        foCategoryHierarchy: generatedCategoryHierarchy,
        foCategoryTree: {
          ...state.foCategoryTree,
          [action.websiteId]: generatedCategoryTree
        },
        inventoryId: action.inventoryId
      }

    default:
      return state
  }
}

const inventoryCampaignsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_CATEGORIES_RESPONSE:
      var generatedCategoryHierarchy = generateCategoryHierarchy(action)
      var generatedCategoryTree = generateCategoryTree(action)
      return {
        ...state,
        categoryHierarchy: generatedCategoryHierarchy,
        categoryTree: {
          ...state.categoryTree,
          [action.websiteId]: generatedCategoryTree
        },
        inventoryId: action.inventoryId
      }

    default:
      return state
  }
}

const inventoryInitialReconciledReducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_INVENTORY_FIRST_RECONCILE_STATUS:
      return {
        ...state,
        initialReconciled: action.reconciled
      }
    default:
      return state
  }
}

const inventoryOutboundFeedsGeneratedReducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_INVENTORY_OUTBOUND_FEEDS_GENERATION_STATUS:
      return {
        ...state,
        outboundFeedsGenerated: action.generated
      }
    default:
      return state
  }
}

const inventoriesReducer = (state = initialState, action) => {
  state = inventoryChangeReducer(state, action)
  state = inventoryStatusReducer(state, action)
  state = inventoryDetailReducer(state, action)
  state = inventoryErrorsReducer(state, action)
  state = feedChampionReducer(state, action)
  state = inventoryFcAttributesReducer(state, action)
  state = inventoryProcessingStatusReducer(state, action)
  state = inventoryProductDataAuditsReducer(state, action)
  state = inventoryFeedOpsCampaignsReducer(state, action)
  state = inventoryCampaignsReducer(state, action)
  state = inventoryInitialReconciledReducer(state, action)
  state = inventoryOutboundFeedsGeneratedReducer(state, action)
  return state
}

export default inventoriesReducer
