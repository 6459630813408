import _ from 'lodash'

export const getSelectedFilterOptionAndValues = (
  appliedFilter,
  selectedAttribute
) => {
  const { filterOptions } = selectedAttribute
  var selectedFilterOption = filterOptions[0]
  var filterValues = ''

  if (!_.isEmpty(appliedFilter)) {
    const { filterType, values } = appliedFilter
    if (!_.isEmpty(filterType)) {
      selectedFilterOption = filterType
    }
    if (!_.isEmpty(values)) {
      filterValues = values.join('\n')
    }
  }

  return { selectedFilterOption, values: filterValues }
}

export const addNewFilters = (
  appliedFilters,
  attributeName,
  selectedFilterOption,
  filterValues,
  metaData = {}
) => {
  var updatedAppliedFilters = _.cloneDeep(appliedFilters)
  updatedAppliedFilters.push({
    attributeName: attributeName,
    filterType: selectedFilterOption,
    values: filterValues,
    metaData: metaData
  })

  return updatedAppliedFilters
}

export const updateAppliedFilters = (params) => {
  const { index, appliedFilters, selectedFilterOption, filterValues } = params
  const metaData = params.metaData || {}

  var updatedAppliedFilters = _.cloneDeep(appliedFilters)
  const appliedFilter = updatedAppliedFilters[index] || {}

  if (!_.isEmpty(appliedFilter)) {
    if (
      !_.isEqual(appliedFilter.filterType, selectedFilterOption) ||
      !_.isEqual(appliedFilter.values, filterValues) ||
      !_.isEqual(appliedFilter.metaData || {}, metaData)
    ) {
      appliedFilter.filterType = selectedFilterOption
      appliedFilter.values = filterValues
      appliedFilter.metaData = metaData
    }
  }

  return updatedAppliedFilters
}

export const getAppliedFilterText = (attributeName, filterType, values) => {
  var filterText = `${attributeName} ${filterType}`
  if (values && values.length > 0) {
    if (values.length > 2) {
      filterText += ` ${values.slice(0, 2).join(', ')}, + ${
        values.length - 2
      } more`
    } else {
      filterText += ` ${values.join(', ')}`
    }
  }

  return filterText
}
