import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import changeCase from 'change-case'
import { MDBView, MDBCard, MDBCardBody, MDBCardText, MDBIcon } from 'mdbreact'
import { SMART_SHOPPING, BRAND_SEARCH } from 'util/build_type'
import AdvertisingPerformanceChart from 'components/feed_ops/charts/advertising_performance_chart'
import FeedOpsPerformanceStatistics from 'components/feed_ops/website/product_feeds/statistics'
import { superUserSelector } from 'selectors/user'
import {
  websiteAdwordsSelector,
  websiteMicrosoftAdwordsSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import {
  feedopsAdvertisingAllTotals,
  feedopsAdvertisingTotals,
  advertisingRecommendationsByType,
  advertisingGoalTypeByType,
  feedopsAdvertisingByChannelAndWebsiteSelector
} from 'selectors/performance'
import ChartOverlay from 'components/feed_ops/charts/chart_overlay'
import TargetActualCard from 'components/websites/target_actual_card'
import FeedOpsDetailChartRecommendations from 'components/feed_ops/charts/chart_recommendation'
import FeedOpsDetailChartSummaryTable from 'components/feed_ops/charts/chart_summary_table'
import { SUPPORTED_CHANNELS, CHANNEL_GOOGLE } from 'util/supported_channels'
import { formatNumber } from 'util/format_number'

const recommendationTooltip =
  'This recommendation is based on the ROAS setting and the smart shopping campaign budget from Google Ads over this period.'

export class FeedOpsGoalAndBudgetManagement extends Component {
  static propTypes = {
    isSuperUser: PropTypes.bool.isRequired,
    googleAdwordsAccount: PropTypes.object.isRequired,
    microsoftAdwordsAccount: PropTypes.object.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    goalTarget: PropTypes.number.isRequired,
    goalActual: PropTypes.number.isRequired,
    costTarget: PropTypes.number.isRequired,
    costActual: PropTypes.number.isRequired,
    advertisingRecommendations: PropTypes.object.isRequired,
    advertisingPerformance: PropTypes.object.isRequired
  }

  renderBriefRecommendation() {
    const { advertisingRecommendations } = this.props
    if (
      advertisingRecommendations &&
      Object.keys(advertisingRecommendations).length > 0
    ) {
      return (
        <MDBCard className='feed-ops-brief-recommendation'>
          <MDBCardBody>
            <MDBCardText>
              <MDBIcon className='far' icon='exclamation-circle' />{' '}
              {changeCase.titleCase(advertisingRecommendations.type)}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      )
    } else {
      return null
    }
  }

  computeGoalTypes() {
    const { shoppingGoalType, brandGoalType } = this.props
    const allowedGoalTypes = ['max_conversion_value', 'roas']
    return [
      ...new Set([
        allowedGoalTypes.includes(shoppingGoalType['goalType'])
          ? 'max_conversion_value'
          : 'missing',
        allowedGoalTypes.includes(brandGoalType['goalType'])
          ? 'max_conversion_value'
          : 'missing'
      ])
    ]
  }

  renderCard(cardType, actualData, targetData) {
    var goalTypes = this.computeGoalTypes()

    goalTypes = goalTypes.filter((x) => x && x !== 'missing')
    const advertisingGoalType =
      goalTypes.length === 0 ? 'missing' : 'max_conversion_value'

    const target =
      advertisingGoalType !== 'max_conversion_value' && cardType === 'goal'
        ? 'N/A'
        : targetData
    return (
      <TargetActualCard
        cardType={cardType}
        actualData={actualData}
        targetData={target}
        advertisingGoalType='max_conversion_value'
        showZeroValues={false}
      />
    )
  }

  renderChart() {
    const { goalTarget, goalActual, costTarget, costActual } = this.props
    return (
      <MDBCard className='feed-ops-goal-budget-management'>
        <MDBCardBody>
          {this.renderCard('goal', goalActual, goalTarget)}
          {this.renderCard('cost', costActual, costTarget)}
          <FeedOpsPerformanceStatistics />
          <AdvertisingPerformanceChart displayFullChart={true} />
        </MDBCardBody>
      </MDBCard>
    )
  }

  getTargetRoas(goal) {
    if (!goal.value || goal.goalType !== 'roas') {
      return 'N/A'
    }
    return `${formatNumber(goal.value * 100, 0)}%`
  }

  getCampaignTypeTotalsRows() {
    const { adTotals } = this.props
    var totalData = []

    const adTotalsCopy = _.cloneDeep(adTotals)
    if (Array.isArray(adTotalsCopy)) {
      adTotalsCopy.forEach((data) => {
        Object.entries(data).forEach(([key, value]) => {
          data[key] = formatNumber(value, 0)
        })

        var row = {
          type: data.type,
          totalConvValue: `$${data.totalConvValue}`,
          totalConversions: data.conversions,
          totalImpressions: data.impressions,
          totalClicks: data.clicks,
          actualCost: `$${data.cost}`,
          actualRoas: `${data.roas}%`
        }
        totalData.push(row)
      })
    }
    return totalData
  }

  renderComponent(shouldRender) {
    const { isSuperUser, advertisingRecommendations } = this.props
    return (
      <div>
        {isSuperUser && this.renderBriefRecommendation()}
        {this.renderChart()}
        {shouldRender && (
          <>
            {isSuperUser && (
              <FeedOpsDetailChartRecommendations
                recommendation={advertisingRecommendations}
                tooltipText={recommendationTooltip}
              />
            )}
            <FeedOpsDetailChartSummaryTable
              totals={this.getCampaignTypeTotalsRows()}
            />
          </>
        )}
      </div>
    )
  }

  shouldRender() {
    const { channel, googleAdwordsAccount, microsoftAdwordsAccount } =
      this.props
    return (
      channel === CHANNEL_GOOGLE
        ? googleAdwordsAccount && googleAdwordsAccount.adwordsAccountId
        : microsoftAdwordsAccount && microsoftAdwordsAccount.customerAccountId
    )
      ? true
      : false
  }

  render() {
    const { channel } = this.props
    const shouldRender = this.shouldRender()

    return (
      <MDBView className='overlay-view'>
        {this.renderComponent(shouldRender)}
        {!shouldRender && (
          <ChartOverlay
            linkPrefixText={`You need to connect your ${changeCase.titleCase(
              channel
            )} Ads account`}
            destination='#'
            linkSuffixText=' to enable performance reporting.'
          />
        )}
      </MDBView>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const totals = feedopsAdvertisingTotals(state, props)
  return {
    isSuperUser: superUserSelector(state),
    googleAdwordsAccount: websiteAdwordsSelector(state, props),
    microsoftAdwordsAccount: websiteMicrosoftAdwordsSelector(state, props),
    channel: channelSelector(state, props),
    shoppingGoalType: advertisingGoalTypeByType(SMART_SHOPPING)(state, props),
    brandGoalType: advertisingGoalTypeByType(BRAND_SEARCH)(state, props),
    goalTarget: totals.goal || 0,
    goalActual: totals.totalConvValue || 0,
    costTarget: totals.budget || 0,
    costActual: totals.cost || 0,
    adTotals: feedopsAdvertisingAllTotals(state, props),
    advertisingRecommendations: advertisingRecommendationsByType(
      SMART_SHOPPING
    )(state, props),
    advertisingPerformance: feedopsAdvertisingByChannelAndWebsiteSelector(
      state,
      props
    )
  }
}

export const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsGoalAndBudgetManagement)
)
