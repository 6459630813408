import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import changeCase from 'change-case'
import _ from 'lodash'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCollapseHeader,
  MDBCollapse,
  MDBIcon
} from 'mdbreact'
import toastr from 'toastr'
import {
  saveProductAttributeReplacements,
  updateProductAttributeReplacements
} from 'actions/product_feeds/replacements'
import { OP_EQUALS } from 'components/feed_ops/website/product_optimisations/attribute_filters_section/filter_operations'
import { websiteProductReplacementsFiltersSelector } from 'selectors/product_feeds/replacements'
import {
  websiteIdSelector,
  websiteFeedIdByChannelSelector
} from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import FooterActions from 'components/feed_ops/website/product_optimisations/optimiser/table_actions/bulk_editor/footer_actions'
import TextEditor from './bulk_editors/text_editor'
import TitleDescriptionEditor from './bulk_editors/title_description_editor'
import GoogleProductCategoryDropdown from './bulk_editors/google_product_category_dropdown'
import AttributeEnumDropdown from './bulk_editors/attribute_enum_dropdown'
import { SUPPORTED_CHANNELS } from 'util/supported_channels'
import {
  PRODUCT_SKU,
  PRODUCT_CREATED_AT,
  PRODUCT_UPDATED_AT,
  TITLE,
  DESCRIPTION,
  GOOGLE_PRODUCT_CATEGORY
} from 'util/product_attributes'
import { Edit } from '@material-ui/icons'
import './bulk_editor.scss'

export class BulkEditor extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    feedId: PropTypes.number.isRequired,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS).isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({ field: PropTypes.string, title: PropTypes.string })
    ).isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveProductAttributeReplacements: PropTypes.func.isRequired,
    onSaveProductAttributesReplacements: PropTypes.func.isRequired,
    toastr: PropTypes.object.isRequired,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    allRowsSelected: PropTypes.bool.isRequired
  }

  static defaultProps = {
    toastr
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      activeField: null,
      values: {},
      saveToAllChannels: false,
      isSaving: false
    }
    this.isLoaded = true
  }

  componentDidMount() {
    const { attributes } = this.props
    const availableFields = attributes.filter((a) =>
      this.getEditorForField(a.field)
    )
    this.setState({
      activeField: availableFields.length > 0 ? availableFields[0].field : ''
    })
    this.initializeValues()
  }

  componentDidUpdate(prevProps) {
    const { selectedRows } = this.props
    if (!_.isEqual(prevProps.selectedRows, selectedRows)) {
      this.initializeValues()
    }
  }

  componentWillUnmount() {
    this.isLoaded = false
  }

  initializeValues() {
    const { selectedRows, attributes } = this.props
    var values = {}
    attributes.forEach((a) => {
      const { field } = a
      var value = selectedRows[0].override[field]
      if (selectedRows.filter((r) => r.override[field] !== value).length <= 0) {
        values[field] = value
      }

      if (
        selectedRows.length === 1 &&
        (field === TITLE || field === DESCRIPTION) &&
        value
      ) {
        values[field] = value.replace(
          `{${changeCase.titleCase(field)}}`,
          selectedRows[0].raw[field]
        )
      }
    })
    this.setState({ values: values })
  }

  toggleIsOpen() {
    if (!this.isLoaded) {
      return
    }
    this.setIsOpen(!this.state.isOpen)
  }

  setIsOpen(isOpen) {
    if (!this.isLoaded) {
      return
    }
    this.setState({ isOpen })
  }

  handleCollapse(column) {
    if (!this.isLoaded) {
      return
    }
    const { activeField } = this.state
    if (activeField === column) {
      this.setState({ activeField: null })
    } else {
      this.setState({ activeField: column })
    }
  }

  getEditorForField(column, enumValues) {
    switch (column) {
      case PRODUCT_SKU:
      case PRODUCT_CREATED_AT:
      case PRODUCT_UPDATED_AT:
        return null
      case TITLE:
      case DESCRIPTION:
        return TitleDescriptionEditor
      case GOOGLE_PRODUCT_CATEGORY:
        return GoogleProductCategoryDropdown
      default:
        if (enumValues && enumValues.length > 0) {
          return AttributeEnumDropdown
        }
        return TextEditor
    }
  }

  renderCollapseHeader(field, title, activeField) {
    return (
      <MDBCollapseHeader
        onClick={this.handleCollapse.bind(this, field)}
        id={field}
      >
        {title}{' '}
        <MDBIcon icon={activeField === field ? 'angle-up' : 'angle-down'} />
      </MDBCollapseHeader>
    )
  }

  handleValueChange(field, value) {
    if (!this.isLoaded) {
      return
    }
    const { values } = this.state
    const { selectedRows } = this.props
    if (
      selectedRows.length === 1 &&
      (field === TITLE || field === DESCRIPTION)
    ) {
      value = value.replace(
        selectedRows[0].raw[field],
        `{${changeCase.titleCase(field)}}`
      )
    }
    this.setState({ values: { ...values, [field]: value } })
  }

  renderFieldEditor(attribute) {
    const { selectedRows } = this.props
    const { field, enumValues, title } = attribute
    const { activeField, values } = this.state

    const Editor = this.getEditorForField(field, enumValues)
    if (!Editor) {
      return null
    }

    return (
      <div className='collapse-row' key={field}>
        {this.renderCollapseHeader(field, title, activeField)}
        <MDBCollapse id={field} isOpen={field === activeField}>
          <Editor
            selectedRows={selectedRows}
            attributeName={field}
            attributeDisplayName={title}
            enumValues={enumValues}
            value={values[field] || ''}
            onChange={this.handleValueChange.bind(this, field)}
          />
        </MDBCollapse>
      </div>
    )
  }

  handleSaveToAllChannels() {
    if (!this.isLoaded) {
      return
    }
    const { saveToAllChannels } = this.state
    this.setState({ saveToAllChannels: !saveToAllChannels })
  }

  handleCancel() {
    if (!this.isLoaded) {
      return
    }
    this.initializeValues()
    this.setIsOpen(false)
  }

  buildFilters() {
    const { allRowsSelected, selectedRows } = this.props
    if (allRowsSelected) {
      return this.props.filters
    } else {
      return [
        {
          attributeName: 'Sku',
          filterType: OP_EQUALS,
          values: selectedRows.map((r) => r.override.sku)
        }
      ]
    }
  }

  async handleSave(genManualOptSuggestions) {
    if (!this.isLoaded) {
      return
    }

    const { websiteId, toastr } = this.props

    toastr.info('Saving your changes.')

    this.setState({ isSaving: true })
    const { saveProductAttributeReplacements, channel, feedId } = this.props
    const { saveToAllChannels, values } = this.state

    var filters = this.buildFilters()

    try {
      await saveProductAttributeReplacements(
        websiteId,
        feedId,
        channel,
        filters,
        values,
        false,
        saveToAllChannels,
        genManualOptSuggestions
      )
      toastr.success('Your changes have been saved.')

      const { onSaveProductAttributesReplacements } = this.props
      await onSaveProductAttributesReplacements()
    } catch (ex) {
      toastr.error('An error occurred saving your changes. Please try again.')
    }
    this.setState({ isSaving: false })
    this.setIsOpen(false)
  }

  renderModalFooter() {
    const { saveToAllChannels, isSaving } = this.state
    return (
      <FooterActions
        saveToAllChannels={saveToAllChannels}
        isSaving={isSaving}
        onHandleSaveToAllChannels={this.handleSaveToAllChannels.bind(this)}
        onHandleCancel={this.handleCancel.bind(this)}
        onHandleSave={this.handleSave.bind(this)}
      />
    )
  }

  render() {
    const { isOpen } = this.state
    const { attributes, selectedRows } = this.props
    return (
      <div className='product-level-bulk-editor'>
        <div
          id='bulk-editor-btn'
          tag='span'
          color='link'
          onClick={this.toggleIsOpen.bind(this)}
          className='btn-action'
        >
          <Edit />
          Edit
        </div>
        <MDBModal isOpen={isOpen} size='xl' backdrop={true}>
          <MDBModalHeader>
            Edit product data ({selectedRows.length} items)
          </MDBModalHeader>
          <MDBModalBody>
            {attributes.map((c) => this.renderFieldEditor(c))}
          </MDBModalBody>
          {this.renderModalFooter()}
        </MDBModal>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  websiteId: websiteIdSelector(state, props),
  feedId: websiteFeedIdByChannelSelector(state, props),
  channel: channelSelector(state, props),
  filters: websiteProductReplacementsFiltersSelector(state, props)
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProductAttributeReplacements,
      saveProductAttributeReplacements
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkEditor)
)
