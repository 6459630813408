import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBSelect,
  MDBIcon
} from 'mdbreact'
import { microsoftAuthRequestToken } from 'util/microsoft/microsoft_apis'
import { fetchWebsiteDetail } from 'actions/websites'
import { listAdsAccounts, selectAdsAccount } from 'actions/microsoft/adwords'
import { getMicrosoftUser } from 'selectors/microsoft/user'
import { websiteIdSelector } from 'selectors/websites'
import { adwordsAccountsSelector } from 'selectors/microsoft/adwords'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'

const MANAGE_ADS_SCOPE = 'https://ads.microsoft.com/msads.manage'

export class MicrosoftAdsConnectionCard extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    adwordsAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    microsoftAuthRequestToken: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired,
    listAdsAccounts: PropTypes.func.isRequired,
    selectAdsAccount: PropTypes.func.isRequired
  }

  static defaultProps = {
    microsoftAuthRequestToken
  }

  constructor(props) {
    super(props)
    this.state = {
      busy: true,
      firstLoadComplete: false,
      fetchingAccounts: false,
      adwordsAccountId: null
    }
  }

  async componentDidMount() {
    await this.fetchWebsiteDetail()

    this.setState({
      firstLoadComplete: true,
      busy: false
    })

    if (this.scopeGranted()) {
      await this.startLogin()
    }
  }

  async componentDidUpdate(prevProps) {
    const { websiteId } = this.props

    if (prevProps.websiteId !== websiteId) {
      await this.fetchWebsiteDetail()
    }
  }

  async fetchWebsiteDetail() {
    const { fetchWebsiteDetail, websiteId } = this.props

    if (websiteId > 0) {
      this.setState({ busy: true })
      await fetchWebsiteDetail(websiteId)
      this.setState({ busy: false })
    }
  }

  async fetchAdsAccounts() {
    const { listAdsAccounts } = this.props
    this.setState({
      busy: true,
      fetchingAccounts: true
    })

    try {
      await listAdsAccounts()
    } catch (error) {
      toastr.error(error.responseJSON.error)
    }

    this.setState({
      firstLoadComplete: true,
      busy: false,
      fetchingAccounts: false
    })
  }

  async startLogin() {
    const { microsoftAuthRequestToken } = this.props
    await microsoftAuthRequestToken(MANAGE_ADS_SCOPE)
    await this.fetchAdsAccounts()
  }

  renderSignedInAs() {
    const { email } = this.props
    if (!email) {
      return null
    }

    var signedInAsText =
      'Not the Microsoft account with access to the Ads Account you want to use?'

    return (
      <p>
        Signed in as {email}. {signedInAsText}{' '}
        <MDBBtn className='btn-href-style' onClick={this.startLogin.bind(this)}>
          Change
        </MDBBtn>
      </p>
    )
  }

  scopeGranted() {
    const { grantedScope } = this.props
    if (grantedScope.search(MANAGE_ADS_SCOPE) < 0) {
      return false
    }

    return true
  }

  handleChange(e) {
    const { adwordsAccountId } = this.state
    if (e[0] && e[0] !== adwordsAccountId) {
      this.setState({ adwordsAccountId: e[0] })
    }
  }

  async connectAdsAccount() {
    const {
      selectAdsAccount,
      websiteId,
      adwordsAccounts,
      fetchWebsiteDetail,
      location
    } = this.props
    const { adwordsAccountId } = this.state
    const adwordsAccount = adwordsAccounts.find(
      (ad) => ad.accountId === adwordsAccountId
    )
    const { accountName } = adwordsAccount

    await selectAdsAccount(websiteId, adwordsAccountId, accountName)
    await fetchWebsiteDetail(websiteId)
    if (location.pathname.includes('feed_ops')) {
      this.props.history.goBack()
    }
  }

  renderUploadFeedButton() {
    const { busy } = this.state
    var uploadFeedButtonText = 'Connect Ads Account'
    var uploadingFeedButtonText = 'Connecting Ads Account'

    const buttonText = busy ? (
      <div>
        <MDBIcon spin fixed icon='spinner' />
        {uploadingFeedButtonText}
      </div>
    ) : (
      uploadFeedButtonText
    )

    return (
      <div>
        <MDBBtn disabled={busy} onClick={this.connectAdsAccount.bind(this)}>
          {buttonText}
        </MDBBtn>
      </div>
    )
  }

  renderSelect() {
    const { adwordsAccounts } = this.props
    const { fetchingAccounts, adwordsAccountId } = this.state

    if (fetchingAccounts) {
      return (
        <IndeterminateProgressIndicator message='Loading your accounts (may take a few minutes).' />
      )
    }

    var options = adwordsAccounts.map((a) => ({
      text: `${a.accountName} (${a.accountId})`,
      value: `${a.accountId}`
    }))

    var selectedOption = options.find((o) => o.value === adwordsAccountId) || {}

    const disabled = options.length === 0 ? 'disabled' : ''
    return (
      <div>
        {adwordsAccounts.length === 0 ? (
          'There is no ads account available under the current Microsoft account. Please use another Microsoft account.'
        ) : (
          <>
            <MDBSelect
              className={disabled + 'merchant-center-selector'}
              options={options}
              labelClass='merchantCenterSelectField'
              selected={selectedOption.text || 'Select Microsoft Ads Account'}
              getValue={this.handleChange.bind(this)}
            />
            {this.renderUploadFeedButton()}
          </>
        )}
      </div>
    )
  }

  conditionallyRenderNext() {
    return this.scopeGranted() ? (
      this.renderSelect()
    ) : (
      <MDBBtn onClick={this.startLogin.bind(this)}>
        Authorize Ads Account Access
      </MDBBtn>
    )
  }

  renderEditable() {
    const { email } = this.props
    return (
      <div className='merchant-center-form'>
        {email.length === 0 && (
          <p>
            In order to connect an existing Microsoft Ads Account, authorize Ads
            Account access by signing in with Microsoft.
          </p>
        )}
        <div className={'connect-or-create'}>{this.renderSignedInAs()}</div>
        {this.conditionallyRenderNext()}
      </div>
    )
  }

  render() {
    const { firstLoadComplete } = this.state
    if (!firstLoadComplete) {
      return <IndeterminateProgressIndicator />
    }

    return (
      <MDBCard className='product-measures-diagnostics'>
        <MDBCardBody>
          <MDBCardTitle>Microsoft Ads Connection</MDBCardTitle>
          {this.renderEditable()}
        </MDBCardBody>
      </MDBCard>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { email, refreshToken, scope } = getMicrosoftUser(() => state)
  return {
    email,
    refreshToken,
    grantedScope: scope,
    websiteId: websiteIdSelector(state, props),
    adwordsAccounts: adwordsAccountsSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWebsiteDetail, listAdsAccounts, selectAdsAccount },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MicrosoftAdsConnectionCard)
)
