import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { reduxForm, getFormValues, change } from 'redux-form'
import _ from 'lodash'
import IndeterminateProgressIndicator from 'components/ad_champion/common/indeterminate_progress_indicator'
import { ALL_CATEGORIES } from 'components/feed/optimisation/filter'
import ControlBar from '../category_optimizations/control_bar'
import CategoryTreeNodes from '../category_optimizations/category_tree_nodes'
import { remoteFormSubmissionHandlers } from 'actions/forms'
import { fetchInventoryCategories } from 'actions/inventories'
import { saveInventoryCategoryReplacements } from 'actions/product_feeds/replacements'
import { filterCategoriesTreeBySearchTerm } from '../category_optimizations/filter'
import {
  inventoryCategoryTreeSelector,
  inventoryCategoryHierarchySelector
} from 'selectors/inventory'

export class CategoryTree extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryHierarchy: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    fetchInventoryCategories: PropTypes.func.isRequired,
    saveInventoryCategoryReplacements: PropTypes.func.isRequired,
    filterCategoriesTreeBySearchTerm: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  static defaultProps = {
    filterCategoriesTreeBySearchTerm
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      categoryFilter: ALL_CATEGORIES,
      searchKeyword: '',
      filteredCategories: [],
      openNodes: []
    }
  }

  async componentDidMount() {
    await this.fetchInventoryCategories()
  }

  async componentDidUpdate(prevProps) {
    const { websiteId, formValues } = this.props
    if (prevProps.websiteId !== websiteId && websiteId !== 0) {
      await this.fetchInventoryCategories()
    }

    const isCategoriesChanged =
      formValues &&
      Object.keys(formValues).length > 0 &&
      !_.isEqual(prevProps.formValues.categories, formValues.categories)
    if (isCategoriesChanged) {
      const { categoryFilter, searchKeyword } = this.state
      this.filterCategoriesBySearchTerm(categoryFilter, searchKeyword)
    }
  }

  async fetchInventoryCategories() {
    const { fetchInventoryCategories, websiteId } = this.props
    if (websiteId !== 0) {
      try {
        this.setState({ loading: true })
        await fetchInventoryCategories(websiteId)
        this.initializeForm()

        const { categoryFilter, searchKeyword } = this.state
        this.filterCategoriesBySearchTerm(categoryFilter, searchKeyword)
      } catch (error) {
        toastr.error(
          'An error occurred while fetching your categories. Please contact support for assistance.'
        )
      }
      this.setState({ loading: false })
    }
  }

  initializeForm() {
    const { initialize, categories, categoryHierarchy } = this.props
    if (categories.length > 0) {
      initialize({ categories: categoryHierarchy, categoryReplacements: [] })
    }
  }

  renderLoading() {
    return (
      <IndeterminateProgressIndicator
        className='loading-indicator'
        message='This can take a long time for the first load each day. You can close this screen and come back at any time. Loading will continue in the background.'
      />
    )
  }

  filterCategoriesBySearchTerm(categoryFilter, searchKeyword) {
    var filteredCategories = []

    const filteredValues = this.filterCategoriesTreeBySearchTerm(
      categoryFilter,
      searchKeyword
    )
    filteredCategories = filteredValues.filteredCategories
    this.setState({ filteredCategories })
  }

  filterCategoriesTreeBySearchTerm(categoryFilter, searchKeyword) {
    const { filterCategoriesTreeBySearchTerm, formValues } = this.props

    return filterCategoriesTreeBySearchTerm(
      categoryFilter,
      searchKeyword,
      formValues,
      []
    )
  }

  handleFilter(filter) {
    this.setState({ categoryFilter: filter })
    const { searchKeyword } = this.state
    this.filterCategoriesBySearchTerm(filter, searchKeyword)
  }

  handleSearch(searchKeyword) {
    this.setState({ searchKeyword: searchKeyword })
    const { categoryFilter } = this.state
    this.filterCategoriesBySearchTerm(categoryFilter, searchKeyword)
  }

  enableDisableFilteredCategories(categories, enabled) {
    const { change } = this.props

    categories.forEach((category) => {
      const { depth, id } = category
      change(REDUX_FORM_NAME, `categories[${depth}][${id}][excluded]`, !enabled)
      if (category.nodes && category.nodes.length > 0) {
        this.enableDisableFilteredCategories(category.nodes, enabled)
      }
    })
  }

  handleEnableDisableAll(enabled) {
    const { filteredCategories } = this.state
    this.enableDisableFilteredCategories(filteredCategories, enabled)
  }

  updateOpenNodes(openNodes) {
    this.setState({ openNodes: openNodes })
  }

  selectedCategory(category, visible) {
    if (!_.isEmpty(category) && visible) {
      const { depth, id, excluded } = category
      const { change } = this.props

      change(
        REDUX_FORM_NAME,
        `categories[${depth}][${id}][excluded]`,
        !excluded
      )
    }
  }

  renderCategoryTreeNodes() {
    const { filteredCategories, openNodes } = this.state
    return (
      <CategoryTreeNodes
        filteredCategories={filteredCategories}
        openNodes={openNodes}
        displayCheckboxes={true}
        updateOpenNodes={this.updateOpenNodes.bind(this)}
        selectedCategory={this.selectedCategory.bind(this)}
      />
    )
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return this.renderLoading()
    }

    const { categories } = this.props
    const { searchKeyword } = this.state
    return categories.length > 0 ? (
      <div className='category-tree-column category-tree-div'>
        <ControlBar
          searchKeyword={searchKeyword}
          displayEnableDisableAllBtns={true}
          handleFilter={this.handleFilter.bind(this)}
          handleSearch={this.handleSearch.bind(this)}
          handleEnableDisableAll={this.handleEnableDisableAll.bind(this)}
        />
        {this.renderCategoryTreeNodes()}
      </div>
    ) : null
  }
}

export const mapStateToProps = (state, props) => ({
  categories: inventoryCategoryTreeSelector(state, props),
  categoryHierarchy: inventoryCategoryHierarchySelector(state, props),
  formValues: getFormValues(REDUX_FORM_NAME)(state) || {}
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInventoryCategories,
      change,
      saveInventoryCategoryReplacements
    },
    dispatch
  )

const CategoryTreeRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryTree)

export const REDUX_FORM_NAME = 'WebsiteCategories'

export const handleSubmit = async (values, dispatch, props) => {
  const {
    websiteId,
    saveInventoryCategoryReplacements,
    fetchInventoryCategories
  } = props
  try {
    const categories = values.categories
    await saveInventoryCategoryReplacements(websiteId, [], categories)
    toastr.success('Category settings applied. Reprocessing feed.')
    fetchInventoryCategories(websiteId, null, true)
  } catch (error) {
    toastr.error(
      'An error occurred while saving your category settings. Please contact support for assistance.'
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: REDUX_FORM_NAME,
      onSubmit: handleSubmit,
      ...remoteFormSubmissionHandlers(REDUX_FORM_NAME)
    })(connect(mapStateToProps, mapDispatchToProps)(CategoryTreeRedux))
  )
)
