import camelCaseKeys from 'camelcase-keys'

export const FETCH_USER_ACCOUNTS_REQUEST = 'FETCH_USER_ACCOUNTS_REQUEST'
export const FETCH_USER_ACCOUNTS_RESPONSE = 'FETCH_USER_ACCOUNTS_RESPONSE'
export const UPDATE_ACCOUNT_OVERVIEW_FILTERS = 'UPDATE_ACCOUNT_OVERVIEW_FILTERS'

const fetchUserAccountsRequest = (userId) => ({
  type: FETCH_USER_ACCOUNTS_REQUEST,
  userId
})

const fetchUserAccountsResponse = (userId, response) => ({
  type: FETCH_USER_ACCOUNTS_RESPONSE,
  userId,
  ...response
})

export const fetchUserAccounts = (userId) => async (dispatch) => {
  dispatch(fetchUserAccountsRequest(userId))

  var response = await $.ajax({
    method: 'GET',
    url: `/ad_champion/users/${userId}/accounts`,
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchUserAccountsResponse(userId, response))
  return response
}

export const updateAccountOverviewFilters = (filters) => (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNT_OVERVIEW_FILTERS,
    filters
  })
}
