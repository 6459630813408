import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import changeCase from 'change-case'
import qs from 'qs'
import { setQueryString } from './header_util'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'
import { appIdSelector } from 'selectors/app'
import { websiteIdSelector } from 'selectors/websites'
import { channelSelector } from 'selectors/channel'
import { enabledChannelsSelector } from 'selectors/websites/channel_settings'
import {
  SUPPORTED_CHANNELS,
  CHANNEL_ALL,
  getLogoByChannel
} from 'util/supported_channels'

import './channel_selector_dropdown.scss'

export class ChannelSelectorDropdown extends Component {
  static propTypes = {
    float: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    showAllOption: PropTypes.bool,
    appId: PropTypes.string,
    websiteId: PropTypes.number,
    channel: PropTypes.oneOf(SUPPORTED_CHANNELS),
    enabledChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
    location: PropTypes.object.isRequired
  }

  renderAllChannelLogos() {
    const { enabledChannels } = this.props
    var imgLogos = []

    enabledChannels.forEach((channel, index) => {
      const logo = getLogoByChannel(channel)
      imgLogos.push(<img key={channel + index} src={logo} alt={channel} />)
    })

    return <>{imgLogos}</>
  }

  renderChannelLogo() {
    const { channel } = this.props
    const logos = getLogoByChannel(channel)
    if (channel === CHANNEL_ALL) {
      return this.renderAllChannelLogos()
    }

    return <img src={logos} alt={channel} />
  }

  computeOptions() {
    const { showAllOption, enabledChannels } = this.props
    return showAllOption
      ? enabledChannels.length > 1
        ? [CHANNEL_ALL, ...enabledChannels]
        : enabledChannels
      : enabledChannels
  }

  renderDropdownToggle() {
    const { enabledChannels, channel, history } = this.props
    const options = this.computeOptions()

    return options.includes(channel) ? (
      <MDBDropdownToggle
        nav
        caret
        className={enabledChannels.length > 1 ? '' : 'single-channel-toggle'}
      >
        {this.renderChannelLogo()}
        <label className='active'>{changeCase.titleCase(channel)}</label>
      </MDBDropdownToggle>
    ) : (
      history.replace({
        search: qs.stringify({ channel: options[0] })
      })
    )
  }

  handleSelectChannel(channel) {
    const { history } = this.props
    history.push(setQueryString(history, { channel }))
  }

  renderDropdownMenu() {
    const options = this.computeOptions()
    const { channel } = this.props

    return (
      <MDBDropdownMenu>
        {options.map((ch) => (
          <MDBDropdownItem
            key={ch}
            color='link'
            onClick={this.handleSelectChannel.bind(this, ch)}
            active={channel === ch}
          >
            {changeCase.titleCase(ch)}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  render() {
    const { float, disabled, enabledChannels } = this.props

    return enabledChannels && enabledChannels.length > 0 ? (
      <MDBDropdown
        className={`channel-selector-dropdown float-${float} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {this.renderDropdownToggle()}
        {enabledChannels.length > 1 && this.renderDropdownMenu()}
      </MDBDropdown>
    ) : null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    appId: appIdSelector(state, props),
    websiteId: websiteIdSelector(state, props),
    channel: channelSelector(state, props),
    enabledChannels: enabledChannelsSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChannelSelectorDropdown)
)
