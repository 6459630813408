import camelCaseKeys from 'camelcase-keys'
import sleep from 'util/sleep'

export const CREATE_SHOPPING_MAPPING_SHEET_REQUEST =
  'CREATE_SHOPPING_MAPPING_SHEET_REQUEST'
export const CREATE_SHOPPING_MAPPING_SHEET_SUCCESS =
  'CREATE_SHOPPING_MAPPING_SHEET_SUCCESS'
export const APPLY_SHOPPING_MAPPING_SHEET_REQUEST =
  'APPLY_SHOPPING_MAPPING_SHEET_REQUEST'
export const APPLY_SHOPPING_MAPPING_SHEET_SUCCESS =
  'APPLY_SHOPPING_MAPPING_SHEET_SUCCESS'
export const APPLY_SHOPPING_MAPPING_SHEET_ERROR =
  'APPLY_SHOPPING_MAPPING_SHEET_ERROR'

const createMappingSheetRequest = () => ({
  type: CREATE_SHOPPING_MAPPING_SHEET_REQUEST
})

const createMappingSheetSuccess = (sheetId, feedUrl) => ({
  type: CREATE_SHOPPING_MAPPING_SHEET_SUCCESS,
  sheetId,
  feedUrl
})

export const createShoppingMappingsSheet = (scenarioId) => (dispatch) => {
  dispatch(createMappingSheetRequest())

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/feed_mappings_sheet/edit`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        createMappingSheetSuccess(response.spreadsheet_id, response.feed_url)
      )
    }
  })
}

export const createShoppingMappingsSheetByInventoryId = (inventoryId) => (
  dispatch
) => {
  dispatch(createMappingSheetRequest())

  return $.ajax({
    method: 'GET',
    url: `/ad_champion/inventories/${inventoryId}/feed_mappings_sheets/edit`,
    dataType: 'json',
    success: (response) => {
      dispatch(
        createMappingSheetSuccess(response.spreadsheet_id, response.feed_url)
      )
    }
  })
}

const applyMappingsSheetRequest = (sheetId) => ({
  type: APPLY_SHOPPING_MAPPING_SHEET_REQUEST,
  sheetId
})

const applyMappingsSheetSuccess = (sheetId) => ({
  type: APPLY_SHOPPING_MAPPING_SHEET_SUCCESS,
  sheetId
})

const applyMappingsSheetFailure = () => ({
  type: APPLY_SHOPPING_MAPPING_SHEET_ERROR
})

export const applyShoppingMappingsSheet = (scenarioId, sheetId) => (
  dispatch
) => {
  dispatch(applyMappingsSheetRequest(sheetId))

  var payload = { spreadsheet_id: sheetId }

  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/feed_mappings_sheet`,
    dataType: 'json',
    data: payload,
    success: (response) => {
      dispatch(applyMappingsSheetSuccess(sheetId))
    }
  })
}

const checkStatus = async (operationId) => {
  var complete = false
  var failed = false
  var response
  while (!complete) {
    await sleep(1000)
    response = await $.ajax({
      url: `/ad_champion/async_operations/${operationId}`
    })

    switch (response.state) {
      case 'Complete':
        complete = true
        break
      case 'Failed':
        failed = true
        complete = true
        break
      default:
    }
  }

  return { failed, message: response.message }
}

export const applyShoppingMappingsSheetByInventoryId = (
  inventoryId,
  sheetId
) => async (dispatch) => {
  dispatch(applyMappingsSheetRequest(sheetId))

  var payload = { spreadsheet_id: sheetId }

  var response = await $.ajax({
    method: 'PUT',
    url: `/ad_champion/inventories/${inventoryId}/feed_mappings_sheets`,
    dataType: 'json',
    data: payload
  })

  var operationId = response.operation_id
  var status = await checkStatus(operationId)

  if (status.failed) {
    dispatch(applyMappingsSheetFailure())
    throw Error(`${status.message}`)
  }

  dispatch(applyMappingsSheetSuccess(sheetId))
}
