import {
  SAVE_KEYWORD_LIST_ITEMS_SUCCESS,
  SAVE_KEYWORD_LIST_ITEMS_ERROR,
  LOAD_KEYWORD_LIST_ITEMS_SUCCESS,
  LOAD_KEYWORD_LIST_ITEMS_ERROR
} from 'actions/keyword_list';

const keywordListReducer = (state = { items: [] }, action) => {
  switch(action.type) {
    case LOAD_KEYWORD_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
        request: null
      };
    case LOAD_KEYWORD_LIST_ITEMS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        request: null
      };
    case SAVE_KEYWORD_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        request: null
      };
    case SAVE_KEYWORD_LIST_ITEMS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        request: null
      }
    default:
      return state;
  }
}

export default keywordListReducer;
