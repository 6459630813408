import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

export class SelectAllRows extends Component {
  static propTypes = {
    metaData: PropTypes.object.isRequired,
    rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSelectAllRows: PropTypes.func.isRequired,
    allRowsSelected: PropTypes.bool.isRequired
  }

  handleSelectAllRows() {
    const { handleSelectAllRows } = this.props
    handleSelectAllRows()
  }

  render() {
    const { allRowsSelected, metaData, rowData } = this.props
    const { totalItems, totalPages, perPage } = metaData

    var show = !allRowsSelected && totalPages > 1 && rowData.length === perPage
    return (
      show && (
        <Button
          tag='span'
          color='link'
          className='btn-column'
          onClick={this.handleSelectAllRows.bind(this)}
        >
          Select All {totalItems}
        </Button>
      )
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllRows)
