import { createSelector } from 'reselect'
import {
  budgetCategoryAdvertisingBudgetsSelector,
  budgetCategoryAdvertisingGoalsSelector
} from 'selectors/advertising'
import { performanceBudgetCategorySelector } from 'selectors/google/performance'
import { budgetCategoryGoalTypeSelector } from 'selectors/advertising_goal_types'
import {
  calculateGoalTargetByGoalType,
  getDataUnit,
  parseTargetsAsFloats,
  selectDataUnit,
  roasActualSelector,
  cpcActualSelector,
  cpaActualSelector
} from './helper'

export const budgetCategoryPerformanceWithGoalAndBudgetSelector = (
  budgetCategoryId
) =>
  createSelector(
    [
      budgetCategoryAdvertisingBudgetsSelector(budgetCategoryId),
      budgetCategoryAdvertisingGoalsSelector(budgetCategoryId),
      performanceBudgetCategorySelector(budgetCategoryId)
    ],
    (budgets, goals, budgetCategoryPerformanceByDay) => {
      const currentBudget = budgets[budgets.length - 1]
      const currentGoal = goals[goals.length - 1]

      var BudgetCategoryPerformanceWithGoalAndBudget = parseTargetsAsFloats(
        budgetCategoryPerformanceByDay.data || [],
        goals,
        'goal',
        currentGoal
      )

      BudgetCategoryPerformanceWithGoalAndBudget = parseTargetsAsFloats(
        BudgetCategoryPerformanceWithGoalAndBudget || [],
        budgets,
        'budget',
        currentBudget
      )

      return BudgetCategoryPerformanceWithGoalAndBudget
    }
  )

export const budgetCategoryCostTargetSelector = (budgetCategoryId) =>
  createSelector(
    [budgetCategoryPerformanceWithGoalAndBudgetSelector(budgetCategoryId)],
    (budgetCategoryPerformanceWithGoalAndBudget) =>
      budgetCategoryPerformanceWithGoalAndBudget.reduce(
        (total, day) => (total += day.budget),
        0
      )
  )

export const budgetCategoryConvValueTotalSelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategoryPerformanceByDay) =>
      (budgetCategoryPerformanceByDay.data || []).reduce(
        (total, day) => (total += day.totalConvValue),
        0
      )
  )

export const budgetCategoryConversionsTotalSelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategoryPerformanceByDay) =>
      (budgetCategoryPerformanceByDay.data || []).reduce(
        (total, day) => (total += day.conversions),
        0
      )
  )

export const budgetCategoryClicksTotalSelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategoryPerformanceByDay) =>
      (budgetCategoryPerformanceByDay.data || []).reduce(
        (total, day) => (total += day.clicks),
        0
      )
  )

export const budgetCategoryGoalTargetSelector = (budgetCategoryId) =>
  createSelector(
    [budgetCategoryPerformanceWithGoalAndBudgetSelector(budgetCategoryId)],
    (budgetCategoryPerformanceWithGoalAndBudget) => {
      var totalGoal = budgetCategoryPerformanceWithGoalAndBudget.reduce(
        (total, day) => (total += day.goal),
        0
      )

      return totalGoal / budgetCategoryPerformanceWithGoalAndBudget.length
    }
  )

export const budgetCategoryCurrencySelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategoryPerformanceByDay) =>
      (budgetCategoryPerformanceByDay.data[0] &&
        budgetCategoryPerformanceByDay.data[0].currency) ||
      '$'
  )

export const budgetCategoryCostActualSelector = (budgetCategoryId) =>
  createSelector(
    [performanceBudgetCategorySelector(budgetCategoryId)],
    (budgetCategoryPerformanceByDay) =>
      (budgetCategoryPerformanceByDay.data || []).reduce(
        (total, day) => (total += day.cost),
        0
      )
  )

export const budgetCategoryGoalActualSelector = (budgetCategoryId) =>
  createSelector(
    [
      budgetCategoryGoalTypeSelector(budgetCategoryId),
      budgetCategoryCostActualSelector(budgetCategoryId),
      budgetCategoryConvValueTotalSelector(budgetCategoryId),
      budgetCategoryClicksTotalSelector(budgetCategoryId),
      budgetCategoryConversionsTotalSelector(budgetCategoryId)
    ],
    (goalType, cost, convValue, clicks, conversions) => {
      switch (goalType) {
        case 'roas':
          return roasActualSelector(cost, convValue)
        case 'cpc':
          return cpcActualSelector(cost, clicks)
        case 'cpa':
          return cpaActualSelector(cost, conversions)
        default:
          return 0
      }
    }
  )
