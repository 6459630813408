import { createPaginator } from 'redux-cached-pagination'
import { normalize, schema } from 'normalizr'
import camelCaseKeys from 'camelcase-keys'
import qs from 'qs'

export const PER_PAGE = 6
const callAdGroupsApi = (page, requestParams) => {
  const { scenarioId } = requestParams
  var queryParam = qs.stringify({
    q: {
      s: 'campaign_generated_name asc',
      generated_name_or_campaign_generated_name_cont: sanitizeSearchParams(
        requestParams.name
      ),
      inventory_disabled_false: 1,
      campaign_inventory_disabled_false: 1,
      campaign_template_disabled_false: 1
    }
  })
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/ad_groups?page=${page}&per_page=${PER_PAGE}&${queryParam}`,
    dataType: 'json'
  }).then(response => {
    return normalizeResponse(camelCaseKeys(response, { deep: true }))
  })
}

const sanitizeSearchParams = searchString => {
  // Replace '.' in search string as Paginator uses '.' to determine cached search results keys
  return searchString
    ? searchString.replace('{DOT_REPLACEMENT}', '.')
    : undefined
}

const adGroup = new schema.Entity('adGroups')
const responseSchema = new schema.Object({
  adGroups: new schema.Array(adGroup)
})

const normalizeResponse = serverResponse => {
  const normalizedData = normalize(serverResponse, responseSchema)
  const notNullEntities = normalizedData.entities.adGroups
    ? normalizedData.entities.adGroups
    : []
  return {
    totalElements: serverResponse.adGroupCount,
    elements: normalizedData.result.adGroups,
    entities: notNullEntities
  }
}

const searchParamsInitState = { scenarioId: 0 }

//default config below
const config = {
  refreshResultInBackground: true,
  timeToRefresh: 60000, //[ms]
  searchHistoryLength: 20,
  elementsPerPage: PER_PAGE
}

export const adGroupsPaginator = createPaginator(
  'adGroups',
  callAdGroupsApi,
  config,
  searchParamsInitState
)

//export used methods
export const loadAdGroupsPage = adGroupsPaginator.requestPage
export const adGroupsReducers = adGroupsPaginator.reducers
export const adGroupsStoreName = adGroupsPaginator.storeName
export const updateAdGroupsSearchParams = adGroupsPaginator.updateSearchParams
export const updateAdGroupsPaginationParams =
  adGroupsPaginator.updatePaginationParams

//selectors:
export const getTotalElements = adGroupsPaginator.selectors.getTotalElements
export const getElementByTotalIndex =
  adGroupsPaginator.selectors.getElementByTotalIndex
export const getPaginationParams =
  adGroupsPaginator.selectors.getPaginationParams
export const getSearchParams = adGroupsPaginator.selectors.getSearchParams
