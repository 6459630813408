import camelCaseKeys from 'camelcase-keys'
import sleep from 'util/sleep'

export const FETCH_KEYWORD_IDEAS_REQUEST = 'FETCH_KEYWORD_IDEAS_REQUEST'
export const FETCH_KEYWORD_IDEAS_RESPONSE = 'FETCH_KEYWORD_IDEAS_RESPONSE'

const fetchKeywordIdeasRequest = (
  websiteId,
  feedId,
  geoTargetId,
  items,
  field
) => ({
  type: FETCH_KEYWORD_IDEAS_REQUEST,
  websiteId,
  feedId,
  geoTargetId,
  items,
  field
})

const fetchKeywordIdeasResponse = (
  websiteId,
  feedId,
  geoTargetId,
  items,
  field,
  ideas
) => ({
  type: FETCH_KEYWORD_IDEAS_RESPONSE,
  websiteId,
  feedId,
  geoTargetId,
  items,
  field,
  ideas
})

const getKeywordIdeasApi = async (websiteId, feedId, geoTargetId, items) => {
  var status = 202
  var statusCode = {
    200: () => {
      status = 200
    },
    202: () => {
      status = 202
    }
  }

  const queryString = `?feed_id=${feedId}&geo_target_id=${geoTargetId}&items=${encodeURIComponent(
    JSON.stringify(items)
  )}`
  const url = `/ad_champion/websites/${websiteId}/search_volume/generate_ideas${queryString}`
  const apiCall = { method: 'GET', url, dataType: 'json', statusCode }
  var response = await $.ajax({ ...apiCall })
  while (status === 202) {
    await sleep(1000)
    response = await $.ajax({ ...apiCall })
  }

  return response
}

export const fetchKeywordIdeas =
  (websiteId, feedId, geoTargetId, items, field) => async (dispatch) => {
    dispatch(
      fetchKeywordIdeasRequest(websiteId, feedId, geoTargetId, items, field)
    )

    const response = await getKeywordIdeasApi(
      websiteId,
      feedId,
      geoTargetId,
      items
    )

    var ideas = camelCaseKeys(JSON.parse(response.ideas), { deep: true })
    dispatch(
      fetchKeywordIdeasResponse(
        websiteId,
        feedId,
        geoTargetId,
        items,
        field,
        ideas
      )
    )
    return ideas
  }
