import QueryString from 'query-string'
import _ from 'lodash'
import { AI_OPTIMISATION_PATH_NAME } from 'components/feed_ops/website/ai_optimisation/container_util'
import { addNewFilters } from 'components/feed_ops/common/popover/popover_util'

export const PAGE_SIZE_OPTIONS = [50, 100, 250, 500]

export const ITEM_ID_HEADER = 'Item ID'
export const TITLE_COLUMN_HEADER = 'Title'
export const DESCRIPTION_COLUMN_HEADER = 'Description'

export const SELECT_ALL_ROWS_ACTION = 'Select All Rows'
export const APPLIED_FILTERS_ACTION = 'Applied Filters'
export const APPLY_FILTERS_ACTION = 'Apply Filters'
export const AI_OPTIMISATION_INFO = 'AI Optimisation Info'
export const CREATE_RULE_ACTION = 'Create Rule'
export const COLUMNS_SELECTION_ACTION = 'Columns'
export const EXPORT_PRODUCT_DATA_ACTION = 'Export'
export const IMPORT_PRODUCT_DATA_ACTION = 'Import'
export const DISABLE_SELECTED_ROWS_ACTION = 'Disable'
export const ENABLE_SELECTED_ROWS_ACTION = 'Enable'
export const EDIT_SELECTED_ROWS_ACTION = 'Edit'
export const APPLY_SUGGESTION_ON_SELECTED_ROWS_ACTION = 'Apply Suggestions'
export const DISMISS_SUGGESTION_ON_SELECTED_ROWS_ACTION = 'Dismiss Suggestions'
export const CLEAR_SELECTED_ROWS_ACTION = 'Clear'
export const APPROVE_REJECT_AI_OPTIMISATION_ACTION =
  'Approve OR Reject AI Optimisation'
export const SINGLE_ROW_DISABLE_ENABLE_ACTION = 'Single Row Disable Enable'

const TOOLTIP_BY_ACTION = {
  [EXPORT_PRODUCT_DATA_ACTION]: 'Export the current view to a Google Sheet',
  [DISABLE_SELECTED_ROWS_ACTION]: 'Pause selected items',
  [ENABLE_SELECTED_ROWS_ACTION]: 'Unpause selected items',
  [CLEAR_SELECTED_ROWS_ACTION]: 'Deselect selected items',
  [SINGLE_ROW_DISABLE_ENABLE_ACTION]: 'getSingleRowDisableEnableTooltip'
}

export const generateTableOptions = (windowHeight, perPage, readOnly) => {
  return {
    idSynonym: 'itemId',
    columnResizable: true,
    debounceInterval: 1000,
    defaultExpanded: true,
    grouping: false,
    maxBodyHeight: `${windowHeight - 400}px`,
    pageSize: perPage,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTitle: false,
    search: false,
    selection: !readOnly,
    tableLayout: 'fixed',
    toolbar: true
  }
}

export const generateTableLocalization = ({ allRowsSelected, metaData }) => {
  return {
    header: {
      actions: ''
    },
    toolbar: {
      nRowsSelected: allRowsSelected
        ? `${metaData.totalItems} selected`
        : '{0} selected'
    }
  }
}

const toolbarActions = (isAIOptimisation) => {
  var actions = [APPLIED_FILTERS_ACTION, APPLY_FILTERS_ACTION]
  if (!isAIOptimisation) {
    actions = [
      ...actions,
      CREATE_RULE_ACTION,
      COLUMNS_SELECTION_ACTION,
      EXPORT_PRODUCT_DATA_ACTION,
      IMPORT_PRODUCT_DATA_ACTION
    ]
  } else {
    actions = [...actions, AI_OPTIMISATION_INFO]
  }

  return actions.map((action) => ({
    actionName: action,
    position: 'toolbar',
    tooltip: TOOLTIP_BY_ACTION[action]
  }))
}

const toolbarOnSelectActions = (isAIOptimisation) => {
  var actions = [SELECT_ALL_ROWS_ACTION, APPROVE_REJECT_AI_OPTIMISATION_ACTION]
  if (!isAIOptimisation) {
    actions = [
      SELECT_ALL_ROWS_ACTION,
      DISABLE_SELECTED_ROWS_ACTION,
      ENABLE_SELECTED_ROWS_ACTION,
      EDIT_SELECTED_ROWS_ACTION,
      APPLY_SUGGESTION_ON_SELECTED_ROWS_ACTION,
      DISMISS_SUGGESTION_ON_SELECTED_ROWS_ACTION,
      CLEAR_SELECTED_ROWS_ACTION,
      COLUMNS_SELECTION_ACTION
    ]
  }

  return actions.map((action) => ({
    actionName: action,
    position: 'toolbarOnSelect',
    tooltip: TOOLTIP_BY_ACTION[action]
  }))
}

const rowActions = (isAIOptimisation) => {
  return isAIOptimisation
    ? []
    : [SINGLE_ROW_DISABLE_ENABLE_ACTION].map((action) => ({
        actionName: action,
        position: 'row',
        tooltip: TOOLTIP_BY_ACTION[action]
      }))
}

export const generateTableActions = (pathLocation) => {
  const { pathname } = pathLocation
  const isAIOptimisation = (pathname || '').includes(AI_OPTIMISATION_PATH_NAME)
  return [
    ...toolbarActions(isAIOptimisation),
    ...toolbarOnSelectActions(isAIOptimisation),
    ...rowActions(isAIOptimisation)
  ]
}

export const getSingleRowDisableEnableTooltip = (
  readOnly,
  updatedDisabledItems,
  rowData
) => {
  const itemDisabled =
    updatedDisabledItems[rowData.itemId] === undefined
      ? rowData.itemDisabled
      : updatedDisabledItems[rowData.itemId]
  var tooltip

  if (readOnly) {
    tooltip = 'View only.'
  } else if (itemDisabled) {
    tooltip = 'This item will be excluded from all feeds. Click to include.'
  } else {
    tooltip = 'This item will be included in feeds. Click to exclude.'
  }

  return tooltip
}

export const getQueryStringColumns = (pathLocation) => {
  const { search } = pathLocation
  const qsAttributes = QueryString.parse(search)
  const { columns } = qsAttributes

  if (columns) {
    return _.uniq([ITEM_ID_HEADER, TITLE_COLUMN_HEADER, ...columns.split(',')])
  }
  return null
}

export const getQueryStringFilters = (pathLocation) => {
  const { search } = pathLocation
  const qsAttributes = QueryString.parse(search)
  const { filter_attribute_name, filter_type, filter_values } = qsAttributes

  if (filter_attribute_name && filter_type) {
    return addNewFilters(
      [],
      filter_attribute_name,
      filter_type,
      !_.isEmpty(filter_values) ? [filter_values] : []
    )
  }
  return null
}

export const getQueryStringBatchIds = (pathLocation) => {
  const { search } = pathLocation
  const qsAttributes = QueryString.parse(search)
  const { batch_ids } = qsAttributes

  if (batch_ids) {
    return _.uniq(batch_ids.split(','))
  }
  return null
}

export const generateKeywordIdeasTableOptions = () => {
  return {
    idSynonym: 'keyword',
    columnResizable: false,
    debounceInterval: 1000,
    defaultExpanded: true,
    draggable: false,
    grouping: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 15, 20],
    showTitle: false,
    search: false,
    toolbar: false
  }
}
