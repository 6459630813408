import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import PopoverContainer from './popover_container'
import {
  RULE_CONDTIONS_NAME,
  conditionsToFilters,
  filtersToConditions
} from './rules_engine_util'
import { websiteAllAttributesSelector } from 'selectors/product_feeds/replacements'
import { valueOperatorsSelector } from 'selectors/value/operators'

import './conditions_container.scss'

export class ConditionsContainer extends Component {
  static propTypes = {
    conditions: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    valueOperators: PropTypes.object.isRequired,
    handleConditionsChange: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = { conditionFilters: [] }
  }

  computeConditionFilters() {
    const { conditions, availableAttributes, valueOperators } = this.props
    const conditionFilters =
      conditions && conditions.length > 0
        ? conditionsToFilters(conditions, availableAttributes, valueOperators)
        : []

    this.setState({ conditionFilters })
  }

  componentDidMount() {
    this.computeConditionFilters()
  }

  componentDidUpdate(prevProps) {
    const { conditions } = this.props
    if (conditions !== prevProps.conditions) {
      this.computeConditionFilters()
    }
  }

  computeConditionsChange(updatedRuleConditions) {
    const {
      conditions,
      availableAttributes,
      valueOperators,
      handleConditionsChange
    } = this.props

    const updatedConditions =
      updatedRuleConditions.length > 0
        ? filtersToConditions(
            conditions,
            updatedRuleConditions,
            availableAttributes,
            valueOperators
          )
        : []
    handleConditionsChange(updatedConditions)
  }

  render() {
    const { conditionFilters } = this.state

    return (
      <div className='conditions-container'>
        <PopoverContainer
          actionName={RULE_CONDTIONS_NAME}
          appliedFilters={conditionFilters}
          computeRulesChange={this.computeConditionsChange.bind(this)}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  availableAttributes: websiteAllAttributesSelector(state, props),
  valueOperators: valueOperatorsSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConditionsContainer)
)
