import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, registerField, getFormValues, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact'
import ModalBody from './column_selection_modal/modal_body'

export class ColumnSelectionModal extends Component {
  static propTypes = {
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleColumnsConfigChange: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      defaultSelectedColumns: [],
      selectedColumns: []
    }
  }

  componentDidMount() {
    const { selectedColumns, initialize } = this.props
    this.setState({ defaultSelectedColumns: selectedColumns })
    registerField(REDUX_FORM_NAME, 'selectedColumns', 'FieldArray')
    initialize({ selectedColumns })
  }

  componentDidUpdate(prevProps) {
    const { selectedColumns, initialize } = this.props
    if (!_.isEqual(prevProps.selectedColumns, selectedColumns)) {
      this.setState({ defaultSelectedColumns: selectedColumns })
      registerField(REDUX_FORM_NAME, 'selectedColumns', 'FieldArray')
      initialize({ selectedColumns })
    }
  }

  toggleModal() {
    const { formValues } = this.props
    const { selectedColumns } = formValues

    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedColumns: selectedColumns
    })
  }

  renderColumnSelectionBtn() {
    return (
      <MDBBtn
        id='open-column-selection-btn'
        color='link'
        className='btn-column'
        onClick={this.toggleModal.bind(this)}
        tag='span'
      >
        <MDBIcon icon={'columns'} />
        Columns
      </MDBBtn>
    )
  }

  handleCancelClick() {
    const { change } = this.props
    const { selectedColumns } = this.state

    change('selectedColumns', selectedColumns)
    this.toggleModal()
  }

  handleAddAllColumnsClick(filteredColumns) {
    const { formValues, change } = this.props
    const { selectedColumns } = formValues
    var updatedSelectedColumns = [...selectedColumns]
    updatedSelectedColumns.push(
      filteredColumns.map((column) => column.columnName)
    )

    change('selectedColumns', _.uniq(updatedSelectedColumns.flat()))
  }

  handleRemoveAllColumnsClick() {
    const { change } = this.props
    change('selectedColumns', ['Item ID'])
  }

  handleAddRemoveColumnClick(columnName, action) {
    const { formValues, change } = this.props
    const { selectedColumns } = formValues
    var updatedSelectedColumns = [...selectedColumns]
    action === 'Add'
      ? updatedSelectedColumns.push(columnName)
      : updatedSelectedColumns.splice(
          updatedSelectedColumns.indexOf(columnName),
          1
        )

    change('selectedColumns', updatedSelectedColumns)
  }

  handleColumnOrderChange(direction, columnName) {
    const { formValues, change } = this.props
    const { selectedColumns } = formValues
    var updatedSelectedColumns = [...selectedColumns]
    const currentIndex = updatedSelectedColumns.indexOf(columnName)
    const updatedIndex =
      direction === 'up' ? currentIndex - 1 : currentIndex + 1
    updatedSelectedColumns.splice(
      updatedIndex,
      0,
      updatedSelectedColumns.splice(currentIndex, 1)[0]
    )

    change('selectedColumns', updatedSelectedColumns)
  }

  renderModalBody() {
    const { formValues, availableColumns } = this.props
    const { selectedColumns } = formValues

    return (
      <MDBModalBody>
        <ModalBody
          selectedColumns={selectedColumns}
          availableColumns={availableColumns}
          handleAddAllColumnsClick={this.handleAddAllColumnsClick.bind(this)}
          handleRemoveAllColumnsClick={this.handleRemoveAllColumnsClick.bind(
            this
          )}
          handleAddRemoveColumnClick={this.handleAddRemoveColumnClick.bind(
            this
          )}
          handleColumnOrderChange={this.handleColumnOrderChange.bind(this)}
        />
      </MDBModalBody>
    )
  }

  handleSaveClick() {
    const { handleColumnsConfigChange, formValues } = this.props
    const { selectedColumns } = formValues
    handleColumnsConfigChange(selectedColumns)
    this.toggleModal()
  }

  renderModalFooter() {
    return (
      <MDBModalFooter className='common-modal-footer'>
        <MDBBtn
          id='cancel-column-selection-btn'
          onClick={this.handleCancelClick.bind(this)}
          tag='span'
          color='dark'
          outline
        >
          Cancel
        </MDBBtn>
        <MDBBtn
          id='save-column-selection-btn'
          onClick={this.handleSaveClick.bind(this)}
          tag='span'
          color='primary'
        >
          Save
        </MDBBtn>
      </MDBModalFooter>
    )
  }

  render() {
    const { isModalOpen } = this.state

    return (
      <div className='column-selection-container'>
        {this.renderColumnSelectionBtn()}
        <MDBModal
          isOpen={isModalOpen}
          className='modal-xl'
          toggle={this.toggleModal.bind(this)}
        >
          <MDBModalHeader>Select columns to display</MDBModalHeader>
          {this.renderModalBody()}
          {this.renderModalFooter()}
        </MDBModal>
      </div>
    )
  }
}

export const REDUX_FORM_NAME = 'ProductLevelOptimiserColumnSelectionModal'

export const mapStateToProps = (state, _props) => {
  return {
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {
      selectedColumns: []
    }
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ registerField, change }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: REDUX_FORM_NAME
  })(ColumnSelectionModal)
)
