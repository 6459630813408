import { FETCH_VERTICAL_FIELDS_RESPONSE } from 'actions/vertical/fields'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VERTICAL_FIELDS_RESPONSE:
      const fields = action.fields.reduce(
        (index, field) => ({
          ...index,
          [field.id]: field
        }),
        {}
      )
      return {
        ...state,
        ...fields
      }

    default:
      return state
  }
}
