import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { editorPropTypes } from './common'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBIcon,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact'

export default class AttributeEnumDropdown extends Component {
  static propTypes = {
    ...editorPropTypes,
    enumValues: PropTypes.array.isRequired
  }

  handleEnumValueChange(enumValue) {
    const { onChange } = this.props
    onChange(enumValue)
  }

  renderDropdownMenu() {
    const { enumValues } = this.props

    return (
      <MDBDropdownMenu>
        {enumValues.map((enumValue, fieldIndex) => (
          <MDBDropdownItem
            key={fieldIndex}
            tag='span'
            onClick={this.handleEnumValueChange.bind(this, enumValue)}
          >
            {enumValue}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    )
  }

  render() {
    const { value } = this.props

    return (
      <div className='attribute-enum-dropdown'>
        <MDBDropdown tag='span' placement='bottom-end'>
          <MDBDropdownToggle tag='span'>
            {value}{' '}
            {
              <MDBIcon
                className={value.length > 0 ? 'value-padding' : null}
                size='lg'
                icon='caret-down'
              />
            }
          </MDBDropdownToggle>
          {this.renderDropdownMenu()}
        </MDBDropdown>
      </div>
    )
  }
}
