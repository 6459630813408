import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MDBRow, MDBCol } from 'mdbreact'
import changeCase from 'change-case'
import TitleDescriptionBuilder from 'components/feed_ops/website/product_optimisations/common/title_description_builder'
import { websiteProductTitlesAllAvailableAttributesSelector } from 'selectors/product_feeds/titles'
import { websiteIdSelector } from 'selectors/websites'
import { templateToFieldArray, fieldArrayToTemplate } from 'util/format_string'

export const TEMPLATE_TYPE_TITLE = 'title'
export const TEMPLATE_TYPE_DESCRIPTION = 'description'
export class TemplateRow extends Component {
  static propTypes = {
    template: PropTypes.string.isRequired,
    onTemplateChange: PropTypes.func.isRequired,
    templateType: PropTypes.oneOf([
      TEMPLATE_TYPE_TITLE,
      TEMPLATE_TYPE_DESCRIPTION
    ]).isRequired,
    allAvailableFields: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  handleTemplateFieldsChanged(newFields) {
    const { onTemplateChange, allAvailableFields } = this.props
    onTemplateChange(fieldArrayToTemplate(newFields, allAvailableFields))
  }

  renderBuilder() {
    const { template, templateType, allAvailableFields } = this.props
    return (
      <TitleDescriptionBuilder
        defaultFields={templateToFieldArray(
          templateType === TEMPLATE_TYPE_TITLE ? '{Title}' : '{Description}',
          allAvailableFields
        )}
        templateFields={templateToFieldArray(template, allAvailableFields)}
        onTemplateFieldsChanged={this.handleTemplateFieldsChanged.bind(this)}
      />
    )
  }

  render() {
    const { templateType } = this.props
    return (
      <>
        <MDBRow className='form-input-row'>
          <MDBCol className='label-column'>
            Default {changeCase.titleCase(templateType)} Template:
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className='input-column'>{this.renderBuilder()}</MDBCol>
        </MDBRow>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const websiteId = websiteIdSelector(state, props)
  return {
    allAvailableFields:
      websiteProductTitlesAllAvailableAttributesSelector(websiteId)(state)
  }
}

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateRow)
)
