import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import QueryString from 'query-string'
import PlatformSignUpCommon from './common'
import FeedopsFeatures from 'components/feed_ops/common/feedops_features'
import { loggedInSelector } from 'selectors/user'
import { appIdSelector, isEmbeddedAppSelector } from 'selectors/app'

export class PlatformSignUpMobile extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired
  }

  handleRedirect() {
    const { isEmbeddedApp, location } = this.props
    if (isEmbeddedApp) {
      const { appId } = this.props
      this.props.history.push(appId, {})
    } else {
      const appId = QueryString.parse(location.search).app_id || 'feed_ops'
      window.open(`/${appId}`, '_self')
    }
  }

  renderCard() {
    return <PlatformSignUpCommon onComplete={this.handleRedirect.bind(this)} />
  }

  render() {
    const { loggedIn } = this.props

    return (
      <>
        <div
          className={`sign-up-mobile-container ${
            loggedIn && 'full-screen-form'
          }`}
        >
          {this.renderCard()}
        </div>
        <FeedopsFeatures />
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  loggedIn: loggedInSelector(state),
  appId: appIdSelector(state, props),
  isEmbeddedApp: isEmbeddedAppSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlatformSignUpMobile)
)
