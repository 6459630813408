import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBInput } from 'mdbreact'
import { setWebsiteWatching, fetchWebsiteDetail } from 'actions/websites'
import { workEmailSelector } from 'selectors/user'
import { websiteIdSelector, websiteWatchingSelector } from 'selectors/websites'

export class FeedOpsNotificationsSettings extends Component {
  static propTypes = {
    websiteId: PropTypes.number.isRequired,
    watching: PropTypes.bool.isRequired,
    workEmail: PropTypes.string.isRequired,
    setWebsiteWatching: PropTypes.func.isRequired,
    fetchWebsiteDetail: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      watching: false
    }
  }

  componentDidMount() {
    const { watching } = this.props
    this.setState({ watching })
  }

  componentDidUpdate(prevProps) {
    const { watching } = this.props

    if (watching !== prevProps.watching) {
      this.setState({ watching })
    }
  }

  async handleChange() {
    const { setWebsiteWatching, websiteId, fetchWebsiteDetail } = this.props
    const { watching } = this.state

    this.setState({ watching: !watching })
    await setWebsiteWatching(websiteId, !watching)
    fetchWebsiteDetail(websiteId)
  }

  renderHeaderRow() {
    return (
      <MDBRow>
        <MDBCol>
          <strong>Work email</strong>
        </MDBCol>
        <MDBCol>
          <strong>Notification</strong>
        </MDBCol>
        <MDBCol size='2'>
          <strong>Subscribed</strong>
        </MDBCol>
      </MDBRow>
    )
  }

  renderContentRow() {
    const { workEmail } = this.props
    const { watching } = this.state

    return (
      <MDBRow>
        <MDBCol>{workEmail}</MDBCol>
        <MDBCol>Google Shopping Ads Performance</MDBCol>
        <MDBCol size='2' onClick={this.handleChange.bind(this)}>
          <MDBInput checked={watching} type='checkbox' />
        </MDBCol>
      </MDBRow>
    )
  }

  render() {
    return (
      <MDBCard className='feedops-notifications-card'>
        <MDBCardBody>
          {this.renderHeaderRow()}
          {this.renderContentRow()}
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    websiteId: websiteIdSelector(state, props),
    watching: websiteWatchingSelector(state, props),
    workEmail: workEmailSelector(state)
  }
}

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setWebsiteWatching, fetchWebsiteDetail }, dispatch)

const FeedOpsNotificationsSettingsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedOpsNotificationsSettings)

export default withRouter(FeedOpsNotificationsSettingsWithRedux)
