import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { STATIC_VALUE_CATEGORY } from 'components/feed_ops/website/store_connection/attribute_mapping/attribute_mapping_popover'
import { MDBRow, MDBCol, MDBInput, MDBIcon } from 'mdbreact'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AttributeStaticValues from './attribute_static_values'
import AttributeListItem from './attribute_list_item'

export default class AttributePopoverContainer extends Component {
  static propTypes = {
    availableAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
    categoryNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentMapping: PropTypes.object,
    handleSelectedAttributeChange: PropTypes.func.isRequired,
    handleStaticValueChange: PropTypes.func
  }

  constructor() {
    super()
    this.state = { searchTerm: '' }
  }

  handleSearchTermChanged(event) {
    this.setState({ searchTerm: event.target.value })
  }

  renderAttributeSearchBox() {
    const { searchTerm } = this.state

    return (
      <>
        <MDBCol size='10'>
          <MDBInput
            placeholder={`Search Fields`}
            value={searchTerm}
            onChange={this.handleSearchTermChanged.bind(this)}
          />
        </MDBCol>
        <MDBCol size='2'>
          <div className='float-right'>
            <MDBIcon icon={'search'} />
          </div>
        </MDBCol>
      </>
    )
  }

  handleAttributeItemClick(selectedAttribute) {
    const { handleSelectedAttributeChange } = this.props
    handleSelectedAttributeChange(selectedAttribute)
  }

  renderSearchableAttributes() {
    const { availableAttributes } = this.props
    const { searchTerm } = this.state
    const searchedAttributes = availableAttributes.filter((attribute) =>
      attribute.columnName.toLowerCase().match(searchTerm.toLowerCase())
    )

    return (
      <AttributeListItem
        attributes={searchedAttributes}
        handleAttributeItemClick={this.handleAttributeItemClick.bind(this)}
      />
    )
  }

  handleStaticValueChange(value) {
    const { handleStaticValueChange } = this.props
    handleStaticValueChange(value)
  }

  renderStaticValueAccordianDetails() {
    const { currentMapping } = this.props
    const { sourceId } = currentMapping
    var staticValues = []
    if (sourceId && _.isString(sourceId) && !sourceId.includes('@o')) {
      staticValues = sourceId.split('@')
    }

    return (
      <AttributeStaticValues
        value={staticValues[0] || ''}
        handleStaticValueChange={this.handleStaticValueChange.bind(this)}
      />
    )
  }

  renderCategoryAccordianDetails(category) {
    if (category === STATIC_VALUE_CATEGORY) {
      return this.renderStaticValueAccordianDetails()
    }

    const { availableAttributes } = this.props
    const categoryAttributes = availableAttributes.filter(
      (attribute) => attribute.columnCategoryName === category
    )

    return (
      <AttributeListItem
        attributes={categoryAttributes}
        handleAttributeItemClick={this.handleAttributeItemClick.bind(this)}
      />
    )
  }

  renderCategoryAttributes() {
    const { categoryNames } = this.props
    var categoryAccordians = []
    categoryNames.forEach((category) => {
      categoryAccordians.push(
        <Accordion key={category}>
          <AccordionSummary id={category} expandIcon={<ExpandMoreIcon />}>
            {category}
          </AccordionSummary>
          <AccordionDetails>
            {this.renderCategoryAccordianDetails(category)}
          </AccordionDetails>
        </Accordion>
      )
    })

    return <>{categoryAccordians}</>
  }

  render() {
    const { searchTerm } = this.state

    return (
      <>
        <MDBRow className='search-attributes-row'>
          {this.renderAttributeSearchBox()}
        </MDBRow>
        <MDBRow className='select-attributes-row'>
          <MDBCol>
            {searchTerm && searchTerm.length > 0
              ? this.renderSearchableAttributes()
              : this.renderCategoryAttributes()}
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}
