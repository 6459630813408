import { LIST_MICROSOFT_ADS_ACCOUNTS_RESPONSE } from 'actions/microsoft/adwords'

const initialState = {
  accounts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_MICROSOFT_ADS_ACCOUNTS_RESPONSE:
      return { ...state, accounts: action.accounts }

    default:
      return state
  }
}
