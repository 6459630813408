import { submit } from 'redux-form'

const pendingFormSubmissions = {}

const resolveFormSubmission = (form, response) => {
  var promise = pendingFormSubmissions[form]
  if (promise) {
    promise.resolve(response)
  }
}

const rejectFormSubmission = (form, result) => {
  var promise = pendingFormSubmissions[form]
  if (promise) {
    promise.reject(result)
  }
}

export const remoteSubmitAndWait = (form) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (pendingFormSubmissions[form]) {
      pendingFormSubmissions[form].reject()
    }

    pendingFormSubmissions[form] = { resolve, reject }
    dispatch(submit(form))
  })
}

export const remoteFormSubmissionHandlers = (form) => ({
  onSubmitSuccess: (result) => resolveFormSubmission(form, result),
  onSubmitFail: (result) => rejectFormSubmission(form, result)
})
