import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'
import IconButton from 'components/ad_champion/common/icon_button'

export const cardTitle = 'Recommendation'

export class FeedOpsDetailChartRecommendations extends Component {
  static propTypes = {
    recommendation: PropTypes.object.isRequired,
    tooltipText: PropTypes.string.isRequired
  }

  renderTooltip() {
    const { tooltipText } = this.props
    return (
      <IconButton
        iconClass={'fa fa-question-circle'}
        tooltipText={tooltipText}
      />
    )
  }

  render() {
    const { recommendation, tooltipText } = this.props
    if (recommendation && Object.keys(recommendation).length > 0) {
      return (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{cardTitle}</MDBCardTitle>
            <div className='content'>
              {recommendation.detail}{' '}
              {tooltipText && tooltipText.length > 0 && this.renderTooltip()}
            </div>
          </MDBCardBody>
        </MDBCard>
      )
    } else {
      return null
    }
  }
}

export default withRouter(FeedOpsDetailChartRecommendations)
