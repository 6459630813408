import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const SELECT_SCENARIO = 'SELECT_SCENARIO'
export const UPDATE_BID_SETTING_REQUEST = 'UPDATE_BID_SETTING_REQUEST'
export const UPDATE_BID_SETTING_SUCCESS = 'UPDATE_BID_SETTING_SUCCESS'
export const UPDATE_BID_SETTING_ERROR = 'UPDATE_BID_SETTING_ERROR'
export const FETCH_SCENARIO_STATUS_REQUEST = 'FETCH_SCENARIO_STATUS_REQUEST'
export const FETCH_SCENARIO_STATUS_SUCCESS = 'FETCH_SCENARIO_STATUS_SUCCESS'
export const FETCH_SCENARIO_STATUS_ERROR = 'FETCH_SCENARIO_STATUS_ERROR'

export const selectScenario = id => ({
  type: SELECT_SCENARIO,
  id
});

export const fetchScenarioStatus = scenarioId => dispatch => {
  dispatch(requestScenarioStatus());
  return $.ajax({
    method: 'GET',
    url: `/ad_champion/scenarios/${scenarioId}/status`,
    dataType: 'json',
    success: (response) => {
      dispatch(receiveScenarioStatusSuccess(camelCaseKeys(response, {deep: true})));
    },
    error: (response) => {
      dispatch(receiveScenarioStatusError());
    }
  });
}

export const updateScenarioSettings = (scenarioId, settings) => dispatch => {
  var payload = { buildSettings: settings }
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/update_settings`,
    dataType: 'json',
    data: snakeCaseKeys(payload, { deep: true }),
    success: (response) => {
      dispatch(receiveScenarioStatusSuccess(camelCaseKeys(response, {deep: true})));
    },
    error: (response) => {
      dispatch(receiveScenarioStatusError());
    }
  });
}

export const updateBidSettings = (scenarioId, setting) => dispatch => {
  dispatch(updateBidSettingRequest())
  return $.ajax({
    method: 'PUT',
    url: `/ad_champion/scenarios/${scenarioId}/settings`,
    dataType: 'json',
    data: snakeCaseKeys(setting),
    success: (response) => {
      dispatch(updateBidSettingSuccess());
      dispatch(receiveScenarioStatusSuccess(camelCaseKeys(response, {deep: true})));
    },
    error: (response) => {
      dispatch(updateBidSettingError())
    }
  });
}

export const publishScenario = (websiteId , data) => dispatch => {
  return $.ajax({
    method: "POST",
    url: `/ad_champion/shopping/websites/${websiteId}/publication`,
    dataType: 'json',
    data: snakeCaseKeys(data),
    error: (response) => { 
      dispatch(responseError(camelCaseKeys(response, {deep: true})));
    }
  });
}

export const generateScenario = (scenarioId) => dispatch => {
  return $.ajax({
    method: "POST",
    url: `/ad_champion/scenarios/${scenarioId}/trigger_generation`,
    dataType: 'json'
  });
}

export const buildScenario = (scenarioId) => dispatch => {
  return $.ajax({
    method: "POST",
    url: `/ad_champion/scenarios/${scenarioId}/trigger_build`,
    dataType: 'json'
  });
}

export const generateScenariobyBuildId = (buildId) => dispatch => {
  return $.ajax({
    method: "POST",
    url: `/ad_champion/builds/${buildId}/trigger_generation`,
    dataType: 'json'
  });
}

const updateBidSettingRequest = () =>({
  type: UPDATE_BID_SETTING_REQUEST
})

const updateBidSettingSuccess = () =>({
  type: UPDATE_BID_SETTING_SUCCESS
})

const updateBidSettingError = () =>({
  type: UPDATE_BID_SETTING_ERROR
})

const requestScenarioStatus = () => ({
  type: FETCH_SCENARIO_STATUS_REQUEST
});

const receiveScenarioStatusSuccess = (status) => ({
  type: FETCH_SCENARIO_STATUS_SUCCESS,
  status
});

const receiveScenarioStatusError = () => ({
  type: FETCH_SCENARIO_STATUS_ERROR
});
