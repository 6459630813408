import { FETCH_ACCOUNT_USERS_RESPONSE } from 'actions/accounts'

const initialState = {}

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_USERS_RESPONSE:
      return {
        ...state,
        [action.accountId]: {
          ...state[action.accountId],
          users: action.accountUsers
        }
      }
    default:
      return state
  }
}

export default accountsReducer
