import { LIST_FEEDS_RESPONSE, GET_FEED_FIELDS_RESPONSE, GET_FEED_RESPONSE } from 'actions/feed_app/api'
import arrayToObject from 'util/array_to_object'

const initialState = { feeds: {}, fields: {} }
export default (state = initialState, action) => {
  switch(action.type) {
    case LIST_FEEDS_RESPONSE:
      return { ...state, feeds: arrayToObject('id', action.feeds) }
    case GET_FEED_FIELDS_RESPONSE:
      return { ...state, fields: action.payload }
    case GET_FEED_RESPONSE:
      return { ...state, feeds: { ...state.feeds, [action.id]: { ...action.feed }}}
    default:
      return state
  }
}