import camelCaseKeys from 'camelcase-keys'

export const FETCH_VERTICAL_FIELDS_REQUEST = 'FETCH_VERTICAL_FIELDS_REQUEST'
export const FETCH_VERTICAL_FIELDS_RESPONSE = 'FETCH_VERTICAL_FIELDS_RESPONSE'

const fetchVerticalFieldsRequest = () => ({
  type: FETCH_VERTICAL_FIELDS_REQUEST
})

const fetchVerticalFieldsResponse = (response) => ({
  type: FETCH_VERTICAL_FIELDS_RESPONSE,
  ...response
})

export const fetchVerticalFields = () => async (dispatch) => {
  dispatch(fetchVerticalFieldsRequest())
  var response = await $.ajax({
    method: 'GET',
    url: '/ad_champion/vertical/fields',
    dataType: 'json'
  })

  response = camelCaseKeys(response, { deep: true })
  dispatch(fetchVerticalFieldsResponse(response))
  return response
}
