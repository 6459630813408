import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { Field, Form, getFormValues, reduxForm } from 'redux-form'
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from 'mdbreact'
import { checkPublicationAccountTypes } from 'actions/advertising'
import { setChannelFilterModalOpen } from 'actions/app'
import { channelFilterModalOpenSelector, appIdSelector } from 'selectors/app'
import { channelSelector } from 'selectors/channel'
import { accountTypeIdSelector, accountTypeSelector } from 'selectors/user'
import {
  websiteFeedPerChannelSelector,
  websiteIdSelector
} from 'selectors/websites'
import {
  hasGooglePublicationAccountSelector,
  hasMicrosoftPublicationAccountSelector
} from 'selectors/advertising'
import { setBookAMeetingModalOpen } from 'actions/app'

export class ChannelFilterModal extends Component {
  static propTypes = {
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    channel: PropTypes.string.isRequired,
    channelFilterModalOpen: PropTypes.bool.isRequired,
    setChannelFilterModalOpen: PropTypes.func.isRequired,
    hasGooglePublicationAccount: PropTypes.bool.isRequired,
    hasMicrosoftPublicationAccount: PropTypes.bool.isRequired,
    appId: PropTypes.string.isRequired,
    websiteId: PropTypes.number.isRequired,
    accountType: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    checkPublicationAccountTypes: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { initialize, channel } = this.props
    initialize({ channel })
    this.checkPublicationAccountTypes()
  }

  componentDidUpdate(prevProps) {
    const { initialize, channelFilterModalOpen, channel } = this.props
    if (
      prevProps.channelFilterModalOpen !== channelFilterModalOpen ||
      prevProps.channel !== channel
    ) {
      initialize({ channel })
    }
    const { accountType, id } = this.props
    if (
      accountType !== prevProps.accountType ||
      (!isNaN(id) && id !== prevProps.id)
    ) {
      this.checkPublicationAccountTypes()
    }
  }

  async checkPublicationAccountTypes() {
    const { appId, checkPublicationAccountTypes, accountType, id } = this.props
    if (appId && appId !== process.env.FEEDOPS_APP_ID && id > 0) {
      await checkPublicationAccountTypes(accountType, id)
    }
  }

  closeModal(submitOnClose) {
    const { setChannelFilterModalOpen, submit } = this.props
    setChannelFilterModalOpen(false)
    submitOnClose && submit()
  }

  handleSubmit(values, _dispatch, _props) {
    const { history } = this.props
    return history.push(
      `${history.location.pathname}?channel=${values.channel}`
    )
  }

  isDisabled(label) {
    const {
      hasGooglePublicationAccount,
      hasMicrosoftPublicationAccount,
      location,
      feedPerChannel
    } = this.props

    var values = location.pathname.includes('cost_and_goal')
      ? {
          google: !hasGooglePublicationAccount,
          microsoft: !hasMicrosoftPublicationAccount
        }
      : {
          google: !_.isInteger(feedPerChannel.google),
          facebook: !_.isInteger(feedPerChannel.facebook),
          other: false
        }
    return values[label.toLowerCase()] === false ? false : true
  }

  gotoNewFeedCreation(channel) {
    const { setBookAMeetingModalOpen } = this.props
    if (channel === 'Other') {
      setBookAMeetingModalOpen(true, 'feedops')
    } else {
      const { history, appId, websiteId } = this.props
      history.push(
        `/${appId}/${websiteId}/new?channel=${channel.toLowerCase()}`
      )
    }
    this.closeModal(false)
  }

  renderAddChannels(labels) {
    var values = _.difference(['Google', 'Facebook', 'Other'], labels)
    return (
      <div className='form-check'>
        {values.map((label, index) => (
          <div key={index}>
            <MDBIcon
              icon={'plus'}
              onClick={this.gotoNewFeedCreation.bind(this, label)}
            />{' '}
            <span>{label}</span>
          </div>
        ))}
      </div>
    )
  }

  getAvailableLabels() {
    const { location, feedPerChannel } = this.props
    var labels = []
    if (location.pathname.includes('cost_and_goal')) {
      labels = labels.concat(['Google', 'Microsoft'])
    } else {
      this.addLabelValue(feedPerChannel.google, 'Google', labels)
      this.addLabelValue(feedPerChannel.facebook, 'Facebook', labels)
    }
    return labels
  }

  addLabelValue(feedId, vendor, labels) {
    if (_.isInteger(feedId)) labels.push(vendor)
  }

  renderForm() {
    const { handleSubmit, formValues, location } = this.props
    var labels = this.getAvailableLabels()

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
        <Field
          name='channel'
          labels={labels}
          type='radio'
          component={({ input, labels }) => (
            <div>
              {labels.map((label, index) => (
                <MDBInput
                  onClick={() => input.onChange(label.toLowerCase())}
                  checked={formValues.channel === label.toLowerCase()}
                  label={label}
                  type='radio'
                  id={`radio${index}`}
                  key={`radio${index}`}
                  disabled={this.isDisabled(label)}
                  gap
                />
              ))}
            </div>
          )}
        />
        {!location.pathname.includes('cost_and_goal') &&
          this.renderAddChannels(labels)}
      </Form>
    )
  }

  render() {
    const { channelFilterModalOpen } = this.props
    return (
      <MDBModal
        className={'channel-filter-modal'}
        centered={true}
        isOpen={channelFilterModalOpen}
        toggle={this.closeModal.bind(this, false)}
      >
        <MDBModalHeader>Channel</MDBModalHeader>
        <MDBModalBody>{this.renderForm()}</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn size='sm' onClick={this.closeModal.bind(this, false)} outline>
            Cancel
          </MDBBtn>
          <MDBBtn size='sm' onClick={this.closeModal.bind(this, true)}>
            Apply
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

export const REDUX_FORM_NAME = 'ChannelFilterModalForm'

const ChannelFilterModalForm = reduxForm({
  form: REDUX_FORM_NAME
})(ChannelFilterModal)

const mapStateToProps = (state, props) => {
  const accountType = accountTypeSelector(state, props)
  const id = accountTypeIdSelector(state, props)
  return {
    appId: appIdSelector(state, props),
    formValues: getFormValues(REDUX_FORM_NAME)(state) || {},
    channel: channelSelector(state, props),
    channelFilterModalOpen: channelFilterModalOpenSelector(state),
    hasGooglePublicationAccount: hasGooglePublicationAccountSelector(
      accountType,
      id
    )(state, props),
    hasMicrosoftPublicationAccount: hasMicrosoftPublicationAccountSelector(
      accountType,
      id
    )(state, props),
    websiteId: websiteIdSelector(state, props),
    feedPerChannel: websiteFeedPerChannelSelector(state, props),
    accountType,
    id
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setChannelFilterModalOpen,
      setBookAMeetingModalOpen,
      checkPublicationAccountTypes
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChannelFilterModalForm)
)
