import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import changeCase from 'change-case'
import { Row, Col, Button } from 'reactstrap'
import { MDBSwitch } from 'mdbreact'
import FilterPopoverHeader from 'components/feed_ops/common/popover/filter_popover_header'
import FilterOptionsDropdown from 'components/feed_ops/common/popover/filter_options_dropdown'
import ActionFilterPopoverValues from './action_filter_popover/filter_values'
import { getSelectedFilterOptionAndValues } from 'components/feed_ops/common/popover/popover_util'
import { SET_ATTRIBUTE_ACTION_NAME } from './rules_engine_util'
import { actionTypesSelector } from 'selectors/inventories/rules'

export class ActionFilterPopoverContainer extends Component {
  static propTypes = {
    selectedAttribute: PropTypes.object.isRequired,
    appliedFilter: PropTypes.object,
    ruleActionTypes: PropTypes.object.isRequired,
    handleClosePopover: PropTypes.func,
    handleApplyFilterClick: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      selectedFilterOption: '',
      value: '',
      showValidationErrors: false,
      replaceManual: false
    }
  }

  computeFilterOptions() {
    const { ruleActionTypes } = this.props
    return Object.values(ruleActionTypes).map((at) =>
      changeCase.lowerCase(at.displayName)
    )
  }

  componentDidMount() {
    const { appliedFilter, selectedAttribute } = this.props
    const { metaData } = appliedFilter ? appliedFilter : {}
    const replaceManual = metaData ? metaData.replaceManual : false
    const updatedStateValues = getSelectedFilterOptionAndValues(
      appliedFilter,
      selectedAttribute
    )
    const availableFilterOptions = this.computeFilterOptions()

    this.setState({
      selectedFilterOption: availableFilterOptions.includes(
        updatedStateValues.selectedFilterOption
      )
        ? updatedStateValues.selectedFilterOption
        : availableFilterOptions[0],
      value: updatedStateValues.values,
      replaceManual
    })
  }

  handleCloseFilterPopover() {
    const { handleClosePopover } = this.props

    if (handleClosePopover) {
      handleClosePopover()
    }
  }

  renderFilterPopoverHeader() {
    const { selectedAttribute } = this.props
    return (
      <FilterPopoverHeader
        attributeName={selectedAttribute.columnName || selectedAttribute.name}
        handleCloseFilterPopover={this.handleCloseFilterPopover.bind(this)}
      />
    )
  }

  handleFilterOptionItemClick(selectedFilterOption) {
    this.setState({ selectedFilterOption: selectedFilterOption })
  }

  renderFilterOptionsDropdown() {
    const { selectedFilterOption } = this.state

    return (
      <FilterOptionsDropdown
        selectedFilterOption={selectedFilterOption}
        filterOptions={this.computeFilterOptions()}
        handleFilterOptionItemClick={this.handleFilterOptionItemClick.bind(
          this
        )}
      />
    )
  }

  requiresValue() {
    const { selectedFilterOption } = this.state
    return selectedFilterOption === SET_ATTRIBUTE_ACTION_NAME
  }

  handleValueChanged(value) {
    this.setState({ value: value })
  }

  renderFilterValues() {
    const { selectedAttribute } = this.props
    const { value, showValidationErrors } = this.state

    return (
      this.requiresValue() && (
        <ActionFilterPopoverValues
          selectedAttribute={selectedAttribute}
          value={value}
          showValidationErrors={showValidationErrors}
          valueChanged={this.handleValueChanged.bind(this)}
        />
      )
    )
  }

  toggleReplaceManual() {
    const { replaceManual } = this.state
    this.setState({ replaceManual: !replaceManual })
  }

  renderReplaceManualCheckbox() {
    const { replaceManual } = this.state

    return (
      <MDBSwitch
        labelLeft=''
        labelRight='Allow this action to override manual edits.'
        checked={replaceManual}
        onChange={this.toggleReplaceManual.bind(this)}
      />
    )
  }

  filterValuesEmpty() {
    const { value } = this.state
    return _.isEmpty(value.replace(/^\s+|\s+$/g, ''))
  }

  handleApplyFilter() {
    const { showValidationErrors, selectedFilterOption, value, replaceManual } =
      this.state

    if (!this.requiresValue() || !this.filterValuesEmpty()) {
      const { handleApplyFilterClick, selectedAttribute } = this.props
      handleApplyFilterClick(
        selectedAttribute.name,
        selectedFilterOption,
        this.requiresValue() ? [value.trim()] : [],
        { replaceManual }
      )
    }

    if (!showValidationErrors) {
      this.setState({ showValidationErrors: true })
    }
  }

  renderApplyFilterBtn() {
    var disabled = this.requiresValue() && this.filterValuesEmpty()
    return (
      <Button
        tag='span'
        className='apply-filter-btn'
        disabled={disabled}
        onClick={this.handleApplyFilter.bind(this)}
      >
        Apply
      </Button>
    )
  }

  render() {
    return (
      <>
        {this.renderFilterPopoverHeader()}
        <Row>
          <Col>{this.renderFilterOptionsDropdown()}</Col>
        </Row>
        <Row className='filter-values-row'>
          <Col>{this.renderFilterValues()}</Col>
        </Row>
        <Row className='override-edits-row'>
          <Col>{this.renderReplaceManualCheckbox()}</Col>
        </Row>
        <Row>
          <Col>{this.renderApplyFilterBtn()}</Col>
        </Row>
      </>
    )
  }
}

export const mapStateToProps = (state, props) => ({
  ruleActionTypes: actionTypesSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionFilterPopoverContainer)
)
