import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isEmbeddedAppSelector } from 'selectors/app'
import { superUserSelector } from 'selectors/user'
import {
  currentPlanIdSelector,
  currentSubscriptionStatusSelector
} from 'selectors/billing'

export class FeedOpsWebsiteDefaultRoute extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    planId: PropTypes.string.isRequired,
    planStatus: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    isEmbeddedApp: PropTypes.bool.isRequired
  }

  computePath(locationPath) {
    const { match, location } = this.props
    if (match.url.includes('sign_in')) {
      return match.url
    }

    if (match.url.includes('//')) {
      return match.url.replace('//', '/')
    }

    var path =
      match.url.slice(-1) === '/'
        ? `${match.url}${locationPath}`
        : `${match.url}/${locationPath}`

    path = `${path}${location.search}`
    return path
  }

  render() {
    const { isSuperUser } = this.props

    const { planId, planStatus, isEmbeddedApp } = this.props

    var path = 'website'
    if (!isSuperUser && !isEmbeddedApp && !!!planId && !!!planStatus) {
      path = 'options'
    }
    return <Redirect to={this.computePath(path)} />
  }
}

export const mapStateToProps = (state, props) => {
  return {
    isSuperUser: superUserSelector(state, props),
    planId: currentPlanIdSelector(state, props),
    planStatus: currentSubscriptionStatusSelector(state, props),
    isEmbeddedApp: isEmbeddedAppSelector(state, props)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedOpsWebsiteDefaultRoute)
)
