export const BLUE_LINE_COLOUR = '#4285F4'
export const YELLOW_LINE_COLOUR = '#F4B400'

export const COMMON_DATASET_PARAMETERS = {
  lineTension: 0,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderWidth: 2,
  borderJoinStyle: 'miter',
  pointBorderWidth: 0,
  pointHoverRadius: 8,
  pointHoverBorderWidth: 2,
  pointRadius: 0,
  pointHitRadius: 10
}

const yAxesCommon = {
  type: 'linear',
  gridLines: {
    drawBorder: false
  },
  ticks: {
    beginAtZero: true,
    maxTicksLimit: 4
  }
}

const scales = {
  yAxes: [
    {
      ...yAxesCommon,
      id: 'A',
      position: 'left'
    }
  ],
  xAxes: [
    {
      gridLines: {
        display: false
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 8,
        minRotation: 0,
        maxRotation: 0
      }
    }
  ]
}

export const CHART_PARAMETERS = {
  responsive: true,
  spanGaps: true,
  scales,
  legend: {
    display: false
  }
}

export const GENERATE_DATASET = (hexColour, fill, label, data, additionalStyle = {}) => {
  const colour = hexColour
  const colourLight = Color(colour).alpha(0.5).lighten(0.2)
  return {
    ...COMMON_DATASET_PARAMETERS,
    label,
    data,
    fill,
    backgroundColor: colourLight,
    borderColor: colour,
    pointBorderColor: colour,
    pointBackgroundColor: colourLight,
    pointHoverBorderColor: colour,
    ...additionalStyle
  }
}
