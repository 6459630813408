import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from 'components/ad_champion/common/icon_button'
import { inventoryProcessingStatusSelector } from 'selectors/inventory'

import InventoryStatus from './inventory_status'
import InventoryStatusPoller from './inventory_status_poller'

import './inventory_status_snackbar.scss'

export class InventoryStatusSnackbar extends Component {
  static propTypes = {
    processingMessage: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      snackbarOpen: false
    }
  }

  async componentDidMount() {
    const { processingMessage } = this.props
    if (!!processingMessage) {
      this.setState({ snackbarOpen: true })
    }
  }

  async componentDidUpdate(prevProps) {
    const { processingMessage } = this.props
    if (!!processingMessage && !prevProps.processingMessage) {
      this.setState({ snackbarOpen: true })
    } else if (!processingMessage && !!prevProps.processingMessage) {
      this.setState({ snackbarOpen: false })
    }
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackbarOpen: false })
  }

  render() {
    const { snackbarOpen } = this.state
    return (
      <div className='inventory-status-snackbar'>
        <InventoryStatusPoller />
        <Snackbar
          open={snackbarOpen}
          onClose={this.handleClose.bind(this)}
          vertical={'bottom'}
          horizontal={'center'}
        >
          <>
            <InventoryStatus />
            <IconButton
              action={this.handleClose.bind(this)}
              iconClass='fa fa-times'
              tooltipText='Dismiss'
            />
          </>
        </Snackbar>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  processingMessage: inventoryProcessingStatusSelector(state, props)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryStatusSnackbar)
)
