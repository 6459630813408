import { createSelector } from 'reselect'
import { advertisingChannelSelector } from 'selectors/advertising'
import { performanceDataSelector } from 'selectors/performance'
import { performanceBudgetCategorySelector } from 'selectors/google/performance'

const actualBudgetIssueSelector = (accountType, id) => {
  switch (accountType) {
    case 'budget_categories':
      return createSelector(
        [performanceBudgetCategorySelector(id)],
        (data) => checkCorrectCurrency(data.data) || ''
      )
    default:
      return createSelector(
        [performanceDataSelector(accountType, id)],
        (data) => checkCorrectCurrency(data) || ''
      )
  }
}

const targetBudgetIssueSelector = (accountType, id) =>
  createSelector(
    [advertisingChannelSelector(accountType, id)],
    (channel) => channel.budgetIssue || ''
  )

export const budgetIssueFormatter = (accountType, id) =>
  createSelector(
    [
      actualBudgetIssueSelector(accountType, id),
      targetBudgetIssueSelector(accountType, id)
    ],
    (actualBudgetIssue, targetBudgetIssue) => {
      return actualBudgetIssue !== '' && targetBudgetIssue !== ''
        ? actualBudgetIssue + ' & ' + targetBudgetIssue
        : actualBudgetIssue + targetBudgetIssue
    }
  )

const checkCorrectCurrency = (data) => {
  var unique = data
    .map((d) => {
      return d.currency
    })
    .filter(onlyUnique)
  return unique.length === 1 && !unique.includes('mixed') ? unique[0] : 'mixed'
}

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index && value != null
}
